<template>
    <div class="allwh">
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @rowDetail="normalEvent"
                  @rowEdit="normalEvent"
                  @append="normalEvent({ref:'addEdit',method:'append'})"
                  @search="search"
                  @reset="reset"
                  @rowDelete="rowDelete">
        </ja-table>

        <add-edit ref="addEdit" />
        <detail ref="detail" />
    </div>
</template>
<script>
import { rqInspectionList, delInspection } from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import Detail from './childComp/Detail'

export default {
    title: '巡检计划',
    mixins: [table,],
    name: 'index',
    components: {
        AddEdit, Detail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd' },
                { input_type: 'input', key: 'planName', attribute: { placeholder: '计划名称', size: 'small' }, },

                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'deviceManage::inspectionPlan::add' },
            ],
            columns: [
                { label: '计划名称', prop: 'planName' },
                { label: '设备类型', prop: 'deviceCategoryName', },
                { label: '计划开始日期', prop: 'planStartTime' },
                { label: '计划结束日期', prop: 'planEndTime' },
                { label: '执行频率', prop: 'executionFrequencyValue' },
                { label: '状态', prop: 'enablePlanValue' },
                { label: '创建人', prop: 'createBy' },
                {
                    label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
                    list: [
                        { text: '详情', method: 'rowDetail', limit: 'deviceManage::inspectionPlan::detail' },
                        { text: '编辑', method: 'rowEdit', limit: 'deviceManage::inspectionPlan::edit' },
                        { text: '删除', method: 'rowDelete', limit: 'deviceManage::inspectionPlan::delete' },
                    ]
                }
            ],
        }
    },
    methods: {
        rqParmas() {
            let obj = { ...this.filterData }
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        getInit() {
            this.loading = true
            let data = this.rqParmas()
            rqInspectionList(data).then(res => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
        // 删除
        rowDelete(row) {
            this.$confirm('是否确认删除计划？', '删除', { type: 'warning' }).then(res => {
                delInspection(row.id).then(res => {
                    this.$message.success("已删除")
                    this.getInit()
                })
            })
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/ .cellImg {
    display: flex;
    margin: auto;
    > img {
        max-height: 23px;
        width: 35px;
        display: flex;
        margin: auto;
    }
}
/deep/ .errorImg {
    width: 100%;
}
</style>