<template>
  <div>
    <!-- 新增/编辑 -->
    <el-dialog v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="55%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        ref="jaForm"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="preSaveForm"
        v-if="showEditAll"
        @selectRoom="selectRoom"
        @rowDelete_formTable="rowDelete_formTable"
      > 
      <template #fromRightButton>
        <el-button type="primary" @click="selectRoom">单元选择</el-button>
      </template>
       <template #uploadFile="row">
          <upload-file ref="Upload" :autoUpload="true" :limit="1" accept='.jpeg,.png,.jpg,' :keyItem="row.data" :initFileList="initFileList" :fileSize="10" v-if="showEditAll" />
      </template>
      </ja-form>
    </el-dialog>
    <!-- 选择空间 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" width="50%" title="房间关联空间"  :visible.sync="isSelectRoom" v-if="isSelectRoom">
        <select-space @selectRow="selectSpace" :selectedPersons="[...selectedRooms]" v-if="isSelectRoom"  :isOnePerson="false"/>
    </el-dialog>
   
  </div>
</template>
<script>
import {addRoom,updateRoom } from '@/api'
import {spaceColumns} from '../roomManage'
import selectSpace from '@/views/home/components/selectSpace/selectSpace'
export default {
  inject: ['labelWidth'],
  components: {selectSpace},
  data() {
    return {
      //空间显示
      isSelectRoom:false,
      // 空间弹窗
      selectedRooms: [],
      columns:spaceColumns,
      // 新增/编辑
      titleText: '编辑房间',
      rowFormData: [  
        { input_type: 'input', label: '租赁区域名称', key: 'roomName',col: 8},
        { input_type: 'input', label: '租赁区域编号', key: 'roomCode',col: 8,disabled:true},
        // { input_type: 'select', label: '户型', key: 'category',col: 8,options: function () {
        //     return getDictData('room_category').then((res) => {
        //       if (res.code == 200) {
        //         let data = res.data.map((item) => {
        //           return {
        //             label: item.dictLabel,
        //             value: item.dictValue
        //           }
        //         })
        //         return data
        //       }
        //     })
        //   } 
        // },
        {col:'24',rightBox:{
          input_type:'customize',customize:'fromRightButton'}, 
        },
        { input_type:'table', col:'24', label: '关联单元', noSearch: true, showPage: false, key:'spaceDataList', isRequired:true, border:true,
          tableAttribute:{showSummary:true},
          columns:spaceColumns 
        },
        { input_type: 'customize', customize: 'uploadFile', label: '控制图', key: 'controlImage', col: 24 },
      ],
      rules: {
        // category: [
        //   { required: true, message: '请选择户型', trigger: 'change' }
        // ],
        roomName: [
          { required: true, message: '请填写房间名称', trigger: 'change' }
        ],
        spaceDataList: [
          { required: true, message: '请选择空间', trigger: 'change' }
        ],
      },
      allDisabled:false,
      rowForm: {
        spaceIds:[],
        spaceDataList:[]

      },
      showEditAll: false, // 显示新增/编辑弹窗
      initFileList: [],
    }
  },
  methods: {
    append() {
      this.isAdd = true
      this.titleText = '新增房间' 
      this.rowForm = {}
      this.initFileList = []
      this.showEditAll = true
      // this.rowFormData[1]
    },
    rowEdit(row,details) {
      this.isAdd = false
      this.titleText = '编辑房间' 
      this.rowForm = { ...row }
      console.log(this.rowForm);
      let imgArr = row.controlImage ? [row.controlImage] : ''
      if (imgArr.length > 0) {
        this.initFileList = this.util.backImgVideoObj(imgArr)
      } else {
        this.initFileList = []
      }
      this.showEditAll = true
      this.$nextTick(()=>{
          this.util.setOptionsInit({arr:this.rowFormData,This:this,obj:this.rowForm,ref:'jaForm'})
      })
    },
    preSaveForm() {
      let fileList = this.$refs.Upload.fileList
      if (fileList.length == 0) {
        this.$set(this.rowForm, 'controlImage', '')
        this.saveForm()
      } else {
        let isOk = fileList[0].status == 'success'
        if (isOk) {
          this.$set(this.rowForm, 'controlImage', fileList[0].response.data)
          this.saveForm()
        } else {
          this.$message.warning("存在文件尚未完成上传")
          return
        }
      }
    },
    // 保存
    saveForm() {
      console.log('新增===》', this.rowForm)
      let spaceIds = []
        this.rowForm.spaceDataList.map(e=>{
            spaceIds.push(e.id)
      })
      this.$set(this.rowForm,'spaceIds',spaceIds)
      if (this.isAdd) {
        addRoom(this.rowForm)
          .then((res) => {
            this.$message.success('新增成功')
            this.showEditAll = false
            this.$parent.getInit()
          })
          .catch((error) => {
          })
      } else {
        // 判断当前户型字段是否是中文 是中文则查询并设置对应的参数
        // let updateParams = deepCopy(this.rowForm)
        // let categoryCN= this.rowFormData[2].options.find(item => item.label == this.rowForm.category)
        // if(categoryCN){
        //   updateParams.category = categoryCN.value
        // }
        // updateRoom(updateParams)
        updateRoom(this.rowForm)
          .then((res) => { 
            this.$message.success('修改成功')
            this.showEditAll = false
            this.$parent.getInit()
          })
          .catch((error) => {
            // this.$message.error(error)
          })
      }
    },
    // 关闭弹窗
    close(){
      this.allDisabled=false
    },
    // 选择空间
    selectSpace({list}){
        this.isSelectRoom = false
        this.selectedRooms = list
        this.$set(this.rowForm,'spaceDataList',list)
    },
    //弹出
    selectRoom(){
      this.selectedRooms = this.rowForm.spaceDataList? [...this.rowForm.spaceDataList] : []
      this.isSelectRoom = true
    },
    rowDelete_formTable (row, info, button) {
        this.rowForm.spaceDataList.splice(info.$index, 1)
    },
    }
}
</script>
<style lang="scss" scoped>
  /deep/.right-customize{
    float: right;
    margin-bottom: 10px;
  }
</style>