<template>
    <!-- 表格 -->
    <div class="content">
        <title-box title="月度能耗统计分析"
                   :isRight="true">
            <template #right>
                <div class="selections">
                    <el-select v-model="activeYear"
                               placeholder="年份"
                               @change="selectYear"
                               class="selectBox">
                        <el-option v-for="item in years"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="activeType"
                               placeholder="类型"
                               @change="selectType"
                               class="selectBox typeSelect">
                        <el-option v-for="item in typeList"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </template>
        </title-box>
        <div class="echart"
             v-if="options">
            <ja-init-chart :options="options"
                           ref="jaChart" />
        </div>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import titleTop from './common/titleTop.vue'
import { getUsageStatistics } from '@/api'
export default {
    components: {
        JaInitChart, TitleBox, titleTop
    },
    data() {
        return {
            options: null,
            // 年份
            activeYear: '',
            years: [],
            // 类型
            activeType: '1',
            yName: '（度）',
            yMax: 140000,
            typeList: [
                { label: '电', value: '2' },
                { label: '水', value: '1' },
            ],
            ydata: [],
            oldYdata: []
        }
    },
    created() {
        this.activeYear = '2022'
        let y = new Date().getFullYear()
        this.years = [
            { label: y, value: y },
            { label: y - 1, value: y - 1 },
            { label: y - 2, value: y - 2 },
        ]

        this.getInit()
    },
    methods: {
        getInit() {
            let ydata = [[], []]
            let oldYdata = []
            getUsageStatistics({ type: this.activeType, year: this.activeYear }).then(res => {
                res.data[0].energyVOList.map(item => {
                    ydata[0].push(Number(item.dosage).toFixed(2))
                    ydata[1].push(item.statisticalNodes + '月')
                })
                res.data[1].energyVOList.map(item => {
                    oldYdata.push(Number(item.dosage).toFixed(2))
                })
                this.ydata = ydata
                this.oldYdata = oldYdata
                this.setOptions()
            })
        },
        selectYear(val) {
            this.activeYear = val
            this.getInit()
        },
        selectType(val) {
            this.activeType = val
            this.getInit()
        },
        async setOptions() {
            const activeYear = this.activeYear
            let legendData = []
            if (this.activeType == 2) {
                legendData = ['当月电耗', '去年同月电耗']
            } else {
                legendData = ['当月水耗', '去年同月水耗']
            }
            this.options = {
                color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        },
                        lineStyle: {
                            type: "dashed"
                        },
                    },
                    formatter: function (params) {
                        // console.log('****', params)
                        let str = activeYear == 'all' ? '全部' : `${activeYear}年${params[0].axisValueLabel}<br/>`;
                        params.forEach((item, idx) => {
                            if (idx < params.length - 1) {
                                str += `${item.marker} ${item.seriesName}: ${Number(item.data).toFixed(2)}  ${this.activeType == 2 ? '度' : '(m³)'}<br/>`

                            }
                        })
                        let length = params[0].dataIndex - 1
                        if (length < 0) {
                            str += `较上月环比：${(params[0].value - (this.oldYdata[this.oldYdata.length - 1])).toFixed(2)} ${this.activeType == 2 ? '度' : '(m³)'}<br/>`
                        } else {

                            str += `较上月环比：${(params[0].value - (this.ydata[0][length])).toFixed(2)} ${this.activeType == 2 ? '度' : '(m³)'}<br/>`
                        }
                        return str
                    }.bind(this)
                },
                legend: {
                    top: '5%',
                    itemGap: this.util.setFontSize(30, 1920),
                    textStyle: { //图例文字的样式
                        fontSize: this.util.setFontSize(15, 1920),
                    },
                    data: legendData,
                },
                grid: {
                    top: '18%',
                    bottom: '28%',
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.ydata[1],
                        axisPointer: {
                            type: 'none',
                        },
                        offset: this.util.setFontSize(8, 1920),
                        axisLabel: {
                            fontSize: this.util.setFontSize(15, 1920),
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: this.activeType == 2 ? '度' : '(m³)',
                        axisTick: {
                            show: false,
                        },
                        axisPointer: {
                            label: {
                                show: true,
                            }
                        },
                        nameGap: 20,
                        nameTextStyle: {
                            fontSize: this.util.setFontSize(16, 1920),
                        },
                        min: 0,
                        max: this.yMax,
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: this.util.setFontSize(14, 1920),
                        },
                    }
                ],
                series: [
                    {
                        name: legendData[0],
                        type: 'bar',
                        activeYear: 2022,
                        data: this.ydata[0]
                    },
                    {
                        name: legendData[1],
                        type: 'bar',
                        data: this.oldYdata
                    },
                    {
                        name: 'Temperature',
                        type: 'line',
                        xAxisIndex: 1,
                        yAxisIndex: 0,
                        data: this.ydata[0]
                    }
                ]
            }
            //添加一个新的刻度线 实现折线在柱状图的中间显示
            this.options.xAxis[1] = {
                type: 'value',
                max: this.options.xAxis[0].data.length,
                show: false,
                axisPointer: {
                    label: {
                        show: false,
                    }
                },
            }
            this.options.series[2].data = this.options.series[2].data.map((x, i) => [(0.25 + i), x])
            this.$nextTick(() => {
                this.$refs.jaChart.drawInit(this.options)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.content {
    overflow: hidden;
    border-radius: 20px;
    width: 100%;
    height: 550px;
    background: white;
    overflow: hidden;
    .selections {
        .selectBox {
            width: 120px;
        }
        .typeSelect {
            margin-left: 20px;
        }
    }
}
.echart {
    height: 100%;
    width: 100%;
}
</style>