<template>
  <router-view/>
</template>

<script>
export default {
    title: "工单管理"
}
</script>

<style scoped>
</style>
