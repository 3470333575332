<template>
  <div>
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="show"
      width="40%"
      :title="titleText"
      v-loading="addLoading"
    >
      <ja-form
        :rules="rules"
        v-model="rowForm"
        :formArray="rowFormData"
        @reset="show = false"
        @save="saveForm"
        @changeId="changeId"
        v-if="show"
        :noButton="true"
        :valueFormat="formatter"
      >
        <div class="dialog-footer">
          <el-button @click="rejected">驳 回</el-button>
          <el-button type="primary" @click="rapproved">通 过</el-button>
        </div>
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { rejectedApproved, updateInternalVehicle } from '@/api'
export default {
  components: {
    JaForm
  },
  inject: ['labelWidth', 'getInit'],
  data() {
    return {
      rules: {},
      rowFormData: [
        {
          input_type: 'input',
          label: '公司名称',
          key: 'tenantName',
          maxlength: 20,
          disabled: true
        },
        {
          input_type: 'input',
          label: '店铺名称',
          key: 'storeName',
          maxlength: 20,
          disabled: true
        },
        {
          input_type: 'input',
          label: '车牌号码',
          key: 'carNumber',
          maxlength: 20,
          disabled: true
        },
        {
          input_type: 'select',
          label: '服务类型',
          key: 'serviceType',
          disabled: true,
          options: [
            {
              value: 0,
              label: '月卡'
            },
            {
              value: 1,
              label: '季卡'
            },
            {
              value: 2,
              label: '半年卡'
            },
            {
              value: 3,
              label: '年卡'
            }
          ]
        },
        {
          input_type: 'input',
          label: '申请人',
          key: 'applicant',
          maxlength: 20,
          disabled: true
        },
        {
          input_type: 'input',
          label: '申请时间',
          key: 'applicationTime',
          maxlength: 20,
          disabled: true
        },
        {
          input_type: 'input',
          label: '联系方式',
          key: 'contactNumber',
          maxlength: 20,
          disabled: true,
          placeholder: ' '
        },
      ],
      show: false,
      titleText: '通过/驳回',
      rowForm: {},
      addLoading: false,
      result: '1'
    }
  },
  methods: {
    init(row) {
      this.show = true
      this.titleText = '通过/驳回'
      this.rowForm = { 'result': this.result, ...row }
    },
    rejected() {
      // this.addLoading = true
      this.rowForm.result = '2'
      this.$confirm('是否确认驳回？').then((res) => {
        rejectedApproved(this.rowForm).then((res) => {
          this.$message.success('已驳回')
          this.show = false
          // this.addLoading = false
          this.getInit()
        })
        .catch((err) => {
          // this.addLoading = false
        })
      })
    },
    rapproved() {
      // this.addLoading = true
      this.rowForm.result = '1'
      this.$confirm('是否确认通过？').then((res) => {
        rejectedApproved(this.rowForm).then((res) => {
          this.$message.success('已通过')
          this.show = false
          // this.addLoading = false
          this.getInit()
        })
        .catch((err) => {
          // this.addLoading = false
        })
      })
    },
    saveForm() {
      this.addLoading = true
      updateInternalVehicle(this.rowForm)
        .then((res) => {
          if (res.code !== 200) return this.$message.error('编辑失败')
          this.show = false
          this.addLoading = false
          this.getInit()
        })
        .catch((err) => {
          this.addLoading = false
        })
    }
  }
}
</script>
<style long="scss" scoped>
.dialog-footer {
  margin-right: 30px;
  float: right;
}
</style>