<template>
  <!-- 新增/编辑 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false"
    :visible.sync="showEditAll"
    v-if="showEditAll"
    width="30%"
    @close="close"
    :title="titleText"
  >
    <div class="block">
      <el-date-picker
        v-model="value1"
        style="width:100%"
        type="month"
        value-format="yyyy-M"
        @change="changePicker"
        placeholder="选择日期"
      >
      </el-date-picker>
      <calendar :dateValue="dateValue"  ref="calendar" @closeAllocation="close" @changeHolidayt="changeHolidayt"></calendar>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="addHolidayt">确 定</el-button>
      <el-button  @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { addHolidayt, updatePark } from '@/api'
import calendar from './calendar'
export default {
  inject: ['labelWidth'],
  components:{
    calendar
  },
  data() {
    return {
      // 新增/编辑
      titleText: '节假日配置',
      rowForm: {},
      value1:new Date(),
      showEditAll: this.show, // 显示新增/编辑弹窗
      dateValue:{
         year:"", 
         month:""
      },
      params:{}
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(val) {
      this.showEditAll = val
      this.value1=new Date()
    }
  },
  methods: {
    append() {
      this.isAdd = true
      this.titleText = '新增'
      this.rowForm = {}
      this.showEditAll = true
    },
    rowEdit(row) {
      //  console.log('row===',row)
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = { ...row }
      this.showEditAll = true
    },
    // 保存
    saveForm() {
      if (this.isAdd) {
        console.log('新增===》', this.rowForm)
        addBusItem(this.rowForm)
          .then((res) => {
            this.$message.success('新增成功')
            this.showEditAll = false
            this.$parent.getInit()
          })
          .catch((error) => {
            // this.$message.error(error)
          })
      } else {
        editBusItem(this.rowForm)
          .then((res) => {
            this.$message.success('修改成功')
            this.showEditAll = false
            this.$parent.getInit()
          })
          .catch((error) => {
            // this.$message.error(error)
          })
      }
    },
    close() {
      this.$emit('closeAllocation', false)
      this.dateValue.year= "", 
      this.dateValue.month= ""
    },
    // 点击确认
    changePicker(val){
      let valArr=val.split("-").map(Number)
      this.dateValue.year=valArr[0]
      this.dateValue.month=--valArr[1]
    },
    // 提交节假日配置
    addHolidayt(){
      this.$refs.calendar.addHolidayt()
      this.close()
    },
    changeHolidayt(val){
      this.params=val
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-footer{
  text-align:center;
  .el-button{
   margin-right: 20px;
   margin-bottom: 30px;
  }
}
</style>