<template>
  <!-- 冷源消耗 -->
  <div class="content">
    <titleBox title="冷源消耗" v-model="activeType" :selectObj="selectObj" />
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>

<script>
import titleBox from './titleBox.vue'
import JaInitChart from "@/components/ja-init-chart/index"


export default {
  components: {
    titleBox, JaInitChart
  },
  data() {
    return {
      // 年份
      activeType: '',
      selectObj: {
        options: [
          { label: '冷水量', value: 'water' },
          { label: '冷风量', value: 'cold' },
        ]
      },
      options: []
    }
  },
  watch: {
    activeType: {
      immediate: true,
      handler(v) {
        console.log("类型====", v)
      }
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    setOptions() {
      const xData = this.util.numberRange(1, 12, null, null, '月').map(v => v.label);
      this.options = {
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          top: '18%',
          bottom: '25%',
          right: '10%'
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow'
            },
            axisTick: {
              show: false,
            },
            // boundaryGap:false
          },
        ],
        yAxis: [
          {
            // splitLine: {
            //   show: false
            // },
            type: 'value',
            name: '（m³/s）',
            nameGap: 20,
            nameTextStyle: {
              fontSize: this.util.setFontSize(16, 1920),
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: this.util.setFontSize(14, 1920),
            },
          },
          {
            show: false,
            // splitLine: {
            //   show: false
            // },
            type: 'value',
            nameGap: 20,
            nameTextStyle: {
              fontSize: this.util.setFontSize(16, 1920),
            },
            axisTick: {
              show: false,
            },
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '自用层',
            type: 'line',
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
            ]
          },
          {
            name: '非自用层',
            type: 'line',
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ]
          },
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.echart {
  height: 100%;
  width: 100%;
}
</style>