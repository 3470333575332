<template>
    <!-- 表格 -->
    <div class="content">
        <title-box title="车位统计" />
        <title-top title="车位总数"
                   subTitle="车位停车状态统计"
                   :num='String(totalParkingSpaces)' />
        <div class="echart"
             v-if="options">
            <ja-init-chart :options="options" />
        </div>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import titleTop from './common/titleTop.vue'
import { getParkingNumber } from '@/api'
export default {
    components: {
        JaInitChart, TitleBox, titleTop
    },
    data() {
        return {
            options: null,
            totalParkingSpaces: 0
        }
    },
    created() {
        this.setOptions()

    },
    methods: {
        async setOptions() {
            let initData = []
            await getParkingNumber().then((res) => {
                let data = res.data
                this.totalParkingSpaces = data.totalParkingSpaces
                initData = [
                    { value: (data.totalParkingSpaces - data.remainingParking), name: '停车车位' },
                    { value: data.remainingParking, name: '空闲车位' },
                ]
            })



            this.options = {
                color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    right: this.util.setFontSize(30, 1920),
                    top: '0',
                    width: '10%',
                    height: '80%',
                    itemGap: this.util.setFontSize(18, 1920),
                    textStyle: { //图例文字的样式
                        fontSize: this.util.setFontSize(15, 1920),
                    },
                    formatter: function (val) {
                        let item = initData.find(v => v.name == val)
                        return `${val}  ${item.value}  个`;
                    },
                    data: [
                        '停车车位',
                        '空闲车位',
                    ]
                },
                series: [
                    {
                        name: 'Area Mode',
                        type: 'pie',
                        avoidLabelOverlap: false,
                        radius: ['20%', '40%'],
                        center: ['40%', '30%'],
                        // roseType: 'area',
                        itemStyle: {
                            borderRadius: 5
                        },
                        colorBy: 'data',
                        label: {
                            fontSize: this.util.setFontSize(15, 1920),
                            formatter: '{b}\n\n{d}%',
                        },
                        labelLine: {
                            length: this.util.setFontSize(15, 1920),
                            length2: this.util.setFontSize(20, 1920),
                            maxSurfaceAngle: this.util.setFontSize(80, 1920),
                        },

                        data: initData
                    }
                ]

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 100%;
}
.echart {
    height: 100%;
    width: 100%;
}
</style>