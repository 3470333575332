let detailData_common = {
    first: [
        { label: '项目名称', key: 'serviceName', col: 24 },
        { label: '项目编码', key: 'serviceCode', },
    ],
    remark: [{ label: '备注', key: 'remark', col: 24 },]
}
//------  不同的表单
let detailObj = {
    periodicity: {
        titleText: '周期类费用',
        detailOptions: [
            ...detailData_common.first,
            { label: '计费频率', key: 'frequencyLabel', },
            { label: '计费日期', key: 'billingDate', },
            { label: '计费周期', key: 'frequencyCategoryLabel', },
            { label: '计费方式', key: 'frequencyModeLabel', },
        ]
    },
    instrument: {
        titleText: '仪表类费用',
        detailOptions: [
            ...detailData_common.first,
            { label: '关联仪表', key: 'deviceCategory', },
            { label: '计费频率', key: 'frequencyLabel', },
            { label: '计费日期', key: 'billingDate', },

            // 下面这三个,根据关联仪表而出现
            { label: '单价', key: 'unitPrice', hide: true, }, //124 水表 元/m³   电表125 text:'/kw · h'
            {
                type: 'table', label: '时间段标准', noSearch: true, showPage: false, key: 'timeIntervalList', col: 24, table_MaxHeight: 500, tableAttribute: { showSummary: true }, hide: true,
                columns: [
                    { label: '时段类型', prop: 'categoryLabel' ,},
                    { label: '时间起止', prop: 'timeIntervalLabel', },
                    { label: '时段单价', prop: 'unitPrice' },
                    { label: '计费单位', prop: 'unit', },
                ]
            },
        ],

    },
    deposit: {
        titleText: '押金类费用',
        detailOptions: [...detailData_common.first, ...detailData_common.remark],
    },
    temporary: {
        titleText: '临时类费用',
        detailOptions: [...detailData_common.first, ...detailData_common.remark],
    }
}


export {
    detailObj,
}