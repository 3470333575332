<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :editDataFormat="editDataFormat" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowEdit="normalEvent" @rowDetail="normalEvent">
    </ja-table>

    <!-- 新增/编辑 -->
    <add-edit ref="addEdit" />
    <detail ref="detail" />
  </div>
</template>
<script>
import { rqPsmActivity } from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit.vue'
import Detail from './childComp/Detail.vue'

export default {
  title: '活动申请',
  mixins: [table,],
  name: 'index',
  components: {
    AddEdit, Detail
  },
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
        { input_type: 'select', key: 'activityType', attribute: { placeholder: '活动类型', size: 'small' }, options: [], },
        {
          input_type: 'select', key: 'status', attribute: { placeholder: '审批状态', size: 'small' }, options: [
            { label: '审核中', value: 0 },
            { label: '已同意', value: 1 },
            { label: '已驳回', value: 2 },
          ],
        },
        { input_type: 'input', key: 'companyName', attribute: { placeholder: '举办单位名称', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
      ],
      columns: [
        { label: '申请编号', prop: 'activityCode' },
        { label: '活动主题', prop: 'activityTopic' },
        { label: '活动类型', prop: 'activityTypeName' },
        { label: '活动区域', prop: 'area', },
        { label: '举办单位', prop: 'companyName', },
        { label: '负责人', prop: 'activityPrincipal', },
        { label: '联系方式', prop: 'phone' },
        { label: '参与人数', prop: 'participantsNumber' },
        { label: '开始时间', prop: 'startTime', },
        { label: '结束时间', prop: 'endTime' },
        { label: '审批状态', prop: 'statusName' },
        { label: '驳回理由', prop: 'rejectReason', },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '详情', method: 'rowDetail' },
            { text: '审批', method: 'rowEdit' },
          ]
        }
      ],
      con_indexObj: {},
    }
  },
  mounted() {
    this.con_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.util.rqOptionsList({ who: [this.conditionData[this.con_indexObj.activityType],], rqUrl: "/sys/dictData/activity_type", rqMethod: 'get', resRule: 'data' })
  },
  methods: {
    rqParmas() {
      let obj = { ...this.filterData }
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = true
      let data = this.rqParmas()
      rqPsmActivity(data).then(res => {
        this.loading = false
        this.tableData = (res.data.data||[]).map(item=>{
          return {
            ...item,
            area:`${item.buildingName} ${item.floorName}`
          }
        })
        this.total = res.data.total
      }).catch(err => {
        this.loading = false
      })
    },
    editDataFormat(row, btn) {
      if (row.status !== 0 && btn.text == '审批') {
        return false
      } else {
        return true
      }
    },
  }
}
</script>
