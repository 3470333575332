<template>
    <div>
        <ul class="content"
            v-loading="show">
            <li v-for="(item,index) in listObj"
                :key="index">
                <div class="top">
                    <div class="box-icon">
                        <img :src="listObj[index].icon"
                             alt=""
                             class="icon">
                    </div>
                    <p class="title">{{listObj[index].title}}</p>
                </div>
                <div class="bottom">
                   {{statisticsData[item.key]}}
                </div>
            </li>
        </ul>
        <ul class="monthList"
            v-loading="show">
            <li v-for="(item,index) in monthList"
                :key="index">
                <div class="top">
                    <p class="title">{{item.title}}</p>
                </div>
                <div class="bottom">
                   {{statisticsData[item.key]}}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import icon1 from '@/assets/images/chargeConfig/icon1.png'
import icon2 from '@/assets/images/chargeConfig/icon2.png'
import icon3 from '@/assets/images/chargeConfig/icon3.png'
import icon4 from '@/assets/images/chargeConfig/icon4.png'
import { getFinanceStatistics } from '@/api'
export default {
    data() {
        return {
            listObj: [
                { id: 1, title: '应收款总金额',  icon: icon1,key:'amountReceivableSum' },
                { id: 2, title: '实收总金额',  icon: icon2,key:'amountWriteOffSum' },
                { id: 3, title: '期末总金额', icon: icon3,key:'amountUnpaidSum' },
                { id: 4, title: '逾期总金额', icon: icon4 ,key:'termEndSum'},
            ],
            monthList: [
                { id: 1, title: '本月逾期金额', key:'lastMonthamountUnpaid' },
                { id: 2, title: '本月逾期客户', key:'lastMontNumber' },
            ],
            show: false,
            statisticsData:{}
        }
    },
    mounted() {
        this.getInit()
    },
    methods: {
        getInit() {
            this.show = true
            getFinanceStatistics().then(res => {
                this.statisticsData=res.data
                this.show = false
            }).catch((err) => {
                return err
            })

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/theme.scss';

ul.content {
    color: #606266;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    li {
        width: 100%;
        background: white;
        margin-right: 20px;
        border-radius: 20px;
        &:last-child {
            margin-right: 0;
        }
        .top {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px 0 18px;
            .icon {
                margin: 0 10px 0 40px;
                width: 30px;
                height:30px;
            }
        }
        .bottom {
            text-align: center;
            padding-bottom: 20px;
            font-size: 22px;
            font-weight: bold;
        }
    }
}
ul.monthList {
    color: #606266;
    display: flex;
    width: 100%;
    justify-content: space-between;
    li {
        width: 100%;
        background: white;
        margin-right: 20px;
        border-radius: 20px;
        background: #4352d4;
        color: #fff;
        &:last-child {
            margin-right: 0;
        }
        .top {
            display: flex;
            align-items: center;
            margin: 30px 40px 18px;
            font-size: 22px;
            font-weight: bold;
        }
        .bottom {
            text-align: center;
            padding-bottom: 20px;
            font-size: 22px;
            font-weight: bold;
        }
    }
    li:nth-of-type(2){
         background: #f59a23;
    }
}
</style>