import { render, staticRenderFns } from "./approval.vue?vue&type=template&id=5017cfad&scoped=true&"
import script from "./approval.vue?vue&type=script&lang=js&"
export * from "./approval.vue?vue&type=script&lang=js&"
import style0 from "./approval.vue?vue&type=style&index=0&id=5017cfad&lass=less&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5017cfad",
  null
  
)

export default component.exports