<template>
    <!-- 表格 -->
    <div class="content" v-loading="loading">
        <div class="header">{{ waterData.deptName }}工单状态分析</div>
        <div class="echart" v-if="options">
            <ja-init-chart :options="options" />
        </div>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
export default {
    components: {
        JaInitChart,
    },
    props: {
        waterData: Object, loading: Boolean
    },
    data() {
        return {
            options: null,
            waterData: null,
        }
    },
    mounted() {
        this.setOptions()
    },
    watch: {
        waterData(newVal){
            this.setOptions()
        }
    },
    methods: {
        async setOptions() {
            let initData = []
            initData = [
                { value: this.waterData.workOrderComplete, name: '已完成' },
                { value: this.waterData.workOrderUnComplete, name: '未完成' },
            ]
            let num = Number(this.waterData.workOrderComplete)
            this.options = {
                color: ['#4352D4', '#DADDF6'],
                legend: {
                    orient: 'vertical',
                    right: this.util.setFontSize(30, 1920),
                    top: '40%',
                    left: '55%',
                    width: '10%',
                    height: '80%',
                    itemGap: this.util.setFontSize(18, 1920),
                    textStyle: { //图例文字的样式
                        color: '#aaa',

                        fontSize: this.util.setFontSize(15, 1920),
                    },
                    formatter: function (val) {
                        let item = initData.find(v => v.name == val)
                        return `${val}  ${item.value}  条`;
                    },
                    data: [
                        '已完成',
                        '未完成',
                    ]
                },
                series: [
                    {
                        name: 'Area Mode',
                        type: 'pie',
                        radius: [0, 0],
                        center: ['-100%', '-100%'],
                        label: {
                            show: false
                        },
                        z: 1,
                        data: initData
                    },
                    {
                        type: 'liquidFill',
                        z: 2,
                        radius: '50%',
                        center: ['35%', '50%'],
                        color: [
                            {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#DADDF6'
                                    },
                                    {
                                        offset: 1,
                                        color: '#4352D4'
                                    }
                                ],
                                globalCoord: false
                            }
                        ],
                        data: [this.waterData.workOrderComplete / this.waterData.workOrderTotal, this.waterData.workOrderComplete / this.waterData.workOrderTotal], // data个数代表波浪数
                        backgroundStyle: {
                            borderWidth: 1,
                            color: 'RGBA(218, 221, 246, 0.3)'
                        },
                        label: {
                            normal: {
                                formatter: function () {
                                    return num // 显示具体数字
                                },
                                textStyle: {
                                    fontSize: 30, // 数字字体大小
                                    color: '#4352D4' // 数字颜色
                                }
                            }
                        },
                        outline: {
                            // show: false
                            borderDistance: 6,
                            itemStyle: {
                                borderWidth: 6,
                                borderColor: '#4352D4'
                            }
                        }
                    },
                ]


            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    text-align: center;
    font-size: 16px;
    width: 100%;
    margin-top: 20px;
    position: relative;
    color: #4352D4;
    font-weight: 700;
}

.content {
    width: 100%;
    height: 100%;
}

.echart {
    height: 100%;
    width: 100%;
}
</style>