<template>
    <!--  指派 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="50%" :title="'查看计划'">
        <ja-form ref="jaForm"
                 :rules="rules"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :loadingForm="loadingForm"
                 :labelWidth="labelWidth"
                 :all-disabled="true"
                 v-if="showEditAll">
            <template #frequency>
                <frequency :planTimeReqList.sync="rowForm.planTimeReqList" :executionFrequency.sync="rowForm.executionFrequency" :disabled="true"/>
            </template>
            <template #minute>
                <div class="timeBox">
                    <span>分钟</span>
                </div>
            </template>

        </ja-form>
    </el-dialog>
</template>
<script>
import frequency from './frequency'

export default {
    inject: ['labelWidth', 'getInit'],
    components: {
        frequency
    },
    data () {
        return {
            rowForm: {},
            // 基础
            rowFormData: [
                { input_type: "input", label: '巡检计划名称', key: 'planName', col: 11 },
                { input_type: "radio", label: '计划类型', key: 'planType', col: 11, options: [{ label: '工程计划', value: "0" }, { label: '物业计划', value: "1" }, { label: '运营计划', value: "2" }] },
                { input_type: "select", label: '巡检路线', key: 'pathId', col: 22, options: [] },
                { input_type: "date", label: '计划开始时间', key: 'planStartTime', col: 11, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
                { input_type: "date", label: '计划结束时间', key: 'planEndTime', col: 11, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
                { input_type: "customize", label: '执行频率', key: 'executionFrequency', col: 22, customize: 'frequency' },
                { input_type: 'switch', key: 'enablePlan', label: '是否启用计划', activeValue: 0, inactiveValue: 1, col: 22 }
            ],
            rules: {},
            loadingForm: false,
            showEditAll: false, // 显示新增/编辑弹窗,
            indexObj: {}
        }
    },
    async created () {
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData })
        this.util.rqOptionsList({ who: [this.rowFormData[this.indexObj.pathId]], rqUrl: "/patrol/path/all?type=0", resRule: 'data', labelName: 'pathName', valueName: 'id', isReturnAll: true })
        console.log("init....", this.rowFormData)
    },
    watch: {
        'rowForm.planType': function (val) {
            this.util.rqOptionsList({
                who: [this.rowFormData[this.indexObj.pathId]],
                rqUrl: "/patrol/path/all?type=" + val,
                resRule: 'data',
                labelName: 'pathName',
                valueName: 'id',
                isReturnAll: true
            })
        }
    },
    methods: {
        // 查看
        rowDetail (row) {
            this.showEditAll = true
            this.loadingForm = true
            this.rowForm = { ...row }
            this.loadingForm = false
        }
    }
}
</script>
<style lang="scss" scoped>
.btns {
    margin-left: 20px;
    display: flex;
    align-items: center;
    .ipTestWord {
        margin-left: 10px;
    }
}
.timeBox {
    display: flex;
    align-items: center;
    span {
        margin: 0 10px;
    }
    .el-switch,
    & > span {
        flex-shrink: 0;
    }
}
</style>
