<template>
    <div class="tabsBox">
        <el-tabs v-model="activeName">
            <el-tab-pane label="待审批" name="1">
                <approval v-if="activeName === '1'"></approval>
            </el-tab-pane>
            <el-tab-pane label="已通过" name="2">
                <passed v-if="activeName === '2'"></passed>
            </el-tab-pane>
            <el-tab-pane label="已下发" name="3">
                <distributed v-if="activeName === '3'"></distributed>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import Approval from "./components/approval.vue"
import Passed from "./components/passed.vue"
import Distributed from "./components/distributed.vue"
export default {
    title: '应收账款审批',
    mixins: [table],
    name: 'approvalList',
    components: {
        Distributed,
        Approval,
        Passed
    },
    data () {
        return {
            activeName: '1'
        }
    }
}
</script>

<style lang="scss" scoped>
.tabsBox {
    padding: 10px 18px;
    overflow: hidden;
}
.el-tabs {
    padding: 10px 10px;
    background-color: #fff !important;
    /deep/.el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll {
        background-color: #fff !important;
    }
}
</style>
