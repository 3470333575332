<template>
    <!-- 上传图片视频 -->
    <div class="content">
        <div class="width:90%">
            <!-- 如果是图片/视频/pdf -->
            <div :class="['videoContent',]"
                 v-if="listType == 'picture-card'">
                <!-- 图片/视频 -->
                <ul v-if="fileList.length>0"
                    class="el-upload-list--picture-card ">
                    <li v-for="(item,index) in fileList"
                        :key="index"
                        :class="['el-upload-list__item', item.status=='success'?'is-success':'']"
                        v-loading="item.status == 'uploading'"
                        v-if="!item.raw.type.includes('pdf')">
                        <img :src="item.url"
                             alt=""
                             v-if="item.raw.type.includes('image')"
                             class="img-avatar">
                        <video :src="item.url"
                               v-if="item.raw.type.includes('video')"
                               class="avatar video-avatar">
                            您的浏览器不支持视频播放
                        </video>
                        <!-- <div v-if="item.raw.type.includes('pdf')" class="pdfBox">
                        <img src="@/assets/pdf.png" alt=""  class="img-avatar">
                    </div> -->
                        <div class="videoPlay_IconBox"
                             v-if="item.raw.type.includes('video')">
                            <i class="el-icon-video-play"></i>
                        </div>
                        <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-check"></i></label>
                        <span class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview"
                                  @click="handlePictureCardPreview(item)">
                                <i :class="[item.raw.type.includes('image')?'el-icon-zoom-in':'el-icon-video-play']"></i>
                            </span>
                            <span class="el-upload-list__item-delete"
                                  @click="imgRemoveMethod(item)"
                                  v-if="!hideDel">
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </li>
                </ul>
                <!-- pdf -->
                <ul class="el-upload-list el-upload-list--text"
                    v-if="fileList.length>0 && accept.includes('pdf')">
                    <div class="li"
                         v-for="(item,index) in fileList"
                         :key="index"
                         v-if="item.raw.type.includes('pdf')">
                        <li :class="['el-upload-list__item',item.status=='success'?'is-success':'',item.status=='uploading'?'is-uploading':'']">
                            <!---->
                            <a class="el-upload-list__item-name"
                               @click="$emit('pdfTitleClickEvemt',item)"><i class="el-icon-document"></i>{{ item.name || item.url | filterPdfName }}</a>
                            <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-circle-check"></i></label>
                            <i class="el-icon-close"
                               @click="imgRemoveMethod(item)"
                               v-if="!hideDel"></i>
                            <i class="el-icon-close-tip">按 delete 键可删除</i>
                            <div role="progressbar"
                                 :aria-valuenow="item.percentage"
                                 aria-valuemin="0"
                                 aria-valuemax="100"
                                 class="el-progress el-progress--line"
                                 v-if="item.status=='uploading'">
                                <div class="el-progress-bar">
                                    <div class="el-progress-bar__outer"
                                         style="height: 2px;">
                                        <div class="el-progress-bar__inner"
                                             :style="{width:`${item.percentage}%`}">
                                            <!---->
                                        </div>
                                    </div>
                                </div>
                                <div class="el-progress__text"
                                     style="font-size: 12.8px;">{{ Math.floor(item.percentage)}}%</div>
                            </div>
                        </li>
                        <label class="iconDownload"
                               @click="handlePictureCardPreview(item)"
                               v-if="item.status=='success'"><i class="el-icon-download "></i></label>
                    </div>
                </ul>
            </div>

            <el-upload :action="action||$uploadUrl+'/oss/upload'"
                       :list-type="listType"
                       :headers="headers"
                       :multiple="multiple"
                       :auto-upload="autoUpload"
                       :on-remove="imgRemoveMethod"
                       :before-upload="beforeUploadImg"
                       :on-preview="handlePictureCardPreview"
                       :on-success="imgSuccessMethod"
                       :on-change="imgListChange"
                       :on-error="imgErrorMethod"
                       :accept="accept"
                       :data="rqData"
                       ref="imgUpload"
                       class="upload-img-form"
                       :file-list="fileList"
                       :limit="limit"
                       :on-exceed="handleExceed"
                       :show-file-list="listType != 'picture-card'"
                       v-if="!noButton">
                <el-button size="small"
                           type="primary"
                           class="uploadBtn"
                           v-if="!noButton"
                           v-bind='{size:"small",type:"primary",...btnAttr}'>{{btnAttr.btnText || '点击上传'}}</el-button>
                <slot name="button"
                      v-else></slot>
            </el-upload>

            <el-dialog v-dialogDrag
                       :close-on-click-modal="false"
                       :visible.sync="dialogVisible"
                       width="60%"
                       append-to-body
                       v-if="listType == 'picture-card'">
                <img width="100%"
                     :src="dialogObj.url"
                     alt=""
                     v-if="dialogObj && dialogObj.raw && dialogObj.raw.type.includes('image') && dialogVisible">
                <div class="videoPreBox"
                     v-else>
                    <video v-if="dialogObj && dialogVisible"
                           :src="dialogObj.url"
                           autoplay
                           class="avatar video-avatar"
                           controls="controls">
                        您的浏览器不支持视频播放
                    </video>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    name: 'upload-file',
    props: {
        // 传进来的文件
        initFileList: {
            type: Array,
            default() {
                return []
            }
        },
        //作为区分的字段
        keyItem: {
            type: Object,
            default() {
                return {}
            }
        },
        btnAttr: {
            type: Object,
            default() {
                return {}
            }
        },
        //是否有按钮
        noButton: {
            type: Boolean,
            default: false,
        },
        //允许上传的文件格式 
        accept: {
            type: String,
            default: '.mp4,.ogg,.flv,.avi,.wmv,.rmvb,.mov,.png,.jpg,.jpeg'
        },
        // 上传的地址
        action: {
            type: String,
            default: ''
        },
        // 允许上传文件的最大个数
        limit: {
            type: Number,
            default: Infinity //无穷大
        },
        // 文件名称
        fileText: {
            type: String,
            default: '文件'
        },
        // 文件大小限制
        fileSize: {
            type: Number,
            default: 10
        },
        // 文件大小限制。但传入的是对象，根据不同类型，不同的限制大小
        fileSizeObj: {
            type: Object,
            default: null,
            // default(){
            //   return {
            //     image:1,
            //     video:2,
            //     pdf:10
            //   }
            // }
        },
        // 上传的图片展示形式
        listType: {
            type: String,
            default: 'picture-card'
        },
        autoUpload: {
            type: Boolean,
            default: false
        },
        rqData: {
            type: Object,
            default() {
                return {}
            }
        },
        headers: {
            type: Object,
            default() {
                const token = this.$store.state.token
                return {
                    Authorization: token.token_type + ' ' + token.access_token
                }
            }
        },
        multiple: {
            type: Boolean,
            default: false
        },
        // 是否隐藏删除按钮
        hideDel: {
            type: Boolean,
            default: false
        }

    },
    data() {
        return {
            dialogVisible: false,
            dialogObj: null,
            fileList: [],
        }
    },
    created() {
        this.fileList = this.initFileList
        // console.log('this===>', this.fileList)
    },
    watch: {
        fileList: {//深度监听，可监听到对象、数组的变化
            handler: function (val, oldVal) {
                // this.$emit('update:initFileList', val,this.keyItem)
                this.$emit('fileListChange', val, this.keyItem)
            },
            deep: true //true 深度监听
        },
        initFileList: {  //修改数据变动没重新赋值问题
            handler: function (val, oldVal) {
                this.fileList = val
            },
            deep: true
        }
    },
    filters: {
        filterPdfName: (value) => {
            let arr = value.split('/')
            let lastName = arr[arr.length - 1]
            let realName = lastName.split('.')[0] + '.pdf'
            return realName
        }
    },
    methods: {
        //= ==== 图片上传相关 =====
        // 开始上传图片
        startUploadImg() {
            let isExist = this.fileList.find(item => item.status != 'success' || !item.status)
            // console.log('--',this.fileList)
            if (!isExist) {
                this.$emit('uploadFinish', this.fileList, this.keyItem)
            } else {
                this.$refs.imgUpload.submit()
            }
        },
        // 展现出来的文件列表
        imgListChange(file, fileList) {
            this.fileList.splice(0, this.fileList.length, ...fileList)
        },
        // 图片预览
        handlePictureCardPreview(file) {
            console.log('图片预览==》', file)
            // this.dialogImageUrl = file.url;
            if (file.raw && file.raw.type && file.raw.type.includes('pdf')) {
                file.response.data.attachmentUrl ? this.util.exportConfig(file.response.data.attachmentUrl,) : this.util.exportConfig(file.response.data,)
            } else {
                this.dialogObj = file
                this.dialogVisible = true
            }
        },
        // 图片删除
        imgRemoveMethod(file, fileList) {
            if (this.fileList && this.fileList.length > 0) {
                const isEixt = this.fileList.findIndex(item => {
                    if (item.uid) {
                        return item.uid == file.uid
                    } else {
                        return false
                    }
                })
                if (isEixt > -1) {
                    this.fileList.splice(isEixt, 1)
                }
            }
            if (this.$refs.imgUpload.uploadFiles && this.$refs.imgUpload.uploadFiles.length > 0) {
                const isEixt = this.$refs.imgUpload.uploadFiles.findIndex(item => {
                    if (item.uid) {
                        return item.uid == file.uid
                    } else {
                        return false
                    }
                })
                if (isEixt > -1) {
                    this.$refs.imgUpload.uploadFiles.splice(isEixt, 1)
                }
            }
        },
        // 停止上传图片
        abortImg() {
            this.$refs.imgUpload.abort()
            this.$emit('abortImg')
        },
        beforeUploadImg(file) {
            // console.log('上传图片之前 file====>', file,this,fileText)
            // //console.log('上传图片之前',this)
            let fileSize = this.fileSize
            let fileText = `文件大小不能超过${fileSize}MB`
            let isfileSize = file.size / 1024 / 1024 < fileSize || file.size / 1024 / 1024 == fileSize
            if (file.type.includes('video')) {
                fileText = `视频大小不能超过${fileSize}MB`
            } else if (file.type.includes('image')) {
                fileText = `图片大小不能超过${fileSize}MB`
            } else {
                fileText = `${this.fileText}大小不能超过${fileSize}MB`
            }
            if (!isfileSize) {
                this.$message.warning(fileText)
                this.abortImg()
                return false
            }
        },
        // 图片上传失败
        imgErrorMethod(err, file, fileList) {
            // //console.log('上传失败==》', file)
            this.$message.error(`${this.fileText}上传失败，请重试`)
            this.abortImg()
            return false
        },
        // 图片上传成功
        imgSuccessMethod(response, file, fileList) {
            // console.log('图片上传成功', response, '目前----',this.fileList )
            let isExist = this.fileList.find(item => item.status != 'success' || response.code !== 200)
            if (!isExist) {
                this.$emit('uploadFinish', this.fileList, this.keyItem, response)
            }
        },
        handleExceed(files) {
            // console.log('图片上传提交限制', files,(files.length + this.fileList.length))
            if ((files.length + this.fileList.length) > this.limit) {
                this.$message.warning(`最多只能上传${this.limit}个文件`)
            }
            return false
        },
    }

}
</script>
<style lang='scss' scoped>
.upload-img-form /deep/ .el-upload--picture-card {
    background-color: initial;
    border: initial;
    border-radius: initial;
    box-sizing: initial;
    width: initial;
    height: initial;
    line-height: initial;
    vertical-align: initial;
    display: inline-block;
    text-align: initial;
}
/deep/ ul.el-upload-list--picture-card {
    display: block;
}
// /deep/ .el-button--primary{
//   background:#0083EE;
// }
.uploadBtn {
    // color:#409EFF;
    // background: transparent;
    // border: none;
    &:hover,
    &:focus {
        // background: transparent;
        // color:#409EFF;
    }
}
.pdfBox {
    padding: 8px;
}
.videoPreBox {
    background: black;
    position: relative;
    display: flex;
    height: 600px;
    > video {
        margin: auto;
        max-height: 100%;
        max-width: 100%;
        // object-fit: fill;
    }
}
.videoContent {
    width: 95%;
    // &.detailForm{
    //   margin-top: 10px;
    // }
    &.detail {
        width: 100%;
        margin-top: 10px;
    }
    &.detail ul li:hover {
        .img-avatar {
            cursor: pointer;
        }
        .videoPlay_IconBox {
            display: flex;
            > i {
                cursor: pointer;
            }
        }
    }
    ul.el-upload-list--picture-card {
        display: flex;
        flex-wrap: wrap;
        li {
            width: 148px;
            height: 148px;
            flex-shrink: 0;
            margin: 0 8px 20px 0;
            border: 1px solid #c0ccda;
            border-radius: 6px;
            overflow: hidden;
            position: relative;
            &:hover {
                .videoPlay_IconBox {
                    display: none;
                }
            }

            .videoPlay_IconBox {
                position: absolute;
                overflow: hidden;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                background: rgba(0, 0, 0, 0.3);
                i {
                    margin: auto;
                    font-size: 50px;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
            .video-avatar {
                width: 100%;
                height: 100%;
                background: black;
            }
            .img-avatar {
                width: 100%;
                height: 100%;
            }
        }
    }
    ul.el-upload-list--text {
        width: 65%;
        margin: 10px 0;
        .li {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            .iconDownload {
                display: block;
                cursor: pointer;
                // position: absolute;
                margin-top: 10px;
                width: 40px;
                top: 0;
                line-height: inherit;
                text-align: center;
                .el-icon-download {
                    text-align: center;
                }
                &:hover {
                    color: #409eff;
                    // font-size: 18px;
                }
            }
        }
    }
}
</style>