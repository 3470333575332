<template>
  <!-- 我的消息 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 -->
  <div class="allwh">
    <div class="allwh-wrapper minWidth1600" style="display: flex;">

      <div class="left-wrapper">
        <ul>
          <li v-for="(item,index) in alarmData" :key="index" class="alarmItem" @click="changeColor(index, item)"
            :class="index == colorIndex ?'setColors':''">
            <img :src='item.src' />
            <span>{{item.name}}</span>
          </li>
        </ul>
      </div>

      <ja-table ref="tst" style="width: 100%;" v-model="filterData" :conditionData="conditionData" :columns="columns"
        :tableData="tableData" :total="total" :loading="loading" @rowEdit="rowEdit"
        @rowDelete="rowDelete" @getInit="getInit" @search="search" >
        <template #alarmType="{row,prop}">
          <div class="alarmColor" :style="{background:alarmColor[colorIndex]}"></div>
          <span style="margin-left: 10px;">{{row[prop]}}</span>
        </template>
      </ja-table>
    </div>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
export default {
  title: '我的消息',
  mixins: [table],
  name: 'index',
  data () {
    return {

      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [ // 表格上方的搜索/选择操作栏
        {
          input_type: 'input',
          key: 'message',
          label: '最新消息',
          attribute: {
            placeholder: '',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'category',
          method: 'change',
          attribute: {
            placeholder: '告警类型',
            size: 'small'
          },
          options: []
        }
      ],
      columns: [ // 表头数据
        {
          label: '告警类型',
          prop: 'alarm_type',
          slotName: 'alarmType'
        },
        {
          label: '告警时间',
          prop: 'alarm_time'
        },
        {
          label: '设备名称',
          prop: 'device_name'
        },
        {
          label: '设备位置',
          prop: 'device_position'
        },
        {
          label: '人员姓名',
          prop: 'person_name'
        },
        {
          label: '处理状态',
          input_type: 'button',
          width: 150,
          attribute: {
            fixed: "right",
            align: 'center'
          },
          list: [{
            text: '确认',
            method: 'rowEdit',
            limit:'user::core::confirm'
          }, {
            text: '忽略',
            method: 'rowDelete',
            limit:'user::core::ignore'
          }]
        }
      ],
      tableData: [{
        alarm_type: '人员黑名单告警',
        alarm_time: '2021-08-08 16:00',
        device_name: '安防摄像机001',
        device_position: 'D栋1层231',
        person_name: '刘三江'
      }, {
        alarm_type: '人员黑名单告警',
        alarm_time: '2021-08-08 16:00',
        device_name: '安防摄像机001',
        device_position: 'D栋1层231',
        person_name: '刘三江'
      }, {
        alarm_type: '人员黑名单告警',
        alarm_time: '2021-08-08 16:00',
        device_name: '安防摄像机001',
        device_position: 'D栋1层231',
        person_name: '刘三江'
      }, {
        alarm_type: '人员黑名单告警',
        alarm_time: '2021-08-08 16:00',
        device_name: '安防摄像机001',
        device_position: 'D栋1层231',
        person_name: '刘三江'
      }],
      loading: false,
      total: 4,
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 告警类型
      alarmData: [{
        src: '/images/alarm/new1.png',
        // src: require('../../../../../public/images/alarm/new1.png'),
        name: '最新消息'
      }, {
        src: '/images/alarm/old.png',
        name: '历史消息'
      }],
      colorIndex: 0,
      alarmColor: ['#D9001B', '#D7D7D7']
    }
  },
  mounted() {
      this.filterData.message = 1
  },
  methods: {
    changeColor: function(i, item) {
      this.colorIndex = i
      if (i == 1) {
        this.conditionData[0].label = '历史消息'
        this.filterData.message = 1
        this.alarmData[0].src = '/images/alarm/new.png'
        this.alarmData[1].src = '/images/alarm/old1.png'
        // this.getInit()
      } else {
        this.conditionData[0].label = '最新消息'
        this.filterData.message = 1
        this.alarmData[0].src = '/images/alarm/new1.png'
        this.alarmData[1].src = '/images/alarm/old.png'
        // this.getInit()
      }
    },
    getInit() {
      // this.loading = true
      // const paramObj = this.util.paramChange(this.filterData, this.queryTableId)
      // queryAll(this.queryTableId, paramObj).then(res => {
      //   this.loading = false
      //   this.tableData = res.data.data
      //   this.total = res.data.total
      // })
      this.loading = false
    },
    rowEdit: function(row) { // 确认
      // const paramJson = {}
      // paramJson.id = row.id
      this.$confirm('您是否确认收到该条告警，并尽快去处理？', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '忽略'
      }).then(res => {
        // paramJson.alarmStatus = 1
        // updateStatus(paramJson).then(res => {
        //   this.$message.success(res.msg)
        //   this.getInit()
        // })
      })
    },
    rowDelete: function(row) { // 忽略
      // const paramJson = {}
      // paramJson.id = row.id
      this.$confirm('您是否确认收到该条告警，并尽快去处理？', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '忽略'
      }).then(res => {
        // paramJson.alarmStatus = 2
        // updateStatus(paramJson).then(res => {
        //   this.$message.success(res.msg)
        //   this.getInit()
        // })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/style/mixin.scss';

  .left-wrapper {
    width: 15%;
    height: calc(100% - 75px);
    min-width: 230px;
    padding: 0 0 0 15px;
    box-sizing: border-box;

    ul {
      height: calc(100% - 75px);
      margin-top: 15px;
      padding-top: 40px;
      border: 1px solid $theme_bordercolor_F0F2F5;
      background-color: $theme_background_fff;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;

      .alarmItem {
        text-align: center;
        height: 40px;
        line-height: 40px;
        margin-bottom: 20px;
        cursor: pointer;
        img {
          width: 20px;
          vertical-align: middle;
        }
        span{
          margin-left: 15px;
          font-size: 16px;
        }
      }

      .setColors {
        color: $theme_color_0083ee;
      }

    }
  }

  .alarmColor {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    vertical-align: middle;
    margin-top: -3px;
  }
</style>
