<template>
  <div class="titleBox">
    <div class="left">{{title}}</div>
    <div class="right">
      <slot name="right"></slot>
      <div class="link" v-if="isMore" @click="turnMore()">查看详情>></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '默认标题'
    },
    isRight: Boolean,
    isMore: Boolean
  },
  methods: {
    turnMore() {
      this.$emit('turnMore')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/theme.scss';

.titleBox {
  display: flex;
  align-items: center;
  height: 60px;
  margin: 0 30px 0 30px;
  justify-content: space-between;
  .link {
    font-size: 14px;
    color: $theme_color_333;
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
  .left {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>