<template>
    <el-dialog :visible.sync="show" width="40%" title="手动结束">
        <ja-form :rules="rules" allDisabled v-model="rowForm" :formArray="rowFormData" v-if="show">
        </ja-form>
        <div class="rightButton">
            <el-button size="mini" @click=" show = false">取消</el-button>
            <el-button type="primary" size="mini" @click="endEvent">保存</el-button>
        </div>
    </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
import { endManually } from '@/api'
export default {
    components: {
        JaForm
    },
    inject: ['getInit'],
    data() {
        return {
            rules: {},
            rowFormData: [
                {
                    input_type: 'input',
                    label: '公司名称',
                    key: 'tenantName',
                    labelWidth: '110px',
                    placeholder: ' '
                },
                {
                    input_type: 'input',
                    label: '店铺名称',
                    key: 'storeName',
                    labelWidth: '110px',
                    placeholder: ' '
                },
                {
                    input_type: 'input',
                    label: '车牌号码',
                    key: 'carNumber',
                    labelWidth: '110px',
                    placeholder: ' '
                },
                {
                    input_type: 'input',
                    label: '服务类型',
                    key: 'serviceTypeName',
                    labelWidth: '110px',
                    placeholder: ' '
                },
                {
                    input_type: 'input',
                    label: '申请人',
                    key: 'applicant',
                    labelWidth: '110px',
                    placeholder: ' '
                },
                {
                    input_type: 'input',
                    label: '联系方式',
                    key: 'contactNumber',
                    labelWidth: '110px',
                    placeholder: ' '
                },
                {
                    input_type: 'input',
                    label: '服务开始时间',
                    key: 'serviceStartTime',
                    labelWidth: '110px',
                    placeholder: ' '
                },
                {
                    input_type: 'input',
                    label: '服务结束时间',
                    key: 'serviceEndTime',
                    labelWidth: '110px',
                    placeholder: ' '
                }
            ],
            show: false,
            rowForm: {}
        }
    },
    methods: {
        init(row) {
            this.show = true
            this.rowForm = { ...row }
        },
        endEvent() {
            this.$confirm('是否确定手动结束？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.show = false
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    endManually(this.rowForm.id).then((res) => {
                        loading.close();
                        this.$message({
                            type: 'success',
                            message: '设置成功!'
                        })
                        this.getInit()
                    }).catch(err=>{
                        loading.close()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消设置'
                    })
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.rightButton {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
</style>