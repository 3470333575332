<template>
  <div class="titleBox">
    <div class="left">
      <p>{{title}}</p>
      <div class="selections" v-if="Object.keys(selectObj).length>0">
        <el-select v-model="selectValue" placeholder="年份" class="selectBox" :style="{width:selectObj.width}" @change="onEvent(selectObj.method,selectValue)">
          <el-option v-for="item in selectObj.options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <slot name="left"></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '默认标题'
    },
    selectObj: {
      type: Object,
      default() {
        return {}
      }
    },
    value: String
  },
  data() {
    return {
      selectValue: ''
    }
  },
  methods: {
    onEvent(method, val) {
      if (method) {
        this.$emit(method, val)
      }
    }
  },
  watch: {
    value(v) {
      this.selectValue = v
    },
    selectValue(v) {
      this.$emit('input', v)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/theme.scss';

.titleBox {
  display: flex;
  align-items: center;
  margin: 20px 15px 10px 30px;
  justify-content: space-between;
  .selections {
    .selectBox {
      width: 120px;
    }
    .typeSelect {
      margin-left: 20px;
    }
  }
  .left {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    > p {
      margin-right: 30px;
    }
  }
}
</style>