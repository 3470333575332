<template>
    <div>
        <ja-table v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @open="open"
                  @close="close"
                  @showDetails="showDetails"
                  @resetUser="resetUser">
        </ja-table>
        <show-details ref="detail"></show-details>
    </div>
</template> 
<script>
import { getResidentAccountList, accountOpening, accountClosing, residentResetPassword } from '@/api'
import table from "@/views/mixins/table"
import ShowDetails from './components/showDetails.vue'
export default {
    mixins: [table],
    title: '驻户账号列表',
    components: {
        ShowDetails,
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small', }, styleWidth: '200px' },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '店铺名称', size: 'small', }, styleWidth: '200px' },
                { input_type: 'input', key: 'username', attribute: { placeholder: '负责人', size: 'small', }, styleWidth: '200px' },
                { input_type: 'select', key: 'status', attribute: { placeholder: '状态', size: 'small', }, styleWidth: '200px', options: [] },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', },
                { label: '店铺名称', prop: 'roomName', },
                { label: '负责人', prop: 'username', },
                { label: '登录用户名', prop: 'loginName', },
                { label: '联系电话', prop: 'mobile', },
                { label: '登录权限', prop: 'loginStatusLabel', },
                { label: '帐号状态', prop: 'statusLabel', },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: 200,
                    list: [
                        { text: '启用', method: 'open', limit: 'residentAccountList::index::open' },
                        { text: '禁用', method: 'close', limit: 'residentAccountList::index::close' },
                        { text: '查看', method: 'showDetails', limit: 'residentAccountList::index::showDetails' },
                        { text: '重置密码', method: 'resetUser', limit: 'residentAccountList::index::resetUser' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            results: null,
        }
    },
    mounted() {
        let length = this.conditionData.findIndex(item => item.key == 'status')
        this.util.rqOptionsList({
            who: [this.conditionData[length]],
            rqUrl: '/sys/dictData/employee_status',
            labelName: 'dictLabel',
            valueName: 'dictValue',
            resRule: 'data'
        })
    },
    methods: {
        getInit() {
            getResidentAccountList(this.filterData).then(res => {
                this.tableData = res.data.data
                this.tableData.map(item => {
                    item.loginStatusLabel = ['开启', '关闭'][item.loginStatus]
                })
                this.loading = false
                this.total = res.data.total
            })
        },
        open(row) {
            this.$confirm('是否确定启用？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await accountOpening(row.id)
                this.$message.success(res.msg)
                this.getInit()
            }).catch((err) => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        close(row) {
            this.$confirm('关闭后该人员将被限制办理业务，是否确定关闭？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await accountClosing(row.id)
                this.$message.success(res.msg)
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        showDetails(row) {
            this.$refs.detail.init(row)
        },
        resetUser(row) {
            this.$confirm('是否重置为初始密码？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await residentResetPassword(row.id)
                this.$message.success(res.msg)
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        editDataFormat(row, btn) {
            if (
                (row.status == 1 && (btn.text == '启用' || btn.text == '重置密码')) ||
                (row.status == 0 && (btn.text == '禁用' || btn.text == '重置密码')) ||
                (row.status == 2 && btn.text == '查看')
            ) {
                return true
            }
        }
    }
}
</script>