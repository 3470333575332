
<template>
    <div>
        <el-radio-group v-model="radio" @change="radioChange">
            <el-radio-button label="1">待租</el-radio-button>
            <el-radio-button label="2">已租</el-radio-button>
        </el-radio-group>
        <customize-tabel ref="tst" v-model="filterData" :columns="columns" :tableData="tableData" :conditionData="conditionData" :total="total" :showSelect="!isOnePerson?true:false" :loading="loading" :border="true" index_label="序号" :showIndex="true" @handleSelectItem="handleSelectItem" @handleSelectionAll="handleSelectionAll" @selectRow="selectRow" @getInit="getInit2" @handleCurrentChange="handleCurrentChange">
            <template #operateSlot="{scope}">
                <el-radio v-model="radio" :label="scope.id" @change="selectRow(scope)" value=""></el-radio>
            </template>
        </customize-tabel>
        <el-button type="primary" @click="submit()">确定添加</el-button>
    </div>
</template>
<script>
// import table from '@/views/mixins/table'
import customizeTabel from './customizeTabel.vue'
import selectTable from './selectTable'
import { getRentOutlist,getRentalUnitList } from '@/api'

export default {
    title: '添加空间信息',
    mixins: [selectTable],
    name: 'index',
    components: {
        customizeTabel,
    },
    data () {
        return {
            rq_Method2: getRentOutlist,
            rq_Method1: getRentalUnitList,
            columns: [
                { label: '租户名称', prop: 'spaceCode', hide: true },
                { label: '租赁单元名称', prop: 'roomName' },
                { label: '套内使用面积', prop: 'totalArea' },
                { label: '状态', prop: 'statusValue' },
            ],
        }
    }
}
</script>
<style lang="scss" scoped>
.el-radio-group {
    margin-left: 30px;
}
.el-button {
    margin: 0 auto;
    display: block;
}
</style>
