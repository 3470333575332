<template>
    <div class="">
        <ja-form
            :rules="{}"
            ref="jaForm"
            v-model="rowForm"
            v-on="$listeners"
            :noButton="true"
            :formArray="rowFormData"
            >
        </ja-form>
    </div>
</template>
<script>
export default {
    props: ['rowFormData', 'rowForm', 'errorObj', 'isClickSave'],
    data () {
        return {
            // rowForm:
        }
    }
}
</script>
<style lang="scss" scoped>
.errorText{
    margin:0 auto;
    color:#f56c6c;
    font-size: 13px;
}
</style>
