<template>
    <el-dialog :visible.sync="show"
               width="40%"
               title="查看">
        <ja-form :rules="rules"
                 allDisabled
                 v-model="rowForm"
                 :formArray="rowFormData"
                 v-if="show">
        </ja-form>
    </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
    components: {
        JaForm,
    },
    data() {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'input', label: '公司名称', key: 'tenantName', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '店铺名称', key: 'storeName', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '车牌号码', key: 'carNumber', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '服务类型', key: 'serviceTypeName', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '申请人', key: 'applicant', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '联系方式', key: 'contactNumber', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '服务开始时间', key: 'serviceStartTime', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '服务结束时间', key: 'serviceEndTime', labelWidth: "110px", placeholder: ' ' }
            ],
            show: false,
            rowForm: {},
        }
    },
    methods: {
        init(row, status = 1) {
            this.show = true
            this.rowForm = { ...row }
            let lengthArr = []
            this.rowFormData.map((e, i) => {
                if (e.key === 'serviceStartTime' || e.key === 'serviceEndTime') lengthArr.push(i)
            })
            if (status == 2) {
                console.log(lengthArr);
                this.rowFormData[lengthArr[0]].hide = true
                this.rowFormData[lengthArr[1]].hide = true
            }
        }
    }
}
</script>