<template>
  <!-- 表格 -->
  <div class="content">
    <div  class="title">
        实收款进度
    </div>
    <div class="echart" >
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import { getFinanceStatistics } from '@/api'

export default {
  components: {
    JaInitChart,
  },
  data() {
    return {
      options: null,
      financeData:{}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init(){
      getFinanceStatistics().then(res=>{
        this.financeData=res.data;
        this.setOptions(res.data)
      })
    },
    setOptions(data) {
      let percentage=(data.amountWriteOffSum/data.amountReceivableSum)?(data.amountWriteOffSum/data.amountReceivableSum).toFixed(2)*100: 0
      let  dataStyle = {
          normal: {
              label: {
                  show: false
              },
              labelLine: {
                  show: false
              },
              // shadowBlur: 40,
              // shadowColor: 'rgba(40, 40, 40, 0.5)',
          }
      };
      var placeHolderStyle = {
              normal: {
                  color: '#eee',//未完成的圆环的颜色
                  label: {
                      show: false
                  },
                  labelLine: {
                      show: false
                  }
              },
              emphasis: {
                  color: 'rgba(44,59,70,1)'//未完成的圆环的颜色
              }
          };
      this.options =  {
          title: {
              text: percentage+'%',
              x: 'center',
              y: 'center',
               top: '33%',
              textStyle: {
                  fontWeight: 'normal',
                  color: "#F59A23",
                  fontSize: 40
              }
          },
          grid: {
              top: '8%',
              bottom: '28%',
          },
          color: ['#F59A23', '#313443', '#fff'],
          tooltip: {
              show: true,
              formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
              show: false,
              itemGap: 12,
              data: ['01', '02']
          },
          toolbox: {
              show: false,
              feature: {
                  mark: {
                      show: true
                  },
                  dataView: {
                      show: true,
                      readOnly: false
                  },
                  restore: {
                      show: true
                  },
                  saveAsImage: {
                      show: true
                  }
              }
          },
          series: [{
                  name: '实收款进度',
                  type: 'pie',
                  clockWise: false,
                  radius: ['54%', '62%'],
                  center: ['50%', '40%'],
                  itemStyle: dataStyle,
                  hoverAnimation: false,

                  data: [{
                          value:data.amountWriteOffSum,
                          name: '实收总金额'
                      }, {
                          value: data.amountReceivableSum-data.amountWriteOffSum,
                          name: '未收总金额',
                          itemStyle: placeHolderStyle
                      }

                  ]
              }, 
          ]
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
}
.echart {
  height: 100%;
  width: 100%;
}
.title{
        font-size: 22px;
        font-weight: bold;
        margin: 20px 0;
        color: #333;
        text-align: center;
    }
</style>