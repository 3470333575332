<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   title="查看"
                   :visible.sync="show"
                   width="50%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     v-model="rowFormData"
                     v-loading="addLoading"
                     allDisabled
                     :rules="rules"
                     :formArray="rowForm"
                     @reset="show = false">

            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    inject: ['getInit'],
    data() {
        return {
            rules: {},
            rowForm: [
                { col: 11, input_type: 'input', label: '驻户名称', key: 'tenantName', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'select', label: '店铺名称', key: 'roomName', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'input', label: '负责人', key: 'username', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'input', label: '登录用户名', key: 'loginName', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'input', label: '联系电话', key: 'mobile', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'input', label: '登录权限', key: 'loginStatusLabel', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'input', label: '帐号状态', key: 'statusLabel', labelWidth: '120px', maxlength: 30 },

            ],
            rowFormData: {},
            title: '查看',
            show: false,
        }
    },
    methods: {
        init(row) {
            this.show = true
            this.rowFormData = { ...row }
        },
    }
}
</script>