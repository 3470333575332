<template>
    <div>
        <ja-table
            ref="tst"
            v-model="filterData"
            :conditionData="conditionData"
            :columns="columns"
            :tableData="tableData"
            :total="total"
            :loading="loading"
            :editDataFormat="editDataFormat"
            @getInit="getInit"
            @search="search"
            @reset="reset"
            @lookRoom="lookRoom"
        >
        </ja-table>
        <show-passed ref="showPassed"></show-passed>
    </div>
</template>

<script>
import { getVehicleTrafficList } from '@/api'
import showPassed from './showPassed.vue'
import table from '@/views/mixins/table'
import { myMinxin } from '../mixin/index'

export default {
    title: '临时车辆',
    mixins: [table, myMinxin],
    name: 'temporaryVehicle',
    components: {
        showPassed,
    },
    created() {
        this.setButtonLimit('passRecord::temporaryVehicle::show')
    },
    methods: {
        getInit() {
            let obj = this.getFilterData(this.filterData)
            this.loading = true
            // 获取楼层信息列表
            getVehicleTrafficList({...obj,type: 3})
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
                .catch((err) => {
                    this.loading = false
                })
        }
    }
}
</script>

