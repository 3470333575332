import http from './plugin/ajax'

// 装修申请
export const getDecApplyInfoList = (data) => http.post('/decApplyInfo/list', data)// 查询
export const editDecApplyInfoList = (data) => http.put('/decApplyInfo', data)// 通过
// 装修验收记录
export const getDecSignInRecordList = (data) => http.post('/decSignInRecord/list', data)// 查询
// 装修巡查记录
export const getDecPatrolRecordList = (data) => http.post('/decPatrolRecord/list', data)// 查询
export const getPatrolLists = (data) => http.get(`/decPatrolRecord/selectDecPatrolRecordInspectItemsInfo/${data.patrolRecordId}/${data.patrolType}`)// 获取巡查列表

// 装修巡查记录
export const getDecInspectItemsInfoList = (data) => http.post('/decInspectItemsInfo/list', data)// 查询
export const editDecInspectItemsInfo = (data) => http.put('/decInspectItemsInfo', data)// 通过
export const addDecInspectItemsInfo = (data) => http.post('/decInspectItemsInfo', data)// 新增
export const deleteDecInspectItemsInfo = (data) => http.delete('/decInspectItemsInfo/' + data)// 删除
