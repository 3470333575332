<template>
    <div>
        <div>
            <el-row v-for="(item, num) in fromArr"
                    :key="updateTime"
                    :class="allDisabled ? 'marginBox' : ''">
                <el-col :span="24"
                        v-show="!allDisabled">

                    <div class="rightButtom">
                        <el-button type="success"
                                   :disabled="false"
                                   size="mini"
                                   icon="el-icon-plus"
                                   circle
                                   @click="addFrom(num)"></el-button>
                        <el-button type="danger"
                                   :disabled="false"
                                   size="mini"
                                   icon="el-icon-minus"
                                   circle
                                   v-show="num > 0"
                                   @click="deleteFrom(num)"></el-button>
                    </div>
                </el-col>
                <el-col class="fromContent"
                        :span="24">
                    <customize-from noButton
                                    v-model="shopDTOList[num]"
                                    :rules="rules"
                                    :key="num"
                                    ref="customizeFrom"
                                    :formArray="rowFormData"
                                    :customizeRef="item"
                                    :allDisabled="allDisabled"
                                    :selectOptions="selectOptions"
                                    @selectChang="(row, val)=>selectChang(row, val,num)"></customize-from>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import CustomizeFrom from './customizeFrom.vue'
import { getResidentRentalUnitList } from '@/api'
export default {
    components: {
        CustomizeFrom
    },
    props: {
        shopDTOList: {
            type: Array,
            default: function () {
                return []
            }
        },
        state: {
            type: String,
            default: '1'
        },
        allDisabled: {
            type: Boolean,
            default: false
        },
        tenantId: {
            type: [String,Number],
            default: null
        }
    },
    data() {
        return {
            rowFormData: [
                {
                    input_type: 'select',
                    label: '店铺名称',
                    key: 'deptId',
                    col: 10,
                    labelWidth: '120px',
                    method: 'selectChang'

                },
                {
                    input_type: 'input',
                    label: '负责人手机号',
                    key: 'mobile',
                    col: 10,
                    labelWidth: '120px',
                },
                {
                    input_type: 'input',
                    label: '负责人姓名',
                    key: 'username',
                    col: 10,
                    labelWidth: '120px',
                }
            ],
            rules: {
                deptId: [
                    {
                        required: true,
                        trigger: 'blur',
                        message:'请选择店铺名称'
                    }
                ],
                username: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.util.formRules.checkText({
                            textMessage: '负责人姓名'
                        })
                    }
                ],
                mobile: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.util.formRules.checkPhone()
                    }
                ]
            },
            fromArr: [],
            selectData: [],
            selectOptions: [],
            updateTime: new Date()
        }
    },
    watch: {
        selectData: {
            handler(val) {
                val.map(item => {
                    if (item) {
                        this.selectOptions.map(val => {
                            if (val.value == item) {
                                //锁定该数据，不可选
                                val.disabled = true
                            }
                        })
                    }
                })
                this.updateTime = new Date()
            },
            deep: true
        }
    },
    created () {
        this.selectData=this.shopDTOList.map(item=>{
            return item.deptId
        })
    },
    async mounted() {
        if (this.state !== '1') {
            this.fromArr = []
            this.shopDTOList.map((e, i) => {
                this.fromArr.push(`from${i}`)
            })
            await getResidentRentalUnitList(this.tenantId).then((res => {
                let arr = []
                res.data.map(item => {
                    item.roomInfo.map(room => {
                        let obj = { label: room.roomName, value: room.roomId,mobile:room.mobile, username:room.username,disabled:true}
                        arr.push(obj)
                    })
                })
                this.selectOptions = [...arr]
            }))
        }
    },
    methods: {
        submitForm() {
            //校验栈
            let arr = []
            for (let i = 0; i < this.fromArr.length; i++) {
                arr.push(i)
                let self = this.$refs.customizeFrom[i]
                let name = this.fromArr[i]
                self.$refs[name].validate((valid) => {
                    if (valid) {
                        arr.pop()
                    }
                })
            }
            if (arr.length > 0) {
                return false
            } else {
                return true
            }
        },
        addFrom() {
            if (this.fromArr.length > this.selectOptions.length - 1) return this.$message.error('已达到店铺最高数量，不可再新增')
            let name = `from${this.fromArr.length}`
            this.fromArr.push(name)
            this.shopDTOList.push({})
            this.updateTime = new Date()
        },
        deleteFrom(num) {
            let deptId = this.shopDTOList[num].deptId
            if (deptId) {
                let dLength = this.selectData.findIndex(item => item == deptId)
                if (dLength >= 0) {
                    //删除选中记录
                    this.selectData.splice(dLength, 1)
                    //释放锁，让其它组件可选，并更新组件
                    this.delLock(deptId)
                }
            }
            this.$delete(this.fromArr, num)
            this.$delete(this.shopDTOList, num)

        },
        selectChang(row, val, num) {
            let arr = this.selectData
            //先判断是否存在
            if (arr[num]) {
                //存在先释放上一个
                this.delLock(arr[num])
            }
            let index=this.selectOptions.findIndex(item=>item.value==val)
            this.$set(this.shopDTOList[num],'username',this.selectOptions[index].username)
            this.$set(this.shopDTOList[num],'mobile',this.selectOptions[index].mobile)
            this.addLock(arr, val, num)


        },
        addLock(arr, val, num) {
            arr[num] = val
            arr.map(item => {
                if (item) {
                    this.selectOptions.map(val => {
                        if (val.value == item) {
                            //锁定该数据，不可选
                            val.disabled = true
                        }
                    })
                }
            })
            this.updateTime = new Date()
            this.selectData = arr
        },
        delLock(val) {
            this.selectOptions.map(item => {
                if (item.value == val) {
                    //锁定该数据，不可选
                    item.disabled = false
                }
            })
            this.updateTime = new Date()
        }
    }
}
</script>
<style lang="scss" scoped>
.rightButtom {
    float: right;
}
.fromContent {
    border: 1px solid rgba(215, 215, 215, 1);
    box-sizing: border-box;
    padding: 20px 20px;
}
.marginBox {
    margin-bottom: 30px;
}
</style>
