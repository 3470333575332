
<template>
  <div>
    <ja-table
      ref="tst"
      v-model="filterData"
      :columns="columns"
      :tableData="tableData"
      :conditionData="conditionData"
      :total="total"
      :showSelect="!isOnePerson ? true : false"
      :loading="loading"
      :border="true"
      index_label="序号"
      :showIndex="false"
      @handleSelectItem="handleSelectItem"
      @handleSelectionAll="handleSelectionAll"
      @selectRow="selectRow"
      @getInit="getInit2"
      @search="search"
      @reset="reset"
    >
      <template #right>
        <el-button
          type="primary"
          @click="submit()"
          class="submit"
          v-if="!isOnePerson"
          >确认添加</el-button
        >
      </template>
      <template #isAll>
        <el-checkbox
          v-model="checked"
          @change="selectAllPerson"
          v-if="isShowSelectAll"
          >全选</el-checkbox
        >
      </template>
    </ja-table>
    <div class="botttom">
      已选择：{{
        checked && isShowSelectAll
          ? except_person_name
            ? `所有人，除了“${except_person_name}”`
            : '所有人'
          : NowPersons2
      }}
    </div>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import selectTable from '@/views/mixins/selectTable'
import { getRentalUnitList } from '@/api'

export default {
  title: '选择房间',
  mixins: [table, selectTable],
  name: 'index',
  data() {
    let conditionData_own = [
      {
        input_type: 'input',
        key: 'roomName',
        attribute: { placeholder: '租赁单元名称', size: 'small' }
      },
      {
        input_type: 'select',
        key: 'buildingId',
        attribute: { placeholder: '楼栋', size: 'small' },
        options: [],
        styleWidth: '100px',
        method: 'selectFun',
        relevance: {
          sonKey: 'floorId',
          This: this,
          rqParam: {
            rqUrl: '/floor/all',
            rqData: () => {
              return { params: { buildingId: this.filterData.buildingId } }
            },
            labelName: 'floorName',
            valueName: 'floorId',
            resRule: 'data'
          } //
        }
      },
      {
        input_type: 'select',
        key: 'floorId',
        attribute: { placeholder: '楼层', size: 'small' },
        options: [],
        styleWidth: '100px'
      }
    ]
    let columns_own = [
      { label: '楼栋', prop: 'buildingName' },
      { label: '楼层', prop: 'floorName' },
      { label: '租赁单元名称', prop: 'roomName' },
      { label: '租赁单元类型', prop: 'roomTypeValue' },
      { label: '套内使用面积', prop: 'totalArea' }
    ]
    return {
      rq_Method: getRentalUnitList,
      keyName: 'roomName',
      conditionData: [
        ...conditionData_own,
        { normalBtn: 'search' },
        { normalBtn: 'reset' },
        { slotName: 'isAll' }
      ],
      columns: [
        ...columns_own,
        {
          label: '操作',
          input_type: 'button',
          width: 120,
          attribute: { fixed: 'right', align: 'center' },
          list: [{ text: '选择', method: 'selectRow' }]
        }
      ]
    }
  },
  created() {
    this.util.rqOptionsList({
      who: [this.conditionData[1]],
      rqUrl: '/building/list',
      rqData: { pageIndex: 1, pageSize: 200 },
      rqMethod: 'post',
      labelName: 'buildingName',
      valueName: 'buildingId',
      resRule: 'data'
    })
  },
  methods: {
    getInit() {
      this.loading = true
      let rqData = this.rqParmas()
      console.log(this.rqParmas())
      this.rq_Method(rqData).then((res) => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    rqParmas() {
      return {
        pageSize: this.filterData.pageSize,
        pageIndex: this.filterData.pageIndex,
        status: 0,
        roomName: this.filterData.roomName,
        buildingId: this.filterData.buildingId,
        floorId: this.filterData.floorId
      }
    }
  }
}
</script>