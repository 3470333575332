<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="45%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            value: null,
            rules: {},
            rowFormData: [
                { input_type: 'input', key: 'contacts', label: '联系人', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'customerContact', label: '客户联系方式', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'clientCompany', label: '客户公司', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'customerBusinessScope', label: '客户业务范围', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'select', key: 'customerSourceName', label: '客户来源', labelWidth: '130px', col: 11, options: [{ label: '58', value: 1 }, { label: '18', value: 2 }], placeholder: ' ' },
                { input_type: 'input', key: 'referrerName', label: '推荐人姓名', labelWidth: '130px', col: 11, hide: true, placeholder: ' ' },
                { input_type: 'input', key: 'referrerContact', label: '推荐人联系方式', labelWidth: '130px', col: 11, hide: true, placeholder: ' ' },
                { input_type: 'input', key: 'intermediaryOrgan', label: '推荐人/中介公司', labelWidth: '130px', col: 11, hide: true, placeholder: ' ' },
                { input_type: 'select', key: 'followUpPeopleDeptName', styleWidth: '6vw', label: '跟进人', labelWidth: '130px', col: 8, options: [], placeholder: ' ', },
                { input_type: 'select', key: 'followUpPeopleName', styleWidth: '6vw', col: 3, options: [], placeholder: ' ' },
                { input_type: 'input', key: 'followUpPeopleContact', label: '跟进人联系方式', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', type: 'textarea', key: 'remark', label: '备注', labelWidth: '130px', col: 24, placeholder: ' ' },
                { input_type: 'input', key: 'createBy', label: '操作人', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'updateTime', label: '操作时间', labelWidth: '130px', col: 11, placeholder: ' ' },
            ],
            title: '查看客户信息',
            show: false,
            isAdd: false,
            allDisabled: false,
            hideList: ['referrerName', 'referrerContact', 'intermediaryOrgan'],
            hideIds: [3, 4, 5],
            rowForm: {}
        }
    },
    methods: {
        async init(row) {
            this.rowForm = { ...row }
            if (!(row.customerSource in this.hideIds)) {
                this.rowFormData.map(item => {
                    if (this.hideList.indexOf(item.key) >= 0) {
                        item.hide = false
                    }
                })
            } else {
                this.rowFormData.map(item => {
                    if (this.hideList.indexOf(item.key) >= 0) {
                        item.hide = true
                    }
                })
            }
            this.show = true
            this.isAdd = false
            this.allDisabled = true

        },
        close(){},

    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>