<template>
    <div :class="['app-container',isFromForm?'fromForm formTable':'formTable']">
        <div class="table">
            <el-table class="ja-table" highlight-current-row :show-summary="showSummary" :summary-method="getSummaries" v-loading="loading" :data="tableData" :row-key="rowKey" :max-height="table_MaxHeight" :tree-props="treeProps" @select="handleSelectItem" @select-all="handleSelectionAll" @selection-change="handleSelectionChange" @current-change="handleCurrentChange" v-bind="tableAttribute || {}" v-on="tableEvents || {}" ref="jaTable">
                <el-table-column type="selection" v-if="showSelect"></el-table-column>
                <el-table-column type="index" :align="align || 'center'" :label="index_label?index_label:'序号'" v-if="showIndex" :width="60" :index="indexMethod" :fixed="indexFiexd"></el-table-column>
                <el-table-column v-for="(item,index) in columns" :key="index" :label="item.label" :prop="item.prop" :width="item.width" :align="item.align?item.align:'center'" :fixed="item.list?'right':item.fixed" v-bind="{showOverflowTooltip:true,align:'center',...item.attribute}" v-on="item.events || {}" v-if="!item.hide">
                    <el-table-column v-for="subCol in item.children" :key="subCol.prop" :prop="subCol.prop" :label="subCol.label" :width="subCol.width" :align="item.align?item.align:'center'" :header-align="item.align?item.align:'center'" v-bind="item.columnAttrs || {}" show-overflow-tooltip>

                        <template v-slot:header="{column}">
                            <span>{{ column.label }}</span>
                        </template>

                        <template v-slot="scope">
                            <div v-if="subCol.type === 'node'" class="OPZTable__cell__node">
                                <slot :name="`cell${item.prop}`" v-bind="scope" />
                            </div>
                        </template>
                    </el-table-column>

                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div class="ja-pagination" v-if="showPage">
            <div>
                <slot name="bottomLeft"></slot>
            </div>
            <el-pagination :class="{'hidden':pgHide}" :background="pgBackground" :pager-count="pagerCount" :current-page.sync="nowFilterData.pageIndex" :page-size.sync="nowFilterData.pageSize" :layout="layout" :page-sizes="pageSizes" :total="total" @size-change="sizeChange" @current-change="currentChage" v-bind="pgAttribute" v-on="pgEvents">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: 'customize-tabel',
    data () {
        return {
            nowFilterData: this.value,
        }
    },
    props: {
        // 是否有分页
        showPage: {
            type: Boolean,
            default: true
        },
        // 条件数组

        align: {
            type: String,
            default: 'center'
        },
        // 条件选定参数
        value: {
            type: Object,
            default: function () {
                return {
                    pageIndex: 1,
                    pageSize: 5
                }
            }
        },

        // 是否显示序号
        showIndex: {
            type: Boolean,
            default: true
        },
        // table加载动画
        loading: {
            type: Boolean,
            ault: false
        },


        border: {
            type: Boolean,
            default: false
        },

        columns: {
            type: Array,
            default: () => []
        },
        // table数据
        tableData: {
            type: Array,
            default: () => []
        },

        pgHide: {
            type: Boolean,
            default: false
        },
        // 是否为分页按钮添加背景色
        pgBackground: {
            type: Boolean,
            default: true
        },
        // table数据总数
        total: {
            required: false,
            type: Number
        },
        // 每页显示条目个数
        pageSizes: {
            type: Array,
            default () {
                return [5]
            }
        },
        // 组件布局，子组件名用逗号分隔
        layout: {
            type: String,
            default: 'total, sizes, prev, pager, next, jumper'
        },
        //最大页码
        pagerCount: {
            type: String,
            default: '7'
        },
        // 表格的最大高度
        table_MaxHeight: {
            type: Number
        },
    },
    methods: {
        // 序列号
        indexMethod (index) {
            const a = this.nowFilterData.pageIndex
            const b = this.nowFilterData.pageSize
            return (a - 1) * b + (index + 1)
        },


        // 每页显示条目个数
        sizeChange (val) {
            this.nowFilterData = {
                ...this.nowFilterData,
                pageIndex: 1,
                pageSize: val
            }
            setTimeout(() => {
                this.$emit('getInit', this.nowFilterData)
            }, 0)
        },
        // 勾选种某一行
        handleSelectItem (selection, row) {
            this.$emit('handleSelectItem', selection, row)
        },
        handleCurrentChange (currentRow, oldCurrentRow) {
            this.$emit('handleCurrentChange', currentRow, oldCurrentRow)
        },
        // 选中事件
        handleSelectionChange (val) {
            this.$emit('handleSelectionChange', val)
        },
        handleSelectionAll (val) {
            this.$emit('handleSelectionAll', val)
        },
        // 改变当前页
        currentChage (val) {
            console.log(val);
            this.nowFilterData = {
                ...this.nowFilterData,
                pageIndex: val
            }
            setTimeout(() => {
                this.$emit('getInit', this.nowFilterData)
            }, 0)
        },
        // table的某项事件
        valueEvent (row, method, scope, button) {
            method = method || 'valueEvent'
            this.$emit(method, row, scope, button)
        },
        // 是否需要显示label
        hasLabel (item) {
            // 有label并且不是按钮时
            if (item.label && item.input_type !== 'button') {
                // 传入不需要的label时
                if (item.noLabel) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },


    },
    watch: {
        value (val) {
            this.nowFilterData = val
        },
        nowFilterData (val) {
            this.$emit('input', val)
        }
    },
    filters: {
        optionsFormat: (val, item) => {
            if (item.options && item.options.length > 0) {
                let obj = {}
                item.options.forEach(item => [
                    obj[item.value] = item.label
                ])
                return obj[val]
            } else {
                return val
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';
/deep/ .el-input__prefix,
/deep/ .el-input__suffix {
    top: -2px;
}
/deep/ .el-table__empty-block {
    border-bottom: 1px solid #ebeef5 !important;
}
.ja-pagination {
    display: flex;
    padding: 10px 15px;
    // margin-top: 15px;
    margin: 0px 15px;
    background-color: $theme-color;
    justify-content: space-between;
}
.form {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 15px;
    background-color: $theme-color;
    justify-content: space-between;
    margin: 15px;
    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner,
    .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
        width: auto;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: auto;
    }
    .el-form-item {
        margin-bottom: 0;
    }
    .right {
        display: flex;
        align-items: center;
    }
}
.formTable {
    /deep/ .el-date-editor .el-range-separator {
        width: 25px;
    }
    /deep/ .el-range-editor.el-input__inner {
    }
}
.table {
    padding: 10px 15px;
    margin: 0px 15px;
    background-color: $theme-color;
    .el-table::before {
        height: 0;
    }
}

/deep/ .el-table__header .cell {
    padding-left: 0.729vw;
    color: #909399;
}
.app-container {
    padding-bottom: 20px;
}
// .app-container{
//   background-color: $theme-color;
//   /deep/ .el-table{
//     background: transparent;
//     tr, th{
//       background: transparent;
//     }
//   }
// }
.fromForm {
    margin: 0 auto;
    .table {
        margin: 0 auto;
        padding: 0;
        font-size: 14px;
        .el-table {
            .has-gutter {
                th {
                    padding: 0;
                }
            }
        }
    }
}
.tableHead {
    display: flex;
    padding-right: 10px;
    justify-content: flex-end;
    padding-bottom: 15px;
}
.formTable {
    &.fromForm {
        /deep/ .el-table thead tr th {
            padding: 0;
        }
        /deep/ .el-select {
            .el-input__inner {
                height: 40px;
                line-height: 40px;
            }
        }
    }
    /deep/ .el-select {
        width: 100%;
    }
    .form {
        /deep/ .el-form.el-form--inline {
            width: 100%;
        }
    }
    .input_text {
        max-width: 100%;
        border: 1px solid #dcdfe6;
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: border-color 0.2s
            cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        &:disabled {
            background: #f5f7fa;
        }
    }
}
.outputBtn {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: top;
    line-height: 40px;
    font-size: 14px;
}
/deep/ .el-button--primary.is-plain {
    background: #ecf5ff;
}
/deep/ .el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
    background: #409eff;
}
/deep/ .el-table__fixed {
    height: 100% !important;
}
</style>
