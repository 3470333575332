<template>
    <div class="main">
        <el-form ref="form"
                 class="contentForm"
                 :model="rowForm"
                 label-width="15vw">
            <el-form-item label="抄表日设置:">
                每个月的第
                <el-input v-model="rowForm.dateValue" placeholder="请输入日期" style="width:65%;"></el-input>
                日
                <el-button size="mini" @click="selectBlur">提交</el-button>
            </el-form-item>
        </el-form>
    </div>

</template>
<script>
import { setThreshold, getThreshold } from '@/api'
export default {
    title: '水表抄表日设置',
    name: 'waterRecordDate',
    data () {
        return {
            rowForm: {}
        }
    },
    async mounted () {
        getThreshold('water_record_date').then(res => {
            this.$set(this.rowForm, 'dateValue', res.data.configValue)
        })
    },
    methods: {
        selectBlur () {
            setThreshold({ configKey: 'water_record_date', configValue: this.rowForm.dateValue }).then(() => {
                location.reload()
            })
        }
    }
}
</script>
<style scoped>
.main {
    height: 100%;
    position: relative;
}
.contentForm {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-wrap: wrap;
}
</style>
