<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="false"
                  @getInit="getInit"
                  @search="search"
                  @append="normalEvent({ ref: 'addEdit', method: 'append' })"
                  @reset="reset"
                  :valueFormat="formatter"
                  @rowSubmit="rowSubmit"
                  @rowDelete="rowDelete"
                  @rowEdit="rowEdit"
                  @setBond="setBond"
                  @rowDetail="rowDetail">
        </ja-table>

        <add-edit ref="addEdit" />
        <detail ref="detail" />
        <submit ref="submit" />
        <set-bond ref="setBondRef"></set-bond>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from '../childComp/AddEdit'
import Detail from '../childComp/Detail'
import Submit from '../childComp/Submit'
import { conditionData, columns } from '../contractManage'
import SetBond from '../childComp/setBond.vue'
import {
    getContractList,
    delContractItem
} from '@/api'

export default {
    title: '合同信息管理-已驳回',
    mixins: [table],
    name: 'rejected',
    components: {
        AddEdit,
        Detail,
        Submit,
        SetBond
    },
    data () {
        return {
            conditionData: conditionData,
            columns: columns,
            tableData: [],
            activeItem: null,
            indexObj: {}
        }
    },
    methods: {
        getInit () {
            this.loading = true
            const rqData = this.rqParmas()
            getContractList(rqData).then((res) => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
        formatter (val, key) {
            if (key === 'roomInfo') {
                if (val && val.length > 0) {
                    const text = val.map((item) => item.roomName).join('，')
                    return text || ''
                } else {
                    return ''
                }
            }
            if (key === 'status') {
                const statusVal = { 0: '待提交', 1: '已提交', 3: '已驳回' }
                return statusVal[val]
            }
            return val || ''
        },
        // 删除
        rowDelete (row) {
            this.$confirm(
                '合同删除后，该合同的相关数据将不再可用，请谨慎操作。',
                '确定删除该合同吗？',
                {
                    type: 'warning'
                }
            ).then((res) => {
                delContractItem(row.id).then((res) => {
                    this.$message.success('已删除')
                    this.getInit()
                })
            })
        },
        // 编辑
        rowEdit (row) {
            row.roomInfo.map((e) => {
                e.building = e.buildingName
                e.floor = e.floorName
                e.category = e.roomCategoryName
            })
            this.$refs.addEdit.rowEdit(row)
        },
        // 提交
        rowSubmit (row) {
            this.$refs.submit.rowEdit(row)
        },
        rowDetail (row) {
            this.$refs.detail.rowDetail(row)
        },
        rqParmas () {
            return {
                pageSize: this.filterData.pageSize,
                pageIndex: this.filterData.pageIndex,
                status: 3,
                contractCode: this.filterData.contractCode || '',
                tenantName: this.filterData.tenantName || '',
                tenantCode: this.filterData.tenantCode || '',
                contractName: this.filterData.contractName || '',
                startTime: this.filterData.date ? this.filterData.date[0] : '',
                endTime: this.filterData.date ? this.filterData.date[1] : '',
                queryFlag: 0,
                contractType: this.filterData.contractType
            }
        },
        setBond (row) {
            this.$refs.setBondRef.init(row)
        }
    }
}
</script>
