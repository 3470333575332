<template>
    <div>
        <ja-table
            ref="tst"
            v-model="filterData"
            :conditionData="conditionData"
            :columns="columns"
            :tableData="tableData"
            :total="total"
            :loading="loading"
            @getInit="getInit"
            @search="search"
            @reset="reset"
            @lookRoom="lookRoom"
        >
        </ja-table>
        <show-passed ref="showPassed"></show-passed>
    </div>
</template>

<script>
import { getApplicationList } from '@/api'
import table from '@/views/mixins/table'
import showPassed from './showPassed.vue'
export default {
    title: '已驳回',
    mixins: [table],
    name: 'toRentList',
    components: {
        showPassed,
    },
    data() {
        return {
            conditionData: [
                // 表格上方的搜索/选择操作栏
                {input_type: 'input',key: 'tenantName',attribute: {placeholder: '驻户名称',    size: 'small'}},
                {input_type: 'input',key: 'storeName',attribute: {placeholder: '店铺名称',    size: 'small'}},
                {input_type: 'input',key: 'carNumber',attribute: {placeholder: '车牌号码',    size: 'small'}},
                {input_type: 'input',key: 'applicant',attribute: {placeholder: '申请人',    size: 'small'}},
                {input_type: 'input',key: 'contactNumber',attribute: {placeholder: '联系方式',    size: 'small'}},
                {input_type: 'select',key: 'serviceType',attribute: {placeholder: '全部服务类型',    size: 'small'},options: []},
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                ],
            columns: [
                // 表头数据
                { label: '驻户名称', prop: 'tenantName' },
                { label: '店铺名称', prop: 'storeName' },
                { label: '车牌号码', prop: 'carNumber' },
                {
                    label: '服务类型',
                    prop: 'serviceTypeName'
                },
                {
                    label: '申请人',
                    prop: 'applicant'
                },
                {
                    label: '联系方式',
                    prop: 'contactNumber'
                },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        {
                            text: '查看',
                            method: 'lookRoom',
                            limit: 'residentApplicationRecord::rejectedModule::show'
                        }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30
        }
    },
    mounted() {
        let index = this.conditionData.findIndex((e) => e.key === 'serviceType')
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'service_card' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        getInit() {
            this.loading = true
            // 获取楼层信息列表
            getApplicationList({...this.filterData,status:3})
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
                .catch((err) => {
                    this.loading = false
                })
        },
        lookRoom(row) {
            this.$refs.showPassed.init(row,2)
        }
    }
}
</script>

