import http from './plugin/ajax'

// =========  工单管理  ===========
/**
 * 维修工单-告警工单-巡检工单   公用
 * data:{classify 工单分类 0-维修工单 1-告警工单 2-巡检工单}
 */
export const getOrderList = (data) => http.post('/work/info/list', data)

export const editOrderList = (data) => http.get(`/work/info/end/${data}`) // 结单
export const getWorkOrderHead = (data) => http.get(`/work/info/amount/${data}`) // 工单头部信息
export const getWorkNewHead = (data) => http.post(`/work/info/statistics/new`,data) // 工单头部信息(新) 
export const getRepairWorkOrders = (data) => http.get(`/work/info/repair/amount/${data}`) // 维修工单头部信息

 // 工单配置
export const getWorkConfig = (data) => http.post('/work/config', data) // 查询
export const addWorkConfig = (data) => http.post('/work/config/add', data) // 新增
export const editWorkConfig = (data) => http.put('/work/config', data) // 编辑
export const delWorkConfig = (data) => http.delete('/work/config/' + data) // 删除
export const getRangeWorkConfig = (data) => http.get('/work/config/getSend/' + data) // 获取派单范围配置
export const assignRangeWorkConfig = (data) => http.put('/work/config/send', data) // 派单范围配置
export const getWorkConfigReceiver = (data) => http.post('/employee/dept/deptIds', data) // 接单人 下拉列表

export const getViewRecords = (data) => http.get(`/work/info/records/${data}`) // 获取工单查看记录

export const rqScanOrderItem = (data) => http.get('/work/info/patrol/' + data) // 巡更工单 详情

// 工单统计
export const getStatisticalData = (data) => http.get('/work/info/statisticalData', data)
// 月度区域维修TOP3分析
export const getrepairTop = (data) => http.get(`/work/info/repairTop/${data}`)
