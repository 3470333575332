<template>
  <!-- 消息模板 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" @rowDelete="rowDelete" :tableData="tableData" :total="total" :loading="loading" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent" @getInit="getInit" @search="search" @reset="reset">
      <!-- <template #msgContent="{row}">
        <div class="table_msgContent">
          {{row.msgContentText }}
        </div>
      </template> -->
    </ja-table>
    <add-edit ref="addEdit" :declarationList="declarationList"/>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import { rqMsgTemplate, delMsgTemplate } from '@/api'

export default {
  title: '消息模板',
  mixins: [table],
  components: {
    AddEdit,
  },
  data() {
    return {
      conditionData: [
        {
          input_type: 'select', key: 'status', attribute: { placeholder: '状态', size: 'small' }, options: [
            { label: '启用', value: 0 },
            { label: '禁用', value: 1 },
          ],
        },
        { input_type: 'select', key: 'msgType', attribute: { placeholder: '消息类型', size: 'small' }, options: [], },
        { input_type: 'input', key: 'search', attribute: { placeholder: '模板名称', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增', },
      ],
      columns: [
        { label: '模板名称', prop: 'templateName' },
        { label: '消息类型', prop: 'msgTypeName' },
        { label: '消息内容',column_type:'htmlStr', prop: 'msgContent', width: 250, },
        // { label: '消息内容', prop: 'msgContent', attribute: { showOverflowTooltip: true, align: 'center' }, },
        { label: '通知组', prop: 'groupNames', },
        { label: '状态', prop: 'statusName', },
        { label: '创建人', prop: 'createBy' },
        { label: '备注', prop: 'remark', attribute: { showOverflowTooltip: true, align: 'center' }, },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '编辑', method: 'rowEdit', },
            { text: '删除', method: 'rowDelete', }
          ]
        }
      ],
      rowForm: {},
      indexObj: {},

      declarationList:[], //变量说明
    }
  },
  mounted() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    const addEdit = this.$refs.addEdit
    this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.msgType], addEdit.rowFormData[addEdit.indexObj.msgType]], rqUrl: "/sys/dictData/msg_type", resRule: 'data', isReturnAll: true }).then(res => {
       console.log(res);
      // const str = addEdit.rowFormData[addEdit.indexObj.msgType].options[0].remark
      // console.log(addEdit.rowFormData[addEdit.indexObj.msgType].options);
      // const list = str.replace(/(\\r|\\n)/ig,'').split('@').slice(1)
      // this.declarationList = list
    })
  },
  methods: {
    getInit() {
      this.loading = true
      const rqData = this.rqParmas()
      rqMsgTemplate(rqData).then(res => {
        this.loading = false
        const { total, data } = res.data
        this.total = total
        this.tableData = (data || []).map(item => {
          let msgContent = item.msgContent
          item.msgContentText = msgContent.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ')
          return item
        })
      }).catch(err => {
        this.loading = false
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm(`是否确认删除模板“${row.templateName}”`).then(res => {
        delMsgTemplate(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
  }
}
</script>
<style scoped>
.table_msgContent {
  max-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
