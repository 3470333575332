<template>
  <router-view/>
</template>

<script>
export default {
  title: "通行模块",
  name: 'passModule'
}
</script>

<style scoped>

</style>
