
<template>
<!--  时段标准设置 -->
    <div>
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showTimeSet" width="55%"   append-to-body class="elDialog" :title="`时段标准设置——【${titleText}${serviceName?'-'+serviceName:''}】`"  :before-close="cancel">
            <div class="btnBox"><el-button type="primary" size="small" @click="addRow">加一行</el-button></div>
            <ja-table
                ref="tst"
                :columns="columns"
                :tableData="tableData"
                :showIndex="true"
                @rowEdit="rowEdit"
                :noSearch='true'
                :border='true'
                :showPage="false"
                :valueFormat="formatter"
                @rowDelete="rowDelete" >
                <template #unit>
                    <div> {{deviceCategory == 186?'元/km³':'元/kw · h'}}</div>
                </template>
            </ja-table>
            <!-- 按钮 -->
            <div class="btnList">
                <el-button type='primary' size="small" @click="saveTable">保存</el-button>
                <el-button type='default' size="small" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
        <time-set-add v-bind="$props" ref="timeAdd"  @dealTableData="dealTableData"/>
    </div>
</template>
<script>
import TimeSetAdd from '../TimeSetAdd/TimeSetAdd'

export default {
    inject:['labelWidth','getInit','category','timeInterval',],
    props:['titleText','showTimeSet','timeIntervalData','serviceName','deviceCategory'],
    components:{
        TimeSetAdd
    },
    data(){
        return{
            showBox:false,
            columns:[
                { label: '时段类型', prop: 'category' ,optionsObj:null},
                { label: '时间起止', prop: 'timeInterval',optionsObj:null },
                { label: '时段单价', prop: 'unitPrice' },
                { label: '计费单位', prop: 'unit',slotName:'unit' },
                { label: '操作', input_type: 'button', width:100,attribute:{fixed:"right",align:'center'},
                    list: [
                        { text: '编辑', method: 'rowEdit' },
                        { text: '删除', method: 'rowDelete' },
                    ]
                }
            ],
            tableData:[],
            rowItem:null, // 当前行
            active_index:-1,
        }
    },
    created(){
        // console.log('每次进来---',this.timeIntervalData)
        this.showBox = true
        this.tableData = this.timeIntervalData && this.timeIntervalData.length > 0 ? this.timeIntervalData : []
        
        this.getAllOptionsObj({who : this.columns[0],key:'optionsObj' ,arr : this.category.options})
        this.getAllOptionsObj({who : this.columns[1],key:'optionsObj', arr : this.timeInterval.options})
    },
    methods:{
        getAllOptionsObj({who,arr,key}){
            if(who && arr && arr.length>0){
                let obj = {}
                arr.forEach(item=>{
                    obj[item.value] = item.label
                })
                this.$set(who,`${key}`,obj)
            }
        },
        formatter (val, key) {
            // console.log(val, key,this.columns[0].optionsObj)
            if (key === 'category') {
                return this.columns[0].optionsObj? this.columns[0].optionsObj[val]:val
            }else if(key === 'timeInterval'){
                return this.columns[1].optionsObj?this.columns[1].optionsObj[val]:val
            }
            return val 
        },
        rowDelete(row,a){
            let index = a.$index
            this.tableData.splice(index,1)
        },
        // 加一行
        addRow(){
            this.$refs.timeAdd.append()
        },
        dealTableData(isAdd,newIem){
            // console.log('newItem-------',newIem)
            if(isAdd){
                this.tableData.push(newIem)
            }else{
                this.tableData.splice(this.active_index,1,newIem)
            }
        },
        // 编辑
        rowEdit(row,a,b){
            // console.log('编辑--',row,a,b)
            this.active_index = a.$index
            this.rowItem = row
            this.$refs.timeAdd.rowEdit()
        },
        // 保存当前表格
        saveTable(){
            this.$emit('changeTimeSet',this.tableData)
            this.cancel()
        },
        cancel(){
            this.$emit('update:showTimeSet',false)
        }
        
    }
}
</script>
<style lang="scss" scoped>
.btnBox{
    display: flex;
    flex-direction: row-reverse;
    margin-right: 30px;
}
.btnList {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}
</style>
<style lang="scss">
// .app-container.formTable{
//     .form{
//         padding: 0 !important;
//         margin: 0!important;
//         .el-form-item{
//             margin-bottom: 0 !important;
//         }
//     }
// }
</style>