<template>
    <!-- 电抄表 -->
    <div class="allwh">
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @dictonaList="dictonaList"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  :showIndex="false"
                  @showDetail="showDetail"
                  @importForm="
        normalEvent({
          ref: 'importForm',
          method: 'importForm',
          url: '/files/电表抄表导入.xls',
          filename: '电表抄表导入模板',
          uploadURL: '/electricityMeter/upload'
        })
      ">
        </ja-table>
        <show-information ref="showInformation"></show-information>
        <import-form ref="importForm"
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
    </div>
</template>

<script>
import { getElectricityMeter } from '@/api'
import table from '@/views/mixins/table'
import ShowInformation from './components/ShowInformation.vue'
import importForm from '../common/importForm.vue'
import checkResult from '../common/checkResult'

export default {
    title: '电抄表',
    mixins: [table],
    name: 'index',
    components: {
        importForm,
        checkResult,
        ShowInformation
    },
    data() {
        return {
            filterData: {},
            conditionData: [
                {
                    input_type: 'input',
                    key: 'deviceCode',
                    lable: '设备识别码',
                    attribute: {
                        placeholder: '设备识别码',
                        size: 'small'
                    }
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'import', text: '导入' }
            ],
            columns: [
                { label: '设备识别码', prop: 'deviceCode' },
                { label: '上次底表', prop: 'lastReading' },
                { label: '本次抄表', prop: 'thisReading' },
                { label: '本次抄表时间', prop: 'updateTime' },
                { label: '本期用量（度）', prop: 'currentReading' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 180,
                    list: [
                        {
                            text: '查看',
                            method: 'showDetail',
                            limit: 'electricity::meter::detail'
                        }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,
            rowForm: {}
        }
    },
    created() { },
    methods: {
        getInit() {
            this.loading = true
            // 获取电表抄表数据
            getElectricityMeter({
                pageIndex: this.filterData.pageIndex,
                pageSize: this.filterData.pageSize,
                deviceCode: this.filterData.deviceCode
            }).then(({ data }) => {
                let dataList = data.data
                dataList.map(item => {
                    item.lastReading = Number(item.lastReading).toFixed(2)
                    item.thisReading = Number(item.thisReading).toFixed(2)
                    item.currentReading = Number(item.currentReading).toFixed(2)
                })
                this.tableData = dataList
                this.total = data.total
                this.loading = false
            })
        },
        //查看
        showDetail(row) {
            this.$refs.showInformation.init(row)
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-col .el-form-item .el-input__inner {
    height: 40px;
}
/deep/ .el-select {
    width: 100% !important;
}
</style>
