<template>
    <el-dialog :visible.sync="show"
               :close-on-click-modal="false"
               @close="diaClose"
               width="40%"
               title="长短尾抹零">
        <ja-form :rules="rules"
                 :btnBox="btnBox"
                 v-model="rowForm"
                 v-loading="loading"
                 :formArray="rowFormData"
                 @save="save"
                 @reset="reset">
        </ja-form>
    </el-dialog>
</template>
<script>
import { customBillToFixed } from '@/api'
export default {
    inject: ['updateList'],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        pid: {
            type: [String, Number],
            defalut: null
        },
        rowForm:{
            type: Object,
            defalut: ()=>{}
        }
    },
    data() {
        return {
            btnBox: [
                { label: '取消', method: 'reset', col: 8, limit: '' },
                { label: '确认', method: 'save', type: 'primary', limit: 'contractFile::submitView::save' }
            ],
            rowFormData: [
                { input_type: 'input', label: '抹零前金额', key: 'rowzero', labelWidth: '150px', col: 20 },
                { input_type: 'input', label: '抹零后金额（元）', key: 'zeroAfter', labelWidth: '150px', col: 20 },
            ],
            rules: {
                zeroAfter: [{ required: true, trigger: 'change', validator: this.checkNumber({ fixedNum: 2,that:this }) }],
            },
            rowForm: {},
            loading: false,
        }
    },
    methods: {
        reset() {
            this.show = false
            this.$emit('update:show', false)
        },
        save() {
            this.loading = true
            let obj = { zeroAfter:this.rowForm.zeroAfter, id: this.pid }
            customBillToFixed(obj).then(res=>{
            this.loading = false
            this.$message.success(res.msg)
            this.show = false
            this.$emit('update:show', false)
            this.updateList()
            }).catch(err=>{
                this.loading = false
            })
        },
        diaClose() {
            this.show = false
            this.$emit('update:show', false)
        },
        // 抹零金额验证
        checkNumber: ({
            that,
            nullText = "请输入数值",
            fixedNum,
            isInt = false,
        } = {}) => {
            return function (rule, value, callback) {
                // console.log('数字  value', value, rule)
                if (rule.hide) {
                    return callback()
                }
                if (rule.required) {
                    if (value === undefined || value === "" || value === null || (!value && value != 0)) { // 如果为空
                        // console.log('验证数字===》', value)
                        return callback(new Error(nullText))
                    }
                }
                if (isNaN(Number(value))) {
                    return callback(new Error('请输入数字'))
                }
                if (isInt) {
                    if (value.toString().includes('.')) { // 若是小数
                        return callback(new Error('请输入整数'))
                    }
                }
                if (fixedNum) {
                    if (value.toString().includes('.')) {
                        const smallNum = value.toString().split('.')[1].length
                        if (smallNum > fixedNum) {
                            return callback(new Error(`只能保留${fixedNum}位小数`))
                        }
                    }
                }
                if (value > Math.floor(that.rowForm.rowzero) + 1) {
                    return callback(new Error(`抹零金额不在抹零范围中,请重新输入`))
                } else if (value < Math.floor(that.rowForm.rowzero)) {
                    return callback(new Error(`抹零金额不在抹零范围中,请重新输入`))
                } else {
                    return callback()
                }
            }
        },
    }
}
</script>

<style lass="less" scoped>
::v-deep .facac {
    justify-content: center !important;
}
</style>
