<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowDelete="rowDelete" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent" @rowDetail="normalEvent">
      <template #img='{row}'>
        <el-image :preview-src-list="[row.coverImg]" fit="contain" class="cellImg" :src="row.coverImg" alt="" v-if="row.coverImg ">
          <div slot="error" class="errorImg el-image__error">
            加载失败
          </div>
        </el-image>
      </template>
    </ja-table>

    <!-- 新增/编辑 -->
    <add-edit ref="addEdit" />
    <detail ref="detail" />
  </div>
</template>
<script>
import { rq_Questionnaire, del_Questionnaire } from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit.vue'
import Detail from './childComp/Detail.vue'

export default {
  title: '客户调查_问卷',
  mixins: [table,],
  name: 'index',
  components: {
    AddEdit, Detail
  },
  data() {
    return {
      conditionData: [
        { input_type: 'select', key: 'respondent', attribute: { placeholder: '调查对象', size: 'small' }, options: [], }, // 涉及到 异步的，options 要有
        {
          input_type: 'select', key: 'status', attribute: { placeholder: '状态', size: 'small' }, options: [
            { label: '待发布', value: 0 },
            { label: '调查中', value: 1 },
            { label: '已完成', value: 2 },
          ],
        }, // 涉及到 异步的，options 要有
        { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
        { input_type: 'input', key: 'questionnaireTitle', attribute: { placeholder: '问卷标题', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add' },
      ],
      columns: [
        { label: '问卷编号', prop: 'questionnaireCode' },
        { label: '封面图片', prop: 'coverImg', width: 100, slotName: 'img' },
        { label: '问卷标题', prop: 'questionnaireTitle' },
        { label: '说明文字', prop: 'description' },
        { label: '问题数', prop: 'questionNumber' },
        { label: '调查对象', prop: 'respondentName' },
        { label: '发布时间', prop: 'releaseTime' },
        { label: '结束时间', prop: 'endTime' },
        { label: '当前参予人数', prop: 'participateNumber' },
        { label: '状态', prop: 'statusName' },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '详情', method: 'rowDetail' },
            { text: '编辑', method: 'rowEdit' },
            { text: '删除', method: 'rowDelete' },
          ]
        }
      ],
      con_indexObj: {},
    }
  },
  mounted() {
    this.con_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    let rowFormData = this.$refs.addEdit.rowFormData
    this.util.rqOptionsList({ who: [this.conditionData[this.con_indexObj.respondent], rowFormData[this.$refs.addEdit.indexObj.respondent]], rqUrl: "/sys/dictData/respondent", rqMethod: 'get', resRule: 'data' })
  },
  methods: {
    rqParmas() {
      let obj = { ...this.filterData }
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = true
      let data = this.rqParmas()
      rq_Questionnaire(data).then(res => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
      }).catch(err => {
        this.loading = false
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该问卷么，问卷统计数据将同步删除？', '操作确认').then((res) => {
        del_Questionnaire(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .cellImg {
  display: flex;
  margin: auto;
  > img {
    max-height: 23px;
    width: 35px;
    display: flex;
    margin: auto;
  }
}
/deep/ .errorImg {
  width: 100%;
}
</style>