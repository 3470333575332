<template>
    <div>
       <div class="statistics">
            <el-row :gutter="50" style="padding: 16px 16px 0; ">
                <el-col :span="12">
                   <pie ref="pie"></pie>
                </el-col>
                <el-col :span="12">
                   <bar ref="bar"></bar>
                </el-col>
            </el-row>
       </div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @edit="edit"
                  @followUpRecord="followUpRecord"
                 :valueFormat="formatter"
                 @followUp="followUp"
                  @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/项目列表导入模板.xlsx', filename: '项目列表导入模板', uploadURL: '/cmProjectInfo/upload'})"> </ja-table>
        <add-edit ref="addEdit"></add-edit>
        <!-- 跟进 -->
        <addFollowUp ref="followUp"></addFollowUp>
        <import-form ref='importForm'
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import importForm from '@/views/home/components/common/importForm.vue'
import { getProject, deleteProject } from '@/api'
import checkResult from '@/views/home/components/common/checkResult.vue'
import AddEdit from './components/AddEdit.vue'
import addFollowUp from './components/addFollowUp.vue'

import pie from './components/pie.vue'
import bar from './components/bar.vue'
export default {
    title: '项目列表',
    name: 'listOfItems',
    mixins: [table],
    components: {
        importForm,
        checkResult,
        AddEdit,

        pie,
        bar,
        addFollowUp
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'contacts', attribute: { placeholder: '联系人', size: 'small', } },
                { input_type: 'input', key: 'customerContact', attribute: { placeholder: '电话号码', size: 'small', } },
                { input_type: 'input', key: 'referrerName', attribute: { placeholder: '推荐人', size: 'small', } },
                { input_type: 'select', key: 'progressStatus', attribute: { placeholder: '进度', size: 'small', }, options: [
                    {value:'1',label:'未开始'},
                    {value:'2',label:'初步接触'},
                    {value:'3',label:'洽谈阶段'},
                    {value:'4',label:'交付诚意金'},
                    {value:'5',label:'交付定金'},
                    {value:'6',label:'正式签约'},
                    {value:'7',label:'暂停跟进'},
                ] },
                { input_type: 'select', key: 'importanceLevel', attribute: { placeholder: '重要级别', size: 'small', }, options: [
                    {value:'1',label:'重要紧急'},
                    {value:'2',label:'重要不紧急'},
                    {value:'3',label:'紧急不重要'},
                    {value:'4',label:'不重要不紧急'}
                ] },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '新增', limit: 'customerManagement::listOfItems::add' },
                // { normalBtn: 'import', text: '导入', limit: 'customerManagement::listOfItems::import' }
            ],
            columns: [
                { label: '项目名称', prop: 'projectName' },
                { label: '租赁用途', prop: 'leasePurposeName' },
                { label: '联系人', prop: 'contacts' },
                { label: '电话号码', prop: 'customerContact' },
                { label: '面积需求（㎡）', prop: 'demandArea' },
                { label: '重要级别', prop: 'importanceLevelName' },
                { label: '进度', prop: 'progressStatusName' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: '250px',
                    list: [
                        { text: '跟进', method: 'followUp', limit: 'customerManagement::listOfItems::followUp' },
                        { text: '查看跟进记录', method: 'followUpRecord', limit: 'customerManagement::listOfItems::show' },
                        { text: '编辑', method: 'edit', limit: 'customerManagement::listOfItems::edit' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            results: null,
        }
    },
    mounted() {

    },
    methods: {
        getInit() {
            this.loading = true
            this.$nextTick(()=>{
                this.$refs.pie.init(this.filterData)
                this.$refs.bar.init(this.filterData)
            })
            getProject(this.filterData).then(res => {
                this.tableData = res.data.data
                this.loading = false
                this.total = res.data.total
            })
        },
        append() {
            this.$refs.addEdit.init(1);
        },
        edit(row) {
            this.$refs.addEdit.init(2, row);
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
        /**新增跟进 */
        followUp(row){
            this.$refs.followUp.init(row,'新增');
        },
        /**跟进记录 */
        followUpRecord(row){
            this.$refs.followUp.init(row,'记录');
        },
        formatter(val, key) {
            if (key === 'progressStatus') {
                const statusVal = {'1':'未开始',
                    '2':'初步接触',
                    '3':'洽谈阶段',
                    '4':'交付诚意金',
                    '5':'交付定金',
                    '6':'正式签约',
                    '7':'暂停跟进',}
                return statusVal[val]
            }else if(key === 'importanceLevel'){
                 const statusVal = {
                    '1':'重要紧急',
                    '2':'重要不紧急',
                    '3':'紧急不重要',
                    '4':'不重要不紧急'}
                return statusVal[val]
            }
            return val
        }


    }
}
</script>
<style lang="scss" scoped>
.statistics{
    height: 320px;
}
</style>