<template>
    <!-- 表格 -->
    <div class="content">
        <div class="echart" v-if="options">
            <ja-init-chart :options="options" />
        </div>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
export default {
    props:{
        statisticsData:Object
    },
    components: {
        JaInitChart
    },
    data() {
        return {
            options: null,
        }
    },
    created() {
        this.setOptions()
    },
    methods: {
        async setOptions() {
            let initData = [
                { value: this.statisticsData.Chartone },
                { value: this.statisticsData.Charttwo },
            ]
            this.options = {
                title: {
                    text: this.statisticsData.workOrderProcessing,
                    top: '46%',
                    left: '43%',
                    textStyle: {
                        color: '#4352D4',
                        fontSize: 20,
                        fontWeight: '700',
                    },
                },
                color: ['#4352D4', '#DADDF6'],
                // tooltip: {
                //     trigger: 'item',
                //     formatter: '{d}%'
                // },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['30%', '60%'],
                        center: ['50%', '50%'],
                        itemStyle: {
                            borderRadius: 5
                        },
                        colorBy: 'data',
                        label: {
                            show: false,
                        },
                        data: initData
                    }
                ]

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 100%;
}

.echart {
    height: 100%;
    width: 100%;
}
</style>