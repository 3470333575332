<template>
  <!-- 场区管理 -->
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @seeDetails="seeDetails"
      @append="append"
      @rowEdit="rowEdit"
      :valueFormat="formatter"
      @dictonaList="dictonaList"
      @rowDelete="rowDelete"
      @getInit="getInit"
      @search="search"
      @reset="reset"
      :showIndex= false
    >
    </ja-table>
    <!-- 详情弹窗 -->
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="showDetail"
      title="修正凭证"
      :width="`${util.setFontSize(800)}px`"
    >
    </el-dialog>
    <!-- 新增/编辑 -->
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="allDisabled=false"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @inclick="inclick"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import { groundManageList, addGroundManage, editGroundManage, delGroundManage } from '@/api'
import table from '@/views/mixins/table'
import { groundManageQuery, groundManageColumn, groundManageFormData, groundManageRules } from './groundColumn'

export default {
  title: '场区管理',
  mixins: [table],
  name: 'index',
  data() {
    return {
      allDisabled:false,
      typeCode: '',
      filterData: {
        // pageIndex: 1,
        // pageSize: 10
      },
      conditionData: groundManageQuery,
      columns: groundManageColumn,
      tableData: [],
      // 新增/编辑
      rowFormData: groundManageFormData,
      loading: false,
      total: 30,
      showDetail: false,//显示详情
      labelWidth: `${this.util.setFontSize(120)}px`,
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      isAdd: true, // 新增/编辑
      rules: groundManageRules
    }
  },
  created() {},
  methods: {
    getInit() {
      this.loading = true
      // 获取楼层列表
      groundManageList({
        pageIndex: this.filterData.pageIndex,
        pageSize: this.filterData.pageSize,
        parkingName: this.filterData.parkingName,
        remark: this.filterData.remark,
        status: this.filterData.status
      }).then(({ data }) => {
        this.tableData = data.data
        this.total = data.total
        this.loading = false
      })
    },
    seeDetails(row) {
      this.rowEdit(row,"details")
    },
    // 新增
    append() {
      console.log(this.rowFormData);
      this.isAdd = true
      this.titleText = "新增场区"
      this.rowForm = {
        status: 0
      }
      this.showEditAll = true
    },
    // 编辑
    rowEdit(row, details) {
      this.isAdd = false
      this.showEditAll = true
      if(details=="details"){
        this.titleText = '查看场区'
        this.allDisabled=true
      }else{
        this.titleText = '编辑场区'
      }
      this.$nextTick(() => {
        this.rowFormData.forEach((item) => {
          this.$set(this.rowForm, item.key, row[item.key])
        })
      })
      this.$set(this.rowForm, 'id', row.id)
      this.$set(this.rowForm, 'parkingName', row.parkingName)
    },
    // 列表跳转
    // dictonaList(row) {
    //   this.$router.push({
    //     path: '/home/sysSetting/dictionaList',
    //     query: { dictType: row.dictType }
    //   })
    // },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该场区').then((res) => {
        delGroundManage(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    // 保存
    saveForm() {
      this.loading_form.isShow = true
      if (this.isAdd) {
        addGroundManage(this.rowForm)
          .then(({ msg }) => {
            this.loading_form.isShow = false
            this.showEditAll = false
            this.$message.success(msg)
            this.getInit()
          })
          .catch((err) => {
            console.log(err)
            this.loading_form.isShow = false
          })
      } else {
        editGroundManage(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.showEditAll = false
            this.$message.success(res.msg)
            this.getInit()
          })
          .catch((err) => {
            console.log(err)
            this.loading_form.isShow = false
          })
      }
    },
    formatter(val, key) {
      if (key === 'status') {
        const statusVal = { 0: '正常', 1: '停用' }
        return statusVal[val]
      }
      return val
    }
  },

  watch: {
    showEditAll(val) {
        console.log(val);
      if (!val) {
        this.$set(this, 'rowForm', {})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
// /deep/ .el-col .el-form-item .el-input__inner {
//   height: 40px;
// }
// /deep/ .el-select {
//   width: 100% !important;
// }
</style>
