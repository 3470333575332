
import util from '@/util/util'

/** 车位管理页面搜索栏 */
export const parkingManageQuery = [
  {
    input_type: 'input',
    key: 'stallCode',
    lable: '车位编号',
    attribute: {
      placeholder: '车位编号',
      size: 'small'
    }
  },
  { normalBtn: 'search' },
  { normalBtn: 'reset' },
  { normalBtn: 'add', limit: 'parking::manage::add' }
]

/** 车位管理页面表头栏 */
export const parkingManageColumn = [
  { label: '车位编号', prop: 'stallCode' },
  { label: '状态', prop: 'status' },
  { label: '描述', prop: 'remark', width: 180 },
  {
    label: '操作',
    input_type: 'button',
    width: 180,
    list: [
      { text: '查看', method: 'rowDetail', limit: 'parking::manage::details' },
      { text: '编辑', method: 'rowEdit', limit: 'parking::manage::update' },
      { text: '删除', method: 'rowDelete', limit: 'parking::manage::delete' }
    ]
  }
]

/** 车位管理页面新增/编辑项 */
export const parkingManageFormData = [
  {
    input_type: 'input',
    label: '车位编号',
    key: 'stallCode',
    maxlength: 30
  },
  {
    input_type: 'radio',
    label: '状态',
    options: [
      { label: '正常', value: 0 },
      { label: '关闭', value: 1 }
    ],
    key: 'status',
    method: 'selectUserType',
    // show: 1
  },
  {
    label: '描述',
    key: 'remark',
    input_type: 'input',
    type: 'textarea',
    col: 22,
    maxlength: 200
  }
]

/** 车位管理页面新增/编辑校验规则 */
export const parkingManageRules = {
  stallCode: [
    { required: true, message: '请输入场地名称', trigger: 'blur' },
    {
      validator: function(rule, value, callback) {
        if (/^[\a-\z\A-\Z0-9\u4e00-\u9fe5]+$/.test(value) == false) {
          callback(new Error("请输入中文、数字和英文"));
        } else {
          //校验通过
          callback();
        }
      },
      trigger: "blur"
    }
  ],
  status: [
    { required: true, message: '请选择状态', trigger: 'change' }
  ]
}
