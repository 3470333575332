<template>
    <div class="tabsBox">
        <el-tabs v-model="activeName">
            <el-tab-pane label="已通过" name="1">
                <passed-module v-if="activeName === '1'"></passed-module>
            </el-tab-pane>
            <el-tab-pane label="已驳回" name="2">
                <rejected-module v-if="activeName === '2'"></rejected-module>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import passedModule from './components/passedModule.vue'
import rejectedModule from './components/rejectedModule.vue'
export default {
    title: '驻户申请记录',
    name: 'residentApplicationRecord',
    components:{
        rejectedModule,
        passedModule,
    },
    data () {
        return {
            activeName: '1'
        }
    },
    
}
</script>
<style lang="scss" scoped>
.tabsBox {
    padding: 10px 18px;
    overflow: hidden;
}
.el-tabs {
    padding: 10px 10px;
    background-color: #fff !important;
    /deep/.el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll {
        background-color: #fff !important;
    }
}
</style>