<template>
    <div>
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="60%" :title="titleText">
            <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData"
                :loadingForm="loadingForm" @reset="reset" @save="preSaveForm" v-if="showEditAll"
                @confiGuration="confiGuration" @selectDate="selectDate" @selectNenant="selectNenant"
                @selectRoom="selectRoom" @frequencySelect="frequencySelect" @tenantSelect="tenantSelect"
                @expenseAllocation="expenseAllocation" @rowDelete_formTable="rowDelete_formTable">
                <template #household>
                    <el-button type="primary" size="samll" slot="label">驻户名称</el-button>
                </template>
                <template #uplpadfaceImg>
                    <upload-file ref="Upload" :autoUpload="true" :accept="'.jpeg,.png,.pdf,.jpg'" :limit="30"
                        :initFileList="[...initFileList]" :fileSize="10" v-if="showEditAll" />
                </template>
                <template #paymentDate>
                    <span>每个月的第</span>
                    <el-select v-model="rowForm.day" class="day" placeholder="请选择日期">
                        <el-option v-for="item in 28" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                    <span>日</span>
                </template>
            </ja-form>
        </el-dialog>
        <!-- 选择房间 -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" width="80%" title="选择租赁单元" :visible.sync="isSelectRoom"
            v-if="isSelectRoom">
            <select-room @selectRow="selectRow2" :selectedPersons="[...selectedRooms]" v-if="isSelectRoom"
                :rqParmasFather="this.rqParmasFather_room" :isOnePerson="true" />
        </el-dialog>
        <!--费用配置-->
        <rent-allocation ref="rentA" @getContractRentReqs="getContractRentReqs"></rent-allocation>

    </div>
</template>
<script>
import selectRoom from './selectRoom.vue'
import RentAllocation from './rentAllocation.vue'
import deepCopy from '@/util/deepCopy.js'
import {
    addContractItem,
    editContractItem,
    rqTenant
} from '@/api'

export default {
    inject: ['labelWidth', 'getInit'],
    components: { selectRoom, RentAllocation },
    data() {
        return {
            // 缴费计算
            frequencyCount: null,
            labelWidth: '150px',
            rowFormData: [
                { input_type: 'input', label: '合同编号', key: 'contractCode', col: 12, styleWidth: '80%', maxlength: 30 },
                { input_type: 'input', label: '合同名称', key: 'contractName', col: 12, styleWidth: '80%', maxlength: 30 },
                { input_type: 'select', label: '合同类型', key: 'contractType', col: 12, styleWidth: '80%', options: [] },
                { input_type: 'date', label: '租期', key: 'leaseTerm', method: 'selectDate', type: 'daterange', col: 12, valueFormat: 'yyyy-MM-dd', unlinkpanels: true },
                { input_type: 'date', label: '计租开始日期', key: 'billingTime', method: 'selectDate', pickerOptions: { disabledDate: this.disabledDateFun }, valueFormat: 'yyyy-MM-dd', defaultValue: '', col: 12, styleWidth: '80%' },
                { input_type: 'customize', customize: 'paymentDate', label: '缴费日期', key: 'day' },
                { input_type: 'select', label: '驻户名称', key: 'tenantId', col: 12, placeholder: '请选择驻户', styleWidth: '80%', maxlength: 30, options: [], method: 'tenantSelect' },
                { input_type: 'input', label: '营业执照号/身份证号', key: 'cardCode', col: 12, styleWidth: '80%', maxlength: 10, disabled: true },
                { input_type: 'select', label: '驻户类型', key: 'tenantType', col: 12, placeholder: '请选择驻户类型', styleWidth: '80%', maxlength: 30, options: [
                    { value: '0', label: "商业" }, 
                    { value: '1', label: "办公" }
                ]},
                {
                    input_type: 'table',
                    label: '驻户租赁单元',
                    col: 20,
                    noSearch: true,
                    showPage: false,
                    key: 'roomTable',
                    isRequired: true,
                    border: true,
                    table_MaxHeight: 500, // roomIds
                    tableAttribute: { showSummary: true },
                    columns: [
                        { label: '驻赁单元名称', prop: 'roomName' },
                        { label: '租赁单元类型', prop: 'roomTypeValue' },
                        { label: '所在楼栋', prop: 'buildingName' },
                        {
                            label: '所在楼层',
                            prop: 'floorName',
                            attribute: { showOverflowTooltip: false }
                        },
                        { label: '套内总体使用面积', prop: 'totalArea' },
                        {
                            label: '操作',
                            input_type: 'button',
                            width: '150',
                            list: [
                                { text: '删除', method: 'rowDelete_formTable', limit: 'contract::manage::rowDelete_formTable' },
                                { text: '费用配置', method: 'expenseAllocation', limit: 'contract::manage::expense_allocation' }
                            ]
                        }
                    ]
                },
                { input_type: 'button', text: '选择租赁单元', readonly: true, col: 2, colMarginLeft: '2%', method: 'selectRoom' },
                { input_type: 'customize', customize: 'uplpadfaceImg', label: '合同附件', key: 'urlLists', col: 24 }
            ],
            showEditAll: false,
            rowForm: {},
            rules: {
                contractType: [{ required: true, trigger: 'change', message: '合同类型不能为空' }],
                contractCode: [
                    { required: true, message: '请输入合同编号', trigger: 'blur' },
                    {
                        validator: function (rule, value, callback) {
                            if (/^[\a-\z\A-\Z0-9]+$/.test(value) == false) {
                                callback(new Error('只能输入正整数和英文'))
                            } else {
                                // 校验通过
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                contractName: [
                    { required: true, message: '请输入合同名称', trigger: 'blur' },
                    {
                        validator: function (rule, value, callback) {
                            if (/^[\a-\z\A-\Z0-9\u4e00-\u9fe5]+$/.test(value) == false) {
                                callback(new Error('只能输入中文、数字和英文'))
                            } else {
                                // 校验通过
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                leaseTerm: [
                    { required: true, message: '请选择租期', trigger: 'change' }
                ],
                billingTime: [
                    { required: true, message: '请选择计租开始日期', trigger: 'change' }
                ],
                tenantId: [
                    { required: true, message: '请选择驻户', trigger: 'change' },
                    {
                        validator: function (rule, value, callback) {
                            if (/^[\a-\z\A-\Z0-9\u4e00-\u9fe5]+$/.test(value) == false) {
                                callback(new Error('只能输入中文、数字和英文'))
                            } else {
                                // 校验通过
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                rentalArea: [
                    { required: true, message: '请输入租赁面积', trigger: 'change' },
                    {
                        validator: function (rule, value, callback) {
                            if (/^[\Z0-9]+$/.test(value) == false) {
                                callback(new Error('只能输入数字'))
                            } else {
                                // 校验通过
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                day: [{ required: true, trigger: 'change', message: '请选择缴费日期' }]
            },
            titleText: '',
            isAdd: false,
            // 驻沪弹窗
            selectedPersons: [],
            isSelectPeople: false,
            // 房间弹窗
            selectedRooms: [],
            isSelectRoom: false,
            // 附件（进入编辑弹窗）
            initFileList: [],
            loadingForm: false,
            // 生成 房间信息 时 的 合同时间
            roomFormActiveDate: [],
            isClickCancel: false, // 是否 点击了取消
            oldDateRage: [], // 原来的合同时间,
            oldBillingTime: null, // 原来的计租开始时间
            indexObj: {},
            contractRentReqs: [],
            frequencyDisabled: false,
            oldBizPayServiceNewDTOS: [],
            oldRoomId: null,
            isclearRoom: false

        }
    },
    created() {
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData })
    },
    methods: {
        append() {
            this.rqOpsitions()
            // 清空费用配置
            this.contractRentReqs = []
            this.isAdd = true
            this.titleText = '新增'
            this.rowForm = { roomTable: [], tenantType: '0' }
            this.frequencyDisabled = false
            this.initFileList = []
            this.loadingForm = false
            this.roomFormActiveDate = []
            this.isClickCancel = false
            this.oldDateRage = []
            this.showEditAll = true
        },
        // 编辑
        async rowEdit(row) {
            console.log(row)
            this.showEditAll = true
            await this.rqOpsitions()
            // 清空费用配置
            this.contractRentReqs = []
            this.loadingForm = false
            this.isClickCancel = false
            this.isAdd = false
            this.titleText = '编辑'
            const obj = { ...row }
            // 重构数据
            obj.leaseTerm = [row.startTime, row.endTime]
            this.oldBizPayServiceNewDTOS = deepCopy(obj.bizPayServiceNewDTOS)
            // 区分新增/编辑生成明细请求参数
            obj.isEdit = true
            obj.number = row.cardCode
            obj.roomTable = [...row.roomInfo].map((item) => {
                this.oldRoomId = item.roomId
                return {
                    ...item,
                    id: item.roomId
                }
            })
            this.roomFormActiveDate = [...obj.leaseTerm]
            this.oldDateRage = [...obj.leaseTerm]
            this.oldBillingTime = row.billingTime
            if (row.attachmentListInfo.length > 0) {
                this.initFileList = row.attachmentListInfo.map((item) => {
                    if (!item) return
                    return {
                        name: item.attachmentName ? item.attachmentName : undefined,
                        url: item.attachmentUrl ? item.attachmentUrl : item,
                        raw: { type: item.attachmentType === 'pdf' ? 'pdf' : 'image' },
                        status: 'success',
                        response: { data: item }
                    }
                })
            } else {
                this.initFileList = []
            }
            this.frequencyDisabled = false
            this.rowForm = { ...obj }
        },
        // 表单提交前的校验
        checkForm() {
            // 配置费用
            if (!(Array.isArray(this.contractRentReqs) && this.contractRentReqs.length > 0)) {
                this.$message.error('请填写费用配置')
                return false
            }
            // 房间信息
            if (!this.rowForm.roomTable || this.rowForm.roomTable.length == 0) {
                this.$message.error('请完善房间信息')
                return false
            }
            return true
        },
        preSaveForm() {
            const fileList = this.$refs.Upload.fileList
            if (fileList.length == 0) {
                this.rowForm.attachmentList = []
                this.saveForm()
            } else {
                const isExist = fileList.find((item) => item.status != 'success')
                if (!isExist) {
                    this.rowForm.attachmentList = fileList.map((item) => {
                        if (item.response && item.response.data) {
                            return item.response.data
                        }
                    })
                    this.saveForm()
                } else {
                    this.$message.warning('存在文件尚未完成上传')
                }
            }
        },
        async saveForm() {
            const isOk = this.checkForm()
            if (!isOk) return false
            const obj = { ...this.rowForm }
            obj.startTime = obj.leaseTerm[0]
            obj.endTime = obj.leaseTerm[1]
            obj.roomIds = obj.roomTable.map((item) => item.id)
            obj.contractRentReqs = this.contractRentReqs

            obj.details = this.contractRentReqs[0].detailedList
            obj.rentInterval = this.contractRentReqs[0].intervaledList.map(item => {
                return { startTime: item.startTime, endTime: item.endTime, rent: item.adjustRent }
            })
            if (this.contractRentReqs.length > 0 && this.contractRentReqs[0].payServiceList.length >= 0) {
                const payServiceList = this.contractRentReqs[0].payServiceList
                obj.payServiceIds = payServiceList.map(item => item.id).join()
            }
            this.loadingForm = true
            if (this.isAdd) {
                addContractItem(obj)
                    .then((res) => {
                        this.$message.success('新增成功')
                        this.showEditAll = false
                        this.getInit()
                        this.loadingForm = false
                    })
                    .catch((error) => {
                        this.loadingForm = false
                    })
            } else {
                obj.attachmentList = obj.attachmentList.map((item) => {
                    return item.attachmentUrl || item
                }) // 移走
                editContractItem(obj)
                    .then((res) => {
                        this.$message.success('修改成功')
                        this.showEditAll = false
                        this.loadingForm = false
                        this.getInit()
                    })
                    .catch((err) => {
                        this.loadingForm = false
                    })
            }
        },
        // 计费频率的切换,情况
        frequencySelect() {
            this.$set(this.rowForm, 'month', '')
            this.$set(this.rowForm, 'day', '')
        },
        // 设置“计租开始日期”
        setBillingTime() {
            this.$set(
                this.rowFormData[this.indexObj.billingTime],
                'defaultValue',
                this.rowForm.leaseTerm[0]
            )
            const billingTime = this.rowForm.billingTime
            if (billingTime) {
                const startDate = this.rowForm.leaseTerm[0]
                const endDate = this.rowForm.leaseTerm[1]
                const { start, end, t } = this.filtersToTime({
                    startDate,
                    endDate,
                    time: billingTime
                })
                if (t < start || t > end) {
                    this.$set(this.rowForm, 'billingTime', '')
                }
            }
        },
        // 选择合同起始时间
        selectDate() {
            // 判断 生成房间信息 时 的合同时间，与当前的是否一致
            // 增加计租开始时间的判断
            if (this.roomFormActiveDate.length > 0) {
                const now = this.rowForm.leaseTerm
                const active = this.roomFormActiveDate
                const oldBillingTime = this.oldBillingTime
                const nowBillingTime = this.rowForm.billingTime
                if (now[0] !== active[0] || now[1] !== active[1]) {
                    // 如果与生成房间时时不一致，提示
                    this.$confirm(
                        `生成“房间信息”时的“合同起始时间”为“${active[0]} 至 ${active[1]}”，与当前的“合同起始时间不一致”，请进行以下选择`,
                        '提示',
                        {
                            distinguishCancelAndClose: true,
                            confirmButtonText: '清空房间信息',
                            cancelButtonText: '恢复原合同时间',
                            showClose: false,
                            closeOnClickModal: false
                        }
                    )
                        .then(() => {
                            this.$set(this.rowForm, 'roomTable', [])
                            // 清空租金设置
                            this.contractRentReqs = []
                            this.setBillingTime()
                        })
                        .catch((action) => {
                            if (action === 'cancel') {
                                // 点击了恢复原合同时间
                                this.$set(this.rowForm, 'leaseTerm', [...this.oldDateRage])
                            }
                        })
                } else if (oldBillingTime !== nowBillingTime) {
                    // 如果与生成房间时时不一致，提示
                    this.$confirm(
                        `生成“房间信息”时的“计租开始时间”为“${oldBillingTime}”，与当前的“计租开始时间不一致”，请进行以下选择`,
                        '提示',
                        {
                            distinguishCancelAndClose: true,
                            confirmButtonText: '清空房间信息',
                            cancelButtonText: '恢复原计租开始时间',
                            showClose: false,
                            closeOnClickModal: false
                        }
                    )
                        .then(() => {
                            this.$set(this.rowForm, 'roomTable', [])
                            // 清空租金设置
                            this.contractRentReqs = []
                            this.setBillingTime()
                        })
                        .catch((action) => {
                            if (action === 'cancel') {
                                // 点击了恢复原合同时间
                                this.$set(this.rowForm, 'billingTime', this.oldBillingTime)
                            }
                        })
                }
            } else {
                this.oldDateRage = [...this.rowForm.leaseTerm]
                this.setBillingTime()
            }
        },

        // 处理转换时间戳
        filtersToTime({ startDate, endDate, time }) {
            const start = new Date(startDate).getTime()
            const end = new Date(endDate).getTime()
            const t = new Date(time).getTime()
            return { start, end, t }
        },
        // 选择驻户
        selectNenant() {
            this.isSelectPeople = true
        },
        selectRoom() {
            if (!this.rowForm.contractType) return this.$message.warning('请选择合同类型')
            if (!this.rowForm.leaseTerm) return this.$message.warning('请选择租期')
            this.selectedRooms = [...this.rowForm.roomTable]
            this.isSelectRoom = true
        },
        // 选择房间额外的请求参数
        rqParmasFather_room() {
            return {
                // startTime: this.rowForm.leaseTerm[0],
                // endTime: this.rowForm.leaseTerm[1]
            }
        },
        // 选择房间
        selectRow2({ list }) {
            this.isSelectRoom = false
            this.selectedRooms = list
            this.rowForm.roomTable.splice(0, this.rowForm.roomTable.length, ...list)
            this.contractRentReqs = []
            this.$set(this.rowForm, 'bizPayServiceNewDTOS', [])
            // 重新选择房间时记录清空房间信息标记
            this.isclearRoom = true
        },
        // 编辑/新增弹窗 表格里 的 "删除"
        rowDelete_formTable(row, info, button) {
            this.rowForm.roomTable.splice(info.$index, 1)
            this.contractRentReqs = []
            this.$set(this.rowForm, 'bizPayServiceNewDTOS', [])
        },
        // 配置费用
        expenseAllocation(row) {
            row.roomId = row.roomId | row.id
            this.custombizPayServiceNewDTOS(row.roomId, this.oldRoomId)
            const { contractType, id, leaseTerm, billingTime } = this.rowForm
            let payServiceList = []
            if (this.contractRentReqs.length <= 0) {
                if (this.rowForm.bizPayServiceNewDTOS) {
                    payServiceList = [...this.rowForm.bizPayServiceNewDTOS]
                }
            }
            this.$refs.rentA.init({ ...row, contractType, contractId: id, leaseTerm, billingTime, clear: this.isclearRoom }, this.contractRentReqs, payServiceList)
        },
        // 取消
        reset() {
            // 保存时恢复记录清空房间标记
            this.isclearRoom = false
            this.showEditAll = false
        },
        async rqOpsitions() {
            this.loadingForm = true
            const index = this.rowFormData.findIndex(
                (item) => item.key == 'contractType'
            )
            this.util.rqOptionsList({
                who: [this.rowFormData[index]],
                rqUrl: '/sys/dictData/contract_type',
                resRule: 'data'
            })
            const length = this.rowFormData.findIndex(item => item.key == 'tenantId')
            await rqTenant({ pageIndex: 1, pageSize: 200 }).then((res) => {
                if (res.code == 200) {
                    const data = res.data.data.map((item) => {
                        return {
                            label: item.tenantName,
                            value: item.id,
                            cardCode: item.idCard ? item.idCard : item.licenseNum
                        }
                    })
                    this.$set(this.rowFormData[length], 'options', data)
                }
            }
            )
        },
        getContractRentReqs(data) {
            const { billingArea, unitPrice, initRent, payServiceList, detailedList, incrementType, intervaledList, incrementTime, billingTime } = data
            this.contractRentReqs = [{ billingArea, unitPrice, initRent, payServiceList, detailedList, incrementType, intervaledList, incrementTime, billingTime }]
            // 保存时恢复记录清空房间标记
            this.isclearRoom = false
        },
        tenantSelect(clo, val) {
            const options = clo.options
            const length = options.findIndex(item => item.value == val)
            this.$set(this.rowForm, 'cardCode', options[length].cardCode)
        },
        // 恢复物管合同其它收费项
        custombizPayServiceNewDTOS(roomId, oldRoomId) {
            if (roomId === oldRoomId) {
                this.$set(this.rowForm, 'bizPayServiceNewDTOS', this.oldBizPayServiceNewDTOS)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.frequencyDay {
    /deep/ .el-select {
        width: 100px;
        margin: 0 8px;
    }

    /deep/ .el-form-item__label {
        width: auto !important;
    }

    /deep/ .el-form-item__content {
        margin-left: auto !important;
    }
}

.elDialog {
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
}

/deep/.selectFrom {
    width: 600px;
}

/deep/.select-two {
    float: right;
    width: 60% !important;
}

/deep/ .el-input-number .el-input__inner {
    padding-left: 0;
}

.day {
    width: 35% !important;
    margin: 0 10px;
}
</style>
