<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="show" width="40%" :title="d">
        <ja-form :rules="[]" allDisabled v-model="rowForm" :formArray="rowFormData" v-if="show"></ja-form>
    </el-dialog>
</template>
<script>
export default {
    data () {
        return {
            rowFormData: [
                { input_type: 'select', label: '空间类型', key: 'spaceTypeName', col: 11,labelWidth:'100px'},
                { input_type: 'input', label: ' 是否规划编号', key: 'planStatusName', col: 11 ,labelWidth:'100px'},
                { input_type: 'input', label: ' 建筑面积', key: 'outsideArea', col: 11, styleWidth: `85%`, rightBox: { input_type: 'text', text: '㎡' },labelWidth:'100px' },
                { input_type: 'input', label: ' 空间编号', key: 'spaceCode', col: 11 ,labelWidth:'100px'},
                { input_type: 'input', label: ' 状态', key: 'spaceStatusName', col: 11 ,labelWidth:'100px'},
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', placeholder: '请输入200字以内的描述', col: 22, maxlength: 200 }
            ],
            show: false, // 显示新增/编辑弹窗,
            rowForm: {},
        }
    },
    methods: {  
        init (row) {
            this.rowForm = {planStatusName:['是','否'][row.planStatus],...row}
            this.show = true
            if(row.planStatus === 1){
                this.rowFormData[3].hide = true
            }else{
                this.rowFormData[3].hide = false
            }
        }
    }
}
</script>
