<template>
    <!-- 设备列表 -->
    <!-- rowDetail: 详情操作->详情面板 -->
    <!-- append: 新增操作->新增面板 -->
    <!-- rowEdit: 编辑操作->编辑面板 -->
    <!-- rowDelete: 删除操作->删除面板 -->
    <!-- getInit: 获取ajax数据 -->
    <!-- outPut_: 导出数据 -->
    <div class="allwh minWidth1000">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="append" @rowEdit="rowEdit" @getInit="getInit" :valueFormat="formatter" @rowDelete="rowDelete" @lookRoom="lookRoom" @search="search" @reset="reset">
        </ja-table>
        <!-- 新增/编辑/查看 -->
        <add-edit-show ref="addEditShow"></add-edit-show>
    </div>
</template>

<script>
import {
    getBuildingList,
    delBuilding,
} from '@/api'
import table from "@/views/mixins/table"
import AddEditShow from './components/AddEditShow.vue'
export default {
    title: '楼栋管理',
    mixins: [table],
    name: 'index',
    components: {
        AddEditShow,
    },
    data () {
        return {
            queryTableId: 'building_info',
            filterData: { // 默认页码
                pageIndex: 1,
                pageSize: 10
            },
            conditionData: [
                { input_type: 'input', key: 'buildingName', attribute: { placeholder: '楼栋名称', size: 'small', } },
                { text: '查询', input_type: 'button', method: 'search', size: 'small', attribute: { type: 'primary', icon: 'el-icon-search' } },
                { text: '重置', input_type: 'button', method: 'reset', size: 'small', attribute: { type: 'default' } },
                { text: '新增', input_type: 'button', method: 'append', size: 'small', attribute: { type: 'primary', icon: 'el-icon-plus', limit: 'building::operate::add', plain: true } }
            ],
            columns: [ // 表头数据
                { label: '楼栋名称', prop: 'buildingName', name: "buildingName" },
                { label: '描述', prop: 'remark', name: "remark" },
                {
                    label: '操作', input_type: 'button', width: 200, attribute: { fixed: "right", align: 'center' },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'building::operate::show' },
                        { text: '编辑', method: 'rowEdit', limit: 'building::operate::edit' },
                        { text: '删除', method: 'rowDelete', limit: 'building::operate::delete' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,
        }
    },
    methods: {
        getInit: function () {
            this.loading = true
            // 获取楼栋信息列表
            getBuildingList(this.filterData).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.data
                    this.total = res.data.total
                    this.loading = false
                }
            })
        },
        // 新增
        append () {
            this.$refs.addEditShow.init(1)
        },
        // 编辑
        rowEdit: function (row) {
            this.$refs.addEditShow.init(2, row)
        },
        //查看
        lookRoom: function (row) {
            this.$refs.addEditShow.init(3, row)
        },
        // 删除
        rowDelete (row) {
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delBuilding(row.id).then(res => {
                    if (res.code == 200) {
                        this.getInit()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
