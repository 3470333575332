import http from './plugin/ajax'

// 水抄表
export const getWaterMeter = (data) => http.post('/waterMeter/query', data)

// 电抄表
export const getElectricityMeter = (data) => http.post('/electricityMeter/query', data)

// 新增公摊水电
export const addPublicWaterElectricity = (data) => http.post('/undertake/electricity/water', data)
// 查询公摊水电
export const getPublicWaterElectricity = (data) => http.post('/undertake/electricity/water/list', data)
// 生成公摊费
export const generatePublicWaterElectricity = (data) => http.get('/undertake/electricity/water/bill/detail', { params: data })