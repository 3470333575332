<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="show"
                   width="55%"
                   title="设置保证金">
            <ja-form :rules="rules"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     @reset="reset"
                     @save="saveForm"
                     @deleteTabelRow="deleteTabelRow"
                     @selectServiceName="selectServiceName"
                     v-if="show">

                <template #addTabel>
                    <el-button type="text"
                               size="mini"
                               style="float:right"
                               @click="addTabel">添加一项</el-button>
                </template>
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { getNewBondList, setNewBondList, getUsageRecordList } from '@/api'
export default {
    inject: ['getInit'],
    props: {
        allDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rowForm: { bzjDTOS: [] },
            rules: {},

            rowFormData: [
                { input_type: 'customize', customize: 'addTabel', col: 24 },
                {
                    input_type: 'table', col: '24', noSearch: true, showPage: false, key: 'bzjDTOS', isRequired: true, border: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', },
                        { column_type: 'select', label: '收费项目名称', prop: 'serviceName', options: [], placeholder: '请选择', method: 'selectServiceName' },
                        { label: '收费项目性质', prop: 'natureCharges' },
                        { label: '收费标准名称', prop: 'feesName', width: 180 },
                        { label: '收费标准', prop: 'fees', },
                        { column_type: 'number', label: '金额', prop: 'unitPrice', input_type: 'number', clearable: false, styleWidth: '100%', min: 0, precision: 2, attribute: { controlsPosition: "right", controls: false } },
                        {
                            label: '操作', input_type: 'button', attribute: { fixed: "right", align: 'center' },
                            list: [
                                { text: '删除', method: 'deleteTabelRow' },

                            ]
                        },
                    ]
                }
            ],
            tableData: [],
            show: false,
            evidenceCode: null

        }
    },
    mounted() {
        getNewBondList({ pageIndex: 1, pageSize: 100, serviceType: '保证金' }).then(res => {
            let data = res.data.data
            data.map(item => {
                item.label = item.serviceName
                item.value = item.serviceName
            })
            this.rowFormData[1].columns[1].options = data
            this.tableData = data
        })
    },
    methods: {
        async init(row) {
            this.evidenceCode = row.contractCode
            let res = await getUsageRecordList({ evidenceCode: this.evidenceCode, serviceType: '保证金类', pageIndex: 1, pageSize: 100 })
            res.data.data.map(item => {
                let arr = item.fees.split('：')
                if (arr.length == 2) {
                    item.natureCharges = '临时性'
                    item.feesName = `${item.serviceName}收费标准`
                    item.fees = arr[0]
                    item.unitPrice = arr[1] || 0.00
                }
            })
            this.rowForm.bzjDTOS = res.data.data
            this.show = true


            let ids = res.data.data.map(item => {
                return item.serviceName
            })
            console.log(ids);
            let arr = this.tableData.filter(item => {
                if (ids.indexOf(item.serviceName) < 0) {
                    return item
                }
            })
            console.log(arr);
            this.rowFormData[1].columns[1].options = arr

        },
        reset() {
            this.show = false
        },
        saveForm() {
            let obj = { ...this.rowForm }
            obj.evidenceCode = this.evidenceCode
            if (obj.bzjDTOS) {
                let isOk = true
                obj.bzjDTOS.map(item => {
                    if (isNaN(item.id)) {
                        isOk = false
                    }
                })
                if (!isOk) return this.$message.error('请填写完整其它收费项')
                obj.bzjDTOS.map(item => { item.fees = `${item.fees}：${item.unitPrice}` })
            }
            setNewBondList(obj).then(res => {
                this.show = false
                this.getInit()
            })
        },
        addTabel() {
            this.rowForm.bzjDTOS.push({})
        },
        deleteTabelRow(row, bt) {
            this.rowForm.bzjDTOS.splice(bt.$index, 1)
            let length = this.tableData.findIndex(item => item.serviceName == row.serviceName)
            if (length < 0) return false
            this.rowFormData[1].columns[1].options.push(this.tableData[length])
        },
        selectServiceName(row, val, index, $index) {

            let length = this.tableData.findIndex(item => item.serviceName == val)

            this.$set(this.rowForm.bzjDTOS, $index, { ...this.tableData[length] })

            let ids = this.rowForm.bzjDTOS.map(item => {
                return item.serviceName
            })
            let arr = this.tableData.filter(item => {
                if (ids.indexOf(item.serviceName) < 0) {
                    return item
                }
            })
            this.rowFormData[1].columns[1].options = arr
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.input_text {
    border: 0 !important;
    padding: 0 !important;
}
</style>
