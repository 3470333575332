<template>
    <div class="allwh">
        <el-row :gutter="50"
                v-loading="headLoading">
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#536ec4' }">
                    <p style="white-space: nowrap;">总合同数</p>
                    <div class="testBox">{{hendViewData.totalContract || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#8dc571' }">
                    <p style="white-space: nowrap;">待提交合同</p>
                    <div class="testBox">{{hendViewData.toBeSubmittedAmount || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#f59a22' }">
                    <p style="white-space: nowrap;">审核中合同</p>
                    <div class="testBox">{{hendViewData.unreviewedAmount || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#2234f5' }">
                    <p style="white-space: nowrap;">已驳回合同</p>
                    <div class="testBox">{{hendViewData.rejectedAmount || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#8dc571' }">
                    <p style="white-space: nowrap;">已生效合同</p>
                    <div class="testBox">{{hendViewData.takeEffectAmount || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#780202' }">
                    <p style="white-space: nowrap;">已失效合同</p>
                    <div class="testBox">{{hendViewData.loseEfficacyAmount || 0}}</div>
                </el-card>
            </el-col>
        </el-row>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="false"
                  :valueFormat="formatter"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @setBond="setBond"
                  @rowDetail="rowDetail">
        </ja-table>

        <!-- 详情 -->
        <detail ref="detail" />
        <set-bond ref="setBondRef"></set-bond>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import Detail from './childComp/details.vue'
import { getArchivesList, getArchivesHead } from '@/api'
import SetBond from "./childComp/setBond.vue"

export default {
    title: '合同档案',
    mixins: [table],
    name: 'index',
    components: {
        SetBond,
        Detail
    },
    data () {
        return {
            conditionData: [
                {
                    input_type: 'input',
                    key: 'contractCode',
                    attribute: { placeholder: '合同编号', size: 'small' }
                },
                {
                    input_type: 'input',
                    key: 'contractName',
                    attribute: { placeholder: '合同名称', size: 'small' }
                },
                {
                    input_type: 'input',
                    key: 'tenantName',
                    attribute: { placeholder: '驻户名称', size: 'small' }
                },
                {
                    input_type: 'select',
                    key: 'tenantType',
                    attribute: { placeholder: '驻户类型', size: 'small' },
                    options: [
                        { label: '所有', value: '' },
                        { label: '商业', value: '0' },
                        { label: '办公', value: '1' },
                    ]
                },
                { input_type: 'select', key: 'contractType', attribute: { placeholder: '合同类型', size: 'small' }, options: [] },
                { input_type: 'date', value: 'date', type: 'monthrange' },
                { input_type: 'date', value: 'contractBatch', type: 'month', placeholder: '选择合同批次', valueFormat: 'yyyy-MM' },
                {
                    input_type: 'select',
                    key: 'tenancyCountdown',
                    attribute: { placeholder: '租期倒计时', size: 'small' },
                    options: [
                        { label: '0-30', value: '1' },
                        { label: '31-90', value: '2' },
                        { label: '大于90', value: '3' },
                        { label: '已到期', value: '4' }
                    ]
                },
                {
                    input_type: 'select',
                    key: 'status',
                    attribute: { placeholder: '审核状态', size: 'small' },
                    options: [
                        { label: '已通过', value: 2 },
                        { label: '已失效', value: 4 }
                    ]
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/contract/export', rqMethod: 'post' }, limit: 'contractFile::index::export' }
            ],
            columns: [
                { label: '合同编号', prop: 'contractCode' },
                { label: '合同名称', prop: 'contractName' },
                { label: '合同类型', prop: 'contractTypeName' },
                { label: '租期', prop: 'leaseTerm', width: 150 },
                { label: '计租开始日期', prop: 'billingTime' }, 
                { label: '租期倒计时', prop: 'tenancyCountdown' },
                { label: '驻户名称', prop: 'tenantName' },
                { label: '驻户租赁单元', prop: 'roomInfo' },
                { label: '合同批次', prop: 'contractBatch' },
                { label: '审批状态', prop: 'status' },

                {
                    label: '操作',
                    input_type: 'button',
                    width: 140,
                    attribute: {
                        fixed: 'right',
                        showOverflowTooltip: false,
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'rowDetail', limit: 'contractFile::index::rowDetail' },
                        { text: '保证金', method: 'setBond', limit: '' }
                    ]
                }
            ],
            tableData: [],
            filterData: {},
            hendViewData: {},
            headLoading: false
        }
    },
    mounted () {
        const index = this.conditionData.findIndex(
            (item) => item.key == 'contractType'
        )
        console.log(index)
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqUrl: '/sys/dictData/contract_type',
            resRule: 'data'
        })
    },
    methods: {
        getInit () {
            const rqData = this.rqParmas()
            getArchivesList(rqData).then((res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
            getArchivesHead(2).then(res => {
                this.hendViewData = res.data
                this.headLoading = false
            })
        },
        rqParmas () {
            return {
                tenantType:this.filterData.tenantType,
                tenancyCountdown: this.filterData.tenancyCountdown,
                pageSize: this.filterData.pageSize,
                pageIndex: this.filterData.pageIndex,
                status: this.filterData.status,
                contractCode: this.filterData.contractCode || '',
                tenantName: this.filterData.tenantName || '',
                contractBatch: this.filterData.contractBatch || '',
                contractName: this.filterData.contractName || '',
                startTime: this.filterData.date ? this.filterData.date[0] : '',
                endTime: this.filterData.date ? this.filterData.date[1] : '',
                contractType: this.filterData.contractType || '',
                queryFlag: 2
            }
        },
        rowDetail (row) {
            row.roomInfo.map((e) => {
                e.building = e.buildingName
                e.floor = e.floorName
                e.category = e.roomCategoryName
            })
            row.statusName = { 2: '已通过', 4: '已失效' }[Number(row.status)]
            this.$refs.detail.rowEdit(row)
        },
        formatter: function (val, key) {
            if (key === 'status') {
                return { 2: '已通过', 4: '已失效' }[val || 0]
            }
            if (key === 'roomInfo') {
                if (val && val.length > 0) {
                    const text = val.map((item) => item.roomName).join('，')
                    return text || ''
                } else {
                    return ''
                }
            }
            return val || ''
        },
        setBond (row) {
            this.$refs.setBondRef.init(row)
        }
    }
}
</script>

<style lang="scss" scoped>
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;
    .el-card {
        color: #fff;
        border-radius: 0.4vw;
        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 2vw;
        }
    }
}
</style>
