<template>
    <div>
        <ja-table :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  v-model="filterData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  ref="approvalList"
                  showSelect
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @handleSelectionChange="handleSelectionChange"
                  @batchPrint="batchPrint"
                  @showDillImg="showDillImg"
                  @showDetails="showDetails" />

        <add-edit ref="receiptRefs"></add-edit>
        <div class="dill_img">
            <div ref="dillImg" >
                <dill-img  v-for="item in selectRows" :key="item.id" :row="item" style="page-break-after:always"></dill-img>
            </div>
        </div>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import AddEdit from '../components/AddEdit'
import DillImg from '../components/dillImg.vue'
import { getBillList } from '@/api'
export default {
    title: '应收账款审批-已下发',
    mixins: [table],
    name: 'distributed',
    components: {
        AddEdit,
        DillImg
    },
    data () {
        return {
            activeName: '1',
            conditionData: [
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '租赁单元名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '计费周期开始时间', endPlaceholder: '计费周期结束时间' },
                { input_type: 'date', type: 'month', value: 'billDate', placeholder: '账单日期' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { input_type: 'button', method: "batchPrint", text: '批量打印', icon: '', limit: 'collectFees::approvalList::batchPrint', size: '', normalBtnList: {} }
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', width: 200 },
                { label: '租赁单元名称', prop: 'roomName', width: 200 },
                { label: '月固定租金', prop: 'rent', width: 200 },
                { label: '物业管理费', prop: 'propertyManagementFee', width: 200 },
                { label: '电费', prop: 'electricity' },
                { label: '设备维护费服务费（电）', prop: 'iptElectricity', width: 200 },
                { label: '水费', prop: 'water', width: 200 },
                { label: '设备维护费服务费（水）', prop: 'iotWater', width: 200 },
                { label: '计费周期', prop: 'date', width: 200 },
                { label: '账单时间', prop: 'billDate', width: 200 },
                { label: '预计合计', prop: 'amountReceivable', width: 200 },
                { label: '状态', prop: 'approvalStatusName', width: 200 },

                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '查看', method: 'showDetails', limit: 'collectFees::approvalList::showDetails' },
                        { text: '预览', method: 'showDillImg', limit: 'collectFees::approvalList::showDetails' }
                    ]
                }
            ],
            tableData: [],
            total: 0,
            selectRows: [],
            // 打印开关
            printShow: false
        }
    },
    methods: {
        getInit () {
            this.loading = true
            getBillList(this.rqParmas()).then(res => {
                res.data.data.map(item => {
                    item.date = `${item.billDateStart}~${item.billDateEnd}`
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        showDetails (row) {
            this.$refs.receiptRefs.init(2, { ...row })
        },
        handleSelectionChange (val) {
            this.selectRows = val
        },
        rqParmas () {
            const obj = { ...this.filterData, status: 3, approvalStatus: "3" }
            if (obj.date) {
                obj.billDateStart = obj.date[0]
                obj.billDateEnd = obj.date[1]
                delete obj.date
            }
            return obj
        },
        /** 批量打印 */
        batchPrint () {
            if (this.selectRows.length === 0) {
                this.$message.warning("至少要选择一个驻户！")
                return
            }
            this.print()
        },
        showDillImg (row) {
            this.$refs.approvalList.$refs.jaTable.clearSelection()
            this.selectRows = [row]
            this.print()
        },
        // 打印表单关闭
        printClose (printShow) {
            this.printShow = printShow
        },
        // 打印
        print () {
            this.printClose(true)
            // 延迟等待打印表渲染
            setTimeout(() => {
                this.$print(this.$refs.dillImg, {
                    noPrint: '.noPrint',
                    onEnd: () => {
                        // 打印结束后将数组置空，页面节点清零
                        this.selectRows = []
                        console.log('打印完成')
                    }
                })
            }, 1000)
        }
    }
}
</script>

<style scoped>
.dill_img{
    width: 100%;
    position: absolute;
    z-index: -100;
    bottom: 10px;
}
</style>
