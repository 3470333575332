<template>
  <div>
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="show"
      width="40%"
      :title="titleText"
      v-loading="addLoading"
      :element-loading-text="isAdd ? '新增中' : '编辑中'"
    >
      <ja-form
        :rules="rules"
        v-model="rowForm"
        :formArray="rowFormData"
        @reset="show = false"
        @save="saveForm"
        v-if="show"
      >
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { rqBillConfirmReceipt } from '@/api'
export default {
  components: {
    JaForm
  },
  inject: ['labelWidth', 'getInit'],
  data() {
    return {
      rules: {
        amountWriteOff: [
          {
            required: true,
            message: '请输入收款费用',
            trigger: 'blur'
          },
          {
            validator: function (rule, value, callback) {
              if (/^[0-9]*[1-9][0-9]*$/.test(value) == false) {
                callback(new Error('请输入正整数'))
              } else {
                //校验通过
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        paymentDate: [
          {
            required: true,
            message: '请选择收款日期',
            trigger: 'blur'
          },
        ]
      },
      rowFormData: [
        {
          input_type: 'input',
          label: '到账费用',
          key: 'amountWriteOff',
          rightBox: { input_type: 'text', text: '元' },
          col: 18,
          styleWidth: '80%',
          labelWidth: '130px',
          placeholder: '请输入收款费用',
          maxlength: 10
        },
        {
          input_type: 'date',
          label: '到账日期',
          key: 'paymentDate',
          col: 18,
          labelWidth: '130px',
          styleWidth: '80%',
          valueFormat: 'yyyy-MM-dd',
          placeholder: '请选择收款日期',
          // pickerOptions: {
          //   disabledDate(date) {
          //     return date.getTime() <= Date.now()
          //   }
          // }
        },
      ],
      show: false,
      rowForm: {},
      addLoading: false,
      penson: {},
      currentData: ''
    }
  },
  methods: {
    init(row) {
      this.show = true
      this.titleText = '到账登记'
      this.rowForm = { id: row.id }
    },
    saveForm() {
      this.addLoading = true
      rqBillConfirmReceipt(this.rowForm)
        .then((res) => {
          if (res.code !== 200) return this.$message.error('确认收款失败')
          this.$message.success('确认收款成功')
          this.show = false
          this.addLoading = false
          this.getInit()
        })
        .catch((err) => {
          this.addLoading = false
        })
    }
  },
  mounted() {
    let that = this
  }
}
</script>
<style long="scss" scoped>
.dialog-footer {
  margin-right: 50px;
  float: right;
}
</style>