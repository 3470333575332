<template>
    <div>
        <el-col :span="12"
                v-loading="loading">
            <div class="box"
                 ref="wrap"></div>
        </el-col>
    </div>
</template>

<script>
import { getGraphicalBar } from '@/api'
export default {
    title: '报修记录',
    data() {
        return {
            loading: false
        }
    },
    mounted() {
        this.setOpsition()
    },
    methods: {
        async setOpsition() {
            let { quantityCompletion, statisticsDate, sumamount } = await this.getInit()
            let option = {
                title: {
                    text: '报修趋势（7天）',
                    left: 'left',
                    textStyle: {
                        fontSize: 16
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        data: statisticsDate,

                        axisLabel: {
                            formatter: function (value) {
                                let strArr = value.split('-')
                                if (strArr.length >= 3) {
                                    strArr.shift()
                                }
                                return strArr.join('-');
                            }
                        }

                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '(次)',
                        axisLine: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        }
                    }
                ],
                grid:{
                    top:70,
                    bottom:40
                },
                series: [
                    {
                        name: '已完成',
                        type: 'bar',
                        stack: 'Ad',
                        barWidth: 30,
                        emphasis: {
                            focus: 'series'
                        },
                        data: quantityCompletion,
                        itemStyle: {
                            normal: {
                                color: '#f59a22'
                            },
                        },
                    },
                    {
                        name: '报修',
                        type: 'bar',
                        stack: 'Ad',
                        barWidth: 30,
                        emphasis: {
                            focus: 'series'
                        },
                        data: sumamount,
                        itemStyle: {
                            normal: {
                                color: '#555555'
                            },
                        },
                    }

                ]
            }
            const myChart = this.$echarts.init(this.$refs.wrap)
            myChart.setOption(option)
            window.addEventListener('resize', () => {
                myChart.resize();
            })
        },
        async getInit() {
            this.loading = true
            let obj = { quantityCompletion: [], statisticsDate: [], sumamount: [] }
            await getGraphicalBar().then(res => {
                res.data.map(e => {
                    obj.quantityCompletion.push(e.quantityCompletion)
                    obj.statisticsDate.push(e.statisticsDate)
                    obj.sumamount.push(e.sumamount)
                })
            })
            this.loading = false
            return obj
        }
    }
}
</script>
<style scoped>
.box {
    width: 100%;
    height: 100%;

    background: #ffffff;
}
.el-col {
    height: 400px;
}
</style>