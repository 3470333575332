export default {
    beforeRouteEnter(to, from, next) {
        // console.log('to--->',to,from)
         next(vm => {
                let query = to.query
                let keys = Object.keys(to.query)
                // console.log("keys----",keys)
                if(keys.length>0){
                    let isNotOk = ['tenantName','tenantCode','id'].find(item=>{
                        let isExist = keys.includes(item)
                        if(isExist){
                            return false
                        }else{
                            return true
                        }
                    })
                    // console.log('是否存在---》',isNotOk)
                    if(isNotOk){ // 找到缺少的字段了，不予进来当前页面
                        vm.$message.warning('地址跳转方式不正确！')
                        vm.$router.replace({path:'*'})
                        return
                    }else{
                        vm.tenantName = query.tenantName
                        vm.tenantCode = query.tenantCode
                        vm.tenantId = query.id
                        vm.getInit()
                        // console.log('---------------',vm.tenantId,query.id)
                        next()
                    }
                }else{
                    vm.$message.warning('地址跳转方式不正确！')
                    vm.$router.replace({path:'*'})
                    return
                }
           
        })

    },
}