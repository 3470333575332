<template>
  <!-- 表格 -->
  <div class="content">
    <div class="echart">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import {progressStatistics} from '@/api'
export default {
  components: {
    JaInitChart,
  },
  data() {
    return {
      options: null
    }
  },
  created() {
    // this.init()
  },
  methods: {
    init(param){
      console.log("统计---------------",param)
      progressStatistics(param).then(res=>{
        let data=res.data
         let initData=[
	                {value:Number(data.progressStatus1), name:'未开始'},
	                {value:Number(data.progressStatus2), name:'初步接触'},
	                {value:Number(data.progressStatus3), name:'洽谈阶段'},
	                {value:Number(data.progressStatus4), name:'交付诚意金'},
	                {value:Number(data.progressStatus5), name:'交付定金'},
	                {value:Number(data.progressStatus6), name:'正式签约'},
	                {value:Number(data.progressStatus7), name:'暂停跟进'},
	      ]
        this.setOptions(initData)
      })
    },
    async setOptions(initData=[]) {
      console.log(initData);
      this.options = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        color:['#008080','#8400FF','#6D000E','#4352D4','#FFA500','#70B603','#D9001B'],
        legend: {
          orient: 'vertical',
          top:'middle',
          height: '80%',
          right:'16%',
          itemGap: this.util.setFontSize(10, 1920),
          textStyle: { //图例文字的样式
            fontSize: this.util.setFontSize(15, 1920),
          },
          formatter: function (val) {
            let item = initData.find(v => v.name == val)
            return `${val}  ${item.value}  个`;
          },
          data: ['未开始','初步接触','洽谈阶段','交付诚意金','交付定金','正式签约','暂停跟进'],
        },
        series: [
          {
	            name: '项目进度统计',
	            type: 'pie',
	            radius : '55%',
	            center: ['40%', '50%'],
	            data:initData,
	            itemStyle: {
	                emphasis: {
	                    shadowBlur: 10,
	                    shadowOffsetX: 0,
	                    shadowColor: 'rgba(0, 0, 0, 0.5)'
	                }
	            },
	            itemStyle: {
	                normal: {
	                    label:{ 
                            show: false, 
	                          position:'inside',
                            formatter: '{b} : {c} ({d}%)' 
                        }
	                },
                    labelLine :{show:true}
	            }
	        }
        ]

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  width:100%;
  height: 320px;
  background: #fff;
  border-radius: 10px
}
.echart {
  height: 100%;
  width: 100%;

}
.title{
        font-size: 22px;
        font-weight: bold;
        margin: 20px 0;
        color: #333;
        text-align: center;
    }
</style>