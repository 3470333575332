<template>
    <div>
         <el-row :gutter="50">
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#536ec4' }">
                    <p style="white-space: nowrap;">本期合计应收（元）</p>
                    <div class="testBox">{{amountReceivable.toFixed(2) || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#8dc571' }">
                    <p style="white-space: nowrap;">本期实际应收（元）</p>
                    <div class="testBox">{{actualReceivable.toFixed(2) || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#f59a22' }">
                    <p style="white-space: nowrap;">本期合计实收（元）</p>
                    <div class="testBox">{{amountWriteOff.toFixed(2) || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: '#025478' }">
                    <p style="white-space: nowrap;">未缴金额（元）</p>
                    <div class="testBox">{{amountUnpaid.toFixed(2) || 0}}</div>
                </el-card>
            </el-col>
        </el-row>
        <div class="tabsBox">
            <el-tabs v-model="activeName">
                <el-tab-pane label="全部" name="0">
                    <ToBeWriteOff v-if="activeName === '0'"></ToBeWriteOff>
                </el-tab-pane>
                <el-tab-pane label="待核销" name="1">
                    <ToBeWriteOff v-if="activeName === '1'" writeOffStatus="0"></ToBeWriteOff>
                </el-tab-pane>
                <el-tab-pane label="部分核销" name="2">
                    <ToBeWriteOff v-if="activeName === '2'" writeOffStatus="3"></ToBeWriteOff>
                </el-tab-pane>
                <el-tab-pane label="已核销" name="3">
                    <WrittenOff v-if="activeName === '3'"></WrittenOff>
                </el-tab-pane>
                <el-tab-pane label="已欠费" name="4">
                    <Arrears v-if="activeName === '4'"></Arrears>
                </el-tab-pane>
                <el-tab-pane label="部分核销欠费" name="5">
                    <ToBeWriteOff v-if="activeName === '5'" writeOffStatus="4"></ToBeWriteOff>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import {getBillStatistics} from '@/api'
import ToBeWriteOff from "./components/toBeWrittenOff.vue"
import WrittenOff from "./components/writtenOff.vue"
import Arrears from "./components/arrears.vue"

export default {
    title: '账单核销',
    mixins: [table],
    name: 'billWriteOff',
    components: {
        Arrears,
        WrittenOff,
        ToBeWriteOff
    },
    data () {
        return {
            activeName: '0',
            amountReceivable: 0,
            actualReceivable: 0,
            amountWriteOff: 0,
            amountUnpaid: 0
        }
    },
    methods: {
        getInit () {
            // 初始化金额数据
            this.loading = true
            const date = new Date()
            const lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())
            const params = {
                pageIndex: 1,
                pageSize: 10,
                approvalStatus: 3,
                writeOffStatus: 0,
                billMonth: this.$moment().format("YYYY-MM")
            }
            getBillStatistics(params).then(res => {
                this.loading = false
                this.amountReceivable = res.data.amountReceivable
                this.actualReceivable = res.data.actualReceivable
                this.amountWriteOff = res.data.amountWriteOff
                this.amountUnpaid = res.data.amountUnpaid
            })
        }
    }
}
</script>

<style lang="scss"  scoped>
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;
    .el-card {
        color: #fff;
        border-radius: 0.4vw;
        width: auto;
        white-space: nowrap;
        p {
            font-size: 0.9rem;
        }
        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 0.9rem;
        }
    }
}
.tabsBox {
    padding: 10px 18px;
    overflow: hidden;
}
.el-tabs {
    padding: 10px 10px;
    background-color: #fff !important;
    /deep/.el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll {
        background-color: #fff !important;
    }
}
</style>
