<template>
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :tableHeadButtons="tableHeadButtons"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @handleSelectionChange="handleSelectionChange"
      @rowDetail="rowDetail"
      @append="append"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @getInit="getInit"
      :valueFormat="formatter"
      :editDataFormat="editDataFormat"
      @search="search"
      @reset="reset"
    >
    <template #bottomLeft>
      <el-button :size="'mini'" :type="'text'" @click="outPut">
          导出
      </el-button>
    </template>
    </ja-table>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="记录详情" :width="`60%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='8'>
      </ja-detail>
    </el-dialog>
    <!-- 新增/编辑 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title="titleText">
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        @reset="showEditAll = false"
        @save="saveForm" v-if="showEditAll">
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
// import {rqBusList,addBusItem,editBusItem,delBusItem} from '@/api'

export default {
  title: '模板页面',
  mixins: [table],
  name: 'index',
  components: {
   
  },
  data () {
    return {
      outPutExcell_obj:{
        outPutExcell_url:'/query/export/vehicleApplyExport',
        limit:'admin::rsVehicleApply::export'
      },
      labelWidth: `${this.util.setFontSize(150)}px`,
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'queryParam',
          attribute: {
            placeholder: '申请人，入库单号',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          type: "datetimerange",
          value: 'time', 
          startPlaceholder:'入库时间',
          endPlaceholder:'入库时间',
          attribute: {
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'settleFlag',
          attribute: {
            placeholder: '是否结清',
            size: 'small'
          },
          options: [
            { label: '未结清', value: 0 },
            { label: '已结清', value: 1 }
          ]
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon:'el-icon-search',
          }
        },
        {
          text: '清空',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon:'el-icon-plus',
            plain:true
          },
          rightPos:true,
          limit:'admin::repairApply::add'
        },
      ],
      columns: [
        { label: '入库编号', prop: 'applyCode' ,},
        { label: '申请人', prop: 'employeeName' },
        { label: '关联采购申请单号', prop: 'purchaseCode'},
        { label: '入库明细', prop: 'detail', width: 300,attribute:{showOverflowTooltip:true,align:'center'} },
        { label: '入库时间', prop: 'stockingTime'},
        { label: '是否结清', prop: 'settleFlag' },
        { label: '备注', prop: 'remark'},
        {
          label: '操作',
          input_type: 'button',
          width:70,
          attribute:{
            fixed:"right",
            align:'center'
          },
          list: [
            { text: '查看', method: 'rowDetail' },
            { text: '编辑', method: 'rowEdit' },
            { text: '删除', method: 'rowDelete' },
          ]
        }
      ],
      tableData: [
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: '是' },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: '是' },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: '是' },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: '是' },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: '是' },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: '是' },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: '是' }
      ],
      loading: false,
      total: 30,
      // 详情
      showDetail: false,
      detailOptions:
        [
          { label: '基本信息', isTitle: true, col: 24 },
          { label: '入库编号', key: 'applyCode', col: 24 },
          { label: '申请人', key: 'employeeName' },
          { label: '入库时间', key: 'stockingTime' },
          { label: '仓库名称', key: 'warehouseName' },
          { label: '关联采购申请单号', key: 'purchaseCode'},
          { label: '申请明细', key: 'phone', type: 'customize', customize: 'table', col: 24 },
          { label: '送货单号', key: 'deliveryCode', col: 24 },
          { label: '送货凭证', key: 'deliveryImgs', type: 'img', col: 24 },
          { label: '是否结清', key: 'settleFlag', type: 'switch', col: 24 },
          { label: '备注', key: 'remark', col: 24 },
          { label: '使用详情', key: 'applyDetail' ,type:'table',noSearch:true,showPage:false,showIndex:false,col:24,
            columns:[
                      { label: '用车周期', prop: 'age',},
                      { label: '申请人', prop: 'employeeName'},
                    ],
          },
        ],
      detailData: {},
      detailTable: [
        { label: '申请人', prop: 'employeeName' },
        { label: '公司', prop: 'orgName' },
        { label: '部门', prop: 'deptName' },
        { label: '员工编号', prop: 'employeeId' },
        { label: '单位', prop: 'articleUnit' },
        { label: '采购数量', prop: 'purchaseNum' },
        { label: '已入库', prop: 'stockedNum' },
        { label: '本次入库', prop: 'stockingNum' }
      ],
      // 新增/编辑
      titleText:'编辑',
      rowFormData: [
        { input_type: 'title',text:'租金计费',col:24,noMarginBottom:true},
        {input_type: 'input',label: '违停车牌号', key: 'plateNumber',maxlength:20},
        {input_type: 'select',label: '公司', key: 'orgName',options: [],},
        {input_type: 'input',label: '车主姓名', key: 'owner',valueType:'number',type:'number'},
        {input_type: 'select',label: '部门', key: 'deptName',options: [],},
        {input_type: 'input',label: '联系电话', key: 'mobile'},
        {input_type: 'radio',label: '状态', key: 'status',col:24,options:[
            {label:'已处理',value:1},
            {label:'未处理',value:0},
        ]},
        {input_type: 'date',label: '时间',type:'datetimerange', key: 'useCarTime',format:'yyyy-MM-dd HH:mm',col:16,pickerOptions:{
          disabledDate:this.disabledDateFun
        }},
        {input_type: 'customize',customize:'uplpadfaceImg',label: '附件',key: 'urlLists',col:24,},
        {label: '备注',key: 'problemDetail',input_type: 'input',type:'textarea',col:22,maxlength:500,showWordLimt:true,rows:5},
        { input_type: 'table',label: '班次列表', key: 'routines',col:24,showPage:false,noSearch:true,table_MaxHeight:200,
         columns:[
           { label: '发车时间', prop: 'departTime',column_type:'time',valueFormat:'HH:mm:ss',format:'HH:mm'},
           { label: '绑定车牌号', prop: 'plateNumbers',column_type:'select',multiple:true,
              options:[]},
           { label: '操作',input_type: 'button',width:100,attribute:{fixed:"right",align:'center'},
            list: [
              { text: '增加', method: 'rowAdd_formTable',key:'routines' },
              { text: '删除', method: 'rowDelete_formTable',key:'routines' },
            ]
          }
         ],
        },
        {input_type: 'table',label: '站点名称', key: 'stations',OtherKey:'pointName',otherRule:'pointName',col:24,showPage:false,noSearch:true,table_MaxHeight:200,
         columns:[
           { label: '站点名称', prop: 'pointName',column_type:'input'},
           { label: '操作',input_type: 'button',width:100,attribute:{fixed:"right",align:'center'},
            list: [
              { text: '增加', method: 'rowAdd_formTable' ,key:'stations'},
              { text: '删除', method: 'rowDelete_formTable' ,key:'stations' },
            ]
          }
         ],
        },
        {input_type: 'checkbox',label: '运营时间', key: 'days',col:24, method:'checkboxItemFun',options:[
          {label:'星期一',value:1},
          {label:'星期二',value:2},
          {label:'星期三',value:3},
          {label:'星期四',value:4},
          {label:'星期五',value:5},
          {label:'星期六',value:6},
          {label:'星期日',value:7},
        ],allBoxObj:{
            label:'全选',value:0,checkAll:false,
            method:'checkAllFun',value_arr:[1,2,3,4,5,6,7]
          }
        },
      ],
      rules: {
        plateNumber: [
          { required: true, message: '请输入违停车牌号', trigger: 'blur' }
        ],
        orgName: [
          { required: true, message: '请选择公司', trigger: 'change' }
        ],
        deptName: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ],
        mobile: [
          { required: true, trigger: 'blur', validator:this.util.formRules.checkPhone()}
          
        ],
        num:[
          { required: true, trigger: 'blur', validator:this.util.formRules.checkNumber({max:9999})}
        ],
        owner: [
          { required: true, message: '请输入车主名称', trigger: 'blur',}
        ],
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      indexObj:{},
    }
  },
  created(){
      // 这种方式，不用通过索引
      this.indexObj = this.util.getArrItemIndex({arr:this.conditionData,})
      this.util.rqOptionsList({who:[this.conditionData[this.indexObj.category]],rqUrl:"/sys/dictData/tenant_category",resRule:'data'}) 
     // 顶部搜索框
      this.util.rqOptionsList({who:this.conditionData[1],rqUrl:"/sys/dictData/aaa",rqData:'dormitory_apply',rqMethod:'post'})
      this.util.rqOptionsList({who:[this.conditionData[2],this.rowFormData[6]],rqUrl:"/sys/dictData/aaa",rqData:'dormitory_apply',rqMethod:'post'})
      // 表单里的
      this.util.rqOptionsList({who:this.rowFormData[1],rqUrl:"/dormitory/aaa",labelName:'buildingCode',valueName:'id'})
      this.util.rqOptionsList({who:this.rowFormData[4],rqUrl:"/sys/dictData/aaa",rqData:'apply_room_type',rqMethod:'post'}).then(res=>{
        this.rowFormData[4].allBoxObj.value_arr = res.map(item => item.value)
      })
  },
  methods: {
    // 点击 全选
    checkAllFun(){
      this.util.formFun.checkAllFun(this.rowFormData[2].allBoxObj,this.rowForm.days,this.rowFormData[2].allBoxObj.value_arr)
    },
    // 点击 多选框 的单项
    checkboxItemFun(){
      this.util.formFun.checkboxItemFun(this.rowFormData[2].allBoxObj,this.rowForm.days,this.rowFormData[2].allBoxObj.value_arr)
    },
    editDataFormat(row,btn){
      if((row.status==2|| row.status==3) && btn.text=='预约'){
        return false
      }else{
        return true
      }
    },
     handleSelectionChange (val) {
      console.log('-------val', val)
      const ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      this.delSelectionIds.splice(0, this.delSelectionIds.length, ...ids)
    },
    formatter (val, key) {
      if (key === 'status') {
        return ['预约中', '预约成功','已取消'][val || 0]
      }
      return val || ''
    },
    outPut(){
      let rqobj={
        queryParam: this.filterData.queryParam?this.filterData.queryParam:'',
        settleFlag: this.filterData.settleFlag?this.filterData.settleFlag:'',
        startTime: this.filterData.time ? this.$moment(this.filterData.time[0]).format("YYYY-MM-DD HH:mm:ss") : '',
        endTime: this.filterData.time ? this.$moment(this.filterData.time[1]).format("YYYY-MM-DD HH:mm:ss") : ''
      }
      reqExportInStorageRecord(rqobj).then(res=>{
        this.util.dealExcelRespose(res,'入库记录')
      })
    },
    disabledDateFun(time){
       return time.getTime() < Date.now();//当天之后的时间可选
    },
   
    getInit () {
      this.loading=true
      reqInStorageRecord({
        pageSize: this.filterData.pageSize,
        pageIndex: this.filterData.pageIndex,
        parameter: {
          queryParam: this.filterData.queryParam,
          settleFlag: this.filterData.settleFlag,
          startTime: this.filterData.time ? this.$moment(this.filterData.time[0]).format("YYYY-MM-DD HH:mm:ss") : '',
          endTime: this.filterData.time ? this.$moment(this.filterData.time[1]).format("YYYY-MM-DD HH:mm:ss") : ''
        }
      }).then(res => {
        console.log(res)
        this.loading=false
        this.tableData = res.rows.map(item=>{
          let obj={...item}
          obj.detail=""
          let articleVos=item.articleVos
          if(articleVos&&articleVos.length>0){
            articleVos.forEach(v=>{
              obj.detail=obj.detail+v.articleName+'+'+v.articleModel+'+'+v.stockingNum+'；'
            })
          }else{
            obj.detail=""
          }
          return obj
        })
        this.total = res.total
      })
    },
    // 删除
    rowDelete (row) { 
      this.$confirm('是否确认删除人员信息').then(res => {
        delUser(
          {parameter:{userId:row.userId}}
        ).then(res=>{
          this.$message.success("已删除")
          this.getInit()
        })  
      })
    },
    // 保存
    saveForm () {
      if (this.isAdd) {
        console.log('新增===》',this.rowForm)
        addBusItem(this.rowForm).then(res => {
          this.$message.success('新增成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          // this.$message.error(error)
        })
      } else {
        editBusItem(this.rowForm).then(res => {
          this.$message.success('修改成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          // this.$message.error(error)
        })
      }
    },
    append(){
      this.isAdd=true
      this.titleText='新增'
      this.rowForm={}
      this.showEditAll = true
    },
    rowEdit (row) {
      //  console.log('row===',row)
      this.isAdd=false
      this.titleText='编辑'
     
      this.rowForm={...row}
      this.showEditAll = true
    },
    rowDetail (row) {
      this.showDetail = true
      this.detailData = row
    },
    rowNewMile(row,a,btn){
      console.log('点击了')
      if(btn.text.includes('出')){
        this.titleText_door = '登记表单（出园登记）'
      }else{
        this.titleText_door = '登记表单（入园登记）'
      }
      this.rowForm_door = row
      this.isShowDoorForm = true
    },
     // 编辑/新增弹窗 表格里 的 新增
    rowAdd_formTable (row, info, button) {
      // console.log('点击了站点新增',row,info,button)
      if (button.key == 'stations') {
        this.rowForm.stations.splice(info.$index + 1, 0, {})
      }
    },
    // 编辑/新增弹窗 表格里 的 "删除"
    rowDelete_formTable (row, info, button) {
      // console.log('删除===》',row)
      if (button.key == 'stations') {
        if (info.$index == 0 && this.rowForm.stations.length == 1) {
          this.$message.warning('仅为一行，禁止删除')
        } else {
          this.rowForm.stations.splice(info.$index, 1)
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
