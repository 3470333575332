<template>
    <div class="allwh">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" :editDataFormat="editDataFormat" @getInit="getInit" @search="search" @reset="reset" @rowDelete="rowDelete" @append="normalEvent({ ref: 'addEdit', method: 'append' })" @showSpace="showSpace" @rowEdit="normalEvent"></ja-table>
        <add-eidt ref="addEdit" />
        <show-space ref="showSpace"></show-space>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEidt from './childComp/AddEdit.vue'
import ShowSpace from './childComp/ShowSpace.vue'
import {
    spaceManageList,
    delSpaceItem
} from '@/api'

export default {
    title: '单元管理',
    mixins: [table],
    name: 'index',
    components: {
        AddEidt,
        ShowSpace
    },
    data () {
        return {
            conditionData: [
                {
                    input_type: 'input',
                    key: 'spaceCode',
                    attribute: { placeholder: '空间编号', size: 'small' }
                },
                {
                    input_type: 'select',
                    key: 'spaceStatus',
                    attribute: { placeholder: '全部状态', size: 'small' },
                    options: [
                        { label: '正常', value: 0 },
                        { label: '关闭', value: 1 },
                    ]
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'spaceManage::operate::add' }
            ],
            columns: [
                { label: '空间类型', prop: 'spaceTypeName' },
                { label: '规划空间编号', prop: 'spaceCode' },
                { label: '建筑面积', prop: 'outsideArea' },
                { label: '状态', prop: 'spaceStatusName' },
                { label: '描述', prop: 'remark' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 140,
                    attribute: {
                        fixed: 'right',
                        showOverflowTooltip: false,
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'showSpace', limit: 'spaceManage::operate::show' },
                        { text: '编辑', method: 'rowEdit', limit: 'spaceManage::operate::edit' },
                        { text: '删除', method: 'rowDelete', limit: 'spaceManage::operate::delete' }
                    ]
                }
            ],
            tableData: [],
            filterData: {
                pageIndex: 1,
                pageSize: 10
            }
        }
    },
    methods: {
        getInit () {
            this.loading = true
            spaceManageList(this.filterData).then((res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        // 删除
        rowDelete (row) {
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delSpaceItem(row.id).then((res) => {
                    if (res.code == 200) {
                        this.getInit()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        editDataFormat (row, btn) {
            // 使用状态为启用时(0启用 1停用)，隐藏开启按钮
            if (row.useStatus === 0 && btn.text === '开启') {
                return false
            } else {
                return true
            }
        },
        showSpace (row) {
            this.$refs.showSpace.init(row)
        }
    }
}
</script>
