<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag
               :close-on-click-modal="false"
               :visible.sync="show"
               width="55%"
               :title="title">
       <div class="tableNumber" v-loading="loading">
        <div class="numberBox" v-for="item in dateArr">
            <p class="date">{{item.day}}</p>
            <p :class="['title', item.turnover == '未上报' ? 'black' : '']">{{item.turnover }}</p>
        </div>
       </div>
    </el-dialog>
</template>
<script>
import { getturnoverdetail } from '@/api'
export default {
    data() {
        return {
            show: false,
            allDisabled: true,
            title:'明细',
            dateArr:[],
            dateArrBox:[
                { day:'', turnover:''},
                { day:'', turnover:''},
                { day:'', turnover:''},
                { day:'', turnover:''}
            ],
            loading:false
        }
    },
    methods: {
        rowDetail(row) {
            this.loading = true
            this.dateArr = []
            this.title = row.tenantName
            this.dateArrBox[3].day = Number(row.date.slice(-2)) + '月合计'
            getturnoverdetail({
                "id": row.id,
                "date": row.date,
            }).then(res=>{
                console.log(res);
                this.dateArrBox[3].turnover = res.data[res.data.length - 1].totalTurnover
                console.log(res.data.length);
                if(res.data.length < 31){
                    for(let i = 0;i <= 31 - res.data.length;i++){
                        res.data.push({ day: '', turnover: '' },)
                    }
                }
                this.dateArr = res.data.concat(this.dateArrBox)
                this.loading = false
            })
            this.show = true
        },
    }
}
</script>
<style lang="scss" scoped>
.tableNumber{
    min-height: 350px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #000;
    >div{
        height: 75px;
        width: 14.28%;
        text-align: center;
        .date{
            height: 25px;
            line-height: 25px;
            font-size: 14px;
            border: 1px solid #000;
        }
        .title{
            height: 50px;
            line-height: 50px;
            font-size: 14px;
            border: 1px solid #000;
            color: #169BD5
        }
        .black{
            color: #333;
        }
    }
}
</style>