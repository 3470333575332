<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="45%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowForm"
                     labelWidth="150px"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     @selectCharges="selectCharges"
                     @selectType="selectType"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { editChargingSettings } from '@/api'
export default {
    inject: ['labelWidth', 'getInit'],
    data() {
        return {
            value: null,
            rules: {
                feesName: [{ required: true, trigger: 'change', message: '收费标准名称不能为空' }],
                unitPrice: [{ required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2, min: 0 }) }],
            },
            rowFormData: [
                { input_type: 'input', key: 'serviceCode', label: '收费项目编号', col: 11, disabled: true },
                { input_type: 'input', key: 'serviceType', label: '收费项目类型', col: 11, disabled: true },
                { input_type: 'input', key: 'natureCharges', label: '收费项目性质', col: 11, disabled: true },
                { input_type: 'input', key: 'dayName', label: '缴费周期', col: 11, disabled: true },
                { input_type: 'input', key: 'serviceName', label: '收费项目名称', col: 11, disabled: true },
                { input_type: 'input', key: 'feesName', label: '收费标准名称', col: 11 },
                { input_type: 'select', key: 'fees', label: '收费标准', col: 11, disabled: true },
                { input_type: 'input', key: 'unitPriceOld', label: '原始单价（元）', col: 11, disabled: true },
                { input_type: 'input', key: 'unitPrice', label: '变更单价（元）', col: 11 },

            ],
            title: '查看收费项使用记录',
            show: false,
            allDisabled: false,
            rowForm: {},
            lengthA: null
        }
    },
    methods: {
        init(row) {
            this.rowForm = {}
            this.title = '变更收费标准'
            this.show = true
            //unitPriceOld 变更单价
            this.rowForm = { ...row, unitPriceOld: row.unitPrice, unitPrice: null }
        },
        saveForm() {
            let obj = {
                ...this.rowForm,
                isEffective: 2
            }
            this.$confirm('是否确定变更该收费项目？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                editChargingSettings(obj).then(res => {
                    this.show = false
                    this.getInit()
                })
                this.$message({
                    type: 'success',
                    message: '变更成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },

    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>