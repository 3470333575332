<template>
    <div>
        <el-row :gutter="50"
                v-loading="headLoading">
            <el-col :span="5">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(83, 110, 196, 1)' }">
                    <p style="white-space: nowrap;">租赁单元总数</p>
                    <div class="testBox">{{Math.round(hendViewData.totalRoom) || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="5">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(141, 197, 113, 1)' }">
                    <p style="white-space: nowrap;">待租租赁单元</p>
                    <div class="testBox">{{Math.round(hendViewData.notRentAmount) || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="5">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(250, 200, 89, 1)' }">
                    <p style="white-space: nowrap;">已租租赁单元</p>
                    <div class="testBox">{{Math.round(hendViewData.rentOutAmount) || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="5">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(238, 102, 102, 1)' }">
                    <p style="white-space: nowrap;">租赁单元出租率</p>
                    <div class="testBox">{{Math.round(hendViewData.occupancyRate) || 0}}%</div>
                </el-card>
            </el-col>

        </el-row>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @append="append"
                  @rowEdit="rowEdit"
                  @getInit="getInit"
                  :valueFormat="formatter"
                  @search="search"
                  @reset="reset"
                  @rowDelete="rowDelete"
                  @lookRoom="lookRoom">
        </ja-table>
        <add-edit ref="addEdit"></add-edit>
        <show-unit ref="showUnit"></show-unit>
    </div>
</template>

<script>
import {
    getRentalUnitList,
    deleteRentalUnit,
    getRentalUnitHead
} from '@/api'
import table from "@/views/mixins/table"
import AddEdit from './components/AddEdit.vue'
import ShowUnit from './components/showUnit.vue'
export default {
    title: '租赁单元管理',
    mixins: [table],
    name: 'rentalUnit',
    components: {
        AddEdit,
        ShowUnit,
    },
    data() {
        return {
            conditionData: [ // 表格上方的搜索/选择操作栏
                {
                    input_type: 'input',
                    key: 'roomName',
                    attribute: {
                        placeholder: '凭租单元名称',
                        size: 'small'
                    },

                },
                {
                    input_type: 'select', label: '楼栋', key: 'buildingId', options: [],
                    relevance: {
                        sonKey: 'floorId',
                        This: this,
                        rqParam: {
                            rqUrl: '/floor/allList',
                            rqData: () => {
                                return { params: {} }
                            },
                            labelName: 'floorName',
                            valueName: 'floorId',
                            resRule: 'data'
                        }
                    }
                },
                { input_type: 'select', label: '楼层', key: 'floorId', options: [], },
                {
                    text: '查询',
                    input_type: 'button',
                    method: 'search',
                    size: 'small',
                    attribute: {
                        type: 'primary',
                        icon: 'el-icon-search'
                    }
                },
                {
                    text: '重置',
                    input_type: 'button',
                    method: 'reset',
                    size: 'small',
                    attribute: {
                        type: 'default'
                    }
                },
                { text: '新增', input_type: 'button', method: 'append', size: 'small', attribute: { type: 'primary', limit: '', plain: true }, limit: 'financeModule::rentalUnit::add' },
            ],
            columns: [ // 表头数据
                {
                    label: '楼栋',
                    prop: 'buildingName'
                },
                {
                    label: '楼层',
                    prop: 'floorName'
                },
                {
                    label: '租赁单元名称',
                    prop: 'roomName'
                },
                {
                    label: '租赁单元类型',
                    prop: 'roomTypeValue'
                },
                {
                    label: '套内总体使用面积',
                    prop: 'totalArea'
                },
                {
                    label: '绑定空间',
                    prop: 'remark'
                },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: "right",
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'financeModule::rentalUnit::show' },
                        { text: '编辑', method: 'rowEdit', limit: 'financeModule::rentalUnit::edit' },
                        { text: '删除', method: 'rowDelete', limit: 'financeModule::rentalUnit::delete' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,
            hendViewData: {},
            headLoading: false
        }
    },
    mounted() {
        let index = this.conditionData.findIndex(
            (item) => item.key == 'buildingId'
        )
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqUrl: '/building/list',
            rqMethod: 'post',
            labelName: 'buildingName',
            valueName: 'buildingId',
            resRule: 'data'
        })
        this.$bus.$on("addUnitResut", this.getInit)
    },
    methods: {
        getInit: function () {
            this.loading = true
            this.headLoading = true
            // 获取楼层信息列表
            getRentalUnitList(this.filterData).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.data
                    this.total = res.data.total
                    this.loading = false
                }
            })
            getRentalUnitHead().then(res => {
                let data = res.data
                data.occupancyRate = (data.rentOutAmount / data.totalRoom) * 100
                this.hendViewData = data
                this.headLoading = false
            })
        },
        lookRoom(row) {
            this.$refs.showUnit.init(row)
        },
        rowEdit(row) {
            this.$refs.addEdit.initAction(2, row)
        },
        append() {
            this.$refs.addEdit.initAction(1)
        },
        rowDelete(row) {
            console.log(row);
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteRentalUnit(row.id).then(res => {
                    if (res.code == 200) {
                        this.getInit()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        beforeDestroy() {
            this.$bus.$off('addUnitResut')
        }
    }
}
</script>

<style lang="scss" scoped>
.heandBatchAdd {
    height: 1.667vw;
}
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;
    .el-card {
        color: #fff;
        border-radius: 0.4vw;
        width: auto;
        white-space: nowrap;
        p {
            font-size: 1.3rem;
        }
        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 2vw;
        }
    }
}
</style>
