<template>
  <!-- 设备管理-设备类型 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 单独导出 -->
  <!-- outPutExcell_obj：导出数据 封装导出-->
  <div class="allwh">
    <div class="allwh-wrapper minWidth600">
      <formCont ref="tst"
                v-model="filterData"
                :conditionData="conditionData"
                :tableData="tableData"
                :total="total"
                :loading="loading"
                @append="append"
                @rowEdit="rowEdit"
                @rowDelete="rowDelete"
                @getInit="getInit">
      </formCont>

      <!-- 新增/编辑 -->
      <!-- reset: 取消 -->
      <!-- saveForm: 保存操作 -->
      <el-dialog v-dialogDrag :close-on-click-modal="false"
                :visible.sync="showEditAll"
                width="24%"
                :title="titleText">
        <ja-form :rules="rules"
                :label-width="labelWidth"
                v-model="rowForm"
                :formArray="rowFormData"
                @reset="showEditAll = false"
                @save="saveForm"
                v-if="showEditAll"
                v-loading="loading_form.isShow"
                :element-loading-text="loading_form.text"
                :element-loading-spinner="loading_form.icon"
                :element-loading-background="loading_form.bg"
                :element-loading-custom-class="loading_form.formLoading">
        </ja-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
import formCont from './components/form'
import { queryAll, deviceCategoryAdd, deviceCategoryEdit, deviceCategoryDelete } from '@/api'

export default {
  title: '设备类型',
  mixins: [table],
  name: 'index',
  data () {
    return {
      queryTableId: 'iot_device_category',
      // filterData: { pageIndex: 1, pageSize: 10 }, // 默认页码
      conditionData: [{ text: '新增大类', input_type: 'button', method: 'append', size: 'small', attribute: { type: 'primary', icon: 'el-icon-plus', plain: true } }], // 表格上方的搜索/选择操作栏
      tableData: [],
      loading: false,
      total: '',
      // 新增/编辑
      titleText: '编辑',
      rowFormData: [{ input_type: 'input', label: '小类名称', key: 'category_name', col: 24 }],
      rules: { category_name: [{ required: true, message: '请输入小类名称', trigger: 'blur' }] },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(100)}px`
    }
  },
  methods: {
    getInit: function () { // 获取设备类型列表
      this.loading = true
      const paramJSON = {
        pageIndex: 1,
        pageSize: 200
      }
      const paramObj = this.util.paramChange(paramJSON, this.queryTableId)
      queryAll(this.queryTableId, paramObj).then(res => {
        this.loading = false
        if (res.data.data.length > 0) {
          const result = []
          const code = []
          res.data.data.map(item => {
            if ((item.category_code) && (item.category_code).length === 2) { // 设备大类
              if (code.indexOf(item.category_code) === -1) {
                code.push(item.category_code)
                const json = item
                item.label = item.category_code + '  ' + item.category_name
                item.isShow = false
                item.del_flag_bz = item.del_flag === 0// del_flag: 0启用 1禁用
                const children = []
                res.data.data.map(item2 => { // 设备小类
                  if (item2.category_code) {
                    if (((item2.category_code).substring(0, 2)).indexOf(item.category_code) != -1 && (item2.category_code).length != 2) {
                      item2.label = item2.category_code + '    ' + item2.category_name
                      item2.isShow = false
                      item2.del_flag_bz = item.del_flag === 0
                      children.push(item2)
                    }
                  }
                })
                json.children = children
                result.push(json)
              }
            }
          })
          this.tableData = result
        } else {
          this.tableData = res.data.data
        }
      })
    },
    rowDelete: function (row) { // 删除小类
      this.loading_form.isShow = true
      const del_flag = row.del_flag === 0 ? 1 : 0 // del_flag: 0启用 1禁用
      const text = row.del_flag === 0 ? '禁用' : '启用'
      this.$confirm(`是否确认${text}该类型${row.category_code}_${row.category_name}？`).then(res => {
        const param = row.id + '/' + del_flag
        deviceCategoryDelete(param).then(res => {
          this.loading_form.isShow = false
          this.$message.success(res.msg)
          this.getInit()
        }).catch(error => {
          this.loading_form.isShow = false
          this.$message.success(error)
          this.getInit()
        })
      })
    },
    append: function (mark) {
      this.rowForm = {}
      if (mark.text === '新增大类') {
        this.rowFormData[0].label = '大类名称'
        this.rules.category_name[0].message = '请输入大类名称'
      } else {
        this.rowFormData[0].label = '小类名称'
        this.rules.category_name[0].message = '请输入小类名称'
        this.rowForm.parentId = mark.id
      }
      this.isAdd = true
      this.titleText = '新增'
      this.showEditAll = true
    },
    rowEdit: function (row) {
      this.isAdd = false
      this.titleText = '编辑'
      this.rowFormData[0].label = '小类名称'
      this.rules.category_name[0].message = '请输入小类名称'
      this.rowForm = { ...row }
      this.showEditAll = true
    },
    saveForm: function () { // 保存
      this.loading_form.isShow = true
      if (this.isAdd) {
        const paramObj = this.util.paramChange(this.rowForm, '', 'edit')
        deviceCategoryAdd(paramObj).then(res => {
          this.loading_form.isShow = false
          this.$message.success(res.msg)
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          this.loading_form.isShow = false
          this.$message.success(error)
        })
      } else {
        const paramObj = this.util.paramChange(this.rowForm, '', 'edit')
        deviceCategoryEdit(paramObj).then(res => {
          this.loading_form.isShow = false
          this.$message.success(res.msg)
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          this.loading_form.isShow = false
          this.$message.success(error)
        })
      }
    }
  },
  components: {
    formCont
  }
}
</script>

<style scoped>
</style>
