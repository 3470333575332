<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  :valueFormat="valueFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @showResident="showResident"> </ja-table>

        <show-detail ref="detailRef"></show-detail>
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import { getUsageRecordList } from '@/api'
import ShowDetail from './components/showDetail.vue'
export default {
    title: '收费项使用记录',
    name: 'usageRecord',
    mixins: [table],
    components: {
        ShowDetail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'evidenceCode', attribute: { placeholder: '合同编号', size: 'small', } },
                { input_type: 'input', key: 'billCode', attribute: { placeholder: '账单编号', size: 'small', } },
                { input_type: 'select', key: 'serviceType', attribute: { placeholder: '收费项目类型', size: 'small', }, options: [{ label: '租金类', value: '租金类' }, { label: '物管类', value: '物管类' }, { label: '保证金类', value: '保证金类' }] },
                { input_type: 'input', key: 'serviceName', attribute: { placeholder: '收费项目名称', size: 'small', } },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '合同编号', prop: 'evidenceCode' },
                { label: '账单编号', prop: 'billCode' },
                { label: '收费项目类型', prop: 'serviceType' },
                { label: '收费项目名称', prop: 'serviceName' },
                { label: '收费标准', prop: 'fees' },
                { label: '使用时间', prop: 'createTime' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: '150px',
                    list: [
                        { text: '查看', method: 'showResident', limit: 'chargeConfig::usageRecord::show' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            results: null,
        }
    },
    mounted() {
        let index = this.conditionData.findIndex(e => e.key === 'serviceType')
        this.util.rqOptionsList({
            who: this.conditionData[index],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'chargeitem_type' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        getInit() {
            getUsageRecordList(this.filterData).then(res => {
                this.tableData = res.data.data
                this.loading = false
                this.total = res.data.total
            })
        },
        showResident(row) {
            this.$refs.detailRef.init(row);
        },
        valueFormat(val, clo) {
            if (val) {
                return val
            } else {
                return '-'
            }

        }

    }
}
</script>