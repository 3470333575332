<template>
    <!-- 表格 -->
    <div class="content">
        <div class="echart"
             v-if="options">
            <div  class="title">
                年度逾期分布图
            </div>
            <ul class="subscript">
                <li><i></i>未到期金额</li>
                <li><i></i>逾期金额</li>
            </ul>
            <ja-init-chart :options="options"
                           ref="jaChart" />
        </div>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import { getAnnualStatistics } from '@/api'
export default {
    components: {
        JaInitChart,
    },
    data() {
        return {
            options: null,
            // 年份
            activeYear: '',
            activeType: '1',
            yMax: 140000,
            ydata: [],
            oldYdata: []
        }
    },
    created() {
        this.getInit()
    },
    methods: {
        getInit() {
            getAnnualStatistics().then(res => {
                this.setOptions(res.data)
            })
        },
        async setOptions(data) { 
            
            this.options ={
                            grid: {
                                left:'12%',
                                right:'12%',
                                top: "10%",
                                bottom: "25%"//也可设置left和right设置距离来控制图表的大小
                            },
                            tooltip: {
                                show: "true",
                                trigger: 'axis',
                                axisPointer: { // 坐标轴指示器，坐标轴触发有效
                                    type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                                    shadowStyle: {
                                        color: 'rgba(112,112,112,0)',
                                    },
                                },
                                formatter: (params)=>{
                                    var index = params[0].dataIndex;
                                    let item=data[index];
                                    let name=item.colour=='blue'?'逾期金额':'未到期金额'
                                    let ratio=item.ratio?params[0].axisValueLabel +  params[1].seriesName+ ' ：'+ item.ratio+'%':'' //折线环比
                                    return params[0].axisValueLabel + name + ' ：'+item.money + '<br />' + ratio
                                },
                            },
                            legend: {
                                data: ["环比", "逾期金额"],
                                top: "15%",
                                 show: false,
                                textStyle: {
                                    color: "#ffffff"
                                },
                                formatter: function (name) {
                                    console.log('数据---',name)
                                    return 'Legend ' + name;
                                }
                            },
                            xAxis: {
                                data: data.map(item=>item.month+'月'),
                                axisLine: {
                                    show: true, //隐藏X轴轴线
                                    lineStyle: {
                                                color: '#666'
                                                }
                                },
                                axisTick: {
                                    show: true //隐藏X轴刻度
                                },
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: "#666" //X轴文字颜色
                                    }
                                },
                                
                            },
                            yAxis: [{
                                    type: "value",
                                    name: "",
                                    nameTextStyle: {
                                        color: "#666"
                                    },
                                    splitLine: {
                                        show: false
                                    },
                                    axisTick: {
                                        show: true
                                    },
                                    axisLine: {
                                        show: true,
                                        lineStyle: {
                                                    color: '#666'
                                                    }
                                    },
                                    axisLabel: {
                                        show: true,
                                        textStyle: {
                                            color: "#666"
                                        }
                                    },
                                    
                                },
                                {
                                    type: "value",
                                    name: "",
                                    nameTextStyle: {
                                        color: "#666"
                                    },
                                    position: "right",
                                    splitLine: {
                                        show: false
                                    },
                                    axisTick: {
                                        show: false
                                    },
                                    axisLine: {
                                        show: false
                                    },
                                    axisLabel: {
                                        show: true,
                                        formatter: "{value} %", //右侧Y轴文字显示
                                        textStyle: {
                                            color: "#666"
                                        }
                                    }
                                },
                            ],
                            series: [{
                                    name: "逾期金额",
                                    type: "line",
                                    yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
                                    smooth: false, //平滑曲线显示
                                    showAllSymbol: true, //显示所有图形。
                                    symbol: "circle", //标记的图形为实心圆
                                    symbolSize:0, //标记的大小
                                    itemStyle: {
                                        //折线拐点标志的样式
                                        color: "#058cff"
                                    },
                                    lineStyle: {
                                        color: "#058cff"
                                    },
                                    areaStyle:{
                                        color: "rgba(5,140,255, 0.2)"
                                    },
                                    data: data.map(item=>item.ratio)
                                },
                                {
                                    name: "环比",
                                    type: "bar",
                                    barWidth: 15,
                                    itemStyle: {
                                        normal: {
                                            color: (params)=>{
                                                return data[params.dataIndex].colour=='blue' ? '#4352d4': '#F59A23'
                                            }
                                        }
                                    },
                                    data:data.map(item=>item.money)
                                }
                            ]
                        };
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    overflow: hidden;
    border-radius: 20px;
    width: 50%;
    height: 100%;
    background: white;
    overflow: hidden;
    .selections {
        .selectBox {
            width: 120px;
        }
        .typeSelect {
            margin-left: 20px;
        }
    }
    .subscript{
        display: flex;
        justify-content: end;
        padding-right:60px;
        li{
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-right: 20px;
            i{
                display: block;
                width: 30px;
                height: 14px;
                background: #F59A23;
                margin-right: 8px;
            }
        }
        li:nth-of-type(2){
           i{
             background: #4352d4;
           }
        }
    }
}
.echart {
    height: 100%;
    width: 100%;
}
.title{
        font-size: 22px;
        font-weight: bold;
        margin: 20px 0;
        color: #333;
        text-align: center;
    }
</style>