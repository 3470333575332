<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowDelete="rowDelete" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent" @rowDetail="normalEvent">
    </ja-table>

    <!-- 新增/编辑 -->
    <add-edit ref="addEdit" />
    <detail ref="detail" />
  </div>
</template>
<script>
import { rqQuestion, delQuestion } from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit.vue'
import Detail from './childComp/Detail.vue'

export default {
  title: '客户调查_题库',
  mixins: [table,],
  name: 'index',
  components: {
    AddEdit, Detail
  },
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
        { input_type: 'select', key: 'questionCategory', attribute: { placeholder: '分类', size: 'small' }, options: [], }, // 涉及到 异步的，options 要有
        { input_type: 'select', key: 'questionType', attribute: { placeholder: '问题类型', size: 'small' }, options: [], }, // 涉及到 异步的，options 要有
        { input_type: 'input', key: 'questionCode', attribute: { placeholder: '问题编号', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add' },
      ],
      columns: [
        { label: '问题编号', prop: 'questionCode' },
        { label: '问题类型', prop: 'questionTypeName' },
        { label: '分类', prop: 'questionCategoryName' },
        { label: '问题', prop: 'questionDescription', width: 200 },
        { label: '录入时间', prop: 'createTime', width: 140 },
        { label: '录入人', prop: 'createBy' },
        { label: '启用状态', prop: 'statusName' },
        // { label: '置顶', prop: 'topValue' },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '查看', method: 'rowDetail' },
            { text: '编辑', method: 'rowEdit' },
            { text: '删除', method: 'rowDelete' },
          ]
        }
      ],
      con_indexObj: {},
    }
  },
  mounted() {
    this.con_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    let rowFormData = this.$refs.addEdit.rowFormData
    this.util.rqOptionsList({ who: [this.conditionData[this.con_indexObj.questionCategory], rowFormData[this.$refs.addEdit.indexObj.questionCategory]], rqUrl: "/sys/dictData/question_category", rqMethod: 'get', resRule: 'data' })
    this.util.rqOptionsList({ who: [this.conditionData[this.con_indexObj.questionType], rowFormData[this.$refs.addEdit.indexObj.questionType]], rqUrl: "/sys/dictData/question_type", rqMethod: 'get', resRule: 'data' })
  },
  methods: {
    rqParmas() {
      let obj = { ...this.filterData }
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = true
      let data = this.rqParmas()
      rqQuestion(data).then(res => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
      }).catch(err => {
        this.loading = false
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该题？', '操作确认').then((res) => {
        delQuestion(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
  }
}
</script>
