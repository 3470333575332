<template>
  <div class="page">
    <!-- 账单、出租率、收缴率-->
    <div class="middle2">
      <bill class="box" />
      <rents-rate class="box mid" />
      <confiscate-rate class="box" />
    </div>

    <!-- 财务收入统计、租期(X)分析 -->
    <div class="middle3">
      <financial-revenue class="energy" />
      <lease-period-analysis class="box box4"/>
    </div>
  </div>
</template>

<script>
import Bill from './childComp/Bill.vue'
import RentsRate from './childComp/RentsRate.vue'
import ConfiscateRate from './childComp/ConfiscateRate.vue'
import FinancialRevenue from './childComp/FinancialRevenue.vue'
import LeasePeriodAnalysis from './childComp/LeasePeriodAnalysis.vue'

export default {
  title: "数据看板",
  components: {
    Bill,RentsRate,ConfiscateRate, FinancialRevenue,LeasePeriodAnalysis
  }
}
</script>

<style lang="scss" scoped>
.boxCommon {
  margin: 15px 0 15px 0;
  border-radius: 20px;
  background: white;
}
.page {
  margin: 15px;
  .middle2 {
    display: flex;
    justify-content: space-between;
    .box {
      flex: 1;
      @extend .boxCommon;
      height: 400px;
      margin-top: 0;
    }
    .mid {
      margin: 0 20px;
    }
  }
  .energy{
    //   margin-right: 10px;
  }
  .middle3 {
    display: flex;
    .box4 {
      @extend .boxCommon;
      flex-shrink:0;
      width: calc(100%/3 - 40px/3) ;
      height: 500px;
      margin: 0 0 0 20px;
    }
  }
}
</style>
