<template>
    <div>
        <!-- 新增/编辑 -->
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="show"
                   width="50%"
                   :title="titleText">
            <ja-table ref="tst"
                    v-model="filterData"
                    :columns="columns"
                    :tableData="tableData"
                    :total="total"
                    noSearch
                    :loading="loading"
                    @getInit="initDetail"
                    @search="search"
                    @reset="reset"                                                                                                                         
            >
            </ja-table>
        </el-dialog>
    </div>
</template>
<script>
import {getWarehousingDetail,} from '@/api'
import table from '@/views/mixins/table'
export default {
    inject: ['getInit'],
    mixins: [table],
    data() {
        return {
            titleText: '',
            show: false,
            allDisabled: false,
            loading:false,
            columns: [
                // 表头数据
                { label: '物资名称', prop: 'materialName' },
                { label: '物资编号', prop: 'materialNumber' },
                { label: '物资类型', prop: 'materialTypeName' },
                { label: '数量（个）', prop: 'amount' },
            ],
            tableData: [],
            filterData:{}
        }
    },
    created () {
        
    },
    methods: {
        async initDetail(row) {
            this.titleText = '入库明细清单'
            this.show = true
            this.loading = true
            this.filterData.id=row.id
            await getWarehousingDetail(this.filterData).then(res=>{
                this.tableData=res.data.data
                this.total=res.data.total
            })
            this.loading = false
           
        },
        saveForm() {
            if (this.isAdd) {
                addWarehousing(this.rowForm).then(() => {
                    this.getInit()
                    this.show = false
                    this.$message.success('新增成功')
                })
            } else {
                
            }
        },
       
    }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    border: 1px solid #d9d9d9;
    margin-left: 120px;
    margin-top: -60px;
}
.deleteIcon {
    position: relative;
    bottom: 230px;
    left: 300px;
}
.deleteIcon :hover {
    color: #e92416;
}
.el-upload__tip {
    margin-left: 100px;
}
.avatar {
    width: 178px;
    height: 150px;
    display: block;
    margin-left: 120px;
    margin-top: -60px;
}
</style>