<template>
    <div>
        <div class="content">
            <div class="leftBox">
                <div class="title">工单类型</div>
                <div class="menu">
                    <el-tabs v-model="activePage" tab-position="left" style="height: 200px;">
                        <el-tab-pane v-for="(item,index) in leftMenu" :key="index" :label="item.label" :name="item.type"></el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="rightBox allwh">
                <ja-table ref="tst" v-model="filterData"
                          :conditionData="conditionData"
                          :columns="defferObj[activePage].columns"
                          :tableData="tableData"
                          :total="total"
                          :loading="loading"
                          :showIndex="true"
                          @getInit="getInit"
                          @assignRage="normalEvent({ref:'assignRage',method:'append'})"
                          @search="search"
                          @reset="reset"
                          @append="normalEvent({ref:'addEdit',method:'append'})"
                          @rowEdit="normalEvent"
                          @rowDelete="rowDelete">
                    <template #tableTop>
                        <div class="bigTitle">{{leftMenu.find(v=>v.type == activePage).label + '配置'}}</div>
                    </template>
                </ja-table>
            </div>
        </div>

        <!-- 新增/编辑 -->
        <add-edit ref="addEdit" :activePage="activePage" />

        <!-- 派单范围 -->
        <assign-rage ref="assignRage" />
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import defferObj from './defferPage'
import AddEdit from './childComp/AddEdit'
import assignRage from './childComp/assignRage'
import { getRangeWorkConfig } from '@/api'

export default {
    title: '工单配置',
    mixins: [table],
    name: 'index',
    components: {
        AddEdit, assignRage
    },
    data () {
        return {
            leftMenu: [
                { label: '维修工单', value: 0, type: 'repair', placeholder: "报修类型" },
                { label: '巡检工单', value: 3, type: 'scan', placeholder: "巡检类型" }
            ],
            activePage: 'repair',
            activePageValue: 0,
            conditionData: [
                { input_type: 'input', key: 'workOrderType', attribute: { placeholder: '报修类型', size: 'small' }, options: [] },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'workOrderManage::orderConfig::add' },
                { text: '派单范围设置', input_type: 'button', method: 'assignRage', attribute: { type: 'primary', icon: '' } }
            ],
            defferObj,
            deptIds: [],
            reciverList: []
        }
    },
    watch: {
        activePage: {
            handler: function (val) {
                this.leftMenu.forEach(item => {
                    if (item.type === val) {
                        this.conditionData[0].attribute.placeholder = item.placeholder
                    }
                })
                if (val === 'repair') {
                    console.info(this.conditionData[0])
                    this.util.rqOptionsList({
                        who: [this.conditionData[0]],
                        rqUrl: "/sys/dictData/work_order_type",
                        resRule: 'data',
                        labelName: 'dictLabel',
                        valueName: 'dictValue'
                    })
                } else if (val === 'scan') {
                    this.conditionData[0].options = [{ label: '工程巡检', value: "0" }, { label: '物业巡检', value: "1" }, { label: '运营巡检', value: "2" }]
                }
                this.deptIds = []
                const item = this.leftMenu.find(v => v.type === val)
                this.activePageValue = item.value
                this.resetDeptIds()
                this.reset()
            }
        }
    },
    created: function () {
        this.util.rqOptionsList({
            who: [this.conditionData[0]],
            rqUrl: "/sys/dictData/work_order_type",
            resRule: 'data',
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    mounted () {
        this.resetDeptIds()
    },
    methods: {
        getInit () {
            this.loading = true
            const obj = this.rqParmas()
            this.defferObj[this.activePage].getInit({ ...obj, classify: this.activePageValue }).then(res => {
                const { data, total } = res.data
                this.total = total
                this.tableData = data.map(item => {
                    return {
                        ...item,
                        employeeNames: item.employeeNameList ? item.employeeNameList.join(',') : ''
                    }
                })
                this.loading = false
            }).catch(err => {
                this.loading = false
            })
        },
        // 更新 派单范围设置
        resetDeptIds () {
            this.$refs.assignRage.loadingForm = true
            getRangeWorkConfig(this.activePageValue).then(res => {
                this.$refs.assignRage.loadingForm = false
                this.deptIds = res.data.deptIds || []
            }).catch(err => {
                this.$refs.assignRage.loadingForm = false
            })
        },
        // 删除
        rowDelete (row) {
            this.$confirm('确定删除该条配置？', '提示').then((res) => {
                this.defferObj[this.activePage].rowDelete(row.id).then((res) => {
                    this.$message.success('已删除')
                    this.getInit()
                })
            })
        }

    }
}
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    .leftBox {
        margin: 16px 0 0 15px;
        width: 200px;
        flex-shrink: 0;
        .title {
            margin-bottom: 15px;
            background-color: #ffffff;
            line-height: 40px;
            padding: 10px 15px;
            font-size: 13px;
            text-align: center;
            background: white;
            color: #606266;
        }
        .menu {
            margin-bottom: 15px;
            ::v-deep .el-tabs--left {
                .el-tabs__header.is-left {
                    width: calc(100% - 10px);
                }
                .el-tabs__item {
                    text-align: center;
                    // color: #606266;
                }
            }
            ::v-deep .el-tabs,
            ::v-deep .el-tabs__nav-scroll {
                background: white !important;
            }
        }
    }
    .rightBox {
        .bigTitle {
            color: #606266;
            font-size: 20px;
            margin: 10px 0;
        }
    }
}
</style>
