<template>
    <div>
        <ja-table :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  v-model="filterData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  ref="approvalList"
                  showSelect
                  :tableRowClassName="tableRowClassName"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @handleSelectionChange="handleSelectionChange"
                  @billApproval="billApproval"
                  @batchToExamineEvent="batchToExamineEvent" />

        <add-edit ref="receiptRefs"></add-edit>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import AddEdit from '../components/AddEdit'
import { getBillList, editbizBillInfoNew } from '@/api'
export default {
    title: '应收账款审批-待审批',
    mixins: [table],
    name: 'approval',
    components: {
        AddEdit
    },
    data () {
        return {
            conditionData: [
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '租赁单元名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '计费周期开始时间', endPlaceholder: '计费周期结束时间' },
                { input_type: 'date', type: 'month', value: 'billDate', placeholder: '账单日期' },
                { input_type: 'select', key: 'type',  attribute: { placeholder: '状态', size: 'small' }, options:[{label:'待审批',value:'0'},{label:'有修改待审批',value:'1'}] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/bizBillInfoNew/exportB', rqMethod: 'post' }, limit: 'collectFees::approvalList::export' },
                { normalBtn: 'batchToExamine' }
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', width: 200 },
                { label: '租赁单元名称', prop: 'roomName', width: 200 },
                { label: '月固定租金', prop: 'rent', width: 200 },
                { label: '物业管理费', prop: 'propertyManagementFee', width: 200 },
                { label: '电费', prop: 'electricity' },
                { label: '设备维护费服务费（电）', prop: 'iptElectricity', width: 200 },
                { label: '水费', prop: 'water', width: 200 },
                { label: '设备维护费服务费（水）', prop: 'iotWater', width: 200 },
                { label: '计费周期', prop: 'date', width: 200 },
                { label: '账单时间', prop: 'billDate', width: 200 },
                { label: '预计合计', prop: 'amountReceivable', width: 200 },
                { label: '状态', prop: 'approvalStatusName', width: 200 },

                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '账单审批', method: 'billApproval', limit: 'collectFees::approvalList::billApproval' }
                    ]
                }
            ],
            tableData: [],
            total: 0,
            selectRows: []
        }
    },
    methods: {
        getInit () {
            this.loading = true
            getBillList(this.rqParmas()).then(res => {
                res.data.data.map(item => {
                    item.date = `${item.billDateStart}~${item.billDateEnd}`
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        billApproval (row) {
            this.$refs.receiptRefs.init(1, { ...row })
        },
        handleSelectionChange (val) {
            this.selectRows = val
        },
        rqParmas () {
            const obj = { ...this.filterData, status: 3, approvalStatus: "0" }
            if (obj.date) {
                obj.billDateStart = obj.date[0]
                obj.billDateEnd = obj.date[1]
                delete obj.date
            }
            return obj
        },
        showDillImg (row) {
            this.$refs.approvalList.$refs.jaTable.clearSelection()
            this.selectRows = [row]
            this.print()
        },
        batchToExamineEvent () {
            if (this.selectRows.length <= 0) return this.$message.error('请先勾选账单')
            this.$confirm('是否确定批量审核通过？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const ids = []
                let   type=false;
                this.selectRows.map(item => {
                    if (item.approvalStatus === '0') {
                        ids.push(item.id)
                    }
                    if(item.type==1){
                        type=true;
                    }
                })
                if(type){
                    this.$confirm('当前选中数据存在修改项，执行审批后无法修改。是否批量执行?', '批量审批提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        showClose:false,
                        closeOnClickModal:false,
                        type: 'warning'
                    }).then(async () => {
                        if (ids.length <= 0) {
                            return this.$message.warning('所选数据没有待审核账单')
                        }
                        await editbizBillInfoNew({ ids: ids.join(), approvalStatus: 1 })
                        this.$message.success('批量审核通过成功')
                        this.selectRows = []
                        this.getInit()
                    }).catch(() => {})
                }else{
                    if (ids.length <= 0) {
                        return this.$message.warning('所选数据没有待审核账单')
                    }
                    await editbizBillInfoNew({ ids: ids.join(), approvalStatus: 1 })
                    this.$message.success('批量审核通过成功')
                    this.selectRows = []
                    this.getInit()
                }
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                })
            })
        },
        /**type为1 ：有修改待审批，
         * 当前行显示红色
         */
        tableRowClassName({row}){
            let type=row.type
            return type==1?"color:red":''
        }
    }
}
</script>
