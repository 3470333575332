<template>
    <div>
         <el-dialog v-dialogDrag :close-on-click-modal="false"
            :visible.sync="showDetails"
            width="55%"
            @close="close"
            :before-close="close"
            title="房间详情"
            >
            <ja-detail :options="detailOptions" :detailData="roomDetails" :showTableTitle="false">
                <template #uplpadfaceImg>
                    <upload-file ref="Upload" :initFileList="roomDetails.controlImageObj " v-if="showDetails" :noButton="true" :hideDel="true" class="imgs"/>
                </template>
            </ja-detail> 
            <div class="title">关联空间</div>
            <ja-table
                :showIndex="showDetails"
                :columns="columns"
                title="关联空间"
                :tableData="roomDetails.spaceDataList"
                :showPage='false'
            >
             
            </ja-table>
         </el-dialog>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import {spaceColumnsTwo} from '../roomManage'
export default{
    mixins: [table],
    props:{
        showDetails:{
            type: Boolean,
            default: false 
        },
        roomDetails: {
            type: Object,
            default:{}
        },
      

    },
    data(){
        return {
            //表头名称
            detailOptions:[
                //TODO l 未改名称
                {label:'房间名称',key:'roomName'},
                {label:'房间编号',key:'id'},
                {label:'所在楼栋',key:'building'},
                {label:'所在楼层',key:'floor' },
                {label:'户  型',key:'category' },
                {label:'套内面积',key:'insideArea' },
                {label:'建筑面积',key:'outsideArea' },
                {label:'房产证面积',key:'premisesPermitArea' },
                {label:'房间状态',key:'status' },
                {label:'租户名称',key:'residentName' },
                {label: '控制图', type: 'customize', customize: 'uplpadfaceImg', col: 22,alignItems:'flex-start' },
            ],
            detailData:{},
            columns:spaceColumnsTwo,
            initFileList: []

        }
    },
    methods:{
        //TODO l 未请求数据
        close(){
            this.$emit('showDetailsDialog',false)
        }
    }
}
</script>
<style lang="scss" scoped>
    /deep/ .form[data-v-4c71cf6a]{
        display: none !important;
    }
    .title{
        margin-top: 10px
    }
</style>
  