<template>
  <div class="edit">
    <el-dialog v-dialogDrag :close-on-click-modal="false"
      :title="title"
      :visible.sync="show"
      width="50%"
      @close="close"
      :before-close="close"
    >
      <ja-form :rules="rules" v-model="rowFormData" :formArray="rowForm"
        @reset="close" @save="saveForm" labelWidth="120px">
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import { editOccupantData, editOccupantRules } from '../billPaymentRecord'
export default {
  name: 'EditOccupant',
  components: {
    JaForm
  },
  data () {
    return {
      rowForm: editOccupantData,
      rowFormData: {},
      rules: editOccupantRules
    }
  },
  props: {
    title: String,
    id: String,
    show: {
      type: Boolean,
      default: false
    }
  },
  created () {
  },
  methods: {
    /** 保存表单 */
    saveForm () {
      this.close()
    },
    /** 关闭弹出框 */
    close () {
      this.$emit('closeForm', false)
    }
  }
}
</script>

<style scoped>

</style>
