<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="show"
                   width="60%"
                   title="合同变更">
            <ja-form :rules="rules"
                     ref="jaForm"
                     :label-width="labelWidth"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :loadingForm="loadingForm"
                     @reset="reset"
                     @save="saveForm"
                     @confiGuration="confiGuration"
                     @selectNenant="selectNenant"
                     @selectRoom="selectRoom"
                     @tenantSelect="tenantSelect"
                     @expenseAllocation="expenseAllocation"
                     @rowDelete_formTable="rowDelete_formTable">
                <template #household>
                    <el-button type="primary"
                               size="samll"
                               slot="label">驻户名称</el-button>
                </template>
                <template #uplpadfaceImg>
                    <upload-file @pdfTitleClickEvemt="pdfTitleClickEvemt"
                                 ref="Upload"
                                 :initFileList="[...initFileList]"
                                 :noButton="true"
                                 :hideDel="true"
                                 accept="pdf" />
                </template>
                <template #paymentDate>
                    <span>每个月的第</span>
                    <el-select v-model="rowForm.day"
                               disabled
                               class="day"
                               placeholder="请选择日期">
                        <el-option v-for="item in 28"
                                   :key="item"
                                   :label="item"
                                   :value="item"></el-option>
                    </el-select>
                    <span>日</span>
                </template>
            </ja-form>
        </el-dialog>
        <!-- 选择房间 -->
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="80%"
                   title="选择租赁单元"
                   :visible.sync="isSelectRoom"
                   v-if="isSelectRoom">
            <select-room @selectRow="selectRow2"
                         :selectedPersons="[...selectedRooms]"
                         v-if="isSelectRoom"
                         :rqParmasFather="this.rqParmasFather_room"
                         :isOnePerson="true" />
        </el-dialog>
        <!--费用配置-->
        <rent-allocation ref="rentA"
                         @getContractRentReqs="getContractRentReqs"></rent-allocation>

    </div>
</template>
<script>
import selectRoom from '@/views/home/contractManage/childComp/selectRoom.vue'
import RentAllocation from './rentAllocation.vue'
import deepCopy from '@/util/deepCopy.js'
import {
    rqTenant,
    getContractChangeDetail,
    setContracChange
} from '@/api'
export default {
    inject: ['labelWidth', 'getInit'],
    components: { selectRoom, RentAllocation },
    data() {
        return {
            //缴费计算
            frequencyCount: null,
            labelWidth: '150px',
            rowFormData: [
                { input_type: 'input', label: '合同编号', key: 'contractCode', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同名称', key: 'contractName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同类型', key: 'contractTypeName', col: 12, styleWidth: '80%', disabled: true },
                { input_type: 'date', label: '租期', key: 'leaseTerm', type: 'daterange', col: 12, valueFormat: 'yyyy-MM-dd', unlinkpanels: true, disabled: true },
                { input_type: 'date', label: '计租开始日期', key: 'billingTime', styleWidth: '80%', pickerOptions: { disabledDate: this.disabledDateFun }, valueFormat: 'yyyy-MM-dd', defaultValue: '', col: 12, styleWidth: '80%', disabled: true },
                { input_type: 'customize', customize: 'paymentDate', label: '缴费日期', key: 'day' },
                { input_type: 'select', label: '驻户名称', key: 'tenantId', col: 12, placeholder: '请选择驻户', styleWidth: '80%', maxlength: 30, options: [], method: 'tenantSelect', disabled: true, changeKey: 'tenantId' },
                { input_type: 'input', label: '营业执照号/身份证号', key: 'cardCode', col: 12, styleWidth: '80%', maxlength: 10, disabled: true },
                {
                    input_type: 'select', label: '驻户类型', key: 'tenantType', col: 12, placeholder: '请选择驻户类型', styleWidth: '80%', maxlength: 30, options: [
                        { value: '0', label: "商业" },
                        { value: '1', label: "办公" }
                    ], disabled: false
                },
                {
                    input_type: 'table',
                    changeKey: 'roomTable',
                    label: '驻户租赁单元',
                    col: 20,
                    noSearch: true,
                    showPage: false,
                    key: 'roomTable',
                    isRequired: true,
                    border: true,
                    table_MaxHeight: 500, //roomIds
                    tableAttribute: { showSummary: true },
                    columns: [
                        { label: '驻赁单元名称', prop: 'roomName' },
                        { label: '租赁单元类型', prop: 'roomTypeValue' },
                        { label: '所在楼栋', prop: 'buildingName' },
                        { label: '所在楼层', prop: 'floorName' },
                        { label: '套内总体使用面积', prop: 'totalArea' },
                        {
                            label: '操作',
                            input_type: 'button',
                            width: '150',
                            list: [
                                { text: '删除', method: 'rowDelete_formTable', disabled: false },
                                { text: '费用配置', method: 'expenseAllocation', disabled: false }
                            ]
                        }
                    ]
                },
                { input_type: 'button', text: '选择租赁单元', readonly: true, col: 2, colMarginLeft: '2%', method: 'selectRoom', changeKey: 'selectRoom' },
                { input_type: 'customize', customize: 'uplpadfaceImg', label: '合同附件', key: 'urlLists', col: 24 }
            ],
            show: false,
            rowForm: {},
            rules: {
                tenantId: [{ required: true, message: '请选择驻户', trigger: 'change' },],

            },
            titleText: '',
            // 驻沪弹窗
            selectedPersons: [],
            isSelectPeople: false,
            // 房间弹窗
            selectedRooms: [],
            isSelectRoom: false,
            // 附件（进入编辑弹窗）
            initFileList: [],
            loadingForm: false,
            indexObj: {},
            contractRentReqs: [],
            //变更方式
            state: null,
            oldBizPayServiceNewDTOS: [],
            oldRoomId: null,
            changeId:''

        }
    },
    created() {
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData })
    },
    methods: {
        // 编辑
        async rowEdit(contractCode, changeId, state) {
            this.changeId = changeId
            this.loadingForm = true
            //变更策略
            this.changeInit(state)
            this.state = state
            this.show = true
            //下拉框请求
            await this.rqOpsitions()

            //请求合同数据
            let res = await getContractChangeDetail({ contractCode, changeId })
            let obj = { ...res.data }
            obj.leaseTerm = [obj.startTime, obj.endTime]
            this.oldBizPayServiceNewDTOS = deepCopy(obj.bizPayServiceNewDTOS)
            //重构房间信息
            obj.roomTable = obj.roomInfo.map((item) => {
                this.oldRoomId = item.roomId
                return {
                    ...item,
                    id: item.roomId
                }
            })

            //附件
            if (obj.attachmentListInfo.length > 0) {
                this.initFileList = obj.attachmentListInfo.map((item) => {
                    if (!item) return
                    return {
                        name: item.attachmentName ? item.attachmentName : undefined,
                        url: item.attachmentUrl ? item.attachmentUrl : item,
                        raw: { type: item.attachmentType === 'pdf' ? 'pdf' : 'image' },
                        status: 'success',
                        response: { data: item }
                    }
                })
            } else {
                this.initFileList = []
            }
            this.rowForm = { ...obj }
            this.loadingForm = false
        },
        // 表单提交前的校验
        checkForm() {
            //不是驻户变更不判断费用配置
            if (this.state != 2) {
                //配置费用  DOTO 这里变更内容不一样时需要动态校验，不是所有变更类型都要
                if (!(Array.isArray(this.contractRentReqs) && this.contractRentReqs.length > 0)) {
                    this.$message.error('请填写费用配置')
                    return false
                }
            }


            // 房间信息
            if (!this.rowForm.roomTable || this.rowForm.roomTable.length == 0) {
                this.$message.error('请完善房间信息')
                return false
            }
            return true
        },
        async saveForm() {
            let isOk = this.checkForm()
            if (!isOk) return false
            let obj = deepCopy(this.rowForm)
            let saveObj = {}
            saveObj.contractId = obj.id
            //变更方式
            saveObj.changeTemplate = this.state
            switch (this.state) {
                //租赁单元变更
                case 1:
                    saveObj.changeRoomNameId = obj.roomTable[0].id
                    let { billingArea, initRent: rent, unitPrice } = this.contractRentReqs[0]
                    saveObj.detailsChange = this.contractRentReqs[0].detailedList
                    saveObj.billingArea = billingArea
                    saveObj.rent = rent
                    saveObj.unitPrice = unitPrice
                    if (this.contractRentReqs.length > 0 && Array.isArray(this.contractRentReqs[0].payServiceList)) {
                        let payServiceList = this.contractRentReqs[0].payServiceList
                        saveObj.payServiceIds = payServiceList.map(item => item.id).join()
                    }
                    break
                //驻户变更
                case 2:
                    saveObj.changeTenantNameId = obj.tenantId
                    saveObj.changeTenantType = obj.tenantType
                    break
                //明细变更
                case 3:
                    saveObj.detailsChange = this.contractRentReqs[0].detailedList
                    break
            }
            this.loadingForm = true
            await setContracChange(saveObj)
            this.$message.success('完成')
            this.loadingForm = false
            this.show = false
            this.$emit('updateSuccess')
        },
        // 选择驻户
        selectNenant() {
            this.isSelectPeople = true
        },
        selectRoom() {
            if (!this.rowForm.contractType) return this.$message.warning('请选择合同类型')
            if (!this.rowForm.leaseTerm) return this.$message.warning('请选择租期')
            this.selectedRooms = [...this.rowForm.roomTable]
            this.isSelectRoom = true
        },
        // 选择房间额外的请求参数
        rqParmasFather_room() {
            return {
                // startTime: this.rowForm.leaseTerm[0],
                // endTime: this.rowForm.leaseTerm[1]
            }
        },
        // 选择房间
        selectRow2({ list }) {
            this.isSelectRoom = false
            this.selectedRooms = list
            this.rowForm.roomTable.splice(0, this.rowForm.roomTable.length, ...list)
            this.contractRentReqs = []
            this.$set(this.rowForm, 'bizPayServiceNewDTOS', [])
        },
        // 编辑/新增弹窗 表格里 的 "删除"
        rowDelete_formTable(row, info, button) {
            this.rowForm.roomTable.splice(info.$index, 1)
            this.contractRentReqs = []
            this.$set(this.rowForm, 'bizPayServiceNewDTOS', [])
        },
        // 配置费用
        expenseAllocation(row) {
            console.log(row);
            row.roomId = row.roomId | row.id
            row.changeId = this.changeId
            this.custombizPayServiceNewDTOS(row.roomId, this.oldRoomId)
            let { contractType, id, leaseTerm } = this.rowForm
            let payServiceList = []
            if (Array.isArray(this.contractRentReqs)) {
                if (this.rowForm.bizPayServiceNewDTOS) {
                    payServiceList = [...this.rowForm.bizPayServiceNewDTOS]
                }
            }
            this.$refs.rentA.init({ ...row, contractType, contractId: id, leaseTerm }, this.contractRentReqs, payServiceList, this.state)
        },
        // 取消
        reset() {
            this.show = false
        },
        async rqOpsitions() {
            this.loadingForm = true
            let length = this.rowFormData.findIndex(item => item.key == 'tenantId')
            await rqTenant({ pageIndex: 1, pageSize: 200 }).then((res) => {
                if (res.code == 200) {
                    let data = res.data.data.map((item) => {
                        return {
                            label: item.tenantName,
                            value: item.id,
                            cardCode: item.idCard ? item.idCard : item.licenseNum
                        }
                    })
                    this.$set(this.rowFormData[length], 'options', data)
                }
            }
            )
        },
        getContractRentReqs(data) {
            let { billingArea, unitPrice, initRent, payServiceList, detailedList } = data
            this.contractRentReqs = [{ billingArea, unitPrice, initRent, payServiceList, detailedList }]
        },
        tenantSelect(clo, val) {
            let options = clo.options
            let length = options.findIndex(item => item.value == val)
            this.$set(this.rowForm, 'cardCode', options[length].cardCode)
        },


        //变更策略
        changeInit(state) {
            let rowFormData = this.rowFormData
            let columns = rowFormData[rowFormData.findIndex(item => item.key == 'roomTable')].columns
            let list = columns[columns.findIndex(item => item.input_type == 'button')].list
            this.rowFormData.map(item => {
                item.disabled = true
            })
            list.map(list => {
                list.disabled = true
            })
            switch (state) {
                //租赁单元变更
                case 1:
                    let selectRoom = this.rowFormData.findIndex(item => item.changeKey == 'selectRoom')
                    this.rowFormData[selectRoom].disabled = false
                    list.map(list => {
                        list.disabled = false
                    })
                    break
                //驻户变更
                case 2:
                    let tenantId = this.rowFormData.findIndex(item => item.changeKey == 'tenantId')
                    this.rowFormData[tenantId].disabled = false
                    this.rowFormData[8].disabled = false
                    break
                //明细变更
                case 3:
                    list[1].disabled = false
                    break
            }
        },
        //恢复物管合同其它收费项
        custombizPayServiceNewDTOS(roomId, oldRoomId) {
            console.log(roomId, oldRoomId);
            if (roomId === oldRoomId) {
                console.log(this.oldBizPayServiceNewDTOS);
                this.$set(this.rowForm, 'bizPayServiceNewDTOS', this.oldBizPayServiceNewDTOS)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.frequencyDay {
    /deep/ .el-select {
        width: 100px;
        margin: 0 8px;
    }
    /deep/ .el-form-item__label {
        width: auto !important;
    }
    /deep/ .el-form-item__content {
        margin-left: auto !important;
    }
}
.elDialog {
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
}
/deep/.selectFrom {
    width: 600px;
}
/deep/.select-two {
    float: right;
    width: 60% !important;
}
/deep/ .el-input-number .el-input__inner {
    padding-left: 0;
}
.day {
    width: 35% !important;
    margin: 0 10px;
}
</style>
