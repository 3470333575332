<template>
    <div>
        <ja-table v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @lookRoom="lookRoom"
                  @deleteItem="deleteItem"
                  @editItem="editItem">
        </ja-table>
        <add-edit ref="addRefs"></add-edit>
        <show-details ref="detailRefs"></show-details>
    </div>
</template>

<script>
import { getDecInspectItemsInfoList, deleteDecInspectItemsInfo } from '@/api'
import table from '@/views/mixins/table'
import addEdit from './components/addEdit.vue'
import showDetails from './components/showDetails.vue'
export default {
    title: '巡查项设置',
    mixins: [table],
    name: 'patrolItemSettings',
    components: {
        addEdit,
        showDetails,
    },
    data() {
        return {
            conditionData: [
                { input_type: 'select', key: 'patrolType', attribute: { placeholder: '装修项目类型', size: 'small' }, options: [] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'renovation::patrolItemSettings::add' }
            ],
            columns: [
                // 表头数据
                { label: '巡查项目类型', prop: 'patrolTypeName' },
                { label: '巡查内容', prop: 'remark' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'renovation::patrolItemSettings::show' },
                        { text: '编辑', method: 'editItem', limit: 'renovation::patrolItemSettings::edit' },
                        { text: '删除', method: 'deleteItem', limit: 'renovation::patrolItemSettings::delete' },

                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,

        }
    },
    mounted() {
        let index = this.conditionData.findIndex((e) => e.key === 'patrolType')
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'patrol_type' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        getInit() {
            getDecInspectItemsInfoList(this.filterData).then((res) => {
                if (res.code === 200) {
                    this.tableData = res.data.data
                    this.total = res.data.total
                    this.loading = false
                }
            })
        },
        deleteItem(row) {
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteDecInspectItemsInfo(row.id).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getInit()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        append() {
            this.$refs.addRefs.init()
        },
        editItem(row) {
            this.$refs.addRefs.init(1, row)
        },
        lookRoom(row) {
            this.$refs.detailRefs.init(row)
        }
    }
}
</script>

<style scoped>
.download {
    width: 5vw;
}
</style>

