<template>
  <div class="allwh">
    <el-row>
      <el-col :span="4.5">
        <div class="selectUser_left">
          <el-input
            placeholder="搜索"
            size="small"
            v-model="filterText"
            class="treeIput"
          >
          </el-input>
          <el-tree
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            :highlight-current="true"
            default-expand-all
            :filter-node-method="filterNode"
            :current-node-key="currentNodekey"
            @node-click="handleNodeClick"
            ref="tree"
            node-key="id"
          >
          </el-tree>
        </div>
      </el-col>
      <el-col :span="19">
        <div class="selectUser_right">
          <ja-table
            ref="tst"
            v-model="filterData"
            :conditionData="conditionData"
            :columns="columns"
            :tableData="tableData"
            :total="total"
            :loading="loading"
            @search="search"
            @rowDelete="rowDelete"
            :showSelect="true"
            @handleSelectionChange="handleSelectionChange"
            @reset="reset"
            @getInit="getInit"
            :showIndex="false"
          >
            <template v-slot:right>
              <el-button
                type="primary"
                @click="addParkAdmin"
                size="small"
                v-if="isAuth('park::manage::assign')"
                plain
                >确认指派</el-button
              >
            </template>
          </ja-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import table from '@/views/mixins/table'

import { delPark, getDepTree, getUserInfo, addParkAdmin } from '@/api'
export default {
  title: '指派管理员',
  name: 'EditOccupant',
  mixins: [table],
  components: {
    JaForm
  },
  data() {
    return {
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'username',
          styleWidth: '200px',

          attribute: {
            placeholder: '用户姓名',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '公司名称',
          prop: 'companyName',
          width: 180,
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '部门',
          prop: 'deptName',
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '用户姓名',
          prop: 'username'
        },
        {
          label: '手机号码',
          prop: 'mobile'
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      filterText: '',
      treeData: [],
      currentNodekey: '',
      adminIds: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  props: {
    title: String,
    id: String,
    show: {
      type: Boolean,
      default: false
    },
    parkId: Number
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  created() {
    this.getDepTree()
  },
  methods: {
    getInit(data) {
      data ? this.getUserInfo(data) : this.getUserInfo(this.filterData)
    },
    search() {
      this.filterData.pageIndex = 1
      this.getUserInfo(this.filterData)
    },
    reset() {
      this.filterData = {}
      this.filterData.pageIndex = 1
      this.filterData.pageSize = 10
      this.getUserInfo(this.filterData)
    },
    /** 保存表单 */
    saveForm() {
      this.close()
    },
    /** 关闭弹出框 */
    close() {
      this.$emit('closeUser', false)
    },
    getUserInfo(filterData) {
      filterData.parkAdmin = false
      // filterData.parkId = this.parkId

      getUserInfo(filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 删除某一项
    rowDelete(row) {
      this.$confirm('是否确认删除该用户吗？').then((res) => {
        console.log('row', row)
        delPark(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    // 多选
    handleSelectionChange(val) {
      console.log('val---', val)
      this.adminIds = val
    },
    filterNode(value, data, node) {
      if (!value) return true
      let if_one = node.data.label.indexOf(value) !== -1
      let if_two =
        node.parent &&
        node.parent.data &&
        node.parent.data.label &&
        node.parent.data.label.indexOf(value) !== -1
      let if_three =
        node.parent &&
        node.parent.parent &&
        node.parent.parent.data &&
        node.parent.parent.data.label &&
        node.parent.parent.data.label.indexOf(value) !== -1
      let result_one = false
      let result_two = false
      let result_three = false
      if (node.level === 1) {
        result_one = if_one
      } else if (node.level === 2) {
        result_two = if_one || if_two
      } else if (node.level === 3) {
        result_three = if_one || if_two || if_three
      }
      return result_one || result_two || result_three
    },
    // 获取部门树的，数据
    getDepTree() {
      getDepTree({ pageIndex: 1, pageSize: 200 }).then((res) => {
        // console.log("-----res",res)
        if (res.code == 200) {
          this.treeData = res.data.data
          this.loading = true
          // console.log(this.treeData)

          if (this.treeData[0]) {
            const deptId = this.treeData[0].children[0].id
            this.currentNodekey = deptId
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.currentNodekey)
            })

            this.filterData.deptId = deptId
            // 查询用户列表信息
            this.getUserInfo(this.filterData)
          } else {
            this.total = 0
            this.loading = false
          }
        }
      })
    },
    handleNodeClick(data) {
      if (!data.children || data.children.length == 0) {
        this.filterData.deptId = data.id
        this.getUserInfo(this.filterData)
        // console.log("yu---")
      }
    },
    // 添加社区管理员
    addParkAdmin() {
      if (this.adminIds.length == 0) {
        this.$message.error('至少要选择一个用户哦！')
        return false
      }
      let adminIds = this.adminIds.map((item) => {
        return item.id
      })
      let ids = adminIds.join(',')
      // console.log(ids)

      let params = {
        parkId: this.parkId,
        adminId: ids
      }
      addParkAdmin(params).then((res) => {
        let _this = this
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.$router.push({ path: '/home/basicInformation/managerList' })
          _this.$emit('closeUser', false, 1)
        }
      })
    }
  }
}
</script>

<style scoped>
.selectUser_left {
  width: 300px;
}
.treeIput {
  /* padding: 10px 15px; */
  margin: 28px 0;
}
.selectUser_right {
  border-left: 1px solid #e3e3e3;
  margin-left: 25px;
}
.el-row {
  margin-left: 30px;
}
</style>
