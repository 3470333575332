<template>
    <div>
        <el-col span="11"
                style="margin-left:15px"
                v-loading="show">
            <div class="headle">
                <span class="left">{{titleLeft}}</span>
                <span>{{titleRight}}</span>
            </div>
            <div ref="wrap"
                 class="wrap"></div>
        </el-col>
    </div>
</template>
<script>
import { getHydropowerRanking } from '@/api'
export default {
    props: {
        titleLeft: {
            type: String,
            default: "左边"
        },
        titleRight: {
            type: String,
            default: "右边"
        },
        seriesBGColor: {
            type: String,
            default: "#02a9ec"
        },
        type: {
            type: Number,
            default: 1
        }
    },
    title: '排行',
    mounted() {

        this.setOptions()

    },
    data() {
        return {

            show: false
        }
    },
    methods: {
        async setOptions() {
            let { data1, data2 } = await this.getInit()
            let options = {
                tooltip: {
                },
                xAxis: {
                    show: false
                },
                yAxis: [
                    {
                        type: 'category',
                        inverse: true,
                        data: data2,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            margin: 110,
                            formatter: (params, index) => {
                                return [`{a|${index + 1}}  ${params}`].join('\n')
                            },
                            align: 'left',
                            rich: {
                                a: {
                                    width: 18,
                                    height: 18,
                                    align: 'center',
                                    borderRadius: 10,
                                    borderColor: 'black',
                                    borderType: 'solid',
                                    borderWidth: 1,
                                }
                            },
                        },
                    }, {
                        type: 'category',
                        inverse: true, // 反向坐标
                        posotion: 'right',
                        data: data1,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        }
                    }
                ],
                series: [
                    {
                        name: '销量',
                        type: 'bar',
                        data: data1,
                        color: this.seriesBGColor,
                        barWidth: 10,
                    }
                ],
                grid: {
                    left: '30%',
                    top: 20,
                    right: '20%'
                },
            }
            const myChart = this.$echarts.init(this.$refs.wrap)
            myChart.setOption(options)
            // window.addEventListener('resize', () => {
            //     myChart.resize();
            // })
        },
        async getInit() {
            let data1 = []
            let data2 = []
            await getHydropowerRanking(this.type).then(res => {
                res.data.map(item => {
                    data1.push(Number(item.monthDosage).toFixed(2))
                    data2.push(item.tenantName)
                })

            })
            return { data1, data2 }
        }
    }

}
</script>
<style lang="scss" scoped>
.headle {
    border-bottom: 1px solid black;
    width: 80%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 14px;
    .left {
        font-weight: bold;
    }
}
.wrap {
    width: 80%;
    height: 300px;
}
</style>