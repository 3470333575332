<template>
    <el-dialog :visible.sync="show"
               :close-on-click-modal="false"
               width="70%"
               :title="title">
        <ja-form :rules="rules"
                 v-if="show"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :btnBox="btnBox"
                 :allDisabled="allDisabled"
                 @adopt="adopt"
                 @reject="reject">
            <template #mesContent>
                <el-tooltip placement="bottom">
                    <div slot="content">
                        计算优惠：如有减免调整后金额，则预计实收合计为调整后金额-优惠金额；
                        <br />
                        不计算优惠：按照调整后应收金额合;
                    </div>
                    <el-button type="text"
                               icon="el-icon-warning"
                               size=""></el-button>
                </el-tooltip>

            </template>
            <template #total>
                <span>账单合计：{{total || 0}}</span>
            </template>
        </ja-form>
    </el-dialog>
</template>
<script>
import { getbizBillDetailNewList, editbizBillInfoNew } from '@/api'
export default {
    inject: ['getInit'],
    data () {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', labelWidth: '110px', col: 5 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '110px', col: 11 },
                { input_type: 'select', label: '合计规则', key: 'sumRule', labelWidth: '110px', col: 6, disabled: true, options: [{ label: '不计算优惠', value: '0' }, { label: '计算优惠', value: '1' }] },
                { input_type: 'customize', customize: 'mesContent', label: '', col: 2, labelWidth: '10px' },
                { input_type: 'text2', label: '收费清单', key: 'amount', labelWidth: '110px', col: 11 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'amountDTOs',
                    isRequired: true,
                    border: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType' },
                        { label: '收费项目性质', prop: 'natureCharges' },
                        { label: '收费项目名称', prop: 'serviceName' },
                        { label: '计费周期', prop: 'date' },
                        { label: '缴费周期', prop: 'day' },
                        { label: '本期应收', prop: 'receivable' },
                        { label: '调整后应收', prop: 'adjustedReceivable', blurMethod: 'blurNum' },
                        { label: '优惠金额', prop: 'coupon', blurMethod: 'blurNum' },
                        { label: '未收金额', prop: 'uncollected' }
                    ]
                },
                { input_type: 'text2', label: '收费分项明细', key: 'detail', labelWidth: '110px', col: 11 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'bizBillDetailNewDTOs',
                    isRequired: true,
                    border: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType' },
                        { label: '收费项目性质', prop: 'natureCharges' },
                        { label: '收费项目名称', prop: 'serviceName' },
                        { label: '计费周期', prop: 'date' },
                        { label: '缴费周期', prop: 'day' },
                        { label: '本期应收', prop: 'receivable' },
                        { label: '调整后应收', prop: 'adjustedReceivable', blurMethod: 'blurNum' },
                        { label: '优惠金额', prop: 'coupon', blurMethod: 'blurNum' },
                        { label: '未收金额', prop: 'uncollected' },
                        { label: '备注', prop: 'remark' }
                    ]
                },
                { input_type: 'customize', customize: 'total', label: '', col: 24, labelWidth: '10px' }
            ],
            show: false,
            allDisabled: false,
            title: '账单审批',
            rowForm: {},
            btnBox: [
                { label: '驳回', method: 'reject', col: 8, limit: 'collectFees::approvalList::reject' },
                { label: '通过', method: 'adopt', type: 'primary', limit: 'collectFees::approvalList::adopt' }
            ],
            total: 0

        }
    },
    methods: {
        async init (state = 1, row) {
            this.title = '账单审批'
            this.allDisabled = false
            this.show = true
            this.rowForm = {}
            this.total = row.actualReceivable || 0
            const res = await getbizBillDetailNewList({ billId: row.id, pageIndex: 1, pageSize: 1000 })
            const amountDTOMap = new Map()
            res.data.data.map(item => {
                if (item.adjustedReceivable == 0) {
                    item.adjustedReceivable = null
                }
                item.date = `${item.billDateStart}~${item.billDateEnd}`
                let serviceDTO = amountDTOMap.get(item.serviceName)
                if (serviceDTO !== undefined) {
                    serviceDTO.receivable += Number(item.receivable)
                    serviceDTO.adjustedReceivable += Number(item.adjustedReceivable)
                    serviceDTO.coupon += Number(item.coupon)
                    serviceDTO.uncollected += Number(item.uncollected)
                } else {
                    serviceDTO = {
                        serviceType: item.serviceType,
                        natureCharges: item.natureCharges,
                        serviceName: item.serviceName,
                        date: item.date,
                        day: item.day,
                        receivable: Number(item.receivable),
                        adjustedReceivable: Number(item.adjustedReceivable),
                        coupon: Number(item.coupon),
                        uncollected: Number(item.uncollected)
                    }
                }
                amountDTOMap.set(item.serviceName, serviceDTO)
            })
            const amountDTOs = []
            amountDTOMap.forEach((value, key) => {
                amountDTOs.push(value)
            })
            this.rowForm = { ...row, bizBillDetailNewDTOs: [...res.data.data], amountDTOs: amountDTOs }
            if (state == 2) {
                this.title = '已通过查看'
                this.allDisabled = true
            }
        },
        adopt () {
            this.rowForm.approvalStatus = '1'
            this.$confirm('是否确定审批通过？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await editbizBillInfoNew({ ...this.rowForm })
                this.$message.success('账单审批已通过')
                this.getInit()
                this.show = false
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                })
            })
        },
        reject () {
            this.rowForm.approvalStatus = '2'
            this.rowForm.status = '1'
            this.$confirm('是否确定审批驳回？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await editbizBillInfoNew({ ...this.rowForm })
                this.$message.success('账单审批已驳回')
                this.getInit()
                this.show = false
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
</style>
