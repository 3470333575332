<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag
               :close-on-click-modal="false"
               :visible.sync="show"
               width="55%"
               title="延时经营申请已通过查看">
        <ja-form :rules="rules"
                 ref="jaForm"
                 :label-width="labelWidth"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :loadingForm="loadingForm"
                 :allDisabled="allDisabled">
        </ja-form>
    </el-dialog>
</template>
<script>
export default {
    inject: ['labelWidth', 'getInit',],
    data() {
        return {
            rowFormData: [
                { input_type: 'input', label: '店铺名称', key: 'applyDeptName', col: 12 },
                { input_type: 'input', label: '申请人', key: 'applyEmployeeName', col: 12 },
                { input_type: 'input', label: '申请人联系方式', key: 'applyEmployeePhone', col: 12 },
                { input_type: 'input', label: '延时时间', key: 'deferredBusinessTime', col: 12 },
                { input_type: 'input', label: '涉及人员数量', key: 'involvedNumber', col: 12 },
                { input_type: 'input', label: '延时经营申请原因', key: 'reason', type: 'textarea', col: 24 },
                { input_type: 'input', label: '延时经营内容', key: 'content', type: 'textarea', col: 24 },
                { input_type: 'input', label: '提交时间', key: 'createTime', col: 12 },
                { input_type: 'input', label: '审批状态', key: 'approvalStatusName', col: 12 },
            ],
            rowForm: {},
            rules: {},
            loadingForm: false,
            show: false,
            allDisabled: true
        }
    },
    methods: {
        rowDetail(row) {
            console.log(row);
            this.rowForm = { ...row }
            this.show = true
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
.img-box {
    display: flex;
    justify-content: space-between;
}
.img-item {
    height: 150px;
    width: 150px;
    .el-image {
        width: 100%;
        height: 100%;
    }
}
</style>