<template>
    <div>

        <el-table :data="tableList"
                  border
                  show-summary
                  header-row-class-name="newTable"
                  sum-text="本期小计"
                  style="width: 100%">
            <el-table-column prop="serviceName"
                             align="center"
                             label="科目名称"
                             width="180">
            </el-table-column>
            <el-table-column prop="date"
                             align="center"
                             label="费用所属期间"
                             width="200">
            </el-table-column>
            <el-table-column prop="day"
                             align="center"
                             label="最后缴款日期">
            </el-table-column>
            <el-table-column prop="receivable"
                             align="center"
                             label="应收款">
            </el-table-column>
            <el-table-column prop="coupon"
                             align="center"
                             label="抵优惠券">
            </el-table-column>
             <el-table-column prop="writeOff"
                             align="center"
                             label="已收款">
            </el-table-column>
            <el-table-column prop="uncollected"
                             align="center"
                             label="未收款">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        tableList: {
            type: Array,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>

::v-deep .newTable {
    font-size: 16px;
    font-weight: 800;
    .el-table__cell {
        border-color: #0a0a0a !important;
    }
}
::v-deep .el-table__footer-wrapper {
    font-size: 16px;
    font-weight: 800;
}
::v-deep .el-table {
    border-color: #080808;
}
::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
    border-color: #0e0e0f !important;
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
    content: '';
    position: absolute;
    background-color: #0e0e0f ;
    z-index: 1;
}
</style>
