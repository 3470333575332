/** 驻户信息操作栏 */
import { getDictData, getAllFloor, getBuildingList } from '@/api'
export const billManageChargeQuery =[]

/** 驻户信息表头栏 */
export const obviousChargeColumn = [
  { label: '房间名称', prop: 'roomName', width: '150px' },
  { label: '驻户名称', prop: 'tenantName', width: '150px' },
  { label: '账单月份', prop: 'month' },
  { label: '账单日期', prop: 'day' },
  { label: '所在楼栋', prop: 'buildingName', width: '160px' },
  { label: '所在楼层', prop: 'floorName', width: '160px' },
  { label: '套内面积（m²）', prop: 'insideArea' },
  { label: '建筑面积（m²）', prop: 'outsideArea' },
  { label: '用途', prop: 'purpose' },
  { label: '应收金额', prop: 'receivable' },
  { label: '已缴金额', prop: 'deducted' },
  { label: '缴费状态', prop: 'status' },
  { label: '发票状态', prop: 'invoiceStatus' },
  // {
  //   label: '操作',
  //   input_type: 'button',
  //   width: 180,
  //   list: [
  //     { text: '确认收款', method: 'getContract' },
  //   ]
  // }
]

/** 新增/修改驻户信息 */
export const editOccupantData = [
  {
    input_type: 'input',
    label: '驻户名称',
    key: 'tenantName',
    
    col: 22
  },
  {
    input_type: 'select',
    label: '驻户类型',
    key: 'category',
    options: [
      {
        value: 1,
        label: '业主'
      },
      {
        value: 2,
        label: '租客'
      }
    ]
  },
  {
    input_type: 'select',
    label: '驻户属性',
    key: 'attribute',
    options: [
      {
        value: 1,
        label: '个人'
      },
      {
        value: 2,
        label: '企业'
      }
    ]
  },
  {
    input_type: 'input',
    label: '纳税人识别号',
    key: 'unifiedSocialCreditCode',
    
    col: 22
  },
  {
    input_type: 'input',
    label: '身份证号码',
    key: 'idCard',
    
    col: 22
  },
  {
    input_type: 'input',
    label: '负责人',
    key: 'linkMan',
    
    col: 22
  },
  {
    input_type: 'input',
    label: '联系方式',
    key: 'contactNumber',
    
    col: 22
  },
  {
    input_type: 'input',
    label: '备注',
    key: 'remark',
    type: 'textarea',
    col: 22
  }
]

/** 新增/修改驻户信息校验规则 */
export const editOccupantRules = {
  occupantName: [
    { required: true, message: '请输入驻户名称', trigger: 'blur' }
  ],
  occupantType: [
    { required: true, message: '请选择驻户类型', trigger: 'change' }
  ],
  occupantAttr: [
    { required: true, message: '请选择驻户属性', trigger: 'change' }
  ],
  taxNum: [
    { required: true, message: '请输入纳税人识别号', trigger: 'blur' }
  ],
  cardNum: [
    { required: true, message: '请输入身份证号码', trigger: 'blur' }
  ],
  phone: [
    { required: true, message: '请输入联系方式', trigger: 'blur' }
  ]
}
