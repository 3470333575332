<template>
    <div>
        <el-row :gutter="50" v-loading="headLoading">
            <el-col :span="4">
                <el-card shadow="never" :body-style="{ background: 'rgba(83, 110, 196, 1)' }">
                    <p style="white-space: nowrap;">申请数量</p>
                    <div class="testBox">{{ Operatetotal || 0 }}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never" :body-style="{ background: 'rgba(141, 197, 113, 1)' }">
                    <p style="white-space: nowrap;">通过数量</p>
                    <div class="testBox">{{ Operatepasstotal || 0 }}</div>
                </el-card>
            </el-col>
        </el-row>
        <ja-table v-model="filterData" :conditionData="conditionData" :columns="columns" :editDataFormat="editDataFormat"
            :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search"
            @reset="reset" @rowEdit="normalEvent" @rowDetail="normalEvent">
        </ja-table>

        <!-- 新增/编辑 -->
        <add-edit ref="addEdit" />
        <detail ref="detail" />
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './components/AddEdit.vue'
import Detail from './components/Detail.vue'
import { getExtendedOperationList, getExtendedOperationstatistics } from '@/api'
export default {
    title: '延时经营管理',
    mixins: [table,],
    name: 'index',
    components: {
        AddEdit, Detail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'applyDeptName', attribute: { placeholder: '店铺名称', size: 'small' } },
                { input_type: 'input', key: 'applyEmployeeName', attribute: { placeholder: '申请人', size: 'small' }, },
                { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
                { input_type: 'select', key: 'approvalStatus', attribute: { placeholder: '审批状态', size: 'small' }, options: [] },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '店铺名称', prop: 'applyDeptName' },
                { label: '申请人', prop: 'applyEmployeeName' },
                { label: '申请人联系方式', prop: 'applyEmployeePhone', width: 140 },
                { label: '延时时间', prop: 'deferredBusinessTime', width: 340 },
                { label: '涉及人员数量', prop: 'involvedNumber', width: 180 },
                { label: '延时经营申请原因', prop: 'reason', width: 180 },
                { label: '延时经营内容', prop: 'content', width: 180 },
                { label: '提交时间', prop: 'createTime', width: 140 },
                { label: '审批状态', prop: 'approvalStatusName' },
                {
                    label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
                    list: [
                        { text: '查看', method: 'rowDetail', limit: 'propertyServices::extendedOperation::rowDetail' },
                        { text: '通过/驳回', method: 'rowEdit', limit: 'propertyServices::extendedOperation::rowEdit' },
                    ]
                }
            ],
            tableData: [{ status: 1 }, { status: 0 }],
            Operatetotal:0,//申请数量
            Operatepasstotal:0// 通过数量
        }
    },
    mounted() {
        let length = this.conditionData.findIndex(item => item.key == 'approvalStatus')
        this.util.rqOptionsList({ rqUrl: "/sys/dictData/apply_status", rqMethod: 'get', resRule: 'data' }).then(res => {
            let index = res.findIndex(item => item.label == '已验收')
            res.splice(index, 1)
            this.conditionData[length].options = res
        })
    },
    methods: {
        rqParmas() {
            let obj = { ...this.filterData }
            if (obj.time) {
                obj.startTime = obj.time ? obj.time[0] : ''
                obj.endTime = obj.time ? obj.time[1] : ''
                delete obj.time
            }
            return obj
        },
        getInit() {
            this.loading = false
            let data = this.rqParmas()
            getExtendedOperationList(data).then(res => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
            getExtendedOperationstatistics(data).then(res => {
                this.Operatetotal =  res.data.total
                this.Operatepasstotal = res.data.passTotal
            })

        },
        editDataFormat(row, btn) {
            if ((row.approvalStatus === 0 && btn.text == '通过/驳回') || (row.approvalStatus != 0 && btn.text == '查看')) {
                return true
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;

    .el-card {
        color: #fff;
        border-radius: 0.4vw;

        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 2vw;
        }
    }
}
</style>