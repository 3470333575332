<template>
    <!-- 表格 -->
    <div class="content">
       <el-row  style="padding: 16px 16px 0;">
             <el-col :span="7" class="legend">
               <p v-for="(item,i) in nameList" :key="i">{{item}} <span>{{importanceData[i]}}</span></p>
            </el-col>
            <el-col :span="15"> 
              <div class="echart"
                  v-if="options">
                  <ja-init-chart :options="options" />
              </div>
            </el-col> 
        </el-row>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import { importanceStatistics } from '@/api'
export default {
    components: {
        JaInitChart,
    },
    data() {
        return {
            options: null,
            writeoffData: {},
            nameList:['重要紧急','重要不紧急','紧急不重要','不重要不紧急'],
            importanceData:[]
        }
    },
   async created() {
       
    //   await  this.init()
    },
    methods: {
        init(param){
            importanceStatistics(param).then(res=>{
                let list=res.data
                this.importanceData=[list.importanceLevel1,list.importanceLevel2,list.importanceLevel3,list.importanceLevel4]
                
                this.setOptions(this.importanceData)
            })
        },
        async setOptions(data=[]) {
            let initData = ['35','55','89','79']
            let nameList= ['重要紧急','重要不紧急','紧急不重要','不重要不紧急']
            this.options = {
                legend: {
                    show:false,
                    top:'5%',
                    right:'10%',
                    data:this.nameList,
                    textStyle:{
                        fontSize:12,
                        color:'#808080'
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    top:'10%',
                    bottom: '17%',
                    containLabel: true
                },
                xAxis: {
                    show:true,
                    type: 'category',
                    data:this.nameList,
                    axisLine:{
                        lineStyle:{
                            color:'#657CA8'
                        }
                    },
                    axisTick:{
                        show:false,
                    },
                    axisLabel:{
                        show:false,
                        color:'#333',
                        fontSize:18
                    },
                },
                yAxis: {
                    show:false,
                    axisLine:{
                        lineStyle:{
                            color:'#657CA8'
                        }
                    },
                    axisTick:{
                        show:true
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#eee'
                        }
                    },
                    axisLabel:{
                        color:'#666',
                        fontSize:16
                        
                    },
                },
                series: [{
                        type: 'bar',
                        barWidth:"40%",
                        data:data,
                        label: {
                            show: true,
                            position:'top' ,
                            textStyle:{
                                color:'#666',
                                fontSize:18
                            }
                        },
                        itemStyle:{
                            normal:{
                                color: function (params){
                                    return '#4554d1'
                                }
                            }
                        }
                    }]
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 320px;
    background: #fff;
    border-radius: 10px;
    .title{
        font-size: 22px;
        font-weight: bold;
        margin: 20px 0;
        color: #333;
        text-align: center;
    }
}
.echart {
    height: 320px;
    width: 100%;
}
.legend{
  padding-top: 66px;
  padding-left: 30px;
  color: #4554d1;
  p{
  display: flex;
  justify-items: center;
  align-items: center;
  line-height: 40px;
  justify-content: space-between;
  width: 80%;
    span{
      display: block;
    }
  }
}
</style>