<template>
  <div>
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="show"
      width="50%"
      :title="titleText"
      v-loading="addLoading"
      :element-loading-text="isAdd ? '新增中' : '编辑中'"
    >
      <ja-form
        :rules="rules"
        v-model="rowForm"
        :formArray="rowFormData"
        @reset="show = false"
        v-if="show"
        :noButton="true"
      >
        <div class="dialog-footer">
          <el-button @click="close">关闭</el-button>
          <el-button type="primary" @click="confirm">确认收款</el-button>
        </div>
      </ja-form>
    </el-dialog>
    <account-regist ref="accountRegist" />
  </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { setAlertTime } from '@/api'
import accountRegist from '../components/accountRegist.vue'
export default {
  components: {
    JaForm,
    accountRegist
  },
  inject: ['labelWidth', 'getInit'],
  data() {
    return {
      rules: {},
      rowFormData: [
        {
          input_type: 'input',
          label: '驻户名称',
          key: 'tenantName',
          styleWidth: '180px',
          disabled: true
        },
        {
          input_type: 'input',
          label: '合同编号',
          key: 'contractCode',
          labelWidth: '120px',
          styleWidth: '180px',
          disabled: true
        },
        {
          input_type: 'input',
          label: '账单时间',
          key: 'billDate',
          styleWidth: '180px',
          disabled: true
        },
        {
          input_type: 'input',
          label: '本期合计应收（元）',
          key: 'amountReceivable',
          labelWidth: '120px',
          styleWidth: '180px',
          disabled: true
        },
        {
          input_type: 'input',
          label: '状态',
          key: 'statusName',
          styleWidth: '180px',
          disabled: true
        },
        {
          input_type: 'input',
          type: 'textarea',
          label: '备注',
          key: 'remark',
          col: 18,
          styleWidth: '260px',
          disabled: true
        },
      ],
      show: false,
      titleText: '账单确认收款',
      rowForm: {},
      addLoading: false,
      penson: {},
      currentData: ''
    }
  },
  methods: {
    init(row) {
      // let data = new Date().getTime() + 60 * 60 * 24 * 1000
      this.show = true
      // console.log(row);
      this.titleText = '账单确认收款'
      this.rowForm = {
        ...row
        // serviceStartTime: new Date(data).toLocaleDateString().replace(/\//g,"-0"),
      }
    },
    confirm() {
      this.$refs.accountRegist.init(this.rowForm)
      this.close()
    },
    close() {
      this.show = false
    }
  },
  mounted() {
    let that = this
  }
}
</script>
<style long="scss" scoped>
.dialog-footer {
  margin-right: 50px;
  float: right;
}
</style>