/** 驻户信息操作栏 */
import { getDictData, payList } from '@/api'
export const billManageChargeQuery = [
    {
        input_type: 'select', key: 'buildingId', attribute: { placeholder: '楼栋', size: 'small' }, options: [], styleWidth: '100px', method: 'selectFun',
        relevance: {
            sonKey: 'floorId', This: this, // 必填。而 rqParam 是异步请求，必填字段
            rqParam: { rqUrl: "/floor/all", rqData: () => { return { params: { buildingId: this.filterData.buildingId } } }, labelName: 'floorName', valueName: 'floorId', resRule: 'data' }, // 
        }
    },
    { input_type: 'select', key: 'floorId', attribute: { placeholder: '楼层', size: 'small' }, options: [], styleWidth: '100px' },
    {
        input_type: 'select',
        attribute: {
            placeholder: '驻户名称',
            size: 'small'
        },
        key: 'purpose',
        options: function () {
            return getDictData('room_purpose').then((res) => {
                let data = res.data.map((item) => {
                    return {
                        label: item.dictLabel,
                        value: item.dictValue
                    }
                })
                return data
            })
        }
    },
    {
        input_type: 'select',
        attribute: {
            placeholder: '账单编号',
            size: 'small'
        },
        key: 'category',
        options: function () {
            return getDictData('room_category').then((res) => {
                if (res.code == 200) {
                    let data = res.data.map((item) => {
                        return {
                            label: item.dictLabel,
                            value: item.dictValue
                        }
                    })
                    return data
                }
            })
        }
    },
    {
        input_type: 'input', attribute: {
            placeholder: '房间名称',
            size: 'small'
        }, key: 'roomName'
    },
    {
        input_type: 'date',
        key: 'date',
        type: 'monthrange',
    }
    ,
    { normalBtn: 'search' },
    { normalBtn: 'reset' },

]

/** 驻户信息表头栏 */
export const obviousChargeColumn = [
    { label: '驻户名称', prop: 'tenantName' },
    { label: '合同编号', prop: 'contractCode' },
    { label: '账单时间', prop: 'billDate' },
    { label: '本期合计应收（元）', prop: 'amountReceivable' },
    { label: '状态', prop: 'status' },
    { label: '备注', prop: 'remark' },
    {
        label: '操作', prop: 'operation',
        input_type: 'button',
        list: [
            { text: '确认收款', method: 'ConfirmReceipt',limit:'bill::manage::confirmReceipt'},
        ]
    },
    // {
    //     label: '操作',
    //     input_type: 'button',
    //     width: 180,
    //     list: [
    //         { text: '编辑', method: 'rowEdit' },
    //         { text: '删除', method: 'rowDelete' },
    //         { text: '合同', method: 'getContract' },
    //         { text: '抄表', method: '' }
    //     ]
    // }
]
/** 账单明细表头 */
export const billingTableHead = [
    { label: '收费项', prop: 'payProject', },
    { label: '项目明细', prop: 'payProjectDetail' },
    { label: '计费期间', prop: 'time' },
    { label: '用量', prop: 'consumption' },
    { label: '单价', prop: 'unitPrice' },
    { label: '本期应收', prop: 'totalCost', },
    {column_type: 'input',label: '用量调整', prop: 'adjustment', method:'inputEvent'},
    {column_type: 'input',label: '单价调整', prop: 'adjustmentUnitPrice',method:'inputEvent'},
    { label: '调整后应收', prop: 'adjustmentTotal', },
    {column_type: 'input',label: '调整备注', prop: 'adjustmentRemark'}
]
/** 账单明细表头打印用 */
export const billingTableHeadPrint = [
    { label: '收费项', prop: 'payProject'},
    { label: '项目明细', prop: 'payProjectDetail',width: '150px'},
    { label: '计费期间', prop: 'time',width: '150px'},
    { label: '用量', prop: 'consumption' },
    { label: '单价', prop: 'unitPrice' },
    { label: '本期应收', prop: 'totalCost', },
    {label: '用量调整', prop: 'adjustment', },
    {label: '单价调整', prop: 'adjustmentUnitPrice',},
    { label: '调整后应收', prop: 'adjustmentTotal', },
    {label: '调整备注', prop: 'adjustmentRemark'}
]

/** 审核设置 元素 */
export const editOccupantData = [
    {
        input_type: 'radio', 
        label: '审核方式',
        key: 'flag',
        col: 21,
        options:[
            {label:'自动审核',value:'0'},
            {label:'手动审核',value:'1'}
        ],
        
    },
    {
        // input_type:'customize',
        // customize:'generateBill',
        col: 21,
        styleWidth:'30%',
        input_type: 'input', 
        label: '生成账单',
        key: 'time', 
        rightBox:{ input_type: 'text', text:'小时 自动通过审核并下发缴费单。'},
        disabled:true
    }
]

/** 审核设置校验规则 */
export const editOccupantRules = {
   
    flag:[{ required: true, message: '请选择审核方式', trigger: 'blur' }],
}

/** 新增收费项 */
export const editCost = [
    {
        input_type: 'select',
        label: '收费项',
        key: 'payProject',
        col: 21,
        options: function () {
            return payList().then((res) => {
                if(res.code !== 200) this.$message.error('请求收费项出错')
                let data = res.data.map((item) => {
                    return {
                        label: item,
                        value: item
                    }
                })
                return data
            })
          }
    },
    {
        input_type: 'input',
        col: 21,
        label: '项目明细',
        key: 'payProjectDetail',
    },
    { 
        input_type: 'date',
        col: 21,
        label:'计费区间',
        type: 'daterange',
        key: 'times',
        format:'yyyy-MM-dd',
        valueFormat:'yyyy-MM-dd',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        attribute: {unlinkPanels:true}
    },
    {
        input_type: 'input',
        col: 15,
        label: '用量',
        key: 'consumption'
    },
    {
        input_type: 'input',
        col: 15,
        label: '单价',
        key: 'unitPrice',
    }
]
// 调整单价
export const AdjustUnitPriceData =[
    {
        input_type: 'number',
        label: '用量',
        key: 'reviseConsumption',
        col: 21,
    },
    {
        input_type: 'number',
        label: '单价',
        key: 'reviseUnitPrice',
        col: 21,
    },
    {
        input_type: 'input',
        label: '调整备注',
        key: 'adjustmentRemark',
        col: 21,
    },
]
// 批量删除
export const batchDeletionData = [
    {
        input_type: 'select',
        label: '批量删除',
        key: 'qw',
        col: 15,
        options: [
            {
                value: 7,
                label: '7天以前的'
            },
            {
                value: 30,
                label: '30天以前的'
            }
            ,
            {
                value: 90,
                label: '90天以前的'
            }
            ,
            {
                value: 180,
                label: '180天以前的'
            },
            {
                value: 365,
                label: '365天以前的'
            }
        ]
    },{
        input_type:'text',
        text:'未通过审核的账单',
        col: 9
    },
    {
        customizeTitle:'ds'
    }
]
