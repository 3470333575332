<template>
    <div>
        <transition name="bounce">
            <div
                v-if="show"
                class="rightBox"
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgb(36 33 33 / 90%)"
            >
                <el-container>
                    <el-header>
                        <el-row class="buttonRow">
                            <el-col :span="12">
                                <span
                                    :class="{highlight:tapHeadId===1?true:false}"
                                    @click="tapHead(1)"
                                > 空间信息</span>
                            </el-col>
                            <el-col :span="12">
                                <span
                                    :class="{highlight:tapHeadId===2?true:false}"
                                    @click="tapHead(2)"
                                >售销数据</span>
                            </el-col>
                        </el-row>
                    </el-header>
                    <el-main>
                        <el-row v-if="tapHeadId == 1">
                            <el-col :span="24">
                                <p>{{roomData.tenantName}}</p>
                            </el-col>
                            <el-col
                                :span="24"
                                v-for="(item,id) in listSpace"
                                :key="id"
                            >
                                <span class="roomLabel">{{item.label}}</span>
                                <span class="roomValue">{{roomData[item.value]}} {{item.rightText?item.rightText:''}}</span>
                            </el-col>

                        </el-row>

                        <el-row v-else-if="tapHeadId == 2">
                            <el-col :span="24">
                                <p>103单元 lingyige </p>
                            </el-col>
                            <el-col
                                :span="24"
                                v-for="(item,id) in listSales"
                                :key="id"
                            >
                                <span>{{item.label}}</span>
                                <span>{{roomData[item.value]}}</span>
                            </el-col>
                        </el-row>

                    </el-main>
                </el-container>

            </div>
        </transition>

    </div>

</template>
<script>
import { getSpatialInformation } from '@/api'
export default {
    data() {
        return {
            show: false,
            tapHeadId: 1,
            loading: false,
            roomData: {},
            listSpace: [
                { label: '驻户名称：', value: 'tenantName' },
                { label: '企业类型：', value: 'tenantCategory' },
                { label: '营业执照号码：', value: 'licenseNum' },
                { label: '负责人：', value: 'linkMan' },
                { label: '联系方式：', value: 'contactNumber' },
                { label: '套内总体使用面积：', value: 'totalArea', rightText: '㎡' },
                { label: '合同期限：', value: 'tenancyTerm' },
            ],
            listSales: [
                { label: '租户名称：', value: '' },
                { label: '运营品牌：', value: '' },
                { label: '业态：', value: '' },
                { label: '本月租金：', value: '' },
                { label: '实收租金：', value: '' },
                { label: '欠款额度：', value: '' },
                { label: '月交易：', value: '' },
                { label: '月销售额：', value: '' },
                { label: '环比上月：', value: '' },
            ]
        }
    },
    methods: {
        showRrawer(id) {
            this.loading = true
            getSpatialInformation(id).then(res => {
                if (res.code === 200) {
                    res.data.status = res.data.status === 0 ? '空闲' : '租用'
                    this.roomData = { ...res.data }
                    this.loading = false
                }

            })
            this.show = true
        },
        hideRrawer() {
            this.show = false
        },
        tapHead(val) {
            this.tapHeadId = val
        }
    }
}
</script>
<style scoped less="less">
.rightBox {
    border-width: 0px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 360px;
    height: 85%;
    background: inherit;
    background-color: rgba(0, 0, 0, 0.8);
    border: none;
    border-radius: 12px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 5px;
}
.el-header {
    text-align: center;
    line-height: 40px;
}
.buttonRow {
    border-bottom: 0.5px solid #fff;
}
.buttonRow span {
    cursor: pointer;
    color: #7f7f7f;
}
.el-col .el-button--text {
    color: '#606266';
}
.highlight {
    color: #fff !important;
}
.el-container {
    color: #fff;
}
.el-col > span {
    font-size: 12px;
    margin: 12px 0;
    display: inline-block;
}
.bounce-enter-active {
    animation: bounce-out 0.8s;
}
.bounce-leave-active {
    animation: bounce-in 0.8s;
}
@keyframes bounce-in {
    0% {
        right: 0;
    }
    100% {
        right: -30%;
    }
}
@keyframes bounce-out {
    0% {
        right: -30%;
    }
    100% {
        right: 0;
    }
}
.roomLabel {
    display: inline-block !important;
    width: 12em !important;
}
.roomValue {
    color: #aaaaaa;
}

</style>