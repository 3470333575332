<template>
  <router-view/>
</template>

<script>
export default {
  title: "智慧安防",
  name:'smartSecurity'
}
</script>

