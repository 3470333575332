import http from './plugin/ajax'

// =========  驻场管理  ===========

// 驻户信息
export const addTenant = (data) => http.post('/tenant', data) // 添加
export const editTenant = (data) => http.put('/tenant', data) // 修改
export const exportTenant = (data) => http.post('/tenant/export', data) // 导出
export const rqTenant = (data) => http.post('/tenant/list', data) // 查询 列表
export const delTenant = (data) => http.delete('/tenant/' + data) // 删除 一项
export const auditTenant = (data) => http.put('/tenant/audit/' + data) //  审批 一项
export const submitTenant = (data) => http.put('/tenant/submit/' + data) //  提交 一项
export const getResidentRentalUnitList = (data) => http.get(`/contract/listByTenantId/${data}`) // 获取驻户合同生效的租赁单元
export const addAccountNumber = (data) => http.post('/tenant/addShop', data) //  添加账号

// ------------ 驻户抄表 ----------
export const rqTenantTable = (data) => http.post('/tenant/meterReading', data) // 添加
export const getContract = (data) => http.post('/tenant/contract', data) // 驻场合同

// ------------ 驻户账号列表 ----------

export const getResidentAccountList = (data) => http.get('/employee/tenant', { params: data }) // 获取列表数据
export const accountOpening = (data) => http.get(`/employee/enable/${data}`) // 开启
export const accountClosing = (data) => http.delete(`/employee/disable/${data}`) // 关闭
export const residentResetPassword = (data) => http.get(`/employee/reset/${data}`) // 重置密码
