import http from './plugin/ajax'

// ****** 车位管理
// 车位列表
export const parkingManageList = (data) => http.post('/sysStallInfo/list', data)
// 新增车位
export const addParkingManage = (data) => http.post('/sysStallInfo', data)
// 编辑车位
export const editParkingManage = (data) => http.put('/sysStallInfo', data)
// 删除车位
export const delParkingManage = (data) => http.delete('/sysStallInfo/' + data)

// ****** 车位管理
// 车位列表
export const groundManageList = (data) => http.post('/sysParkingInfo/list', data)
// 新增车位
export const addGroundManage = (data) => http.post('/sysParkingInfo', data)
// 编辑车位
export const editGroundManage = (data) => http.put('/sysParkingInfo', data)
// 删除车位
export const delGroundManage = (data) => http.delete('/sysParkingInfo/' + data)