
<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="isShowAddEdit" width="75%" title="新增用户" @close="close" >
    <ja-table ref="tst" v-model="filterData" :columns="columns" :tableData="tableData" :conditionData="conditionData" :total="total" :border="true" :showSelect="true" :loading="loading" index_label="序号" :showIndex="true" @handleSelectItem="handleSelectItem" @handleSelectionAll="handleSelectionAll" @selectRow="selectRow" @getInit="getInit2" @search="search" @reset="reset" v-loading="loadingForm">
      <template #right>
        <el-button type="primary" @click="submit()" class="submit">添加</el-button>
      </template>
      <template #isAll>
        <!-- <el-checkbox v-model="checked" @change="selectAllPerson" >全选</el-checkbox> -->
      </template>
    </ja-table>
    <!-- <div class="botttom">已选择：{{checked && isShowSelectAll ?(except_person_name?`所有人，除了“${except_person_name}”`:'所有人'):NowPersons2}}</div> -->
  </el-dialog>
</template>
<script>
import table from '@/views/mixins/table'
import selectTable from '@/views/mixins/selectTable'
import { rqMsgGroupEmployee } from '@/api'

export default {
  title: '选择用户',
  mixins: [table, selectTable],
  name: 'index',
  props: {
    isShowAddEdit: Boolean,
  },
  data() {
    let conditionData_own = [
      { input_type: 'select', key: 'userType', attribute: { placeholder: '用户类型', size: 'small' }, options: [], },
      { input_type: 'input', key: 'userName', attribute: { placeholder: '账号名', size: 'small' } },
      { input_type: 'input', key: 'employeeName', attribute: { placeholder: '用户名称', size: 'small' } },
    ]
    let columns_own = [
      { label: '账号', prop: 'userName', attribute: { showOverflowTooltip: false, align: 'center' } },
      { label: '用户名称', prop: 'employeeName', attribute: { showOverflowTooltip: false, align: 'center' } },
      { label: '所属单位', prop: 'companyName', attribute: { showOverflowTooltip: false, align: 'center' } },
    ]
    return {
      rq_Method: rqMsgGroupEmployee,
      IdKeyWord: 'employeeId',
      keyName: 'employeeName',
      conditionData: [...conditionData_own, { normalBtn: 'search', }, { normalBtn: 'reset' }, { slotName: 'isAll' }],
      columns: [...columns_own,
        {
          label: '操作', input_type: 'button', width: 120, attribute: { fixed: 'right', align: 'center' },
          list: [
            { text: '选择', method: 'selectRow' }
          ]
        }
      ],
      loadingForm:false
    }
  },
  created() {
    this.util.rqOptionsList({ who: [this.conditionData[0],], rqUrl: "/sys/dictData/msg_user_type", resRule: 'data', })
    // // 顶部搜索框
    // this.util.rqOptionsList({ who: [this.conditionData[0]], rqUrl: "/company", rqData: { params: { pageIndex: 1, pageSize: 200 } }, rqMethod: 'get', labelName: 'companyName', valueName: 'id' }) // 所属公司
  },
  methods: {
    rqParmasFather() {
      return {
        groupId: this.$parent.activeTree
      }
    },
    append() {
      this.showEditAll = true
      this.reset()
    },
    close() {
      this.$emit('update:isShowAddEdit', false)
    },
  }
}
</script>