/** 缴费管理列表 */
export const billPaymentManageColumn = [
  { label: '驻户名称', prop: 'tenantName', width: '150px' },
  { label: '驻户编号', prop: 'tenantCode', width: '150px' },
  { label: '驻户类型', prop: 'categoryLabel', width: '150px' },
  { label: '驻户属性', prop: 'attributeLabel', width: '150px' },
  { label: '统一社会信用代码', prop: 'unifiedSocialCreditCode', width: '150px' },
  { label: '身份证号码', prop: 'idCard', width: '150px' },
  { label: '联系方式', prop: 'contactNumber', width: '150px' },
  { label: '保证金总额（元）', prop: 'depositBalance', width: '150px' },
  { label: '驻户余额（元）', prop: 'accountBalance', width: '150px' },
  { label: '总余额（元）', prop: 'totalBalance', width: '150px' },
  { label: '驻户状态', prop: 'status', width: '150px' },
  {
    label: '操作',
    input_type: 'button',
    width: 160,
    list: [
      { text: '到账登记', method: 'append', limit:'paycost::manage::register' },
      { text: '流水查询', method: 'showBillFlowDetail', limit:'paycost::manage::select' }
    ]
  }
]

/** 银行到账登记 */
export const arrivalRegistrationData = [
  {
    input_type: 'input',
    label: '到账金额',
    key: 'transactionAmount',
    col: 22,
    slotPosition: 'append',
    slotName: '元'
  },
  {
    input_type: 'date',
    type: 'datetime',
    label: '到账时间',
    key: 'receivedDate',
    pickerOptions:{
      disabledDate(date){
          return date.getTime() < Date.now() - 24 * 60 *60 *1000 * 90
      }
    },
    col: 22
  },
  {
    input_type: 'input',
    label: '交易备注',
    key: 'remark',
    type: 'text',
    col: 22
  }
]


