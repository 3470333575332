<template>
    <div>
        <el-dialog :visible.sync="show"
                   :close-on-click-modal="false"
                   width="70%"
                   title="新增终止">
            <ja-table :conditionData="conditionData"
                      :columns="columns"
                      :tableData="tableData"
                      v-model="filterData"
                      :valueFormat="formatter"
                      :total="total"
                      :loading="loading"
                      :showIndex="true"
                      @getInit="listInit"
                      @search="search"
                      @reset="reset"
                      @choice="choice" />

        </el-dialog>
        <all-detail ref="allDetailRefs"
                    @getInit="getInit"></all-detail>
    </div>
</template> 
<script>
import { getArchivesList } from '@/api'
import AllDetail from './allDetail.vue'
export default {
    inject: ['getInit'],
    title: '终止',
    components: {
        AllDetail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'contractCode', attribute: { placeholder: '合同编号', size: 'small' } },
                { input_type: 'input', key: 'contractName', attribute: { placeholder: '合同名称', size: 'small' } },
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'select', key: 'contractType', attribute: { placeholder: '合同类型', size: 'small' }, options: [] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '合同编号', prop: 'contractCode', width: 150 },
                { label: '合同名称', prop: 'contractName', width: 150 },
                { label: '合同类型', prop: 'contractTypeName', width: 150 },
                { label: '租期', prop: 'leaseTerm', width: 200 },
                { label: '计租开始日期', prop: 'billingTime', width: 150 },
                { label: '驻户名称', prop: 'tenantName', width: 150 },
                { label: '驻户租赁单元', prop: 'roomInfo', width: 150 },
                { label: '合同批次', prop: 'contractBatch' },
                { label: '状态', prop: 'statusName', },
                {
                    label: '操作', input_type: 'button', width: 200, attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '选择', method: 'choice' },

                    ]
                }
            ],
            tableData: [],
            total: 0,
            show: false,
            loading: false,
            filterData: {
                pageIndex: 1,
                pageSize: 10,
            },

        }
    },
    mounted() {
        let length = this.conditionData.findIndex(item => item.key == 'contractType')
        this.util.rqOptionsList({
            who: [this.conditionData[length]],
            rqUrl: '/sys/dictData/contract_type',
            resRule: 'data'
        })
        this.listInit()
    },
    methods: {
        listInit() {
            this.loading = true
            getArchivesList(this.rqParmas()).then((res) => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
        initState() {
            this.show = true
            this.listInit()
        },
        choice(row) {
            this.show = false
            this.$refs.allDetailRefs.init(1, row)
        },
        search() {
            this.filterData.pageIndex = 1
            this.listInit()
        },
        reset() {
            this.filterData = {
                pageIndex: 1,
                pageSize: 10
            }
            this.listInit()
        },
        formatter(val, key) {
            if (key === 'roomInfo') {
                if (val && val.length > 0) {
                    let text = val.map((item) => item.roomName).join('，')
                    return text || ''
                } else {
                    return ''
                }
            }
            if (key === 'statusName') {
                return '已通过'
            }
            return val || ''
        },
        rqParmas() {
            return {
                ...this.filterData,
                queryFlag: 2,
                status: 2,
                isTerminationQuery: 1
            }
        },
    }
}
</script>
<style lang="scss" scoped>
</style>