<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="55%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowForm"
                     labelWidth="120px"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     @selectCharges="selectCharges"
                     @selectType="selectType"
                     v-if="show">
                <template #paymentDate>
                    <span>每个月的第</span>
                    <el-select v-model="rowForm.day"
                               class="day"
                               style="width:40%;margin:0 10px"
                               placeholder="请选择">
                        <el-option v-for="item in 28"
                                   :key="item"
                                   :label="item"
                                   :value="item"></el-option>
                    </el-select>
                    <span>日</span>
                </template>
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { addChargingSettings } from '@/api'
export default {
    inject: ['labelWidth', 'getInit'],
    data() {
        return {
            value: null,
            rules: {
                serviceType: [{ required: true, trigger: 'change', message: '收费项目类型不能为空' }],
                natureCharges: [{ required: true, trigger: 'change', message: '收费项目性质不能为空' }],
                day: [{ required: true, trigger: 'change', message: '缴费日期' }],
                serviceName: [{ required: true, trigger: 'change', message: '收费项目名称不能为空' }],
            },
            rowFormData: [
                { input_type: 'input', key: 'serviceCode', label: '收费项目编号', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'select', key: 'serviceType', label: '收费项目类型', col: 12, options: [], method: 'selectType' },
                { input_type: 'select', key: 'natureCharges', label: '收费项目性质', col: 12, options: [], method: 'selectCharges' },
                { input_type: 'customize', customize: 'paymentDate', label: '缴费日期', col: 12, key: 'day', hide: true },
                { input_type: 'input', key: 'serviceName', label: '收费项目名称', col: 12, },
                { input_type: 'select', key: 'serviceName', label: '收费项目名称', col: 12, hide: true, options: [{ label: '电费', value: '电费' }, { label: '设备维护服务费（电）', value: '设备维护服务费（电）' }, { label: '水费', value: '水费' }, { label: '设备维护服务费（水）', value: '设备维护服务费（水）' }] },
            ],
            title: '查看收费项使用记录',
            show: false,
            isAdd: false,
            allDisabled: false,
            rowForm: {},
            lengthA: null
        }
    },
    watch: {
        'rowForm.natureCharges': {
            handler(newVla) {
                if (newVla && newVla == '周期性') {
                    this.$set(this.rowFormData[3], 'hide', false)
                    this.$set(this.rowFormData[5], 'hide', false)
                    this.$set(this.rowFormData[4], 'hide', true)
                } else {
                    this.$set(this.rowFormData[3], 'hide', true)
                    this.$set(this.rowFormData[4], 'hide', false)
                    this.$set(this.rowForm, 'day', null)
                    this.$set(this.rowFormData[5], 'hide', true)

                }
            }
        }
    },
    mounted() {
        let length1 = this.rowFormData.findIndex(e => e.key === 'serviceType')
        let length2 = this.rowFormData.findIndex(e => e.key === 'natureCharges')
        this.lengthA = length2
        this.util.rqOptionsList({ rqUrl: '/sys/dictData/query', rqMethod: 'post', rqData: { dictType: 'chargeitem_type' }, labelName: 'dictLabel', valueName: 'dictValue' }).then(res => {
            let arr = res.filter(item => {
                if (item.label != '保证金类') return item
            })
            this.rowFormData[length1].options = arr
        })
        this.util.rqOptionsList({
            who: this.rowFormData[length2],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'nature_charges' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        async init(status = 1, row) {
            this.rowForm = {}
            this.title = '新增收费项目定义'
            this.show = true
            this.isAdd = true
            this.allDisabled = false
            if (status == 2) {
                this.isAdd = false
                this.title = '编辑'
                this.rowForm = { ...row }
            } else if (status == 3) {
                this.isAdd = false
                this.title = '查看收费项目定义'
                this.allDisabled = true
                this.rowForm = { ...row }
            }

        },
        saveForm() {
            let obj = {
                ...this.rowForm,
                feesName: `${this.rowForm.serviceName}收费标准`,
            }
            if (this.rowForm.natureCharges == '临时性') {
                obj.fees = '固定金额'
            }
            if (this.rowForm.natureCharges == '周期性') {
                obj.isEffective = 0
                obj.fees = '用量X单价'
            }
            addChargingSettings(obj).then(res => {
                this.show = false
                this.getInit()
            })
        },
        selectType(row, val) {
            let obj = this.rowFormData[this.lengthA].options
            if (val == '租金类') {
                if (this.rowForm.natureCharges) this.rowForm.natureCharges = null
                obj.map(item => {
                    if (item.label != '临时性') {
                        item.disabled = true
                    }
                })
            } else if (val == '物管类') {
                obj.map(item => {
                    item.disabled = false
                })
                this.$set(this.rowForm, 'serviceName', null)
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>