<template>
    <div>
         <!-- 详情弹窗 -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" :title="'收费单明细' + `  账单号：${detailData.billCode}`" :width="`60%`">
            <ja-detail :options="detailOptions" :detailData="detailData" :showTableTitle="false" :label_width='12'></ja-detail>
            <div style="text-align: center"><el-button @click="showDetail = false" style="">关 闭</el-button></div>
        </el-dialog>
    </div>
</template>
<script>
import { rqBillWriteOffDetailList } from '@/api'
export default {
  inject: ['labelWidth', 'getInit'],
  data () {
    return {
      showDetail: false,
      detailOptions:
            [
              { label: '缴费单位/个人', key: 'tenantName', col: 8 },
              { label: '房间', key: 'roomName', col: 8 },
              { label: '生成日期', key: 'billSendTime', col: 8 },
              { label: '核销状态', key: 'writeOffStatusName', col: 8 },
              { label: '开票类型', key: 'invoiceTypeName', col: 8 },
              { label: '开票月份', key: 'invoiceDates', col: 8 },
              {
                type: 'table',
                noSearch: true,
                showPage: false,
                key: 'billDetail',
                col: 24,
                table_MaxHeight: 500,
                showTableLabel: false,
                showSummary: true,
                chooseArr:[,'本期应收（元）','本期已缴（元）','欠费（元）'],
                columns: [
                  { label: '收费项', prop: 'payProject' },
                  { label: '项目明细', prop: 'payProjectDetail' },
                  { label: '计费期间', prop: 'time' },
                  { label: '用量', prop: 'consumption' },
                  { label: '单价（元）', prop: 'unitPrice' },
                  { label: '本期应收（元）', prop: 'totalCost' },
                  { label: '本期已缴（元）', prop: 'amountWriteOff' },
                ]
              }
            ],
      detailData: {},
      initFileList: [],
      
    }
  },
  methods: {
    rowDetail (row) {
      this.detailData = {}
      this.showDetail = true
      // 获取账单核销详情列表
      rqBillWriteOffDetailList({ billCode: row.billCode }).then(res => {
        const invoiceDates = row.invoiceDate ? this.$moment(row.invoiceDate).format("YYYY-MM") : '-'
        this.detailData = { ...row, billDetail: res.data, invoiceDates }
      })
    }
  }
}
</script>
