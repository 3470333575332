<template>
  <div class="table">
    <div  v-for="(item,index) in contractList" :key="index" class="box-card">
      <el-card >
        <div class="text item item1">
          关联合同：{{ item.contractCode }}
        </div>
        <!-- <div class="text item">
          合同类型：{{ item.contractType }}
        </div> -->
        <div class="text item">
          合同期限：{{ item.contractTime }}
        </div>
        <div class="text item">
          合同状态：{{ item.contractStatus }}
        </div>
        <div class="text item">
          合同房产：{{ item.contractRoom }}
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContractList',
  props: {
    contractList: {
      type:Array,
      default(){
        return []
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.table{
  .item1{
    font-size: 17px;
    font-weight: 600;
  }
}
.text {
  font-size: 16px;
  color: #606266;
}

.item {
  padding: 10px 5px;
}

.box-card {
  width: cal(100%, 100px);
  margin-bottom: 20px;
}
</style>
