import http from './plugin/ajax'

// =========  收费管理  ===========

// 账单管理
export const rqBill = (data) => http.get('/bill', {
    params: data
})

// 确认缴费
export const rqBillConfirmReceipt = (data) => http.put('/bill/confirm', data)

// 账单核销列表
export const rqBillWriteOffList = (data) => http.get('/bill/writeOff', {
    params: data
})

// 账单核销-详细列表
export const rqBillWriteOffDetailList = (data) => http.post('/bill/writeOff/detail', data)

// 缴费管理-列表
export const rqPaymentManageList = (data) => http.post('/tenant/list', data)

// 缴费管理-交易明细列表
export const transactionDetails = (data) => http.post('/account/record', data)

// 缴费管理-流水查询-保证金类型
export const getDepositType = () => http.get('/account/getDepositType')

// 收费单管理-开票登记
export const invoiCing = (data) => http.put('/bill/invoicing', data)

// 加载开票登记数据
export const invoiceType = () => http.get('/bill/invoiceType')

// 收费项查询
export const payList = () => http.get('/pay/list')

// 保证金列表
export const getBondList = (data) => http.post('/account/bzj', data)
// 退还保证金
export const depositRefund = (data) => http.post('/account/depositRefund', data)
// 保证金退还记录
export const getRefundRecord = (data) => http.get('/account/depositRefund/detail/' + data)
