<template>
    <div>
        <el-col span="24" v-loading="loading">
            <div class="headle">
                <span>{{ title }}</span>
                <div class="select" v-if="type == 2">
                    <el-select v-model="value" placeholder="请选择" @change="getNewStatistics">
                        <el-option v-for="item in slelctArr" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div ref="wrap" class="wrap">
            </div>

        </el-col>
    </div>
</template>
<script>
import { getUsageStatistics, getelectricity } from '@/api'
export default {
    title: '统计',
    props: {
        title: {
            type: String,
            default: "标题"
        },
        type: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            loading: false,
            ydata: [],
            oldYdata: [],
            value: 1,
            slelctArr: [
                {
                    value: 1,
                    label: '总表'
                }, {
                    value: 0,
                    label: '不含总表'
                },
            ]
        }
    },
    mounted() {
        this.getInit()
    },
    methods: {
        getInit() {
            let ydata = []
            let oldYdata = []

            if (this.type == 2) {//电表
                getelectricity(this.value).then(res => {
                    res.data[0].energyVOList.map(item => {
                        ydata.push(Number(item.dosage).toFixed(2))
                    })
                    res.data[1].energyVOList.map(item => {
                        oldYdata.push(Number(item.dosage).toFixed(2))
                    })
                    this.ydata = ydata
                    this.oldYdata = oldYdata
                    this.setOptions()
                })
            } else {
                getUsageStatistics({ type: this.type, year: new Date().getFullYear() }).then(res => {
                    res.data[0].energyVOList.map(item => {
                        ydata.push(Number(item.dosage).toFixed(2))
                    })
                    res.data[1].energyVOList.map(item => {
                        oldYdata.push(Number(item.dosage).toFixed(2))
                    })
                    this.ydata = ydata
                    this.oldYdata = oldYdata
                    this.setOptions()
                })
            }
        },
        //切换总表 不含总表数据
        getNewStatistics() {
            this.getInit()
        },
        async setOptions() {
            //区分浮动显示单位
            let companyStr = this.type == 2 ? '（kW·h）' : '(m³)'
            let options = {
                color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                },
                legend: {
                    top: '5%',
                    right: 0,
                    itemGap: this.util.setFontSize(30, 1920),
                    textStyle: { //图例文字的样式
                        fontSize: this.util.setFontSize(15, 1920),
                    },
                    data: ['本期', '同比',],
                },
                formatter: function (params) {
                    if (typeof params != 'object') return params
                    let str = ''
                    let year = new Date().getFullYear()
                    params.map(item => {
                        if (item.seriesIndex == 0) {
                            str += `${year}年${item.name}：${item.value}  ${companyStr} <br/>`
                        } else if (item.seriesIndex == 1) {
                            str += `${(year - 1)}年${item.name}： ${item.value} ${companyStr} <br/>`
                            str += `同比增长：${(params[0].value - item.value)} ${companyStr}<br/>`
                            let length = params[0].dataIndex - 1
                            if (length < 0) {
                                str += `较上月环比：${(params[0].value - (this.oldYdata[this.oldYdata.length - 1])).toFixed(2)} ${companyStr}<br/>`
                            } else {

                                str += `较上月环比：${(params[0].value - (this.ydata[length])).toFixed(2)} ${companyStr}<br/>`
                            }
                        }
                    })
                    return str
                }.bind(this),
                xAxis: [
                    {
                        type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisPointer: {
                            type: 'none',
                        },
                        offset: this.util.setFontSize(8, 1920),
                        axisLabel: {
                            fontSize: this.util.setFontSize(15, 1920),
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: companyStr,
                        axisTick: {
                            show: false,
                        },
                        axisPointer: {
                            label: {
                                show: true,
                            }
                        },
                        nameGap: 20,
                        nameTextStyle: {
                            fontSize: this.util.setFontSize(16, 1920),
                        },

                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: this.util.setFontSize(14, 1920),
                        },
                    }
                ],
                series: [
                    {
                        name: '本期',
                        type: 'bar',
                        data: this.ydata,
                    },
                    {
                        name: '同比',
                        type: 'bar',
                        data: this.oldYdata,
                    },
                    {
                        xAxisIndex: 1,
                        yAxisIndex: 0,
                        type: 'line',
                        data: this.ydata
                    }

                ]
            }
            //添加一个新的刻度线 实现折线在柱状图的中间显示
            options.xAxis[1] = {
                type: 'value',
                max: options.xAxis[0].data.length,
                show: false,
                axisPointer: {
                    label: {
                        show: false,
                    }
                },
            }
            options.series[2].data = options.series[2].data.map((x, i) => [(0.25 + i), x])
            const myChart = this.$echarts.init(this.$refs.wrap)
            myChart.setOption(options)
            window.addEventListener('resize', () => {
                myChart.resize();
            })
        }

    }
}
</script>
<style lang="scss" scoped>
.headle {
    border-bottom: 1px solid black;
    width: 90%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

.wrap {
    width: 90%;
    height: 400px;
}

.select {
    margin-left: 10px;

    /deep/ .el-input__inner {
        height: 30px;
        line-height: 30px;
        width: 100px;
    }

    /deep/ .el-input__suffix {
        height: 30px;
        line-height: 45px;
    }
}
</style>