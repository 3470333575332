<template>
    <el-dialog :visible.sync="show" width="40%" title="查看租赁区域">
        <ja-form :rules="rules" allDisabled v-model="rowForm" :formArray="rowFormData" v-if="show" :editDataFormat="editDataFormat">
        </ja-form>
    </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
    components: {
        JaForm,
    },
    data () {
        return { 
            rules: {},
            rowFormData: [
                { input_type: 'input', label: '楼栋', key: 'buildingName', labelWidth: "110px" },
                { input_type: 'input', label: '楼层', key: 'floorName', labelWidth: "110px" },
                { input_type: 'input', label: '空间类型', key: 'spaceTypeValue', labelWidth: "110px" },
                { input_type: 'input', label: '租赁空间名称', key: 'roomName', labelWidth: "110px" },
                { input_type: 'input', label: '套内总体使用面积', key: 'totalArea' ,labelWidth: "110px" },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', col: 22, maxlength: 200 }
            ],
            show: false,
            rowForm: {},
        }
    },
    methods: {
        init (row) {
            this.show = true
            this.rowForm = { ...row }
        }
    }
}
</script>