<template>
  <!-- 表格 -->
  <div class="content">
    <title-box title="设备类型统计" :isMore="true" />
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import {getCategory} from '@/api'
export default {
  components: {
    JaInitChart,TitleBox
  },
  data() {
    return {
      options: null
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    turnMore(){
      console.log('查看更多')
    },
    async setOptions() {
        let initData = []
        await getCategory().then(res=>{
            let data = res.data.data
            data.map(item=>{
                let {categoryName:name,num:value} = item
                initData.push({name,value})
            })
        })
      console.log(initData);
      let xData = initData.map(v=>v.name)
      this.options = {
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc','#91A4B8','#829285','#056A18'],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          right: 30,
          top:'5%',
          width: '10%',
          height: '80%',
          itemGap: this.util.setFontSize(10, 1920),
          textStyle: { //图例文字的样式
            fontSize: this.util.setFontSize(15, 1920),
          },
          formatter: function (val) {
            let item = initData.find(v => v.name == val)
            return `${val}  ${item.value}  个`;
          },
          data: xData
        },
        series: [
          {
            name: 'Area Mode',
            type: 'pie',
            radius: ['10%', '50%'],
            center: ['40%', '35%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 5
            },
            colorBy: 'data',
            label: {
              fontSize: this.util.setFontSize(20, 1920),
              formatter: function (val) {
                return val.percent + '%';
              },
            },
            data: initData
          }
        ]

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  width:100%;
  height: 100%;
}
.echart {
  height: 100%;
  width: 100%;

}
</style>