<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="50%"
                   title="查看装修巡查记录"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="{}"
                     v-model="rowForm"
                     :formArray="detailOptions"
                     :btnBox="btnBox"
                     @save="saveForm"
                     @patroTypeSelect="patroTypeSelect"
                     v-if="show">
            </ja-form>
            </ja-detail>
        </el-dialog>
    </div>
</template>
<script>
import { getPatrolLists } from '@/api'
export default {
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'title', text: '基本信息', col: 24, key: 'title1', noMarginBottom: true },
                { input_type: 'input', label: '驻户名称', key: 'tenantName', labelWidth: '120px', disabled: true, col: 12 },

                { input_type: 'input', label: '报装人', key: 'shipper', labelWidth: '120px', disabled: true, col: 12 },
                { input_type: 'input', label: '报装人手机号码', key: 'shipperPhone', labelWidth: '120px', disabled: true, col: 12 },

                { input_type: 'input', label: '所属楼栋', key: 'buildingName', labelWidth: '120px', disabled: true, col: 12 },
                { input_type: 'input', label: '所属楼层', key: 'floorName', labelWidth: '120px', disabled: true, col: 12 },
                { input_type: 'input', label: '所属租赁单元', key: 'roomName', labelWidth: '120px', disabled: true, col: 12 },
                { input_type: 'input', label: '巡查项目类型', key: 'patrolTypeName', labelWidth: '120px', disabled: true, col: 12 },
                { input_type: 'input', label: '巡查人员', key: 'updateBy', labelWidth: '120px', disabled: true, col: 12 },
                { input_type: 'input', label: '巡查时间', key: 'createTime', labelWidth: '120px', disabled: true, col: 12 },
                { input_type: 'title', text: '巡查项目信息', col: 24, key: 'title1', noMarginBottom: true },

                { input_type: 'select', label: '', key: 'patrolType', labelWidth: '120px', col: 12, options: [], method: 'patroTypeSelect', placeholder: '巡查项目类型' },
                {
                    input_type: 'table', label: '', labelWidth: '120px', col: 20, noSearch: true, showPage: false, key: 'patrolTable', isRequired: true, border: true, table_MaxHeight: 500, showIndex: false,
                    columns: [
                        { label: '巡查项目类型', prop: 'patrolTypeName' },
                        { label: '巡查项目', prop: 'remark' },
                        { label: '状态', prop: 'statusName' },
                    ]
                },
            ],
            rowForm: {},
            show: false,
            btnBox: []
        }
    },
    mounted() {
        let index = this.detailOptions.findIndex((e) => e.key === 'patrolType')
        this.util.rqOptionsList({
            who: [this.detailOptions[index]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'patrol_type' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        async init(row) {
            this.show = true
            this.rowForm = { ...row, patrolType: '0' }
            let res = await getPatrolLists({ patrolRecordId: row.id, patrolType: 0 })
            this.$set(this.rowForm, 'patrolTable', res.data)
        },
        async patroTypeSelect(row, val) {
            let { id } = this.rowForm
            let res = await getPatrolLists({ patrolRecordId: id, patrolType: val })
            this.$set(this.rowForm, 'patrolTable', res.data)
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/.formItemText {
    margin-bottom: 15px !important;
    background-color: #ebffff;
    border-bottom: 1px solid rgb(217, 217, 217);
    padding-left: 10px;
}
</style>
