<template>
    <div>
        <span class="titleSpan">巡更线路列表</span>
        <ul class="right-card-box"
            ref="caedBox"
            v-loading="loading">
            <li class="right-card"
                shadow="always"
                v-for="(item,num) in cardsData"
                :key="item.id">
                <div class="right-card-text"
                     @click="checkedItem(num,item)">
                    <p>{{item.lineName}}</p>
                </div>
                <div class="cradUpdata"
                     @click="getUpdataId(item.id)">
                    <el-upload ref="upload"
                               :action="$uploadUrl+'/oss/upload'"
                               :on-success="onSuccess"
                               :on-progress="onProgress"
                               :limit="20"
                               :before-upload="beforeUpload"
                               :show-file-list="false"
                               :on-error="imgErrorMethod"
                               accept=".png,.jpg,.jpeg,.bmp">
                        <el-button type="text"
                                   icon="el-icon-upload">示意图上传</el-button>
                    </el-upload>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { getPatrolRoute, updataRouteUrl } from '@/api'
export default {
    name: '巡更记录列表',
    data() {
        return {
            cardsData: null,
            loading: false,
            updataId: null,
            checkedId: null,
            loading: null
        }
    },
    mounted() {
        this.getInit()

    },
    methods: {
        getInit() {
            this.loading = true
            getPatrolRoute().then(res => {
                this.loading = false
                this.cardsData = res.data
                this.$bus.$emit("getRouteList", res.data)
            })
        },
        checkedItem(num, item) {
            let NodeList = this.$refs.caedBox.childNodes
            let length = NodeList[0].nodeName == 'LI' ? num : num + 1
            for (let i = 0; i < NodeList.length; i++) {
                if (i === length) {
                    NodeList[i].className = "right-card checkCard"
                    this.$emit('getSrc', item.imageUrl)
                    this.checkedId = item.id
                } else {
                    NodeList[i].className = "right-card"
                }

            }
        },
        //文件成功上次后接收地址
        onSuccess(res) {
            if (res.code != 200) return this.$message('文件上传失败')
            let obj = {
                id: this.updataId,
                url: res.data
            }
            updataRouteUrl(obj).then(res => {
                this.$message.success('图片上传成功')
                this.loading.close();
                this.getInit()
                if (this.updataId != this.checkedId) return false
                this.$emit('getSrc', obj.url)
            })
        },
        beforeUpload(file) {
            let name = file.name
            let size = file.size
            let pattern = /.(jpg|bmp|png|jpeg)/
            if (!pattern.test(name)) {
                this.$message.error('只接受图片格式：jpg,bmp,png,jpeg')
                return false
            }
            if ((size) / 1024 / 1024 > 4) {
                this.$message.error('图片导入失败，图片不能大于4M')
                return false
            }

        },
        onProgress() {
            this.loading = this.$loading({
                lock: true,
                text: '图片上传中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        // 图片上传失败
        imgErrorMethod() {
            this.loading.close();
            this.$message.error('图片上传失败，请重试')
            this.$refs.imgUpload.abort()
            return false
        },
        getUpdataId(id) {
            this.updataId = id
        }
    }
}
</script>
<style lang="scss" scoped>
.titleSpan {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 12px;
}
.right-card-box {
    border: 1px solid rgba(215, 215, 215, 1);
    height: 200px;
    overflow: auto;
    font-size: 12px;
}
.right-card-box::-webkit-scrollbar {
    width: 7px;
}
.right-card-box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(7, 150, 126, 0.2);
}
.right-card-box::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(21, 31, 15, 0.1);
}
.right-card-text {
    width: 80%;
}
.right-card-text:hover {
    cursor: pointer;
}
.checkCard {
    border: 1px solid rgba(0, 191, 191, 1) !important;
}
.right-card {
    position: relative;
    margin: 10px 20px;
    padding: 0 15px;
    font-size: 12px;
    box-shadow: 2px 2px 2px #d7dadf;
    box-sizing: border-box;
    border: 1px solid rgba(242, 242, 242, 1);
    .cradUpdata {
        position: absolute;
        top: 0;
        right: 26px;
    }
    p {
        display: inline-block;
        height: 100%;
        padding: 12px;
    }
}
</style>