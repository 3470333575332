<template>
    <div>
        <el-container>
            <el-header>
                <i class="el-icon-s-tools" @click="toEditing" v-if="isAuth('addMap::header::editOrAdd')"></i>
            </el-header>
            <el-container>
                <el-aside v-loading="loadingTree" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgb(36 33 33 / 90%)">
                    <el-tree class="u-menu" node-key="id" accordion :default-expanded-keys="expandedkey" highlight-current ref="tree" @node-click="nodeClick" :data="treeData" :props="defaultProps"></el-tree>
                </el-aside>
                <el-main ref="canvasDiv" v-loading="loadingCanvas" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgb(36 33 33 / 90%)">
                    <canvas class="canvas" ref="canvas"></canvas>
                    <drawer ref="drawer" />
                    <el-menu v-show="showTool" @select="handleSelect" default-active="1-4-1" collapse-transition text-color="#fff" background-color="#333333" :collapse="true">
                        <el-menu-item index="2" v-if="isAuth('addMap::tool::zoom')">
                            <i class="el-icon-zoom-in"></i>
                        </el-menu-item>
                        <el-menu-item index="3" v-if="isAuth('addMap::tool::pan')">
                            <i class="el-icon-rank"></i>
                        </el-menu-item>

                    </el-menu>
                </el-main>
            </el-container>
        </el-container>

    </div>
</template>

<script>

import Drawer from './components/Drawer'
import { myMinxin } from '../mixin/index.js'
import {
    buildingTree,

} from '@/api'
export default {
    mixins: [myMinxin],
    title: "销控图新增",
    name: 'addedControlMap',
    components: {
        Drawer
    },
    watch: {
        'canvasObj.responseData': {
            handler (newVal, oldVal) {
                if (typeof newVal === 'number') {
                    let { id } = this.canvasObj.addressLists[newVal]
                    this.$refs.drawer.showRrawer(id)
                } else {
                    this.$refs.drawer.hideRrawer()
                }
            },
            deep: true,
        },
        'canvasObj.CANVAS_STATE': {
            handler (newVal, oldVal) {
                let isOk = this.canvasObj.getAllState(newVal)
                if (isOk) {
                    this.canvasObj.getClickItemId(true)
                }
            },
            deep: true
        },
    },
    methods: {
        getInit () {
            this.loadingTree = true
            buildingTree().then((res) => {
                this.loadingTree = false
                this.treeData = res.data
                this.$nextTick(() => {
                    let id = this.$route.params.setid
                    if (id) {
                        this.$set(this.expandedkey, 0, id)
                        this.$refs.tree.setCurrentKey(id)
                        let Objitem = this.$refs.tree.getCurrentNode()
                        let item = this.$refs.tree.getNode(Objitem)
                        this.nodeClick(Objitem, item)
                    } else {
                        let obj = res.data[0]
                        if (obj) {
                            let { id } = obj.children[0]
                            this.$set(this.expandedkey, 0, id)
                            this.$refs.tree.setCurrentKey(id)
                            let Objitem = this.$refs.tree.getCurrentNode()
                            let item = this.$refs.tree.getNode(Objitem)
                            this.nodeClick(Objitem, item)
                        } else {
                            this.$message.error('数据错误，请联系管理员')
                        }

                    }
                })
            })
        },
        nodeClick (Objitem, item) {
            this.canvasObj.addressLists = []
            this.rowFormTree = {}
            this.getCheckTheControlChart(Objitem, item)
        },
        toEditing () {
            let setid = this.$refs.tree.getCurrentKey()
            this.$router.push({ name: 'eliminationChart_controlDiagramEditing', params: { setid: setid ? setid : '' } });
        },
        //工具选择
        handleSelect (index) {
            if (index === '2') {
                this.canvasObj.enlargeCanvas()
                this.canvasObj.showcCickEvent()
            } else if (index === '3') {
                this.canvasObj.panCanvas()
                this.canvasObj.showcCickEvent()

            }
        },
    }
}
</script>

<style lang="scss" scoped>
.el-icon-s-tools {
    float: right;
    cursor: pointer;
}
.el-main {
    width: 80%;
    height: 750px;
    min-height: 700px;
    overflow: hidden;
    position: relative;
    padding: 0;
}
.el-header {
    height: 30px !important;
    margin: 10px 10px;
    border-radius: 0.521vw;
    background: #fff;
    padding: 5px 10px;
}
.canvas {
    background-color: #027db4;
}
.tool {
    border-bottom: 1px solid #fff;
}
.qy_box {
    position: absolute;
    left: 3%;
    top: 5%;
    font-size: 12px;
}
.qy_color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}
.qy_box_main {
    margin: 0 10px;
    display: inline-block;
}
/* .qy_blue {
    background-color: #003aff;
}
.qy_red {
    background-color: #ff0089;
} */
.el-aside {
    width: 240px !important;
    position: relative;
    margin: 0 10px;
}
.rightDate {
    border-width: 0px;
    position: absolute;
    right: 0px;
    top: 40px;
    width: 320px;
    height: 800px;
    background: inherit;
    background-color: rgba(0, 0, 0, 0.8);
    border: none;
    border-radius: 12px;
}
.u-menu {
    box-sizing: border-box;
    width: 95%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    height: 100%;
    padding: 10px 10px;
    background: #fff;
}
.u-menu::-webkit-scrollbar {
    width: 7px;
}
.u-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(7, 150, 126, 0.2);
}
.u-menu::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(21, 31, 15, 0.1);
}
.el-menu {
    width: 8.167vw;
    height: 8%;
    position: absolute;
    left: 2%;
    top: 4%;
    border-right: none;
}
.el-menu li {
    display: inline-block;
}
</style>
