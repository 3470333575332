import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

router.beforeEach((to, from, next) => {
  // next()
  // return
  const token = localStorage.getItem('token')
  if (token) { // 如果已经登录
    // console.log('----store',store.getters,'to',to)
    if (!store.getters.roles) {
      // console.log('路由-=====  角色 ---用户信息',store.getters.roles)
      // 判断当前用户是否已拉取完user_info信息
      store.dispatch('GetInfo').then(res => {
        sessionStorage.setItem('permissions', JSON.stringify(res.permissions))
        store.dispatch('GenerateRoutes').then(accessRoutes => {
          // console.log('最终生成的路由表====》',accessRoutes)
          next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
        })
      }).catch(err => {
        store.dispatch('FedLogOut').then(() => {
          // Message.error(err)
        })
      })
    } else {
      if (to.fullPath.includes('home')) {
        next()
        return
      }
      next()
    }
  } else { // 如果没有token
    console.log('to==>', to)
    if (to.name == '404') {
      next('/login')
    }
    if (to.fullPath.includes('login')) {
      next()
      return
    }
   
    next('/login')
  }
})

router.afterEach((to, from) => {
  store.commit('setLoading', true)
})
