<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @append="append"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @details="details">
            <template #test>
                <span>选择日期</span>
            </template>
        </ja-table>
        <!-- 详情 -->
        <Details ref="detailsRef" />
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import { getFeebill, getPaymentSlipRecord, getTelegraphTableList } from '@/api'
import Details from './Details.vue'
export default {
    title: '租赁单元计量报表(电)',
    mixins: [table],
    name: 'index',
    components: { Details },
    data () {
        return {
            conditionData: [
                {
                    input_type: 'input',
                    attribute: {
                        placeholder: '设备识别码',
                        size: 'small'
                    },
                    key: 'deviceCode'
                },
                { slotName: 'test' },
                {
                    input_type: 'date',
                    type: 'daterange',
                    value: 'deviceName'
                },
                {
                    input_type: 'input',
                    key: 'tenantName',
                    attribute: {
                        placeholder: '驻户名称',
                        size: 'small'
                    }
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '生成费单', icon: '', limit: 'energyModule::telegraphTable::append' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/iot/waterElectricity/export', rqMethod: 'post' }, limit: 'energyModule::telegraphTable::download' }
            ],
            columns: [
                { label: '设备名称', prop: 'deviceName' },
                { label: '设备识别码', prop: 'deviceCode' },
                { label: '楼栋', prop: 'buildingName' },
                { label: '楼层', prop: 'floorName' },
                { label: '租赁单元名称', prop: 'roomName' },
                { label: '租赁单元类型', prop: 'roomTypeValue' },
                { label: '驻户名称', prop: 'tenantName' },
                { label: '上次表底(度)', prop: 'lastReading', width: '120px' },
                { label: '本次抄表(度)', prop: 'thisReading', width: '120px' },
                { label: '本次抄表时间', prop: 'updateTime' },
                { label: '本期用量(度)', prop: 'currentReading', width: '120px' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '查看', method: 'details', limit: 'energyModule::telegraphTable::show' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 300,
            activeRow: null,
            showDetails: false,
            // 空间绑定该id
            spaceId: null,
            filterData: {
                deviceName: [this.$moment().subtract(7, 'days').format("YYYY-MM-DD HH:mm:ss"), this.$moment().format("YYYY-MM-DD HH:mm:ss")],
                startTime: this.$moment().subtract(7, 'days').format("YYYY-MM-DD HH:mm:ss"),
                endTime: this.$moment().format("YYYY-MM-DD HH:mm:ss")
            }
        }
    },
    watch: {
        'filterData.deviceName': {
            handler (newVla) {
                if (!newVla) return false
                if (newVla !== 'undefined' && newVla.length > 0) {
                    this.filterData.startTime = newVla[0]
                    this.filterData.endTime = newVla[1]
                }
            },
            deep: true
        }
    },
    methods: {
        getInit () {
            this.loading = true
            getTelegraphTableList(this.filterData).then((res) => {
                const dataList = res.data.data
                dataList.map(item => {
                    item.lastReading = Number(item.lastReading).toFixed(2)
                    item.thisReading = Number(item.thisReading).toFixed(2)
                    item.currentReading = Number(item.currentReading).toFixed(2)
                })
                this.loading = false
                this.tableData = dataList
                this.total = res.data.total
            })
        },
        // 详情
        details (row) {
            this.$refs.detailsRef.getDetailsData(row)
        },
        rqParmas () {
            return {
                endTime: this.filterData.endTime ? this.filterData.endTime : '',
                startTime: this.filterData.startTime ? this.filterData.startTime : '',
                deviceCode: this.filterData.deviceCode ? this.filterData.deviceCode : '',
                pageIndex: this.filterData.pageIndex,
                pageSize: this.filterData.pageSize
            }
        },
        append () {
            getPaymentSlipRecord(2).then(res => {
                const dateRange = res.data.dateRange
                const startDate = res.data.startDate
                const endDate = res.data.endDate
                const h = this.$createElement
                this.$confirm('提示', {
                    title: '提示',
                    message: h('div', [h('p', '上期新开账单区间【' + dateRange + '】'), h('p', '本期新开账单区间【' + startDate + '~' + endDate + '】')]),
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    getFeebill({ type: 2, startDate: startDate, endDate: endDate }).then(res => {
                        this.$message.success(res.msg)
                        this.getInit()
                    })
                }).catch(() => {
                    this.$message({ type: 'info', message: '已取消' })
                })
            })
        }
    }
}
</script>
