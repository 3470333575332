<template>
  <!--  普通的表单 -->
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="showEditAll"
    width="50%"
    :title="isAdd ? '新增' : '编辑'"
  >
    <ja-form
      :rules="rules"
      ref="jaForm"
      :label-width="labelWidth"
      v-model="rowForm"
      :formArray="rowFormData"
      :loadingForm="loadingForm"
      @reset="showEditAll = false"
      @save="saveForm"
      v-if="showEditAll"
    >
      <template #msg>
        <div style="marginright: 80px">
          <editor-mce
            :config="Config"
            :value.sync="rowForm.msgContent"
            :Id="Date.now()"
            ref="msg"
            :url="$uploadUrl + '/oss/upload'"
          ></editor-mce>
        </div>
      </template>
      <template #declaration>
        <ul class="declaration" v-if="declarationList && declarationList.length > 0">
          <li v-for="(item, index) in declarationList" :key="index">
            @{{ item }}
          </li>
        </ul>
      </template>
    </ja-form>
  </el-dialog>
</template>
<script>
import editorMce from '@/components/editorMce'
import { addMsgTemplate, editMsgTemplate, rqMsgGroup } from '@/api'

export default {
  inject: ['labelWidth', 'getInit'],
  components: {
    editorMce
  },
  props: {
    declarationList: Array
  },
  data() {
    return {
      Config: { height: 300 },
      rowFormData: [
        { input_type: 'input', label: '模板名称', key: 'templateName' },
        {
          input_type: 'select',
          label: '消息类型',
          key: 'msgType',
          options: []
        },
        {
          input_type: 'checkbox',
          label: '接收多端(多选)',
          key: 'receivingType',
          options: []
        },
        {
          input_type: 'select',
          label: '通知组(多选)',
          key: 'groupIds',
          options: [],
          multiple: true,
          collapseTags: true
        },
        {
          input_type: 'switch',
          label: '状态',
          key: 'status',
          col: 11,
          activeValue: 0,
          inactiveValue: 1,
          word0: '当前状态：开',
          word1: '当前状态：关',
          rightBox: { input_type: 'text', text: '' },
          styleWidth: '35px'
        },
        {
          input_type: 'customize',
          customize: 'declaration',
          label: '变量说明',
          key: 'declaration',
          col: 24,
          list: []
        },

        {
          input_type: 'customize',
          customize: 'msg',
          label: '消息内容',
          key: 'msgContent',
          col: 24
        },
        // { input_type: 'input', type: 'textarea', label: '模板内容', key: 'msgContent', col: 22, minRows: 4, maxlength: 2000 },
        {
          input_type: 'input',
          label: '备注',
          key: 'remark',
          type: 'textarea',
          col: 22,
          rows: 1,
          maxlength: 100
        }
      ],
      rowForm: {},
      rules: {
        templateName: [
          { required: true, message: '请输入模板名称', trigger: 'blur' }
        ],
        receivingType: [
          { required: true, message: '请勾选接收方式', trigger: 'change' }
        ],
        msgType: [
          { required: true, message: '请选择消息类型', trigger: 'change' }
        ],
        groupIds: [
          { required: true, message: '请选择通知组', trigger: 'change' }
        ],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        // eventId: [{ required: true, message: '请输入关联事件号', trigger: 'blur' }],
        msgContent: [
          { required: true, message: '请输入消息内容', trigger: 'change' }
        ]
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {}
    }
  },
  watch: {
    'rowForm.msgContent': {
      handler: function () {
        if (this.$refs.jaForm) {
          this.$refs.jaForm.$refs.formbox.validateField('msgContent')
        }
      }
    },
    'rowForm.status': {
      handler: function (val) {
        const item = this.rowFormData[this.indexObj.status]
        if (val == 0) {
          this.$set(item.rightBox, 'text', item.word0)
        } else {
          this.$set(item.rightBox, 'text', item.word1)
        }
      }
    },
    'rowForm.msgType': {
      handler: function (val) {
        this.declarationList = []
        console.log("val",val,this.rowFormData[this.indexObj.msgType].options.length,this.rowFormData[this.indexObj.msgType].options);
        if (val > this.rowFormData[this.indexObj.msgType].options.length) {
          this.rowForm.groupIds = []
          this.rowFormData[this.indexObj.groupIds].hide = true
        }else if(val == 26){
          this.rowFormData[this.indexObj.groupIds].hide = true
        } else {
          this.rowFormData[this.indexObj.groupIds].hide = false
        }
        // console.log(this.indexObj.msgType,this.rowFormData,this.rowFormData[this.indexObj.msgType],val,this.rowFormData[this.indexObj.msgType].options[val]);
        const optonsArr = this.rowFormData[this.indexObj.msgType].options.filter(({value})=>value===val)
        // console.log("optons",optons);
        let option = null;
        if(optonsArr && optonsArr.length){
          option = optonsArr[0]?.remark .replace(/(\\r|\\n)/gi, '').split('@').slice(1)
        }
        // option = this.rowFormData[this.indexObj.msgType].options[val]?.remark .replace(/(\\r|\\n)/gi, '') .split('@') .slice(1)
        // console.log(222,this.declarationList, this.declarationList.length === 0)
        if(option){
          this.declarationList = option
        }
        if (this.declarationList && this.declarationList.length === 0) {
          this.declarationList = ['无']
        }
      }
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData })
    this.util.rqOptionsList({
      who: [this.rowFormData[this.indexObj.receivingType]],
      rqUrl: '/sys/dictData/msg_receiving_type',
      resRule: 'data'
    })
    this.util.rqOptionsList({
      who: [this.rowFormData[this.indexObj.groupIds]],
      httpFun: rqMsgGroup,
      resRule: 'data',
      valueName: 'id',
      labelName: 'groupName'
    })
  },
  methods: {
    append() {
      this.isAdd = true
      this.rowForm = { msgContent: '', receivingType: [], groupIds: [] }
      this.showEditAll = true
      this.declarationList = '无'
      this.rowFormData[this.indexObj.msgType].disabled = false
    },
    rowEdit(row) {
      console.log('row---', row)
      this.isAdd = false
      this.titleText = '编辑'
      this.rowFormData[this.indexObj.msgType].disabled = true
      this.showEditAll = true
      this.rowForm = {
        ...row,
        receivingType: row.receivingType.split(','),
        groupIds: row.groupIds.split(',').map((v) => Number(v))
      }
    },
    saveForm() {
      this.loadingForm = true
      let rqMethod = this.isAdd ? addMsgTemplate : editMsgTemplate
      const rqObj = {
        ...this.rowForm,
        groupIds: this.rowForm.groupIds.join(','),
        receivingType: this.rowForm.receivingType.join(',')
      }
      rqMethod(rqObj)
        .then((res) => {
          this.loadingForm = false
          this.rowForm = {}
          this.$message.success('提交成功')
          this.showEditAll = false
          this.getInit()
        })
        .catch((error) => {
          this.loadingForm = false
        })
    },
    // 富文本的校验
    editorCheck(rule, value, callback) {
      console.log('校验---》', rule, value)
      if (rule.required) {
        if (
          value === undefined ||
          value === '' ||
          value === null ||
          (!value && value != 0)
        ) {
          // 如果为空
          return callback(new Error('请完善消息内容'))
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.declaration {
  display: flex;
  align-items: center;
  > li {
    margin-right: 30px;
  }
}
</style>