<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="show"
                   width="55%"
                   title="设置保证金">
            <ja-form :rules="rules"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     @reset="reset"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { getUsageRecordList } from '@/api'
export default {
    inject: ['getInit'],
    props: {
        allDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rowForm: { bzjDTOS: [] },
            rules: {},

            rowFormData: [
                {
                    input_type: 'table', col: '24', noSearch: true, showPage: false, key: 'bzjDTOS', isRequired: true, border: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', disabled: true },
                        { label: '收费项目名称', prop: 'serviceName', disabled: true },
                        { label: '收费项目性质', prop: 'natureCharges', disabled: true },
                        { label: '收费标准名称', prop: 'feesName', width: 180, disabled: true },
                        { label: '收费标准', prop: 'fees', disabled: true },
                        { label: '金额', prop: 'unitPrice', disabled: true }
                    ]
                }
            ],
            tableData: [],
            show: false,
            evidenceCode: null
        }
    },
    computed: {
        initRent: function () {
            let data = this.rowForm
            if (data.billingArea >= 0 && data.unitPrice >= 0) {
                return data.unitPrice * data.billingArea
            } else {
                return 0
            }

        }
    },
    mounted () {
    },
    methods: {
        async init(row) {
            this.evidenceCode = row.contractCode
            let res = await getUsageRecordList({ evidenceCode: this.evidenceCode, serviceType: '保证金类', pageIndex: 1, pageSize: 100 })
            res.data.data.map(item => {
                let arr = item.fees.split('：')
                if (arr.length == 2) {
                    item.natureCharges = '临时性'
                    item.feesName = `${item.serviceName}收费标准`
                    item.fees = arr[0]
                    item.unitPrice = arr[1] || 0.00
                }
            })
            this.rowForm.bzjDTOS = res.data.data
            this.show = true
        },
        reset() {
            this.show = false
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.input_text {
    border: 0 !important;
    padding: 0 !important;
}
</style>
