<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="40%"
                   :title="title"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="{}"
                     v-model="roomDetails"
                     :formArray="detailOptions"
                     :allDisabled="allDisabled"
                     :btnBox="btnBox"
                     @reset="show=false"
                     @save="save"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { editOrderList } from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'input', label: '工单号', key: 'workOrderCode', labelWidth: '120px', disabled: true },
                { input_type: 'input', label: '工单类型', key: 'workOrderTypeValue', labelWidth: '120px', disabled: true },
                { input_type: 'input', label: '区域', key: 'regionValue', labelWidth: '120px', disabled: true },
                { input_type: 'input', label: '紧急程度', key: 'workOrderUrgencyValue', labelWidth: '120px', disabled: true },
                { input_type: 'input', label: '提单人', key: 'submitEmployeeName', labelWidth: '120px', disabled: true },
                { input_type: 'input', label: '提单人联系方式', key: 'submitEmployeeMobile', labelWidth: '120px', disabled: true },
                { input_type: 'input', label: '问题描述', key: 'workOrderDescription', labelWidth: '120px', col: 24, type: 'textarea', },
                { input_type: 'input', label: '备注要求', key: 'remark', labelWidth: '120px', col: 24, type: 'textarea', },
                { input_type: 'input', label: '提单时间', key: 'orderSubmitTime', labelWidth: '120px', disabled: true },
                { input_type: 'input', label: '工单状态', key: 'statusValue', labelWidth: '120px', disabled: true },
            ],
            //表头数据
            roomDetails: {},
            show: false,
            btnBox: [],
            allDisabled: false,
            title: ''
        }
    },
    methods: {
        showDetails(row) {
            this.allDisabled = true
            this.roomDetails = row
            this.show = true
            this.title = '查看'
        },
        showComplete(row) {
            this.allDisabled = false
            this.roomDetails = { ...row }
            this.show = true
            this.title = '确认完成'
            this.btnBox = [
                {
                    label: '确认完成',
                    method: 'save',
                    type: 'primary',
                    monitor: true,
                    col: 18,
                    isCenter: true
                },
                { label: '取消', method: 'reset', col: 18, isCenter: true }
            ]
        },
        save() {
            this.$confirm('是否确定完成该工单？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                editOrderList(this.roomDetails.id).then(() => {
                    this.$message({
                        type: 'success',
                        message: '结单成功!'
                    });
                    this.getInit()
                    this.show = false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    }
}
</script>
