<template>
  <!-- 管理员列表 -->
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      :showSelect="true"
      @handleSelectionChange="handleSelectionChange"
      @reset="reset"
      :showIndex=false
    >
      <template v-slot:right>
        <el-button @click="selectUser" type="primary" size="small" v-if="isAuth('manager::list::add')" plain>新增</el-button>
        <el-button type="primary" @click="delAdminList" size="small" v-if="isAuth('manager::list::batchdelete')" plain>批量删除</el-button>
      </template>
      
    </ja-table>
    <!-- 新增/编辑 -->
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="allDisabled = false"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @inclick="inclick"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import {
    getAdminList,
    delAdminList,
} from '@/api'
import table from '@/views/mixins/table'
import {
    managerListQuery,
    managerListColumn,
} from './managerColumn'

export default {
    title: '管理员列表',
    mixins: [table],
    name: 'index',
    data () {
        return {
            typeCode: '',
            conditionData: managerListQuery,
            columns: managerListColumn,
            tableData: [],
            loading: false,
            total: 30,
            showUser: false, // 显示新增/编辑弹窗
            assignAdminList: []
        }
    },
    methods: {
        getInit () {
            this.loading = true
            // 获取楼层列表
            getAdminList({
                pageIndex: this.filterData.pageIndex,
                pageSize: this.filterData.pageSize,
                adminName: this.filterData.adminName,
            }).then(({ data }) => {
                this.tableData = data.data
                this.total = data.total
                this.loading = false
            })
        },
        selectUser () {
          this.$router.push({ path: '/home/basicInformation/assignAdmin' })
        },
        closeUser () {
            this.showUser = false
            this.getInit()
        },
        // 删除某一项
        rowDelete (row) {
            this.$confirm('是否确认删除此园区管理员？').then((res) => {
                delAdminList([row.id]).then((res) => {
                    this.$message.success('已删除')
                    this.getInit()
                })
            })
        },
        // 多选
        handleSelectionChange (val) {
            console.log("val---", val)
            this.assignAdminList = val
        },
        // 删除全部
        delAdminList () {
            if (this.assignAdminList.length == 0) {
                this.$message.error('至少要选择一个用户哦！');
                return false
            }
            let assignAdminList = this.assignAdminList.map(item => {
                return item.id
            })
            this.$confirm('是否确认删除已选择的园区管理员？').then((res) => {
                delAdminList(assignAdminList).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.getInit()
                    }
                })
            })
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
