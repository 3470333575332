<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title='title'>
        <ja-form :rules="rules" :allDisabled="allDisabled" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
    </el-dialog>
</template>
<script>
import { addPublicWaterElectricity} from '@/api'
export default {
    inject: ['getInit'],
    data () {
        return {
            rowFormData: [
                { input_type: 'select', label: '类型', key: 'undertakeType', options: [],},
                { input_type: 'date', label: ' 日期', type: 'month', key: 'date',valueFormat: 'yyyy-MM'},
                { input_type: 'input', label: '数值', key: 'number', col: 11},
            ],
            rowForm: {},
            rules: {
                undertakeType: [{ required: true, message: '请选择类型', trigger: 'change' }],
                date: [{ required: true, message: '请选择日期', trigger: 'change' }],
                number: [{ required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2}) }],
            },
            loadingForm: false,
            labelWidth: `${this.util.setFontSize(140)}px`,
            showEditAll: false, // 显示新增/编辑弹窗,
            copyRowForm: [],
            allDisabled: false,
            indexObj:{},
            title:'新增'
        }
    },
    mounted () {
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
        // 类型下拉列表
        this.util.rqOptionsList({ who: [this.rowFormData[this.indexObj.undertakeType],], rqUrl: "/sys/dictData/undertake_type", resRule: 'data', })
    },
    watch: {
        
    },
    methods: {
        append (row,type) {
            this.rowForm = {
                ...row
            }
            this.showEditAll = true
            this.allDisabled = false
            if(type=='编辑'){
                this.title='编辑'
                this.rowFormData[this.indexObj.undertakeType].disabled=true;
                this.rowFormData[this.indexObj.date].disabled=true;
            }else{
                this.rowFormData[this.indexObj.undertakeType].disabled=false;
                this.rowFormData[this.indexObj.date].disabled=false;
            }
        },
        saveForm () {
            this.loadingForm = true
            addPublicWaterElectricity(this.rowForm)
                .then((res) => {
                    this.loadingForm = false
                    this.$message.success('提交成功')
                    this.showEditAll = false
                    this.getInit()
                })
                .catch((error) => {
                    this.loadingForm = false
                })
        }
    }
}
</script>
