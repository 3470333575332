<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @lookRoom="lookRoom"
                  @endManually="endManually">
            <template #download>
                <el-dropdown @command="handleCommand"
                             class="download">
                    <el-button type="primary"
                               size="mini">
                        导出<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">按日汇总导出</el-dropdown-item>
                        <el-dropdown-item command="2">按月汇总导出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </template>
        </ja-table>
        <show-passed ref="showPassed"></show-passed>
        <end-manually ref="endManuall"></end-manually>

    </div>
</template>

<script>
import { getApplicationList } from '@/api'
import table from '@/views/mixins/table'
import showPassed from './showPassed.vue'
import endManually from './endManually.vue'
import { outPutCommonExcell } from '@/api'
export default {
    title: '已通过',
    mixins: [table],
    name: 'toRentList',
    components: {
        showPassed,
        endManually
    },
    data() {
        return {
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'input', key: 'storeName', attribute: { placeholder: '店铺名称', size: 'small' } },
                { input_type: 'input', key: 'carNumber', attribute: { placeholder: '车牌号码', size: 'small' } },
                { input_type: 'input', key: 'applicant', attribute: { placeholder: '申请人', size: 'small' } },
                { input_type: 'input', key: 'contactNumber', attribute: { placeholder: '联系方式', size: 'small' } },
                { input_type: 'select', key: 'serviceType', attribute: { placeholder: '全部服务类型', size: 'small' }, options: [] },
                { input_type: 'text', text: '服务起止时间', styleWidth: '100px' },
                { input_type: 'date', type: 'daterange', value: 'date', valueFormat: 'yyyy-MM-dd' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { slotName: 'download', styleWidth: '5vw' },
                // { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/tenant/apply/export', rqMethod: 'post' }, limit: 'Resident::passedModule::download' }
            ],
            columns: [
                // 表头数据
                { label: '驻户名称', prop: 'tenantName' },
                { label: '店铺名称', prop: 'storeName' },
                { label: '车牌号码', prop: 'carNumber' },
                {
                    label: '服务类型',
                    prop: 'serviceTypeName'
                },
                {
                    label: '申请人',
                    prop: 'applicant'
                },
                {
                    label: '联系方式',
                    prop: 'contactNumber'
                },
                {
                    label: '服务开始时间',
                    prop: 'serviceStartTime',
                    width: '120px'
                },
                {
                    label: '服务结束时间',
                    prop: 'serviceEndTime',
                    width: '120px'
                },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        {
                            text: '查看',
                            method: 'lookRoom',
                            limit: 'Resident::passedModule::show'
                        },
                        {
                            text: '手动结束',
                            method: 'endManually',
                            limit: 'Resident::passedModule::endManually'
                        }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,

        }
    },
    mounted() {
        let index = this.conditionData.findIndex((e) => e.key === 'serviceType')
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'service_card' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        getInit() {
            let obj = {
                ...this.filterData,
                status: 2,
                serviceStartTime: this.filterData.date ? this.filterData.date[0] : '',
                serviceEndTime: this.filterData.date ? this.filterData.date[1] : ''
            }
            this.loading = true
            // 获取楼层信息列表
            getApplicationList(obj)
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
                .catch((err) => {
                    this.loading = false
                })
        },
        lookRoom(row) {
            this.$refs.showPassed.init(row)
        },
        endManually(row) {
            this.$refs.endManuall.init(row)
        },
        editDataFormat(row, btn) {
            if (row.serviceStatus == 0 && btn.text == '手动结束' || row.serviceStatus == 1 && btn.text == '查看') {
                return true
            } else {
                return false
            }
        },
        handleCommand(item) {
            let data = {
                pageIndex: 1,
                pageSize: 9999,
                status: 2,
                rule: item
            }
            outPutCommonExcell({
                url: '/tenant/apply/export',
                data: data,
                method: 'post'
            }).then((res) => {
                this.util.dealExcelRespose(res)
            })
        }
    }
}
</script>

<style scoped>
.download {
    width: 5vw;
}
</style>

