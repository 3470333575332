<template>
  <!-- 表格 -->
  <div class="content">
    <title-box title="租期(X)分析" />
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'

export default {
  components: {
    JaInitChart, TitleBox
  },
  data() {
    return {
      options: null,
      num: '100'
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    setOptions() {
      const xData = ['0年>x≤1年', '1年>x≤3年', '3年>x≤5', '5年>x']
      const activeYear = this.activeYear
      this.options = {
        grid: {
          top: '18%',
          bottom: '30%',
          right: '10%',
          left: '15%'
        },
        tooltip: {
          trigger: 'axis',
          formatter:'{b}：{c} 户'
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            offset: this.util.setFontSize(8, 1920),
            axisLabel: {
              fontSize: this.util.setFontSize(15, 1920),
              interval: 0
            },
            axisTick: {
              show: false
            },
            axisPointer: {
              type: 'shadow'
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '（户）',
            axisTick: {
              show: false,
            },
            axisPointer: {
              label: {
                show: true,
              }
            },
            splitLine: {
              show: false
            },
            nameGap: 20,
            nameTextStyle: {
              fontSize: this.util.setFontSize(16, 1920),
            },
            min: 0,
            max: 100,
            interval: 10,
            axisLine: {
              show: true,
            },
            axisLabel: {
              // formatter: '{value} ',
              fontSize: this.util.setFontSize(14, 1920),
            },
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: this.util.setFontSize(50, 1920),
            activeYear: this.activeYear,
            data: [
              10, 30, 90, 19,
            ],
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 0, color: 'rgba(167, 231, 255, 1)' },
                { offset: 1, color: 'rgba(0, 137, 255, 1)' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: 'rgba(0, 137, 255, 1)'
              }
            },
            label: {
              show: true,
              position: 'top',
              fontSize: this.util.setFontSize(16),
              formatter: function (val) {
                let sum = 10 + 30 + 90 + 19;
                let res = (val.value * 100 / sum).toFixed(2) + '%'
                return res
              }
            },
          }
        ]
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
}
.echart {
  height: 100%;
  width: 100%;
}
</style>