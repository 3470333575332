<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="50%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     @reset="show = false"
                     @save="saveForm"
                     @getPhone="getPhone"
                     v-if="show"
                     v-loading="addLoading">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { addFollow, getProject } from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            value: null,
            rowFormData: [
                { input_type: 'select', key: 'projectName', label: '项目名称', labelWidth: '130px', col: 11, maxlength: 30, options: [] },
                { input_type: 'input', key: 'leasePurposeName', label: '租赁用途', labelWidth: '130px', col: 11, disabled: true },
                { input_type: 'input', key: 'contacts', label: '联系人', labelWidth: '130px', col: 11, disabled: true },
                { input_type: 'input', key: 'customerContact', label: '客户联系方式', labelWidth: '130px', col: 11, disabled: true, placeholder: '-' },
                { input_type: 'input', key: 'clientCompany', label: '客户公司', labelWidth: '130px', col: 11, disabled: true, placeholder: '-' },
                { input_type: 'input', key: 'demandArea', label: '面积需求（㎡）', labelWidth: '130px', col: 11, disabled: true },
                { input_type: 'input', key: 'followUpPeopleDeptName', styleWidth: '6vw', label: '跟进人', labelWidth: '130px', col: 8, placeholder: '-', disabled: true },
                { input_type: 'input', key: 'followUpPeopleName', styleWidth: '6vw', col: 3, placeholder: '人员', disabled: true },
                { input_type: 'input', key: 'followUpPeopleContact', label: '跟进人联系方式', labelWidth: '130px', col: 11, disabled: true, placeholder: '-' },
                { input_type: 'select', key: 'progressStatus', label: '进度状态', labelWidth: '130px', col: 11, options: [] },
                { input_type: 'input', type: 'textarea', key: 'remark', label: '进度描述', labelWidth: '130px', col: 24 },
            ],
            rules: {
                projectName: [
                    { required: true, message: '请选择项目名称', trigger: 'blur' }
                ],
                progressStatus: [
                    { required: true, message: '请选择进度状态', trigger: 'blur' }
                ],
            },
            rowForm: {},
            title: '新增项目跟进记录',
            show: false,
            isAdd: false,
            addLoading: false,
            projectList: []
        }
    },
    watch: {
        'rowForm.projectName': {
            handler: function (newVla) {
                if (newVla) {
                    let arr = this.projectList.filter(item => {
                        if (item.label == newVla) return item
                    })
                    console.log(arr);
                    console.log(this.rowForm);
                    this.rowForm = { projectName: arr[0].label, ...arr[0] }
                }
            }
        }
    },
    methods: {
        async init() {
            this.show = true
            let index1 = this.rowFormData.findIndex(e => e.key === 'progressStatus')
            this.util.rqOptionsList({
                who: this.rowFormData[index1],
                rqUrl: '/sys/dictData/query',
                rqMethod: 'post',
                rqData: { dictType: 'progress_status' },
                labelName: 'dictLabel',
                valueName: 'dictValue'
            })
            let index = this.rowFormData.findIndex(e => e.key === 'projectName')
            await this.util.rqOptionsList({
                who: this.rowFormData[index],
                httpFun: getProject,
                rqMethod: 'post',
                rqData: { pageIndex: 1, pageSize: 1000 },
                labelName: 'projectName',
                valueName: 'projectName',
                otherKeys: [
                    { keyName: 'leasePurposeName', keyValue: 'leasePurposeName' },
                    { keyName: 'contacts', keyValue: 'contacts' },
                    { keyName: 'customerContact', keyValue: 'customerContact' },
                    { keyName: 'clientCompany', keyValue: 'clientCompany' },
                    { keyName: 'demandArea', keyValue: 'demandArea' },
                    { keyName: 'followUpPeopleDeptId', keyValue: 'followUpPeopleDeptId' },
                    { keyName: 'followUpPeopleDeptName', keyValue: 'followUpPeopleDeptName' },
                    { keyName: 'followUpPeopleId', keyValue: 'followUpPeopleId' },
                    { keyName: 'followUpPeopleName', keyValue: 'followUpPeopleName' },
                    { keyName: 'followUpPeopleContact', keyValue: 'followUpPeopleContact' },
                ]
            }).then(res => {
                console.log(res);
                this.projectList = res
            })
            this.rowForm = {}
            this.isAdd = true
        },
        saveForm() {
            addFollow({ ...this.rowForm }).then(() => {
                this.show = false
                this.getInit()
            })
        },

    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>