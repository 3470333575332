<template>
  <!--  普通的表单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="60%" :title="isAdd?'新增':'编辑'">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="preSaveForm">
      <template #msg>
        <div style="marginRight:80px;">
          <editor-mce :config="Config" :value.sync="rowForm.content" :Id="Date.now()" ref="msg" :url="$uploadUrl+'/oss/upload'"></editor-mce>
        </div>
      </template>
      <template #uploadFile="row">
        <upload-file ref="Upload" :autoUpload="true" :limit="1" accept='.jpeg,.png,.jpg,' :keyItem="row.data" :initFileList="initFileList" :fileSize="2" v-if="showEditAll" />
      </template>
    </ja-form>
  </el-dialog>
</template>
<script>
import editorMce from '@/components/editorMce'
import { addNews, editNews } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  components: {
    editorMce
  },
  data() {
    return {
      Config: { height: 300 },
      rowFormData: [
        { input_type: 'input', label: '标题', key: 'title', maxlength: 20, col: 22 },
        { input_type: 'input', label: '作者', key: 'author', col: 11 },
        { input_type: 'select', label: '咨询类型', key: 'tag', col: 11, options: [], },
        { input_type: 'switch', label: '是否置顶', key: 'top', col: 11, activeValue: 1, inactiveValue: 0 },
        { input_type: 'switch', label: '是否发布', key: 'status', col: 11, activeValue: 1, inactiveValue: 0 },
        { input_type: 'customize', customize: 'msg', label: '正文', key: 'content', col: 24, },
        { input_type: 'customize', customize: 'uploadFile', label: '上传封面', key: 'cover', col: 24, isRequired: true },
      ],
      rowForm: {},
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        tag: [{ required: true, message: '请选择标签', trigger: 'change' }],
        importanceDegree: [{ required: true, message: '请选择咨询类型', trigger: 'change' }],
        author: [{ required: true, message: '请完善作者名称', trigger: 'blur' }],
        content: [{ required: true, message: '请完善正文', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {},
      initFileList: []
    }
  },
  watch: {
    'rowForm.content': {
      handler: function () {
        if (this.$refs.jaForm) {
          this.$refs.jaForm.$refs.formbox.validateField('content')
        }
      }
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },
  methods: {
    append() {
      this.isAdd = true
      this.rowForm = { content: '', top: 0, status: 0 }
      this.initFileList = []
      this.showEditAll = true
      this.$nextTick(() => {
        this.$refs.jaForm.$refs.formbox.clearValidate()
      })
    },
    rowEdit(row) {
      this.isAdd = false
      this.titleText = '编辑'
      let imgArr = row.cover ? [row.cover] : ''
      if (imgArr.length > 0) {
        this.initFileList = this.util.backImgVideoObj(imgArr)
      } else {
        this.initFileList = []
      }
      this.rowForm = { ...row }
      this.showEditAll = true
      this.$nextTick(() => {
        this.$refs.jaForm.$refs.formbox.clearValidate()
      })
    },
    preSaveForm() {
      let fileList = this.$refs.Upload.fileList
      if (fileList.length == 0) {
        this.$message.warning('请完善封面')
      } else {
        let isOk = fileList[0].status == 'success'
        if (isOk) {
          this.$set(this.rowForm, 'cover', fileList[0].response.data)
          this.saveForm()
        } else {
          this.$message.warning("存在文件尚未完成上传")
          return
        }
      }
    },
    saveForm() {
      this.loadingForm = true
      let rqMethod = this.isAdd ? addNews : editNews
      rqMethod(this.rowForm).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },
  }
}
</script>