<template>
    <div>
        <!-- 新增/编辑 -->
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="show"
                   width="50%"
                   :title="titleText">
            <ja-form ref="jaForm"
                     :rules="rules"
                     :label-width="labelWidth"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show"
                     :allDisabled="allDisabled">
                <template v-slot:
                          uploadChart>
                    <el-upload ref="upload"
                               class="avatar-uploader"
                               :action="$uploadUrl + '/oss/upload'"
                               :limit="1"
                               :disabled="allDisabled"
                               :show-file-list="false"
                               :on-success="handleAvatarSuccess"
                               :before-upload="beforeAvatarUpload"
                               :on-error="imgErrorMethod"
                               accept=".png,.jpg"
                               :file-list="fileList">
                        <img v-if="salesControlUrl"
                             :src="salesControlUrl"
                             class="avatar" />
                        <i v-else
                           class="el-icon-plus avatar-uploader-icon"></i>
                        <div slot="tip"
                             v-if="!allDisabled"
                             class="el-upload__tip">
                            png、jpg图片格式，大小限制3mb
                        </div>
                    </el-upload>
                    <span class="deleteIcon"
                          v-show="salesControlUrl"
                          v-if="!allDisabled"
                          @click="handleRemove()">
                        <i class="el-icon-circle-close"></i>
                    </span>
                </template>
                <template v-slot:materialNumber>
                    <el-autocomplete
                        style="width:100%"
                        v-model="rowForm.materialNumber"
                        value-key="materialNumber"
                        :fetch-suggestions="querySearchAsync"
                        placeholder="请输入内容"
                        @select="selectMaterialNumber"
                        @change="changeMaterialNumber"
                        ></el-autocomplete>
                </template>
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import {addWarehousing,getMaterialList } from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            titleText: '',
            rowForm: {},
            isAdd: true,
            show: false,
            salesControlUrl: '',
            allDisabled: false,
            rules: {
                materialNumber: [{ required: true, trigger: 'change', validator: this.util.formRules.checkEN() }],
                materialType: [{ required: true, message: '请选择物资类型', trigger: 'blur' }],
                materialName: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText() }],
                amount: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkInteger() }],
                // price: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkNumber() }],

            },
            rowFormData: [
                {input_type: 'customize',customize:'materialNumber',label: '物资编号',key: 'materialNumber',labelWidth: '100px',isRequired:true},
                { input_type: 'select', label: '物资类型', key: 'materialType', opstion: [], labelWidth: '100px' },
                { input_type: 'input', label: '物资名称', key: 'materialName', maxlength: 30, labelWidth: '100px' },
                { input_type: 'input', label: '数量（个）', key: 'amount', maxlength: 10, labelWidth: '100px' },
                { input_type: 'input', label: '价格（元）', key: 'price', maxlength: 30, labelWidth: '100px' },
                { label: '图片', key: 'imgUrl', slotName: 'uploadChart', col: 24,labelWidth: '100px' }
            ],
            fileList:[],
            labelWidth:`${this.util.setFontSize(140)}px`
        }
    },
    created () {
        
    },
    methods: {
        // 图片上传成功
        handleAvatarSuccess(res) {
            this.$refs.upload.clearFiles()
            if (res.code !== 200) return this.$message.error('图片上传失败')
            this.$refs.jaForm.$refs.formbox.clearValidate('imgUrl')
            this.salesControlUrl = res.data
            this.$set(this.rowForm, 'imgUrl', res.data)
        },
        // 上传之前
        beforeAvatarUpload(file) {
            let name = file.name
            let size = file.size
            let pattern = /.(jpg|png)/
            if (!pattern.test(name)) {
                console.log(this.$refs.upload)
                // this.$refs.upload.abort()
                this.$message.error('只接受图片格式：jpg,png')
                return false
            }
            if (size / 1024 / 1024 > 3) {
                this.$refs.upload.abort()
                this.$message.error('图片导入失败，图片不能大于3M')
                return false
            }
        },
        // 图片上传失败
        imgErrorMethod() {
            this.$message.error('图片上传失败，请重试')
            this.$refs.imgUpload.abort()
            return false
        },
        handleRemove() {
            this.$refs.upload.clearFiles()
            this.salesControlUrl = null
            this.$set(this.rowForm, 'imgUrl', '')
        },
        async init(state, row) {
            let index1 = this.rowFormData.findIndex((e) => e.key === 'materialType')
            let index2 = this.rowFormData.findIndex((e) => e.key === 'materialName')
            this.rowFormData[index1].disabled ? delete this.rowFormData[index1].disabled : ''
            this.rowFormData[index2].disabled ? delete this.rowFormData[index2].disabled : ''
            this.$set(this.rowFormData[index1],'disabled',false)
            this.$set(this.rowFormData[index2],'disabled',false)

            await this.updateSelect()
            this.titleText = '新增物资'
            this.show = true
            this.rowForm = {}
            this.isAdd = true
            this.allDisabled = false
            this.salesControlUrl = null
            let index = this.rowFormData.findIndex((e) => e.key === 'amount')
            if (state === 1) {
                this.titleText = '编辑物资'
                this.isAdd = false
                this.rowForm = { ...row }
                this.rowFormData[index].hide=true;
                this.salesControlUrl = row.imgUrl
            } else if (state === 2) {
                this.titleText = '查看物资'
                this.rowFormData[index].hide=false
                this.allDisabled = true
                this.isAdd = false
                this.rowForm = { ...row }
                this.salesControlUrl = row.imgUrl
            }
        },
        saveForm() {
            if (this.isAdd) {
                addWarehousing(this.rowForm).then(() => {
                    this.getInit()
                    this.show = false
                    this.$message.success('新增成功')
                })
            } else {
                
            }
        },
        async updateSelect() {
            let index1 = this.rowFormData.findIndex((e) => e.key === 'materialType')
            await this.util.rqOptionsList({
                who: [this.rowFormData[index1]],
                rqData: { pageIndex: 1, pageSize: 10, dictType: 'material_type' },
                rqUrl: '/sys/dictData/query',
                rqMethod: 'post',
                labelName: 'dictLabel',
                valueName: 'dictValue',
            })
        },
        /**input框远程搜索方法 */
        querySearchAsync(queryString, cb) {
           getMaterialList({materialNumber:queryString,pageIndex:1,pageSize:100}).then(res=>{
            console.log("返回数据的------",res)
                cb(res.data.data)
           })
        },
        /**选中远程搜索下拉内容 */
        selectMaterialNumber(data){
            let index1 = this.rowFormData.findIndex((e) => e.key === 'materialType')
            let index2 = this.rowFormData.findIndex((e) => e.key === 'materialName')
            this.rowFormData[index1].disabled=true;
            this.rowFormData[index2].disabled=true;
            this.$set(this.rowForm,'materialName',data.materialName)
            this.$set(this.rowForm,'materialType',data.materialType)
        },
        /**改变input内容 */
        changeMaterialNumber(data){
            console.log("参数------",data,!data)
            if(!data){
                let index1 = this.rowFormData.findIndex((e) => e.key === 'materialType')
                let index2 = this.rowFormData.findIndex((e) => e.key === 'materialName')
                this.rowFormData[index1].disabled ? delete this.rowFormData[index1].disabled : ''
                this.rowFormData[index2].disabled ? delete this.rowFormData[index2].disabled : ''
                this.$set(this.rowFormData[index1],'disabled',false)
                this.$set(this.rowFormData[index2],'disabled',false)
            }
        }
    }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    border: 1px solid #d9d9d9;
    margin-left: 120px;
    margin-top: -60px;
}
.deleteIcon {
    position: relative;
    bottom: 230px;
    left: 300px;
}
.deleteIcon :hover {
    color: #e92416;
}
.el-upload__tip {
    margin-left: 100px;
}
.avatar {
    width: 178px;
    height: 150px;
    display: block;
    margin-left: 120px;
    margin-top: -60px;
}
</style>