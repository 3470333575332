<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="70%" :title="titleText">
        <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll" @selectPayServiceId="selectPayServiceId">
        </ja-form>
    </el-dialog>
</template>
<script>
import { rqRoomPayListItem, addRoomPay } from '@/api'
export default {
    inject: ['labelWidth', 'getInit',],
    data () {
        return {
            roomId: '',
            rowFormData: [
                {
                    input_type: 'table', label: '', key: 'tableList', col: 24, showPage: false, noSearch: true, table_MaxHeight: 300, border: true,
                    columns: [
                        { label: '仪表类型', prop: 'deviceCategoryLabel', width: 150 },
                        {
                            label: '收费项目名称', prop: 'payServiceId', column_type: 'select', aloneCont: 'device_action_id', width: 150, method: 'selectPayServiceId',

                        },
                        { label: '收费类别', prop: 'categoryLabel', },
                        { label: '所属账单', prop: 'billTypeName', },
                        { label: '计费日期', prop: 'billingDate', },
                        { label: '计算方法(未写再补)', prop: 'aa', },
                        { label: '单价', prop: 'unitPrice', },
                    ]
                },
            ],
            rowForm: {
                //初始数据
                tableList: [{
                    deviceCategoryLabel: '水表',
                    categoryLabel: '',
                    billTypeName: '',
                    unitPrice: '',
                    billingDate: '',
                    options: [],
                },
                {
                    deviceCategoryLabel: '电表',
                    categoryLabel: '',
                    billTypeName: '',
                    unitPrice: '',
                    billingDate: '',
                    options: []
                },
                {
                    deviceCategoryLabel: '风冷',
                    categoryLabel: '',
                    billTypeName: '',
                    unitPrice: '',
                    billingDate: '',
                    options: []
                },
                {
                    deviceCategoryLabel: '水冷',
                    categoryLabel: '',
                    billTypeName: '',
                    unitPrice: '',
                    billingDate: '',
                    options: []
                },
                ]
            },
            rules: {},
            showEditAll: false, // 显示新增/编辑弹窗,
            activeRowIndex: 0, // 当前点击了的表格具体行的索引值
            optionsItem: { deviceIdOptions: [] },
            titleText: '',
            loadingForm: false,
            // 用于接收收费项目名称的值
            selectPayServiceList: [],
        }
    },
    mounted () {
        //请求收费项目数据
        this.util.rqOptionsList({ rqUrl: "/pay/list", rqData: { deviceCategory: 124, pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'serviceName', valueName: 'id', isReturnAll: true }).then((res) => { this.setTableListOptions(res, 1) })
        this.util.rqOptionsList({ rqUrl: "/pay/list", rqData: { deviceCategory: 125, pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'serviceName', valueName: 'id', isReturnAll: true }).then((res) => { this.setTableListOptions(res, 1) })
        this.util.rqOptionsList({ rqUrl: "/pay/list", rqData: { deviceCategory: 186, pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'serviceName', valueName: 'id', isReturnAll: true }).then((res) => { this.setTableListOptions(res, 2) })
        this.util.rqOptionsList({ rqUrl: "/pay/list", rqData: { deviceCategory: 187, pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'serviceName', valueName: 'id', isReturnAll: true }).then((res) => { this.setTableListOptions(res, 3) })
    },
    methods: {
        setTableListOptions (res, count) {

            let obj = []
            res.forEach(e => {
                obj.push(
                    { device_action: e.label, action_id: e.value }
                )
            })
            this.rowForm.tableList[count].options = obj
            this.selectPayServiceList[count] = res
        },
        // 按钮点击进来
        rowEdit () {
            let { id, roomName } = this.$parent.activeRow
            this.roomId = id
            // 重置
            let obj = []
            this.rowForm.tableList.forEach((e, i) => {
                obj[i] = {
                    deviceCategoryLabel: ['水表', '电表', '风冷', '水冷'][i],
                    categoryLabel: '',
                    billTypeName: '',
                    unitPrice: '',
                    billingDate: '',
                    options: e.options,
                }

            })
            this.$set(this.rowForm, 'tableList', obj)
            this.titleText = `收费标准配置（房间名称：${roomName}）`
            this.showEditAll = true
            //请求数据
            rqRoomPayListItem(id).then(res => {
                console.log(res);
                //判断数据重新构造
                if (res.data.length > 0) {
                    res.data.forEach((e, i) => {
                        this.$set(this.rowForm.tableList[i], 'payServiceId', e.serviceName)
                        this.$set(this.rowForm.tableList[i], 'categoryLabel', e.frequencyLabel)
                        this.$set(this.rowForm.tableList[i], 'billingDate', e.billingDate)
                        this.$set(this.rowForm.tableList[i], 'billingDate', e.billingDate)
                    })
                    this.getInit()
                }
            })
        },
        // 检查表格
        checkTable () {
            const tableList = this.rowForm.tableList
            for (let i = 0; i < tableList.length; i++) {
                const item = tableList[i]
                let arr = ['deviceCategory', 'payServiceId', 'deviceId']
                let notExistItem = arr.find(v => !item[v])
                if (notExistItem) {
                    let itemData = this.rowFormData[1].columns.find(item => item.prop == notExistItem)
                    this.$message.warning(`请完善表格 第${(i + 1)}行 的“${itemData.label}”`)
                    i = tableList.length
                    return false
                }
            }
            return true
        },
        saveForm () {
            let payInfo = [...this.rowForm.tableList]
            this.loadingForm = true
            // console.log('我保存前的数据--------------',payInfo);
            addRoomPay({ payInfo, roomId: this.roomId }).then(res => {
                this.getInit()
                this.$message.success('提交成功')
                this.showEditAll = false
                this.loadingForm = false
            }).catch(err => {
                this.loadingForm = false
            })
        },
        // 收费项目名称 的切换，设置值
        selectPayServiceId (a, val) {
            if (val.payServiceId) {
                let row = this.rowForm.tableList.findIndex(e => e.payServiceId == val.payServiceId)
                let data = this.selectPayServiceList[row].findIndex(e => e.id == val.payServiceId)
                this.$set(this.rowForm.tableList[row], 'categoryLabel', this.selectPayServiceList[row][data].frequencyLabel)
                this.$set(this.rowForm.tableList[row], 'billTypeName', this.selectPayServiceList[row][data].billTypeName)
                this.$set(this.rowForm.tableList[row], 'billingDate', this.selectPayServiceList[row][data].billingDate)
                this.$set(this.rowForm.tableList[row], 'unitPrice', this.selectPayServiceList[row][data].unitPrice)
            } else {
                let row = this.rowForm.tableList.findIndex(e => e.options == val.options)
                console.log(row);
                console.log(this.rowForm.tableList);
                this.$set(this.rowForm.tableList[row], 'categoryLabel', '')
                this.$set(this.rowForm.tableList[row], 'billTypeName', '')
                this.$set(this.rowForm.tableList[row], 'billingDate', '')
                this.$set(this.rowForm.tableList[row], 'unitPrice', '')
            }
        }
    }
}
</script>
