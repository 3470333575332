<template>
    <div class="edit" >
        <div class="pdf"
             ref="excleCustom">
            <div class="head">
                <div class="title">
                    <h3>结算通知单</h3>
                </div>
                <div class="content">
                    <div>
                        <span>尊敬的商户：</span>{{titleData.tenantName}}
                    </div>
                    <div>
                        <span>项目名称：</span>{{titleData.entryName}}
                    </div>
                    <div>
                        <span>品牌：</span>
                    </div>
                    <div>
                        <span>结算期：</span>{{titleData.billMonth}}
                    </div>
                </div>
            </div>
            <!-- 本月账单 -->
            <current-period-table :tableList="newBillData"></current-period-table>
            <!-- 历史账单 -->
            <history-bill-table :tableList="historyBillData"></history-bill-table>
            <!-- 一次性费用 -->
            <temporary-bill :tableList="temporaryBillList"
                            :totalList="totalList"></temporary-bill>
            <!-- 水电数据 -->
            <hydropower-bill :tableList="hydropowerData"></hydropower-bill>
        </div>
        <div class="tips">
            <p>请于合同约定缴款日前缴纳以上款项至我司指定账户。</p>
            <p>温馨提示：</p>
            <p>未按合同约定缴款的，我司有权停止物业服务/解除合同。</p>
            <p style="margin-left:0.5vw"> 谢谢您的配合，顺祝商祺！</p>
            <p style="margin-left:70%">迎龙海悦城</p>
            <div class="autograph">
                <p style="margin-left:1.5vw">商户签收人：</p>
                <p style="margin-left:50%">签收日期：</p>
            </div>
        </div>
    </div>
</template>
<script>
import CurrentPeriodTable from './excleCustom/currentPeriodTable.vue'
import HistoryBillTable from './excleCustom/historyBillTable.vue'
import TemporaryBill from './excleCustom/temporaryBill.vue'
import HydropowerBill from './excleCustom/hydropowerBill.vue'
import { getBillPdfList, getBillPdfTitle } from '@/api'
export default {
    inject: ['getInit'],
    components: {
        CurrentPeriodTable,
        HistoryBillTable,
        TemporaryBill,
        HydropowerBill
    },
    data () {
        return {
            newBillData: [],
            temporaryBillList: [{ date: '装修押金', A: 0, B: 0, C: 0, D: 0 }, { date: '垃圾清运费', A: 0, B: 0, C: 0, D: 0 }],
            hydropowerData: [{ subcategory: '电表' }, { subcategory: '水表' }],
            totalList: [
                { name: '合计', A: '人民币', total: 0 }
            ],
            titleData: { entryName: '迎龙海悦城' },
            historyBillData: [
                { serviceName: '月固定租金', uncollected: null, writeOff: null, coupon: null, receivable: null },
                { serviceName: '物业管理费', uncollected: null, writeOff: null, coupon: null, receivable: null },
                { serviceName: '电   费', uncollected: null, writeOff: null, coupon: null, receivable: null },
                { serviceName: '设备维护服务费', uncollected: null, writeOff: null, coupon: null, receivable: null },
                { serviceName: '水   费', uncollected: null, writeOff: null, coupon: null, receivable: null },
                { serviceName: '设备维护服务费', uncollected: null, writeOff: null, coupon: null, receivable: null }
            ]
        }
    },
    props: {
        printShow: {
            type: Boolean,
            default: false
        },
        row:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    async created () {
        await this.init(this.row)
    },
    methods: {
        async init (row) {
            await this.getTitleData(row.id)
            await this.generateNewBileData(row.id)

        },
        // 生成当月账单数据
        generateNewBileData (id) {
            getBillPdfList({ billId: id }).then((res) => {
                const list = res
                const textArr = ['月固定租金', '物业管理费', '电费', '设备维护服务费（电）', '水费', '设备维护服务费（水）']
                const result1 = []; const result2 = []; const lack = []
                let total = 0
                // 过滤数据，result1=模板数据，result2=其它数据
                list.data['本期明细'].map(item => {
                    // 统计全部未收款
                    total += Number(item.uncollected) | 0
                    item.date = `${item.billDateStart} ~ ${item.billDateEnd}`
                    item.B = null
                    item.uncollected = item.uncollected - item.writeOff
                    item.uncollected = item.uncollected.toFixed(2)
                    if (item.natureCharges === '周期性') {
                        const length = textArr.indexOf(item.serviceName)
                        if (length < 0) {
                            result2.push(item)
                        } else {
                            if (item.serviceName === '设备维护服务费（电）' || item.serviceName === '设备维护服务费（水）') {
                                item.serviceName = '设备维护服务费'
                            }
                            lack.push(length)
                            result1[length] = item
                        }
                    } else {
                        result2.push(item)
                    }
                })
                // 查找result1是否存在6条模板数据，不存在补上
                for (let i = 0; i <= 5; i++) {
                    const length = lack.indexOf(i)
                    if (length < 0) {
                        const { date, day } = result1[0]
                        result1[i] = { serviceName: textArr[i], date, day }
                    }
                }
                this.newBillData = [...result1, ...result2]
                this.totalList.total = total
                // 判断是否存在水电表 ，不存在显示模板
                if (list.data['水电表'].length > 0) {
                    list.data['水电表'].map(item => {
                        if (item.subcategory === '水表') {
                            item.money = item.waterAmount
                            item.maintenanceCost = item.waterMaintenanceFee
                            item.total = Number(item.waterAmount) + Number(item.waterMaintenanceFee)
                        } else {
                            item.money = item.electricityAmount
                            item.maintenanceCost = item.electricityMaintenanceFee
                            item.total = Number(item.electricityAmount) + Number(item.electricityMaintenanceFee)
                        }
                    })
                    this.hydropowerData = list.data['水电表']
                }

                if (list.data['历史欠费'].length > 0) {
                    list.data['历史欠费'].map(item => {
                        // 统计全部未收款
                        total += Number(item.uncollected) | 0
                        item.date = `${item.billDateStart} ~ ${item.billDateEnd}`
                        item.uncollected = item.uncollected - item.writeOff
                        item.uncollected = item.uncollected.toFixed(2)
                    })
                    this.historyBillData = list.data['历史欠费']
                } else {
                    this.historyBillData = [
                        { serviceName: '月固定租金', uncollected: null, writeOff: null, coupon: null, receivable: null },
                        { serviceName: '物业管理费', uncollected: null, writeOff: null, coupon: null, receivable: null },
                        { serviceName: '电   费', uncollected: null, writeOff: null, coupon: null, receivable: null },
                        { serviceName: '设备维护服务费', uncollected: null, writeOff: null, coupon: null, receivable: null },
                        { serviceName: '水   费', uncollected: null, writeOff: null, coupon: null, receivable: null },
                        { serviceName: '设备维护服务费', uncollected: null, writeOff: null, coupon: null, receivable: null }
                    ]
                }
            })
        },
        getTitleData (id) {
            getBillPdfTitle(id).then((res) => {
                const { tenantName, billMonth } = res.data
                this.$set(this.titleData,'tenantName',tenantName)
                this.$set(this.titleData,'billMonth',billMonth)
            })
        },
        /** 关闭弹出框 */
        close () {
            this.$emit('printClose', false)
        }
    }

}
</script>
<style lang="scss" scoped>
.title {
    text-align: center;
    font-weight: 600;
    font-size: 2vw;
}
.head {
    .content {
        margin: 1vw 0;
        display: flex;
        flex-wrap: wrap;
        div {
            width: 50%;
            margin: 0.5vw 0;
            span {
                display: inline-block;
                width: 20%;
                text-align: right;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}
.tips{
    padding-top: 20px;
    p{
        line-height: 36px;
    }
    p:nth-of-type(5){
        padding-left: 60px;
        margin-top: 6px;
    }
    .autograph{
        display: flex;
    }
}
</style>
