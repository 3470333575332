<template>
  <div>
    <JaTable
      :conditionData="conditionData"
      :columns="obviousChargeColumn"
      :tableData="tableData"
      v-model="filterData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @getInit="getInit"
      @search="search"
      :showIndex="true"
      @reset="reset"
      :valueFormat="formatter"
      @getContract="getContract"
    />

    <AddEdit
      :show.sync="show"
      @closeForm="closeForm"
      :id="editId"
      :title="title"
    />
  </div>
</template>

<script>
import JaTable from '@/components/ja-table'
import {  obviousChargeColumn } from './billPaymentRecord'
import table from '@/views/mixins/table'
import AddEdit from './components/AddEdit'

import {
  addTenant,
  editTenant,
  exportTenant,
  getBillPay,
  getDictData,
  delTenant,
  auditTenant,
  submitTenant
} from '@/api'

export default {
  title: '账单缴费记录',
  mixins: [table],
  name: 'obviousCharge',
  components: {
    JaTable,
    AddEdit
  },
  data() {
    return {
      conditionData: [
       { input_type: 'select',key: 'buildingId',attribute: {placeholder: '楼栋',size: 'small'},options: [],styleWidth:'100px',method:'selectFun',
          relevance:{ sonKey:'floorId', This:this, // 必填。而 rqParam 是异步请求，必填字段
            rqParam:{ rqUrl:"/floor/all",rqData:()=>{return {params:{ buildingId: this.filterData.buildingId } }},labelName:'floorName',valueName:'floorId',resRule:'data'}, // 
          }
        },
        { input_type: 'select',key: 'floorId',attribute: {placeholder: '楼层',size: 'small'},options: [],styleWidth:'100px'},
        {
          input_type: 'select',
          attribute: {
            placeholder: '用途',
            size: 'small'
          },
          key: 'purpose',
          options: function () {
            return getDictData('room_purpose').then((res) => {
              console.log(res.data)
              let data = res.data.map((item) => {
                return {
                  label: item.dictLabel,
                  value: item.dictValue
                }
              })
              return data
            })
          }
        },
        {
          input_type: 'select',
          attribute: {
            placeholder: '账单期间',
            size: 'small'
          },
          key: 'time',
          options: [
            {
              label: '2021年1月',
              value: '2021年1月'
            },
            {
              label: '2021年2月',
              value: '2021年2月'
            },
            {
              label: '2021年3月',
              value: '2021年3月'
            },
            {
              label: '2021年4月',
              value: '2021年4月'
            },
            {
              label: '2021年5月',
              value: '2021年5月'
            },
            {
              label: '2021年6月',
              value: '2021年6月'
            },
            {
              label: '2021年7月',
              value: '2021年7月'
            },
            {
              label: '2021年8月',
              value: '2021年8月'
            },
            {
              label: '2021年9月',
              value: '2021年9月'
            },
            {
              label: '2021年10月',
              value: '2021年10月'
            },
            {
              label: '2021年11月',
              value: '2021年11月'
            },
            {
              label: '2021年12月',
              value: '2021年12月'
            }
          ]
        },
        {
          input_type: 'input',
          attribute: {
            placeholder: '房间名称',
            size: 'small'
          },
          key: 'roomName'
        },
        { normalBtn: 'search' },
        { normalBtn: 'reset' }
      ],
      obviousChargeColumn,
      tableData: [],
      total: 0,
      show: false,
      editId: '',
       filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      title: ''
    }
  },
  created() {},
  mounted() {
    // console.log("this.conditionData",this.conditionData)
    let index2 =  this.conditionData.findIndex(item=>item.key == 'buildingId' )
    this.util.rqOptionsList({who:[this.conditionData[index2]],rqUrl:"/building/list",rqData:{ pageIndex: 1, pageSize: 200 },rqMethod:'post',labelName:'buildingName',valueName:'id'})
  },
  methods: {
    getInit() {
      this.loading = true

      // 查询园区列表信息
      getBillPay(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    append() {
      this.title = '新增'
      this.show = true
    },
    rowEdit(item) {
      this.editId = item.id
      this.title = '编辑'
      this.show = true
    },
    getContract(item) {
      this.$router.push('/home/occupantManage/occupantContract')
    },
    rowDelete() {
      // TODO: 服务器出现问题，不能开发
    },
    closeForm(show) {
      this.show = show
    },
    formatter(val, key) {
      if (key === 'status') {
        return ['待缴费', '已到账 ', '已欠费'][val || 0]
      } else if (key === 'invoiceStatus') {
        return ['未开票', '已开票 '][val || 0]
      }
      return val || ''
    }
  }
}
</script>

<style scoped>
</style>
