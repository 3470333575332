<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="isAdd?'新增':'编辑'"
                   :visible.sync="show"
                   width="45%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowFormData"
                     :formArray="rowForm"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show"
                     v-loading="addLoading">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { addMaintenance, editMaintenance } from '@/api'
export default {
    inject: ['getInit'],
    components: {
        JaForm,
    },
    data() {
        return {
            rules: {
                deviceCategory:[{required:true,message:'设备类型不能为空',trigger:'blur'}],
                brand: [{ required: true, trigger: 'change', validator: this.util.formRules.checkText({textMessage:"设备品牌"}) }],
                deviceCompany: [{ required: true, trigger: 'change', validator: this.util.formRules.checkText({textMessage:"维保单位"}) }],
                linkMan: [{ required: true, trigger: 'change', validator: this.util.formRules.checkText({textMessage:"负责人"}) }],
                contactNumber: [{ required: true, trigger: 'change', validator: this.util.formRules.checkPhone() }],
            },
            rowForm: [
                { input_type: 'select', key: 'deviceCategory', label: '设备类型', labelWidth: '7vw', col: 11, options: [] },
                { input_type: 'input', key: 'brand', label: '设备品牌', labelWidth: '7vw', col: 11, maxlength: 30 },
                { input_type: 'input', key: 'deviceCompany', label: '维保单位', labelWidth: '7vw', col: 11, maxlength: 30 },
                { input_type: 'input', key: 'linkMan', label: '负责人', labelWidth: '7vw', col: 11, maxlength: 30 },
                { input_type: 'input', key: 'contactNumber', label: '联系方式', labelWidth: '7vw', col: 11, maxlength: 11 },
            ],
            rowFormData: {},
            show: false,
            isAdd: false,
            allDisabled: false,
            addLoading: false,
            indexObj:null
        }
    },
    mounted() {
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowForm })
    },
    methods: {
        init() {
            this.show = true
            this.allDisabled = false
            this.rowFormData = {}
            this.isAdd = false
        },
        append(row) {
            this.init()
            this.isAdd = true
            this.rowFormData = { ...row }
        },
        rowEdit(row) {
            this.init()
            this.rowFormData = { ...row }
        },
        showResident(row) {
            this.init()
            this.allDisabled = true
            this.rowFormData = { ...row }
        },

        saveForm() {
            this.addLoading = true
            if (this.isAdd) {
                addMaintenance(this.rowFormData).then((res) => {
                    this.show = false
                    this.addLoading = false
                    this.getInit()
                }).catch((res) => {
                    this.addLoading = false
                })
            } else {
                editMaintenance(this.rowFormData).then((res) => {
                    this.show = false
                    this.addLoading = false
                    this.getInit()
                }).catch((res) => {
                    this.addLoading = false
                })
            }
        }
    }
}
</script>