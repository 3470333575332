<template>
    <div class="edit">
        <el-dialog v-dialogDrag :close-on-click-modal="false" :title="title"  :visible.sync="show" width="35%" @close="close" :before-close="close">
            <ja-form  :rules="rules"  v-model="rowFormData" :formArray="rowForm" @initialization="initialization" @reset="close" @save="saveForm"  labelWidth="100px">
                <!-- <template #generateBill>
                    <div v-show="canBeEntered">
                        <span> 生成帐单</span> 
                        <el-input v-model="rowFormData.time" style="width:20%;margin: 0 10px"></el-input>
                        <span>小时 自动通过审核并下发缴费单。</span>
                    </div>
                </template>  -->
            </ja-form>
        </el-dialog>
    </div>
</template>

<script> 
import JaForm from '@/components/ja-form'
import { editOccupantData, editOccupantRules } from '../billManage'
import { switchAj, getSwitchAj } from '@/api'
export default {
    inject:['labelWidth','getInit'],
    name: 'EditOccupant',
    components: {
        JaForm
    },
    data () {
        return {
            rowForm: editOccupantData,
            rowFormData: {},
            rules: editOccupantRules,
            title: '账单审核设置',
            show: false,
            canBeEntered:false
        }
    },
    watch:{
        'rowFormData.flag':{
            handler(newVal){
                if(newVal ==='1'){
                    this.rowForm[1].disabled = true
                    this.rowFormData.time = null
                }else{
                    this.rowForm[1].disabled = false
                }
            }
        }
    },
    methods: {
        initialization(){
            this.show = true
            getSwitchAj().then((res) => {
            if (res.code === 200) {
                this.$set(this.rowFormData,'flag',res.data.flag)
                this.$set(this.rowFormData,'time',res.data.time)
            }
        })
        },
        /** 弹出框点击确认 */
        saveForm () {
            if(!this.rowFormData.time) return this.$message.warning('生成账单日期不可为空')
            switchAj({flag:this.rowFormData.flag,time:this.rowFormData.time}).then(res => {
                console.log(res);
                if (res.code === 200) {
                    this.$message.success('修改成功')
                    this.getInit()
                    this.close()
                }
            })

        },
        /** 关闭弹出框 */
        close () {
            this.show = false
        },
        abc(value){
            console.log(value);
        }
    }
}
</script>

<style less="scss" scoped>
.el-form-item__content > .el-switch {
    margin-left: 5vw;
}
.el-form-item__content > .el-switch .el-switch__core {
    width: 2.563vw !important;
}
</style>
