<template>
    <div class="tabsBox">
        <el-tabs v-model="activeName">
            <el-tab-pane label="内部车辆" name="1">
                <interior-vehicle v-if="activeName === '1'"></interior-vehicle>
            </el-tab-pane>
            <el-tab-pane label="驻户车辆" name="2">
                <resident-vehicle v-if="activeName === '2'"></resident-vehicle>
            </el-tab-pane>
            <el-tab-pane label="临时车辆" name="3">
                <temporary-vehicle v-if="activeName === '3'"></temporary-vehicle>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import interiorVehicle from './components/interiorVehicle.vue'
import residentVehicle from './components/residentVehicle.vue'
import temporaryVehicle from './components/temporaryVehicle.vue'
export default {
    title: '车辆通行记录',
    name: 'passRecord',
    components:{
        residentVehicle,
        interiorVehicle,
        temporaryVehicle,
    },
    data () {
        return {
            activeName: '1'
        }
    },
    
}
</script>
<style lang="scss" scoped>
.tabsBox {
    padding: 10px 18px;
    overflow: hidden;
}
.el-tabs {
    padding: 10px 10px;
    background-color: #fff !important;
    /deep/.el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll {
        background-color: #fff !important;
    }
}
</style>