<template>
    <el-main>
        <div class="header">
            <el-switch v-model="switchValue" @change="setSwitchValue" inactive-text="启停巡更" v-if="isAuth('videoTour::leftList:startStopPatrol')"></el-switch>
        </div>
        <div id="player"></div>
    </el-main>
</template>
<script>
const IS_MOVE_DEVICE = document.body.clientWidth < 992
import {getCruiseTime, patrolTeamView} from '@/api'
export default {
    data () {
        return {
            player: null,
            switchValue: false,
            urls: [],
        }
    },
    mounted () {
        this.init()
        this.createPlayer()
        this.$bus.$on('setvideoStream', res => {
            let id = res.id
            this.urls = []
            this.player.JS_StopRealPlayAll() // 切换前停止所有播放
            patrolTeamView(id,'ws').then(res => {
                if (res) {
                    res.data.map(e => {
                        this.urls.push(e.url)
                    })
                }
                if (this.urls.length > 0) {
                    this.realplay(this.urls)
                }
            })
            
        })
        this.$bus.$on('closePatrol',res=>{
            this.switchValue = res
        })
    },
    methods: {
        init () {
            // 设置播放容器的宽高并监听窗口大小变化
            window.addEventListener('resize', () => {
                this.player.JS_Resize()
            })
        },
        createPlayer () {
            this.player = new window.JSPlugin({
                szId: 'player',
                szBasePath: './js',
                iMaxSplit: 4,
                iCurrentSplit: IS_MOVE_DEVICE ? 1 : 2,
                openDebug: true,
                oStyle: {
                    borderSelect: IS_MOVE_DEVICE ? '#000' : '#FFCC00',
                }
            })

            // 事件回调绑定
            this.player.JS_SetWindowControlCallback({
                windowEventSelect: function (iWndIndex) {
                    //插件选中窗口回调
                    console.log('windowSelect callback: ', iWndIndex)
                },
                pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {
                    //插件错误回调
                    console.log('pluginError callback: ', iWndIndex, iErrorCode, oError)
                },
                performanceLack: function () {
                    //性能不足回调
                    console.log('performanceLack callback: ')
                }
            })
        },
        /* 播放 */
        realplay (urls) {
            urls.map((e, i) => {
                if (e) {
                    let playURL= e
                    let index = i
                    this.player.JS_Play(e, { playURL, mode:1 }, index).then(
                        () => {
                            console.log('realplay success')
                        },
                        (e) => {
                            console.error(e)
                        }
                    )
                }

            })

        },
        setSwitchValue (val) {
            if(val){
                getCruiseTime().then(res=>{
                    let time = null
                    time = Number(res.data.configValue) * 60 * 1000
                    this.$bus.$emit('getSwitchValue', val,time)
                })
            }else{
                this.$bus.$emit('getSwitchValue', val)
            }
          
            
        }
    },
    beforeDestroy(){
        this.$bus.$off('setvideoStream')
        this.$bus.$off('closePatrol')
    }

}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 991px) {
    #player {
        width: 90%;
        height: 98%;
    }
}
@media screen and (min-width: 992px) {
    #player {
        width: 100%;
        height: 98%;
    }
}
.el-main {
    min-height: 400px;
    height: 82vh;
    background: #fff;
    padding-top: 40px !important;
    position: relative;
    margin-right: 10px;
}
.header {
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px 20px;
    .el-switch {
        .el-switch__core {
            width: 35px !important;
        }
    }
}
</style>