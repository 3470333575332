<template>
    <div>
        <ja-table :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  v-model="filterData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  :editDataFormat="editDataFormat"
                  :valueFormat="formatter"
                  showSelect
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @handleSelectionChange="handleSelectionChange"
                  @rowEdit="rowEdit"
                  @append="allSubmit"
                  @rowSubmit="rowSubmit"
                  @showDetails="showDetails" />

        <add-edit ref="receiptRefs"></add-edit>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import AddEdit from './components/AddEdit'
import { getWaterElList, editWaterElList } from '@/api'
export default {
    title: '水电明细列表',
    mixins: [table],
    name: 'detailedList',
    components: {
        AddEdit
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small' } },
                { input_type: 'input', key: 'deviceNumber', attribute: { placeholder: '设备识别码', size: 'small' } },
                { input_type: 'select', key: 'subcategory', attribute: { placeholder: '设备小类', size: 'small' }, options: [] },
                { input_type: 'select', key: 'buildingName', attribute: { placeholder: '楼栋', size: 'small' }, options: [] },
                { input_type: 'select', key: 'floorName', attribute: { placeholder: '楼层', size: 'small' }, options: [] },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '租赁单元名称', size: 'small' } },
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '抄表周期开始时间', endPlaceholder: '抄表周期结束时间', styleWidth: '300px' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/iotElectricityWaterMeter/export', rqMethod: 'post' }, limit: 'energyModule::detailedList::export' },
                { normalBtn: 'add', text: '批量提交', icon: '', limit: 'energyModule::detailedList::allSubmit' }
            ],
            columns: [
                { label: '设备名称', prop: 'deviceName' },
                { label: '设备识别码', prop: 'deviceNumber', width: 150 },
                { label: '设备小类', prop: 'subcategory' },
                { label: '楼栋', prop: 'buildingName' },
                { label: '楼层', prop: 'floorName' },
                { label: '租赁单元名称', prop: 'roomName', width: 150 },
                { label: '驻户名称', prop: 'tenantName' },
                { label: '上次读数', prop: 'lastReading' },
                { label: '本次读数', prop: 'thisReading' },
                { label: '倍率', prop: 'rate' },
                { label: '抄表周期', prop: 'date', width: 200, },
                { label: '本期用量', prop: 'currentReading' },
                { label: '状态', prop: 'status' },
                {
                    label: '操作', input_type: 'button', width: 200, attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '查看', method: 'showDetails', limit: 'energyModule::detailedList::showDetails' },
                        { text: '编辑', method: 'rowEdit', limit: 'energyModule::detailedList::rowEdit' },
                        { text: '提交', method: 'rowSubmit', limit: 'energyModule::detailedList::rowSubmit' },

                    ]
                }
            ],
            tableData: [],
            total: 0,
            selectRows: []

        }
    },
    mounted() {
        let length = this.conditionData.findIndex(item => item.key == 'subcategory')
        let length1 = this.conditionData.findIndex(item => item.key == 'buildingName')
        let length2 = this.conditionData.findIndex(item => item.key == 'floorName')
        this.util.rqOptionsList({ who: [this.conditionData[length]], rqUrl: '/device/category', rqData: { params: { pageIndex: 1, pageSize: 200, isAll: '2', parentId: 112 } }, labelName: 'categoryName', valueName: 'categoryName' })

        this.util.rqOptionsList({ who: [this.conditionData[length1]], rqUrl: "/building/list", rqData: { pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'buildingName', valueName: 'buildingName', resRule: 'data' })
        this.util.rqOptionsList({ who: [this.conditionData[length2]], rqUrl: "/floor/allList", rqData: { pageIndex: 1, pageSize: 200 }, rqMethod: 'get', labelName: 'floorName', valueName: 'floorName', resRule: 'data' })
    },
    methods: {
        getInit() {
            this.loading = true
            getWaterElList(this.rqParmas()).then(res => {
                res.data.data.map(item => {
                    item.date = `${item.dateStart}~${item.dateEnd}`
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        showDetails(row) {
            this.$refs.receiptRefs.init(2, { ...row })
        },
        rowEdit(row) {
            this.$refs.receiptRefs.init(1, { ...row })
        },
        allSubmit() {
            if (this.selectRows.length <= 0) return this.$message.error('请先选择数据')
            this.$confirm('是否确定提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let ids = []
                this.selectRows.map(item => {
                    ids.push(item.id)
                })
                await editWaterElList({ ids: ids.join(), status: 1 })
                this.$message.success('提交成功')
                this.selectRows = []
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        rowSubmit(row) {
            this.$confirm('是否确定提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await editWaterElList({ ...row, status: 1 })
                this.$message.success('提交成功')
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        handleSelectionChange(val) {
            this.selectRows = val
        },
        editDataFormat(row, btn) {
            if ((row.status == 1 && btn.text == '查看') || (row.status != 1)) {
                return true
            } else {
                return false
            }
        },
        formatter(val, key) {
            if (key === 'status') {
                const statusVal = { 0: '待提交', 1: '已提交' }
                return statusVal[val]
            }
            if (key == 'rate' && val == null) {
                return '-'
            }
            return val
        },
        rqParmas() {
            let obj = { ...this.filterData }
            if (obj.date) {
                obj.dateStart = obj.date[0]
                obj.dateEnd = obj.date[1]
                delete obj.date
            }
            return obj
        }
    }
}
</script>

<style scoped>
/deep/.left {
    flex-wrap: wrap;
}
</style>
