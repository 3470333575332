<template>
    <div>
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @getInit="getInit" @search="search" @reset="reset" @lookRoom="lookRoom">
        </ja-table>
        <show-rental ref="showRental"></show-rental>
    </div>
</template>

<script>
import {
    getRentOutlist,
} from '@/api'
import table from "@/views/mixins/table"
import showRental from './showRental.vue'
export default {
    title: '已租列表',
    mixins: [table],
    name: 'toRentList',
    components: {
        showRental,
    },
    data () {
        return {
            conditionData: [ // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '驻户名称', size: 'small' }, },
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '凭租单元名称', size: 'small' }, },
                {
                    input_type: 'select', label: '楼栋', key: 'buildingId', options: [],
                    relevance: {
                        sonKey: 'floorId',
                        This: this,
                        rqParam: {
                            rqUrl: '/floor/allList',
                            rqData: () => {
                                return { params: {} }
                            },
                            labelName: 'floorName',
                            valueName: 'floorId',
                            resRule: 'data'
                        }
                    }
                },
                { input_type: 'select', label: '楼层', key: 'floorId', options: [] },
                {
                    text: '查询',
                    input_type: 'button',
                    method: 'search',
                    size: 'small',
                    attribute: {
                        type: 'primary',
                        icon: 'el-icon-search'
                    }
                },
                {
                    text: '重置',
                    input_type: 'button',
                    method: 'reset',
                    size: 'small',
                    attribute: {
                        type: 'default'
                    }
                }],
            columns: [ // 表头数据
                {
                    label: '楼栋',
                    prop: 'buildingName'
                },
                {
                    label: '楼层',
                    prop: 'floorName'
                },
                {
                    label: '驻户名称',
                    prop: 'tenantName'
                },
                {
                    label: '租赁单元名称',
                    prop: 'roomName'
                },
                {
                    label: '租赁单元类型',
                    prop: 'roomTypeValue'
                },
                {
                    label: '状态',
                    prop: 'statusValue'
                },
                {
                    label: '合同签订日期',
                    prop: 'startTime'
                },
                {
                    label: '租期',
                    prop: 'tenancyTerm',
                    width: '180px'
                },
                {
                    label: '租赁面积（㎡）',
                    prop: 'rentalArea',

                },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: "right",
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'financeModule::rentalConfigure::show' },
                    ]
                }
            ],
            tableData: [
                { a: '1' }
            ],
            loading: false,
            total: 30,
        }
    },
    mounted () {
        let index = this.conditionData.findIndex((item) => item.key == 'buildingId')
        this.util.rqOptionsList({ who: [this.conditionData[index]], rqUrl: '/building/list', rqMethod: 'post', labelName: 'buildingName', valueName: 'buildingId', resRule: 'data' })
    },
    methods: {
        getInit () {
            this.loading = true
            // 获取楼层信息列表
            getRentOutlist(this.filterData).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.data
                    this.total = res.data.total
                    this.loading = false
                }
            }).catch(err => {
                this.loading = false
            })
        },
        lookRoom (row) {
            this.$refs.showRental.init(row)
        }

    }
}
</script>

