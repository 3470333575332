
import util from '@/util/util'

/** 管理员列表页面搜索栏 */
export const managerListQuery = [
    {
        input_type: 'input',
        key: 'adminName',
        styleWidth: '200px',

        attribute: {
            placeholder: '人员姓名',
            size: 'small'
        }
    },
    {
        text: '查询',
        input_type: 'button',
        method: 'search',
        size: 'small',
        attribute: {
            type: 'primary',
            icon: 'el-icon-search'
        }
    },
    {
        text: '重置',
        input_type: 'button',
        method: 'reset',
        size: 'small',
        attribute: {
            type: 'default'
        }
    }
]

/** 管理员列表页面表头栏 */
export const managerListColumn = [
    {
        label: '园区名称',
        prop: 'parkName',
        attribute: { showOverflowTooltip: true, align: 'center' }
    },
    {
        label: '公司名称',
        prop: 'company'
    },
    {
        label: '用户姓名',
        prop: 'employeeName',
        attribute: { showOverflowTooltip: true, align: 'center' }
    },
    {
        label: '部门',
        prop: 'department'
    },
    {
        label: '手机号',
        prop: 'mobile'
    },
    {
        label: '操作',
        input_type: 'button',
        width: 100,
        attribute: {
            fixed: 'right',
            align: 'center'
        },
        list: [
            { text: '删除', method: 'rowDelete', limit: 'manager::list::delete' },
        ]
    }
]
