<template>
  <div class="tinymce">
    <!-- <editor v-model="editObj.content" :init="init" v-if="init"></editor> -->
    <editor v-model="myValue" :init="init" v-if="init"></editor>
  </div>
</template>

<script>
// 引入插件必需的文件
import tinymce from 'tinymce'
import 'tinymce/themes/silver/theme'
import './tinymce/skins/ui/oxide/skin.min.css'
import './tinymce/icons/default/icons'
import './tinymce/plugins/indent2em'
import './tinymce/plugins/image'

import lang from './tinymce/zh_CN'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/plugins/link'
import 'tinymce/plugins/fullscreen'
// import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/fullscreen'
// 这个是业务代码里的接口，upload上传图片，uploadUrl 获取图片路径
// import { upload, uploadUrl } from '@/api/fsl'
export default {
  name: 'tinymce',
  props: {
    // editObj: {
    //   type: Object,
    //   default: {}
    // },
    value: {
      type: String,
      default: ''
    },
    accept: {
      default: "image/jpeg, image/png,image/jpg",
      type: String
    },
    url: {
      default: "",
      type: String
    },
    maxSize: {
      default: 1024 * 1024,
      type: Number
    },
    withCredentials: {
      default: false,
      type: Boolean
    },
    config: {
      type: Object,
      default: () => {
        return {
          // theme: "modern",
          height: 300
        }
      }
    }
  },
  data() {
    return {
      // tinymceHtml: '请输入内容',
      self: this,
      init: null,
      loading: false,
      myValue: this.value
    }
  },
  mounted() {
    this.myInit()
    tinymce.init({})
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit('update:value', newValue)
      // this.$emit("input", newValue);
    }
  },
  methods: {
    myInit() {
      const self = this
      this.init = {
        image_dimensions: false,
        language_url: lang,
        language: 'zh_CN',
        height: 500,
        convert_urls: false,
        // emoticons_database_url: '/tinymce/emojis.js',
        plugins: 'link lists image code table preview fullscreen indent2em',
        // content_style:"img{max-width:100%}",
        // images_upload_url: '/demo/upimg.php',
        toolbar: 'bold italic underline strikethrough | fontselect | fontsizeselect | forecolor backcolor | indent2em | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | undo redo | image | fullscreen | removeformat link unlink preview code',
        // toolbar: `styleselect | fontselect | formatselect | fontsizeselect | forecolor backcolor | bold italic underline strikethrough | image  media | table | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | preview removeformat  hr | paste code  link | undo redo | fullscreen `,
        // CONFIG: Paste
        // paste_retain_style_properties: "all",
        // paste_word_valid_elements: "*[*]", // word需要它
        // paste_data_images: true, // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
        // paste_convert_word_fake_lists: false, // 插入word文档需要该属性
        // paste_webkit_styles: "all",
        // paste_merge_formats: true,
        // nonbreaking_force_tab: false,
        // paste_auto_cleanup_on_paste: false,
        // CONFIG: Font
        fontsize_formats: "10px 11px 12px 14px 16px 18px 20px 24px",

        // FontSelect
        font_formats: `
            微软雅黑=微软雅黑;
            宋体=宋体;
            黑体=黑体;
            仿宋=仿宋;
            楷体=楷体;
            隶书=隶书;
            幼圆=幼圆;
            Andale Mono=andale mono,times;
            Arial=arial, helvetica,
            sans-serif;
            Arial Black=arial black, avant garde;
            Book Antiqua=book antiqua,palatino;
            Comic Sans MS=comic sans ms,sans-serif;
            Courier New=courier new,courier;
            Georgia=georgia,palatino;
            Helvetica=helvetica;
            Impact=impact,chicago;
            Symbol=symbol;
            Tahoma=tahoma,arial,helvetica,sans-serif;
            Terminal=terminal,monaco;
            Times New Roman=times new roman,times;
            Trebuchet MS=trebuchet ms,geneva;
            Verdana=verdana,geneva;
            Webdings=webdings;
            Wingdings=wingdings,zapf dingbats`,
        branding: false,
        // prop内传入的的config
        ...this.config,
        // 图片上传
        images_upload_handler: (blobInfo, success, failure) => {
          if (blobInfo.blob().size > this.maxSize) {
            let num = Math.floor((this.maxSize / 1024 / 1024))
            failure(`文件不能大于${num}MB`)
          }

          if (this.accept.indexOf(blobInfo.blob().type) >= 0) {
            uploadPic()
          } else {
            failure("图片格式错误，仅支持 jpeg，jpg，png")
          }
          // let self = this
          function uploadPic() {
            console.log('-===>', this, self)
            const xhr = new XMLHttpRequest()
            const formData = new FormData()
            xhr.withCredentials = self.withCredentials
            xhr.open("POST", self.url)
            xhr.onload = function () {
              if (xhr.status !== 200) {
                // 抛出 'on-upload-fail' 钩子
                self.$emit("on-upload-fail")
                failure("上传失败: " + xhr.status)
                return
              }

              const json = JSON.parse(xhr.responseText)
              success(json.data)
              // 抛出 'on-upload-complete' 钩子
              self.$emit("on-upload-complete", [json, success, failure])
            }
            formData.append("file", blobInfo.blob())
            xhr.send(formData)
          }
        }
      }
    }
  },
  components: { Editor }
}
</script>

<style lang="scss">
.tinymce {
  width: 100%;
  img {
    max-width: 100%;
  }
}
.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 2200;
}
</style>
