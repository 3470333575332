<template>
    <div>
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading"  @getInit="getInit" @search="search" @reset="reset" @lookRoom="lookRoom">
        </ja-table>
        <show-unit ref="showUnit"></show-unit>
    </div>
</template>

<script>
import {
    getRentalUnitList,
} from '@/api'
import table from "@/views/mixins/table"
import showUnit from './showUnit.vue'
export default {
    title: '待租列表',
    mixins: [table],
    name: 'toRentList',
    components:{
        showUnit,
    },
    data () {
        return {
            conditionData: [ // 表格上方的搜索/选择操作栏
                {
                    input_type: 'input',
                    key: 'roomName',
                    attribute: {
                        placeholder: '凭租单元名称',
                        size: 'small'
                    },
            
                },
                {
                    input_type: 'select', label: '楼栋', key: 'buildingId', options: [],
                    relevance: {
                        sonKey: 'floorId',
                        This: this,
                        rqParam: {
                            rqUrl: '/floor/allList',
                            rqData: () => {
                                return { params: {} }
                            },
                            labelName: 'floorName',
                            valueName: 'floorId',
                            resRule: 'data'
                        }
                    }
                },
                { input_type: 'select', label: '楼层', key: 'floorId', options: [] },
                {
                    text: '查询',
                    input_type: 'button',
                    method: 'search',
                    size: 'small',
                    attribute: {
                        type: 'primary',
                        icon: 'el-icon-search'
                    }
                },
                {
                    text: '重置',
                    input_type: 'button',
                    method: 'reset',
                    size: 'small',
                    attribute: {
                        type: 'default'
                    }
                }],
            columns: [ // 表头数据
                {
                    label: '楼栋',
                    prop: 'buildingName'
                },
                {
                    label: '楼层',
                    prop: 'floorName'
                },
                {
                    label: '租赁单元名称',
                    prop: 'roomName'
                },
                {
                    label: '租赁单元类型',
                    prop: 'roomTypeValue'
                },
                {
                    label: '套内使用面积',
                    prop: 'totalArea'
                },
                {
                    label: '状态',
                    prop: 'statusValue'
                },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: "right",
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'financeModule::toRentList::show' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,
        }
    },
    mounted () {
        let index = this.conditionData.findIndex((item) => item.key == 'buildingId')
        this.util.rqOptionsList({who: [this.conditionData[index]],rqUrl: '/building/list',rqMethod: 'post',labelName: 'buildingName',valueName: 'buildingId',resRule: 'data'})
    },
    methods: {
        getInit() {
            this.loading = true
            // 获取楼层信息列表
            getRentalUnitList({...this.filterData,status:0}).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.data
                    this.total = res.data.total
                    this.loading = false
                }
            }).catch(err=>{
                this.loading = false
            })
        },
        lookRoom(row){
            this.$refs.showUnit.init(row)
        }
       
    }
}
</script>

