<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  showSelect
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="batchSubmit"
                  @handleSelectionChange="handleSelectionChange"
                  @editRow="editRow"
                    @importForm="
                    normalEvent({
                    ref: 'importForm',
                    method: 'importForm',
                    url: '/files/公摊费用收费项导入模板.xls',
                    filename: '公摊费用收费项导入模板',
                    uploadURL: '/bizBillDetailNew/upload'
                    })">
        </ja-table>
        <edit-rows ref="editRows"></edit-rows>
        <import-form ref="importForm"
                     @openResult="openResult" />
        <check-result ref="checkResult"/>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import EditRows from './editRows.vue'
import { bizBillInfoNewBatchSubmit, getBillList } from '@/api'
import importForm from './common/importForm.vue'
import checkResult from './common/checkResult'

export default {
    title: '待确定',
    mixins: [table],
    components: {
        EditRows,
        importForm,
        checkResult
    },
    data () {
        return {
            conditionData: [
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '租赁单元名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '计费周期开始时间', endPlaceholder: '计费周期结束时间' },
                { input_type: 'date', type: 'month', value: 'billDate', placeholder: '账单日期' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'import', text: '公摊费项导入' },
                { normalBtn: 'add', text: '批量确认账单' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/bizBillInfoNew/exet', rqMethod: 'post' } } //
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', width: 200 },
                { label: '租赁单元名称', prop: 'roomName', width: 200 },
                { label: '计费周期', prop: 'date', width: 200 },
                { label: '账单时间', prop: 'billDate', width: 200 },
                { label: '租金', prop: 'rent', width: 200 },
                { label: '物业管理费', prop: 'propertyManagementFee', width: 200 },
                { label: '电费', prop: 'electricity', width: 200 },
                { label: '设备维护费服务费（电）', prop: 'iptElectricity', width: 200 },
                { label: '水费', prop: 'water', width: 200 },
                { label: '设备维护费服务费（水）', prop: 'iotWater', width: 200 },
                { label: '状态', prop: 'statusName', width: 200 },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '编辑', method: 'editRow', limit: 'contractFile::determined::editRow' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,
            selectRows: []
        }
    },
    methods: {
        getInit () {
            const obj = { ...this.filterData, status: 1 }
            if (obj.date) {
                obj.billDateStart = obj.date[0]
                obj.billDateEnd = obj.date[1]
                delete obj.date
            }
            this.loading = true
            getBillList(obj).then(res => {
                res.data.data.map(item => {
                    item.date = `${item.billDateStart}~${item.billDateEnd}`
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        rqParmas () {
            return {
                pageSize: this.filterData.pageSize,
                pageIndex: this.filterData.pageIndex,
                billDateStart: this.filterData.date ? this.filterData.date[0] : null,
                billDateEnd: this.filterData.date ? this.filterData.date[1] : null,
                status: 1,
                tenantName: this.filterData.tenantName,
                roomName: this.filterData.roomName,
                billDate: this.filterData.billDate
            }
        },
        editRow (row) {
            this.$refs.editRows.init(row)
        },
        openResult ({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
        handleSelectionChange (val) {
            this.selectRows = val
        },
        batchSubmit () {
            if (this.selectRows.length <= 0) return this.$message.error('请先勾选确认的账单')
            this.$confirm('是否批量确认账单？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const ids = []
                this.selectRows.map(item => {
                    ids.push(item.id)
                })
                await bizBillInfoNewBatchSubmit({ ids: ids.join(), status: 2 })
                this.$message.success('提交成功')
                this.selectRows = []
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                })
            })
        }
    }
}
</script>

<style scoped>
.download {
    width: 5vw;
}
</style>
