<template>
    <div>
        <el-dialog :visible.sync="show"
                   :close-on-click-modal="false"
                   width="70%"
                   title="查看">
            <ja-form :rules="rules"
                     v-if="show"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :btnBox="btnBox"
                     :allDisabled="allDisabled"
                     @showDetails="showDetails"
                     @handleCurrentChange="handleCurrentChange"
                     @writeOff="writeOff"
                     @register="register"
                     @adopt="adopt"
                     @reset="show = false">
                <template #mesContent>
                    <el-tooltip placement="bottom">
                        <div slot="content">
                            计算优惠：如有减免调整后金额，则预计实收合计为调整后金额-优惠金额；
                            <br />
                            不计算优惠：按照调整后应收金额合;
                        </div>
                        <el-button type="text"
                                   icon="el-icon-warning"
                                   size=""></el-button>
                    </el-tooltip>

                </template>
                <template #totle>
                    <span style="margin-right:20px">合计：{{totleList.receivableTotal}}</span>
                    <span style="margin-right:20px"> 合计实收：{{totleList.writeOffTotal}}</span>
                    <span>欠费总计：{{totleList.unpaidAmount}}</span>
                </template>
            </ja-form>

        </el-dialog>
    </div>
</template>
<script>
import { getArrearageBillListDerail } from '@/api'
export default {
    data() {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', labelWidth: '110px', col: 5 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '110px', col: 10 },
                { input_type: 'select', label: '合计规则', key: 'sumRule', labelWidth: '110px', col: 7, disabled: true, options: [{ label: '不计算优惠', value: '0' }, { label: '计算优惠', value: '1' }] },
                { input_type: 'customize', customize: 'mesContent', label: '', col: 2, labelWidth: '10px' },
                {
                    input_type: 'table', col: '24', noSearch: true, showPage: false, key: 'bizBillDetailNewDTOs', isRequired: true, border: true, highlightCurrentRow: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', width: 120 },
                        { label: '收费项目性质', prop: 'natureCharges', width: 120 },
                        { label: '收费项目名称', prop: 'serviceName', width: 120 },
                        { label: '计费周期', prop: 'date', width: 220 },
                        { label: '缴费周期', prop: 'day', width: 120 },
                        { label: '本期应收', prop: 'receivable', width: 120 },
                        { label: '调整后应收', prop: 'adjustedReceivable', width: 120 },
                        { label: '优惠金额', prop: 'coupon', width: 120 },
                        { label: '未收金额', prop: 'uncollected', width: 120 },
                        { label: '备注', prop: 'remark', width: 220 },
                        { label: '已核销金额（元）', prop: 'writeOff', width: 150 },
                        { label: '欠费金额', prop: 'unpaidAmount', width: 150 },
                    ]
                },
                { input_type: 'customize', customize: 'totle', label: '', col: 24, labelWidth: '10px' },
            ],
            show: false,
            title: '',
            rowForm: {},
            totle: 0,
            btnBox: [],
            totleList: null
        }
    },
    methods: {
        async init(row) {
            this.show = true
            this.rowForm = {}
            this.totle = row.amountReceivable || 0
            let res = await getArrearageBillListDerail({ billId: row.infoId, pageIndex: 1, pageSize: 1000 })
            let { receivableTotal, sumRule, writeOffTotal, unpaidAmount } = res.data['scatteredData']
            this.totleList = { receivableTotal, writeOffTotal, unpaidAmount }
            res.data['billSchedule'].map(item => {
                if (item.adjustedReceivable == 0) {
                    item.adjustedReceivable = null
                }
                item.date = item.billDateStart + '~' + item.billDateEnd
            })
            this.rowForm = { ...row, bizBillDetailNewDTOs: [...res.data['billSchedule']], sumRule }

        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
::v-deep .titlePart {
    padding-left: 15px;
    background: #ebffff;
    width: 100%;
}
::v-deep .el-input-number__increase {
    display: none;
}
::v-deep .el-input-number__decrease {
    display: none;
}
</style>