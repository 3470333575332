<template>
    <!--  结单 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="(isAdd?'增加':'编辑')+titleLast+'类型'">
        <ja-form :rules="rules" ref="jaForm" v-model="rowForm" :labelWidth="labelWidth" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
    </el-dialog>
</template>
<script>
import defferObj from '../defferPage'
import { addWorkConfig, editWorkConfig, getWorkConfigReceiver } from '@/api'
export default {
    inject: ['getInit'],
    props: {
        activePage: String
    },
    data () {
        return {
            defferObj,
            rowFormData: [],
            rowForm: {},
            rules: {},
            loadingForm: false,
            showEditAll: false, // 显示新增/编辑弹窗,
            isAdd: false,
            titleLast: '',
            rowFormData_indexObj: {},
            employees: []
        }
    },
    watch: {
        'rowForm.workOrderTypeId': function (val) {
            if (this.$parent.activePage === 'scan') {
                this.util.rqOptionsList({
                    who: [this.rowFormData[1]],
                    rqUrl: "/patrol/path/all?type=" + val,
                    resRule: 'data',
                    labelName: 'pathName',
                    valueName: 'id'
                })
            }
        }
    },
    methods: {
        dealDiffer () {
            const type = this.$parent.activePage
            this.rowFormData = [...this.defferObj[type].rowFormData] // 表单数据
            this.rules = { ...this.defferObj[type].rules }
            this.rowFormData[0].options = [...this.$parent.conditionData[0].options] // 派单类型
            if (type === 'scan') {
                this.util.rqOptionsList({
                    who: [this.rowFormData[1]],
                    rqUrl: "/patrol/path/all",
                    resRule: 'data',
                    labelName: 'pathName',
                    valueName: 'id'
                })
                console.info(this.rowFormData)
            }
            this.titleLast = this.defferObj[type].typeText // 维修，巡检，告警
            this.rowFormData_indexObj = this.util.getArrItemIndex({ arr: this.rowFormData })// 表单选项的key为对应这条数据的索引
        },
        // 编辑
        async rowEdit (row) {
            this.rowForm = { ...row }
            this.dealDiffer()
            this.isAdd = false
            this.showEditAll = true
            await this.resetEmployee(row.deptIds)
            // 当部门范围有改变时，就要把不在部门范围内的人员过滤掉，不在显示到input框内
            this.rowForm.employeeIdList = row.employeeIdList.filter(it => this.employees.map(item => item.id).includes(it))
        },

        // 新增
        async append () {
            if (this.$parent.deptIds.length === 0) {
                this.$message.warning('请先完善 “派单范围设置”')
                return
            }
            this.dealDiffer()
            this.isAdd = true
            this.showEditAll = true
            this.rowForm = {}
            await this.resetEmployee()
            this.$nextTick(() => {
                this.$refs.jaForm.$refs.formbox.clearValidate()
            })
        },
        // 更新 新增弹窗 的 接单人下拉列表
        resetEmployee () {
            return new Promise((resolve, reject) => {
                this.loadingForm = true
                const arr = this.$parent.deptIds
                getWorkConfigReceiver([...arr]).then(res => {
                    this.loadingForm = false
                    this.rowFormData[this.rowFormData_indexObj.employeeIdList].options = (res.data || []).map(item => {
                        return {
                            label: `${item.deptName}_${item.username}`,
                            value: item.id
                        }
                    })
                    // 保存接单人信息
                    this.employees = res.data || []

                    resolve()
                }).catch(() => {
                    this.loadingForm = false
                    reject()
                })
            })
        },
        saveForm () {
            const obj = { ...this.rowForm }
            obj.employeeId = this.rowForm.employeeIdList.join(',')
            // 筛选出接单人的部门id，并去重。
            const selectDeptIds = this.employees.filter(item => {
                return obj.employeeIdList.includes(item.id)
            }).map(item => {
                return item.deptId
            }).filter((item, index, arr) => {
                return arr.indexOf(item) == index
            })
            obj.deptId = selectDeptIds.join(',')

            if (this.isAdd) {
                obj.classify = this.$parent.activePageValue
            }
            this.loadingForm = true
            const rqMethod = this.isAdd ? addWorkConfig : editWorkConfig
            rqMethod(obj).then(() => {
                this.loadingForm = false
                this.$message.success('提交成功')
                this.showEditAll = false
                this.getInit()
            }).catch(() => {
                this.loadingForm = false
            })
        }
    }
}
</script>
