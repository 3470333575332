<template>
<div :class="['ja-chart-wrap']">
  <h2>{{titleText}} <small><slot name="btn"></slot></small></h2>
  <div class="content">
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  name: "jaChartWrap",
  props: ["titleText", 'otherClass']
}
</script>

<style scoped lang="scss">
@import "~@/style/mixin.scss";
.showWarp{
  overflow: initial !important;
}
.ja-chart-wrap{
  background-color: $theme-color;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 1px 1px 1px lightgray;
  .content{
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  h2{
    display: flex;
    justify-content: space-between;
    align-items: center;
   padding: 15px;
   border-bottom: 1px solid $theme_border_color;
 }
}
</style>
