<template>
    <div class="main">
        <!-- 年度统计 -->
        <el-row class="totle" v-loading="show">
            <el-col :span="4">
                <el-card shadow="never" class="box1">
                    <span class="topText">本月电耗（总表）</span>
                    <div class="bottomTest">
                        <span>{{ totleData.monthElectricity || 0 }}</span>
                        <span class="rightText">kW·h</span>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never" class="box1">
                    <span class="topText">年度总电耗（总表）</span>
                    <div class="bottomTest">
                        <span>{{ totleData.annualElectricity || 0 }}</span>
                        <span class="rightText">kW·h</span>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never" class="box1">
                    <span class="topText">本月电耗（不含总表）</span>
                    <div class="bottomTest">
                        <span>{{ totleData.monthElectricityNoTotal || 0 }}</span>
                        <span class="rightText">kW·h</span>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never" class="box1">
                    <span class="topText">年度总电耗（不含总表）</span>
                    <div class="bottomTest">
                        <span>{{ totleData.annualElectricityNoTotal || 0 }}</span>
                        <span class="rightText">kW·h</span>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never" class="box2">
                    <span class="topText">本月水耗</span>
                    <div class="bottomTest">
                        <span>{{ totleData.monthWaterYeld || 0 }}</span>
                        <span class="rightText">m³</span>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never" class="box2">
                    <span class="topText">年度总水耗</span>
                    <div class="bottomTest">
                        <span>{{ totleData.annualWater || 0 }}</span>
                        <span class="rightText">m³</span>
                    </div>
                </el-card>
            </el-col>

        </el-row>

        <el-row class="ranking">
            <!-- 排行 -->

            <ranking titleLeft="驻户用电月排名" titleRight="单位：kW·h" type="2" seriesBGColor="#02a9ec"></ranking>

            <ranking titleLeft="驻户用水月排名" titleRight="单位：m³" type="1" seriesBGColor="#f59a22"></ranking>

            <!-- 统计 -->
            <statistics title="用电统计" type="2" />
            <statistics title="用水统计" type="1" />
        </el-row>

    </div>
</template>

<script>
import Ranking from './components/ranking.vue'
import Statistics from './components/statistics.vue'
import { getAnnualConsumption } from '@/api'
export default {
    title: "能源统计",
    components: { Ranking, Statistics },
    data() {
        return {
            totleData: {
                annualElectricity: 0,
                annualWater: 0,
                monthElectricity: 0,
                monthWaterYeld: 0
            },
            show: true
        }
    },
    mounted() {
        getAnnualConsumption().then(res => {
            let obj = {}
            let data = res.data
            for (const iterator in data) {
                obj[iterator] = Number(data[iterator]).toFixed(2)
            }

            this.totleData = obj
            this.show = false
        })
    }

}
</script>
<style lang="scss" scoped>
.totle {
    padding: 0.5vw;
    text-align: center;
    display: flex;
    justify-content: space-between;

    .bottomTest {
        white-space: nowrap;
        display: block;
        text-align: center;
        margin: 10px;
        font-size: 2vw;

        >.rightText {
            font-size: 15px;
        }
    }

    .topText {
        white-space: nowrap;
        width: 80%;
        height: 20%;
        display: inline-block;
        padding: 5px;
        border-radius: 20px;
        color: #fff;
    }
}

.box1 {
    font-size: 13px;
    border-radius: 12px;

    .bottomTest {
        color: #027db4;
    }

    .topText {
        background: #027db4;
    }
}

.box2 {
    font-size: 13px;
    border-radius: 15px;

    .bottomTest {
        color: #f59a22;
    }

    .topText {
        background: #f59a22;
    }
}

.ranking {
    margin: 0 12px;
    padding: 10px 20px;
    background: #fff;
    border-radius: 12px;
}
</style>
