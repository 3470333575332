<template>
    <div>
         <!-- 详情弹窗 -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="查看详情" :width="`50%`">
            <ja-detail :options="detailOptions" :detailData="detailData" :label_width='8' v-if="showDetail">
            </ja-detail>
        </el-dialog>
    </div>
</template>
<script>
import {detailObj,} from './Detail.js'

export default {
    data(){
        return{
            showDetail: false,
            detailOptions:[],
            detailData: {},
            categoryLink_idObj:{} // 关联仪表，id 对象
        }
    },
    methods:{
        rowDetail (row) {
            // console.log('查看详情---',row,)
            this.detailOptions = [...(detailObj[row.category].detailOptions)]
            this.detailData = {...row}
            if( row.category == 'instrument'){ // 若是仪表类
                this.selectDevice()
                this.detailData.deviceCategory = this.categoryLink_idObj[this.detailData.deviceCategory]
            }
            this.showDetail = true
            
        },
        // 关联仪表 的处理
        selectDevice(){
            let index = this.detailOptions.findIndex(v=>v.key == 'unitPrice')
            let index2 = this.detailOptions.findIndex(v=>v.key == 'timeIntervalList')
            let row = this.detailData
            let deviceCategory = row.deviceCategory
            // 124 水表单位为m³，125 电表单位为kw · h， 186 风冷单位为km³，187 水冷单位为kw · h
            let type01 =  deviceCategory == 124 || deviceCategory == 125
            let type02 =  deviceCategory == 186 || deviceCategory == 187
            this.$set(this.detailOptions[index],'hide', !type01 )
            this.$set(this.detailOptions[index2],'hide', !type02)
            if(type01){
                this.detailData.unitPrice = `${this.detailData.unitPrice}${deviceCategory ==124?'元/m³':'元/kw · h'}`
            }
            if(type02){
               this.detailData.timeIntervalList = this.detailData.timeIntervalList.map(item=>{
                    return{
                         ...item,
                         unit: deviceCategory == 186?'元/km³':'元/kw · h'
                    }
                })
            }
          
        },
    }
}
</script>