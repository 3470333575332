<template>
  <section class="home">
    <u-header />
    <section class="content">
      <aside>
        <u-menu ref="leftMenu"/>
      </aside>
      <main
        class="theme_back_color"
        v-loading="loading"
        ref="main"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="wrap">
          <div class="header_nav">
            <div class="nav_list">
              <el-row>
                <el-col :span="22">
                  <el-breadcrumb separator="/">
                    <transition-group name="breadcrumb">
                      <template v-for="(route, i) in crumbList">
                        <el-breadcrumb-item
                          :key="i"
                          v-if="route == '首页'"
                          class="indexTitle"
                          :to="{ path: '/home/cockpit' }"
                        >
                          {{ route }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :key="i" v-else >
                          {{ route }}
                        </el-breadcrumb-item>
                      </template>
                    </transition-group>
                  </el-breadcrumb>
                </el-col>
                <el-col :span="2">
                  <div class="edit" v-if="isCockpit">
                     <el-button  size="mini" @click="screenfullBtn">{{isFullscreen?'全屏':'退出'}}</el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <router-view />
        </div>
      </main>
    </section>
  </section>
</template>

<script>
import UHeader from "./components/uHeader/uHeader"
import UMenu from "./components/uMenu/uMenu"
import { mapGetters } from "vuex"
import screenfull from 'screenfull'

export default {
    name: "Home",
    components: { UMenu, UHeader },
    data () {
        return {
            loading: false,
            title: "",
            crumbList: [],
            isCockpit: false,
            isFullscreen: true,
            active_arr: [] // 后台返回的菜单  active 时的索引
        }
    },
    computed: {
        ...mapGetters(["permission_routes", "rq_routes"])
    },
    created () {
        const path = this.$route.name === '404' ? { name: 'defaultPage', fromType: '404' } : this.$route
        this.dealMenuTitle(path)
        this.loading = false
        this.isCockpit = this.$route.path === '/home/cockpit'
    },
    watch: {
        $route (val) {
            this.dealMenuTitle(val)
            this.isCockpit = val.path === '/home/cockpit'
        }
    },
    mounted () {
        this.$emit("loading")
    },
    methods: {
    /** 点击全屏按钮 */
        screenfullBtn () {
            screenfull.toggle(this.$refs.main)
            this.isFullscreen = screenfull.isFullscreen
        },
        dealMenuTitle (value) {
            // console.log('value--->',value,this.$store.getters.menu_title)
            this.crumbList.splice(0, this.crumbList.length)
            if (value.name === 'defaultPage') {
                if (value.fromType === '404') {
                    this.$router.replace('/home/defaultPage')
                    return
                }
                sessionStorage.setItem("menu", "/")
                this.$store.commit('SET_ACTIVEURL', "/")
                this.$nextTick(() => {
                    this.$refs.leftMenu.defaultOpeneds = []
                })
                return
            }
            const arr_url = value.path.split("/")
            const active_page = arr_url[arr_url.length - 1]
            if (value.name === '404' || !this.$store.getters.menu_title[active_page]) {
                this.$nextTick(() => {
                    this.$refs.leftMenu.defaultOpeneds = []
                })
                sessionStorage.removeItem("menu")
                this.$store.commit("SET_ACTIVEURL", '*')
                if (value.name !== '404') {
                    this.$router.replace('/home/404')
                }
                return
            }

            const active_menuPath_arr = this.$store.getters.menu_title[active_page].path.split('/')
            const defaultOpeneds = []
            this.$store.commit("SET_ACTIVEURL", value.fullPath)
            for (let i = active_menuPath_arr.length - 1; i > 2; i--) {
                const str = active_menuPath_arr.slice(0, i).join('/')
                defaultOpeneds.unshift(str)
                // console.log('str===>',str,'defaultOpeneds===',defaultOpeneds)
            }
            this.$nextTick(() => {
                this.$refs.leftMenu.defaultOpeneds = [...defaultOpeneds]
            })
            this.crumbList = this.crumbList.splice(this.crumbList.length - 1)
            this.crumbList = [...this.$store.getters.menu_title[active_page].titleList]
            if (this.$store.getters.menu_title[active_page]) {
                const active_menuPath = this.$store.getters.menu_title[active_page].path
                sessionStorage.setItem("menu", active_menuPath)
                this.$store.commit('SET_ACTIVEURL', active_menuPath)
            } else {

            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/style/mixin.scss";
.home {
  @include flex(column);
  height: 100vh;
  background-color: $bg-color;
  overflow: hidden;

  .content {
    @include flex;
    flex: 1;
    //margin-top: 1px;
    aside {
      width: 300px;
      position: relative;

      padding-bottom: 20px;
    }
    main {
      position: relative;
      flex: 1;
      // margin: 15px;
      margin-top: 4px;
      margin-right: 0;
      .wrap {
         //@include flex(column);

        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        // padding-right: 15px;
        h2 {
          line-height: 60px;
          margin-bottom: 15px;
          background-color: $theme-color;
          font-weight: 600;
          // padding-left: 15px;
          flex: none;
        }
        > * {
          flex: 1;
        }
      }
    }
  }
}
.nav-breadcrumb {
  padding-left: 10px;
  padding-top: 15px;
  background: #ffffff;
  padding-bottom: 15px;

}

.hid-nav {
  display: none;
}

/deep/.el-breadcrumb__inner {
  font-weight: normal;
  color: #999;
}
/deep/ .el-breadcrumb__item:last-child {
  color: #000;
  font-weight: 500;
}
/deep/ .indexTitle{
  .el-breadcrumb__inner{
    font-weight: 700 !important;
    text-decoration: none;
    -webkit-transition: color .2s cubic-bezier(.645,.045,.355,1);
    transition: color .2s cubic-bezier(.645,.045,.355,1);
    color: #303133 !important;
  }

}
.header_nav{
  width: 100;
  padding: 16px 15px 0;
    overflow: hidden;
  .el-breadcrumb__item{
    display: flex;
    align-items: center;
    &:last-child{
      /deep/ .el-breadcrumb__inner{
              color: $theme_background !important;
      }
      border-bottom: 4px solid $theme_background !important;
    }

  }

  .el-breadcrumb__separator{
    margin: 0 3px;
  }
  .nav_list{
    background: white;
    border-radius: 10px;
    height: 50px;
    padding-left: 19px;
    font-size: 16px;
    // line-height: 22px;
    /deep/ .el-breadcrumb{
      // line-height: 50px;
      .el-breadcrumb__inner{
        padding: 17px 14px 13px;
        display: block;
        &.is-link{
          color: $theme_background !important;
          // border-bottom: 4px solid $theme_background !important;
        }
      }
    }
  }
  .edit{
    display: flex;
      height: 50px;
    align-items: center;
  }
}
</style>
