<template>
    <div>
        <el-table :data="historyList"
                  border
                  style="width: 90%;margin: 0 auto;">
            <el-table-column type="index"
                             label="版本"
                             width="100"
                             align="center">
            </el-table-column>
            <el-table-column prop="changeTemplateName"
                             align="center"
                             label="变更内容">
            </el-table-column>
            <el-table-column prop="changeApplyName"
                             align="center"
                             label="变更人">
            </el-table-column>
            <el-table-column prop="changeApplyTime"
                             align="center"
                             width="200"
                             label="变更时间">
            </el-table-column>
            <el-table-column prop="auditName"
                             align="center"
                             label="审批人">
            </el-table-column>
            <el-table-column prop="auditTime"
                             align="center"
                             width="200"
                             label="审批时间">
            </el-table-column>
            <el-table-column align="center"
                             label="操作">
                <template slot-scope="scope">
                    <el-button type="text"
                               @click="showChange(scope.row)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="titleText">变更详情</div>

        <el-row style="width: 90%;margin: 0 auto;"
                v-if="historyState == 1 || historyState == 2">
            <el-col :span="12">
                <div class="customTitle">
                    变更前
                </div>
                <el-table :data="historyListDetail"
                          border
                          :show-header="false"
                          style="width: 90%;margin: 0 auto;">
                    <el-table-column prop="changeTemplateName"
                                     align="center"
                                     label="">
                    </el-table-column>
                    <el-table-column prop="newData"
                                     align="center"
                                     label="">
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="12">
                <div class="customTitle">
                    变更后
                </div>
                <el-table :data="historyListDetail"
                          border
                          :show-header="false"
                          style="width: 90%;margin: 0 auto;">
                    <el-table-column prop="changeTemplateName"
                                     align="center"
                                     label="">
                    </el-table-column>
                    <el-table-column prop="oldData"
                                     align="center"
                                     label="">
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <el-row style="width: 90%;margin: 0 auto;"
                v-if="historyState == 3">
            <el-col :span="12">
                <div class="customTitle">
                    变更前
                </div>
                <el-table :data="details"
                          border
                          style="width: 90%;margin: 0 auto;">
                    <el-table-column prop="date"
                                     align="center"
                                     label="账单周期">
                    </el-table-column>
                    <el-table-column prop="adjustRent"
                                     align="center"
                                     label="租金（元/月）">
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="12">
                <div class="customTitle">
                    变更后
                </div>
                <el-table :data="detailsChange"
                          border
                          style="width: 90%;margin: 0 auto;">
                    <el-table-column prop="date"
                                     align="center"
                                     label="账单周期">
                    </el-table-column>
                    <el-table-column prop="adjustRent"
                                     align="center"
                                     label="租金（元/月）">
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { getContractChangeJournal, getContractChangeJournalDetail } from '@/api'
export default {
    props: {
        changeDetail: {
            type: Object,
            defalut: () => { return {} }
        },
        pid: {
            type: String,
            defalut: ''
        }
    },
    data() {
        return {
            //变更记录
            historyList: [],
            //变更记录详情
            historyListDetail: [],
            //明细变更前
            details: [],
            //明细变更后
            detailsChange: [],
            historyState: null
        }
    },
    async mounted() {
        let res = await getContractChangeJournal(this.pid)
        res.data.map(item => {
            item.changeTemplateName = ['租赁单元变更', '驻户变更', '明细变更'][item.changeTemplate - 1]
        })
        this.historyList = [...res.data]
    },
    methods: {
        async showChange(row) {
            this.historyState = row.changeTemplate
            let res = await getContractChangeJournalDetail(this.pid)
            switch (row.changeTemplate) {
                case 1:
                    let { roomName, changeRoomName } = res.data
                    this.historyListDetail = [{ changeTemplateName: '租赁单元', newData: roomName, oldData: changeRoomName }]
                    break;
                case 2:
                    let { tenantName, changeTenantName } = res.data
                    this.historyListDetail = [{ changeTemplateName: '驻户名称', newData: tenantName, oldData: changeTenantName }]
                    break;
                case 3:
                    let { details, detailsChange } = res.data
                    //捕获空值
                    try {
                        details.map(item => {
                            item.date = item.startTime + '~' + item.endTime
                        })
                        detailsChange.map(item => {
                            item.date = item.startTime + '~' + item.endTime
                        })
                        this.details = details
                        this.detailsChange = detailsChange
                    } catch (err) {

                    }

                    break;
            }
        },



    }
}
</script>
<style less="scss" scoped>
.titleText {
    width: 95%;
    height: 50px;
    background-color: #ebffff;
    margin: 20px auto;
    line-height: 50px;
    font-weight: 600;
    font-size: 0.885vw;
    color: #606266;
    border-bottom: 1px solid black;
}
.customTitle {
    height: 7vh;
    border: 1px solid #ebeef5;
    width: 90%;
    margin: 0 auto;
    border-bottom: 0;
    font-weight: 600;
    line-height: 7vh;
    padding-left: 0.5vw;
}
</style>
