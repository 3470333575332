<template>
    <div>
        <el-dialog v-dialogDrag :close-on-click-modal="false"  :visible.sync="show" width="60%" :title="titleText">
            <ja-table
                :columns="columns" 
                :tableData="tableData"
                :showIndex="true"
                :showPage="false"
                :total="total"
                @gotoDetail="gotoDetail"
            >
            </ja-table>
        </el-dialog>
        <!-- 详情 -->
       <detail ref="detail"/>
    </div>
</template>
<script>
import { ContractHistoryApi} from '@/api'
import Detail from './Detail'
import table from '@/views/mixins/table'
export default{
    mixins: [table,],
    title: "历史",
    components: {
        Detail
    },
    data(){
        return{
            show: false,
            titleText: '合同历史版本',
            columns:[
                { label: '合同名称', prop: 'contractName'},
                { label: '历史版本', prop: 'version'},
                { label: '审核日期', prop: 'auditTime',width:200},
                { label: '合同状态', prop: 'statusName'},
                { label: '录入人', prop: 'createBy'},
                { label: '审核人', prop: 'auditEmployee'},
                { label: '操作', input_type: 'button',attribute:{ fixed:"right" , align:'center' },
                list: [
                    { text: '查看', method: 'gotoDetail'}, //TODO l 配权限
                    
                ]
    }
            ],
            tableData:[],
            contractId:0,
        }
    },
    methods:{
        contractHistory(row){
            let obj = {...row}
            this.contractId = obj.id
            this.show = true
            this.tableData = []
            ContractHistoryApi({id:row.id}).then((res)=>{
                if(res.code !== 200) return this.$message.error('加载历史列表失败')
                if(!res.data.length) return false
                this.tableData = res.data
                this.total = res.data.total
            })
        },
        gotoDetail(row){
            //重构合同类型
            row.contractTypeName = row.contractTypeLabel
            this.$refs.detail.rowDetail(row,true)
        }
    }
}
</script>
