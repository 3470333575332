<template>
    <!--  -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="校验结果" :width="`60%`" v-if="results">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='11' >
          <template #errNum>
              <div class="errNum">
                  <div v-if="Number(detailData.errNum)>0"><span class="warn">{{detailData.errNum}}</span>条，失败详情：</div>
                  <div v-else>{{detailData.errNum+'条'}}</div>
              </div>
          </template>
          <template #errorList>
              <div class="errBox" v-if="Number(detailData.errNum)>0">
                  <ul>
                      <li v-for="(item,index) in detailData.errorList" :key="index">{{item}}</li>
                  </ul>
              </div>
          </template>
      </ja-detail>
    </el-dialog>
</template>
<script>
export default {
    props:['results'],
    data() {
        return {
            //详情
            showDetail: false,
            detailOptions:
                [
                    { label: '成功导入', key: 'successNum', col: 24 },
                    { label: '导入失败', key: 'errNum', type: 'customize', customize: 'errNum', col: 24  },
                    { label:' ',type: 'customize', customize: 'errorList', col: 24 },
                    { label: '重复数据', key: 'repeat', col: 24 },
                    { label: '重复数据处理', key: 'dealType', col: 24},
                ],
            detailData: {},
        }
    },
    methods: {
        rowDetail() {
            // console.log('row==>',this.results)
            this.showDetail = true
            let obj = {...this.$parent.results.data}
            obj.successNum = `${obj.successNum}条`
            obj.repeat = `${obj.repeatNum}条（表号与抄表时间相同）`
            obj.dealType = this.$parent.results.uploadFlag
            this.detailData = obj
            // console.log('详情数据---',this.detailData)
        },
    }
}
</script>
<style lang="scss" scoped>
.errNum{
    .warn{
        color:#f56c6c;
    }
}
.errBox{
    min-height: 300px;
    overflow: auto;
    padding: 20px;
    border: 1px solid #DCDFE6;
    border-radius:8px;
}
</style>