<template>
    <div>
        <el-table :data="tableList"
                  border
                  header-row-class-name="newTable"
                  style="width: 100%">
            <el-table-column label="当期水电费明细"
                             align="center">
                <el-table-column prop="subcategory"
                                 align="center"
                                 label="电表类型">
                </el-table-column>
                <el-table-column prop="lastReading"
                                 align="center"
                                 label="上次读数">
                </el-table-column>
                <el-table-column prop="thisReading"
                                 align="center"
                                 label="本次读数">
                </el-table-column>
                <el-table-column prop="rate"
                                 align="center"
                                 label="倍率">
                </el-table-column>
                <el-table-column prop="currentReading"
                                 align="center"
                                 label="用量">
                </el-table-column>
                <el-table-column prop="money"
                                 align="center"
                                 label="金额">
                </el-table-column>
                <el-table-column prop="maintenanceCost"
                                 align="center"
                                 label="设备维护服务费">
                </el-table-column>
                <el-table-column prop="total"
                                 align="center"
                                 label="总额">
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        tableList: {
            type: Array,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
.autograph{
    display: flex;
}
.bottom-box {
    margin: 1vw 0;
    //font-size: 13px;
    line-height: 6vh;
}
::v-deep .el-table--border,
.el-table--group {
    border-top: 0;
}
::v-deep .newTable {
    font-size: 16px;
    font-weight: 800;
    .el-table__cell {
        border-color: #0a0a0a !important;
    }
}
::v-deep .el-table__footer-wrapper {
    font-size: 16px;
    font-weight: 800;
}
::v-deep .el-table {
    border-color: #080808;
}
::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
    border-color: #0e0e0f !important;
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
    content: '';
    position: absolute;
    background-color: #0e0e0f;
    z-index: 1;
}
::v-deep .el-table::before {
    content: '';
    position: absolute;
    z-index: 1;
}
</style>
