<template>
  <!-- 设备用电 -->
  <div class="content">
    <titleBox title="设备用电" v-model="activeYear" :selectObj="selectObj">
      <template #left>
        <div class="subTitle">合计用电量：{{sum}} KWH</div>
      </template>
    </titleBox>
    <!-- 表格 -->
    <div class="tableBox">
      <div class="header">
        <span class="th_01">序号</span><span class="th_02">用电类型</span><span class="th_03">用电量（KWH）</span><span class="th_04">占比</span>
      </div>
      <div class="main">
        <ul class="list">
          <li v-for="(item,index) in tableList" :key="index">
            <div class="th_01">
              <div :class="['indexBox',index<3?'activeIndex':'']">{{index+1}}</div>
            </div>
            <div class="th_02">{{item.type}}</div>
            <div class="th_03">
              <el-progress :text-inside="true" :format="formatFun(item)" :stroke-width="strokeWidth" :percentage="item.percent"></el-progress>
            </div>
            <div class="th_04">{{item.percent+'%'}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from './titleBox.vue'

export default {
  components: {
    titleBox
  },
  data() {
    return {
      // 年份
      activeYear: '',
      selectObj: {
        options: [
          { label: '2019', value: '2019' },
          { label: '2020', value: '2020' },
          { label: '2021', value: '2021' },
          { label: '全部', value: 'all' },
        ]
      },
      sum: 8486,
      // 表格相关
      strokeWidth: this.util.setFontSize(20, 1920),
      tableList: [
        { type: '照明', num: 2278, percent: 48.5 },
        { type: '空调', num: 2278, percent: 32 },
        { type: '生活水泵动力', num: 2278, percent: 10 },
        { type: '消防动力', num: 2278, percent: 9 },
        { type: '消防动力', num: 12, percent: 8 },
        { type: '消防动力', num: 278, percent: 8 },
        { type: '消防动力', num: 23, percent: 8 },
        { type: '消防动力', num: 478, percent: 8 },
        { type: '消防动力', num: 500, percent: 8 },
      ]
    }
  },
  watch: {
    activeYear: {
      immediate: true,
      handler(v) {
        console.log("年份====", v)
      }
    }
  },
  methods: {
    formatFun(item) {
      return function (v,) {
        return item.num
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/srcollbar.scss';

.subTitle {
  font-size: 15px;
  color: #333333;
  margin-left: 15px;
  font-weight: initial;
}
.tableBox {
  font-size: 15px;
  padding: 20px 40px 10px 30px;
  width: 100%;
  .header {
    color: #909399;
  }
  .th_01,
  .th_02,
  .th_03,
  .th_04 {
    text-align: center;
  }
  .main {
    color: #303133;
    height: 300px;
    overflow-y: auto;

    .th_01 {
      .indexBox {
        &.activeIndex {
          background: #f9c580;
        }
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #cecece;
        margin: 0 auto;
        color: white;
      }
    }
    .th_02 {
      line-height: 1.3;
    }
  }
  .header,
  .main > ul.list > li {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 15px 0;
  }
  .th_02 {
    width: 20%;
  }
  .th_01 {
    width: 15%;
  }
  .th_04 {
    width: 15%;
  }
  .th_03 {
    width: 50%;
    .el-progress {
      width: 80%;
      margin: 0 auto;
    }
  }
}
/deep/ .el-progress-bar {
  position: relative;
  .el-progress-bar__inner {
    position: initial;
  }
  .el-progress-bar__innerText {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
/deep/ .el-progress-bar__innerText {
  color: black;
}
</style>