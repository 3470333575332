<template>
    <div class="tabsBox">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="待租列表" name="1">
                <to-rent-list v-if="activeName === '1'"></to-rent-list>
            </el-tab-pane>
            <el-tab-pane label="已租列表" name="2">
                <rental-configure v-if="activeName === '2'"></rental-configure>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import toRentList from './components/toRentList.vue'
import rentalConfigure from './components/rentalConfigure.vue'
export default {
    title: '租赁状态列表',
    name: 'rentalStatusList',
    components:{
        toRentList,
        rentalConfigure,
    },
    data () {
        return {
            activeName: '1'
        }
    },
}
</script>
<style lang="scss" scoped>
.tabsBox {
    padding: 10px 18px;
    overflow: hidden;
}
.el-tabs {
    padding: 10px 10px;
    background-color: #fff !important;
    /deep/.el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll {
        background-color: #fff !important;
    }
}
</style>