import { queryDeptList, getEmployList, getCustomer } from '@/api'
export default {
    data() {
        return {
            personnelLists: [],
            contacts: []
        }
    },
    watch: {
        'rowForm.contactsId': {
            handler(newVla) {
                if (newVla) {
                    this.contacts.forEach(item => {
                        if (item.value === newVla) {
                            this.$set(this.rowForm, 'clientCompany', item.clientCompany)
                            this.$set(this.rowForm, 'customerContact', item.customerContact)
                            this.$set(this.rowForm, 'clientDepartment', item.clientDepartment)
                        }
                    })
                } else {
                    this.$set(this.rowForm, 'clientCompany', null)
                    this.$set(this.rowForm, 'customerContact', null)
                }
            }
        },
        'rowForm.followUpPeopleDeptId': {
            handler(newVla) {
                if (newVla) {
                    let index = this.rowFormData.findIndex(e => e.key === 'followUpPeopleId')
                    this.util.rqOptionsList({
                        who: this.rowFormData[index],
                        httpFun: getEmployList,
                        rqData: {
                            deptId: newVla,
                            pageIndex: 1,
                            pageSize: 100,
                        },
                        otherKeys: [{ keyName: 'mobile', keyValue: 'mobile' }],
                        labelName: 'nickname',
                        valueName: 'id'
                    }).then((res) => {
                        this.personnelLists = res
                        let length = res.findIndex(item => item.value === this.rowForm.followUpPeopleId)
                        
                        if(length < 0){
                            this.$set(this.rowForm, 'followUpPeopleId', '')
                        }
                    })
                }
            },
            immediate: false
        },
        'rowForm.followUpPeopleId': {
            handler(newVla) {
                if (newVla) {
                    this.personnelLists.forEach(item => {
                        if (item.value === newVla) {
                            this.$set(this.rowForm, 'followUpPeopleContact', item.mobile)
                        }
                    })
                }
            }
        },
    },
    methods: {
        async getSelect() {
            let index1 = this.rowFormData.findIndex(e => e.key === 'followUpPeopleDeptId')
            let index2 = this.rowFormData.findIndex(e => e.key === 'contactsId')
            let index3 = this.rowFormData.findIndex(e => e.key === 'leasePurpose')
            await this.util.rqOptionsList({
                who: this.rowFormData[index1],
                httpFun: queryDeptList,
                rqData: {
                    pageIndex: 1,
                    pageSize: 100,
                },
                labelName: 'deptName',
                valueName: 'id'
            })
            await this.util.rqOptionsList({
                who: this.rowFormData[index2],
                httpFun: getCustomer,
                rqData: {
                    pageIndex: 1,
                    pageSize: 100,
                },
                labelName: 'contacts',
                valueName: 'id',
                otherKeys: [
                    { keyName: 'customerContact', keyValue: 'customerContact' },
                    { keyName: 'clientDepartment', keyValue: 'clientDepartment' },
                    { keyName: 'clientCompany', keyValue: 'clientCompany' }],
            }).then(res => {
                this.contacts = res
            })
            this.util.rqOptionsList({
                who: this.rowFormData[index3],
                rqUrl: '/sys/dictData/query',
                rqMethod: 'post',
                rqData: { dictType: 'lease_purpose' },
                labelName: 'dictLabel',
                valueName: 'dictValue'
            })
        }

    }
}