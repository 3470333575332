import myCanvas from '@/util/MyCanvas/MyCanvas'
import {
    checkTheControlChart,

} from '@/api'
export const myMinxin = {
    data() {
        return {
            //左侧数据源
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'text',
            },
            rowFormTree: {},
            canvasObj: {},
            expandedkey: [],
            loadingCanvas: false,
            loadingTree: false
        }
    },
    mounted() {
        this.getInit()
        this.newCanvas()
    },
    methods: {
        /**
         * 创建新画布
         * imgUrl：图片地址
         * addressLists:坐标地址
         */
        newCanvas(imgUrl, addressLists = []) {
            if (imgUrl && imgUrl.length > 0) {
                const img = new Image()
                img.src = imgUrl
                img.onload = function () {
                    this.canvasObj = new myCanvas({ canvas: this.$refs.canvas, div: this.$refs.canvasDiv.$el, addressLists: addressLists, img: img, state: true })
                    this.canvasObj.init()
                }.bind(this)
            } else {
                this.canvasObj = new myCanvas({ canvas: this.$refs.canvas, div: this.$refs.canvasDiv.$el, state: true })
                this.canvasObj.init()

            }
            this.loadingCanvas = false
        },
        //请求消控图
        getCheckTheControlChart(Objitem, item) {
            try {
                if (Objitem.children) {
                    this.showTool = false
                } else {
                    this.loadingCanvas = true
                    this.showTool = true
                    this.rowFormTree = {
                        buildingId: item.parent.data.value,
                        floorId: item.data.value,
                    }
                    checkTheControlChart(this.rowFormTree).then(res => {
                        if (res.code !== 200) {
                            this.$message.error('请求失败')
                        }
                        //重构之后的数据
                        let arrData = []
                        //存在数据 
                        if (res.data.id) {
                            let { salesControlUrl, controlCoordinateReqList, id } = res.data
                            this.fileList = salesControlUrl
                            this.rowFormTree.id = id
                            //保证存在坐标没有就传空避免报错
                            if (isNaN(controlCoordinateReqList) && controlCoordinateReqList.length > 0) {
                                (controlCoordinateReqList || []).map(e => {
                                    arrData.push({ childs: [...e.childs], id: e.roomId, name: e.roomName, colour: e.colour })
                                })
                                this.newCanvas(salesControlUrl, arrData)
                                this.canvasObj.addressLists = { ...arrData }
                            } else {
                                this.newCanvas(salesControlUrl)
                                this.canvasObj.addressLists = {}
                            }

                        }
                    })
                }
            } catch (err) {
                console.log(err);
                this.$message.error('未知错误，请联系管理员')
            }

        },
    }
}