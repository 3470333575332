<template>
    <div class="main">
        <el-form ref="form"
                 class="contentForm"
                 :model="rowForm"
                 label-width="15vw">
            <el-form-item label="账单日设置:">
                每个月的第
                <el-select v-model="rowForm.dateValue"
                           @change="selectBlur"
                           placeholder="请选择日期">
                    <el-option :label="item"
                               :key="item"
                               v-for="item in 28"
                               :value="item"></el-option>
                </el-select>
                日
            </el-form-item>
            <el-form-item label="开账提醒设置:">
                账单日提前
                <el-input-number v-model="rowForm.day"
                                 :controls="false"
                                 :min="1"
                                 @change="inputBlur"
                                 style="width:65%;"></el-input-number>
                天
            </el-form-item>
        </el-form>
    </div>

</template> 
<script>
import { setThreshold, getThreshold } from '@/api'
export default {
    title: '账单日设置',
    name: 'billingDate',
    data() {
        return {
            rowForm: {}
        }
    },
    async mounted() {
        getThreshold('bill_date').then(res => {
            this.$set(this.rowForm, 'dateValue', res.data.configValue)
        })
        getThreshold('bill_hint_day_num').then(res => {
            this.$set(this.rowForm, 'day', res.data.configValue || 5)
        })
    },
    methods: {
        selectBlur(val) {
            setThreshold({ configKey: 'bill_date', configValue: val }).then(() => {
                getThreshold('bill_date').then(res => {
                    this.$set(this.rowForm, 'dateValue', res.data.configValue)
                })
            })
        },
        inputBlur(val) {
            setThreshold({ configKey: 'bill_hint_day_num', configValue: val }).then(() => {
                getThreshold('bill_hint_day_num').then(res => {
                    this.$set(this.rowForm, 'day', res.data.configValue || 5)
                })
            })
        }
    }
}
</script>
<style scoped>
.main {
    height: 100%;
    position: relative;
}
.contentForm {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-wrap: wrap;
}
::v-deep .el-input__inner {
    text-align: left !important;
}
</style>