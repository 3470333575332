<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @selectRoom="selectRoom"
                  @details="details">
        </ja-table>
        <!-- 选择空间 -->
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="60%"
                   title="仪表绑定空间"
                   :visible.sync="isSelectRoom"
                   v-if="isSelectRoom">
            <select-space @selectRow="selectSpace"
                          :selectedPersons="[...selectedRooms]"
                          v-if="isSelectRoom" />
        </el-dialog>
        <!-- 详情 -->
        <Details ref="detailsRef" />
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import { getInstrumentManagement, setBindingSpace } from '@/api'
import selectSpace from './selectSpace/selectSpace.vue'
import Details from './Details.vue'
export default {
    title: '仪表管理',
    mixins: [table],
    name: 'index',
    components: { selectSpace, Details },
    data() {
        return {
            //空间显示
            isSelectRoom: false,
            // 空间弹窗
            selectedRooms: [],
            conditionData: [
                {
                    input_type: 'select',
                    attribute: {
                        placeholder: '设备小类',
                        size: 'small'
                    },
                    options: [],
                    key: 'subCategory',
                },
                {
                    input_type: 'select',
                    attribute: {
                        placeholder: '绑定状态',
                        size: 'small'
                    },
                    key: 'bindStatus',
                    options: [
                        {
                            label: '已绑定',
                            value: 1
                        },
                        {
                            label: '未绑定',
                            value: 0
                        }
                    ]
                },
                {
                    input_type: 'select', key: 'buildingId', attribute: { placeholder: '楼栋', size: 'small' }, options: [], styleWidth: '100px', method: 'selectFun',
                  
                },
                { input_type: 'select', key: 'floorId', attribute: { placeholder: '楼层', size: 'small' }, options: [], styleWidth: '100px' },
                {
                    input_type: 'input',
                    attribute: {
                        placeholder: '设备名称',
                        size: 'small'
                    },
                    key: 'deviceName'
                },
                {
                    input_type: 'input',
                    attribute: {
                        placeholder: '设备识别码',
                        size: 'small'
                    },
                    key: 'deviceNumber'
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/bizIot/upload', rqMethod: 'post' }, limit: 'Instrument::Management::download' } //
            ],
            columns: [
                { label: '设备名称', prop: 'deviceName' },
                { label: '设备识别码', prop: 'deviceNumber', width: '100px' },
                { label: '设备大类', prop: 'categoryName' },
                { label: '设备小类', prop: 'subCategoryName' },
                { label: '所属楼栋', prop: 'buildingName' },
                { label: '所属楼层', prop: 'floorName' },
                { label: '设备位置', prop: 'installLocation' },
                { label: '设备品牌', prop: 'brand' },
                { label: '设备型号', prop: 'model' },
                { label: '绑定状态', prop: 'statusName' },
                { label: '绑定租赁单元', prop: 'roomName' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '查看', method: 'details', limit: 'meter::manage::details' },
                        { text: '绑定租赁单元', method: 'selectRoom', limit: 'meter::manage::bindingSpace' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 300,
            activeRow: null,
            showDetails: false,
            // 空间绑定该id
            spaceId: null,
            roomName: null
        }
    },
    mounted() {
        //加载搜索框数据
        let index2 = this.conditionData.findIndex(item => item.key == 'buildingId')
        let index3 = this.conditionData.findIndex(item => item.key == 'subCategory')
        let index = this.conditionData.findIndex(item => item.key == 'floorId')
        this.util.rqOptionsList({ who: [this.conditionData[index2]], rqUrl: "/building/list", rqData: { pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'buildingName', valueName: 'buildingId' ,resRule:'data'})
        this.util.rqOptionsList({ who: [this.conditionData[index]], rqUrl: "/floor/allList", rqData: { pageIndex: 1, pageSize: 200 }, rqMethod: 'get', labelName: 'floorName', valueName: 'floorId' ,resRule:'data'})
        this.util.rqOptionsList({ who: [this.conditionData[index3]], rqUrl: "/bizIot/getSubCategory", rqData: { pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'categoryName', valueName: 'id', resRule: 'data' })
    },
    methods: {
        getInit() {
            this.loading = true
            getInstrumentManagement(this.filterData).then((res) => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
        // 选择空间
        selectSpace({ list }) {
            //空间id
            let id = list[0].id
            let confirmStr = ''
            if (!this.roomName) {
                confirmStr = `是否确定绑定租赁单元为：${list[0].roomName}？`
            } else {
                confirmStr = `该仪表已绑定租赁单元为：${this.roomName}，是否确定绑定租赁单元修改为：${list[0].roomName}？`
            }

            this.$confirm(confirmStr, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '绑定中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                setBindingSpace({ id: id, spaceId: this.spaceId }).then(res => {
                    loading.close();
                    this.isSelectRoom = false
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '绑定成功!'
                    });
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消绑定'
                });
            });
        },
        //弹出
        selectRoom(row) {
            this.roomName = row.roomName
            this.spaceId = row.id
            this.isSelectRoom = true
        },
        //详情
        details(row) {
            this.$refs.detailsRef.getDetailsData(row)
        }
    }
}
</script>
