<template>
    <div class="allwh">
        <el-row :gutter="50" v-loading="headLoading">
            <el-col :span="4">
                <el-card shadow="never" :body-style="{ background: 'rgba(83, 110, 196, 1)' }">
                    <p style="white-space: nowrap;">申请数量</p>
                        <div class="testBox">{{  transire.wholeNumber || 0 }}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never" :body-style="{ background: 'rgba(141, 197, 113, 1)' }">
                    <p style="white-space: nowrap;">放行数量</p>
                    <div class="testBox">{{  transire.releaseNumber || 0 }}</div>
                </el-card>
            </el-col>
        </el-row>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :editDataFormat="editDataFormat"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @rowEdit="normalEvent"
                  @rowDetail="normalEvent"
                  @limitconfig="limitConfig">
        </ja-table>
        <!-- 新增/编辑 -->
        <add-edit ref="addEdit" />
        <detail ref="detail" />
        <!-- 权限配置弹框 -->
        <el-dialog
            title="权限配置"
            :visible.sync="limitVisible"
            width="30%"
            :before-close="handleClose">
            <div style="margin-bottom: 10px;">上次权限人:{{ LastlimitUser }}</div>
            <span>权限人:</span>
            <el-select v-model="limitUser" placeholder="请选择">
                <el-option
                v-for="item in UserData"
                :key="item.id"
                :label="item.username"
                :value="item.id">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="limitVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveLimitConfig">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { rqTransire, getUserInfo, getrqParameterList, setThreshold, getTransire } from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './components/AddEdit.vue'
import Detail from './components/Detail.vue'

export default {
    title: '放行条管理',
    mixins: [table,],
    name: 'index',
    components: {
        AddEdit, Detail
    },
    data() {
        return {
            limitVisible:false,
            conditionData: [
                { input_type: 'input', key: 'applyDeptName', attribute: { placeholder: '店铺名称', size: 'small' } },
                { input_type: 'input', key: 'applyEmployeeName', attribute: { placeholder: '申请人', size: 'small' }, },
                { input_type: 'select', key: 'type', attribute: { placeholder: '放行条类型', size: 'small' }, options: [] },
                { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
                {
                    input_type: 'select', key: 'status', attribute: { placeholder: '审批状态', size: 'small' }, options: [
                        { label: '审核中', value: 0 },
                        { label: '已同意', value: 1 },
                        { label: '已驳回', value: 2 },
                        { label: '已放行', value: 3 },
                    ]
                },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
                { input_type: 'button', text:'权限配置',method: 'limitconfig' },
            ],
            columns: [
                { label: '店铺名称', prop: 'applyDeptName' },
                { label: '申请人', prop: 'applyEmployeeName' },
                { label: '申请人联系方式', prop: 'applyEmployeePhone', width: 140 },
                { label: '放行条类型', prop: 'typeName' },
                { label: '放行时间', prop: 'transitTime' },
                { label: '物品搬运人', prop: 'transireEmployeeName', width: 140 },
                { label: '身份证号码', prop: 'transireEmployeeIdCard', },
                { label: '搬运人联系方式', prop: 'transireEmployeePhone', width: 140 },
                { label: '放行申请原因', prop: 'applyDescription', width: 180 },
                { label: '提交时间', prop: 'createTime', width: 140 },
                { label: '审批状态', prop: 'statusName' },
                {
                    label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
                    list: [
                        { text: '查看', method: 'rowDetail', limit: 'propertyServices::releaseNote::rowDetail' },
                        { text: '通过/驳回', method: 'rowEdit', limit: 'propertyServices::releaseNote::rowEdit' },
                    ]
                }
            ],
            tableData: [{status:0}],
            UserData:[],//用户列表
            limitUser:'',//当前权限人
            limitid:'',//权限id
            LastlimitUser:'',//上次权限人
            transire:'',//放行统计
            headLoading:false
        }
    },
    mounted() {
        let length = this.conditionData.findIndex(item => item.key == 'type')
        this.util.rqOptionsList({ who: [this.conditionData[length]], rqUrl: "/sys/dictData/transire_type", rqMethod: 'get', resRule: 'data' })
    },
    methods: {
        //更新权限
        saveLimitConfig(){
            setThreshold({ configValue:this.limitUser, configId:this.limitid }).then(res => {
                console.log(res);
            })
            this.limitVisible = false
            this.loading = true
            this.getInit()
        },
        //权限配置弹窗
        limitConfig(){
            this.limitVisible = true
        },
        rqParmas() {
            let obj = { ...this.filterData }
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        async getInit() {
            this.loading = false
            this.headLoading = true
            let data = this.rqParmas()
            getTransire(data).then(res => {
                this.transire = res.data
                this.headLoading = false
            })
            rqTransire(data).then(res => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
            await getUserInfo({ pageIndex :1, pageSize :999}).then(res => {
                this.UserData = res.data.data
            })
            await getrqParameterList('transire_audit_employee').then(res => {
                this.limitid = res.data.configId
                this.UserData.forEach(item => {
                    if(item.id == res.data.configValue){
                        this.LastlimitUser = item.username
                    }
                })
            })
        },
        editDataFormat(row, btn) {
            if ((row.status === 0 && btn.text == '通过/驳回') || (row.status != 0 && btn.text == '查看')) {
                return true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;

    .el-card {
        color: #fff;
        border-radius: 0.4vw;

        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 2vw;
        }
    }
}
</style>