<template>
  <div class="contain">
    <iframe :src="webUrl" width="100%" height="100%" scrolling="auto" frameborder="0" > </iframe>
  </div>
</template>

<script>



export default {
  beforeRouteEnter(to, from, next) {
    console.log('to====>',to)
    next((vm => {
      vm.webUrl = to.params.outLink || sessionStorage.getItem('outLink')
    }))
  },
  title: "嵌套网页框",
  // name: 'DevicePandect',
  name: 'web',
  data() {
    return {
      webUrl: ''
    }
  },
  components: {
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@import '~@/style/mixin.scss';
.contain{
  padding: 10px 15px;
  margin: 15px 15px;
  height: calc(100% - 100px);
  background-color: $theme-color;
}
</style>
