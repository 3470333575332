<template>
    <div>
        <ja-table :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  v-model="filterData"
                  showSelect
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @handleSelectionChange="handleSelectionChange"
                  @showDetails="showDetails"
                  @writeNewBill="writeNewBill"
                  @advancePayment="advancePayment"
                  @append="batchBill" />

        <add-edit ref="addRefs"></add-edit>
        <advance-payment ref="advanceRefs"></advance-payment>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import AddEdit from './components/AddEdit'
import { getBillList, editbizBillInfoNew } from '@/api'
import AdvancePayment from "@/views/home/collectFees/obviousCharge/components/AdvancePayment.vue"
export default {
    title: '收费明细列表',
    mixins: [table],
    name: 'obviousCharge',
    components: {
        AdvancePayment,
        AddEdit
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '租赁单元名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '计费周期开始时间', endPlaceholder: '计费周期结束时间' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '批量开新账单', icon: '', limit: 'contractFile::obviousCharge::add' }
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', width: 200 },
                { label: '租赁单元名称', prop: 'roomName', width: 200 },
                { label: '计费周期', prop: 'date', width: 200 },
                { label: '账单时间', prop: 'billDate', width: 200 },
                { label: '租金', prop: 'rent', width: 200 },
                { label: '物业管理费', prop: 'propertyManagementFee', width: 200 },
                { label: '电费', prop: 'electricity', width: 200 },
                { label: '设备维护费服务费（电）', prop: 'iptElectricity', width: 200 },
                { label: '水费', prop: 'water', width: 200 },
                { label: '设备维护费服务费（水）', prop: 'iotWater', width: 200 },
                {
                    label: '操作', input_type: 'button', width: 200, attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '开新账单', method: 'writeNewBill', limit: 'contractFile::obviousCharge::writeNewBill' },
                        { text: '查看', method: 'showDetails', limit: 'contractFile::obviousCharge::showDetails' },
                        // { text: '预缴费用', method: 'advancePayment', limit: 'contractFile::obviousCharge::writeNewBill' }
                    ]
                }
            ],
            tableData: [],
            total: 0,
            selectRows: []

        }
    },
    methods: {
        getInit() {
            let obj = { ...this.filterData, status: 0 }
            if (obj.date) {
                obj.billDateStart = obj.date[0]
                obj.billDateEnd = obj.date[1]
                delete obj.date
            }
            this.loading = true
            getBillList(obj).then(res => {
                res.data.data.map(item => {
                    item.date = `${item.billDateStart}~${item.billDateEnd}`
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        handleSelectionChange(val) {
            this.selectRows = val
        },
        batchBill() {
            if (this.selectRows.length <= 0) return this.$message.error('请先勾选下发的账单')
            this.$confirm('是否确定批量开新账单？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let ids = []
                this.selectRows.map(item => {
                    let isAdopt = (new Date().getFullYear() == new Date(item.billDateStart).getFullYear()) && (new Date().getMonth() >= new Date(item.billDateStart).getMonth())
                    if (isAdopt) {
                        ids.push(item.id)
                    }
                })
                if (ids.length <= 0) return this.$message.warning('所选数据未满足开新账单条件')
                await editbizBillInfoNew({ ids: ids.join(), status: 1 })
                this.$message.success('开新账单成功')
                this.selectRows = []
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        writeNewBill(row) {
            this.$refs.addRefs.init(1, row)
        },
        showDetails(row) {
            this.$refs.addRefs.init(2, row)
        },
        advancePayment (row) {
            this.$refs.advanceRefs.init(row)
        },
        editDataFormat (row, btn) {
            if (row.billDateStart) {
                const isAdopt = (new Date().getMonth() >= new Date(row.billDateStart).getMonth())
                const isYear = (new Date().getFullYear() > new Date(row.billDateStart).getFullYear())
                if (isYear) {
                    if (btn.text === '开新账单' || btn.text === '预缴费用') {
                        return true
                    }
                } else {
                    // 当账单时间是今年时
                    if (new Date(row.billDateStart).getFullYear() === new Date().getFullYear()) {
                        if ((isAdopt && (btn.text === '开新账单' || btn.text === '预缴费用')) || (!isAdopt && btn.text === '查看')) {
                            return true
                        }
                    } else {
                        if (btn.text === '查看') {
                            return true
                        }
                    }
                }
            }
        }
    }
}
</script>

<style >
</style>
