import http from './plugin/ajax'

/*
    菜单管理
*/

// 查询菜单列表
export const listMenu = (data) => http.post('/sys/menu/query', data)

// 新增菜单
export const addMenu = (data) => http.post('/sys/menu', data)
// 修改菜单
export const updateMenu = (data) => http.put('/sys/menu', data)
// 删除菜单
export const delMenu = (data) => http.delete(`/sys/menu/${data}`)

// ****** 字典管理
// 字典列表
export const dictionMangerList = (data) => http.post('/sysDictType/list', data)
// 新增字典
export const addDiction = (data) => http.post('/sysDictType', data)
// 编辑字典
export const editDiction = (data) => http.put('/sysDictType', data)
// 删除字典
export const delDiction = (data) => http.delete('/sysDictType/' + data)

// ****** 字典数据
// 字典列表
export const dictionDataList = (data) => http.post('/sys/dictData/query', data)
// 新增字典
export const addDicData = (data) => http.post('/sys/dictData', data)
// 编辑字典
export const editDicData = (data) => http.put('/sys/dictData', data)
// 删除字典
export const delDicData = (data) => http.delete('/sys/dictData/' + data)

// ****** 参数设置
// 查询 参数列表
export const rqParameterList = (data) => http.post('/sys/config/query', data)
// 查询 参数列表
export const getrqParameterList = (data) => http.get(`/sys/config/key/${data}` )
// 新增 参数
export const addParameter = (data) => http.post('/sys/config', data)
// 编辑 参数
export const editParameter = (data) => http.put('/sys/config', data)
// 删除 参数
export const delParameter = (v) => http.delete('/sys/config', { data: v })
// export const delParameter = (data) => http.delete('/sys/config/'+data)

/* 系统设置--权限管理 */
/* 角色管理 */
// 获取角色
export const getRole = (data) => http.post('/sys/role/query', data)
// 新增角色
export const roleAdd = (data) => http.post('/sys/role', data)
// 编辑角色
export const roleEdit = (data) => http.put('/sys/role', data)
// 状态修改
export const changeRoleStatus = (data) => http.put('/system/role/changeStatus', data)
// 删除角色
export const delRole = (data) => http.delete('/sys/role/' + data)
// 获取某个角色的菜单信息树
export const rqRoleTreeList = (data) => http.post('/sys/menu/roleMenu', data)
// 修改某个角色的菜单权限
export const modifyRoleTree = (data) => http.post('/sys/role/authorization', data)
// 角色绑定用户列表
export const getRelationList = (data) => http.post('/sys/role/relation/list', data)
// 删除角色绑定用户
export const delRelation = (data) => http.delete('/sys/role/relation', { data: data })
// 角色绑定用户
export const addRelation = (data) => http.post('/sys/role/relation', data)
// 修改密码
export const resetPassword = (data) => http.post('/sys/user/resetPassword', data)
//  个人中心 -- 上传图片
export const userPersonUpload = (data) => http.put('/sys/user/headPortrait', data)

// 角色数据权限
export function dataScope (data) {
    return http({
        url: '/system/role/dataScope',
        method: 'put',
        data: data
    })
}
