<template>
    <div>
        <div class="statistics">
            <div class="statisTitle">
                人脸客流统计
            </div>
            <el-divider></el-divider>
            <statistics></statistics>
        </div>
        <div class="statistics" >
            <div class="statisTitle">
                人脸客流同环比
            </div>
            <el-divider></el-divider>
            <!-- <statistics></statistics> -->
            <ring-ratio></ring-ratio>
        </div>

    </div>
</template>
<script>
import Statistics from './components/statistics.vue'
import ringRatio from './components/ringRatio.vue'
export default {
    title: '客流分析',
    name: 'passengerFlow',
    components: { Statistics, ringRatio },
    data() {
        return {

        }
    }
}
</script>
<style less="scss" scoped>
.statistics {
    margin: 15px 15px;
    background-color: #ffff;
}
.statisTitle {
    padding: 10px;
    height: 15px;
}
</style>