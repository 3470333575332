<template>
  <div>
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="show"
      width="40%"
      :title="titleText"
      v-loading="addLoading"
      :element-loading-text="isAdd ? '新增中' : '编辑中'"
    >
      <ja-form
        :rules="rules"
        v-model="rowForm"
        :formArray="rowFormData"
        @reset="show = false"
        @save="saveForm"
        @changeId="changeId"
        v-if="show"
      >
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { addInternalVehicle, updateInternalVehicle } from '@/api'
export default {
  components: {
    JaForm
  },
  inject: ['labelWidth', 'getInit'],
  data() {
    return {
      rules: {
        carNumber: [
          { required: true, message: '请输入车牌号码', trigger: 'blur' },
          {
            validator: function (rule, value, callback) {
              if (/^[\a-\z\A-\Z0-9\u4e00-\u9fe5]+$/.test(value) == false) {
                callback(new Error('请输入中文、数字和英文'))
              } else {
                //校验通过
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        companyId: [
          { required: true, message: '请选择所属公司', trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: '请选择所属部门', trigger: 'blur' }
        ],
        userId: [{ required: true, message: '请选择使用人', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入联系方式', trigger: 'blur' }]
      },
      rowFormData: [
        {
          input_type: 'input',
          label: '车牌号码',
          key: 'carNumber',
          maxlength: 10
        },
        {
          input_type: 'select',
          label: '所属公司',
          key: 'companyId',
          options: [],
          maxlength: 30,
          relevance: {
            sonKey: 'deptId',
            This: this,
            type: 'select',
            rqParam: true, // 必填。而 rqParam 是异步请求，必填字段
            rqParam: {
              rqUrl: '/department',
              rqData: () => {
                return {
                  params: {
                    pageIndex: 1,
                    pageSize: 200,
                    companyId: this.rowForm.companyId
                  },
                  
                }
              },
              labelName: 'deptName',
              valueName: 'id',
              otherKeys: [{ keyName: 'disabled', keyValue: 'disabled' }]
            }
          }
        },
        {
          input_type: 'select',
          label: '所属部门',
          key: 'deptId',
          options: [],
          maxlength: 30,
          relevance: {
            sonKey: 'userId',
            This: this,
            type: 'select',
            rqParam: true, // 必填。而 rqParam 是异步请求，必填字段
            rqParam: {
              rqUrl: '/employee',
              rqData: () => {
                return {
                  params: {
                    pageIndex: 1,
                    pageSize: 200,
                    deptId: this.rowForm.deptId
                  }
                }
              },
              labelName: 'username',
              valueName: 'id'
            }
          }
        },
        {
          input_type: 'select',
          label: '使用人',
          key: 'userId',
          options: [],
          maxlength: 20,
          method: 'changeId'
        },
        {
          input_type: 'input',
          label: '联系方式',
          key: 'mobile',
          maxlength: 20,
          disabled: true,
          placeholder: '按照使用人自动填写'
        }
      ],
      show: false,
      titleText: '新增',
      rowForm: {},
      isAdd: false,
      addLoading: false,
      penson: {}
    }
  },
  mounted() {
    let index = this.rowFormData.findIndex((item) => item.key == 'companyId')
    const paramObj = { params: { pageIndex: 1, pageSize: 200 } }
    this.util.rqOptionsList({
      who: [this.rowFormData[index]],
      rqUrl: '/company',
      rqData: paramObj,
      rqMethod: 'get',
      labelName: 'companyName',
      valueName: 'id',
      otherKeys: [{ keyName: 'disabled', keyValue: 'disabled' }]
    }) // 所属公司
  },
  methods: {
    init(state, row) {
      this.show = true
      if (row && row.companyId) {
        let index = this.rowFormData.findIndex((item) => item.key == 'deptId')
        const paramObj = {
          params: { pageIndex: 1, pageSize: 200, companyId: row.companyId }
        }
        this.util.rqOptionsList({
          who: [this.rowFormData[index]],
          rqUrl: '/department',
          rqData: paramObj,
          rqMethod: 'get',
          labelName: 'deptName',
          valueName: 'id'
        }) // 所属公司
      }
      if (row && row.deptId) {
        let index = this.rowFormData.findIndex((item) => item.key == 'userId')
        const paramObj = {
          params: { pageIndex: 1, pageSize: 200, deptId: row.deptId }
        }
        this.util.rqOptionsList({
          who: [this.rowFormData[index]],
          rqUrl: '/employee',
          rqData: paramObj,
          rqMethod: 'get',
          labelName: 'username',
          valueName: 'id'
        }) // 所属部门
      }
      if (state == 1) {
        this.isAdd = true
        this.titleText = '新增'
        this.rowForm = {}
        this.rowFormData[0].disabled = false
      } else if (state == 2) {
        this.isAdd = false
        this.titleText = '编辑'
        this.rowForm = { ...row }
        this.rowFormData[0].disabled = true
      }
    },
    saveForm() {
      this.addLoading = true
      if (this.isAdd) {
        addInternalVehicle(this.rowForm)
          .then((res) => {
            if (res.code !== 200) return this.$message.error('新增失败')
            this.show = false
            this.addLoading = false
            this.getInit()
          })
          .catch((err) => {
            this.addLoading = false
          })
      } else {
        updateInternalVehicle(this.rowForm)
          .then((res) => {
            if (res.code !== 200) return this.$message.error('编辑失败')
            this.show = false
            this.addLoading = false
            this.getInit()
          })
          .catch((err) => {
            this.addLoading = false
          })
      }
    },
    changeId(item, value) {
      const paramObj = {
        params: { pageIndex: 1, pageSize: 200, id: value }
      }
      this.util
        .rqOptionsList({
          rqUrl: '/employee',
          rqData: paramObj,
          rqMethod: 'get',
          labelName: 'mobile',
          valueName: 'id'
        })
        .then((res) => {
          for (const item of res) {
            if (item.value === value) {
              this.$set(this.rowForm, 'mobile', item.label)
              break
            }
          }
        })
    }
  }
}
</script>