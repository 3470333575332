<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   title="阈值设置"
                   :visible.sync="show"
                   width="30%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowFormData"
                     :formArray="rowForm"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { setThreshold, } from '@/api'
export default {
    inject: ['getInit'],

    data() {
        return {
            rules: {
                configValue: [{ required: true, validator: this.util.formRules.checkNumber({ max: 99, min: 0 }) }],
            },
            rowForm: [
                { input_type: 'input', key: 'configValue', label: '阈值设置', labelWidth: '7vw', col: 24, maxlength: 30, styleWidth: '60%', rightBox: { input_type: 'text', text: '%' } },
            ],
            rowFormData: {},
            show: false,

        }
    },
    methods: {
        init(val) {
            this.show = true
            this.allDisabled = false
            this.rowFormData = { configValue: val ? Number(val) : 30 }
            this.isAdd = false
        },
        saveForm() {
            this.$confirm(`是否确定阈值设置为${this.rowFormData.configValue}%？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await setThreshold({ ...this.rowFormData, configKey: 'device_report' })
                this.show = false
                this.getInit()
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });

        }
    }
}
</script>