<template>
    <div>
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" @close="close" :width="`60%`">
            
            <el-tabs v-model="activeName" @tab-click="handleClick">
            
            <!-- 流水查询部分 -->
            <el-tab-pane label="流水查询" name="first">
            <ja-detail :options="detailOptions" :detailData="detailData" :showTableTitle="false"></ja-detail> 
            <ja-table :conditionData="conditionData" ref="ls"  @getInit="getRunningWater" v-model="filterData" :columns="tableColumns" :loading="loading" :showIndex="true" :tableData="tableData"  :table_MaxHeight="200" :total="total" :valueFormat="formatter" @search="search" @reset="reset" @getRunningWater="getRunningWater"></ja-table>
            </el-tab-pane>

            <!-- 保证金管理部分 -->
            <el-tab-pane label="保证金管理" name="second">
            <ja-detail :options="bondOptions" :detailData="bondData" :showTableTitle="false"></ja-detail> 
            <ja-table :conditionData="bondConditionData" ref="ls" v-model="bondFilterData" :columns="bondTableColumns" :loading="bondLoading" :showIndex="true" :tableData="bondTableData"  :table_MaxHeight="200" :total="bondTotal" :valueFormat="bondFormatter" @search="bondSearch" @reset="bondReset" @getRunningWater="bondInit" @returnBond="returnBond" @returnBondRecord="returnBondRecord" :editDataFormat="bondEditDataFormat"></ja-table>
            </el-tab-pane>

            </el-tabs>
            
            <div class="button_grounp">
                <div class="btn">
                    <el-button @click="showDetail = false">关闭</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- 退还保证金弹窗 -->
        <BondReturnRecord ref="bondReturnRecord" @depositRefund="depositRefund"></BondReturnRecord>

    </div>
</template>
<script>
import table from '@/views/mixins/table'
import { transactionDetails, getBondList, depositRefund, getDepositType, rqPaymentManageList } from '@/api'
import JaTable from '@/components/ja-table'
import BondReturnRecord from './BondReturnRecord'
export default {
    inject:['labelWidth','getInit'],
    // mixins: [table],
    components: {
        JaTable,
        BondReturnRecord
        },
    data () {
        return {
            loading: false,
            activeName: 'first',
            showDetail: false,
            initFileList: [],
            
            // 流水查询
            detailOptions:
                [
                    { label: '驻户名称', key: 'tenantName', col: 22 },
                    { label: '证件号码', col: 22 },
                    { label: '当前余额', key: 'accountBalance', col: 22 }
                ],
            detailData: {},
            conditionData: [
                { input_type: 'date', type: 'daterange', value: 'time', valueFormat: 'yyyy-MM-dd' },
                { input_type: 'select', key: 'type', attribute: { placeholder: '交易类型' }, options: [{ label: '入账', value: '0' }, { label: '划扣', value: '1' }] },
                { input_type: 'input', key: 'remark', attribute: { placeholder: '交易备注' } },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas1, rqUrl: '/account/record/export', rqMethod: 'post' }, }
            ],
            tableColumns: [
                { label: '交易金额', prop: 'transactionAmount' },
                { label: '类型', prop: 'type' },
                { label: '交易备注', prop: 'remark' },
                { label: '交易时间', prop: 'receivedDate', width: 140 },
                { label: '交易余额', prop: 'accountBalance' },
                { label: '流水来源', prop: 'source' },
                { label: '操作人', prop: 'createBy' }
            ],
            tableData: [],
            total: 0,
            filterData: {
                pageIndex: 1,
                pageSize: 10
            },



            // 保证金管理
            bondOptions:
                [
                    { label: '驻户名称', key: 'tenantName', col: 22 },
                    { label: '证件号码', col: 22 },
                    { label: '保证金总额', key: 'depositBalance', col: 22 }
                ],
            bondData: {},
            bondConditionData: [
                { input_type: 'date', type: 'daterange', value: 'time', valueFormat: 'yyyy-MM-dd' },
                { 
                    input_type: 'select',
                    key: 'depositType',
                    attribute: { placeholder: '保证金类型' },
                    options: function () {
                        return getDepositType().then((res) => {
                            if (res.code == 200) {
                                const data = res.data.map((item) => {
                                    return {
                                        label: item.serviceName,
                                        value: item.serviceName
                                    }
                                })
                                return data
                            }
                        })
                    }
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas2, rqUrl: '/account/bzj/export', rqMethod: 'post' }, }
            ],
            bondTableColumns: [
                { label: '保证金类型', prop: 'depositType' },
                { label: '累计金额', prop: 'depositAmount' },
                { label: '收费类型', prop: 'chargeType' },
                { label: '交易时间', prop: 'transactionTime', width: 140 },
                { label: '金额来源', prop: 'source' },
                { label: '操作人', prop: 'updateBy' },
                { label: '状态', prop: 'status' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: { fixed: "right", align: 'center' },
                    list: [
                    { text: '退还', method: 'returnBond' },
                    { text: '退还记录', method: 'returnBondRecord' }
                    ]
                }
            ],
            bondTableData: [],
            bondTotal: 0,
            bondFilterData: {
                pageIndex: 1,
                pageSize: 10
            },
            bondLoading: false

        }
    },
    methods: {
        // 退还保证金
        depositRefund(rqData) {
            depositRefund(rqData).then((res) => {
                if (res.code === 200) {
                    this.$message.success('提交成功')
                    this.$refs.bondReturnRecord.close()
                    // 更新保证金页面的保证金总额字段
                    rqPaymentManageList({status:2,pageIndex: 1,pageSize: 10,tenantCode:this.bondData.tenantCode}).then(res => {
                        this.$set(this.bondData,'depositBalance',res.data.data[0].depositBalance)
                        this.bondInit()
                        this.getInit()
                    })
                }
            })
        },
        // 退还保证金弹窗
        returnBond(row) {
            this.$refs.bondReturnRecord.showDialog(row, true)
        },
        // 退还记录弹窗
        returnBondRecord(row) {
            this.$refs.bondReturnRecord.showDialog(row, false)
        },
        getRunningWater () {
            this.loading = true
            if (this.filterData.tenantCode) {
                // 查询流水列表（未完成）
                transactionDetails(this.filterData).then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.loading = false
                        this.total = res.data.total
                    }
                })
            } else {
                this.loading = false
            }
        },
        bondInit () {
            this.bondLoading = true
            if (this.bondFilterData.tenantCode) {
                // 查询保证金管理列表
                getBondList(this.bondFilterData).then((res) => {
                    if (res.code === 200) {
                        this.bondTableData = res.data.data
                        this.bondLoading = false
                        this.bondTotal = res.data.total
                    }
                })
            }
        },
        rqParmas1() {
            return {
                pageSize: this.filterData.pageSize,
                pageIndex: this.filterData.pageIndex,
                startTime: this.filterData.time ? this.filterData.time[0] + ' 00:00:00' : '',
                endTime: this.filterData.time ? this.filterData.time[1] + ' 23:59:59' : '',
                tenantCode: this.filterData.tenantCode,
                type: this.filterData.type,
                remark: this.filterData.remark
            }
        },
        rqParmas2() {
            return {
                pageSize: this.bondFilterData.pageSize,
                pageIndex: this.bondFilterData.pageIndex,
                startTime: this.bondFilterData.time ? this.bondFilterData.time[0] + ' 00:00:00' : '',
                endTime: this.bondFilterData.time ? this.bondFilterData.time[1] + ' 23:59:59' : '',
                tenantCode: this.bondFilterData.tenantCode,
                depositType: this.bondFilterData.depositType
            }
        },
        showBillFlow (row) {
            this.detailData = {}
            this.detailData = row
            this.bondData = row
            this.filterData.tenantCode = this.detailData.tenantCode
            this.bondFilterData.tenantCode = this.detailData.tenantCode
            
            this.getRunningWater()
            this.showDetail = true
            const objId = (this.detailOptions || []).findIndex((item) => item.label === '证件号码')
            const bondObjId = (this.bondOptions || []).findIndex((item) => item.label === '证件号码')
            if (row.attributeLabel == '个人') {
                this.detailOptions[objId].key = 'idCard'
                this.bondOptions[bondObjId].key = 'idCard'
            } else {
                this.detailOptions[objId].key = 'unifiedSocialCreditCode'
                this.bondOptions[bondObjId].key = 'unifiedSocialCreditCode'
            }
        },
        close () {
            this.filterData = {
                pageIndex: 1,
                pageSize: 10,
                tenantCode: this.detailData.tenantCode
            }
            this.bondFilterData = {
                pageIndex: 1,
                pageSize: 10,
                tenantCode: this.detailData.tenantCode
            }
            this.activeName = 'first'
        },
        reset () {
            this.filterData = {
                pageIndex: 1,
                pageSize: 10,
                tenantCode: this.detailData.tenantCode
            }
            this.getRunningWater()
        },
        bondReset () {
            this.bondFilterData = {
                pageIndex: 1,
                pageSize: 10,
                tenantCode: this.detailData.tenantCode
            }
            this.bondInit()
        },
        search () {
            this.filterData.startTime = this.filterData.time ? this.filterData.time[0] : ''
            this.filterData.endTime = this.filterData.time ? this.filterData.time[1] : ''
            this.getRunningWater()
        },
        bondSearch () {
            this.bondFilterData.startTime = this.bondFilterData.time ? this.bondFilterData.time[0] : ''
            this.bondFilterData.endTime = this.bondFilterData.time ? this.bondFilterData.time[1] : ''
            this.bondInit()
        },
        formatter: function (val, key) {
            if (key === 'type') {
                return ['入账', '划扣'][val || 0]
            }
            return val || ''
        },
        bondFormatter: function (val, key) {
            if (key === 'source') {
                return ['手动划扣', '自动划扣'][val || 0]
            }
            if (key === 'status') {
                return ['正常', '已退还'][val || 0]
            }
            return val || ''
        },
        bondEditDataFormat(row, btn) {
            if (row.status === 0 && btn.text === '退还记录' || row.status === 1 && btn.text === '退还') {
                return false
            } else {
                return true
            }
        },
        // 点击标签页
        handleClick(tab) {
            if(tab.name == 'second'){
                this.bondInit()
            } else {
                this.getRunningWater()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.button_grounp {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    .btn {
        margin-left: 10px;
        margin-right: 10px;
    }
}

/deep/ .el-tabs {
    background-color: #FFF !important;
}

/deep/ .el-tabs__nav-scroll {
    background-color: #FFF !important;
}
</style>
