<template>
  <div>
    <JaTable
      :conditionData="conditionData"
      :columns="chargeBillManagementColumn"
      :tableData="tableData"
      v-model="filterData"
      :total="total"
      :loading="loading"
      @search="search"
      :showIndex="true"
      @getInit="getInit"
      @reset="reset"
      :valueFormat="formatter"
      @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/开票记录导入模版.xlsx', filename: '开票记录导入模版', uploadURL: '/bill/upload'})"
    >
     <!-- 插槽从ja-table衍生出来，数据也是 -->
      <template v-slot:operateSlot="scope">
        <el-button type="text" class="WriteOff" v-show="scope.scope.invoiceStatus !== 1? true: false"  @click="InvoicingEvent(scope)">开票登记</el-button>
        <el-button type="text" class="WriteOff" style="margin: 0 5px" @click="detail(scope)">查看明细</el-button>
      </template>
    </JaTable>
        <!-- 查看明细 -->
    <Detail ref="detail"/>
    <!-- 开票登记 -->
    <InvoicingRegistration ref="invoicing"/>
     <!-- 导出弹窗 -->
    <import-form ref='importForm' @openResult="openResult" />
      <!-- 校验结果 弹窗 -->
    <check-result ref="checkResult" :results="results" />
  </div>
</template>

<script>
import JaTable from '@/components/ja-table'
import { chargeBillManagementColumn } from './chargeBillManagement'
import table from '@/views/mixins/table'
import Detail from './components/Detail'
import importForm from '../common/importForm'
import checkResult from '../common/checkResult'
import InvoicingRegistration from './components/InvoicingRegistration.vue'
import {
  rqBillWriteOffList,
} from '@/api'

export default {
  title: '收费单管理',
  mixins: [table],
  name: 'chargeBillManagement',
  components: {
    JaTable,
    Detail,
    InvoicingRegistration,
    importForm,
    checkResult,
  },
  data () {
    return {
      billCode:'',
      lockId:null,
      conditionData: [
        {
          input_type: 'select',
          key: 'buildingId',
          attribute: { placeholder: '楼栋', size: 'small' },
          options: [],
          styleWidth: '100px',
          method: 'selectFun',
          relevance: {
            sonKey: 'floorId',
            This: this, // 必填。而 rqParam 是异步请求，必填字段
            rqParam: { rqUrl: "/floor/all", rqData: () => { return { params: { buildingId: this.filterData.buildingId } } }, labelName: 'floorName', valueName: 'floorId', resRule: 'data' } //
          }
        },
        { input_type: 'select', key: 'floorId', attribute: { placeholder: '楼层', size: 'small' }, options: [], styleWidth: '100px' },
        {
          input_type: 'select',
          styleWidth: '100px',
          attribute: {
            placeholder: '账单期间',
            size: 'small'
          },
          key: 'month',
          options: [
            {
              label: '2021年',
              value: '2021年'
            },
            {
              label: '2020年',
              value: '2020年'
            },
            {
              label: '2019年',
              value: '2019年'
            },
            {
              label: '不限',
              value: '不限'
            }
          ]
        },
        // {
        //   input_type: 'select',
        //   styleWidth: '100px',
        //   attribute: {
        //     placeholder: '住宅',
        //     size: 'small'
        //   },
        //   key: 'purpose',
        //   options: function () {
        //     return getDictData('room_purpose').then((res) => {
        //       const data = res.data.map((item) => {
        //         return {
        //           label: item.dictLabel,
        //           value: item.dictValue
        //         }
        //       })
        //       return data
        //     })
        //   }
        // },
        {
          input_type: 'input',
          styleWidth: '100px',
          attribute: {
            placeholder: '房间名称',
            size: 'small'
          },
          key: 'roomName'
        },
        {
          input_type: 'select',
          styleWidth: '100px',
          attribute: {
            placeholder: '开票状态',
            size: 'small'
          },
          key: 'invoiceStatus',
          options: [
            {
              label: '未开票',
              value: 0
            },
            {
              label: '已开票',
              value: 1
            }
          ]
        },
        { normalBtn: 'search' },
        { normalBtn: 'reset' },
        { normalBtn: 'import', text: '开票记录导入' },
        { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/bill/exportnew', rqMethod: 'post' } }
      ],
      chargeBillManagementColumn,
      tableData: [],
      total: 0,
      filterData: {
        writeOffStatus: 1,
        // 默认页码
        pageIndex: 1,
        pageSize: 10
      },
      results: null,
    }
  },
  methods: {
    getInit () {
      this.loading = true
      // 查询账单核销列表
      console.log(this.filterData);
      rqBillWriteOffList(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.loading = false
          this.total = res.data.total
        }
      })
    },
     //查看订单
    detail(scope){
      this.$refs.detail.rowDetail(scope.scope)
    },
    formatter (val, key) {
      if (key === 'writeOffStatus') {
        return ['待核销', '已核销 ', '已欠费'][val || 0]
      } else if (key === 'invoiceStatus') {
        return ['未开票', '已开票 '][val || 0]
      } else if (key === 'invoiceDate') {
        return val ? this.$moment(val).format("YYYY-MM-DD") : '-'
      }
      return val || '-'
    },
    reset(){
      this.filterData = {
        writeOffStatus: 1,
        // 默认页码
        pageIndex: 1,
        pageSize: 10
      }
      this.getInit()
    },
    // 开票登记
    InvoicingEvent(scope){
      if(scope.scope.invoiceStatus === 1) return this.$message.success('该单已经开票')
      this.$refs.invoicing.openBillingRegistration(scope.scope.billCode)
    },
    openResult ({ data, uploadFlag,res}) {
      console.log(data,uploadFlag,res);
      this.results = { data, uploadFlag }
      this.$nextTick(() => {
        this.$refs.checkResult.rowDetail()
      })
      }
  }
}
</script>

<style lang="scss" scoped>
.el-tooltip .el-button+.el-button  {
    margin-left: 0;
    }
</style>
