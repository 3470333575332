<template>
    <div>
        <el-dialog :visible.sync="show"
                   :close-on-click-modal="false"
                   :show-close="false"
                   :close-on-press-escape="false"
                   width="70%"
                   title="查看">
            <ja-form :rules="rules"
                     v-if="show"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :btnBox="btnBox"
                     :allDisabled="allDisabled"
                     @showDetails="showDetails"
                     @handleCurrentChange="handleCurrentChange"
                     @writeOff="writeOff"
                     @register="register"
                     @adopt="adopt"
                     @reset="show = false">
                <template #mesContent>
                    <el-tooltip placement="bottom">
                        <div slot="content">
                            计算优惠：如有减免调整后金额，则预计实收合计为调整后金额-优惠金额；
                            <br />
                            不计算优惠：按照调整后应收金额合;
                        </div>
                        <el-button type="text"
                                   icon="el-icon-warning"
                                   size=""></el-button>
                    </el-tooltip>

                </template>
                <template #totle>
                    <span style="margin-right:20px">合计：{{totleList.receivableTotal}}</span>
                    <span>合计实收：{{totleList.writeOffTotal}}</span>
                </template>
                <template #totle2>
                    <span style="margin-right:20px">本期应收合计：{{totleHistoryList.receivableTotalHistory}}</span>
                    <span style="margin-right:20px">合计实收：{{totleHistoryList.writeOffTotalHistory}}</span>
                    <span>欠费总计：{{totleHistoryList.unpaidAmountHistory}}</span>
                </template>
            </ja-form>

        </el-dialog>
    </div>
</template>
<script>
import { getArrearageBillListDerail, showWriteOffDetail,getArrearageBillListHistory } from '@/api'
export default {
    data() {
        return {
            rules: {},
            rowFormData:[],
            //正常配置项
            rowFormDataIndex: [
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', col: 8 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '8vw', col: 8 },
                { input_type: 'select', label: '合计规则', key: 'sumRule', col: 6, disabled: true, options: [{ label: '不计算优惠', value: '0' }, { label: '计算优惠', value: '1' }] },
                { input_type: 'customize', customize: 'mesContent', label: '', col: 2, labelWidth: '10px' },
                {
                    input_type: 'table', col: '24', noSearch: true, showPage: false, key: 'bizBillDetailNewDTOs', isRequired: true, border: true, highlightCurrentRow: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', width: 120 },
                        { label: '收费项目性质', prop: 'natureCharges', width: 120 },
                        { label: '收费项目名称', prop: 'serviceName', width: 120 },
                        { label: '计费周期', prop: 'date', width: 220 },
                        { label: '缴费周期', prop: 'day', width: 120 },
                        { label: '本期应收', prop: 'receivable', width: 120 },
                        { label: '调整后应收', prop: 'adjustedReceivable', width: 120 },
                        { label: '优惠金额', prop: 'coupon', width: 120 },
                        { label: '未收金额', prop: 'uncollected', width: 120 },
                        { label: '抹零后金额', prop: 'zeroAfter', width: 120 },
                        { label: '备注', prop: 'remark', width: 220 },
                        { label: '已核销金额（元）', prop: 'writeOff', width: 150 },
                    ]
                },
                { input_type: 'customize', customize: 'totle', label: '', col: 24, labelWidth: '10px' },
                { input_type: 'title', text: '收费项登记记录', col: 24 },
                {
                    input_type: 'table', col: '24', noSearch: true, showPage: false, key: 'writeOffRecord', isRequired: true, border: true, showIndex: false,
                    columns: [
                        { label: '到账日期', prop: 'writeOffTime' },
                        { label: '到账金额', prop: 'writeOffNumber' },
                        { label: '剩余未缴金额（元）', prop: 'unpaidAmount' },
                        { label: '核销操作人', prop: 'updateBy' },
                    ]
                }
            ],  
            //带有历史欠费的配置项
            rowFormHistory: [
                { input_type: 'title',text:'本期账单',col:24},
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', col: 8 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '8vw', col: 8 },
                { input_type: 'select', label: '合计规则', key: 'sumRule', col: 6, disabled: true, options: [{ label: '不计算优惠', value: '0' }, { label: '计算优惠', value: '1' }] },
                { input_type: 'customize', customize: 'mesContent', label: '', col: 2, labelWidth: '10px' },
                {
                    input_type: 'table', col: '24', noSearch: true, showPage: false, key: 'bizBillDetailNewDTOs', isRequired: true, border: true, highlightCurrentRow: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', width: 120 },
                        { label: '收费项目性质', prop: 'natureCharges', width: 120 },
                        { label: '收费项目名称', prop: 'serviceName', width: 120 },
                        { label: '计费周期', prop: 'date', width: 220 },
                        { label: '缴费周期', prop: 'day', width: 120 },
                        { label: '本期应收', prop: 'receivable', width: 120 },
                        { label: '调整后应收', prop: 'adjustedReceivable', width: 120 },
                        { label: '优惠金额', prop: 'coupon', width: 120 },
                        { label: '未收金额', prop: 'uncollected', width: 120 },
                        { label: '抹零后金额', prop: 'zeroAfter', width: 120 },
                        { label: '备注', prop: 'remark', width: 220 },
                        { label: '已核销金额（元）', prop: 'writeOff', width: 150 },
                    ]
                },
                { input_type: 'customize', customize: 'totle', label: '', col: 24, labelWidth: '10px' },
                { input_type: 'title',text:'历史欠费',col:24},
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', col: 8 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '8vw', col: 8 },
                // { input_type: 'select', label: '合计规则', key: 'sumRule', col: 6, disabled: true, options: [{ label: '不计算优惠', value: '0' }, { label: '计算优惠', value: '1' }] },
                // { input_type: 'customize', customize: 'mesContent', label: '', col: 2, labelWidth: '10px' },
                  {
                    input_type: 'table', col: '24', noSearch: true, showPage: false, key: 'billScheduleHistory', isRequired: true, border: true, highlightCurrentRow: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', width: 120 },
                        { label: '收费项目性质', prop: 'natureCharges', width: 120 },
                        { label: '收费项目名称', prop: 'serviceName', width: 200 },
                        { label: '计费周期', prop: 'date', width: 220 },
                        { label: '缴费周期', prop: 'day', width: 120 },
                        { label: '本期应收', prop: 'receivable', width: 120 },
                        { label: '调整后应收', prop: 'adjustedReceivable', width: 120 },
                        { label: '优惠金额', prop: 'coupon', width: 120 },
                        { label: '未收金额', prop: 'uncollected', width: 120 },
                        { label: '抹零后金额', prop: 'zeroAfter', width: 120 },
                        { label: '已核销金额（元）', prop: 'writeOff', width: 150 },
                        { label:'欠费',prop:'unpaidAmount',width:150},
                    ]
                },
                 { input_type: 'customize', customize: 'totle2', label: '', col: 24, labelWidth: '10px' },
                { input_type: 'title', text: '收费项登记记录', col: 24 },
                {
                    input_type: 'table', col: '24', noSearch: true, showPage: false, key: 'writeOffRecord', isRequired: true, border: true, showIndex: false,
                    columns: [
                        { label: '到账日期', prop: 'writeOffTime' },
                        { label: '到账金额', prop: 'writeOffNumber' },
                        { label: '剩余未缴金额（元）', prop: 'unpaidAmount' },
                        { label: '核销操作人', prop: 'updateBy' },
                    ]
                }
            ],  
            show: false,
            title: '',
            rowForm: {},
            totle: 0,
            btnBox: [
                { label: '取消', method: 'reset', col: 8, limit: '' }
            ],
            totleList: {},
            totleHistoryList: {},

        }
    },
    methods: {
        async init(row) {
            this.rowFormData = this.rowFormDataIndex
            this.show = true
            this.rowForm = {}
            let res = await getArrearageBillListDerail({ billId: row.id, pageIndex: 1, pageSize: 1000 })
            let { receivableTotal, sumRule, writeOffTotal } = res.data['scatteredData']
            this.totleList = { receivableTotal, writeOffTotal }
            res.data['billSchedule'].map(item => {
                if (item.adjustedReceivable == 0) {
                    item.adjustedReceivable = null
                }
                item.date = row.date
            })
            let bizBillDetailNewDTOsArr = res.data['billSchedule']
             // 历史欠费请求
            let Historyres = await getArrearageBillListHistory({ billId: row.id, pageIndex: 1, pageSize: 1000 })
            if(Historyres.data.billSchedule != null){
                  Historyres.data['billSchedule'].map(item => {
                        if (item.adjustedReceivable == 0) {
                            item.adjustedReceivable = null
                        }
                        item.date = item.billDateStart + '~' + item.billDateEnd
                    })
                    // 筛选掉历史欠费为0的数据
                    let billScheduleHistoryArr = Historyres.data['billSchedule'].filter(item =>{
                        if(item.unpaidAmount == 0 || item.unpaidAmount == null){
                            return
                        }else{
                            return item
                        }
                    })
                    // let { receivableTotalHistory,  writeOffTotalHistory ,unpaidAmountHistory} = Historyres.data['scatteredData']
                    this.totleHistoryList = { receivableTotalHistory:0, writeOffTotalHistory:0,unpaidAmountHistory:0 }
                    
                    // 判断是否有历史欠费,有则启用带有历史欠费的配置项
                    if(billScheduleHistoryArr.length > 0){
                        billScheduleHistoryArr.forEach(item=>{
                            this.totleHistoryList.receivableTotalHistory += Number(item.uncollected)
                            this.totleHistoryList.writeOffTotalHistory += Number(item.writeOff)
                            this.totleHistoryList.unpaidAmountHistory += Number(item.unpaidAmount)
                        })
                        this.rowFormData = this.rowFormHistory
                    }
                    console.log('历史欠费',billScheduleHistoryArr);
                    this.totleHistoryList.receivableTotalHistory = this.totleHistoryList.receivableTotalHistory.toFixed(2)
                    this.totleHistoryList.writeOffTotalHistory = this.totleHistoryList.writeOffTotalHistory.toFixed(2)
                    this.totleHistoryList.unpaidAmountHistory = this.totleHistoryList.unpaidAmountHistory.toFixed(2)
                    this.rowForm = { ...row, bizBillDetailNewDTOs: [...bizBillDetailNewDTOsArr],billScheduleHistory:[...billScheduleHistoryArr], sumRule,writeOffRecord:res.data.writeOffRecord, }
            }else{
                this.rowForm = { ...row, bizBillDetailNewDTOs: [...bizBillDetailNewDTOsArr], sumRule,writeOffRecord:res.data.writeOffRecord, }
            }

        },
        async showDetails(row) {
            let res = await showWriteOffDetail(row.id)
            this.$set(this.rowForm, 'writeOffRecord', res.data.writeOffRecord)
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
::v-deep .titlePart {
    padding-left: 15px;
    background: #ebffff;
    width: 100%;
}
::v-deep .el-input-number__increase {
    display: none;
}
::v-deep .el-input-number__decrease {
    display: none;
}
</style>