<template>
  <!--  普通的表单 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="50%" :title="isAdd?'新增':'编辑'">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="preSaveForm  " v-if="showEditAll">
      <template #uploadFile="row">
        <upload-file ref="Upload" :autoUpload="true" :limit="1" accept='.jpeg,.png,.jpg,' :keyItem="row.data" :initFileList="initFileList" :fileSize="2" v-if="showEditAll" />
      </template>
      <template #questionText>
        <div style="text-align:right">已选 {{rowForm.questionList?rowForm.questionList.length:0}} 题</div>
      </template>
    </ja-form>
  </el-dialog>
</template>
<script>
import editorMce from '@/components/editorMce'
import { add_Questionnaire, edit_Questionnaire, rqQuestion } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  components: {
    editorMce
  },
  data() {
    return {
      Config: { height: 300 },
      rowFormData: [
        { input_type: 'input', label: '问卷标题', key: 'questionnaireTitle', col: 22 },
        { input_type: 'customize', customize: 'uploadFile', label: '上传封面', key: 'coverImg', col: 24 },
        { input_type: 'input', label: '说明文字', key: 'description', col: 22 },
        { input_type: 'select', label: '调查对象', key: 'respondent', col: 11, options: [], },
        // ---- 异步
        {
          label: '发起部门', input_type: 'select', key: 'launchDeptId', options: [],
          relevance: {
            sonKey: 'launchEmployeeId', This: this, type: 'select', // 必填。而 rqParam 是异步请求，必填字段
            rqParam: { rqUrl: () => { return '/employee/dept/deptIds' }, rqMethod: 'post', rqData: () => { return [this.rowForm.launchDeptId] }, labelName: 'username', valueName: 'id', resRule: 'data' }, // 
          }
        },
        { label: '发起人', input_type: 'select', key: 'launchEmployeeId', options: [], }, // 要注意，options 必写

        { input_type: 'date', type: 'datetime', label: '发布时间', key: 'releaseTime', col: 11, pickerOptions: { disabledDate: this.disabledDateStart }, format: 'yyyy-MM-dd HH:mm', valueFormat: 'yyyy-MM-dd HH:mm' },
        { input_type: 'date', type: 'datetime', label: '结束时间', key: 'endTime', col: 11, pickerOptions: { disabledDate: this.disabledDateStart }, format: 'yyyy-MM-dd HH:mm', valueFormat: 'yyyy-MM-dd HH:mm' },
        { input_type: 'text2', label: '状态', key: 'statusName', col: 11 },
        { input_type: 'select', label: '问题', key: 'questionCategory', col: 11, options: [], },
        { input_type: 'customize', customize: 'questionText', label: '', key: 'cc', col: 11, },
        { input_type: 'select', labelEmpty: true, key: 'questionList', col: 22, options: [], multiple: true, },
      ],
      rowForm: {},
      rules: {
        questionnaireTitle: [{ required: true, message: '请输入问卷标题', trigger: 'blur' }],
        respondent: [{ required: true, message: '请选择调查对象', trigger: 'change' }],
        launchDeptId: [{ required: true, message: '请选择发起部门', trigger: 'change' }],
        launchEmployeeId: [{ required: true, message: '请选择发起人', trigger: 'change' }],
        questionCategory: [{ required: true, message: '请选择问题类型', trigger: 'change' }],
        questionList: [{ message: '请选择问题', trigger: 'change', validator: this.validatorQuestionList }],
        releaseTime: [{ required: true, trigger: 'change', validator: this.validatorStartTime }],
        endTime: [{ required: true, trigger: 'change', validator: this.validatorEndTime }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {},
      initFileList: []
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
    this.util.rqOptionsList({ who: [this.rowFormData[this.indexObj.launchDeptId]], rqUrl: "/department", rqData: { params: { pageIndex: 1, pageSize: 200 } }, rqMethod: 'get', labelName: 'deptName', valueName: 'id' })
    this.util.rqOptionsList({ who: [this.rowFormData[this.indexObj.questionCategory]], rqUrl: "/sys/dictData/question_category", rqMethod: 'get', resRule: 'data' })
  },
  watch: {
    'rowForm.releaseTime': function () {
      if (this.$refs.jaForm) {
        this.$refs.jaForm.$refs.formbox.validateField('releaseTime');
        if (this.rowForm.endTime) {
          this.$refs.jaForm.$refs.formbox.validateField('endTime');
        }
      }
    },
    'rowForm.endTime': function () {
      if (this.$refs.jaForm) {
        this.$refs.jaForm.$refs.formbox.validateField('endTime');
      }
    },
    'rowForm.questionCategory': {
      deep: true,
      handler: function (val) {
        let arr = this.rowFormData[this.indexObj.questionList].options
        let oldOptions = []
        arr.forEach(item => {
          let isExist = (this.rowForm.questionList || []).find(v => v == item.value)
          if (isExist) {
            oldOptions.push(item)
          }
        })
        this.util.rqOptionsList({ httpFun: rqQuestion, rqData: { pageIndex: 1, pageSize: 200, questionCategory: val || '' }, rqMethod: 'post', labelName: 'questionDescription', valueName: 'id', resRule: 'data.data', isReturnAll: true }).then(res => {
          oldOptions.forEach(item => {
            let index = res.findIndex(v => v.id === item.value)
            if (index > -1) {
              res.splice(index, 1)
            }
          })
          let res2 = res.map(item => {
            return { ...item, isNew: true }
          })
          let list = [...oldOptions, ...res2]
          this.rowFormData[this.indexObj.questionList].options = [...list]
        })
      }

    },
  },
  methods: {
    append() {
      this.isAdd = true
      this.rowForm = { questionList: [], statusName: '待发布' }
      this.initFileList = []
      this.showEditAll = true
      this.util.clearSelectOptions(this.rowFormData) //  这个 要加，清除关联的下拉列表
      this.$nextTick(() => {
        this.$refs.jaForm.$refs.formbox.clearValidate()
      })
    },
    rowEdit(row) {
      this.$set(this.rowForm, 'questionCategory', '')
      this.isAdd = false
      this.titleText = '编辑'
      let imgArr = row.coverImg ? [row.coverImg] : ''
      if (imgArr.length > 0) {
        this.initFileList = this.util.backImgVideoObj(imgArr)
      } else {
        this.initFileList = []
      }
      let questionList = row.pcsqq.map(v => v.questionId)
      this.rowFormData[this.indexObj.questionList].options = [...this.dealOptions({ optionsArr: [...row.pcsqq], labelName: 'questionDescription', valueName: 'questionId', isReturnAll: true })]
      this.rowForm = { ...row, pcsqq: [], questionList }
      this.showEditAll = true
      this.$nextTick(() => {
        this.util.setOptionsInit({ arr: this.rowFormData, This: this, obj: this.rowForm, ref: 'jaForm' })
        this.$refs.jaForm.$refs.formbox.clearValidate()
      })
    },
    preSaveForm() {
      let fileList = this.$refs.Upload.fileList
      if (fileList.length == 0) {
        this.$set(this.rowForm, 'coverImg', '')
        this.saveForm()
      } else {
        let isOk = fileList[0].status == 'success'
        if (isOk) {
          this.$set(this.rowForm, 'coverImg', fileList[0].response.data)
          this.saveForm()
        } else {
          this.$message.warning("存在文件尚未完成上传")
          return
        }
      }
    },
    saveForm() {
      this.loadingForm = true
      let rqMethod = this.isAdd ? add_Questionnaire : edit_Questionnaire
      let Obj = { ...this.rowForm, pcsqq: [] }
      this.rowFormData[this.indexObj.questionList].options.forEach(item => {
        let isExist = Obj.questionList.find(v => v === item.value)
        if (isExist) {
          if (this.isAdd) {
            Obj.pcsqq.push({ ...item, questionId: item.id })
          } else {
            let oldItem = { ...item }
            if (item.isNew) {
              delete item.id
              item.pcsqo.map(value => {
                delete value.id
              })
            }
            Obj.pcsqq.push({ ...item, questionId: oldItem.value, questionnaireId: this.rowForm.id })
          }
        }
      })
      rqMethod(Obj).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },
    // 开始时间禁用
    disabledDateStart(val) {
      let now = (new Date()).getTime()
      let time = val.getTime()
      let oneDay = 24 * 3600 * 1000
      return time < now - oneDay
    },
    // 校验开始时间
    validatorStartTime(rule, value, callback) {
      if (value === '' || !value) {
        callback(new Error('请完善发布时间'));
      } else {
        let now = new Date().getTime()
        let minutes_3 = 3 * 60 * 1000
        let time = new Date(value).getTime()
        if (!(time > (now + minutes_3))) {
          callback(new Error('请选择3分钟之后的时间'))
        } else {
          callback()
        }
      }
    },
    validatorEndTime(rule, value, callback) {
      if (value === '' || !value) {
        callback(new Error('请完善结束时间'));
      } else {
        let releaseTime = new Date(this.rowForm.releaseTime).getTime()
        let time = new Date(value).getTime()
        if (!(time > releaseTime)) {
          callback(new Error('结束时间要大于发布时间'))
        } else {
          callback()
        }
      }
    },
    // 校验问题列表
    validatorQuestionList(rule, value, callback) {
      if (!value || value.length == 0) {
        callback(new Error('请选择问题'));
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
ul.main {
  margin-top: 10px;
  li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .ipt {
      width: 100%;
    }
    .iconBox {
      flex-shrink: 0;
      font-size: 23px;
      margin-left: 10px;
      color: #606266;
      &:hover {
        cursor: pointer;
        color: #409eff;
      }
    }
  }
}
</style>