<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="详情" :width="`45%`"  v-if="showDetail">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='10' :loadingForm="loadingForm">
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import { rqInspectionItem } from '@/api'
export default {
  data() {
    return {
      showDetail: false,
      loadingForm: false,
      detailOptions_base: [
        { key: 'planName', label: '计划名称', },
        { key: 'deviceCategoryName', label: '设备类型', },
        { key: 'deviceNames', label: '巡检设备', col: 22 },
      ],
      // 周期
      detailOptions_0: [
        { key: 'planStartTime', label: '计划开始日期', },
        { key: 'planEndTime', label: '计划结束日期', },
        { key: 'executionFrequencyValue', label: '执行频率', },
        { key: 'warnFlagValue', label: '是否提醒', },
        { key: 'advanceCreateTime', label: '工单创建提前时间', rightText: '分钟' },
        { key: 'enablePlanValue', label: '启用状态', },
      ],
      // 临时
      detailOptions_1: [
        { key: 'temporaryStartDate', label: '计划开始日期', },
        { key: 'temporaryStartTime', label: '计划开始时间', },
      ],
      detailOptions: [],
      detailData: {},
      initFileList: []
    }
  },
  methods: {
    async rowDetail(row) {
      this.detailData = {}
      this.showDetail = true
      this.loadingForm = true
      let res = await rqInspectionItem(row.id)
      this.detailOptions = res.data.planType == 0 ? [...this.detailOptions_base , ...this.detailOptions_0] :[...this.detailOptions_base , ...this.detailOptions_1]
      this.detailData = res.data
      this.loadingForm = false
    },
  }
}
</script>