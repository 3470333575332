<template>
    <div>
        <el-dialog v-dialogDrag :close-on-click-modal="false" :title="title" :visible.sync="show" width="30%" @close="close" :before-close="close">
            <ja-form :rules="rules"  v-model="rowFormData" :formArray="rowForm" @reset="close" @save="saveForm" >
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { batchDeletionData } from '../billManage'
export default {
    components:{
        JaForm,
    },
    data(){
        return{
            rowForm: batchDeletionData,
            rowFormData: {},
            rules:{},
            title: '批量删除'
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    methods:{
         /** 保存表单 */
        saveForm () {
            this.$emit('deleteSaveFrom',this.rowFormData,this)
        },
        /** 关闭弹出框 */
        close () {
            this.$emit('deleteCloseForm', false)
        }
    }
}
</script>
<style lang="scss" scoped>
    /deep/ .input_text{
        margin-left: 10px;
    }
  
</style>