import http from './plugin/ajax'
export * from './sysSetting'
export * from './login'
export * from './basicInfo'
export * from './device'
export * from './house'
export * from './occupantManage'
export * from './chargeConfig'
export * from './obviousCharge'
export * from './contractManage'
export * from './subsystem'
export * from './propertyServices'
export * from './eliminationChart'
export * from './parkingManage'
export * from './smartSecurity'
export * from './passModule'
export * from './financeModule'
export * from './energyModule'
export * from './cockpit'
export * from './workOrderManage'
export * from './customerManagement'
export * from './material'
export * from './renovation'
export * from './collectFees'
export * from './contractChange'
export * from './msgCenter'
export * from './scan'

// 获取左侧菜单栏的接口
export const rqReftMenu = (data) => http.post('/auth/getRouters', data)
// 个人中心账号信息获取
export const getPersonalmess = (data) => http.post('/auth/user/currentUser')
// 是否需要验证码
export const rqIsNeedCode = (data) => http.get('/sys/config/key/login_validate_code', data)

// 导出execl 表格 公共接口
export const outPutCommonExcell = (v) => http[v.method](`${v.url}`, v.method === 'get' ? { params: v.data } : v.data, { responseType: 'blob' })

// 查询通用配置列表
export const queryAll = (data1, data2) => http.post('/query/' + data1, data2)
