<template>
    <div>
        <el-container>
            <left-tree />
            <mian-video />
        </el-container>
    </div>
</template>
<script>
import LeftTree from './components/LeftTree.vue'
import MianVideo from './components/MianVideo.vue'
export default {
    title: '实时画面MP4',
    name: 'liveScreenMP4',
    components: {
        LeftTree,
        MianVideo
    },
}
</script>
<style  scoped>
.el-container {
    margin: 10px 0;
}
</style>
