<template>
    <div class="allwh">
        <ja-table
            ref="tst"
            v-model="filterData"
            :conditionData="conditionData"
            :columns="columns"
            :tableData="tableData"
            :total="total"
            :loading="loading"
            :showIndex="false"
            :valueFormat="formatter"
            :editDataFormat="editDataFormat"
            @getInit="getInit"
            @search="search"
            @reset="reset"
            @changeDetail="changeDetail"
            @examine="examine"
            @rowDetail="rowDetail"
            @setBond="setBond"
        >
        </ja-table>

        <!-- 详情 -->
        <detail ref="detail" />
        <!-- 审核 -->
        <examine ref="examine" />
        <!-- 变更内容 -->
        <changeDetail ref="changeDetail" />
        <set-bond ref="setBondRef"></set-bond>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import Detail from './childComp/details.vue'
import Examine from './childComp/Examine.vue'
import ChangeDetail from './childComp/ChangeDetail.vue'
import { getContractList } from '@/api'
import SetBond from "./childComp/setBond.vue"

export default {
    title: '合同变更审核',
    mixins: [table],
    name: 'index',
    components: {
        SetBond,
        ChangeDetail,
        Examine,
        Detail
    },
    data() {
        return {
            conditionData: [
                {
                    input_type: 'input',
                    key: 'contractCode',
                    attribute: { placeholder: '合同编号', size: 'small' }
                },
                {
                    input_type: 'input',
                    key: 'contractName',
                    attribute: { placeholder: '合同名称', size: 'small' }
                },
                {
                    input_type: 'input',
                    key: 'tenantName',
                    attribute: { placeholder: '驻户名称', size: 'small' }
                },
                { input_type: 'select', key: 'contractType', attribute: { placeholder: '合同类型', size: 'small' }, options: [] },
                {
                    //TODO 筛选未定义字段
                    input_type: 'date',
                    value: 'date',
                    type: 'monthrange',
                },

                { normalBtn: 'search' },
                { normalBtn: 'reset' }
            ],
            columns: [
                { label: '合同编号', prop: 'contractCode' },
                { label: '合同名称', prop: 'contractName' },
                { label: '合同类型', prop: 'contractTypeName' },
                { label: '租期', prop: 'leaseTerm', width: 150 },
                { label: '计租开始日期', prop: 'billingTime' },
                { label: '驻户名称', prop: 'tenantName' },
                { label: '驻户租赁单元', prop: 'roomInfo' },
                { label: '审批状态', prop: 'status' },

                {
                    label: '操作',
                    input_type: 'button',
                    width: 140,
                    attribute: {
                        fixed: 'right',
                        showOverflowTooltip: false,
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'rowDetail', limit: 'contract::changes::details' },
                        { text: '审批', method: 'examine', limit: 'contract::changes::audit' },
                        { text: '查看保证金', method: 'setBond', limit: '' }
                    ]
                }
            ],
            tableData: [],
            filterData: {}
        }
    },
    mounted() {
        let index = this.conditionData.findIndex(
            (item) => item.key == 'contractType'
        )
        console.log(index);
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqUrl: '/sys/dictData/contract_type',
            resRule: 'data'
        })
    },
    methods: {
        getInit() {
            let rqData = this.rqParmas()
            getContractList(rqData).then((res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        rqParmas() {
            return {
                pageSize: this.filterData.pageSize,
                pageIndex: this.filterData.pageIndex,
                status: this.filterData.status,
                contractCode: this.filterData.contractCode || '',
                tenantName: this.filterData.tenantName || '',
                tenantCode: this.filterData.tenantCode || '',
                contractName: this.filterData.contractName || '',
                startTime: this.filterData.date ? this.filterData.date[0] : '',
                endTime: this.filterData.date ? this.filterData.date[1] : '',
                queryFlag: 1,
                contractType: this.filterData.contractType || ''
            }
        },
        examine(row) {
            row.roomInfo.map((e) => {
                e.building = e.buildingName
                e.floor = e.floorName
                e.category = e.roomCategoryName
            })
            this.$refs.examine.rowEdit(row)
        },
        changeDetail(row) {
            this.$refs.changeDetail.append(row)
        },
        rowDetail(row) {
            row.roomInfo.map((e) => {
                e.building = e.buildingName
                e.floor = e.floorName
                e.category = e.roomCategoryName
            })
            this.$refs.detail.rowEdit(row)
        },
        formatter: function (val, key) {
            if (key === 'status') {
                return ['待提交', '待审核', '已通过', '已驳回', '失效'][val || 0]
            }
            if (key === 'roomInfo') {
                if (val && val.length > 0) {
                    let text = val.map((item) => item.roomName).join('，')
                    return text || ''
                } else {
                    return ''
                }
            }
            return val || ''
        },
        editDataFormat(row, btn) {
            if ((row.status == 0 && btn.text == '通过/驳回') || (row.status == 1 && btn.text == '查看') || (row.status == 2 && btn.text == '通过/驳回') || (row.status == 3 && btn.text == '通过/驳回') || (row.status == 4 && btn.text == '通过/驳回')) {
                return false
            } else {
                return true
            }
        },
        setBond (row) {
            this.$refs.setBondRef.init(row)
        }
    }
}
</script>
