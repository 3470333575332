<template>
    <!-- 导入表格 -->
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="showEditAll"
                   title="导入表格"
                   :width="`40%`"
                   v-if="showEditAll"
                   class="elDialog">
            <ja-form ref="jaForm"
                     :rules="rules"
                     :label-width="labelWidth"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     @reset="showEditAll = false"
                     :noButton="true"
                    >
                <template #uplpadExcell='row'>
                    <div class="el-upload__tip">只能上传.xlsx, .xls 格式的文件，且不超过1MB。<span @click="downLoadTemp">下载模板</span></div>
                    <upload-file ref="Upload"
                                 :autoUpload="true"
                                 :rqData="rowForm"
                                 @uploadFinish="uploadFinish"
                                 :listType="'text'"
                                 :action="$uploadUrl+upLoadURL"
                                 :accept="'.xlsx, .xls'"
                                 :limit="1"
                                 :keyItem="row.data"
                                 :fileSize="1"
                                 />
                </template>
            </ja-form>
        </el-dialog>

    </div>
</template>
<script> 

export default {
    inject: ['labelWidth', 'getInit'],
    components: {

    },
    data() {
        return {
            rowFormData: [
                { input_type: 'customize', customize: 'uplpadExcell', label: '', key: 'attachs', col: 24, hide: false },
                // { input_type: 'radio', label: '如有重复数据', key: 'uploadFlag', options: [{ label: '覆盖旧数据', value: true }, { label: '丢弃新数据', value: false }], col: 24, labelWidth: 'initial', method: 'radioChange' }
            ],
            rules: {},
            rowForm: {},
            showEditAll: false, // 显示新增/编辑弹窗
            isOk: false,
            headers: {},
            downLoadURL: '',
            downLoadName: '',
            upLoadURL: ''
        }
    },
    created() { },
    methods: {
        // 打开导入的模板
        importForm(row) {
            this.rowForm = {}
            this.upLoadURL = row.uploadURL
            this.downLoadURL = row.url
            this.downLoadName = row.filename
            this.isAdd = true
            this.titleText = '新增'
            this.isOk = false
            this.results = null
            this.showEditAll = true
        },
        // 下载模板
        downLoadTemp() {
            console.log(this.downLoadURL, this.downLoadName);
            this.util.exportConfig(this.downLoadURL, this.downLoadName)
        },
        uploadFinish(fileList, keyItem, response) {
            if (response.code == 200) {
                this.$emit('openResult', { data: response.data, uploadFlag: this.rowForm.uploadFlag ? '覆盖旧数据' : '丢弃新数据' })
                this.showEditAll = false
                this.getInit()
            } else {
                this.$message.warning(response.msg || '提交失败')
            }
            // this.saveForm()
        },
        saveForm() {
            // this.$message.success('上传完成')
            // this.showEditAll = false
            // this.getInit()
        }

    }
}
</script>
<style lang="scss" scoped>
.elDialog /deep/ .el-dialog__body {
    padding: 5px 20px;
}
.el-upload__tip {
    font-size: 16px;
    margin-bottom: 10px;
    > span {
        color: #0083ee;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 10px;
        &:hover {
            opacity: 0.8;
        }
    }
}
</style>
