<template>
    <div>
        <el-aside>
            <div class="buttonList">
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addGroUp" v-if="isAuth('videoTour::leftList:addGroUp')">新增组</el-button>
                <el-button type="primary" size="mini" @click="setIntervals" v-if="isAuth('videoTour::leftList:setIntervals')">间隔时间设置</el-button>
            </div>
            <table-new :showPage="false" @handRowClick="handRowClick" highlightCurrentRow noSearch :showIndex="false" :columns="columns" :tableData="tableData" :loading="loading" @getInit="getInit">
                <template #tabelIco="scope">
                    <i class="el-icon-edit" @click.stop="editGroUp(scope.row)" v-if="isAuth('videoTour::leftList:editGroUp')"></i>
                    <i class="el-icon-delete" @click.stop="deleteGroUp(scope.row)" v-if="isAuth('videoTour::leftList:deleteGroUp')"></i>
                </template>
            </table-new>
        </el-aside>

        <add-edit-group ref="groUp" :maxOrder="maxOrder"></add-edit-group>

        <set-intervals ref="setIntervals"></set-intervals>
    </div>

</template>
<script>
import AddEditGroup from './AddEditGroup.vue'
import setIntervals from './SetIntervals.vue'
import TableNew from './tabelNew'
import table from '@/views/mixins/table'
import { patrolTeamList, deletePatrolTeam } from '@/api'
export default {
    name: '巡更组列表',
    components: {
        AddEditGroup,
        setIntervals,
        TableNew,
    },
    mixins: [table,],
    data () {
        return {
            columns: [
                { label: '组名', prop: 'groupName', width: '50px' },
                { label: '顺序', prop: 'orderNum', width: '50px' },
                {
                    label: '操作', slotName: 'tabelIco'
                },
            ],
            tableData: [],
            //计数
            currentRow: 0,
            //定时器
            timeoutObj: null,
            tableDataLength: null,
            loading: false,
            switchValue: null,
            maxOrder: null
        }
    },
    mounted () {
        //监听巡航开关
        this.$bus.$on('getSwitchValue', (val, time) => {
            this.switchValue = val
            if (val) {
                this.setTime(time)
            } else {
                this.clearTime()
            }
        })
    },
    methods: {
        getInit () {
            this.loading = true
            patrolTeamList(11).then(res => {
                if (res.code !== 200) return this.$message.error('巡更组查询失败')
                this.tableData = res.data
                this.maxOrder = res.data.length + 1
                // this.tableData.map(e => {
                //     if (e.deviceOrderList) {
                //         e.deviceOrderList.map((a, v) => {
                //             if (v % 2 === 0) {
                //                 a.url = 'ws://10.19.147.22:559/EUrl/q2jQie4'
                //             }
                //         })
                //     }

                // })
                this.tableDataLength = this.tableData.length - 1
                this.loading = false
                this.$bus.$emit('currentRowEvent', this.currentRow)
                this.$bus.$emit('setvideoStream', this.tableData[this.currentRow] ? this.tableData[this.currentRow].deviceOrderList : [])
            })
        },
        //打开巡航  TODO
        setTime (time) {
            console.log(time);
            this.timeoutObj = setInterval(() => {
                if (this.currentRow < this.tableDataLength) {
                    this.currentRow++
                    this.$bus.$emit('currentRowEvent', this.currentRow)
                } else {
                    this.currentRow = 0
                    this.$bus.$emit('currentRowEvent', this.currentRow)
                }
                this.$bus.$emit('setvideoStream', this.tableData[this.currentRow].deviceOrderList)
            }, time);
        },
        buttonClick () {
            this.currentRow = 2
            console.log(this.currentRow);
        },
        //关闭巡航  TODO
        clearTime () {
            if (this.timeoutObj) {
                clearInterval(this.timeoutObj)
                this.timeoutObj = null
                this.switchValue = false
                this.$bus.$emit('closePatrol', false)
            }
        },
        //新增组
        addGroUp () {
            this.clearTime()
            this.$refs.groUp.init(true)
        },
        //编辑组
        editGroUp (row) {
            this.clearTime()
            this.$refs.groUp.init(false, row)
        },
        //删除组
        deleteGroUp (item) {
            this.clearTime()

            this.$confirm('此操作会改巡更组, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deletePatrolTeam(item.id).then(res => {
                    if (res.code !== 200) return this.$message.error('删除失败')
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        //间隔时间设置
        setIntervals () {
            this.clearTime()
            this.$refs.setIntervals.init()
        },
        handRowClick (row) {
            let length = this.tableData.findIndex(e => e.id === row.id)
            if (!this.switchValue) {
                this.currentRow = length
                this.$bus.$emit('setvideoStream', row.deviceOrderList)
            }

        },

    },
    beforeDestroy () {
        this.$bus.$off('getSwitchValue')
    }


}
</script>
<style scoped>
.el-aside {
    width: 300px !important;
    position: relative;
    margin: 0 10px;
    height: 83vh;
    background-color: #fff;
    padding: 10px 5px;
}
.buttonList {
    display: flex;
    justify-content: space-between;
}
.el-icon-delete,
.el-icon-edit:hover {
    cursor: pointer;
}
.el-icon-delete,
.el-icon-edit {
    color: #409eff;
    margin: 0 5px;
}
</style>    