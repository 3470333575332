<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @showResident="showResident"
                  @editCustom="editCustom"
                  @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/客户列表导入模板.xlsx', filename: '客户列表导入模板', uploadURL: '/cmClientInfo/upload'})"> </ja-table>
        <add-edit ref="addEdit"></add-edit>

        <import-form ref='importForm'
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
        <show-detail ref="detailRef"></show-detail>
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import importForm from './common/importForm.vue'
import { getCustomer } from '@/api'
import checkResult from './common/checkResult.vue'
import AddEdit from './components/AddEdit.vue'
import ShowDetail from './components/showDetail.vue'
export default {
    title: '客户列表',
    name: 'customerList',
    mixins: [table],
    components: {
        importForm,
        checkResult,
        AddEdit,
        ShowDetail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'contacts', attribute: { placeholder: '联系人', size: 'small', } },
                { input_type: 'input', key: 'customerContact', attribute: { placeholder: '电话号码', size: 'small', } },
                { input_type: 'input', key: 'referrerName', attribute: { placeholder: '推荐人', size: 'small', } },
                { input_type: 'input', key: 'clientCompany', attribute: { placeholder: '公司名称', size: 'small', } },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '新增', limit: 'customerManagement::customerList::add' },
                // { normalBtn: 'import', text: '导入', limit: 'customerManagement::customerList::import' }
            ],
            columns: [
                { label: '联系人', prop: 'contacts' },
                { label: '电话号码', prop: 'customerContact' },
                { label: '公司名称', prop: 'clientCompany' },
                { label: '推荐人', prop: 'referrerName' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: '150px',
                    list: [
                        { text: '查看', method: 'showResident', limit: 'customerManagement::customerList::show' },
                        { text: '编辑', method: 'editCustom', limit: 'customerManagement::customerList::edit' } //
                    ]
                }
            ],
            tableData: [],
            loading: false,
            results: null,
        }
    },
    mounted() {
        let index = this.conditionData.findIndex(e => e.key === 'customerSource')
        this.util.rqOptionsList({
            who: this.conditionData[index],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'customer_source' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        getInit() {
            getCustomer(this.filterData).then(res => {
                this.tableData = res.data.data
                this.loading = false
                this.total = res.data.total
            })
        },
        append() {
            this.$refs.addEdit.init('新增');
        },
        showResident(row) {
            this.$refs.addEdit.showResident(row,'编辑');
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
        /**编辑 */
        editCustom(row){
            this.$refs.addEdit.edit(row,'编辑');
        }

    }
}
</script>