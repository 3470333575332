<template>
    <div>
        <ja-table :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :pageSizes="pageSizes"
                  v-model="filterData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @receipt="receipt"
                  showSummary
                  :chooseArr="['合计:','本期合计应收（元）','本期实际应收（元）','本期合计实收（元）','未缴金额（元）']"
                   />

        <receipt ref="receiptRefs"></receipt>
        <details-list ref="detailsListRefs"></details-list>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import Receipt from '../components/receipt'
import DetailsList from '../components/detailsList.vue'
import { getBillList } from '@/api'
export default {
    title: '账单核销-待核销',
    mixins: [table],
    name: 'toBeWriteOff',
    components: {
        Receipt,
        DetailsList
    },
    props: {
        writeOffStatus:{
            type:String,
            default:''
        }
    },
    data () {
        return {
            pageSizes: [10, 30, 50, 100, 200],
            conditionData: [
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '租赁单元名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '计费周期开始时间', endPlaceholder: '计费周期结束时间' },
                { input_type: 'date', type: 'month', value: 'billDate', placeholder: '账单日期' },
                { input_type: 'date', type: 'daterange', value: 'update', startPlaceholder: '登记开始时间', endPlaceholder: '登记结束时间' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/bizBillInfoNew/exportA', rqMethod: 'post' }, limit: 'contractFile::billWriteOff::export' }
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', width: 200 },
                { label: '租赁单元名称', prop: 'roomName', width: 200 },
                { label: '计费周期', prop: 'date', width: 200 },
                { label: '账单时间', prop: 'billDate', width: 200 },
                { label: '本期合计应收（元）', prop: 'amountReceivable', width: 200 },
                { label: '本期实际应收（元）', prop: 'actualReceivable', width: 200 },
                { label: '本期合计实收（元）', prop: 'amountWriteOff', width: 200 },
                { label: '未缴金额（元）', prop: 'amountUnpaid', width: 200 },
                { label: '最后登记时间', prop: 'updateTime', width: 200 },
                { label: '状态', prop: 'writeOffStatusName', width: 200 },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '到账登记', method: 'receipt', limit: 'contractFile::billWriteOff::receipt' }

                    ]
                }
            ],
            tableData: [],
            total: 0
        }
    },
    methods: {
        getInit () {
            this.loading = true
            getBillList(this.rqParmas()).then(res => {
                res.data.data.map(item => {
                    item.date = `${item.billDateStart}~${item.billDateEnd}`
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        receipt (row) {
            if(row.writeOffStatusName == '待核销' || row.writeOffStatusName == '部分核销'){
                this.$refs.receiptRefs.init(1, row)
            }else{
                this.$refs.detailsListRefs.init(row)
            }
        },
        rqParmas () {
            const obj = { ...this.filterData, approvalStatus: 3, writeOffStatus: this.writeOffStatus }
            if (obj.date) {
                obj.billDateStart = obj.date[0]
                obj.billDateEnd = obj.date[1]
                delete obj.date
            }
            if (obj.update) {
                obj.updateTimeStart = obj.update[0]
                obj.updateTimeEnd = obj.update[1]
                delete obj.update
            }
            return obj
        }
    }
}
</script>
