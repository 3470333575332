<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   title="确认单元"
                   :visible.sync="show"
                   width="30%"
                   v-loading="loading">
            <ja-form :rules="rules"
                     v-model="rowFormData"
                     :formArray="rowForm"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import { addRentalUnit, editRentalUnit } from '@/api'
export default {
    name: 'EditOccupant',
    components: {
        JaForm
    },
    data() {
        return {
            rowForm: [
                { col: 24, input_type: 'input', label: '租赁单元名称', key: 'roomName', labelWidth: "140px", maxlength: 30, styleWidth: "150px" },
                { col: 24, input_type: 'select', label: '租赁单元类型', key: 'roomType', options: [], maxlength: 30,labelWidth: "140px",styleWidth: "150px" },
                { col: 24, input_type: 'input', label: '套内总体使用面积', key: 'totalArea', labelWidth: "140px", maxlength: 30, styleWidth: "150px" }
            ],
            rowFormData: {},
            rules: {
                roomName: [{ required: true, trigger: 'blur' }],
                totalArea: [{ required: true, trigger: 'blur', pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,message:'必须输入数字,有小数要保留2位' }],
                roomType: [{ required: true, trigger: 'blur',message:'租赁单元类型不能为空'}],
            },
            show: false,
            loading: false,
            isAdd: false
        }
    },
    mounted() {
        let index = this.rowForm.findIndex(
            (item) => item.key == 'roomType'
        )
        this.util.rqOptionsList({
            who: [this.rowForm[index]],
            rqData:{dictType: "room_type",pageIndex: 1,pageSize: 10},
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        init(item, isAdd) {
            this.isAdd = isAdd
            console.log(item);
            this.show = true
            this.rowFormData = { ...item }
        },
        /** 保存表单 */
        saveForm() {
            let obj = { ...this.rowFormData }
            this.loading = true
            if (this.isAdd) {
                addRentalUnit(obj).then(() => {
                    this.loading = false
                    this.show = false
                    this.$bus.$emit("addUnitResut")
                }).catch(err => {
                    this.loading = false
                })
            } else {
                editRentalUnit(obj).then(() => {
                    this.loading = false
                    this.show = false
                    this.$bus.$emit("addUnitResut")
                }).catch(err => {
                    this.loading = false
                })
            }

        },

    }
}
</script>

<style scoped>
</style>
