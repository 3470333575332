export const myMinxin = {
    data() {
        return {
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'carNumber', attribute: { placeholder: '车牌号码', size: 'small' } },
                { input_type: 'select', key: 'status', attribute: { placeholder: '全部状态', size: 'small' }, options: [] },
                { input_type: 'select', key: 'serviceType', attribute: { placeholder: '全部服务类型', size: 'small' }, options: [], hide: true },
                { input_type: 'text', text: '车辆进场时间', styleWidth: '88px' },
                { input_type: 'date', value: 'data1', type: 'datetimerange' },
                { input_type: 'text', text: '车辆离场时间', styleWidth: '88px' },
                { input_type: 'date', value: 'data2', type: 'datetimerange' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
            ],
            columns: [
                // 表头数据
                { label: '车牌号码', prop: 'carNumber' },
                { label: '车辆入场闸机', prop: 'inequipcode' },
                { label: '车辆离场闸机', prop: 'outequipcode' },
                { label: '车辆入场时间', prop: 'intime' },
                { label: '车辆离场时间', prop: 'outtime' },
                { label: '状态', prop: 'statusValue' },
                { label: '停车时长（s）', prop: 'stopTime', width: '120px' },
                { label: '服务类型', prop: 'serviceTypeValue', width: '120px', hide: true },
                {
                    label: '操作', input_type: 'button', width: 200, attribute: { fixed: 'right', align: 'center' },
                    list: [
                        {
                            text: '查看',
                            method: 'lookRoom',
                            limit: ''
                        }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,
            abc: [{options:[]}]
        }
    },
    mounted() {
        let index = this.conditionData.findIndex((e) => e.key === 'status')
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'parking_status' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        openServeSelect() {
            let index = this.conditionData.findIndex((e) => e.key === 'serviceType')
            let index1 = this.columns.findIndex((e) => e.prop === 'serviceTypeValue')
            this.columns[index1].hide = false
            this.conditionData[index].hide = false
            this.util.rqOptionsList({
                who: [this.conditionData[index]],
                rqData: { pageIndex: 1, pageSize: 10, dictType: 'service_card' },
                rqUrl: '/sys/dictData/query',
                rqMethod: 'post',
                labelName: 'dictLabel',
                valueName: 'dictValue',
            })
        },
        setButtonLimit(limit = '') {
            let index = this.columns.findIndex((e) => e.label === '操作')
            this.columns[index].list[0].limit = limit
        },
        lookRoom(row) {
            this.$refs.showPassed.init(row)
        },
        getFilterData(item) {
            return {
                ...item,
                startintime: item.data1 ? item.data1[0] : '',
                endintime: item.data1 ? item.data1[1] : '',
                startouttime: item.data2 ? item.data2[0] : '',
                endouttime: item.data2 ? item.data2[1] : '',
            }
        }

    }
}