<template>
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="isShow" append-to-body width="45%" :title="`高峰时段标准设置——【${titleText}${serviceName?'-'+serviceName:''}】-${isAdd?'新增':'编辑'}`" :before-close="cancel" v-if="isShow">
      <ja-form
        :rules="rules"
        ref="jaForm"
        :label-width="labelWidth"
        v-model="rowForm"
        @reset="cancel"
        :formArray="rowFormData"
        @save="saveForm" >
      </ja-form>
    </el-dialog>
</template>

<script>

export default {
    inject:['labelWidth','category','timeInterval',],
    props:['titleText','serviceName','deviceCategory'],
    data(){
        return{
            isShow:false,
            rowFormData:[
                {input_type: 'radio',label: '时段类型', key: 'category',col:24, options:[...this.category.options]},
                {input_type: 'radio',label: '时间起止', key: 'timeInterval',col:24,options:[...this.timeInterval.options]},
                {input_type: 'input',label: '单价', key: 'unitPrice',styleWidth:'100px',rightBox:{input_type: 'text',text:'元/km³'},col:18,}, //186 风冷单位为km³，187 水冷单位为kw · h
            ],
            rowForm: {},
            rules:{
                category:[{ required: true, message: '请选择时段类型', trigger: 'change' }],
                timeInterval:[{ required: true, message: '请选择时间起止', trigger: 'change' }],
                unitPrice:[{ required:true, validator:this.util.formRules.checkNumber({min:0,fixedNum:2}),trigger: 'blur' }]
            },
            isAdd:false,
        }
    },
    methods:{
        append(){
            this.isAdd=true
            this.rowForm={}
            this.$set(this.rowFormData[2].rightBox, 'text', this.deviceCategory == 186?'元/km³':'元/kw · h')
            this.isShow = true
        },
        rowEdit () {
            this.isAdd=false
            this.rowForm = {...this.$parent.rowItem}
            this.isShow = true
        },
        saveForm(){
            // console.log('提交了表单高时段设置---',this.rowForm)
            this.$emit('dealTableData',this.isAdd,this.rowForm)
            this.cancel()
        },
        cancel(){
            this.isShow = false
        },
    }
}
</script>