<template>
    <div class="container">
      
    </div>
</template>

<script>



export default {
  title: "首页",
  // name: 'DevicePandect',
  name: 'homeIndex',
  components: {
  },
  created () {
    
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
