import http from './plugin/ajax'

// ==>  消息中心

// =========  通知组 页面  =========
// 组织表
export const addMsgGroupItem = (data) => http.post('/msg/group', data) // 新增组织组
export const rqMsgGroupItem = (data) => http.get('/msg/group', data) // 查询组织组
export const delMsgGroupItem = (data) => http.delete('/msg/group', { data: data }) // 删除组织组

// 添加人弹窗
export const rqMsgGroupEmployee = (data) => http.get('/msg/group/detail/employee', { params: data }) // 查询
export const addMsgGroupEmployee = (data) => http.post('/msg/group/detail', data) // 添加

// 右侧列表
export const rqMsgGroupDetail = (data) => http.get('/msg/group/detail', { params: data }) // 查询
export const delMsgGroupDetail = (data) => http.delete('/msg/group/detail/' + data) // 删除 一项

// ========  消息模板 页面  ============
export const rqMsgTemplate = (data) => http.get('/msg/template', { params: data }) // 查询 列表
export const addMsgTemplate = (data) => http.post('/msg/template', data) // 添加
export const editMsgTemplate = (data) => http.put('/msg/template', data) // 修改
export const delMsgTemplate = (data) => http.delete('/msg/template/' + data) // 删除 一项
export const rqMsgGroup = (data) => http.get('/msg/group', { params: data }) // 查询 所有组

// ======= 消息任务 页面
export const rqMsgTask = (data) => http.get('/msg/task', { params: data }) // 查询 列表
export const addMsgTask = (data) => http.post('/msg/task', data) // 添加
export const editMsgTask = (data) => http.put('/msg/task', data) // 修改
export const delMsgTask = (data) => http.delete('/msg/task/' + data) // 删除 一项
export const cancelMsgTask = (data) => http.post('/msg/task/cancel/' + data) // 取消 一项

// ======= 消息记录 页面
export const recordMsg = (data) => http.get('/msg/record', { params: data }) // 查询消息列表
export const retryMsg = (data) => http.post('/msg/record/resend/' + data) // 重发/msg/record/resend/{id}

// ========消息中心 页面
export const Msglist = (data) => http.get('/msg/record/web/self', { params: data }) // 消息列表
export const Msg = (data) => http.post('/msg/record/read/' + data) // 查看消息
