<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="50%"
                   >
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowFormData"
                     :formArray="rowForm"
                     v-if="show"
                     v-loading="addLoading"
                     @reset="show = false"
                     @save="saveForm">
                <template #storeInformation>
                    <store-information ref="shopFrom"
                                       :shopDTOList="rowFormData.shopDTOList"
                                       :tenantId="tenantId"
                                       state="addAccount"
                                       :allDisabled="shopFromAllDisabled"></store-information>
                </template>
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import StoreInformation from './storeInformation'
import { addAccountNumber } from '@/api'
import deepCopy from '@/util/deepCopy.js'
export default {
    inject: ['getInit'],
    components: {
        JaForm,
        StoreInformation
    },
    data() {
        return {
            rules: {
                tenantName: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText({ textMessage: '驻户名称' }) }],
                categoryLabel: [{ required: true, trigger: 'change', message: '企业类型不能为空' }],
                linkMan: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText({ textMessage: '负责人姓名' }) }],
                licenseNum: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkBusinessLicense() }],
                contactNumber: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkPhone() }],
                idCard: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkIdCard() }]
            },
            rowForm: [
                { input_type: 'title', text: '驻户信息', col: 24, key: 'title1', noMarginBottom: true },
                { col: 11, input_type: 'input', label: '驻户名称', key: 'tenantName', labelWidth: '120px', maxlength: 30, disabled: true, placeholder: ' ' },
                { col: 11, input_type: 'select', label: '企业类型', key: 'categoryLabel', labelWidth: '120px', maxlength: 30, disabled: true, placeholder: ' ' },
                { col: 11, input_type: 'input', label: '营业执照号码', key: 'licenseNum', labelWidth: '120px', maxlength: 30, disabled: true, placeholder: ' ' },
                { col: 11, input_type: 'input', label: '负责人姓名', key: 'linkMan', labelWidth: '120px', maxlength: 30, disabled: true, placeholder: ' ' },
                { col: 11, input_type: 'input', label: '联系电话', key: 'contactNumber', labelWidth: '120px', maxlength: 30, disabled: true, placeholder: ' ' },
                { col: 11, input_type: 'input', label: '负责人身份证号', key: 'idCard', labelWidth: '120px', maxlength: 30, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', col: 22, maxlength: 200, labelWidth: '120px', disabled: true, placeholder: ' ' },
                { input_type: 'title', text: '店铺信息', col: 24, key: 'title2', noMarginBottom: true },
                { input_type: 'customize', customize: 'storeInformation', col: 24, key: 'shopFrom' }
            ],
            rowFormData: {},
            title: '添加账号',
            show: false,
            isAdd: false,
            allDisabled: false,
            addLoading: false,
            shopFromAllDisabled: false,
            tenantId: null
        }
    },
    methods: {
        init(row) {
            this.show = true
            this.rowFormData = { shopDTOList: [{}] }
            this.title = '添加账号'
            let {
                tenantName,
                categoryLabel,
                linkMan,
                licenseNum,
                idCard,
                contactNumber,
                remark,
                id,
                shopDTOList
            } = deepCopy(row)
            this.rowFormData = {
                tenantName: tenantName,
                categoryLabel: categoryLabel,
                linkMan: linkMan,
                licenseNum: licenseNum,
                idCard: idCard,
                contactNumber: contactNumber,
                remark: remark,
                id: id,
                shopDTOList: shopDTOList.length > 0 ? shopDTOList : [{}]
            }
            this.tenantId = id
        },
        saveForm() {
            let isOk = this.$refs.shopFrom.submitForm()
            if (!isOk) return false
            addAccountNumber({ companyId: this.tenantId, shopDTOList: [...this.rowFormData.shopDTOList] })
                .then((res) => {
                    this.show = false
                    this.addLoading = false
                    this.getInit()
                })
                .catch((res) => {
                    this.addLoading = false
                })
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.formItemText {
    margin-bottom: 15px !important;
    background-color: #ebffff;
    border-bottom: 1px solid rgb(217, 217, 217);
    padding-left: 10px;
}
</style>