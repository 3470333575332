<template>
    <div>
        <el-container>
            <left-list></left-list>
            <mian-video></mian-video>
        </el-container>
    </div>
</template>
<script>
import LeftList from './components/LeftList.vue'
import MianVideo from './components/MianVideo.vue'
export default {
    title: '视频巡更MP4',
    name: 'videoTourMP4',
    components: {
        LeftList,
        MianVideo,
    },
    data () {
        return {
        }
    },
}
</script>
<style lang="scss" scoped>
.el-container {
    margin: 10px 0;
}
.mpBox::-webkit-scrollbar {
    width: 7px;
}
.mpBox::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(7, 150, 126, 0.2);
}
.mpBox::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(21, 31, 15, 0.1);
}
</style>
