import { reqLogin, getPersonalmess } from '@/api'

const user = {
    state: {
    // token: getToken(),
        token_data: window.localStorage.getItem('token'),
        name: '',
        avatar: '',
        // roles: [],
        roles: null,
        permissions: []
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token_data = token
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        }
    },

    actions: {
    // 登录
        Login ({ commit }, data) {
            return new Promise((resolve, reject) => {
                reqLogin(data).then(res => {
                    commit('SET_TOKEN', res.data)
                    resolve(res.data)
                })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // 获取用户信息
        GetInfo ({ commit, state }) {
            return new Promise((resolve, reject) => {
                getPersonalmess().then(res => {
                    commit('SET_ROLES', res.data)
                    resolve(res.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 前端 登出
        FedLogOut ({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', null)
                sessionStorage.removeItem('menu')
                resolve()
            })
        }

    }
}

export default user
