<template>
    <el-dialog :visible.sync="show"
               :close-on-click-modal="false"
               @close="diaClose"
               width="40%"
               title="核销登记">
        <ja-form :rules="rules"
                 v-model="rowForm"
                 v-loading="loading"
                 :formArray="rowFormData"
                 @save="save"
                 @reset="reset">

        </ja-form>
    </el-dialog>
</template>
<script>
import { writeOffRegistration } from '@/api'
export default {
    inject: ['updateList'],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        pid: {
            type: [String, Number],
            defalut: null
        }
    },
    data() {
        return {

            rowFormData: [
                { input_type: 'date', label: '到账日期', key: 'writeOffTime', labelWidth: '150px', col: 24, valueFormat: 'yyyy-MM-dd' },
                { input_type: 'input', label: '到账金额（元）', key: 'writeOff', labelWidth: '150px', col: 24 },
            ],
            rules: {
                writeOffTime: [{ required: true, trigger: 'change', message: '到账日期不能为空' }],
                writeOff: [{ required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }],
            },
            rowForm: {},
            loading: false
        }
    },
    methods: {
        reset() {
            this.show = false
            this.$emit('update:show', false)
        },
         save() {
            this.loading = true
            let obj = { ...this.rowForm, billId: this.pid }
            writeOffRegistration(obj).then(res=>{
                this.loading = false
                this.$message.success(res.msg)
                this.show = false
                this.$emit('update:show', false)
                this.updateList()
            }).catch(err=>{
                this.loading = false
            })
            
           
        },
        diaClose() {
            this.show = false
            this.$emit('update:show', false)
        }
    }
}
</script>

<style lass="less" scoped>
::v-deep .facac {
    justify-content: center !important;
}
</style>
