<template>
    <!-- 电抄表 -->
    <div class="allwh">
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  :showIndex="false"
                  @showEdit="showEdit"
                  @generatePublicExpenses="generatePublicExpenses"
                  @append="normalEvent({ ref: 'addEdit', method: 'append' })"
                  @importForm="
        normalEvent({
          ref: 'importForm',
          method: 'importForm',
          url: '/files/公摊水电导入模板.xlsx',
          filename: '公摊水电导入模板',
          uploadURL: '/undertake/electricity/water/upload'
        })
      ">
        </ja-table>
        <add-edit ref="addEdit" />
        <import-form ref="importForm"
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
        <!-- 生成公摊费用 -->
        <publicExpenses ref="publicExpenses"></publicExpenses>
    </div>
</template>

<script>
import { getPublicWaterElectricity } from '@/api'
import table from '@/views/mixins/table'
import addEdit from './components/AddEdit.vue'
import importForm from './components/importForm.vue'
import checkResult from './components/checkResult'
import publicExpenses from './components/publicExpenses.vue'

export default {
    title: '公摊水电',
    mixins: [table],
    name: 'index',
    components: {
        importForm,
        checkResult,
        addEdit,
        publicExpenses
    },
    data() {
        return {
            filterData: {},
            conditionData: [
                {
                    input_type: 'select',
                    key: 'undertakeType',
                    options: [{ "value": 1, "label": "办公区域用电" }, { "value": 2, "label": "公摊总用电" }, { "value": 3, "label": "公摊总用水" }],
                    attribute: {
                        placeholder: '类型',
                        size: 'small'
                    }
                },
                {
                    input_type: 'date',
                    type: 'month', 
                    value: 'date',
                    valueFormat: 'yyyy-MM',
                    attribute: {
                        placeholder: '日期',
                        size: 'small'
                    }
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add',limit: 'subsystemModule::publicWaterElectricity::add' },
                { input_type:'button',method: 'generatePublicExpenses',text:'生成公摊费用',limit: 'subsystemModule::publicWaterElectricity::cost' },
                { normalBtn: 'import', text: '导入',limit: 'subsystemModule::publicWaterElectricity::import' }
            ],
            columns: [
                { label: '名称', prop: 'undertakeTypeName' },
                { label: '日期', prop: 'date' },
                { label: '数值', prop: 'number' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 180,
                    list: [
                        {
                            text: '编辑',
                            method: 'showEdit',
                            limit: 'subsystemModule::publicWaterElectricity::edit'
                        }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,
            rowForm: {},
            results: null,
            indexObj:{}
        }
    },
    created() { 
        this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
        // 类型下拉列表
        this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.undertakeType],], rqUrl: "/sys/dictData/undertake_type", resRule: 'data', })
    },
    methods: {
        getInit() {
            this.loading = true
            // 获取电表抄表数据
            getPublicWaterElectricity({
                pageIndex: this.filterData.pageIndex,
                pageSize: this.filterData.pageSize,
                undertakeType: this.filterData.undertakeType,
                date: this.filterData.date,
            }).then(({ data }) => {
                let dataList = data.data
                this.tableData = dataList
                this.total = data.total
                this.loading = false
            })
        },
        //查看
        showEdit(row) {
            this.$refs.addEdit.append(row,'编辑')
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
        generatePublicExpenses(){
            this.$refs.publicExpenses.init()
        },
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-col .el-form-item .el-input__inner {
    height: 40px;
}
/deep/ .el-select {
    width: 100% !important;
}
</style>
