<template>
    <div>
        <ja-table
            ref="tst"
            v-model="filterData"
            :conditionData="conditionData"
            :columns="columns"
            :tableData="tableData"
            :total="total"
            :loading="loading"
            :showIndex="true"
            @getInit="getInit"
            @search="search"
            @reset="reset"
            @append="append"
            @edit="edit"
            @showResident="showResident"
            @deleteResident="deleteResident"
            @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/设备台账模板.xlsx', filename: '设备台账模板', uploadURL: '/iotDeviceRecordTemp/upload'})"
        > </ja-table>
        <add-edit ref="addEdit"></add-edit>
        <import-form
            ref='importForm'
            @openResult="openResult"
        />
        <check-result
            ref="checkResult"
            :results="results"
        />
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import importForm from '@/views/home/components/common/importForm.vue'
import AddEdit from './components/AddEdit.vue'
import { getLedgerList ,deleteLedger} from '@/api'
import checkResult from '@/views/home/components/common/checkResult.vue'
export default {
    title: '设备台账',
    name: 'equipmentLedger',
    mixins: [table],
    components: {
        importForm,
        AddEdit,
        checkResult,
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small', } },
                { input_type: 'select', key: 'type', attribute: { placeholder: '全部类型', size: 'small', }, options: [] },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '新增', limit: 'sysSetting::equipmentLedger::add' },
                { normalBtn: 'import', text: '导入', limit: 'sysSetting::equipmentLedger::import' }
            ],
            columns: [
                { label: '设备名称', prop: 'deviceName', width: '150px' },
                { label: '设备型号', prop: 'model', width: '150px' },
                { label: '设备类型', prop: 'typeValue', width: '150px' },
                { label: '型号/规格/功率', prop: 'spec', width: '150px' },
                { label: '单位', prop: 'unit', width: '150px' },
                { label: '数量', prop: 'number', width: '150px' },
                { label: '运行地点', prop: 'place', width: '150px' },
                { label: '入册时间', prop: 'registrationTime', width: '150px' },
                { label: '维管部门', prop: 'department', width: '150px' },
                { label: '负责人', prop: 'linkman', width: '150px' },
                { label: '设备属性说明', prop: 'attributeSpecification', width: '150px' },
                { label: '品牌/厂家', prop: 'manufacturer', width: '150px' },
                { label: '保质期', prop: 'shelfLife', width: '150px' },
                { label: '配件', prop: 'accessories', width: '150px' },
                { label: '备注', prop: 'remark', width: '150px' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: '150px',
                    list: [
                        { text: '查看', method: 'showResident', limit: 'sysSetting::equipmentLedger::show' },
                        { text: '编辑', method: 'edit', limit: 'sysSetting::equipmentLedger::edit' },
                        { text: '删除', method: 'deleteResident', limit: 'sysSetting::equipmentLedger::delete' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            results: null,
        }
    },
    mounted() {
        let index = this.conditionData.findIndex(e => e.key === 'type')
        this.util.rqOptionsList({
            who: this.conditionData[index],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'equipment _type' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        getInit() {
            getLedgerList(this.filterData).then(res => {
                this.tableData = res.data.data
                this.loading = false
                this.total = res.data.total
            })
        },
        append() {
            this.$refs.addEdit.init(1);
        },
        edit(row) {
            this.$refs.addEdit.init(2, row);
        },
        showResident(row) {
            this.$refs.addEdit.init(3, row);
        },
        deleteResident(row) {
            console.log(row);
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                deleteLedger(row.id).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.loading = false
                    this.getInit()
                }).catch(res => {
                    this.loading = false
                    this.$message.error('删除失败,请稍后再试')
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        }

    }
}
</script>