<template>
    <!-- 表格 -->
    <div class="content">
        <div class="title">
            账单核销分布
        </div>
        <div class="echart"
             v-if="options">
            <ja-init-chart :options="options" />
        </div>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import { getWriteoffStatistics } from '@/api'
export default {
    components: {
        JaInitChart,
    },
    data() {
        return {
            options: null,
            writeoffData: {}
        }
    },
    created() {
       
        this.init()
    },
    methods: {
        init(){
            getWriteoffStatistics().then(res=>{
                let data=res.data
                
                this.setOptions([data.waitWriteoff,data.alreadyWriteoff,data.arrearage])
            })
        },
        async setOptions(data=[]) {
            let initData = data

            this.options = {
                legend: {
                    show:false,
                    top:'5%',
                    right:'10%',
                    data: ['待核销','已核销','已欠费'],
                    textStyle:{
                        fontSize:12,
                        color:'#808080'
                    },
                    icon:'rect'
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '18%',
                    containLabel: true
                },
                xAxis: {
                    show:true,
                    type: 'category',
                    data: ['待核销','已核销','已欠费'],
                    axisLine:{
                        lineStyle:{
                            color:'#657CA8'
                        }
                    },
                    axisTick:{
                        show:false,
                    },
                    axisLabel:{
                        show:true,
                        color:'#333',
                        fontSize:18
                    },
                },
                yAxis: {
                    axisLine:{
                        lineStyle:{
                            color:'#657CA8'
                        }
                    },
                    axisTick:{
                        show:true
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#eee'
                        }
                    },
                    axisLabel:{
                        color:'#666',
                        fontSize:16
                        
                    },
                },
                series: [
                    {
                        type: 'bar',
                        barWidth:100,
                        data: initData,
                        label: {
                            show: true,
                            position:'top' ,
                            textStyle:{
                                color:'#666',
                                fontSize:18
                            }
                        },
                        itemStyle:{
                            normal:{
                                color: function (params){
                                    var colorList = [
                                        ['#f59a23','transparent'],
                                        ['#4352d4','transparent'],
                                        ['#d9001b','transparent'],
                                    ];
                                    return colorList[params.dataIndex][0]
                                }
                            }
                        }
                    }
                ]
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 100%;
    .title{
        font-size: 22px;
        font-weight: bold;
        margin: 20px 0;
        color: #333;
        text-align: center;
    }
}
.echart {
    height: 100%;
    width: 100%;
}
</style>