<template>
  <router-view/>
</template>

<script>
export default {
  title: "招商管理",
  name: 'investmentManagement'
}
</script>

<style scoped>

</style>
