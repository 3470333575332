<template>
  <!-- 表格 -->
  <div class="content" v-loading="loading">
    <div class="header">维修工单状态分析</div>
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
export default {
  components: {
    JaInitChart,
  },
  props: {
    repairMonthStatistics: Array, loading: Boolean
  },
  data() {
    return {
      options: null
    }
  },
  created() {
    this.setOptions()
  },
   watch: {
    repairMonthStatistics(newVal) {
      this.setOptions()
    }
  },
  methods: {
    setOptions() {
      let initData = this.repairMonthStatistics.map(item => {
        return {value:Number(item.percentage.match(/\d+/)),name:item.statusName }
      })
      this.options = {
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}%'
        },
        legend: {
          orient: 'vertical',
          right: this.util.setFontSize(30, 1920),
          top: '25%',
          left: '60%',
          width: '10%',
          height: '80%',
          itemGap: this.util.setFontSize(18, 1920),
          textStyle: { //图例文字的样式
            color: '#aaa',

            fontSize: this.util.setFontSize(15, 1920),
          },
          formatter: function (val) {
            let item = initData.find(v => v.name == val)
            return `${val}`;
          },
          data: initData
        },
        series: [
          {
            name: 'Area Mode',
            type: 'pie',
            avoidLabelOverlap: true,
            radius: ['30%', '50%'],
            center: ['35%', '50%'],
            // roseType: 'area',
            itemStyle: {
              borderRadius: 5
            },
            colorBy: 'data',
            label: {
              fontSize: this.util.setFontSize(15, 1920),
              formatter: '{c}%',
            },
            labelLine: {
              length: this.util.setFontSize(15, 1920),
              length2: this.util.setFontSize(20, 1920),
              maxSurfaceAngle: this.util.setFontSize(80, 1920),
            },

            data: initData
          }
        ]

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 16px;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  position: relative;
  color: #4352D4;
  font-weight: 700;
}

.content {
  width: 100%;
  height: 100%;
}

.echart {
  height: 100%;
  width: 100%;
}
</style>