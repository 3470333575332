<template>
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :tableHeadButtons="tableHeadButtons"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :showIndex="true"
      @handleSelectionChange="handleSelectionChange"
      @rowDetail="rowDetail"
      @append="append"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @getInit="getInit"
      :valueFormat="formatter"
      :editDataFormat="editDataFormat"
      @search="search"
      @reset="reset"
    >
    </ja-table>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="记录详情" :width="`60%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='8'>
      </ja-detail>
    </el-dialog>
    <!-- 新增/编辑 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title="titleText">
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        @reset="showEditAll = false"
        @save="saveForm" v-if="showEditAll">
        <template #uplpadfaceImg>
            这是插槽哈哈
        </template>
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
// import {rqBusList,addBusItem,editBusItem,delBusItem} from '@/api'

export default {
  title: '模板页面',
  mixins: [table],
  name: 'index',
  components: {
   
  },
  data () {
    return {
      outPutExcell_obj:{
        outPutExcell_url:'/query/export/vehicleApplyExport',
        limit:'admin::rsVehicleApply::export'
      },
      labelWidth: `${this.util.setFontSize(150)}px`,
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        { input_type: 'input',key: 'queryParam',attribute: {placeholder: '申请人，入库单号',size: 'small'}},
        { input_type: 'cascader',key: 'queryParam',placeholder:'请选择省市区'},
        { input_type: 'date',type: "datetimerange",value: 'time', startPlaceholder:'入库时间',endPlaceholder:'入库时间',attribute: {size: 'small'}},
        { input_type: 'select',key: 'settleFlag',attribute: {placeholder: '是否结清',size: 'small'},options: [{ label: '未结清', value: 0 },{ label: '已结清', value: 1 }]},
        // { text: '查询',input_type: 'button',method: 'search',size: 'small',attribute: {type: 'primary',icon:'el-icon-search', }},
        // { text: '清空',input_type: 'button',method: 'reset',size: 'small',attribute: {type: 'default'}},
        // { text: '导出',input_type: 'outputBtn',rightPos:'right', outPutExcell_url:'/query/export/vehicleApplyExport',},
        // { text: '新增',input_type: 'button',method: 'append',size: 'small',attribute: {type: 'primary',icon:'el-icon-plus',plain:true},rightPos:true,limit:'admin::repairApply::add'},
        { normalBtn:'search',},
        { normalBtn:'reset'},
        { normalBtn:'download'},
        { normalBtn:'add'},
      ],
      columns: [
        { label: '入库编号', prop: 'orderNumber' ,},
        { label: '关联采购申请单号', prop: 'number' },
        { label: '申请人', prop: 'applier'},
        { label: '入库明细', prop: 'detail', width: 300,attribute:{showOverflowTooltip:true,align:'center'} },
        { label: '总金额', prop: 'total'},
        { label: '入库时间', prop: 'time'},
        { label: '是否结清', prop: 'settleFlag' },
        { label: '操作',input_type: 'button',width:70,attribute:{fixed:"right",align:'center'},
          list: [
            { text: '查看', method: 'rowDetail' },
            { text: '编辑', method: 'rowEdit' },
            { text: '删除', method: 'rowDelete' },
          ]
        }
      ],
      tableData: [
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: 1 },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: 0 },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: 0 },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: 1 },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: 1 },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: 1 },
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: 0 }
      ],
      loading: false,
      total: 300,
      //详情
      showDetail: false,
      detailOptions:
        [
          { label: '基本信息', isTitle: true, col: 24 },
          { label: '申请人', key: 'applier' },
          { label: '入库时间', key: 'time' },
          { label: '关联采购申请单号', key: 'number'},
          { label: '申请明细', key: 'detail', type: 'customize', customize: 'table', col: 24 },
          { label: '是否结清', key: 'settleFlag', type: 'settleFlag', col: 24 },
        ],
      detailData: {},
      // 新增/编辑
      titleText:'编辑',
      rowFormData: [
        {input_type: 'input',label: '违停车牌号', key: 'plateNumber'},
        {input_type: 'select',label: '公司', key: 'orgName',options: [],},
        {input_type: 'input',label: '车主姓名', key: 'owner',valueType:'number',type:'number'},
        {input_type: 'select',label: '部门', key: 'deptName',options: [],},
        {input_type: 'input',label: '联系电话', key: 'mobile'},
        {input_type: 'radio',label: '状态', key: 'status',col:24,options:[
            {label:'已处理',value:1},
            {label:'未处理',value:0},
        ]},
        {input_type: 'customize',customize:'uplpadfaceImg',label: '附件',key: 'urlLists',col:24,},
      ],
      rules: {
            plateNumber: [{ required: true, message: '请输入违停车牌号', trigger: 'blur' }],
            orgName: [{ required: true, message: '请选择公司', trigger: 'change' }],
            deptName: [{ required: true, message: '请选择部门', trigger: 'change' }],
            mobile: [{ required: true, trigger: 'blur', validator:this.util.formRules.checkPhone()}],
            num:[{ required: true, trigger: 'blur', validator:this.util.formRules.checkNumber({max:9999})}],
            owner: [{ required: true, message: '请输入车主名称', trigger: 'blur',}],
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
    }
  },
  created(){
     
  },
  methods: {
    editDataFormat(row,btn){
      if((row.status==2|| row.status==3) && btn.text=='预约'){
        return false
      }else{
        return true
      }
    },
    handleSelectionChange (val) {
      console.log('val', val)
    },
    formatter (val, key) {
      if (key === 'status') {
        return ['预约中', '预约成功','已取消'][val || 0]
      }
      return val || ''
    },
    outPut(){
      let rqobj={
        queryParam: this.filterData.queryParam?this.filterData.queryParam:'',
        settleFlag: this.filterData.settleFlag?this.filterData.settleFlag:'',
        startTime: this.filterData.time ? this.$moment(this.filterData.time[0]).format("YYYY-MM-DD HH:mm:ss") : '',
        endTime: this.filterData.time ? this.$moment(this.filterData.time[1]).format("YYYY-MM-DD HH:mm:ss") : ''
      }
      reqExportInStorageRecord(rqobj).then(res=>{
        this.util.dealExcelRespose(res,'入库记录')
      })
    },
    getInit () {
    return
      this.loading=true
      reqInStorageRecord({
        pageSize: this.filterData.pageSize,
        pageIndex: this.filterData.pageIndex,
        parameter: {
          queryParam: this.filterData.queryParam,
          settleFlag: this.filterData.settleFlag,
          startTime: this.filterData.time ? this.$moment(this.filterData.time[0]).format("YYYY-MM-DD HH:mm:ss") : '',
          endTime: this.filterData.time ? this.$moment(this.filterData.time[1]).format("YYYY-MM-DD HH:mm:ss") : ''
        }
      }).then(res => {
        console.log(res)
        this.loading=false
        this.tableData = res.data.data
        this.total = res.total
      })
    },
    // 删除
    rowDelete (row) { 
      this.$confirm('是否确认删除人员信息').then(res => {
        delUser(
          {parameter:{userId:row.userId}}
        ).then(res=>{
          this.$message.success("已删除")
          this.getInit()
        })  
      })
    },
    // 保存
    saveForm () {
      if (this.isAdd) {
        console.log('新增===》',this.rowForm)
        addBusItem(this.rowForm).then(res => {
          this.$message.success('新增成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          // this.$message.error(error)
        })
      } else {
        editBusItem(this.rowForm).then(res => {
          this.$message.success('修改成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          // this.$message.error(error)
        })
      }
    },
    append(){
      this.isAdd=true
      this.titleText='新增'
      this.rowForm={}
      this.showEditAll = true
    },
    rowEdit (row) {
      //  console.log('row===',row)
      this.isAdd=false
      this.titleText='编辑'
      this.rowForm={...row}
      this.showEditAll = true
    },
    rowDetail (row) {
      this.showDetail = true
      this.detailData = row
    },
  }
}
</script>

<style scoped>

</style>
