<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag
               :close-on-click-modal="false"
               :visible.sync="show"
               width="50%"
               title="放行条管理已通过查看">
        <ja-form :rules="rules"
                 ref="jaForm"
                 :label-width="labelWidth"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :loadingForm="loadingForm"
                 :allDisabled="allDisabled">
            <template #imgList>
                <div class="img-box">
                    <div v-for="(item,index) in rowForm.imgUrls"
                         :key="index"
                         class="img-item">
                        <el-image :src="item"
                                  :preview-src-list="rowForm.imgUrls"></el-image>
                    </div>
                </div>
            </template>
        </ja-form>
    </el-dialog>
</template>
<script>
export default {
    inject: ['labelWidth', 'getInit',],
    data() {
        return {
            rowFormData: [
                { input_type: 'input', label: '店铺名称', key: 'applyDeptName', col: 12 },
                { input_type: 'input', label: '申请人', key: 'applyEmployeeName', col: 12 },
                { input_type: 'input', label: '申请人联系方式', key: 'applyEmployeePhone', col: 12 },
                { input_type: 'input', label: '放行条类型', key: 'typeName', col: 12 },
                { input_type: 'input', label: '放行时间', key: 'transitTime', col: 12 },
                { input_type: 'input', label: '物品搬运人', key: 'transireEmployeeName', col: 12 },
                { input_type: 'input', label: '身份证号码', key: 'transireEmployeeIdCard', col: 12 },
                { input_type: 'input', label: '搬运人联系方式', key: 'transireEmployeePhone', col: 12 },
                { input_type: 'input', type: 'textarea', label: '放行申请原因', key: 'applyDescription', col: 24 },
                { input_type: 'customize', customize: 'imgList', label: '图片', col: 24 },
                { input_type: 'input', label: '提交时间', key: 'createTime', col: 12 },
                { input_type: 'input', label: '审批状态', key: 'statusName', col: 12 },
                { input_type: 'input', label: '操作人', key: 'auditEmployeeName', col: 12 },
                { input_type: 'input', label: '操作时间', key: 'auditTime', col: 12 },

            ],
            rowForm: {},
            rules: {},
            loadingForm: false,
            show: false,
            allDisabled: true
        }
    },
    methods: {
        rowDetail(row) {
            this.rowForm = { ...row }
            this.show = true
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
.img-box {
    display: flex;
    justify-content: space-between;
}
.img-item {
    height: 150px;
    width: 150px;
    .el-image {
        width: 100%;
        height: 100%;
    }
}
</style>