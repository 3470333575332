<template>
  <!--  普通的表单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="50%" :title="isAdd?'新增':'编辑'">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm">
      <template #optionsList>
        <ul class="main">
          <li v-for="(item,index) in rowForm.pcsqo" :key="index">
            <el-form-item :prop='`pcsqo[${index}].optionContent`' :rules="{ required: true, message: '请完善选项', trigger: 'blur' }">
              <el-input v-model="item.optionContent" class="ipt"></el-input>
              <i class="el-icon-circle-plus iconBox" @click="addItem"></i>
              <i class="el-icon-remove iconBox" @click="removeItem(index)" v-show="rowForm.pcsqo.length >1 "></i>
            </el-form-item>
          </li>
        </ul>
      </template>
    </ja-form>
  </el-dialog>
</template>
<script>
import editorMce from '@/components/editorMce'
import { addQuestion, editQuestion } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  components: {
    editorMce
  },
  data() {
    return {
      Config: { height: 300 },
      rowFormData: [
        { input_type: 'input', label: '问题', key: 'questionDescription', maxlength: 20, col: 20 },
        { input_type: 'select', label: '分类', key: 'questionCategory', col: 10, options: [], },
        { input_type: 'select', label: '问题类型', key: 'questionType', col: 10, options: [], },
        { input_type: 'customize', label: '选项', key: 'pcsqo', col: 20, customize: 'optionsList', isRequired: true },

      ],
      rowForm: {},
      rules: {
        questionDescription: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        questionCategory: [{ required: true, message: '请选择分类', trigger: 'change' }],
        questionType: [{ required: true, message: '请选择问题类型', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {},
      initFileList: []
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },
  methods: {
    append() {
      this.isAdd = true
      this.rowForm = { pcsqo: [{ optionContent: '' }] }
      this.showEditAll = true
      this.$nextTick(() => {
        this.$refs.jaForm.$refs.formbox.clearValidate()
      })
    },
    rowEdit(row) {
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = { ...row }
      this.showEditAll = true
      this.$nextTick(() => {
        this.$refs.jaForm.$refs.formbox.clearValidate()
      })
    },
    saveForm() {
      this.loadingForm = true
      let rqMethod = this.isAdd ? addQuestion : editQuestion
      rqMethod(this.rowForm).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },
    addItem() {
      if (this.isAdd) {
        this.rowForm.pcsqo.push({ optionContent: '' })
      } else {
        this.rowForm.pcsqo.push({ optionContent: '', questionId: this.rowForm.id })
      }
    },
    removeItem(index) {
      this.rowForm.pcsqo.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
ul.main {
  margin-top: 10px;
  > li {
    margin-bottom: 10px;
    /deep/ .el-form-item__content {
      display: flex;
      align-items: center;
    }
    .ipt {
      width: 100%;
    }
    .iconBox {
      flex-shrink: 0;
      font-size: 23px;
      margin-left: 10px;
      color: #606266;
      &:hover {
        cursor: pointer;
        color: #409eff;
      }
    }
  }
}
</style>