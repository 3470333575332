<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="showEditAll"
                   width="55%"
                   title="提交">
            <ja-form :rules="rules"
                     ref="jaForm"
                     :label-width="labelWidth"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :loadingForm="loadingForm"
                     @reset="showEditAll = false"
                     @save="saveForm"
                     @expenseAllocation="expenseAllocation"
                     v-if="showEditAll">
                <template #uplpadfaceImg>
                    <upload-file @pdfTitleClickEvemt="pdfTitleClickEvemt"
                                 ref="Upload"
                                 :initFileList="[...initFileList]"
                                 :noButton="true"
                                 :hideDel="true"
                                 accept="pdf" />
                </template>
                <template #paymentDate>
                    <span>每个月的第</span>
                    <el-select v-model="rowForm.day"
                               disabled
                               style="width:35%;margin:0 10px"
                               class="day"
                               placeholder="请选择日期">
                        <el-option v-for="item in 28"
                                   :key="item"
                                   :label="item"
                                   :value="item"></el-option>
                    </el-select>
                    <span>日</span>
                </template>
            </ja-form>
        </el-dialog>
        <!--费用配置-->
        <rent-allocation ref="rentA"
                         :allDisabled="true"></rent-allocation>
    </div>
</template>
<script>
import selectTenant from '@/views/home/components/selectTenant/selectTenant'
import selectRoom from '@/views/home/components/selectRoom/selectRoom'
import RentAllocation from './rentAllocation.vue'
import {
    submitContractItem
} from '@/api'

export default {
    inject: ['labelWidth', 'getInit'],
    components: { selectTenant, selectRoom, RentAllocation },
    data() {
        return {
            labelWidth: `${this.util.setFontSize(150)}px`,
            rowFormData: [
                { input_type: 'input', label: '合同编号', key: 'contractCode', col: 12, styleWidth: '60%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同名称', key: 'contractName', col: 12, styleWidth: '60%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同类型', key: 'contractTypeName', col: 12, styleWidth: '60%' },
                { input_type: 'date', label: '租期', key: 'leaseTerm', method: 'selectDate', type: 'daterange', col: 12, valueFormat: 'yyyy-MM-dd', unlinkpanels: true, disabled: true },
                { input_type: 'date', label: '计租开始日期', key: 'billingTime', pickerOptions: { disabledDate: this.disabledDateFun }, disabled: true, valueFormat: 'yyyy-MM-dd', defaultValue: '', col: 12, styleWidth: '60%' },
                { input_type: 'customize', customize: 'paymentDate', label: '缴费日期', key: 'day' },
                { input_type: 'input', label: '驻户名称', key: 'tenantName', col: 12, placeholder: '请选择驻户', styleWidth: '60%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '营业执照号/身份证号', key: 'cardCode', col: 12, styleWidth: '60%', maxlength: 10, disabled: true },
                {
                    input_type: 'table', label: '驻户租赁单元', editDataFormat: this.editDataFormatTable, col: 20, noSearch: true, showPage: false, key: 'roomTable', isRequired: true, border: true, table_MaxHeight: 500, //roomIdstableAttribute: { showSummary: true },
                    columns: [
                        { label: '驻赁单元名称', prop: 'roomName' },
                        { label: '租赁单元类型', prop: 'roomTypeValue' },
                        { label: '所在楼栋', prop: 'buildingName' },
                        { label: '所在楼层', prop: 'floorName', attribute: { showOverflowTooltip: false } },
                        { label: '套内总体使用面积', prop: 'totalArea' },
                        {
                            label: '操作',
                            input_type: 'button',
                            width: '150',
                            list: [
                                { text: '查看', method: 'expenseAllocation', limit: 'contract::manage::expense_allocation' }
                            ]
                        }
                    ]
                },
                {
                    input_type: 'customize',
                    customize: 'uplpadfaceImg',
                    label: '合同附件',
                    key: 'urlLists',
                    col: 24,
                    disabled: true
                },
            ],
            rowForm: {},
            rules: {},
            showEditAll: false,
            selectedPersons: [],
            isSelectPeople: false,
            initFileList: [],
            loadingForm: false,
            roomFormActiveDate: [],
            isClickCancel: false, // 是否 点击了取消
            oldDateRage: [], // 原来的合同时间,
            indexObj: {},
        }
    },
    created() {
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData })
    },
    methods: {
        // 编辑
        rowEdit(row) {
            //清空费用配置
            this.contractRentReqs = []
            this.isChange = false
            this.loadingForm = false
            this.isClickCancel = false
            this.isAdd = false
            this.rowFormData[4].disabled ? delete this.rowFormData[4].disabled : ''
            this.rowFormData[3].disabled ? delete this.rowFormData[3].disabled : ''
            this.$set(this.rowFormData[2], 'disabled', true)
            this.$set(this.rowFormData[3], 'disabled', true)
            this.$set(this.rowFormData[4], 'disabled', true)
            this.titleText = '编辑'

            let obj = { ...row }
            // 重构数据
            obj.leaseTerm = [row.startTime, row.endTime]
            //区分新增/编辑生成明细请求参数
            obj.isEdit = true
            // obj.liquidatedDamagesRate
            obj.number = row.cardCode
            obj.roomTable = [...row.roomInfo].map((item) => {
                return {
                    ...item,
                    id: item.roomId
                }
            })
            this.roomFormActiveDate = [...obj.leaseTerm]
            this.oldDateRage = [...obj.leaseTerm]
            if (row.attachmentListInfo.length > 0) {
                this.initFileList = row.attachmentListInfo.map((item) => {
                    if (!item) return
                    return {
                        name: item.attachmentName ? item.attachmentName : undefined,
                        url: item.attachmentUrl ? item.attachmentUrl : item,
                        // raw: {type :item.includes('pdf')?'pdf':'image'},
                        raw: { type: item.attachmentType === 'pdf' ? 'pdf' : 'image' },
                        status: 'success',
                        response: { data: item }
                    }
                })
            } else {
                this.initFileList = []
            }
            // 禁用部分按钮
            // this.$set(this.rowFormData[2], 'disabled', false)
            this.frequencyDisabled = false
            this.rowForm = { ...obj }
            this.showEditAll = true
        },
        editDataFormatTable(row, btn) {
            if (
                row.isChange &&
                btn.text == '删除' &&
                this.rowForm.contractTypeName == 1
            ) {
                return false
            } else {
                return true
            }
        },
        async saveForm() {
            this.$confirm('是否确定提交该项?').then((res) => {
              console.info(this.rowForm)
                submitContractItem(`${this.rowForm.id}/${this.rowForm.tenantId}`).then((res) => {
                    this.$message.success('提交成功')
                    this.showEditAll = false
                    this.getInit()
                })
            })
        },
        // 配置费用
        expenseAllocation(row) {
            let { contractType, id, billingTime } = this.rowForm
            let payServiceList = []
            if (this.rowForm.bizPayServiceNewDTOS) {
                payServiceList = [...this.rowForm.bizPayServiceNewDTOS]
            }

            this.$refs.rentA.init({ ...row, contractType, contractId: id, billingTime }, [], payServiceList)
        },
    }
}
</script>
<style lang="scss" scoped>
.frequencyDay {
    /deep/ .el-select {
        width: 100px;
        margin: 0 8px;
    }
    /deep/ .el-form-item__label {
        width: auto !important;
    }
    /deep/ .el-form-item__content {
        margin-left: auto !important;
    }
}
.elDialog {
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
}
/deep/.selectFrom {
    width: 600px;
}
/deep/.select-two {
    float: right;
    width: 60% !important;
}
/deep/ .el-input-number .el-input__inner {
    padding-left: 0;
}
</style>
