<template>
    <div>
        <el-dialog :visible.sync="show"
                   :close-on-click-modal="false"
                   :show-close="false"
                   :close-on-press-escape="false"
                   width="70%"
                   :title="title">
            <ja-form :rules="rules"
                     v-if="show"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :btnBox="btnBox"
                     :allDisabled="allDisabled"
                     :loadingForm="loadingForm"
                     @customTofixed="customTofixed"
                     @writeOffAll="writeOffAll"
                     @showDetails="showDetails"
                     @showDetailsHistory="showDetailsHistory"
                     @handleCurrentChange="handleCurrentChange"
                     @register="register"
                     @registerHistory="registerHistory"
                     @adopt="adopt"
                     @reset="reset">
                <template #mesContent>
                    <el-tooltip placement="bottom">
                        <div slot="content">
                            计算优惠：如有减免调整后金额，则预计实收合计为调整后金额-优惠金额；
                            <br />
                            不计算优惠：按照调整后应收金额合;
                        </div>
                        <el-button type="text"
                                   icon="el-icon-warning"
                                   size=""></el-button>
                    </el-tooltip>

                </template>
                <template #total>
                    <span style="margin-right:20px">本期应收合计：{{totalList.receivableTotal}}</span>
                    <span>合计实收：{{totalList.writeOffTotal}}</span>
                </template>
                 <template #total2>
                    <span style="margin-right:20px">本期应收合计：{{totalHistoryList.receivableTotalHistory}}</span>
                    <span style="margin-right:20px">合计实收：{{totalHistoryList.writeOffTotalHistory}}</span>
                    <span>欠费总计：{{totalHistoryList.unpaidAmountHistory}}</span>
                </template>
            </ja-form>

        </el-dialog>
        <write-off :show.sync="writeOffShow"
                   v-if="writeOffShow"
                   :pid="id"></write-off>
        <write-off-history :show.sync="writeOffHistoryShow"
                   v-if="writeOffHistoryShow"
                   :pid="id"></write-off-history>
        <write-zero :show.sync="writeZero"
                   v-if="writeZero"
                   :pid="checkCloumuId"
                   :rowForm="rowzero"></write-zero>
    </div>
</template>
<script>
import {
    getArrearageBillListCancel,
    getArrearageBillListConfirm,
    getArrearageBillListDerail,
    getArrearageBillListHistory,
    getHistoryWriteOffRecord,
    setWriteOffAll,
    showWriteOffDetail
} from '@/api'
import writeOff from './writeOff.vue'
import writeOffHistory from './writeOffHistory.vue'
import writeZero from './writeZero.vue'

export default {
    components: { writeOff, writeOffHistory, writeZero },
    inject: ['getInit'],
    provide () {
        return {
            updateList: this.updateList
        }
    },
    data () {
        return {
            rules: {},
            rowFormData: [],
            // 正常配置项
            rowFormDataIndex: [
                { input_type: 'title', text: '本期账单', col: 24 },
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', col: 5 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '8vw', col: 8 },
                { input_type: 'select', label: '合计规则', key: 'sumRule', col: 5, disabled: true, options: [{ label: '不计算优惠', value: '0' }, { label: '计算优惠', value: '1' }] },
                { input_type: 'customize', customize: 'mesContent', label: '', col: 1, labelWidth: '10px' },
                { rightBoxItem: { input_type: 'button', label: '一键核销', method: 'writeOffAll' }, col: 1.5 },
                { rightBoxItem: { input_type: 'button', label: '核销登记', type: 'node', method: 'register' }, col: 1.5 },
                { input_type: 'text2', label: '收费清单', key: 'amount', labelWidth: '110px', col: 11 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'amountDTOs',
                    isRequired: true,
                    border: true,
                    highlightCurrentRow: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType' },
                        { label: '收费项目性质', prop: 'natureCharges' },
                        { label: '收费项目名称', prop: 'serviceName' },
                        { label: '计费周期', prop: 'date' },
                        { label: '缴费周期', prop: 'day' },
                        { label: '本期应收', prop: 'receivable' },
                        { label: '调整后应收', prop: 'adjustedReceivable', blurMethod: 'blurNum' },
                        { label: '优惠金额', prop: 'coupon', blurMethod: 'blurNum' },
                        { label: '未收金额', prop: 'uncollected' },
                        { label: '抹零后金额', prop: 'zeroAfter', width: 120 },
                        { label: '已核销金额（元）', prop: 'writeOff', width: 150 }
                    ]
                },
                { input_type: 'text2', label: '收费分项明细', key: 'detail', labelWidth: '110px', col: 11 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'bizBillDetailNewDTOs',
                    isRequired: true,
                    border: true,
                    highlightCurrentRow: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', width: 120 },
                        { label: '收费项目性质', prop: 'natureCharges', width: 120 },
                        { label: '收费项目名称', prop: 'serviceName', width: 200 },
                        { label: '计费周期', prop: 'date', width: 220 },
                        { label: '缴费周期', prop: 'day', width: 120 },
                        { label: '本期应收', prop: 'receivable', width: 120 },
                        { label: '调整后应收', prop: 'adjustedReceivable', width: 120 },
                        { label: '优惠金额', prop: 'coupon', width: 120 },
                        { label: '未收金额', prop: 'uncollected', width: 120 },
                        { label: '抹零后金额', prop: 'zeroAfter', width: 120 },
                        { label: '备注', prop: 'remark', width: 220 },
                        { label: '已核销金额（元）', prop: 'writeOff', width: 150 },
                        {
                            label: '操作',
                            input_type: 'button',
                            width: 200,
                            attribute: { fixed: 'right', align: 'center' },
                            list: [
                                // { text: '查看', method: 'showDetails' },
                                { text: '长短款抹零', method: 'customTofixed' }

                            ]
                        }
                    ]
                },
                { input_type: 'customize', customize: 'total', label: '', col: 24, labelWidth: '10px' },
                { input_type: 'title', text: '收费项登记记录', col: 24 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'writeOffRecord',
                    isRequired: true,
                    border: true,
                    showIndex: false,
                    columns: [
                        { label: '到账日期', prop: 'writeOffTime' },
                        { label: '到账金额', prop: 'writeOffNumber' },
                        { label: '剩余未缴金额（元）', prop: 'unpaidAmount' },
                        { label: '核销操作人', prop: 'updateBy' }
                    ]
                }
            ],
            // 带有历史欠费的配置项
            rowFormHistory: [
                { input_type: 'title', text: '本期账单', col: 24 },
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', col: 5 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '8vw', col: 8 },
                { input_type: 'select', label: '合计规则', key: 'sumRule', col: 5, disabled: true, options: [{ label: '不计算优惠', value: '0' }, { label: '计算优惠', value: '1' }] },
                { input_type: 'customize', customize: 'mesContent', label: '', col: 1, labelWidth: '10px' },
                { rightBoxItem: { input_type: 'button', label: '一键核销', method: 'writeOffAll' }, col: 1.5 },
                { rightBoxItem: { input_type: 'button', label: '核销登记', type: 'node', method: 'register' }, col: 1.5 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'bizBillDetailNewDTOs',
                    isRequired: true,
                    border: true,
                    highlightCurrentRow: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', width: 120 },
                        { label: '收费项目性质', prop: 'natureCharges', width: 120 },
                        { label: '收费项目名称', prop: 'serviceName', width: 200 },
                        { label: '计费周期', prop: 'date', width: 220 },
                        { label: '缴费周期', prop: 'day', width: 120 },
                        { label: '本期应收', prop: 'receivable', width: 120 },
                        { label: '调整后应收', prop: 'adjustedReceivable', width: 120 },
                        { label: '优惠金额', prop: 'coupon', width: 120 },
                        { label: '未收金额', prop: 'uncollected', width: 120 },
                        { label: '抹零后金额', prop: 'zeroAfter', width: 120 },
                        { label: '备注', prop: 'remark', width: 220 },
                        { label: '已核销金额（元）', prop: 'writeOff', width: 150 },
                        {
                            label: '操作',
                            input_type: 'button',
                            width: 200,
                            attribute: { fixed: 'right', align: 'center' },
                            list: [
                                // { text: '查看', method: 'showDetails' },
                                { text: '长短款抹零', method: 'customTofixed' }

                            ]
                        }
                    ]
                },
                { input_type: 'customize', customize: 'total', label: '', col: 24, labelWidth: '10px' },
                { input_type: 'title', text: '历史欠费', col: 24 },
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', col: 5 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '8vw', col: 16 },
                { rightBoxItem: { input_type: 'button', label: '核销登记', type: 'node', method: 'registerHistory' }, col: 1.5 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'billScheduleHistory',
                    isRequired: true,
                    border: true,
                    highlightCurrentRow: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', width: 120 },
                        { label: '收费项目性质', prop: 'natureCharges', width: 120 },
                        { label: '收费项目名称', prop: 'serviceName', width: 200 },
                        { label: '计费周期', prop: 'date', width: 220 },
                        { label: '缴费周期', prop: 'day', width: 120 },
                        { label: '本期应收', prop: 'receivable', width: 120 },
                        { label: '调整后应收', prop: 'adjustedReceivable', width: 120 },
                        { label: '优惠金额', prop: 'coupon', width: 120 },
                        { label: '未收金额', prop: 'uncollected', width: 120 },
                        { label: '抹零后金额', prop: 'zeroAfter', width: 120 },
                        { label: '已核销金额（元）', prop: 'writeOff', width: 150 },
                        { label: '欠费', prop: 'unpaidAmount', width: 150 }
                        // {
                        //     label: '操作',
                        //     input_type: 'button',
                        //     width: 200,
                        //     attribute: { fixed: 'right', align: 'center' },
                        //     list: [
                        //         { text: '查看', method: 'showDetails' }
                        //     ]
                        // }
                    ]
                },
                { input_type: 'customize', customize: 'total2', label: '', col: 24, labelWidth: '10px' },
                { input_type: 'title', text: '收费项登记记录', col: 24 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'writeOffRecord',
                    isRequired: true,
                    border: true,
                    showIndex: false,
                    columns: [
                        { label: '到账日期', prop: 'writeOffTime' },
                        { label: '到账金额', prop: 'writeOffNumber' },
                        { label: '剩余未缴金额（元）', prop: 'unpaidAmount' },
                        { label: '核销操作人', prop: 'updateBy' }
                    ]
                }
            ],
            show: false,
            allDisabled: false,
            title: '',
            rowForm: {},
            btnBox: [
                { label: '取消', method: 'reset', col: 8, limit: '' },
                { label: '确认', method: 'adopt', type: 'primary', limit: 'contractFile::submitView::adopt' }
            ],
            total: 0,
            writeOffShow: false,
            writeOffHistoryShow: false,
            writeZero: false,
            rowzero: {}, // 未收金额
            checkCloumuId: null,
            id: null,
            loadingForm: true,
            totalList: {},
            totalHistoryList: {}

        }
    },
    methods: {
        async init (state = 1, row) {
            this.checkCloumuId = "" // 点开弹窗清空收费分项id
            this.rowFormData = this.rowFormDataIndex
            this.loadingForm = true
            this.title = '到账登记'
            this.allDisabled = false
            this.show = true
            this.rowForm = {}
            this.id = row.id
            const res = await getArrearageBillListDerail({ billId: row.id, pageIndex: 1, pageSize: 1000 })
            const { receivableTotal, sumRule, writeOffTotal } = res.data.scatteredData
            this.totalList = { receivableTotal, writeOffTotal }
            const amountDTOMap = new Map()
            res.data.billSchedule.map(item => {
                if (item.adjustedReceivable == 0) {
                    item.adjustedReceivable = null
                }
                item.date = item.billDateStart + '~' + item.billDateEnd
                let serviceDTO = amountDTOMap.get(item.serviceName)
                if (serviceDTO !== undefined) {
                    serviceDTO.receivable += Number(item.receivable)
                    serviceDTO.adjustedReceivable += Number(item.adjustedReceivable)
                    serviceDTO.coupon += Number(item.coupon)
                    serviceDTO.uncollected += Number(item.uncollected)
                } else {
                    serviceDTO = {
                        serviceType: item.serviceType,
                        natureCharges: item.natureCharges,
                        serviceName: item.serviceName,
                        date: item.date,
                        day: item.day,
                        receivable: Number(item.receivable),
                        adjustedReceivable: Number(item.adjustedReceivable),
                        coupon: Number(item.coupon),
                        uncollected: Number(item.uncollected)
                    }
                }
                amountDTOMap.set(item.serviceName, serviceDTO)
            })
            const amountDTOs = []
            amountDTOMap.forEach((value, key) => {
                amountDTOs.push(value)
            })
            const bizBillDetailNewDTOsArr = res.data.billSchedule
            // 历史欠费请求
            const Historyres = await getArrearageBillListHistory({ billId: row.id, pageIndex: 1, pageSize: 1000 })
            if (Historyres.data.billSchedule != null) {
                Historyres.data.billSchedule.map(item => {
                    if (item.adjustedReceivable == 0) {
                        item.adjustedReceivable = null
                    }
                    item.date = item.billDateStart + '~' + item.billDateEnd
                })
                // 筛选掉历史欠费为0的数据
                const billScheduleHistoryArr = Historyres.data.billSchedule.filter(item => {
                    if (item.unpaidAmount == 0 || item.unpaidAmount == null) {

                    } else {
                        return item
                    }
                })
                this.totalHistoryList = { receivableTotalHistory: 0, writeOffTotalHistory: 0, unpaidAmountHistory: 0 }

                // 判断是否有历史欠费,有则启用带有历史欠费的配置项
                if (billScheduleHistoryArr.length > 0) {
                    billScheduleHistoryArr.forEach(item => {
                        this.totalHistoryList.receivableTotalHistory += Number(item.uncollected)
                        this.totalHistoryList.writeOffTotalHistory += Number(item.writeOff)
                        this.totalHistoryList.unpaidAmountHistory += Number(item.unpaidAmount)
                    })
                    this.rowFormData = this.rowFormHistory
                }
                console.log('历史欠费', billScheduleHistoryArr)
                this.totalHistoryList.receivableTotalHistory = this.totalHistoryList.receivableTotalHistory.toFixed(2)
                this.totalHistoryList.writeOffTotalHistory = this.totalHistoryList.writeOffTotalHistory.toFixed(2)
                this.totalHistoryList.unpaidAmountHistory = this.totalHistoryList.unpaidAmountHistory.toFixed(2)
                this.rowForm = { ...row, bizBillDetailNewDTOs: [...bizBillDetailNewDTOsArr], writeOffRecord: res.data.writeOffRecord, billScheduleHistory: [...billScheduleHistoryArr], sumRule, amountDTOs: amountDTOs }
            } else {
                this.rowForm = { ...row, bizBillDetailNewDTOs: [...bizBillDetailNewDTOsArr], writeOffRecord: res.data.writeOffRecord, sumRule, amountDTOs: amountDTOs }
            }

            if (state == 2) {
                this.title = '已提交应收账款查看'
                this.allDisabled = true
            }
            this.loadingForm = false
        },
        async adopt () {
            this.$confirm('是否确定完成到账登记？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await getArrearageBillListConfirm(this.id)
                this.$message.success('操作成功')
                this.show = false
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                })
            })
        },
        // 取消
        async reset () {
            await getArrearageBillListCancel(this.id)
            this.show = false
        },
        // 一键核销
        async writeOffAll () {
            this.loadingForm = true
            this.$confirm('是否确定一键核销？确定后一键核销金额无法撤回', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await setWriteOffAll(this.id)
                this.$message.success(res.msg)
                this.updateList()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                })
            })
            this.loadingForm = false
        },
        handleCurrentChange (val) {
            try {
                this.checkCloumuId = val.id
            } catch (err) {
            }
        },
        async showDetails (row) {
            console.log(row)
            const res = await showWriteOffDetail(row.id)
            this.$set(this.rowForm, 'writeOffRecord', res.data.writeOffRecord)
        },
        async showDetailsHistory (row) {
            console.log(row)
            const res = await getHistoryWriteOffRecord(row.id)
            this.$set(this.rowForm, 'writeOffRecord', res.data.historyWriteOffRecord)
        },
        async customTofixed (row) {
            this.rowzero = {
                rowzero: row.uncollected,
                zeroAfter: row.zeroAfter
            }
            this.loadingForm = true
            this.writeZero = true
            this.loadingForm = false
        },
        // 核销登记
        register () {
            // if (!this.checkCloumuId) return this.$message.error('请先选择需要核销的账单')
            console.log(this.checkCloumuId)
            this.writeOffShow = true
        },
        // 历史核销登记
        registerHistory () {
            // if (!this.checkCloumuId) return this.$message.error('请先选择需要核销的账单')
            console.log(this.checkCloumuId)
            this.writeOffHistoryShow = true
        },
        async updateList () {
            this.rowFormData = this.rowFormDataIndex
            const res = await getArrearageBillListDerail({ billId: this.id, pageIndex: 1, pageSize: 1000 })
            const { receivableTotal, writeOffTotal } = res.data.scatteredData
            this.totalList = { receivableTotal, writeOffTotal }
            res.data.billSchedule.map(item => {
                if (item.adjustedReceivable == 0) {
                    item.adjustedReceivable = null
                }
                item.date = item.billDateStart + '~' + item.billDateEnd
            })
            const bizBillDetailNewDTOsArr = res.data.billSchedule
            // 历史欠费请求
            const Historyres = await getArrearageBillListHistory({ billId: this.id, pageIndex: 1, pageSize: 1000 })
            if (Historyres.data.billSchedule != null) {
                Historyres.data.billSchedule.map(item => {
                    if (item.adjustedReceivable == 0) {
                        item.adjustedReceivable = null
                    }
                    item.date = item.billDateStart + '~' + item.billDateEnd
                })
                // 筛选掉历史欠费为0的数据
                const billScheduleHistoryArr = Historyres.data.billSchedule.filter(item => {
                    if (item.unpaidAmount == 0 || item.unpaidAmount == null) {

                    } else {
                        return item
                    }
                })
                // let { receivableTotalHistory,  writeOffTotalHistory ,unpaidAmountHistory} = Historyres.data['scatteredData']
                this.totalHistoryList = { receivableTotalHistory: 0, writeOffTotalHistory: 0, unpaidAmountHistory: 0 }

                // 判断是否有历史欠费,有则启用带有历史欠费的配置项
                if (billScheduleHistoryArr.length > 0) {
                    billScheduleHistoryArr.forEach(item => {
                        this.totalHistoryList.receivableTotalHistory += Number(item.uncollected)
                        this.totalHistoryList.writeOffTotalHistory += Number(item.writeOff)
                        this.totalHistoryList.unpaidAmountHistory += Number(item.unpaidAmount)
                    })
                    this.rowFormData = this.rowFormHistory
                    this.totalHistoryList.receivableTotalHistory = this.totalHistoryList.receivableTotalHistory.toFixed(2)
                    this.totalHistoryList.writeOffTotalHistory = this.totalHistoryList.writeOffTotalHistory.toFixed(2)
                    this.totalHistoryList.unpaidAmountHistory = this.totalHistoryList.unpaidAmountHistory.toFixed(2)
                    this.$set(this.rowForm, 'billScheduleHistory', [...billScheduleHistoryArr])
                }
            }
            this.$set(this.rowForm, 'bizBillDetailNewDTOs', [...bizBillDetailNewDTOsArr])
            this.$set(this.rowForm, 'writeOffRecord', res.data.writeOffRecord)
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
::v-deep .titlePart {
    padding-left: 15px;
    background: #ebffff;
    width: 100%;
}
::v-deep .el-input-number__increase {
    display: none;
}
::v-deep .el-input-number__decrease {
    display: none;
}
</style>
