<template>
  <div>
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="show"
      width="30%"
      :title="titleText"
      v-loading="addLoading"
      :element-loading-text="isAdd ? '新增中' : '编辑中'"
    >
      <ja-form
        :rules="rules"
        v-model="rowForm"
        :formArray="rowFormData"
        @reset="show = false"
        @save="saveForm"
        v-if="show"
      >
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { setAlertTime } from '@/api'
export default {
  components: {
    JaForm
  },
  inject: ['labelWidth', 'getInit'],
  data() {
    return {
      rules: {
        serviceStartTime: [
          {
            required: true,
            message: '请选择开始时间',
            trigger: 'blur'
          }
        ],
        reminderTime: [
          {
            validator: function (rule, value, callback) {
              if (/^[0-9]*[1-9][0-9]*$/.test(value) == false) {
                callback(new Error('请输入正整数'))
              } else {
                //校验通过
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      },
      rowFormData: [
        {
          input_type: 'date',
          label: '开始时间',
          key: 'serviceStartTime',
          col: 18,
          labelWidth: '130px',
          valueFormat: 'yyyy-MM-dd',
          pickerOptions: {
            disabledDate(date) {
              return date.getTime() <= Date.now()
            }
          }
        },
        {
          input_type: 'input',
          label: '服务到期提醒时间',
          key: 'reminderTime',
          rightBox: { input_type: 'text', text: '天' },
          col: 18,
          styleWidth: '70%',
          labelWidth: '130px',
          maxlength: 5
        }
      ],
      show: false,
      titleText: '时间设置',
      rowForm: {},
      addLoading: false,
      penson: {},
      currentData: '',
      timeDate: ''
    }
  },
  methods: {
    init(row) {
      let data = new Date().getTime() + 60 * 60 * 24 * 1000
      this.timeDate = new Date()
      this.timeDate.setDate(this.timeDate.getDate() + 1)
      this.show = true
      this.titleText = '时间设置'
      let carNumber = row.carNumber
      this.rowForm = {
        id: row.id,
        carNumber: carNumber,
        serviceStartTime: moment(this.timeDate).format("YYYY-MM-DD"),
        reminderTime: 5
      } 
      console.log(this.rowForm);
      
    },
    saveForm() {
      this.addLoading = true
      setAlertTime(this.rowForm)
        .then((res) => {
          if (res.code !== 200) return this.$message.error('设置失败')
          this.$message.success('设置时间成功')
          this.show = false
          this.addLoading = false
          this.getInit()
        })
        .catch((err) => {
          this.addLoading = false
        })
    }
  },
  mounted() {
    let that = this
  }
}
</script>