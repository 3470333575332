<template>
    <div class="edit">
        <el-dialog v-dialogDrag :close-on-click-modal="false" :title="title"  :visible.sync="show" width="35%" @close="close" :before-close="close">
            <ja-form  :rules="rules"  v-model="rowFormData" :formArray="rowForm" @initialization="initialization" @reset="close" @save="saveForm"  labelWidth="100px">
                <template #generateBill>
                    <el-form-item prop="day">
                        <span> 园区统一帐单日   每月第</span>
                        <el-select v-model="rowFormData.day" placeholder="请选择" style="width:20%;margin: 0 10px">
                            <el-option
                                v-for="item in 28"
                                :key="item * 0.1"
                                :label="item"
                                :value="item"
                                :disabled="item.disabled">
                            </el-option>
                        </el-select>
                        <span>日生成帐单</span>
                    </el-form-item>
                </template> 
            </ja-form>
        </el-dialog>
    </div>
</template>

<script> 
import JaForm from '@/components/ja-form'
import {billingDaySetting,getBillingDaySetting} from '@/api'
export default {
    inject:['labelWidth','getInit'],
    components: {
        JaForm
    },
    data () {
        return {
            rowForm: [
            {
                input_type:'customize',
                customize:'generateBill',
                col: 21
            }
            ],
            rowFormData: {
                day:null
            },
            rules: {
            day: [
                { required: true, message: '账单日不能为空', trigger: 'blur' }
            ]
            },
            title: '账单设置',
            show: false,
        }
    },
    methods: {
        async initialization(){
            await getBillingDaySetting().then(res=>{
                this.rowFormData.day = res.data
                
            })
            this.show = true

        },
        /** 弹出框点击确认 */
        saveForm () {
            billingDaySetting(this.rowFormData.day).then(res => {
                if (res.code === 200) {
                    this.$message.success('账单设置成功')
                    this.getInit() 
                    this.close()
                }
            })

        },
        /** 关闭弹出框 */
        close () {
            this.show = false
        }
    }
}
</script>

<style less="scss" scoped>
.el-form-item__content > .el-switch {
    margin-left: 5vw;
}
.el-form-item__content > .el-switch .el-switch__core {
    width: 2.563vw !important;
}
</style>
