<template>
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :showIndex="true"
      @details="details"
      @handleSelectionChange="handleSelectionChange"
      @rowDetail="normalEvent"
      @append="normalEvent({ref:'addEdit',method:'append'})"
      @rowCharge="rowCharge"
      @rowEdit="normalEvent"
      @rowDelete="rowDelete"
      @getInit="getInit"
      @search="search"
      @reset="reset"
    >
    </ja-table>
    <!-- 新增 -->
    <add-edit ref="addEdit" />
    <!-- 收费标准 -->
    <charge-standard ref="charge"/>
    <!-- 详情 -->
    <Details :showDetails.sync="showDetails" @showDetailsDialog="showDetailsDialog"  :roomDetails="roomDetails"/>
  </div>
</template>

<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import ChargeStandard from './childComp/ChargeStandard'
import Details from './childComp/Details.vue'
import { getRoomList, getSpaceDetails, getAllFloor, getBuildingList,delRoom,roomUnbind } from '@/api'

export default {
  title: '租赁区域管理',
  mixins: [table],
  name: 'roomManage',
  components: { AddEdit,ChargeStandard,Details },
  data() {
    return {
      labelWidth: `${this.util.setFontSize(150)}px`,
      filterData: { pageIndex: 1, pageSize: 10 },
      conditionData: [
        {
          input_type: 'select',
          attribute: {
            placeholder: '全部',
            size: 'small'
          },
          key: 'status',
          options:[
            {
              label:'入驻中',
              value:1
            },
            {
              label:'空闲中',
              value:0
            },
          ]
        },
        { input_type: 'input', attribute: {
            placeholder: '房间名称/租户名称',
            size: 'small'
          }, key: 'roomName' },
        { normalBtn: 'search' },
        { normalBtn: 'reset' },
        // { normalBtn:'download'},
        { normalBtn: 'add', limit:'room::management::add'},
        //TODO 没写路径
        // { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '', rqMethod: 'post' }}
      ],
      columns: [
        { label: '区域名称', prop: 'roomName'},
        { label: '状态', prop: 'status' },
        { label: '驻户名称', prop: 'residentName' },
        { label: '所在楼栋', prop: 'building' },
        { label: '所在楼层', prop: 'floor' },
        { label: '户型', prop: 'category'},
        { label: '套内面积', prop: 'insideArea' },
        { label: '建筑面积', prop: 'outsideArea' },
        { label: '房产证面积', prop: 'premisesPermitArea' },
        {
          label: '操作',
          input_type: 'button',
          width: 200,
          attribute: { fixed: 'right', align: 'center' },
          list: [
            {text:'详情',method:'details',limit:'room::management::details'},
            { text: '编辑', method: 'rowEdit', limit:'room::management::update'},
            { text: '删除', method: 'rowDelete', limit:'room::management::del'},
            { text: '收费标准', method: 'rowCharge', limit:'room::management::charge'},
            // { text: '迁出', method: 'rowOutOf',limit:'room::management::remove' }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 300,
      activeRow : null,
      showDetails: false,
      // 房间详情
      roomDetails:{},
      spaceDetails:[]
    }
  },
  created() {
    let param=this.$route.params
    if(param.id){
      this.filterData.buildingId=param.id
      this.getInit()
    }
  },
  mounted() {
    let index2 =  this.conditionData.findIndex(item=>item.key == 'buildingId' )
    this.util.rqOptionsList({who:[this.conditionData[index2]],rqUrl:"/building/list",rqData:{ pageIndex: 1, pageSize: 200 },rqMethod:'post',labelName:'buildingName',valueName:'id'})
  },
  methods: {
    handleSelectionChange(val) {
      console.log('val', val)
    },
    getInit() {
      this.loading = true
      getRoomList(this.filterData).then((res) => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('房间删除后，该房间的相关数据将不再可用，请谨慎操作。').then((res) => {
        delRoom( row.id ).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    }, 
    // 收费标准
    rowCharge(row){
      this.activeRow = row
      this.$refs.charge.rowEdit()
    },
    // 详情开关
    showDetailsDialog(show){
      this.showDetails = show
    },
    //点击详情
    details(row){
      row.controlImageObj = row.controlImage ? this.util.backImgVideoObj([row.controlImage]):[]
      this.roomDetails = row
      this.showDetailsDialog(true)
    },
   
  } 
}
</script>

<style lang="scss" scoped>
.firstcell {
  cursor: pointer;
}
</style>
