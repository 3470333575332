<template>
    <el-main>
        <div class="header">
            <el-switch
                v-model="switchValue"
                @change="setSwitchValue"
                inactive-text="启停巡更"
                v-if="isAuth('videoTour::leftList:startStopPatrol')"
            ></el-switch>
        </div>
        <el-row class="mpBox" :gutter="4">
            <el-col :span="12">
                <video
                    width="98%"
                    height="98%"
                    controls
                >
                    <source
                        src="/images/mp4/movie.mp4"
                        type="video/mp4"
                    >
                </video>
            </el-col>
            <el-col :span="12">
                <video
                    width="98%"
                    height="98%"
                    controls
                >
                    <source
                        src="/images/mp4/movie.mp4"
                        type="video/mp4"
                    >
                </video>
            </el-col>
            <el-col :span="12">
                <video
                    width="98%"
                    height="98%"
                    controls
                >
                    <source
                        src="/images/mp4/movie.mp4"
                        type="video/mp4"
                    >
                </video>
            </el-col>
            <el-col :span="12">
                <video
                    width="98%"
                    height="98%"
                    controls
                >
                    <source
                        src="/images/mp4/movie.mp4"
                        type="video/mp4"
                    >
                </video>
            </el-col>
        </el-row>
    </el-main>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.el-row{
    width: 100%;
    height: 80%;
}
@media screen and (max-width: 991px) {
    #player {
        width: 90%;
        height: 98%;
    }
}
@media screen and (min-width: 992px) {
    #player {
        width: 100%;
        height: 98%;
    }
}
.el-main {
    min-height: 400px;
    height: 82vh;
    background: #fff;
    padding-top: 40px !important;
    position: relative;
    margin-right: 10px;
}
.header {
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px 20px;
    .el-switch {
        .el-switch__core {
            width: 35px !important;
        }
    }
}
.el-main::-webkit-scrollbar {
    width: 1px;
}
.el-main::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(7, 150, 126, 0.2);
}
.el-main::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(21, 31, 15, 0.1);
}
</style>