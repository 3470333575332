<template>
    <div>
        <el-row :gutter="50"
                v-loading="headLoading">
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(83, 110, 196, 1)' }">
                    <p style="white-space: nowrap;">所有设备总数</p>
                    <div class="testBox">{{hendViewData.number || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(141, 197, 113, 1)' }">
                    <p style="white-space: nowrap;">报修率</p>
                    <div class="testBox">{{(Math.round((hendViewData.reportNumber/hendViewData.number)*100 )) || 0}} %</div>
                </el-card>
            </el-col>
        </el-row>
        <ja-table v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :valueFormat="formatter"
                  @getInit="getInit"
                  @search="search"
                  @append="setThreshold"
                  @reset="reset">
        </ja-table>
        <add-edit ref="addRefs"
                  :configValue="configValue"></add-edit>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './components/AddEdit.vue'
import { getStatistics, getHeadData, getThreshold } from "@/api"
export default {
    title: '设备统计',
    mixins: [table],
    components: { AddEdit },
    data() {
        return {
            conditionData: [
                { input_type: 'select', attribute: { placeholder: '设备类型', size: 'small' }, key: 'categoryId', options: [] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '设置阈值', limit: 'deviceManage::statistics::setThreshold' },
            ],
            columns: [
                { label: '设备类型', prop: 'categoryName' },
                { label: '设备总数', prop: 'deviceNumber' },
                { label: '报修次数', prop: 'deviceReportNumber' },
                { label: '季报修率', prop: 'repairRate' },
                { label: '是否阈值范围', prop: 'threshold' },
            ],
            tableData: [],
            loading: false,
            hendViewData: {},
            headLoading: false
        }
    },
    mounted() {
        let index = this.conditionData.findIndex(e => e.key === 'categoryId')
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqUrl: "/device/category", rqData: { params: { pageIndex: 1, pageSize: 200, isAll: '1' } }, rqMethod: 'get', labelName: 'categoryName', valueName: 'id'
        })
    },
    methods: {
        async getInit() {
            this.loading = true
            this.headLoading = true
            await getThreshold('device_report').then(res => {
                this.configValue = res.data.configValue
            })
            await getStatistics({ ...this.filterData }).then((res) => {
                let { data, total } = res.data
                this.loading = false
                data.map(item => {
                    let repairRate = Math.round((item.deviceReportNumber / item.deviceNumber) * 100)
                    item.threshold = repairRate <= Number(this.configValue) ? '是' : '否'
                    item.repairRate = repairRate + '%'
                })

                this.tableData = data
                this.total = total
            })
            await getHeadData().then(res => {
                this.hendViewData = res.data
                this.headLoading = false
            })

        },
        setThreshold() {
            this.$refs.addRefs.init(this.configValue)
        }
    }
}
</script>
<style lang="scss" scoped>
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;
    .el-card {
        color: #fff;
        border-radius: 0.4vw;
        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 2vw;
        }
    }
}
</style>