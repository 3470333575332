<template>
  <router-view/>
</template>

<script>
export default {
  title: "能源管理",
}
</script>

<style scoped>

</style>
