<template>
    <router-view />
</template>

<script>
export default {
    title: "财务模块",
    name: 'financeModule'
}
</script>

<style scoped>
</style>


