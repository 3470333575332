<template>
    <div>
        <ja-form ref="jaForm"
                 v-model="rowForm"
                 :rules="rules"
                 :label-width="labelWidth"
                 :formArray="rowFormData"
                 :btnBox="[]"
                 @expenseAllocation="expenseAllocation"
                 @reject="reject"
                 @adopt="adopt">
            <template #uplpadfaceImg>
                <upload-file @pdfTitleClickEvemt="pdfTitleClickEvemt"
                             ref="Upload"
                             :initFileList="[...initFileList]"
                             :noButton="true"
                             :hideDel="true"
                             accept="pdf" />
            </template>
        </ja-form>

        <!--费用配置-->
        <rent-allocation ref="rentA"
                         :allDisabled="true"></rent-allocation>
    </div>
</template>
<script>
import RentAllocation from '@/views/home/contractManage/childComp/rentAllocation'
export default {
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        }
    },
    components: { RentAllocation },
    data() {
        return {
            labelWidth: `${this.util.setFontSize(150)}px`,
            rowFormData: [
                { input_type: 'input', label: '合同编号', key: 'contractCode', col: 12, styleWidth: '90%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同名称', key: 'contractName', col: 12, styleWidth: '90%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同类型', key: 'contractTypeName', col: 12, styleWidth: '90%', disabled: true },
                { input_type: 'date', label: '租期', key: 'leaseTerm', method: 'selectDate', type: 'daterange', col: 12, valueFormat: 'yyyy-MM-dd', unlinkpanels: true, disabled: true },
                { input_type: 'input', label: '驻户名称', key: 'tenantName', col: 12, styleWidth: '90%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '营业执照号/身份证号', key: 'cardCode', col: 12, styleWidth: '90%', maxlength: 10, disabled: true },
                {
                    input_type: 'select', label: '驻户类型', key: 'tenantType', col: 12, placeholder: '驻户类型', styleWidth: '90%', maxlength: 30, options: [
                        { value: '0', label: "商业" },
                        { value: '1', label: "办公" }
                    ], disabled: true
                },
                {
                    input_type: 'table', label: '驻户租赁单元', col: 20, noSearch: true, showPage: false, key: 'roomInfo', isRequired: true, border: true, table_MaxHeight: 500, //roomIdstableAttribute: { showSummary: true },
                    columns: [
                        { label: '驻赁单元名称', prop: 'roomName' },
                        { label: '租赁单元类型', prop: 'roomTypeValue' },
                        { label: '所在楼栋', prop: 'buildingName' },
                        { label: '所在楼层', prop: 'floorName', attribute: { showOverflowTooltip: false } },
                        { label: '套内总体使用面积', prop: 'totalArea' },
                        {
                            label: '操作',
                            input_type: 'button',
                            width: '150',
                            list: [
                                { text: '查看', method: 'expenseAllocation', limit: 'contract::manage::expense_allocation' }
                            ]
                        }
                    ]
                },
                {
                    input_type: 'customize',
                    customize: 'uplpadfaceImg',
                    label: '合同附件',
                    key: 'urlLists',
                    col: 24,
                    disabled: true
                },
            ],
            rowForm: {},
            rules: {},
            initFileList: [],
        }
    },
    mounted() {
        this.init(this.data)
    },
    methods: {
        init(row) {
            let obj = { ...row }
            obj.leaseTerm = [obj.startTime, obj.endTime]
            if (obj.attachmentListInfo.length > 0) {
                this.initFileList = obj.attachmentListInfo.map((item) => {
                    if (!item) return
                    return {
                        name: item.attachmentName ? item.attachmentName : undefined,
                        url: item.attachmentUrl ? item.attachmentUrl : item,
                        raw: { type: item.attachmentType === 'pdf' ? 'pdf' : 'image' },
                        status: 'success',
                        response: { data: item }
                    }
                })
            } else {
                this.initFileList = []
            }
            //判断是否变更过驻户名称
            if(row.changeTenantName){
               this.rowFormData[4].key = 'changeTenantName'
            }else{
              this.rowFormData[4].key = 'tenantName'
            }
            this.rowForm = { ...obj }
        },
        // 配置费用
        expenseAllocation(row) {
            let { contractType, id,changeId } = this.rowForm
            let payServiceList = []
            if (this.rowForm.bizPayServiceNewDTOS) {
                payServiceList = [...this.rowForm.bizPayServiceNewDTOS]
            }
            this.$refs.rentA.init({ ...row, contractType, contractId: id,changeId }, [], payServiceList)
        }
    }
}
</script>
<style lass="sass" scoped>
::v-deep .el-input-number .el-input__inner {
    padding-left: 0;
}
</style>
