<template>
  <!-- 缴费单管理 -->
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @dictonaList="dictonaList"
      @getInit="getInit"
      @search="search"
      @reset="reset"
      :showIndex="false"
      @showDetail="showDetail"
      :valueFormat="formatter"
    >
    </ja-table>
    <show-information ref="showInformation"></show-information>
  </div>
</template>

<script>
import { rqBill } from '@/api'
import table from '@/views/mixins/table'
import ShowInformation from './components/ShowInformation.vue'

export default {
  title: '缴费单管理',
  mixins: [table],
  name: 'index',
  components: {
    ShowInformation
  },
  data() {
    return {
      filterData: {},
      conditionData: [
        {
          input_type: 'input',
          attribute: {
            placeholder: '驻户名称',
            size: 'small'
          },
          key: 'tenantName'
        },
        {
          input_type: 'input',
          attribute: {
            placeholder: '合同编号',
            size: 'small'
          },
          key: 'contractCode'
        },
        {
          input_type: 'date',
          value: 'billDate',
          type: 'month',
          valueFormat: 'yyyy-MM',
          placeholder: '选择月份'
        },
        { normalBtn: 'search' },
        { normalBtn: 'reset' }
      ],
      columns: [
        { label: '驻户名称', prop: 'tenantName' },
        { label: '合同编号', prop: 'contractCode' },
        { label: '账单时间', prop: 'billDate' },
        { label: '本期合计应收（元）', prop: 'amountReceivable' },
        { label: '本期已缴（元）', prop: 'amountWriteOff' },
        { label: '到账时间', prop: 'paymentDate' },
        { label: '状态', prop: 'status' },
        { label: '备注', prop: 'remark' },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          list: [
            {
              text: '查看',
              method: 'showDetail',
              limit: 'billpayment::manage::detail'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      rowForm: {}
    }
  },
  created() {},
  methods: {
    getInit() {
      this.loading = true
      let rqData = this.rqParmas()
      rqBill(rqData).then((res) => {
        this.tableData = res.data.data
        this.loading = false
        this.total = res.data.total
      })
    },
    rqParmas() {
      return {
        pageSize: this.filterData.pageSize,
        pageIndex: this.filterData.pageIndex,
        status: 1,
        tenantName: this.filterData.tenantName || '',
        contractCode: this.filterData.contractCode || '',
        billDate: this.filterData.billDate || ''
      }
    },
    //查看
    showDetail(row) {
      this.$refs.showInformation.init(row)
    },
    formatter(val, key) {
      if (key === 'status') {
        return ['未缴纳', '已缴纳 '][val || 0]
      }
      return val || ''
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-col .el-form-item .el-input__inner {
  height: 40px;
}
/deep/ .el-select {
  width: 100% !important;
}
</style>
