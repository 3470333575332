<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="show"
                   width="55%"
                   :title="titile">
            <ja-form ref="jaForm"
                     v-model="rowForm"
                     :rules="rules"
                     :label-width="labelWidth"
                     :formArray="rowFormData"
                     :loadingForm="loadingForm"
                     :btnBox="btnBox"
                     @reset="show = false"
                     @confirmTermination="confirmTermination"
                     @expenseAllocation="expenseAllocation"
                     @reject="reject"
                     @adopt="adopt">
                <template #uplpadfaceImg>
                    <upload-file @pdfTitleClickEvemt="pdfTitleClickEvemt"
                                 ref="Upload"
                                 :initFileList="[...initFileList]"
                                 :noButton="true"
                                 :hideDel="true"
                                 accept="pdf" />
                </template>
            </ja-form>
        </el-dialog>
        <!--费用配置-->
        <rent-allocation ref="rentA"
                         :allDisabled="true"></rent-allocation>
        <!-- 在线预览pdf -->
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="pdfShow"
                   width="60%">
            <pdf ref="pdf"
                 v-loading="loading"
                 element-loading-text="拼命加载中"
                 element-loading-spinner="el-icon-loading"
                 element-loading-background="rgba(0, 0, 0, 0.8)"
                 :src="pdfUrl"
                 v-for="i in numPages"
                 :key="i"
                 :page="i"
                 class="pdf">
            </pdf>
        </el-dialog>
    </div>
</template>
<script>
import RentAllocation from '@/views/home/contractManage/childComp/rentAllocation'
import pdf from 'vue-pdf'
import {
    addTermination,
    approvalTermination,
    getTerminationDetails
} from '@/api'

export default {
    inject: ['labelWidth', 'getInit'],
    components: { RentAllocation, pdf },
    data() {
        return {
            labelWidth: `${this.util.setFontSize(150)}px`,
            rowFormData: [
                { input_type: 'input', label: '合同编号', key: 'contractCode', col: 12, styleWidth: '90%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同名称', key: 'contractName', col: 12, styleWidth: '90%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同类型', key: 'contractTypeName', col: 12, styleWidth: '90%', disabled: true },
                { input_type: 'date', label: '租期', key: 'leaseTerm', method: 'selectDate', type: 'daterange', col: 12, valueFormat: 'yyyy-MM-dd', unlinkpanels: true, disabled: true },
                { input_type: 'date', label: '计租开始日期', key: 'billingTime', styleWidth: '80%', valueFormat: 'yyyy-MM-dd', col: 12, styleWidth: '80%', disabled: true },
                { input_type: 'input', label: '驻户名称', key: 'tenantName', col: 12, styleWidth: '90%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '营业执照号/身份证号', key: 'cardCode', col: 12, styleWidth: '90%', maxlength: 10, disabled: true, placeholder: ' ' },
                {
                    input_type: 'table', label: '驻户租赁单元', col: 20, noSearch: true, showPage: false, key: 'roomInfo', isRequired: true, border: true, table_MaxHeight: 500, //roomIdstableAttribute: { showSummary: true },
                    columns: [
                        { label: '驻赁单元名称', prop: 'roomName' },
                        { label: '租赁单元类型', prop: 'roomTypeValue' },
                        { label: '所在楼栋', prop: 'buildingName' },
                        { label: '所在楼层', prop: 'floorName', attribute: { showOverflowTooltip: false } },
                        { label: '套内总体使用面积', prop: 'totalArea' },
                        {
                            label: '操作',
                            input_type: 'button',
                            width: '150',
                            list: [
                                { text: '查看', method: 'expenseAllocation', limit: 'contract::manage::expense_allocation' }
                            ]
                        }
                    ]
                },
                {
                    input_type: 'customize',
                    customize: 'uplpadfaceImg',
                    label: '合同附件',
                    key: 'urlLists',
                    col: 24,
                    disabled: true
                },
            ],
            titile: '',
            rowForm: {},
            rules: {},
            show: false,
            initFileList: [],
            loadingForm: false,
            btnBox: [],
            appendLength: null,
            pdfShow: false,
            numPages: null
        }
    },
    mounted() {
        this.appendLength = this.rowFormData.findIndex(item => item.key == 'urlLists')
    },
    methods: {
        /**
         * state 1打开手动终止，2打开审批，3打开查看
         */
        async init(state, row) {
            this.loadingForm = false
            let obj = {}
            if (state != 1) {
                let res = await getTerminationDetails({ contractCode: row.contractCode, terminationId: row.terminationId })
                obj = { ...res.data }
                obj.statusName = ['已终止', '已驳回', '待审批', '已通过'][obj.terminationAuditStatus - 1]
            } else {
                obj = { ...row }
            }
            obj.leaseTerm = [obj.startTime, obj.endTime]
            if (Array.isArray(obj.attachmentListInfo)) {
                this.initFileList = obj.attachmentListInfo.map((item) => {
                    if (!item) return
                    return {
                        name: item.attachmentName ? item.attachmentName : undefined,
                        url: item.attachmentUrl ? item.attachmentUrl : item,
                        raw: { type: item.attachmentType === 'pdf' ? 'pdf' : 'image' },
                        status: 'success',
                        response: { data: item }
                    }
                })
            } else {
                this.initFileList = []
            }

            this.rowForm = { ...obj }

            //清空前状态
            this.rowFormData.splice(this.appendLength + 1)
            //表单合并，现有部分全部公用，使用类型区分开各表单
            if (state == 1) {
                this.manual()
            } else if (state == 2) {
                this.approval()
            }
            else if (state == 3) {
                this.showdetails()
            }
            this.show = true
        },
        // 配置费用
        expenseAllocation(row) {
            let { contractType, id } = this.rowForm
            let payServiceList = []
            if (this.rowForm.bizPayServiceNewDTOS) {
                payServiceList = [...this.rowForm.bizPayServiceNewDTOS]
            }
            this.$refs.rentA.init({ ...row, contractType, contractId: id }, [], payServiceList)
        },

        //手动终止
        manual() {
            this.titile = '手动终止'
            let rowFormData = [
                { input_type: 'date', label: '终止生效日期', key: 'terminationDate', col: 12, styleWidth: '90%', pickerOptions: { disabledDate: this.disabledDateFun }, valueFormat: 'yyyy-MM-dd' }
            ]
            let btnBox = [
                { label: '取消', method: 'reset', col: 8, },
                { label: '确定', method: 'confirmTermination', type: 'primary', monitor: true }
            ]
            this.rules = {
                terminationDate: [{ required: true, trigger: 'change', message: '终止生效日期不能为空' }],
            }
            this.btnBox = btnBox
            this.rowFormData = this.rowFormData.concat(rowFormData)
        },
        disabledDateFun(time) {
            let endDate = this.rowForm.leaseTerm[1]
            return time.getTime() < Date.now() || time.getTime() > new Date(endDate)
        },
        //确定终止
        async confirmTermination() {
            this.loadingForm = true
            let { id: contractId, terminationDate } = this.rowForm
            try {
                await addTermination({ contractId, terminationDate })
                this.loadingForm = false
                this.show = false
                this.$emit('getInit')
            } catch (err) {
                this.loadingForm = false
                this.show = false
            }
        },
        //审批
        approval() {
            this.titile = '手动终止审批'
            let btnBox = [
                { label: '驳回', method: 'reject', col: 8, },
                { label: '通过', method: 'adopt', type: 'primary', }
            ]
            this.btnBox = btnBox
        },
        //通过
        adopt() {
            this.$confirm('合同终止后，该合同相关的收费项目将不再生效，请谨慎操作。', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.loadingForm = true
                let { terminationId, id: contractId } = this.rowForm
                try {
                    await approvalTermination({ terminationId, contractId, status: 1 })
                    this.loadingForm = false
                    this.show = false
                    this.getInit()
                    this.$message.success('操作成功')
                } catch (err) {
                    this.loadingForm = false
                    this.show = false
                    this.getInit()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });


        },
        //驳回
        reject() {
            this.$confirm('是否确定驳回？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.loadingForm = true
                let { terminationId, id: contractId } = this.rowForm
                try {
                    await approvalTermination({ terminationId, contractId, status: 2 })
                    this.loadingForm = false
                    this.show = false
                    this.getInit()
                    this.$message.success('操作成功')
                } catch (err) {
                    this.loadingForm = false
                    this.show = false
                    this.getInit()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        //查看
        showdetails() {
            this.titile = '已终止查看合同'
            let rowFormData = [
                { input_type: 'input', label: '合同批次', key: 'contractBatch', col: 12, disabled: true, styleWidth: '90%', placeholder: ' ' },
                { input_type: 'input', label: '手动终止申请人', key: 'terminationApplyName', col: 12, disabled: true, styleWidth: '90%', placeholder: ' ' },
                { input_type: 'input', label: '申请时间', key: 'terminationApplyDate', col: 12, disabled: true, styleWidth: '90%', placeholder: ' ' },
                { input_type: 'input', label: '终止时间', key: 'terminationDate', col: 12, disabled: true, styleWidth: '90%', placeholder: ' ' },
                { input_type: 'input', label: '状态', key: 'statusName', col: 12, disabled: true, styleWidth: '90%', placeholder: ' ' },
                { input_type: 'input', label: '终止方式', key: 'terminationMethod', col: 12, disabled: true, styleWidth: '90%', placeholder: ' ' },
            ]
            let btnBox = []
            this.btnBox = btnBox
            this.rowFormData = this.rowFormData.concat(rowFormData)
        },
        pdfTitleClickEvemt(item) {
            // 使用线上ip无法访问
            //判断当前环境
            if (process.env.NODE_ENV === 'development') {
                let urlList = item.url.split('/')
                this.pdfUrl = `/upload/${urlList[urlList.length - 1]}`
            } else {
                this.pdfUrl = item.url
            }
            this.pdfShow = true

            let loadingTask = pdf.createLoadingTask(this.pdfUrl)
            loadingTask.promise.then(pdf => {
                this.numPages = pdf.numPages
            }).catch(err => {
                console.error('pdf 加载失败', err);
            })
            // 加载loading
            setTimeout(() => {
                this.loading = false
            }, this.numPages * 300);
        },

    }
}
</script>
<style lass="less" scoped>
::v-deep .facac {
    justify-content: flex-end !important;
}
</style>
