<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  :editDataFormat="editDataFormat"
                  :valueFormat="valueFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @rowShow="rowShow"
                  @rowChange="rowChange"
                  @takeEffect="takeEffect"
                  @rowAdd="rowAdd"> </ja-table>

        <add-edit ref="addEdieRef"></add-edit>
        <row-change ref="rowChangeRef"></row-change>
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import { getChargingSettings, editChargingSettings } from '@/api'
import AddEdit from './components/addEdit.vue'
import RowChange from './components/rowChange.vue'
export default {
    title: '收费标准设置',
    name: 'custom',
    mixins: [table],
    components: {
        AddEdit,
        RowChange
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'serviceCode', attribute: { placeholder: '收费项目编号', size: 'small', } },
                { input_type: 'select', key: 'serviceType', attribute: { placeholder: '收费项目类型', size: 'small', }, options: [] },
                { input_type: 'select', key: 'natureCharges', attribute: { placeholder: '收费项目性质', size: 'small', }, options: [] },
                { input_type: 'input', key: 'serviceName', attribute: { placeholder: '收费项目名称', size: 'small', } },
                { input_type: 'input', key: 'feesName', attribute: { placeholder: '收费标准名称', size: 'small', } },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '收费项目编号', prop: 'serviceCode' },
                { label: '收费项目类型', prop: 'serviceType' },
                { label: '收费项目性质', prop: 'natureCharges' },
                { label: '缴费周期', prop: 'dayName' },
                { label: '收费项目名称', prop: 'serviceName' },
                { label: '收费标准名称', prop: 'feesName' },
                { label: '收费标准', prop: 'fees' },
                { label: '单价（元）', prop: 'unitPrice' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: '150px',
                    list: [
                        { text: '查看', method: 'rowShow',limit: 'chargeConfig::chargingSettings::rowShow' },
                        { text: '添加', method: 'rowAdd',limit: 'chargeConfig::chargingSettings::rowAdd' },
                        { text: '生效', method: 'takeEffect',limit: 'chargeConfig::chargingSettings::takeEffect' },
                        { text: '变更', method: 'rowChange',limit: 'chargeConfig::chargingSettings::rowChange' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
        }
    },
    mounted() {
        let length1 = this.conditionData.findIndex(e => e.key === 'serviceType')
        let length2 = this.conditionData.findIndex(e => e.key === 'natureCharges')
        this.lengthA = length2
        this.util.rqOptionsList({
            who: this.conditionData[length1],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'chargeitem_type' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
        this.util.rqOptionsList({
            who: this.conditionData[length2],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'nature_charges' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        getInit() {
            getChargingSettings(this.filterData).then(res => {
                res.data.data.map(item => {
                    if (item.day) {
                        item.dayName = `每月${item.day}日`
                    } else {
                        item.dayName = '-'
                    }
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        rowChange(row) {
            console.log(row);
            this.$refs.rowChangeRef.init(row)
        },
        rowShow(row) {
            this.$refs.addEdieRef.init(3, row);
        },
        rowAdd(row) {
            this.$refs.addEdieRef.init(1, row)
        },
        takeEffect(row) {
            this.$confirm('是否确定生效该收费项目？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                editChargingSettings({ ...row, isEffective: 1 }).then(res => {
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '生效成功!'
                    });
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        editDataFormat(row, btn) {
            if (row.isEffective === 0 && btn.text == '添加' || row.isEffective === 1 && btn.text == '变更' || row.isEffective === 2 && btn.text == '生效' || btn.text == '查看') {
                return true
            } else {
                return false
            }
        },
        valueFormat(val, clo) {
            if (clo == 'unitPrice') {
                if (val) {
                    return val
                } else {
                    return '-'
                }
            }
            return val
        }

    }
}
</script>