<template>
  <!-- 数字字典 -->
  <div class="allwh">
    <ja-table ref="tst"
              v-model="filterData"
              :conditionData="conditionData"
              :columns="columns"
              :tableData="tableData"
              :total="total"
              :loading="loading"
              @append="append"
              @rowEdit="rowEdit"
              :valueFormat="formatter"
              @dictonaList="dictonaList"
              @rowDelete="rowDelete"
              @getInit="getInit"
              @search="search"
              @reset="reset">
      <template #typeCode="{row}">
        <div class="firstcell el-button--text" @click="turnPage(row)">{{row.typeCode}}</div>
      </template>
      <template #img="{row}">
        <img :src="row.peoplePic"
             alt="">
      </template>
    </ja-table>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false"
               :visible.sync="showDetail"
               title="修正凭证"
               :width="`${util.setFontSize(800)}px`">
    </el-dialog>
    <!-- 新增/编辑 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false"
               :visible.sync="showEditAll"
               width="40%"
               :title="isAdd?'新增':'编辑'">
      <ja-form :rules="rules"
               :label-width="labelWidth"
               v-model="rowForm"
               :formArray="rowFormData"
               @reset="showEditAll = false"
               @save="saveForm"
               v-if="showEditAll"
               v-loading="loading_form.isShow"
               :element-loading-text="loading_form.text"
               :element-loading-spinner="loading_form.icon"
               :element-loading-background="loading_form.bg"
               :element-loading-custom-class="loading_form.formLoading">
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import { dictionMangerList, addDiction, editDiction, delDiction } from '@/api'
import table from '@/views/mixins/table'
import { dicManagerQuery, dicManagerColumn, dicManagerFormData, dicManagerRules } from '../systemSettingColumn'

export default {
  title: '字典管理',
  mixins: [table],
  name: 'index',
  data () {
    return {
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: dicManagerQuery,
      columns: dicManagerColumn,
      tableData: [],
      // 新增/编辑
      rowFormData: dicManagerFormData,
      loading: false,
      total: 30,
      showDetail: false,
      labelWidth: `${this.util.setFontSize(120)}px`,
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      isAdd: true, // 新增/编辑
      rules: dicManagerRules
    }
  },
  created () {

  },
  methods: {
    getInit () {
      this.loading = true
      // 获取楼层列表
      dictionMangerList({
        pageIndex: this.filterData.pageIndex,
        pageSize: this.filterData.pageSize,
        id: this.filterData.id,
        typeLabel: this.filterData.typeLabel,
        typeCode: this.filterData.typeCode,
        status: this.filterData.status,
        remark: this.filterData.remark
      }).then(({ data }) => {
        this.tableData = data.data
        this.total = data.total
        this.loading = false
      })
    },
    // 新增
    append () {
      this.isAdd = true
      this.rowForm = {
        status: 0
      }
      this.showEditAll = true
    },
    // 编辑
    rowEdit (row) {
      this.isAdd = false
      this.showEditAll = true
      this.$nextTick(() => {
        this.rowFormData.forEach(item => {
          this.$set(this.rowForm, item.key, row[item.key])
        })
      })
      this.$set(this.rowForm, 'id', row.id)
      this.$set(this.rowForm, 'dictCode', row.dictCode)
    },
    // 列表跳转
    // dictonaList (row) {
    //   this.$router.push({ path: '/home/sysSetting/dictionaList', query: { dictType: row.dictType } })
    // },
    // 删除
    rowDelete (row) {
      this.$confirm('是否确认删除该字典').then(res => {
        delDiction(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
    // 保存
    saveForm () {
      this.loading_form.isShow = true
      if (this.isAdd) {
        addDiction(this.rowForm).then(({ msg }) => {
          this.loading_form.isShow = false
          this.showEditAll = false
          this.$message.success(msg)
          this.getInit()
        }).catch(err => {
          console.log(err)
          this.loading_form.isShow = false
        })
      } else {
        editDiction(this.rowForm).then(res => {
          this.loading_form.isShow = false
          this.showEditAll = false
          this.$message.success(res.msg)
          this.getInit()
        }).catch(err => {
          console.log(err)
          this.loading_form.isShow = false
        })
      }
    },
    formatter (val, key) {
      if (key === 'status') {
        const statusVal = { 0: '正常', 1: '停用' }
        return statusVal[val]
      }
      return val
    },
    turnPage(row){
      console.log(row.id)
      this.$router.push({ path: '/home/sysSetting/dictionaryData', query: { typeCode: row.typeCode } })
    }
  },

  watch: {
    showEditAll (val) {
      if (!val) {
        this.$set(this, 'rowForm', {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cell {
  img {
    height: 70px;
  }
}
/deep/ .el-input__inner {
  height: 40px;
}
/deep/ .el-select {
  width: 100% !important;
}
</style>
