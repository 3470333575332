<template>
  <div :class="['app-container','fromForm formTable']"
       :model="nowFilterData">
    <!-- 操作栏 -->
    <div class="form"
         v-if="!noSearch">
      <el-form :inline="true"
               v-bind="formAttribute || {}">
        <div style="display: flex;justify-content:space-between;">
          <div :class="[corIndex==0?'left':'right']"
               style="display:flex;"
               v-for="(corItem,corIndex) in ['左边','右边']"
               :key="corIndex">
            <el-form-item v-for="(item,index) in conditionData"
                          :key="index"
                          :label="hasLabel(item)?item.label:''"
                          :prop="item.prop"
                          v-if="corIndex==0 ?(!item.rightPos && !item.hide && (item.limit?isAuth(item.limit):true)):(item.rightPos && !item.hide && (item.limit?isAuth(item.limit):true))">
              <template v-if="item.slotName">
                <slot :name="item.slotName"></slot>
              </template>
              <template v-else>
            
                <!-- 搜索 -->
                <el-input v-if="item.input_type === 'input'"
                          v-model="nowFilterData[item.key]"
                          v-bind="item.attribute?{size: 'small',...item.attribute}: {size: 'small'}"
                          @input="item.valueType=='number'?nowFilterData[item.key]=nowFilterData[item.key].replace(/[^\d]/g,''):''"
                          @keyup.enter.native="getInit"
                          :style="{width:item.styleWidth}" />

                <!-- 按钮 -->
                <el-button class="search-button"
                           v-if="item.input_type === 'button'&&(item.limit?isAuth(item.limit):true)"
                           :size="item.size || 'small'"
                           v-bind="item.attribute?{size: 'small',...item.attribute} : {size: 'small'}"
                           @click="itemEvent(item.method,item)">
                  {{item.text || item.label}}
                </el-button>
              </template>
            </el-form-item>
           
          </div>
        </div>
      </el-form>
      <div class="right">
        <slot name="right"></slot>
      </div>
    </div>
    <div class="table">
      <div class="tableHead"> <p>类型编码</p> <p>类型名称</p> </div>

      <el-tree ref="tree"
               :data="tableData"
               node-key="id"
               :props="defaultProps"
               :show-checkbox="false"
               :default-expand-all="true"
               :highlight-current="false"
               :expand-on-click-node="false">

        <span class="custom-tree-node"
              slot-scope="{ node, data }"
              @mouseenter="() => ennterFun(node, data)"
              @mouseleave="() => leaveFun(node, data)">
          <span>{{ node.label }}</span>
          <span>
            <el-button :class="data.isShow ? 'initial' : 'hidden' "
                       type="text"
                       size="mini"
                       v-if="node.level === 1"
                       @click="() => append(data)">
              增加
            </el-button>
            <el-button :class="data.isShow ? 'initial' : 'hidden' "
                       type="text"
                       size="mini"
                       v-if="node.level === 2"
                       @click="() => edit(data)">
              编辑
            </el-button>
            <!-- 启用/禁用 0启用 1禁用 -->
            <el-switch :class="data.isShow ? 'initial' : 'hidden' "
                       :value="data.del_flag === 0 ? true : false"
                        @change="() => rowDelete(data)"
                       size="mini"
                       active-color="#13ce66"
                       inactive-color="#ff4949">
            </el-switch>
            <!-- <el-button :class="data.isShow ? 'initial' : 'hidden' "
                       type="text"
                       size="mini"
                       v-if="node.level === 2"
                       @click="() => remove(node, data)">
              删除
            </el-button> -->
          </span>
        </span>

      </el-tree>

    </div>
  </div>
</template>

<script>
/** 
 *  conditionData: 表格上方的搜索/选择操作栏：input，下拉选择，按钮
 * columns: 表头数据
*/
import { outPutCommonExcell } from '@/api'

export default {
  name: 'formCont',
  data () {
    return {
      nowFilterData: this.value,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  props: {
    showEditAll: {
      type: Boolean,
      default: false
    },
    // 表单属性
    formAttribute: {
      type: Object,
      default: () => { }
    },
    // 是否没有搜索头
    noSearch: {
      type: Boolean,
      default: false
    },
    // 条件数组
    conditionData: {
      type: Array,
      default: () => []
    },
    // 条件选定参数
    value: {
      type: Object,
      default: function () {
        return {
          pageIndex: 1,
          pageSize: 5
        }
      }
    },
    // table的border
    border: {
      type: Boolean,
      default: false
    },
    // table数据
    tableData: {
      type: Array,
      default: () => []
    },
    // table加载动画
    loading: {
      type: Boolean, ault: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    getCheckedNodes () { // 删除大类
      const list = this.$refs.tree.getCheckedKeys()
      if (list.length > 0) {
        this.$confirm('是否确认删除该类型？').then(res => {
          this.$message.success("已删除")
        })
      } else {
        this.$message({ message: '请先选择删除的类型！', type: 'warning' });
      }
    },
    append (data) { // 增加小类
      this.$emit('append', data)
    },
    edit (data) { // 编辑小类
      this.$emit('rowEdit', data)
    },
    remove (node, data) { // 删除小类
      this.$emit('rowDelete', '')
    },
    rowDelete (data) { // 0启用 1禁用
      this.$emit('rowDelete', data)
    },
    ennterFun (node, data) { // 悬浮进入
      data.isShow = true
    },
    leaveFun (node, data) { // 悬浮离开
      data.isShow = false
    },
    async init () { // 初始化 加动态下拉框
      for (let i = 0; i < this.conditionData.length; i++) {
        const el = this.conditionData[i]
        if ((el.input_type === 'select') && typeof (el.options) === 'function') {
          const res = await el.options()
          el.options = res
          this.$forceUpdate()
        }
      }
    },
    // button事件
    itemEvent (method, row, val) {
      this.$emit(method, row, val)
    },
    // 是否需要显示label
    hasLabel (item) {
      // 有label并且不是按钮时
      if (item.label && item.input_type !== 'button') {
        // 传入不需要的label时
        if (item.noLabel) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
  },
  watch: {
    value (val) {
      this.nowFilterData = val
    },
    nowFilterData (val) {
      this.$emit('input', val)
    }
  }
}

</script>

<style lang="scss" scoped>
  @import '~@/style/mixin.scss';
  .app-container {
    height: 100%;

    .table {
      height: calc(100% - 80px);

      .el-tree {
        height: calc(100% - 28px);
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }

  /** 表单 */
  .form {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 15px;
    background-color: $theme-color;
    justify-content: space-between;
    margin: 15px;
    .el-form-item {
      margin-bottom: 0;
    }
    .right {
      display: flex;
      align-items: center;
    }
  }

  .formTable {
    /deep/ .el-date-editor .el-range-separator {
      width: 25px;
    }
    /deep/ .el-range-editor.el-input__inner {
    }
  }

  .formTable {
    &.fromForm {
      /deep/ .el-table thead tr th {
        padding: 0;
      }
    }
    /deep/ .el-select {
      width: 100%;
    }
    .form {
      /deep/ .el-form.el-form--inline {
        width: 100%;
      }
    }
    .input_text {
      max-width: 100%;
      border: 1px solid #dcdfe6;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 0.208vw;
      border: 1px solid #dcdfe6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 2.083vw;
      line-height: 2.083vw;
      outline: 0;
      padding: 0 0.781vw;
      -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }
  }

  /** 树形控件 */
  .table {
    padding: 10px 0;
    margin: 0px 15px;
    background-color: $theme_background_fff;
    .tableHead {
      background-color: $theme_background_D6D6D6;
      font-size: $theme_fontsize_14;
      padding: 11px 24px;
      p {
        display: inline-block;
        &:last-child {
          padding-left: 30px;
        }
      }
    }
  }

  /deep/ .tree {
    padding: 30px 0 10px 0;
  }

  /deep/ .el-tree-node__content {
    width: 100%;
    height: 36px;
    .expanded {
      visibility: hidden;
    }
    .custom-tree-node {
      padding-right: 24px;
    }
    .el-button + .el-button {
      margin-left: 16px;
    }
  }

  /deep/ .el-tree-node__children {
    .el-checkbox {
      visibility: hidden;
    }
    .custom-tree-node {
      padding-left: 40px;
      padding-right: 24px;
    }
    .el-button + .el-button {
      margin-left: 16px;
    }
  }

  /deep/ .el-switch {
    transform: scale(0.7);
    margin-left: 10px;
  }
</style>
