<template>
    <div>
        <el-row :gutter="50"
                v-loading="headLoading">
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(83, 110, 196, 1)' }">
                    <p style="white-space: nowrap;">有效工单</p>
                    <div class="testBox">{{hendViewData.effectiveNumber || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(141, 197, 113, 1)' }">
                    <p style="white-space: nowrap;">处理率</p>
                    <div class="testBox">{{hendViewData.processRate * 100 || 0}}%</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(250, 200, 89, 1)' }">
                    <p style="white-space: nowrap;">未处理数</p>
                    <div class="testBox">{{hendViewData.untreatedNumber || 0}}</div>
                </el-card>
            </el-col>
        </el-row>
        <ja-table v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @details="details"
                  :valueFormat="valueFormat"
                  @viewRecords="viewRecords"
                  @showComplete="showComplete">
            <template #test>
                <span>选择日期</span>
            </template>
        </ja-table>
        <!-- 详情 -->
        <Details ref="detailsRef" />
        <!-- 查看记录 -->
        <view-records ref="viewRecords"></view-records>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import Details from './completeAndDetails.vue'
import viewRecords from './components/viewRecords.vue'
import { getOrderList, getWorkNewHead, getViewRecords } from "@/api"
export default {
    title: '维修工单',
    mixins: [table],
    components: { Details, viewRecords },
    data () {
        return {
            conditionData: [
                {
                    input_type: 'input',
                    attribute: {
                        placeholder: '工单号',
                        size: 'small'
                    },
                    key: 'orderCodeOrName'
                },
                { slotName: 'test' },
                { input_type: 'date', type: 'daterange', value: 'deviceName' },
                {
                    input_type: 'select',
                    key: 'status',
                    attribute: { placeholder: '工单状态', size: 'small' },
                    options: [
                        { label: '待接单', value: '0' },
                        { label: '处理中', value: '1' },
                        { label: '待确认', value: '2' },
                        { label: '已完成', value: '3' },
                        { label: '已驳回', value: '4' }
                    ]
                },
                {
                    input_type: 'select',
                    key: 'workOrderType',
                    attribute: { placeholder: '工单类型', size: 'small' },
                    options: [
                        { label: '工程类', value: 0 },
                        { label: '商务类', value: 1 },
                        { label: '保安类', value: 2 },
                        { label: '保洁类', value: 3 },
                        { label: '停车类', value: 4 },
                        { label: '其他类', value: 5 }
                    ]
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' }
            ],
            columns: [
                { label: '工单号', prop: 'workOrderCode' },
                { label: '工单类型', prop: 'workOrderTypeValue' },
                { label: '提单人', prop: 'submitEmployeeName' },
                { label: '提单人联系方式', prop: 'submitEmployeeMobile' },
                { label: '提单时间', prop: 'orderSubmitTime' },
                { label: '问题描述', prop: 'workOrderDescription' },
                { label: '工单状态', prop: 'status' },
                { label: '结单时间', prop: 'orderEndTime' },
                { label: '区域', prop: 'regionValue' },
                { label: '紧急程度', prop: 'workOrderUrgencyValue' },
                { label: '备注要求', prop: 'remark' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '查看', method: 'details', limit: 'workOrderManage::repairWorkOrder::details' },
                        { text: '确认完成', method: 'showComplete', limit: 'workOrderManage::repairWorkOrder::showComplete' },
                        { text: '查看记录', method: 'viewRecords', limit: 'workOrderManage::repairWorkOrder::viewRecords' }
                    ]
                }
            ],
            tableData: [

            ],
            loading: false,
            showDetails: false,
            hendViewData: {},
            headLoading: false,
            statusValue: {
                0: "待接单",
                4: "已驳回",
                1: "处理中",
                2: "待确认",
                3: "已完成"
            }
        }
    },
    watch: {
        'filterData.deviceName': {
            handler (newVla) {
                if (!newVla){
                    delete this.filterData.startTime
                    delete this.filterData.endTime
                    return false
                } 
                if (newVla != null && newVla.length > 0) {
                    this.filterData.startTime = newVla[0]
                    this.filterData.endTime = newVla[1]
                }
            },
            deep: true
        }
    },
    methods: {
        getInit () {
            this.loading = true
            this.headLoading = true
            const filterData = {
                ...this.filterData,
                status: this.filterData.status ? [Number(this.filterData.status)] : []
            }
            getOrderList({ ...filterData, classify: 0, platform: 'web' }).then((res) => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
            getWorkNewHead({ ...filterData, classify: 0, platform: 'web' }).then(res => {
                this.hendViewData = res.data
                this.headLoading = false
            })
        },
        details (row) {
            this.$refs.detailsRef.showDetails(row)
        },
        showComplete (row) {
            this.$refs.detailsRef.showComplete(row)
        },
        editDataFormat (row, btn) {
            if (row.status == 1 && btn.text == '确认完成') {
                return false
            } else {
                return true
            }
        },
        /** 查看记录 */
        viewRecords (row) {
            const code = row.workOrderCode
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            getViewRecords(code).then(res => {
                loading.close()
                this.$refs.viewRecords.showDetails(res.data)
            }).catch(error => {
                loading.close()
                this.$message({
                    type: 'error',
                    message: error.msg
                })
            })
        },
        valueFormat (val, key) {
            if (key === 'status') {
                const statusVal = {
                    0: "待接单",
                    4: "已驳回",
                    1: "处理中",
                    2: "待确认",
                    3: "已完成"
                }
                return statusVal[val]
            }
            return val
        }
    }
}
</script>
<style lang="scss" scoped>
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;
    .el-card {
        color: #fff;
        border-radius: 0.4vw;
        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 2vw;
        }
    }
}
</style>
