<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="40%"
                   title="查看报修记录  "
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="{}"
                     v-model="roomDetails"
                     :formArray="detailOptions"
                     :allDisabled="true"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'input', label: '报修工单编号', key: 'workOrderCode', labelWidth: '120px' },
                { input_type: 'input', label: '报修人', key: 'submitEmployeeName', labelWidth: '120px' },
                { input_type: 'input', label: '报修人联系方式', key: 'submitEmployeeMobile', labelWidth: '120px' },
                { input_type: 'input', label: '详细位置', key: 'workOrderAddress', labelWidth: '120px' },
                { input_type: 'input', type: 'textarea', label: '问题描述', key: 'workOrderDescription', labelWidth: '120px', col: 24 },
                { input_type: 'input', label: '提单时间', key: 'orderSubmitTime', labelWidth: '120px' },
                { input_type: 'input', label: '完成状态', key: 'statusValue', labelWidth: '120px' },
            ],
            //表头数据
            roomDetails: {},
            show: false
        }
    },
    methods: {
        rowDetail(row) {
            this.roomDetails = row
            this.show = true
        }
    }
}
</script>
