<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowDelete="rowDelete" @rowEdit="normalEvent" @rowDetail="normalEvent" :editDataFormat="editDataFormat">
    </ja-table>

    <!-- 新增/编辑 -->
    <add-edit ref="addEdit" />
    <detail ref="detail" />
  </div>
</template>
<script>
import { rqMsgService, delNews } from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit.vue'
import Detail from './childComp/Detail.vue'

export default {
  title: '通讯报装',
  mixins: [table,],
  name: 'index',
  components: {
    AddEdit, Detail
  },
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
        { input_type: 'select', key: 'installType', attribute: { placeholder: '报装类型', size: 'small' }, options: [], },
        {
          input_type: 'select', key: 'status', attribute: { placeholder: '审批状态', size: 'small' }, options: [
            { label: '审核中', value: 0 },
            { label: '已同意', value: 1 },
            { label: '已驳回', value: 2 },
          ],
        },
        { input_type: 'input', key: 'search', attribute: { placeholder: '报装人/报装单位/申请编号', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
      ],
      columns: [
        { label: '申请编号', prop: 'applyCode' },
        { label: '报装人', prop: 'applyEmployee' },
        { label: '报装单位', prop: 'applyTenantName' },
        { label: '报装类型', prop: 'installTypeName', },
        { label: '联系方式', prop: 'applyPhone', },
        { label: '楼栋', prop: 'buildingName', },
        { label: '楼层', prop: 'floorName', },
        { label: '房间号', prop: 'roomName', },
        { label: '提交时间', prop: 'createTime', width: 140 },
        { label: '申请说明', prop: 'applyDescription', width: 140 },
        { label: '审批状态', prop: 'statusName' },
        { label: '驳回理由', prop: 'rejectReason', },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '详情', method: 'rowDetail' },
            { text: '审批', method: 'rowEdit' },
          ]
        }
      ],
      con_indexObj: {},
    }
  },
  mounted() {
    this.con_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.util.rqOptionsList({ who: [this.conditionData[this.con_indexObj.installType],], rqUrl: "/sys/dictData/install_type", rqMethod: 'get', resRule: 'data' })
  },
  methods: {
    rqParmas() {
      let obj = { ...this.filterData }
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = true
      let data = this.rqParmas()
      rqMsgService(data).then(res => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
      }).catch(err => {
        this.loading = false
      })
    },
    editDataFormat(row, btn) {
      if (row.status !== 0 && btn.text === '审批') {
        return false
      } else {
        return true
      }
    },
  }
}
</script>
