<template>
  <!-- 表格 -->
  <div class="content">
    <title-box title="财务收入统计" :isRight="true">
      <template #right>
        <div class="selections">
          <el-select v-model="activeYear" placeholder="年份" @change="selectYear" class="selectBox">
            <el-option v-for="item in years" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </template>
    </title-box>
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" ref="jaChart" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import titleTop from './common/titleTop.vue'

export default {
  components: {
    JaInitChart, TitleBox, titleTop
  },
  data() {
    return {
      options: null,
      // 年份
      activeYear: '',
      years: [
        { label: '2019', value: '2019' },
        { label: '2020', value: '2020' },
        { label: '2021', value: '2021' },
        { label: '全部', value: 'all' },
      ],
    }
  },
  created() {
    this.activeYear = '2021'
    this.setOptions()
  },
  methods: {
    selectYear(val) {
      console.log('选择年份', val)
    },
    setOptions() {
      const xData = this.util.numberRange(1, 12, null, null, '月').map(v => v.label);
      const activeYear = this.activeYear
      this.options = {
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross',
            // show: true,
            // crossStyle: {
            //   color: '#999'
            // },
            lineStyle: {
              type: "dashed"
            },
          },
          formatter: function (params) {
            console.log('****', params)
            let str = activeYear == 'all' ? '全部' : `${activeYear}年${params[0].axisValueLabel}<br/>`;
            params.forEach((item, idx) => {
              if (idx < params.length - 1) {
                if(item.seriesName == '实收'){
                    let span = item.marker.replace('background-color:[object Object];','background-color:rgb(255 93 31);')
                    item.marker = span
                }
                str += `${item.marker} ${item.seriesName}: ${item.data}  万元<br/>`
              }
            })
            // console.log(params[0].axisValueLabel)
            let nowMon = params[0].data
            let lastYearMon = params[1].data
            let text, percent
            let gap = nowMon - lastYearMon
            let marker3 = params[2].marker
            marker3 = marker3.replace('#5470c6','rgba(0, 137, 255, 1)')
            if (gap > 0) { //说明是上升
              text = `${marker3} 上升：`
              percent = (gap * 10000 / (lastYearMon * 100)).toFixed(2) + '%'
            } else {
              text = `${marker3} 下降：`
              percent = (Math.abs(gap) * 10000 / (lastYearMon * 100)).toFixed(2) + '%'
            }
            str += text + percent
            return str
          }
        },
        legend: {
          top: '5%',
          itemGap: this.util.setFontSize(30, 1920),
          textStyle: { //图例文字的样式
            fontSize: this.util.setFontSize(15, 1920),
          },
          data: ['实收', '应收',],
        },
        grid: {
          top: '18%',
          bottom: '30%',
          right: '8%',
          left: '8%'
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'none',
            },
            offset: this.util.setFontSize(8, 1920),
            axisLabel: {
              fontSize: this.util.setFontSize(15, 1920),
            },
            // boundaryGap:false
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '（万元）',
            axisTick: {
              show: false,
            },
            axisPointer: {
              label: {
                show: true,
              }
            },
            nameGap: 20,
            nameTextStyle: {
              fontSize: this.util.setFontSize(16, 1920),
            },
            min: 0,
            max: 250,
            interval: 50,
            axisLine: {
              show: false
            },
            axisLabel: {
              // formatter: '{value} ',
              fontSize: this.util.setFontSize(14, 1920),
            },
          }
        ],
        series: [
          {
            name: '实收',
            type: 'bar',
            activeYear: this.activeYear,
            data: [
              2.0, 10, 40, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
            ],
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 0, color: 'rgb(255 208 153)' },
                { offset: 1, color: 'rgb(255 93 31)' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: 'rgb(255 93 31)'
              }
            },
          },
          {
            name: '应收',
            type: 'bar',
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ],
            itemStyle: {
              color: 'rgb(209 199 217)'
            },
            emphasis: {
              itemStyle: {
                color: 'rgb(204 178 225)'
              }
            },
          },
          {
            name: 'Temperature',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 0,
            data: [2.0, 10, 40, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
            lineStyle: {
              color: "rgb(245, 154, 35)"
            }
          }
        ]
      }
      //添加一个新的刻度线 实现折线在柱状图的中间显示
      this.options.xAxis[1] = {
        type: 'value',
        max: this.options.xAxis[0].data.length,
        show: false,
        axisPointer: {
          label: {
            show: false,
          }
        },
      }
      this.options.series[2].data = this.options.series[2].data.map((x, i) => [(0.25 + i), x])
      this.$nextTick(() => {
        this.$refs.jaChart.drawInit(this.options)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  overflow: hidden;
  border-radius: 20px;
  width: 100%;
  height: 500px;
  background: white;
  overflow: hidden;
  .selections {
    .selectBox {
      width: 120px;
    }
    .typeSelect {
      margin-left: 20px;
    }
  }
}
.echart {
  height: 100%;
  width: 100%;
}
</style>