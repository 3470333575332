<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData"
              :conditionData="conditionData"
              :columns="columns"
              :tableData="tableData"
              :total="total"
              :loading="loading"
              @append="normalEvent({ref:'addEdit',method:'append'})"
              @rowDetail="normalEvent"
              @rowEdit="normalEvent"
              @rowDelete="rowDelete"
              @getInit="getInit"
              @search="search"
              @reset="reset"
              @cancellation="cancellation"
              @push="push"
              :editDataFormat="editDataFormat">
    </ja-table>
    <!-- 新增 -->
    <add-edit ref="addEdit" />
    <detail ref="detail" />

  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import Detail from './childComp/detail'
import { pathList, deleteByPathId, cancellation, pushLine } from '@/api'
export default {
    title: '巡更线路',
    mixins: [table],
    components: {
        Detail,
        AddEdit
    },
    data () {
        return {
            conditionData: [
                { input_type: 'select', key: 'type', attribute: { placeholder: '线路类型', size: 'small' }, options: [{ label: '工程巡检', value: 0 }, { label: '物业巡检', value: 1 }, { label: '运营巡检', value: 2 }], styleWidth: '100px' },
                { input_type: 'select', key: 'status', attribute: { placeholder: '路线状态', size: 'small' }, options: [{ label: '待发布', value: 0 }, { label: '已发布', value: 1 }] },
                { input_type: 'input', key: 'pathName', attribute: { placeholder: '巡检路线名称', size: 'small' } },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '新增' }
            ],
            columns: [
                { label: '巡检路线名称', prop: 'pathName' },
                { label: '线路类型', prop: 'typeName' },
                { label: '路线状态', prop: 'statusName' },
                { label: '创建人', prop: 'createBy' },
                { label: '创建时间', prop: 'createTime' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 160,
                    attribute: { fixed: "right", align: 'center' },
                    list: [
                        { text: '查看', method: 'rowDetail' },
                        { text: '作废', method: 'cancellation' },
                        { text: '编辑', method: 'rowEdit' },
                        { text: '发布', method: 'push' },
                        { text: '删除', method: 'rowDelete' }
                    ]
                }
            ],
            indexObj: {}

        }
    },

    methods: {
        getInit () {
            this.loading = false
            const rqData = this.rqParmas()
            pathList(rqData).then(res => {
                const { total, data } = res.data
                this.total = total
                this.tableData = data
            })
        },
        // 删除
        rowDelete (row) {
            this.$confirm('是否确认删除该条数据？').then(res => {
                deleteByPathId(row.id).then(res => {
                    this.$message.success("已删除")
                    this.getInit()
                })
            })
        },
        push ({ id }) {
            this.$confirm('是否确认发布该条数据？').then(res => {
                pushLine(id).then(res => {
                    this.$message.success(res.msg)
                    this.getInit()
                })
            })
        },
        cancellation ({ id }) {
            this.$confirm('是否确认作废该条数据？').then(res => {
                cancellation(id).then(res => {
                    this.$message.success(res.msg)
                    this.getInit()
                })
            })
        },
        editDataFormat (row, btn) {
            if (row.status === 1 && ['作废', '查看'].indexOf(btn.text) != -1) {
                return true
            }

            if (row.status === 0 && ['发布', '编辑', '删除'].indexOf(btn.text) != -1) {
                return true
            }

            if (row.status === 2 && btn.text === '删除') {
                return true
            }
            return false
        }
    }
}
</script>
