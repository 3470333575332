<template>
    <div class="edit">
        <el-dialog v-dialogDrag :close-on-click-modal="false" :title="title" :visible.sync="show" width="80%" @close="close" :before-close="close">
            <!-- 显示范围 -->
            <div>
                <div class="title">
                    <p>{{billDetailTableData.billType == 0 ? '租金缴费通知单(' + billDetailTableData.month + ')' : '物业管理费缴费通知单(' + billDetailTableData.month + ')'}}</p>
                    <div class="content">
                        <span>缴费单位/个人:{{ billDetailTableData.tenantName }}</span>
                        <span>房间：{{ billDetailTableData.roomName }}</span>
                        <span>账单日期：{{ billDetailTableData.day }}</span>
                        <span>最后缴款日期：{{ billDetailTableData.lastPayDate }}</span>
                    </div>
                </div>
                <JaTable @iDeleteEvent="iDeleteEvent" @inputEvent="inputEvent" :noSearch="true" :showSummary="true" :chooseArr="chooseArr" :border="border" :columns="billingTableHead" :tableData="billDetailTableData.billDetail" :showPage="showPage">
                </JaTable>
            </div>
            <div class="button" v-if="billDetailTableData.status !== 2">
                <el-button type="primary" @click="addCost">+增加收费项</el-button>
                <el-button type="primary" @click="temporaryStorage">暂存</el-button>
                <el-button type="primary" @click="Approved">通过审核</el-button>
                <el-button type="primary" @click="print">打印</el-button>
            </div>
            <div class="button" v-else>
                <el-button type="primary" @click="print">打印</el-button>
            </div>
        </el-dialog>
        <addCost :show.sync="costShow" @closeFormCost="closeFormCost" :costData="billDetailTableData.billDetail"></addCost>
        <PrintTable ref="print" :title="title" @printClose="printClose" :printShow.sync="printShow" :billDetailTableData="billDetailTableData"></PrintTable>
    </div>
</template>

<script>
import JaTable from '@/components/ja-table'
import { billingTableHead, billingTableHeadPrint } from '../billManage'
import addCost from './addCost.vue'
import { datailld } from '@/api'
import AdjustUnitPrice from './AdjustUnitPrice.vue'
import PrintTable from './printTable.vue'
export default {
    name: 'billingDetails',
    components: {
        JaTable,
        addCost,
        AdjustUnitPrice,
        PrintTable
    },
    data () {
        return {
            // 打印开关
            printShow: false,
            // 调整数据
            correctionEventData: null,
            // 调整弹出
            AdjustmentDialog: false,
            // 调整初始单价
            adjustFormData: {},
            showPage: false,
            border: true,
            costShow: false,
            //表头
            billingTableHead,
            //   打印显示
            billingTableHeadPrint,
            chooseArr: ['本期合计', '本期应收', '调整后应收'],
        }
    },
    props: {
        id: String,
        title: String,
        billDetailLength: Number,
        show: {
            type: Boolean,
            default: false
        },
        // 账单明细数据
        billDetailTableData: [Object, Array]

    },
    methods: {
        // 调整
        inputEvent (item) {
            if (item instanceof Object) {
                let keyArr = ['adjustment', 'adjustmentUnitPrice']
                for (let i = 0; i < keyArr.length; i++) {
                    if (item[keyArr[i]] != null) {
                        if (isNaN(Number(item[keyArr[i]]))) {
                            this.$set(item, keyArr[i], '')
                            return this.$message.warning('请输入数字')
                        }
                        if (item[keyArr[i]].toString().includes('.')) {
                            const smallNum = item[keyArr[i]].toString().split('.')[1].length
                            if (smallNum > 2) {
                                this.$set(item, keyArr[i], '')
                                return this.$message.warning('只能保留2位小数')
                            }
                        }
                    }
                }
                item.adjustmentTotal = this.util.formatFloat(item.adjustment * item.adjustmentUnitPrice)
                item.adjustmentTotal = NaN?0:item.adjustmentTotal
                
            }
        },
        closeFormCost (costShow) {
            if (this.billDetailTableData.status === 2) {
                this.$message.error('账单已审核')
            } else {
                this.costShow = costShow
            }
        },
        addCost () {
            this.closeFormCost(true)
        },
        // 暂存
        temporaryStorage (item) {
            if (this.billDetailTableData.status === 2) {
                this.$message.error('账单已审核')
            } else {
                this.$emit('temporaryStorage', this.billDetailTableData, this)
            }
        },
        Approved () {
            if (this.billDetailTableData.status === 2) {
                this.$message.error('账单已审核')
            } else {
                this.$emit('Approved', this.billDetailTableData, this)
            }
        },
        // 打印表单关闭
        printClose (printShow) {
            this.printShow = printShow
        },
        // 打印
        print () {
            this.printClose(true)
            // 延迟等待打印表渲染
            setTimeout(() => {
                this.$print(this.$refs.print)
                this.printClose(false)
            }, 1500)
        },
        // 删除
        iDeleteEvent (val) {
            if (val.id) {
                datailld(val.id)
                    .then((res) => {
                        if (res.code === 200) {
                            return res.data
                        }
                    })
                    .then((data) => {
                        this.billDetailTableData.billDetail = data
                        this.$message.success('删除成功')
                    })
            } else {
                let count = (this.billDetailTableData.billDetail || []).findIndex((e) => e.payProjectDetail === val.payProjectDetail)
                if (count >= 0) {
                    this.billDetailTableData.billDetail.splice(count, 1)
                } else {
                    this.$message.error('删除失败,请联系管理员')
                }
            }
        },
        /** 弹出框点击确认 */
        saveForm () {
            console.log(2)
        },
        /** 关闭弹出框 */
        close () {
            this.$emit('closeFormTable', false)
        },

    }
}
</script>

<style less="scss" scoped>
.title > p {
    width: 50%;
    font-size: 30px;
    font-weight: bold;
    margin: 0 auto;
}
.title > .content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.button {
    width: 100%;
    display: flex;
    justify-content: center;
}
.button > .el-button {
    padding: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}
.formTable .input_text[data-v-4c71cf6a] {
    padding: 0;
    border: 0;
}
.el-icon-delete:before {
    content: '\e6d7';
    position: absolute;
    /* left: 135px; */
    right: 10px;
    top: 25px;
    color: red;
    cursor: pointer;
    z-index: 8888;
}
/deep/.el-input {
    padding: 0 !important;
    border: 0 !important;
}
</style>
