<template>
    <el-main>
        <video width="98%" height="98%" controls>
            <source src="/images/mp4/movie.mp4" type="video/mp4">
        </video>
    </el-main>
</template>
<script>
export default {
    data () {
        return {
           
        }
    },
    methods: {
    }

}
</script>
<style lang="scss" scoped>
.el-main {
    min-height: 400px;
    height: 82vh;
    background: #fff;
    padding: auto 10px;
}
.video_box {
    width: 100%;
    height: 100%;
    min-height: 60%;
    position: relative;
}
@media screen and (max-width: 991px) {
    #player {
        width: 100%;
        height: 98%;
    }
}
@media screen and (min-width: 992px) {
    #player {
        width: 100%;
        height: 98%;
    }
}
.video_box_hender {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    height: 1.563vw;
    width: 95%;
    position: absolute;
    left: 50%;
    top: 6px;
    transform: translateX(-50%);
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
        .right_color {
            width: 0.521vw;
            height: 0.521vw;
            display: inline-block;
            border-radius: 50%;
            margin: 0 10px;
        }
    }
}
.stateColor1 {
    background-color: red;
}
.stateColor2 {
    background-color: blue;
}
</style>