<template>
  <!-- 设备管理-设备分组 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 单独导出 -->
  <!-- outPutExcell_obj：导出数据 封装导出 -->
  <!-- editDataFormat: 检测当前行显示的内容（如：根据条件显示待处理、已处理）+ 可根据自定义的key值判断 -->
  <!-- handleSelectionChange -->
  <div class="allwh">
    <div class="allwh-wrapper">
      <ja-table ref="tst"
                v-model="filterData"
                :conditionData="conditionData"
                :columns="columns"
                :tableData="tableData"
                :total="total"
                :loading="loading"
                @append="append"
                @rowEdit="rowEdit"
                @rowDelete="rowDelete"
                @change="selectChange"
                @getInit="getInit"
                @search="search"
                @reset="reset">
      </ja-table>

      <!-- 新增/编辑 -->
      <!-- reset: 取消 -->
      <!-- saveForm: 保存操作 -->
      <el-dialog v-dialogDrag :close-on-click-modal="false"
                :visible.sync="showEditAll"
                width="40%"
                :title="titleText">
        <ja-form :rules="rules"
                :label-width="labelWidth"
                v-model="rowForm"
                :formArray="rowFormData"
                @reset="showEditAll = false"
                @save="saveForm"
                @change2="selectChange2"
                v-if="showEditAll"
                v-loading="loading_form.isShow"
                :element-loading-text="loading_form.text"
                :element-loading-spinner="loading_form.icon"
                :element-loading-background="loading_form.bg"
                :element-loading-custom-class="loading_form.formLoading">
        </ja-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
import { getDeviceList, deviceGroupAdd, deviceGroupEdit, deviceGroupDelete } from '@/api'
import { deviceGroupQuery, deviceGroupColumn, deviceGroupFormData, deviceGroupRules } from '../systemSettingColumn'
export default {
  title: '设备分组',
  mixins: [table],
  name: 'index',
  data () {
    return {
      queryTableId: 'iot_device_group',
      outPutExcell_obj: { outPutExcell_url: '/query/export/iot_device_group', queryTableId: 'iot_device_group' }, // limit: 'admin::rsVehicleApply::export' // limit: 'admin::user::add'
      filterData: { pageIndex: 1, pageSize: 10 }, // 默认页码
      conditionData: deviceGroupQuery,
      columns: deviceGroupColumn,
      tableData: [],
      loading: false,
      total: 0,
      // 新增/编辑
      titleText: '编辑',
      rowFormData: deviceGroupFormData,
      rules: deviceGroupRules,
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`
    }
  },
  computed: {
    // parkAllList () {
    //   return this.$store.state.global.parkAllList // 监听园区列表--全部
    // },
    // parkList () {
    //   return this.$store.state.global.parkList // 监听园区列表
    // }
  },
  watch: {
    // 'rowForm.category' (newVal, oldVal) { // 编辑-设备大类 && typeof(oldVal) != 'undefined'
    //   if (newVal != oldVal && newVal != '') {
    //     if (this.titleText === '新增') {
    //       if (this.rowForm['subcategory']) { this.rowForm['subcategory'] = null }
    //     } else if (this.titleText === '编辑' && typeof (oldVal) != 'undefined') {
    //       // if (this.rowForm['subcategory']) { this.rowForm['subcategory'] = null }
    //     }
    //     const paramJson = {
    //       pageIndex: 1,
    //       pageSize: 200
    //     }
    //     paramJson.parentId = newVal
    //     const paramObj = this.util.paramChange(paramJson, 'device_subcategory_dropdown')
    //     this.util.rqOptionsList({ who: this.rowFormData[3], rqUrl: "/query/device_subcategory_dropdown ", rqData: paramObj, rqMethod: 'post', labelName: 'category_name', valueName: 'id' }) // 获取设备小类列表
    //   }
    // }
  },
  mounted () {
    // const paramObj = {
    //   pageIndex: 1,
    //   pageSize: 200
    // }

    // // 顶部搜索框
    // this.conditionData[0].options = this.parkAllList
    // this.util.rqOptionsList({ who: [this.conditionData[1], this.rowFormData[2]], rqUrl: "/query/device_category_dropdown", rqData: paramObj, rqMethod: 'post', labelName: 'category_name', valueName: 'id' }) // 获取设备大类列表

    // // 表单里的
    // this.rowFormData[0].options = this.parkList
  },
  methods: {
    selectChange: function (row, val) { // 查询设备列表
      if (row === 'category') { // 设备大类
        if (this.filterData.subcategory) { this.filterData.subcategory = '' }
        const paramJson = this.global.dropdown
        paramJson.parentId = val
        const paramObj = this.util.paramChange(paramJson, 'device_subcategory_dropdown')
        this.util.rqOptionsList({ who: this.conditionData[2], rqUrl: "/query/device_subcategory_dropdown ", rqData: paramObj, rqMethod: 'post', labelName: 'category_name', valueName: 'id' }) // 获取设备小类列表
      }
    },
    getInit: function () { // 查询设备分组
      this.loading = true
      getDeviceList().then(({ data }) => {
        this.tableData = data.data
      })
      this.loading = false
      // const paramObj = this.util.paramChange(this.filterData, this.queryTableId)
      // queryAll(this.queryTableId, paramObj).then(res => {
      //   this.loading = false
      //   this.tableData = res.data.data
      //   this.total = res.data.total
      // })
    },
    rowDelete: function (row) { // 删除
      this.$confirm('是否确认删除该设备组信息').then(res => { // 删除设备分组信息
        deviceGroupDelete(row.id).then(res => {
          this.$message.success(res.msg)
          this.getInit()
        })
      })
    },
    append: function () {
      this.isAdd = true
      this.titleText = '新增'
      this.rowForm = {}
      this.showEditAll = true
    },
    rowEdit: function (row) { // 编辑
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = { ...row }
      this.showEditAll = true
    },
    saveForm: function () { // 保存
      this.loading_form.isShow = true
      if (this.isAdd) {
        // const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
        // deviceGroupAdd(paramObj).then(res => { // 新增设备分组信息
        //   this.loading_form.isShow = false
        //   this.$message.success(res.msg)
        //   this.showEditAll = false
        //   this.getInit()
        // }).catch(error => {
        //   this.loading_form.isShow = false
        //   this.$message.error(error)
        // })
      } else {
        // const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
        // deviceGroupEdit(paramObj).then(res => { // 更新设备分组信息
        //   this.loading_form.isShow = false
        //   this.$message.success(res.msg)
        //   this.showEditAll = false
        //   this.getInit()
        // }).catch(error => {
        //   this.loading_form.isShow = false
        //   this.$message.error(error)
        // })
      }
    },
    selectChange2 (row, item, key) {
      if ( row.key === 'category') { // 所属设备大类修改
        if (this.rowForm['subcategory']) { this.rowForm['subcategory'] = null }
      }
    }
  }
}
</script>

<style scoped>
</style>
