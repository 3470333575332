<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @showResident="showResident"> </ja-table>

        <show-detail ref="detailRef"></show-detail>
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import { getChargingHistory } from '@/api'
import ShowDetail from './components/showDetail.vue'
export default {
    title: '收费标准历史记录',
    name: 'usageRecord',
    mixins: [table],
    components: {
        ShowDetail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'feesName', attribute: { placeholder: '收费标准名称', size: 'small', } },
                { input_type: 'input', key: 'serviceName', attribute: { placeholder: '收费项目名称', size: 'small', } },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '收费标准名称', prop: 'feesName' },
                { label: '收费项目名称', prop: 'serviceName' },
                { label: '原始收费标准', prop: 'oldFees' },
                { label: '现收费标准', prop: 'newFees' },
                { label: '变更时间', prop: 'createTime' },
                { label: '审批人', prop: 'createBy' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: '150px',
                    list: [
                        { text: '查看', method: 'showResident', limit: 'chargeConfig::chargingHistory::show' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            results: null,
        }
    },
    methods: {
        getInit() {
            getChargingHistory(this.filterData).then(res => {
                this.tableData = res.data.data
                this.tableData.map(item=>{
                    item.newFees = `用量×${item.newFees}`
                    item.oldFees = `用量×${item.oldFees}`
                })
                this.loading = false
                this.total = res.data.total
            })
        },
        showResident(row) {
            this.$refs.detailRef.init(row);
        },

    }
}
</script>