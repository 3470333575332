<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :visible.sync="show"
                   width="55%"
                   title="合同变更审批">
            <ja-form ref="jaForm"
                     v-model="rowForm"
                     :rules="rules"
                     :label-width="labelWidth"
                     :formArray="rowFormData"
                     :loadingForm="loadingForm"
                     :btnBox="btnBox"
                     @reset="show = false"
                     @expenseAllocation="expenseAllocation"
                     @reject="reject"
                     @adopt="adopt">
                <template #uplpadfaceImg>
                    <upload-file @pdfTitleClickEvemt="pdfTitleClickEvemt"
                                 ref="Upload"
                                 :initFileList="[...initFileList]"
                                 :noButton="true"
                                 :hideDel="true"
                                 accept="pdf" />
                </template>
            </ja-form>
        </el-dialog>
        <!--费用配置-->
        <rent-allocation ref="rentA"
                         :allDisabled="true"></rent-allocation>
    </div>
</template>
<script>
import RentAllocation from '@/views/home/contractManage/childComp/rentAllocation'
import {
    submitContractItem,
    getContractChangeDetail,
    approvalContractChange
} from '@/api'

export default {
    inject: ['labelWidth', 'getInit'],
    components: { RentAllocation },
    data() {
        return {
            labelWidth: `${this.util.setFontSize(150)}px`,
            rowFormData: [
                { input_type: 'input', label: '合同编号', key: 'contractCode', col: 12, styleWidth: '90%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同名称', key: 'contractName', col: 12, styleWidth: '90%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '合同类型', key: 'contractTypeName', col: 12, styleWidth: '90%', disabled: true },
                { input_type: 'date', label: '租期', key: 'leaseTerm', method: 'selectDate', type: 'daterange', col: 12, valueFormat: 'yyyy-MM-dd', unlinkpanels: true, disabled: true },
                { input_type: 'input', label: '驻户名称', key: 'tenantName', col: 12, styleWidth: '90%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '营业执照号/身份证号', key: 'cardCode', col: 12, styleWidth: '90%', maxlength: 10, disabled: true },
                {
                    input_type: 'select', label: '驻户类型', key: 'changeTenantType', col: 12, placeholder: '驻户类型', styleWidth: '90%', maxlength: 30, options: [
                        { value: '0', label: "商业" },
                        { value: '1', label: "办公" }
                    ], disabled: true
                },
                {
                    input_type: 'table', label: '驻户租赁单元', col: 20, noSearch: true, showPage: false, key: 'roomInfo', isRequired: true, border: true, table_MaxHeight: 500, //roomIdstableAttribute: { showSummary: true },
                    columns: [
                        { label: '驻赁单元名称', prop: 'roomName' },
                        { label: '租赁单元类型', prop: 'roomTypeValue' },
                        { label: '所在楼栋', prop: 'buildingName' },
                        { label: '所在楼层', prop: 'floorName', attribute: { showOverflowTooltip: false } },
                        { label: '套内总体使用面积', prop: 'totalArea' },
                        {
                            label: '操作',
                            input_type: 'button',
                            width: '150',
                            list: [
                                { text: '查看', method: 'expenseAllocation', limit: 'contract::manage::expense_allocation' }
                            ]
                        }
                    ]
                },
                {
                    input_type: 'customize',
                    customize: 'uplpadfaceImg',
                    label: '合同附件',
                    key: 'urlLists',
                    col: 24,
                    disabled: true
                },
            ],
            titile: '',
            rowForm: {},
            rules: {},
            show: false,
            initFileList: [],
            loadingForm: false,
            btnBox: [{ label: '驳回', method: 'reject', col: 8, },
            { label: '通过', method: 'adopt', type: 'primary', }],
        }
    },
    mounted() {
    },
    methods: {
        async init(contractCode, changeId) {
            this.loadingForm = true
            let res = await getContractChangeDetail({ contractCode, changeId })
            let obj = { ...res.data }
            obj.leaseTerm = [obj.startTime, obj.endTime]
            if (obj.attachmentListInfo.length > 0) {
                this.initFileList = obj.attachmentListInfo.map((item) => {
                    if (!item) return
                    return {
                        name: item.attachmentName ? item.attachmentName : undefined,
                        url: item.attachmentUrl ? item.attachmentUrl : item,
                        raw: { type: item.attachmentType === 'pdf' ? 'pdf' : 'image' },
                        status: 'success',
                        response: { data: item }
                    }
                })
            } else {
                this.initFileList = []
            }
            // 判断是否变更过驻户名称
            if(this.rowForm.changeTenantName){
               this.rowFormData[4].key = 'changeTenantName'
            }else{
              this.rowFormData[4].key = 'tenantName'
            }
            this.rowForm = { ...obj }

            this.loadingForm = false
            this.show = true
        },
        async saveForm() {
            this.$confirm('是否确定提交该项?').then((res) => {
                submitContractItem(`${this.rowForm.id}/${this.rowForm.tenantId}`).then((res) => {
                    this.$message.success('提交成功')
                    this.show = false
                    this.getInit()
                })
            })
        },

        // 配置费用
        expenseAllocation(row) {
            let { contractType, id,changeId } = this.rowForm
            console.log(this.rowForm);
            let payServiceList = []
            if (this.rowForm.bizPayServiceNewDTOS) {
                payServiceList = [...this.rowForm.bizPayServiceNewDTOS]
            }
            this.$refs.rentA.init({ ...row, contractType, contractId: id,changeId }, [], payServiceList)
        },
        //通过
        adopt() {
            this.$confirm('是否确认通过？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let { id, changeId } = this.rowForm
                this.loadingForm = true
                try {
                    await approvalContractChange({ id, changeId, status: 3 })
                } finally {
                    this.show = false
                    this.loadingForm = false
                    this.getInit()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        //驳回
        reject() {
            this.$confirm('是否确定驳回？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let { id, changeId } = this.rowForm
                this.loadingForm = true
                try {
                    await approvalContractChange({ id, changeId, status: 1 })
                } finally {
                    this.show = false
                    this.loadingForm = false
                    this.getInit()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },

    }
}
</script>
<style lass="less" scoped>
::v-deep .facac {
    justify-content: flex-end !important;
}
</style>
