<template>
    <el-aside v-loading="loadingTree">
        <el-tree class="u-menu" node-key="id" accordion :default-expanded-keys="expandedkey" highlight-current ref="tree" @node-click="handleNodeClick" :data="treeData" :props="defaultProps"></el-tree>
    </el-aside>
</template>
<script>
import { getTreeData } from '@/api'
export default {
    data () {
        return {
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'name',
                value: 'id'
            },
            loadingTree: false,
            expandedkey: []
        }
    },
    mounted () {
        this.getInit()
    },
    methods: {
        getInit () {
            this.loadingTree = true
            try {
                getTreeData().then(res => {
                    if (res.code === 200) {
                        this.loadingTree = false
                        this.treeData = res.data
                    }
                })
            } catch (err) {
                console.log('tree请求错误', err);
            }
        },
        handleNodeClick (Objitem) {
            if (!Objitem.children) {
                let { id } = Objitem
                this.$bus.$emit('TreeClick', id)
            }

        }
    }
}
</script>
<style scoped>
.el-aside {
    width: 240px !important;
    position: relative;
    margin: 0 10px;
    height: 83vh;
}
.u-menu {
    box-sizing: border-box;
    width: 95%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    height: 100%;
    padding: 10px 10px;
    background: #fff;
}
.u-menu::-webkit-scrollbar {
    width: 7px;
}
.u-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(7, 150, 126, 0.2);
}
.u-menu::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(21, 31, 15, 0.1);
}
</style>    