<template>
  <div class="msgcenter">
    <!-- 详情页弹窗 -->
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="showDetail"
      title="消息详情"
      width="50%"
      @close="getInit()"
    >
      <h1>{{ detailData.msgTypeName }}</h1>
      <div class="msglist">
        <p>{{ detailData.sendTime }}</p>
        <p>系统消息</p>
      </div>
      <p v-html="detailData.msgContent" class="p2"></p>
    </el-dialog>
  </div>
</template>
<script>
import { Msg } from '@/api'
export default {
  data() {
    return {
      showDetail: false,
      detailData: {}
    }
  },
  methods: {
    rowDetail(row) {
      console.log(row)
      this.detailData = row
      this.showDetail = true
      Msg(row.id).then((res) => {
        console.log(res)
      })
    },
    getInit() {
      this.$emit('getInit')
    }
  }
}
</script>
<style lang="scss" scoped>
h1 {
  font-weight: bold;
  font-size: 20px;
  color: #606266;
  margin: 15px 0 20px 15px;
}
.msglist{
     display: flex;
     justify-content:space-between
}

p {
  color: #606266;
  margin-bottom: 40px;
}
.p2 {
  color: #000;
  margin: 0 0 20px 20px;
}
</style>