<template>
    <div>
        <ja-table :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  v-model="filterData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  :editDataFormat="editDataFormat"
                  :valueFormat="valueFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @approval="approval"
                  @append="addChange"
                  @showDetails="showDetails" />

        <new-change ref="addChangeRef"></new-change>
        <approval ref="approvalRefs"></approval>
        <tap-detail ref="tapRefs"></tap-detail>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import { getContractChangeList } from '@/api'
import NewChange from './components/newChange.vue'
import Approval from './components/approval.vue'
import TapDetail from './components/tapDetail.vue'
export default {
    title: '合同变更',
    mixins: [table],
    components: {
        NewChange,
        Approval,
        TapDetail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'contractCode', attribute: { placeholder: '合同编号', size: 'small' } },
                { input_type: 'input', key: 'contractName', attribute: { placeholder: '合同名称', size: 'small' } },
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'select', key: 'contractType', attribute: { placeholder: '合同类型', size: 'small' }, options: [] },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '开始时间', endPlaceholder: '结束时间', styleWidth: '300px', valueFormat: 'yyyy-MM-dd' },
                { input_type: 'date', type: 'month', value: 'contractBatch', placeholder: '合同批次', valueFormat: 'yyyy-MM-dd' },
                { input_type: 'select', key: 'status', attribute: { placeholder: '全部状态', size: 'small' }, options: [{ label: '已驳回', value: 1 }, { label: '待审批', value: 2 }, { label: '已通过', value: 3 }] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '新增变更', limit: 'contractChange::index::add' }
            ],
            columns: [
                { label: '合同编号', prop: 'contractCode', width: 150 },
                { label: '合同名称', prop: 'contractName', width: 150 },
                { label: '合同类型', prop: 'contractTypeName', width: 150 },
                { label: '租期', prop: 'date', width: 200 },
                { label: '计租开始日期', prop: 'billingTime', width: 200 },
                { label: '驻户名称', prop: 'changeTenantName', width: 150 },
                { label: '驻户租赁单元', prop: 'changeRoomName', width: 150 },
                { label: '合同批次', prop: 'contractBatch', width: 150 },
                { label: '变更申请人', prop: 'changeApplyName', width: 150 },
                { label: '申请时间', prop: 'changeApplyTime', width: 150 },
                { label: '状态', prop: 'statusName' },
                { label: '审批人', prop: 'auditName', width: 150 },
                { label: '审批时间', prop: 'auditTime', width: 200 },
                {
                    label: '操作', input_type: 'button', width: 200, attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '通过/驳回', method: 'approval', limit: 'contractChange::index::approval' },
                        { text: '查看', method: 'showDetails', limit: 'contractChange::index::showDetails' },

                    ]
                }
            ],
            tableData: [],
            contractTypeOptions: [],
            total: 0,

        }
    },
    async mounted() {
        let length = this.conditionData.findIndex(item => item.key == 'contractType')
        await this.util.rqOptionsList({
            who: [this.conditionData[length]],
            rqUrl: '/sys/dictData/contract_type',
            resRule: 'data'
        }).then(res => {
            this.contractTypeOptions = res
        })
        this.getInit()
    },
    methods: {
        getInit() {
            this.loading = true
            getContractChangeList(this.rqParmas()).then(res => {
                res.data.data.map(item => {
                    item.date = item.startTime + '至' + item.endTime
                    if (typeof item.contractType == 'string') {
                        this.contractTypeOptions.map(option => {
                            if (option.value == item.contractType) {
                                item.contractTypeName = option.label
                            }
                        })
                    }
                    if (item.status) {
                        item.statusName = ['已驳回', '待审批', '已通过'][item.status - 1]
                    }

                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        showDetails(row) {
            this.$refs.tapRefs.init(row)
        },
        addChange(row) {
            this.$refs.addChangeRef.initState(row)
        },
        approval(row) {
            let changeId = row.changeId ? row.changeId : 0
            this.$refs.approvalRefs.init(row.contractCode, changeId)
        },
        rqParmas() {
            let obj = { ...this.filterData }
            if (obj.date) {
                obj.startTime = this.filterData.date[0] || ''
                obj.endTime = this.filterData.date[1] || ''
            }
            return obj
        },
        editDataFormat(row, bto) {
            if ((row.status == 2 && bto.text == '通过/驳回') || row.status != 2 && bto.text == '查看') {
                return true
            }
        },
        valueFormat(val, key, row) {
            if (key == 'changeTenantName') {
                return val ? val : row.tenantName
            } else if (key == 'changeRoomName') {
                return val ? val : row.roomName
            } else {
                return val
            }
        }
    }
}
</script>

<style scoped>
</style>
