<template>
    <div>
        <el-row :gutter="20">
            <graphical-pie :type="1"></graphical-pie>
            <graphical-pie :type="2"></graphical-pie>
            <graphical-bar></graphical-bar>
        </el-row>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @rowDetail="normalEvent">
        </ja-table>
        <show-detail ref="detail"></show-detail>
    </div>
</template>
<script>
import GraphicalPie from './components/GraphicalPie.vue'
import GraphicalBar from './components/GraphicalBar.vue'
import table from "@/views/mixins/table"
import ShowDetail from './components/ShowDetail.vue'
import { getWorkOrderList } from '@/api'
export default {
    title: "报修记录",
    name: "repairRecord",
    mixins: [table],
    components: {
        GraphicalPie,
        GraphicalBar,
        ShowDetail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'date', type: 'daterange', value: 'deviceName', label: '报修记录查找' },
                // { input_type: 'select', key: 'workOrderCategory', attribute: { placeholder: '报修类型', size: 'small', }, options: [] },
                { input_type: 'select', key: 'status', attribute: { placeholder: '完成状态', size: 'small', }, options: [] },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '报修工单编号', prop: 'workOrderCode' },
                // { label: '报修类型', prop: 'workOrderCategoryValue' },
                // { label: '设备类型', prop: 'repairTypeValue' },
                { label: '报修人', prop: 'submitEmployeeName' },

                { label: '报修人联系方式', prop: 'submitEmployeeMobile' },

                { label: '详细位置', prop: 'workOrderAddress' },
                { label: '问题描述', prop: 'workOrderDescription' },
                { label: '提单时间', prop: 'orderSubmitTime' },
                { label: '完成状态', prop: 'statusValue' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    list: [
                        { text: '查看', method: 'rowDetail', limit: 'deviceManage::repairRecord::show' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
        }
    },
    watch: {
        'filterData.deviceName': {
            handler(newVla) {
                if (!newVla) return false
                if (newVla != 'undefined' && newVla.length > 0) {
                    this.filterData.startTime = newVla[0]
                    this.filterData.endTime = newVla[1]
                }
            },
            deep: true,
        }
    },
    mounted() {
        // let length1 = this.conditionData.findIndex(item => item.key == 'workOrderCategory')
        let length2 = this.conditionData.findIndex(item => item.key == 'status')
        // this.util.rqOptionsList({
        //     who: this.conditionData[length1],
        //     rqUrl: '/sys/dictData/query',
        //     rqMethod: 'post',
        //     rqData: { dictType: 'work_order_category' },
        //     labelName: 'dictLabel',
        //     valueName: 'dictValue'
        // })
        this.util.rqOptionsList({
            who: this.conditionData[length2],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'work_order_status' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        getInit() {
            this.loading = true
            let obj = {
                classify: 0,
                pageIndex: this.filterData.pageIndex || 1,
                pageSize: this.filterData.pageSize || 10,
                startTime: this.filterData.startTime || '',
                endTime: this.filterData.endTime || '',
                // workOrderCategory: this.filterData.workOrderCategory || '',
                status: this.filterData.status || '',
            }
            getWorkOrderList({ ...obj }).then((res) => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.el-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 15px 15px;
    overflow: hidden;
}
</style>