<template>
  <!-- 详情弹窗 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="详情" :width="`40%`">
    <div class="main" v-if="showDetail">
      <div class="title">* {{detailData.questionDescription}} （{{["单选","多选"][detailData.questionType]}}）</div>
      <ul class="list">
        <li v-for="(item,index) in detailData.pcsqo" :key="index">
          <span>{{labels[index]}}：{{item.optionContent}}</span>
        </li>
      </ul>
    </div>
  </el-dialog>
</template>
<script>

export default {
  data() {
    return {
      showDetail: false,
      labels: [],
      detailData: {},
    }
  },
  created() {
    this.labels = this.generateBig()
  },
  methods: {
    // 获得大学字母
    generateBig() {
      var str = [];
      for (var i = 65; i < 91; i++) {
        str.push(String.fromCharCode(i));
      }
      return str;
    },
    rowDetail(row) {
      // console.log('查看详情---',row,)
      this.detailData = { ...row }
      this.showDetail = true
    },
  }
}
</script>
<style lang="scss" scoped>
.main {
  color: '#303133';
  padding: 0 30px 0;
  font-size: 14px;
  .title {
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  .list {
    >li{
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }
}
</style>