<template>
    <!-- 表格 -->
    <div class="content" v-loading="loading">
        <div class="header">巡检工单月度统计</div>
        <div class="echart" v-if="options">
            <ja-init-chart :options="options" />
        </div>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
export default {
    components: {
        JaInitChart,
    },
    props: {
        monthlyStatistics: Array, loading: Boolean
    },
    data() {
        return {
            options: null,
            monthlyStatistics: [],
        }
    },
    mounted() {
        this.setOptions()
    },
    watch: {
        monthlyStatistics(newVal){
            this.setOptions()
        }
    },
    methods: {
        async setOptions() {
            let months = this.monthlyStatistics.map(item => {
                return item.month
            })
            let total = this.monthlyStatistics.map(item => {
                return item.total
            })
            this.options = {
                color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                tooltip: {
                    trigger: 'axis',
                    // axisPointer: {
                    //     type: 'cross',
                    //     crossStyle: {
                    //         color: '#999'
                    //     },
                    //     lineStyle: {
                    //         type: "dashed"
                    //     },
                    // }, 
                    formatter: '{b} : {c}'
                  
                },
                grid: {
                    top: '18%',
                    bottom: '28%',
                },
                xAxis: [
                    {
                        type: 'category',
                        data: months,
                        axisPointer: {
                            type: 'none',
                        },
                        offset: this.util.setFontSize(8, 1920),
                        axisLabel: {
                            fontSize: this.util.setFontSize(15, 1920),
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        // name: '次',
                        axisTick: {
                            show: false,
                        },
                        axisPointer: {
                            label: {
                                show: true,
                            }
                        },
                        nameTextStyle: {
                            fontSize: this.util.setFontSize(16, 1920),
                        },
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: this.util.setFontSize(14, 1920),
                        },
                    }
                ],
                series: [
                    {
                        name: '0',
                        type: 'bar',
                        barWidth: 50,
                        barGap: '20%',
                        barCategoryGap: '20%',
                        data: total
                    },
                ]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    text-align: center;
    font-size: 16px;
    width: 100%;
    margin-top: 20px;
    position: relative;
    color: #4352D4;
    font-weight: 700;
}

.content {
    overflow: hidden;
    border-radius: 20px;
    width: 100%;
    height: 400px;
    background: white;
    overflow: hidden;
}
.echart {
    height: 100%;
    width: 100%;
}
</style>