<template>
    <div>
        <el-container>
            <el-main>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <span class="titleSpan">线路示意图</span>
                        <div class="ep-left">
                            <el-image :src="src">
                                <div slot="error" class="image-slot">
                                    未上传示意图
                                </div>
                            </el-image>
                            <div class="imgErr"></div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="ep-right">
                            <right-card @getSrc="getSrc"></right-card>
                            <right-table></right-table>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import RightCard from './components/rightCard.vue'
import RightTable from './components/rightTable.vue'
export default {
    title: "电子巡更",
    name: 'electronicPatrol',

    components: {
        RightCard,
        RightTable
    },
    data () {
        return {
            src: ''
        }
    },
    methods: {
        getSrc (src) {
            this.src = src
        }

    }

}
</script>
<style lang="scss" scoped>
.el-main {
    height: 80vh;
    background: #fff;
    margin: 10px 10px;
    .titleSpan {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 12px;
    }
}
.ep-left {
    min-height: 60vh;
    position: relative;
    .image-slot {
        text-align: center;
        color: darkgrey;
    }
}
</style>