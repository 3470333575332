<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title="(isAdd ? '新增' : '编辑') + '空间信息'">
        <ja-form :rules="rules" :allDisabled="allDisabled" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
    </el-dialog>
</template>
<script>
import { addSpaceItem, editSpaceItem } from '@/api'
export default {
    inject: ['getInit'],
    data () {
        return {
            rowFormData: [
                { input_type: 'select', label: '空间类型', key: 'spaceType', col: 11, options: [] },
                { input_type: 'radio', label: ' 是否规划编号', key: 'planStatus', options: [{ "value": 0, "label": "是" }, { "value": 1, "label": "否" }], col: 11 },
                { input_type: 'input', label: ' 建筑面积', key: 'outsideArea', col: 11, styleWidth: `85%`, rightBox: { input_type: 'text', text: '㎡' } },
                { input_type: 'input', label: ' 空间编号', key: 'spaceCode', col: 11, maxlength: 30 },
                { input_type: 'radio', label: ' 状态', key: 'spaceStatus', options: [{ "value": 0, "label": "正常" }, { "value": 1, "label": "关闭" }], col: 24 },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', placeholder: '请输入200字以内的描述', col: 22, maxlength: 200 }
            ],
            rowForm: {},
            rules: {
                spaceType: [{ required: true, message: '请输入空间编号', trigger: 'change' }],
                planStatus: [{ required: true, message: '不能为空', trigger: 'blur' }],
                outsideArea: [{ required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2, max: 99999 }) }],
            },
            loadingForm: false,
            labelWidth: `${this.util.setFontSize(140)}px`,
            showEditAll: false, // 显示新增/编辑弹窗,
            isAdd: false,
            copyRowForm: [],
            allDisabled: false
        }
    },
    mounted () {
        let index = this.rowFormData.findIndex(item => item.key == 'spaceType')
        const paramObj = { pageIndex: 1, pageSize: 200, dictType: 'space_type' }
        this.util.rqOptionsList({ who: [this.rowFormData[index]], rqUrl: "/sys/dictData/query", rqData: paramObj, rqMethod: 'post', labelName: 'dictLabel', valueName: 'dictValue' })
    },
    watch: {
        'rowForm.planStatus': {
            handler (newVal, oldVal) {
                if (newVal === 1) {
                    this.rowFormData[3].hide = true
                    this.$set(this.rules, 'spaceCode', [])
                    this.rowForm.spaceCode = ''
                } else {
                    this.rowFormData[3].hide = false
                    this.$set(this.rules, 'spaceCode', [{
                        required: true, trigger: 'blur', validator: (rule, value, callback) => {
                            const value_rule = /^[A-Za-z0-9_]+$/
                            if (!value) {
                                return callback(new Error('空间编号不能为空'));
                            } else if (!value_rule.test(value)) {
                                return callback(new Error('仅支持英文，数字'))
                            } else {
                                return callback()
                            }
                        }
                    }])
                }
            }
        }
    },
    methods: {
        append () {
            this.isAdd = true
            this.rowForm = {
                planStatus: 0,
                spaceStatus: 0
            }
            this.showEditAll = true
            this.allDisabled = false
        },
        rowEdit (row) {
            this.isAdd = false
            this.titleText = '编辑'
            this.rowForm = { ...row }
            this.showEditAll = true
            this.allDisabled = false
        },
        saveForm () {
            console.log(111);
            this.loadingForm = true
            let rqMethod = this.isAdd ? addSpaceItem : editSpaceItem
            rqMethod(this.rowForm)
                .then((res) => {
                    this.loadingForm = false
                    this.$message.success('提交成功')
                    this.showEditAll = false
                    this.getInit()
                })
                .catch((error) => {
                    this.loadingForm = false
                })
        }
    }
}
</script>
