<template>
  <!-- 参数设置 -->
  <div class="allwh">
    <ja-table ref="tst"
              v-model="filterData"
              :conditionData="conditionData"
              :columns="columns"
              :tableData="tableData"
              :total="total"
              :loading="loading"
              :tableAttribute="tableAttribute"
              @handleSelectionChange="handleSelectionChange"
              @append="append"
              @rowEdit="rowEdit"
              :showIndex="false"
              @rowDelete="rowDelete"
              @getInit="getInit"
              :valueFormat="formatter"
              @search="search"
              @reset="reset">
    </ja-table>
    <!-- 新增/编辑 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false"
               :visible.sync="showEditAll"
               width="40%"
               :title="titleText">
      <ja-form :rules="rules"
               v-model="rowForm"
               :formArray="rowFormData"
               @reset="showEditAll = false"
               @save="saveForm"
               v-if="showEditAll"
               labelWidth="100px"
               v-loading="loading_form.isShow"
               :element-loading-text="loading_form.text"
               :element-loading-spinner="loading_form.icon"
               :element-loading-background="loading_form.bg"
               :element-loading-custom-class="loading_form.formLoading">
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
import { rqParameterList, addParameter, editParameter, delParameter } from '@/api'
import { paramSettingQuery, paramSettingColum, paramSettingFormData, paramSettingRules,overallData } from '../systemSettingColumn'

export default {
  title: '参数设置',
  mixins: [table],
  name: 'index',
  components: {

  },
  data () {
    return {
      tableAttribute: {
        fit: true
      },
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: paramSettingQuery,
      columns: paramSettingColum,
      tableData: [],
      loading: false,
      total: 30,
      // 新增/编辑
      titleText: '编辑',
      rowFormData: paramSettingFormData,
      rules: paramSettingRules,
      rowForm: {},
      showEditAll: false // 显示新增/编辑弹窗
    }
  },
  methods: {
    handleSelectionChange (val) {
      console.log('val', val)
    },
    formatter (val, key) {
      if (key === 'settleFlag') {
        return ['未结清', '已结清'][val || 0]
      }
      return val || ''
    },
    disabledDateFun (time) {
      return time.getTime() < Date.now()// 当天之后的时间可选
    },
    getInit () {
      this.loading = true
      rqParameterList({
        pageSize: this.filterData.pageSize,
        pageIndex: this.filterData.pageIndex,
        configName: this.filterData.configName,
        configKey: this.filterData.configKey,
        configType: this.filterData.configType,
        startTime: this.filterData.time ? this.$moment(this.filterData.time[0]).format("YYYY-MM-DD HH:mm:ss") : '',
        endTime: this.filterData.time ? this.$moment(this.filterData.time[1]).format("YYYY-MM-DD HH:mm:ss") : ''

      }).then(res => {
        console.log(res)
        this.loading = false
        if (res.data.data && res.data.data.length > 0) {
          this.tableData = res.data.data.map(item => {
            if (item.configType === 0 || item.configType === 'N') {
              item.type = '否'
            } else if (item.configType === 1 || item.configType === 'Y') {
              item.type = '是'
            } else {
              item.type = ''
            }
            return item
          })
        } else {
          this.tableData = res.data.data
        }
        this.total = res.data.total
      })
    },
    // 删除
    rowDelete (row) {
      this.$confirm('是否确认删除该参数项').then(res => {
        delParameter(
          { configId: row.configId }
        ).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
    // 保存
    saveForm () {
      this.loading_form.isShow = true
      if (this.isAdd) {
        console.log('新增===》', this.rowForm)
        addParameter(this.rowForm).then(res => {
          this.loading_form.isShow = false
          this.$message.success('新增成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      } else {
        editParameter(this.rowForm).then(res => {
          this.loading_form.isShow = false
          this.$message.success('修改成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      }
    },
    append () {
      this.isAdd = true
      this.titleText = '新增'
      this.rowForm = {}
      this.showEditAll = true
    },
    rowEdit (row) {
      this.isAdd = false
      this.titleText = '编辑'
      // 编辑的时候根据类型显示
      if(row.configType==1){
        paramSettingFormData[1].hide=true
        paramSettingFormData[2].hide=false
        let options=row.selectNum.map(item=>{
          return {
            label:item,
            value:item
          }
        })
        paramSettingFormData[2].options=options
      }else{
         overallData.newNum = row.maxNum || 30
         paramSettingFormData[1].hide=false
         paramSettingFormData[2].hide=true
      }
      
      this.rowForm = { ...row }
      this.showEditAll = true
    }
  }
}
</script>

<style scoped>
</style>
