<template>
    <!-- 表格 -->
    <div class="content">
        <div class="echart" v-if="options">
            <ja-init-chart :options="options" />
            <div class="chartnum">{{ unfinishedWorkOrder }}</div>
        </div>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
export default {
    components: {
        JaInitChart
    },
    props:{
        unfinishedWorkOrder:String
    },
    data() {
        return {
            options: null,
        }
    },
    created() {
        this.setOptions()
    },
    methods: {
        async setOptions() {
            let initData = [
                { value: 100 },
            ]

            const gradientColors = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#DADDF6' },  // 起始颜色
                { offset: 1, color: '#5470c6' },  // 结束颜色
            ]);

            this.options = {
                color: gradientColors,
                // tooltip: {
                //     trigger: 'item',
                //     formatter: '{d}%'
                // },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['0%', '60%'],
                        center: ['50%', '50%'],
                        itemStyle: {
                            borderRadius: 5
                        },
                        colorBy: 'data',
                        label: {
                            show: false,
                        },
                        data: initData
                    }
                ]

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 100%;
}

.echart {
    height: 100%;
    width: 100%;
    position: relative;
    >.chartnum{
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 35px;
        color: #fff;
        transform: translate(-50%,-50%);
    }
}


</style>