<template>
    <div>
        <el-row :gutter="50"
                v-loading="headLoading">
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(83, 110, 196, 1)' }">
                    <p style="white-space: nowrap;">维修工单总数</p>
                    <div class="testBox">{{hendViewData.totalWorkOrder || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(141, 197, 113, 1)' }">
                    <p style="white-space: nowrap;">已处理工单</p>
                    <div class="testBox">{{hendViewData.processedWorkOrderAmount || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(250, 200, 89, 1)' }">
                    <p style="white-space: nowrap;">未处理工单</p>
                    <div class="testBox">{{hendViewData.undisposedWorkOrderAmount || 0}}</div>
                </el-card>
            </el-col>
        </el-row>
        <ja-table v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @details="details"
                  @showComplete="showComplete">
            <template #test>
                <span>选择日期</span>
            </template>
        </ja-table>
        <!-- 详情 -->
        <Details ref="detailsRef" />
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import Details from './completeAndDetails.vue'
import { getOrderList ,getWorkOrderHead} from "@/api"
export default {
    title: '告警工单',
    mixins: [table],
    components: { Details },
    data() {
        return {
            conditionData: [
                {
                    input_type: 'input',
                    attribute: {
                        placeholder: '工单号',
                        size: 'small'
                    },
                    key: 'orderCodeOrName'
                },
                { slotName: 'test' },
                {
                    input_type: 'date',
                    type: 'daterange',
                    value: 'deviceName',
                },
                { input_type: 'select', key: 'status', attribute: { placeholder: '工单状态', size: 'small' }, options: [{ label: '未完成', value: 0 }, { label: '已完成', value: 1 }] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '工单号', prop: 'workOrderCode' },
                { label: '工单类型', prop: 'repairTypeValue' },
                { label: '提单人', prop: 'submitEmployeeName' },
                { label: '所属公司', prop: 'submitEmployeeCompany' },
                { label: '提单时间', prop: 'orderSubmitTime' },
                { label: '工单状态', prop: 'statusValue' },
                { label: '结单时间', prop: 'orderEndTime' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '查看', method: 'details', limit: 'workOrderManage::warningWork::details' },
                        { text: '确认完成', method: 'showComplete', limit: 'workOrderManage::warningWork::showComplete' },
                    ]
                }
            ],
            tableData: [

            ],
            loading: false,
            showDetails: false,
            hendViewData: {},
            headLoading: false
        }
    },
    watch: {
        'filterData.deviceName': {
            handler(newVla) {
                if (!newVla) return false
                if (newVla != 'undefined' && newVla.length > 0) {
                    this.filterData.startTime = newVla[0]
                    this.filterData.endTime = newVla[1]
                }
            },
            deep: true,
        }
    },
    methods: {
        getInit() {
            this.loading = true
            this.headLoading = true
            getOrderList({ ...this.filterData, classify: 1 }).then((res) => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
            getWorkOrderHead(1).then(res => {
                this.hendViewData = res.data
                this.headLoading = false
            })
        },
        details(row) {
            this.$refs.detailsRef.showDetails(row)
        },
        showComplete(row) {
            this.$refs.detailsRef.showComplete(row)
        },
        editDataFormat(row, btn) {
            if (row.status == 1 && btn.text == '确认完成') {
                return false
            } else {
                return true
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;
    .el-card {
        color: #fff;
        border-radius: 0.4vw;
        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 2vw;
        }
    }
}
</style>