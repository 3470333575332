
const routes = {
  children: []
}

// require.context页面home下的文件，包含子文件
const requireComponent = require.context('../../views/home', true, /\.vue$/)

// 先按目录层级排序再进行遍历（多层嵌套的路由要先生成父级路由再push）
requireComponent.keys().sort((a, b) => a.split('/').length - b.split('/').length).forEach(fileName => {
  if (fileName === './chart_1.vue' || fileName.indexOf('.vue') === -1) {
    return
  }

  // 剥去文件名开头的 `./` 和`/chart_1.vue`结尾的扩展名(这里是以test/index.vue的格式为标准，如写成test.vue，下面的正则需要修改)
  let componentName = fileName.replace(/^\.\//, '').replace(/\/index\.vue$/, '').replace(/\/children\//g, '/')
  // 路由拼接名称（子路由名称用_连接，如：test/test2,路由名为test_test2，home的直接子路由则直接为文件名）（拼接太长已改为以最后一级的文件名为路由名）
  const componentNameRe = componentName.replace(/\//g, '_')
  // 懒加载所需的路径（去掉./）
  const allPath = fileName.replace(/^\.\//, '')
  const arr = componentNameRe.split('_')
  // console.log('arr_', componentNameRe)
  // path路径
  componentName = arr[arr.length - 1]
  // components目录下的不添加进路由表
  if (arr.length > 2) {
    const par_name = arr[arr.length - 2]
    // 组件过滤
    if (par_name === 'components') return
  }
  // （第一级路由） components模块,sysSetting,,smartStore过滤)
  if (arr[0] === 'components' || arr[0] === 'Home.vue') {
    return
  }
  // // 首字母大写的路由名（因子目录重名，导致路由名重名，故弃用）
  // const routeName = componentName.substring(0, 1).toUpperCase() + componentName.substring(1)
  // console.log(`${allPath}`)
  // 路由信息
  const result = {
    path: componentName, // path
    title: requireComponent(fileName).default.title || '默认title',
    // name: requireComponent(fileName).default.name || routeName, // 路由名(相应页面的name，无name时采用当前文件名首字母大写)
    name: componentNameRe,
    meta: {
      name: requireComponent(fileName).default.title || '默认title',
      auth: true,
      show: !requireComponent(fileName).default.notMenu || false // 是否不在左边菜单栏显示
    },
    // component: () => import(`@/views/home/${allPath}`), // eslint高版本不可用
    component: resolve => require([`@/views/home/${allPath}`], resolve), // 兼容高版本eslint
    children: []
  }
  if (arr.length > 1) {
    equivalArray(arr, result)
  } else {
    routes.children.push(result)// 无父级路有子项由此进入
  }
  // return result
})
// 包含子目录的拆分
function equivalArray (arr, result) {
  /*
  // 父级路由名（首字母大写采用此步骤）
  const parentname = arr[arr.length - 2]
  const pname = parentname.substring(0, 1).toUpperCase() + parentname.substring(1)
  */
  // 路由名以下划线采用此步骤
  arr.pop()
  const pname = arr.join('_')
  recursiveTraverse(routes, node => {
    if (node.name && node.name === pname) { // 父级匹配
      node.children.push(result)
    }
  })
}
// 利用递归与引用值特性
function recursiveTraverse (node, action) {
  if (!node || !node.children) return
  action(node)
  node.children.forEach((item) => {
    recursiveTraverse(item, action)
  })
}
// console.log('routes--++->', routes)
// export default routes

// console.log('routes--++->', routes)
export default routes
