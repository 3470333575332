<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="normalEvent({ref:'addEdit',method:'append'})"
                  @rowEdit="normalEvent"
                  @showResident="normalEvent"
                  @deleteResident="deleteResident"> </ja-table>
        <add-edit ref="addEdit"></add-edit>
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import AddEdit from './components/AddEdit.vue'
import {deleteMaintenance,getMaintenanceLists} from '@/api'
export default {
    title: '设备维保',
    name: 'maintenance',
    mixins: [table],
    components: {
        AddEdit,
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'brand', attribute: { placeholder: '设备品牌', size: 'small', } },
                { input_type: 'select', key: 'deviceCategory', attribute: { placeholder: '设备类型', size: 'small', }, options: [] },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '新增', limit: 'deviceManage::maintenance::add' },
            ],
            columns: [
                { label: '设备类型', prop: 'deviceCategoryName' },
                { label: '设备品牌', prop: 'brand' },
                { label: '维保单位', prop: 'deviceCompany' },
                { label: '负责人', prop: 'linkMan' },
                { label: '联系方式', prop: 'contactNumber' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: '150px',
                    list: [
                        { text: '查看', method: 'showResident', limit: 'deviceManage::maintenance::show' },
                        { text: '编辑', method: 'rowEdit', limit: 'deviceManage::maintenance::edit' },
                        { text: '删除', method: 'deleteResident', limit: 'deviceManage::maintenance::delete' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
        }
    },
    mounted() {
        let index = this.conditionData.findIndex(e => e.key === 'deviceCategory')
        const addEdit = this.$refs.addEdit
        this.util.rqOptionsList({
            who: [this.conditionData[index], addEdit.rowForm[addEdit.indexObj.deviceCategory]],
            rqUrl: "/device/category", rqData: {params: { pageIndex: 1, pageSize: 200, isAll: '1' }}, rqMethod: 'get', labelName: 'categoryName', valueName: 'id'
        })
    },
    methods: {
        getInit() {
            getMaintenanceLists(this.filterData).then(res => {
                console.log(res);
                this.tableData = res.data.data
                this.loading = false
                this.total = res.data.total
            })
        },
        deleteResident(row) {
            console.log(row);
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                deleteMaintenance(row.id).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.loading = false
                    this.getInit()
                }).catch(res => {
                    this.loading = false
                    this.$message.error('删除失败,请稍后再试')
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

    }
}
</script>