<template>
    <el-dialog :visible.sync="show"
               width="40%"
               title="查看">
        <ja-form :rules="rules"
                 allDisabled
                 v-model="rowForm"
                 :formArray="rowFormData"
                 v-if="show">
        </ja-form>
    </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
    components: {
        JaForm,
    },
    data() {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'input', label: '车牌号码', key: 'carNumber', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '车辆入场闸机', key: 'inequipcode', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '车辆离场闸机', key: 'outequipcode', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '车辆入场时间', key: 'intime', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '车辆离场时间', key: 'outtime', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '状态', key: 'statusValue', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '停车时长（s）', key: 'stopTime', labelWidth: "110px", placeholder: ' ' },
                { input_type: 'input', label: '停车类型', key: 'serviceTypeValue', labelWidth: "110px", placeholder: ' ', hide: true },
                
            ],
            show: false,
            rowForm: {},
        }
    },
    methods: {
        init(row, status = false) {
            this.show = true
            this.rowForm = { ...row }
            if (status) {
                console.log(row);
                let index = this.rowFormData.findIndex(e => e.key === 'serviceTypeValue')
                this.rowFormData[index].hide = false
            }
        }
    }
}
</script>