<template>
    <div>
        <el-dialog
            v-dialogDrag
            :close-on-click-modal="false"
            :visible.sync="show"
            width="50%"
            :title="titleText"
        >
            <ja-form
                :rules="rules"
                ref="jaFrom"
                :label-width="labelWidth"
                v-model="rowForm"
                :formArray="rowFormData"
                @reset="show = false"
                @save="confirmButton"
                v-if="show"
                v-loading="loading_form.isShow"
                :allDisabled="allDisabled"
                :element-loading-text="loading_form.text"
                :element-loading-spinner="loading_form.icon"
                :element-loading-background="loading_form.bg"
                noButton
                @spaceTypeEvent="spaceTypeEvent"
            >
                <template #formTable>
                  <el-transfer
                    filterable
                    :filter-method="filterMethod"
                    filter-placeholder="请输入空间编号"
                    v-model="spaceIds"
                    :data="tableData"
                    :titles="['未选中', '已绑定']"
                  ></el-transfer>
                </template>
                <template #confirmButton>
                    <el-button
                        type="primary"
                        @click="confirmButton"
                        v-if="isAuth('financeModule::rentalUnit::confirmButton')"
                    >确认单元</el-button>
                </template>
            </ja-form>
        </el-dialog>
        <unit-operate ref="unit"></unit-operate>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import table from "@/views/mixins/table"
import unitOperate from './unitOperate.vue'
import { spaceManageList } from '@/api'
export default {
    mixins: [table],
    name: '新增/编辑/查看楼层',
    components: {
        JaForm,
        unitOperate,
    },
    data () {
        const checkTable = function (rule, value, callback) {
            if (this.spaceIds.length > 0) {
                callback()
            } else {
                return callback(new Error('空间编号不能为空'))
            }

        }.bind(this)
        return {
            show: false, // 显示新增/编辑弹窗
            titleText: '新增租赁区域',
            rules: {
                floorName: [{ required: true, trigger: 'blur' }],
                buildingId: [{ required: true, trigger: 'change', message: '楼栋不能为空' }],
                floorId: [{ required: true, trigger: 'change', message: '楼层不能为空' }],
                tabelFrom: [{ required: true, trigger: 'blur', validator: checkTable }],
                spaceType: [{ required: true, trigger: 'change', message: '空间类型不能为空' }],
            },
            rowForm: { spaceType: '1' },
            labelWidth: `${this.util.setFontSize(140)}px`,
            rowFormData: [
                {
                    input_type: 'select', label: '楼栋', key: 'buildingId', options: [],
                    relevance: {
                        sonKey: 'floorId', This: this, type: "select", rqParam: true, // 必填。而 rqParam 是异步请求，必填字段
                        rqParam: { rqUrl: "/floor/allList", rqData: () => { return { params: {} } }, labelName: 'floorName', valueName: 'floorId', resRule: 'data' },
                    }
                },
                { input_type: 'select', label: '楼层', key: 'floorId', options: [], maxlength: 30 },
                { input_type: 'select', label: '空间类型', key: 'spaceType', options: [], maxlength: 30, method: 'spaceTypeEvent' },
                { input_type: 'customize', customize: 'formTable', label: '空间编号', col: 24, key: 'tabelFrom' },
                { input_type: 'customize', customize: 'confirmButton', col: 24, },
            ],
            allDisabled: false,
            isAdd: false,
            loading_form: {
                isShow: false,
                text: '拼命加载中',
                icon: 'el-icon-loading'
            },
            columns: [ // 表头数据
                {
                    label: '空间编号',
                    prop: 'spaceCode',
                },
                {
                    label: '规划面积（㎡）',
                    prop: 'outsideArea'
                },
            ],
            tableData: [],
            fromData: {
                pageIndex: 1,
                pageSize: 1000,
                spaceType: '1'
            },
            state: 0,
            // 关联空间id
            spaceIds: []
        }
    },
    mounted() {
        let index = this.rowFormData.findIndex(
            (item) => item.key == 'buildingId'
        )
        let index1 = this.rowFormData.findIndex(
            (item) => item.key == 'spaceType'
        )
        const paramObj = { pageIndex: 1, pageSize: 200, dictType: 'space_type' }
        this.util.rqOptionsList({ who: [this.rowFormData[index1]], rqUrl: "/sys/dictData/query", rqData: paramObj, rqMethod: 'post', labelName: 'dictLabel', valueName: 'dictValue' })
        this.util.rqOptionsList({
            who: [this.rowFormData[index]],
            rqUrl: '/building/list',
            rqMethod: 'post',
            labelName: 'buildingName',
            valueName: 'buildingId',
            resRule: 'data'
        })

    },
    methods: {
        getInit1(id) {
            this.loading_form.isShow = true
            if (!isNaN(id)) {
                this.$set(this.fromData, 'spaceType', id)
            }
            this.tableData = []
            spaceManageList(this.fromData).then(res => {
                this.loading_form.isShow = false
                const tableData = res.data.data
                for (let i = 0; i < tableData.length; i++) {
                    this.tableData.push({
                        key: tableData[i].id + "",
                        label: tableData[i].spaceTypeName + ':' + tableData[i].spaceCode,
                        disabled: 0
                    })
                }
            })
        },
        /**
         * initAction(state,row)
         * state: 1=新增,2=編輯
         * row：携带进来的数据
         */
        initAction(state, row) {
            this.rowForm = {}
            this.show = true
            this.$set(this.rowForm, 'spaceType', '1')
            this.spaceIds = []
            if (state == 1) {
                this.isAdd = true
                this.titleText = '新增租赁区域'
                this.getInit1(this.rowForm.spaceType)
            } else if (state == 2) {
                let index = this.rowFormData.findIndex(
                    (item) => item.key == 'floorId'
                )
                this.spaceIds = row.spaceIds.split(',') || []
                this.getInit1(row.spaceType)
                this.util.rqOptionsList({ who: [this.rowFormData[index]], rqUrl: "/floor/allList", labelName: 'floorName', valueName: 'floorId', resRule: 'data' })
                this.isAdd = false
                this.titleText = '编辑租赁区域'
                this.rowForm = { ...row }

            }
        },
        //校验 跳转下一个
        confirmButton () {
            let self = this.$refs.jaFrom.$refs['formbox']
            self.validate((valid) => {
                if (valid) {
                    this.$refs.unit.init({ ...this.rowForm, spaceIds: this.spaceIds.toString() }, this.isAdd);
                    this.show = false
                } else {
                    return false;
                }
            });
        },
        // 空间类型 改变处理
        spaceTypeEvent(row, data) {
            this.spaceIds = []
            this.getInit1(data)
        },
        filterMethod (query, item) {
            return item.label.indexOf(query) > -1
        }
    }
}

</script>
