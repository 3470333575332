<template>
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title="titleText"  :before-close="reset">
      <ja-form
        :rules="rules"
        ref="jaForm"
        :label-width="labelWidth"
        v-model="rowForm"
        :loadingForm="loadingForm"
        :btnBox="btnArrObj[btnType]"
        :formArray="rowFormData"
        @reset="reset"
        @nextBtn="nextBtn"
        @lastBtn="lastBtn"
        @setTime="setTime"
        @frequencySelect="frequencySelect"
        @selectDevice="selectDevice"
        @save="saveForm" v-if="showEditAll_change"
        >
            <template #frequencyDay>
                <ul class="frequencyDay" v-if='rowForm.frequency'>
                    <li>
                        <div>
                            <span>{{dateList[rowForm.frequency].words[0]}}</span>
                            <el-select v-model="rowForm.month" v-if="rowForm.frequency != 'month'"><el-option v-for="item in dateList[rowForm.frequency].options" :key="item.value" :label="item.label" :value="item.value"></el-option></el-select><span>{{dateList[rowForm.frequency].words[1]}}</span>
                            <el-select v-model="rowForm.day" ><el-option v-for="item in dateList.month.options" :key="item.value" :label="item.label" :value="item.value"></el-option></el-select><span>{{dateList[rowForm.frequency].words[2]}}</span>
                        </div>
                    </li>
                </ul>
            </template>
        </ja-form>
        
        <time-set :showTimeSet.sync="showTimeSet" :timeIntervalData="[...timeIntervalData]" :serviceName="rowForm.serviceName" :titleText="rowForm.deviceCategory == 186?'计冷风量':'计冷水量'"  v-if="showTimeSet" @changeTimeSet="changeTimeSet" :deviceCategory="rowForm.deviceCategory"/>
        
    </el-dialog>
</template>
<script>
import {FormObj,btnArrObj} from './AddEdit.js'
import TimeSet from '../TimeSet/TimeSet'
import {addPayItem,editPay} from '@/api'

export default {
    inject:['labelWidth','getInit',],
    components:{
        TimeSet,
    },
    data(){
        return{
            titleText:'新增',
            rules:{},
            rowForm:{},
            rowFormData:[],
            showEditAll:false,

            // 表单的按钮
            btnType:'first',
            btnArrObj,

            // 第二个表单的
            active_type:'periodicity',
            typeForm:{...FormObj},

            // 第一个表单的
            rules_first:{
                category:[{ required: true, message: '请选择收费类型', trigger: 'change' }]
            },
            rowFormData_first:[
                {input_type: 'select',label: '收费类型', key: 'category',options: [],col:24},
            ],

            dateList:{
                month:{options:[],words:['每月第','','天']}, // 28天
                quarter:{options:[],words:['每季第','个月，第','天']}, // 季度，3个月
                year:{options:[],words:['每年第','个月，第','天']}, // 12月
            },
            showEditAll_change:false,
            isAdd : true,

            timeIntervalData:[],
            showTimeSet:false,

            loadingForm:false,
        }
    },
    mounted(){
        this.util.rqOptionsList({who:[this.typeForm.periodicity.rowFormData[2],this.typeForm.instrument.rowFormData[3]],rqUrl:"/sys/dictData/service_cycle_frequency",resRule:'data'}) 
        this.util.rqOptionsList({who:[this.typeForm.periodicity.rowFormData[4]],rqUrl:"/sys/dictData/service_cycle_category",resRule:'data'})
        this.util.rqOptionsList({who:[this.typeForm.periodicity.rowFormData[5]],rqUrl:"/sys/dictData/service_cycle_mode",resRule:'data'})
        // 所属合同
        this.util.rqOptionsList({who:[this.typeForm.periodicity.rowFormData[6]],rqUrl:"/sys/dictData/bill_type",resRule:'data'})

        this.util.numberRange(1,28,this.dateList.month)
        this.util.numberRange(1,3,this.dateList.quarter)
        this.util.numberRange(1,12,this.dateList.year)
        this.util.rqOptionsList({who:[this.typeForm.instrument.rowFormData[6]],rqUrl:"/sys/dictData/service_cycle_mode",resRule:'data'})
        this.util.rqOptionsList({who:[this.typeForm.instrument.rowFormData[5]],rqUrl:"/sys/dictData/bill_type",resRule:'data'})
        this.util.rqOptionsList({who:[this.typeForm.instrument.rowFormData[2]],rqUrl:"/sys/dictData/energy_consumption_subcategory",resRule:'data'})
        this.util.rqOptionsList({who:[this.typeForm.deposit.rowFormData[3]],rqUrl:"/sys/dictData/bill_type",resRule:'data'})
        this.util.rqOptionsList({who:[this.typeForm.deposit.rowFormData[4]],rqUrl:"/sys/dictData/service_cycle_mode",resRule:'data'})
        this.util.rqOptionsList({who:[this.typeForm.temporary.rowFormData[3]],rqUrl:"/sys/dictData/bill_type",resRule:'data'})
        this.util.rqOptionsList({who:[this.typeForm.temporary.rowFormData[4]],rqUrl:"/sys/dictData/service_cycle_mode",resRule:'data'})
    },
    methods:{
        // 第一个表单的
        firstFormDeal(){
            this.showEditAll_change = false
            this.btnType = 'first' // 改变按钮
            this.rowFormData = [...this.rowFormData_first]
            
            this.rules = {...this.rules_first}
            this.$nextTick(()=>{
                this.showEditAll_change = true
            })
        },
        // 第二个表单的
        secondFormDeal(){
            this.showEditAll_change = false
            this.btnType = 'second' // 改变按钮
            let category = this.rowForm.category
            const formObj = this.typeForm[category]
            this.titleText = formObj.titleText
            this.rowFormData.splice(0,this.rowFormData.length,...formObj.rowFormData)
            this.rules = formObj.rules
            this.$nextTick(()=>{
                this.showEditAll_change = true
            })
            // console.log('下一个表单----- ', this.rowFormData,this.rules )
        },
        append(){
            this.isAdd = true
            this.titleText='新增'
            this.rowForm = {}
            this.firstFormDeal()
            this.showEditAll = true
            this.rowFormData[0].disabled = false
            this.showEditAll_change = true
        },
        // 表单提交前的校验
        checkForm(){
            let category = this.rowForm.category
            let text1 = '请完善'
            if(category == 'periodicity' || category == 'instrument' ){
                // 检查计费频率
                let frequency = this.rowForm.frequency
                let label = '“计费日期”'
                if(frequency == 'month'){ 
                    if(!this.rowForm.day){
                        this.$message.warning(text1+label)
                        return false
                    }
                }else{ // 如果是选择了 年份、季度
                    if(!this.rowForm.day || !this.rowForm.month){
                        this.$message.warning(text1+label)
                        return false
                    }
                }
                // 检查关联仪表
                if(category == 'instrument'){
                    let deviceCategory =this.rowForm.deviceCategory
                    if(deviceCategory == 186 ||deviceCategory == 187 ){
                        if(!this.rowForm.timeInterval ||this.rowForm.timeInterval.length == 0 ){
                            this.$message.warning('请设置“时段标准”')
                            return false
                        }
                    }
                }
                return true
            }else {
                return true
            }
        },
        saveForm(){
            let isOk = this.checkForm()
            if(!isOk) return
            let rqMethod = this.isAdd ? addPayItem : editPay 
            this.loadingForm = true
            console.log(this.rowForm);
            rqMethod({...this.rowForm,operationType:'save'}).then(res=>{
                this.reset()
                this.getInit()
                this.$message.success('保存成功')
                this.loadingForm = false
            }).catch(err=>{
                this.loadingForm = false
            })
            // console.log('this=====',this.rowForm)
        },
        // 上一步
        lastBtn(){
            this.titleText = this.isAdd?'新增':'编辑'
            this.firstFormDeal()
        },
        nextBtn(){
            let category = this.rowForm.category
            if(this.isAdd){
                let obj = {category}
                obj.frequency = 'month'
                this.rowForm = obj 
            }else{
               
            }
            this.secondFormDeal()
            if(category == 'instrument'){
                this.selectDevice()
            }
        },
        // 关联仪表变化的处理
        selectDevice(){
            let index = this.rowFormData.findIndex(v=>v.key == 'unitPrice')
            let index2 = this.rowFormData.findIndex(v=>v.key == 'timeInterval')
            let deviceCategory = this.rowForm.deviceCategory
            // console.log('deviceCategory===》',deviceCategory)
            // 124 水表单位为m³，125 电表单位为kw · h， 186 风冷单位为km³，187 水冷单位为kw · h
            if( deviceCategory == 124 ||  deviceCategory == 125 ){ // 124 水表
               this.$set(this.rowFormData[index],'hide',false)
               this.$set(this.rowFormData[index].rightBox,'text',deviceCategory ==124?'元/m³':'元/kw · h')
               this.$set(this.rowFormData[index2],'hide',true)
            }else if(deviceCategory == 186 || deviceCategory == 187){
               this.$set(this.rowFormData[index],'hide',true)
               this.$set(this.rowFormData[index2],'hide',false)
            }else{
               this.$set(this.rowFormData[index],'hide',true)
               this.$set(this.rowFormData[index2],'hide',true)
            }
        },
        // 计费频率的切换,情况
        frequencySelect(){
            this.$set(this.rowForm,'month','')
            this.$set(this.rowForm,'day','')
        },
        rowEdit () {
            this.isAdd=false    
            this.titleText='编辑'
            this.firstFormDeal()
            let row = this.$parent.activeRow
            this.rowForm={...row,timeInterval:row.timeIntervalList}
            this.rowFormData[0].disabled = true
            this.showEditAll = true
            this.showEditAll_change = true

        },
        changeTimeSet(list){
            this.rowForm.timeInterval = [...list]
            if(list.length > 0){
                this.$message.success('设置 时间段标准 成功')
            }
        },
        setTime(){
            this.timeIntervalData = this.rowForm.timeInterval||[]
            // console.log('打开时间段设置---》',this.rowForm)
            this.showTimeSet = true
        },
        reset(){
            this.showTimeSet = false
            this.showEditAll = false;
            this.showEditAll_change = false
        }
    }
}
</script>
<style lang="scss" scoped>
.frequencyDay{
    /deep/ .el-select{
        width: 100px;
        margin:0 8px;
    }
}
.elDialog{
    /deep/ .el-dialog__body{
        padding: 0 20px;
    }
} 
</style>