<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  showSelect
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @submitRow="submitRow"
                  @append="batchSubmit"
                  @handleSelectionChange="handleSelectionChange"
                  @showDetails="showDetails">
        </ja-table>
        <submit-view ref="submitRefs"></submit-view>
    </div>
</template>

<script>
import { editbizBillInfoNew, getBillList } from '@/api'
import table from '@/views/mixins/table'
import SubmitView from './submitView.vue'
export default {
    title: '待提交',
    mixins: [table],
    name: 'submittedList',
    components: { SubmitView },
    data () {
        return {
            conditionData: [
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '租赁单元名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '计费周期开始时间', endPlaceholder: '计费周期结束时间' },
                { input_type: 'date', type: 'month', value: 'billDate', placeholder: '账单日期' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '批量提交账单' }
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', width: 200 },
                { label: '租赁单元名称', prop: 'roomName', width: 200 },
                { label: '计费周期', prop: 'date', width: 200 },
                { label: '账单时间', prop: 'billDate', width: 200 },
                { label: '租金', prop: 'rent', width: 200 },
                { label: '物业管理费', prop: 'propertyManagementFee', width: 200 },
                { label: '电费', prop: 'electricity', width: 200 },
                { label: '设备维护费服务费（电）', prop: 'iptElectricity', width: 200 },
                { label: '水费', prop: 'water', width: 200 },
                { label: '设备维护费服务费（水）', prop: 'iotWater', width: 200 },
                { label: '状态', prop: 'statusName', width: 200 },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'showDetails', limit: 'contractFile::submittedList::showDetails' },
                        { text: '提交', method: 'submitRow', limit: 'contractFile::submittedList::submitRow' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,
            selectRows: [],
            filterData: {
                pageIndex: 1,
                pageSize: 10,
                status: '2'
            }
        }
    },
    methods: {
        getInit () {
            const obj = { ...this.filterData }
            if (obj.date) {
                obj.billDateStart = obj.date[0]
                obj.billDateEnd = obj.date[1]
                delete obj.date
            }
            this.loading = true
            getBillList(obj).then(res => {
                res.data.data.map(item => {
                    item.date = `${item.billDateStart}~${item.billDateEnd}`
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        showDetails (row) {
            this.$refs.submitRefs.init(2, row)
        },
        submitRow (row) {
            this.$refs.submitRefs.init(1, row)
        },
        editDataFormat (row, btn) {
            if (row.statusName === '待提交' && btn.text === '提交') {
                return true
            } else if (row.statusName === '已提交' && btn.text === '查看') {
                return true
            }
        },
        handleSelectionChange (val) {
            this.selectRows = val
        },
        batchSubmit () {
            if (this.selectRows.length <= 0) return this.$message.error('请先勾选确认的账单')
            this.$confirm('是否批量确认账单？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const ids = []
                this.selectRows.map(item => {
                    ids.push(item.id)
                })
                await editbizBillInfoNew({ ids: ids.join(), status: 3 })
                this.$message.success('提交成功')
                this.selectRows = []
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                })
            })
        }
    }
}
</script>
