<template>
    <div>
        <el-row :gutter="50" v-loading="headLoading">
            <el-col :span="4">
                <el-card shadow="never" :body-style="{ background: 'rgba(83, 110, 196, 1)' }">
                    <p style="white-space: nowrap;">营业总额</p>
                    <div class="testBox">{{ Operatetotal || 0 }}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never" :body-style="{ background: 'rgba(141, 197, 113, 1)' }">
                    <p style="white-space: nowrap;">未完成数</p>
                    <div class="testBox">{{ Operatepasstotal || 0 }}家</div>
                </el-card>
            </el-col>
        </el-row>
        <ja-table v-model="filterData" :conditionData="conditionData" :columns="columns"
            :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search"
            @reset="reset" @rowDetail="normalEvent">
        </ja-table>

        <!-- 新增/编辑 -->
        <detail ref="detail" />
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import Detail from './components/Detail.vue'
import { getturnoverList, getturnovergetMonth } from '@/api'
export default {
    title: '营业数据',
    mixins: [table,],
    name: 'index',
    components: {
         Detail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'date', type: 'monthrange', value: 'time', startPlaceholder: '开始月份', endPlaceholder: '结束月份', valueFormat: 'yyyy-MM', attribute: { 'unlink-panels': true } },
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'select', key: 'status', attribute: { placeholder: '选择状态', size: 'small' }, options: [
                    { label: '未完成', value: '0' },
                    { label: '已完成', value: '1' },
                ] },
                { normalBtn: 'search', },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/turnover/export', rqMethod: 'post' } },
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName' },
                { label: '月份', prop: 'date' },
                { label: '总营业额', prop: 'totalTurnover', width: 140 },
                { label: '状态', prop: 'status', width: 340 },
                {
                    label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
                    list: [
                        { text: '查看明细', method: 'rowDetail'},
                    ]
                }
            ],
            tableData: [],
            Operatetotal:0,//营业总额
            Operatepasstotal:0// 未完成数
        }
    },
    mounted() {
    },
    methods: {
        rqParmas() {
            let obj = { ...this.filterData }
            if (obj.time) {
                obj.startTime = obj.time ? obj.time[0] : ''
                obj.endTime = obj.time ? obj.time[1] : ''
                delete obj.time
            }
            return obj
        },
        getInit() {
            this.loading = false
            let data = this.rqParmas()
            getturnoverList(data).then(res => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
            getturnovergetMonth(data).then(res => {
                this.Operatetotal = res.data.totalTurnover
                this.Operatepasstotal = res.data.unfinished
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;

    .el-card {
        color: #fff;
        border-radius: 0.4vw;

        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 2vw;
        }
    }
}
</style>