<template>
    <div>
        <el-dialog v-dialogDrag
            :close-on-click-modal="false"
            width="60%"
            :title="title"
            :visible.sync="show"
            v-if="show">
            <ja-table
                  :columns="columns"
                  noSearch
                  :showPage="false"
                  :tableData="tableData"
                  :loading="loading"
                  >

            </ja-table>
            <div slot="footer" class="dialog-footer">
                <el-button  @click="show = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    inject: ['getInit'],
    title: '查看工单记录',
    data() {
        return {
            //表头名称
            columns: [
                { label: '操作时间', prop: 'createTime',width:180 },
                { label: '操作人', prop: 'createBy',width:150 },
                { label: '操作处理', prop: 'operationProcessing' },
            ],
            //表数据
            tableData: [
               
            ],
            show: false,
            title: '查看操作记录',
            loading:false
        }
    },
    methods: {
        showDetails(row) {
            this.tableData = row
            this.show = true
        },
    }
}
</script>
<style lang="scss" scoped>
    .app-container{
        padding-bottom: 0;
    }
</style>
