<template>
    <el-dialog :visible.sync="show"
               :close-on-click-modal="false"
               width="70%"
               :title="title">
        <ja-form :rules="rules"
                 v-if="show"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :btnBox="btnBox"
                 :allDisabled="allDisabled"
                 @adopt="adopt"
                 @reset="show = false">
            <template #mesContent>
                <el-tooltip placement="bottom">
                    <div slot="content">
                        计算优惠：如有减免调整后金额，则预计实收合计为调整后金额-优惠金额；
                        <br />
                        不计算优惠：按照调整后应收金额合;
                    </div>
                    <el-button type="text"
                               icon="el-icon-warning"
                               size=""></el-button>
                </el-tooltip>

            </template>
            <template #total>
                <span>合计：{{total}}</span>
            </template>
        </ja-form>
    </el-dialog>
</template>
<script>
import { getbizBillDetailNewList, editbizBillInfoNew } from '@/api'
export default {
    inject: ['getInit'],
    data () {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', labelWidth: '110px', col: 5 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '110px', col: 11 },
                { input_type: 'select', label: '合计规则', key: 'sumRule', labelWidth: '110px', col: 6, disabled: true, options: [{ label: '不计算优惠', value: '0' }, { label: '计算优惠', value: '1' }] },
                { input_type: 'customize', customize: 'mesContent', label: '', col: 2, labelWidth: '10px' },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'bizBillDetailNewDTOs',
                    isRequired: true,
                    border: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType' },
                        { label: '收费项目性质', prop: 'natureCharges' },
                        { label: '收费项目名称', prop: 'serviceName' },
                        { label: '计费周期', prop: 'date' },
                        { label: '缴费周期', prop: 'day' },
                        { label: '本期应收', prop: 'receivable' },
                        { label: '调整后应收', prop: 'adjustedReceivable' },
                        { label: '优惠金额', prop: 'coupon' },
                        { label: '未收金额', prop: 'uncollected' },
                        { label: '备注', prop: 'remark' }
                    ]
                },
                { input_type: 'customize', customize: 'total', label: '', col: 24, labelWidth: '10px' }
            ],
            show: false,
            allDisabled: false,
            title: '应收账款提交',
            rowForm: {},
            btnBox: [
                { label: '取消', method: 'reset', col: 8, limit: '' },
                { label: '确认提交', method: 'adopt', type: 'primary', limit: 'contractFile::submitView::adopt' }
            ],
            total: 0

        }
    },
    methods: {
        async init (state = 1, row) {
            this.title = '应收账款提交'
            this.allDisabled = false
            this.show = true
            this.rowForm = {}
            this.total = row.actualReceivable || 0
            const res = await getbizBillDetailNewList({ billId: row.id, pageIndex: 1, pageSize: 1000 })
            res.data.data.map(item => {
                if (item.adjustedReceivable === 0) {
                    item.adjustedReceivable = null
                }
                item.date = `${item.billDateStart}~${item.billDateEnd}`
            })
            this.rowForm = { ...row, bizBillDetailNewDTOs: [...res.data.data] }
            if (state === 2) {
                this.title = '已提交应收账款查看'
                this.allDisabled = true
            }
            console.log(this.rowForm)
        },
        async adopt () {
            this.$confirm('是否确定提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await editbizBillInfoNew({ ...this.rowForm, status: 3 })
                this.$message.success('操作成功')
                this.show = false
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                })
            })
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
</style>
