import http from './plugin/ajax'
// 查询租赁单元列表
export const getRentalUnitList = (data) => http.post('/sysBuildingRoomsNew/list', data)
// 新增租赁单元列表
export const addRentalUnit = (data) => http.post('/sysBuildingRoomsNew', data)
// 编辑租赁单元列表
export const editRentalUnit = (data) => http.put('/sysBuildingRoomsNew', data)
// 编辑租赁单元列表
export const deleteRentalUnit = (data) => http.delete('/sysBuildingRoomsNew/' + data)
// 查询已租租赁单元列表
export const getRentOutlist = (data) => http.post('/sysBuildingRoomsNew/rentOutlist', data)

export const getRentalUnitHead = (data) => http.post('/sysBuildingRoomsNew/amount', data)
