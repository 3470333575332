<template>
  <div class="edit">
    <el-dialog v-dialogDrag :close-on-click-modal="false"
      :title="title"
      :visible.sync="printShow"
      width="80%"
      @close="close"
      :before-close="close"
    >
        <div class="title">
          <p>{{billDetailTableData.billType == 0 ? '租金缴费通知单(' + billDetailTableData.month + ')' : '物业管理费缴费通知单(' + billDetailTableData.month + ')'}}</p>
          <div class="content">
            <span>缴费单位/个人:{{ billDetailTableData.tenantName }}</span>
            <span>房间：{{ billDetailTableData.roomName }}</span>
            <span>账单日期：{{ billDetailTableData.day }}</span>
            <span>最后缴款日期：{{ billDetailTableData.lastPayDate }}</span>
          </div>
        </div>
        <JaTable
          :showSummary="true"
          :chooseArr="chooseArr"
          :noSearch="true"
          :border="border"
          :columns="billingTableHeadPrint"
          :tableData="billDetailTableData.billDetail"
          :showPage="showPage"
        >
        </JaTable>
        </el-dialog>
      </div>
</template>
<script>
import {billingTableHeadPrint} from '../billManage'
import JaTable from '@/components/ja-table'
export default {
   data(){
       return{
           billingTableHeadPrint,
           showPage: false,
           border: true,
           chooseArr: ['本期合计', '本期应收', '调整后应收'],
       }
   },
    components:{
      JaTable,
    },
   props: {
    id: String,
    title: String,
    printShow: {
      type: Boolean,
      default: false
    },
   
    // 账单明细数据
    billDetailTableData: [Object, Array]
  },
  methods:{
      /** 弹出框点击确认 */
    saveForm() {
      console.log(2)
    },
    /** 关闭弹出框 */
    close() {
      this.$emit('printClose', false)
    }
  }
}
</script>
<style less="scss" scoped>
 .title > p {
  width: 60%;
  font-size: 30px;
  font-weight: bold;
  margin: 0 auto;
}
.title > .content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>