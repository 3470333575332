<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="50%"
                   title="查看装修验收记录"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="{}"
                     v-model="rowForm"
                     :formArray="detailOptions"
                     :allDisabled="allDisabled"
                     @save="saveForm"
                     v-if="show">
                <template #imgList>
                    <div class="img-box">
                        <div v-for="(item,index) in rowForm.imgUrlList"
                             :key="index"
                             class="img-item">
                            <el-image :src="item"
                                      :preview-src-list="rowForm.imgUrlList"></el-image>
                        </div>
                    </div>
                </template>
            </ja-form>
            </ja-detail>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'input', label: '驻户名称', key: 'tenantName', labelWidth: '120px', placeholder: ' ', col: 12 },
                { input_type: 'input', label: '所属楼栋', key: 'buildingName', labelWidth: '120px', placeholder: ' ', col: 12 },
                { input_type: 'input', label: '所属楼层', key: 'floorName', labelWidth: '120px', placeholder: ' ', col: 12 },
                { input_type: 'input', label: '所属租赁单元', key: 'roomName', labelWidth: '120px', placeholder: ' ', col: 12 },
                { input_type: 'input', label: '施工单位', key: 'companyName', labelWidth: '120px', placeholder: ' ', col: 12 },
                { input_type: 'input', label: '施工负责人', key: 'decPerson', labelWidth: '120px', placeholder: ' ', col: 12 },
                { input_type: 'input', label: '负责人手机号码', key: 'decPhone', labelWidth: '120px', placeholder: ' ', col: 12 },
                { input_type: 'input', label: '验收人员', key: 'signInPerson', labelWidth: '120px', placeholder: ' ', col: 12 },
                { input_type: 'input', label: '验收时间', key: 'createTime', labelWidth: '120px', placeholder: ' ', col: 12 },
                { input_type: 'input', label: '验收内容', tepy: 'textarea', key: 'remark', labelWidth: '120px', placeholder: ' ', col: 24 },
                { input_type: 'customize', customize: 'imgList', label: '图片', col: 24, labelWidth: '120px' },
            ],
            rowForm: {},
            show: false,
            allDisabled: true,
        }
    },
    methods: {
        init(row) {
            this.show = true
            this.rowForm = { ...row }
            console.log(row);
        },
    }
}
</script>
<style lang="scss" scoped>
.img-box {
    display: flex;
    justify-content: space-between;
}
.img-item {
    height: 150px;
    width: 150px;
    .el-image {
        width: 100%;
        height: 100%;
    }
}
</style>
