/** 内部车辆管理页面搜索栏 */
export const internalVehicleQuery = [
    {
        input_type: 'input',
        key: 'carNumber',
        lable: '车牌号码',
        attribute: {
            placeholder: '车牌号码',
            size: 'small'
        }
    },
    {
        input_type: 'input',
        key: 'userName',
        lable: '使用人',
        attribute: {
            placeholder: '使用人',
            size: 'small'
        }
    },
    {
        input_type: 'input',
        key: 'mobile',
        lable: '联系方式',
        attribute: {
            placeholder: '联系方式',
            size: 'small'
        }
    },
    { normalBtn: 'search' },
    { normalBtn: 'reset' },
    { normalBtn: 'add', limit: 'internal::manage::add' }
]

/** 内部车辆管理页面表头栏 */
export const internalVehicleColumn = [
    { label: '车牌号码', prop: 'carNumber' },
    { label: '所属公司', prop: 'companyName' },
    { label: '所属部门', prop: 'deptName' },
    { label: '使用人', prop: 'userName' },
    { label: '联系方式', prop: 'mobile' },
    {
        label: '操作',
        input_type: 'button',
        width: 180,
        list: [
            { text: '查看', method: 'showDetail', limit: 'internal::manage::detail' },
            { text: '编辑', method: 'rowEdit', limit: 'internal::manage::update'},
            { text: '删除', method: 'rowDelete', limit: 'internal::manage::delete' }
        ]
    }
]
