<template>
    <div>
        <el-table ref="jaTable" :data="tableData" v-loading="loading" :highlight-current-row="highlightCurrentRow" @row-click="handRowClick">
            <el-table-column v-for="(item,index) in columns" :key="index" :label="item.label" :prop="item.prop" :width="item.width" :align="item.align?item.align:'center'" :fixed="item.list?'right':item.fixed" v-bind="{showOverflowTooltip:true,align:'center',...item.attribute}" v-on="item.events || {}" v-if="!item.hide">
                <template slot-scope="scope">
                    <slot v-if="item.slotName" :name="item.slotName" :row="scope.row" :prop="item.prop"></slot>
                    <template>
                        <span v-if="(item['input_type']==='text' || !item['input_type']) && !item.isButton">{{valueFormat(scope.row[item.prop], item.prop, scope.row)}}
                            <i v-if="(scope.row.status !== 2)&&(scope.row.source === 1 && item['prop'] === 'payProject')" class="el-icon-delete" style="color:red;margin-left: 10px;cursor:pointer" @click="$emit('iDeleteEvent',scope.row)"></i>
                        </span>
                    </template>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    props: {
        // table加载动画
        loading: {
            type: Boolean,
            ault: false
        },
        // 是否要高亮当前行
        highlightCurrentRow: {
            type: Boolean,
            default: false
        },
        columns: {
            type: Array,
            default: () => []
        },
        // table数据
        tableData: {
            type: Array,
            default: () => []
        },
        valueFormat: {
            type: Function,
            default: function (val, key, row, scope) {
                return val
            }
        }
    },
    data () {
        return {

        }
    },
    methods: {
        //点击行事件
        handRowClick (val) {
            this.$emit('handRowClick', val)
        }
    },
    mounted () {
        //监听时间车，更新选中
        this.$bus.$on('currentRowEvent', row => {
            //如果存在需要选中
            if (row >= 0 && this.highlightCurrentRow) {
                this.$nextTick(() => {
                    console.log(row);
                    this.$refs.jaTable.setCurrentRow(this.tableData[row])
                })
            }
        })
    },
    beforeDestroy () {
        this.$bus.$off('currentRowEvent')
    }
}
</script>