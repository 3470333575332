import http from './plugin/ajax'

/*
    能源统计
**/

export const getAnnualConsumption = () => http.get('/iot/energyConsumptionAmount') // 年度用量

// 租赁单元计量报表（电）
export const getTelegraphTableList = (data) => http.get('/iot/electricityReportForms', { params: data }) // 查询

export const getPaymentSlipRecord = (type) => http.get('/iot/' + type) // 查询生成费单记录
export const setPaymentSlip = (type, date) => http.get('/iot/' + type + "/" + date) // 生成缴费单
export const getFeebill = (data) => http.get('/iot/feebill', { params: data }) // 生成缴费单

// 租赁单元计量报表（水）
export const getWaterReportList = (data) => http.get('/iot/waterReportForms', { params: data }) // 查询
// 水电排行
export const getHydropowerRanking = (data) => http.get('/iot/waterElectricityRanking/' + data) // 查询.

//= =========水电明细=========
export const getWaterElList = (data) => http.post('/iotElectricityWaterMeter/list', data) // 查询列表
export const editWaterElList = (data) => http.put('/iotElectricityWaterMeter', data) // 修改
