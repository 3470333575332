<template>
  <div>
    <div class="content">
      <div class="leftBox">
        <div class="title">
          <p>组织表</p>
          <div class="right">
            <el-button type="primary" plain size="small" @click="addGroup">新增</el-button>
            <el-button type="danger" plain size="small" @click="delGroup">删除</el-button>
          </div>
        </div>
        <ul class='treeContent' v-loading="loadingTree">
          <template v-if="listTree.length>0">
            <li v-for="item in listTree" :key="item.id">
              <div class="checkBoxOut">
                <el-checkbox :true-label="item.id" @change="checkBoxChange"></el-checkbox>
              </div>
              <div class="name" :class="{'activeTree':activeTree===item.id}" @click="clickName(item)">{{item.groupName}}</div>
            </li>
          </template>
        </ul>
      </div>
      <div class="rightBox allwh">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @rowEdit="normalEvent" @append="append" @rowDelete="rowDelete" @getInit="getInit" @search="search" @reset="reset">
        </ja-table>
      </div>
    </div>

    <add-edit v-if="isShowAddEdit" :isShowAddEdit.sync="isShowAddEdit" :isOnePerson="false" :isShowSelectAll="true" @selectRow="addPersonSubmit" ref="addEdit" />
    <add-item ref="addItem" />
  </div>
</template>
<script>
import table from '@/views/mixins/table';
import AddEdit from './childComp/AddEdit.vue'
import AddItem from './childComp/AddItem.vue'
import { rqMsgGroupItem, delMsgGroupItem, addMsgGroupEmployee, rqMsgGroupDetail, delMsgGroupDetail } from '@/api'

export default {
  title: '办公设备',
  mixins: [table,],
  name: 'index',
  components: {
    AddEdit, AddItem
  },
  data() {
    return {
      checkedList: [], // 选中的值
      listTree: [], // 左边树  
      activeTree: '', // 当前点击的
      // 表格
      conditionData: [
        { input_type: 'select', key: 'userType', attribute: { placeholder: '用户类型', size: 'small' }, options: [], },
        { input_type: 'input', key: 'userName', attribute: { placeholder: '账号名', size: 'small' } },
        { input_type: 'input', key: 'employeeName', attribute: { placeholder: '用户名称', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', },
      ],
      columns: [
        { label: '用户类型', prop: 'userType' },
        { label: '帐号', prop: 'userName' },
        { label: '用户名称', prop: 'employeeName', },
        { label: '所属单位', prop: 'companyName', },
        { label: '添加人', prop: 'createBy', },
        { label: '加入时间', prop: 'createTime', },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '删除', method: 'rowDelete', },
          ]
        }
      ],
      indexObj: {},
      groupList: [],
      isShowAddEdit: false,
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    // 用户类型下拉列表
    this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.userType],], rqUrl: "/sys/dictData/msg_user_type", resRule: 'data', })

  },
  methods: {
    async getInit() {
      if (this.activeTree === '') {
        let isOk = await this.getGroupList()
        if (!isOk) return
      }
      this.loading = true
      let data = this.rqParmas()
      data.groupId = this.activeTree
      rqMsgGroupDetail(data).then(res => {
        // console.log(res)
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
      }).catch(err => {
        this.loading = false
      })
    },

    // 获取组织列表
    getGroupList() {
      return new Promise(resolve => {
        this.loadingTree = true
        rqMsgGroupItem().then(res => {
          this.checkedList = []
          this.listTree = res.data
          if (this.activeTree === '') {
            this.activeTree = this.listTree[0].id
          }
          this.loadingTree = false
          resolve(true)
        }).catch(err => {
          this.loading = false
          resolve(false)
        })
      })
    },
    // ========================   左侧  =================
    // 勾选框
    checkBoxChange(Id) {
      let isExist = this.checkedList.findIndex(v => v === Id)
      if (isExist > -1) {
        this.checkedList.splice(isExist, 1)
      } else {
        this.checkedList.push(Id)
      }
    },
    // 点击组织名
    clickName(item) {
      this.activeTree = item.id
      this.reset()
    },
    // 删除组织组 --- 左侧
    delGroup() {
      if (this.checkedList.length === 0) {
        this.$message.warning('请先选择要删除的组织')
        return
      }
      this.$confirm('是否确认删除？', '操作确认', { type: 'warning' }).then(res => {
        this.loadingTree = true
        let isExist = this.checkedList.find(v => v === this.activeTree)
        delMsgGroupItem(this.checkedList).then(res => {
          if (isExist) this.activeTree = ''
          this.checkedList = []
          this.$message.success(res.msg || '删除成功')
          this.getGroupList().then(res => {
            this.getInit()
          })
          this.loadingTree = false
        }).catch(err => {
          this.loadingTree = false
        })
      })
    },
    // 新增组织，左侧列表
    addGroup() {
      this.$refs.addItem.append()
    },
    // ----------------------- 右侧 ------------------
    // 打开新增弹窗，右侧列表
    append() {
      this.isShowAddEdit = true
    },
    // 新增用户 的提交
    addPersonSubmit(obj) {
      this.$refs.addEdit.loadingForm = true
      addMsgGroupEmployee({
        groupId: this.activeTree,
        employeeIds: (obj.list || []).map(v => v.employeeId)
      }).then(res => {
        this.$refs.addEdit.loadingForm = false
        this.$message.warning(res.msg || '新增成功')
        this.getInit()
        this.isShowAddEdit = false
      }).catch(err => {
        this.$refs.addEdit.loadingForm = false
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该条数据？', '操作确认', { type: 'warning' }).then(res => {
        delMsgGroupDetail(row.id).then(res => {
          this.$message.success(res.msg || "已删除")
          this.getInit()
        })
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';

.content {
  display: flex;
  .leftBox {
    margin: 16px 0 0 15px;
    width: 250px;
    height: calc(100vh - 200px);
    flex-shrink: 0;
    .title {
      margin-bottom: 15px;
      background-color: #ffffff;
      padding: 10px 15px;
      font-size: 13px;
      background: white;
      color: #606266;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > p {
        line-height: 40px;
      }
    }
    .treeContent {
      height: calc(100% - 75px);
      margin-top: 15px;
      padding: 20px;
      border: 1px solid #f0f2f5;
      background-color: white;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;
      > li {
        display: flex;
        margin-bottom: 15px;
        .checkBoxOut {
          height: 19px;
        }
        .name {
          margin-left: 10px;
          line-height: 19px;
          font-size: 14px;
          &:hover {
            cursor: pointer;
          }
          &.activeTree {
            color: #409eff;
          }
        }
      }
    }
  }
  .rightBox {
    .bigTitle {
      color: #606266;
      font-size: 20px;
      margin: 10px 0;
    }
  }
}
</style>