<template>
    <el-dialog
        :visible.sync="show"
        width="40%"
        title="查看"
    >
        <ja-form
            :rules="rules"
            allDisabled
            v-model="rowForm"
            :formArray="rowFormData"
            v-if="show"
            :editDataFormat="editDataFormat"
        >
        </ja-form>
    </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
    components: {
        JaForm,
    },
    data() {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'input', label: '楼栋', key: 'buildingName', labelWidth: "10em" },
                { input_type: 'input', label: '楼层', key: 'floorName', labelWidth: "10em" },
                { input_type: 'input', label: '租赁单元名称', key: 'roomName', labelWidth: "10em" },
                { input_type: 'input', label: '租赁单元类型', key: 'roomTypeValue', labelWidth: "10em" },
                { input_type: 'input', label: '套内总体使用面积', key: 'totalArea', labelWidth: "10em" },
                { input_type: 'input', label: '租赁状态', key: 'statusValue', labelWidth: "10em" }
            ],
            show: false,
            rowForm: {},
        }
    },
    methods: {
        init(row) {
            this.show = true
            this.rowForm = { ...row }
        }
    }
}
</script>