<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :modal="false"
                   :visible.sync="show"
                   width="55%"
                   :title="!allDisabled?'费用配置':'查看费用配置'">
            <ja-form ref="customFrom"
                     :rules="rules"
                     v-model="rowForm"
                     v-loading="loading"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     labelWidth="11vw"
                     @reset="reset"
                     @save="saveForm"
                     @deleteTabelRow="deleteTabelRow"
                     @generateDetailsClick="generateDetailsClick"
                     @selectServiceName="selectServiceName"
                     v-if="show">

                <template #addTabel>
                    <el-button type="text"
                               v-show="state != '3'"
                               size="mini"
                               style="float:right"
                               @click="addTabel">添加一项</el-button>
                </template>
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { getRentList, getChargingSettings, getContractRentDetail } from '@/api'
import deepCopy from '@/util/deepCopy.js'
export default {
    props: {
        allDisabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            rowForm: { payServiceList: [] },
            rules: {
                billingArea: [{ required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }],
                unitPrice: [{ required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }],
                initRent: [{ required: true, trigger: 'change', message: '不能为空' }]

            },
            rowFormData: [],
            rentClo: [
                { input_type: 'title', text: '基本信息', col: 24, disabled: false },
                { input_type: 'input', label: '租赁单元名称', key: 'roomName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '租赁单元类型', key: 'roomTypeValue', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '所属楼栋', key: 'buildingName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '所属楼层', key: 'floorName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '套内总体使用面积', key: 'totalArea', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'title', text: '租金配置', col: 24, disabled: false },
                { input_type: 'input', label: ' 计租面积（㎡）', key: 'billingArea', col: 12, styleWidth: '80%', disabled: false },
                { input_type: 'input', label: ' 租金单价（元）', key: 'unitPrice', col: 12, styleWidth: '80%', disabled: false },
                { input_type: 'input', label: ' 租金（元/月）', key: 'initRent', col: 14, styleWidth: '62%', disabled: true, placeholder: '输入单价自动计算租金' },
                { label: '', rightBoxItem: { input_type: 'button', text: '生成明细', method: 'generateDetailsClick' }, col: 6, bukey: 1, disabled: false, key: 'detailedButton' },
                { input_type: 'title', text: '每月租金明细', col: 24, key: 'detailed', hide: false, disabled: false },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'detailedList',
                    isRequired: true,
                    border: true,
                    table_MaxHeight: '500',
                    hide: false,
                    columns: [
                        { label: '账单周期', prop: 'date' },
                        { label: '租金（元/月）', prop: 'rent' },
                        { column_type: 'number', label: '调整租金（元/月）', prop: 'adjustRent', min: 0 }

                    ]
                }

            ],
            matterClo: [
                { input_type: 'title', text: '基本信息', col: 24, disabled: false },
                { input_type: 'input', label: '租赁单元名称', key: 'roomName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '租赁单元类型', key: 'roomTypeValue', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '所属楼栋', key: 'buildingName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '所属楼层', key: 'floorName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '套内总体使用面积', key: 'totalArea', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'title', text: '物管配置', col: 24, disabled: false },
                { input_type: 'input', label: ' 计租面积（㎡）', key: 'billingArea', col: 12, styleWidth: '80%', disabled: false },
                { input_type: 'input', label: ' 管理费单价（元）', key: 'unitPrice', col: 12, styleWidth: '80%', disabled: false },
                { input_type: 'input', label: ' 物业管理费（元/月）', key: 'initRent', col: 14, styleWidth: '62%', disabled: true, placeholder: '输入单价自动计算物业管理费' },
                { label: '', rightBoxItem: { input_type: 'button', text: '生成明细', method: 'generateDetailsClick' }, col: 6, bukey: 1, disabled: false, key: 'detailedButton' },
                { input_type: 'title', text: '每月物管明细', col: 24, key: 'detailed', hide: false, disabled: false },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'detailedList',
                    isRequired: true,
                    border: true,
                    table_MaxHeight: '500',
                    hide: false,
                    columns: [
                        { label: '账单周期', prop: 'date' },
                        { label: '租金（元/月）', prop: 'rent' },
                        { column_type: 'number', label: '调整租金（元/月）', prop: 'adjustRent', min: 0 }
                    ]
                },
                { input_type: 'title', text: '其他收费项', col: 24 },
                { input_type: 'customize', customize: 'addTabel', col: 24 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'payServiceList',
                    isRequired: true,
                    border: true,
                    allDisabled: false,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType' },
                        { column_type: 'select', label: '收费项目名称', prop: 'serviceName', options: [], placeholder: '请选择', method: 'selectServiceName' },
                        { label: '收费项目性质', prop: 'natureCharges' },
                        { label: '缴费周期', prop: 'dayName' },
                        { label: '收费标准名称', prop: 'feesName' },
                        { label: '收费标准', prop: 'fees' },
                        { label: '费用（单价）', prop: 'unitPrice' },
                        {
                            label: '操作',
                            input_type: 'button',
                            attribute: { fixed: "right", align: 'center' },
                            list: [
                                { text: '删除', method: 'deleteTabelRow' }

                            ]
                        }
                    ]
                }
            ],
            tableData: [],
            show: false,
            loading: false,
            oldData: { billingArea: '', unitPrice: '' },
            state: null

        }
    },
    computed: {
        initRent: function () {
            const data = this.rowForm
            if (data.billingArea >= 0 && data.unitPrice >= 0) {
                const num = Number(data.unitPrice * data.billingArea)
                return num.toFixed(2)
            } else {
                return 0
            }
        }

    },
    watch: {
        initRent: function (newVla) {
            if (newVla) {
                this.rowForm.initRent = newVla
            }
        }
    },
    mounted () {
        getChargingSettings({ pageIndex: 1, pageSize: 100, natureCharges: '周期性' }).then(res => {
            const data = res.data.data
            data.map(item => {
                if (item.day) {
                    item.dayName = `每月${item.day}日`
                } else {
                    item.dayName = '-'
                }
                item.label = item.serviceName
                item.value = item.id
            })
            const length = this.matterClo.findIndex(item => item.key == 'payServiceList')
            this.matterClo[length].columns[1].options = data
            this.tableData = data
        })
    },
    methods: {
        async init (row, contractRentReqs = [], payServiceList = [], state = 0) {
            this.loading = true
            this.rowForm = {}
            this.show = true
            this.rowForm = { ...row, payServiceList: [], detailedList: [] }
            // 0-租赁合同，1-物管合同
            if (row.contractType == '0') {
                this.rowFormData = this.rentClo
            } else if (row.contractType == '1') {
                this.rowFormData = this.matterClo
            }
            console.log(row.contractType)
            this.state = state
            this.initFormData(state)
            // 回显逻辑
            if (contractRentReqs.length > 0) {
                const obj = deepCopy(contractRentReqs[0])
                this.rowForm = { ...obj, ...row }
            } else {
                // 回显远程数据
                const { roomId, contractId, changeId } = row
                if (roomId && contractId) {
                    let rentData
                    if (changeId == 0) {
                        rentData = await getRentList({ roomId, contractId })
                    } else {
                        rentData = await getRentList({ roomId, contractId, changeId })
                    }
                    const { billingArea, unitPrice, initRent } = rentData.data
                    this.rowForm = { billingArea, unitPrice, initRent, ...row, payServiceList }

                    // 如果存在合同id，请求明细回显
                    const detailedList = await getContractRentDetail({ roomId, contractId })
                    const arr = Array.isArray(detailedList.data) ? detailedList.data : []
                    arr.map(item => {
                        item.date = item.startTime + '~' + item.endTime
                    })
                    this.$set(this.rowForm, 'detailedList', arr)
                }
            }

            // 记录原始租金数据
            this.oldData = { billingArea: this.rowForm.billingArea || 0, unitPrice: this.rowForm.unitPrice || 0 }

            if (this.rowForm.payServiceList) {
                this.initSelectData()
                this.rowForm.payServiceList.map(item => {
                    if (item.day) {
                        item.dayName = `每月${item.day}日`
                    } else {
                        item.dayName = '-'
                    }
                })
            }

            // 隐藏按钮
            const buKeyLength = this.rowFormData.findIndex(item => item.bukey == '1')
            if (this.allDisabled) {
                if (buKeyLength >= 0) {
                    this.$set(this.rowFormData[buKeyLength], 'hide', true)
                }
            } else {
                this.$set(this.rowFormData[buKeyLength], 'hide', false)
            }
            console.log(this.rowForm)
            // 判断是否存在明细，不存在隐藏表格
            if (Array.isArray(this.rowForm.detailedList) && this.rowForm.detailedList.length > 0) {
                this.hideDetailed(false)
            } else {
                this.hideDetailed(true)
            }
            this.loading = false
        },
        reset () {
            this.show = false
        },
        saveForm () {
            const obj = deepCopy(this.rowForm)
            const mas = this.checkFrom(obj)
            if (mas) {
                this.$message.error(mas)
                return false
            }
            this.$emit('getContractRentReqs', obj)
            this.show = false
        },
        addTabel () {
            this.rowForm.payServiceList.push({})
        },
        deleteTabelRow (row, bt) {
            const length = this.matterClo.findIndex(item => item.key == 'payServiceList')
            this.rowForm.payServiceList.splice(bt.$index, 1)
            if (row.value) {
                this.matterClo[length].columns[1].options.push(row)
            }
        },
        selectServiceName (row, val, index, $index) {
            const length = this.tableData.findIndex(item => item.id == val)

            this.$set(this.rowForm.payServiceList, $index, { ...this.tableData[length] })

            this.initSelectData()
        },
        initSelectData () {
            const ids = this.rowForm.payServiceList.map(item => {
                return item.id
            })
            const arr = this.tableData.filter(item => {
                if (ids.indexOf(item.id) < 0) {
                    return item
                }
            })
            const length = this.matterClo.findIndex(item => item.key == 'payServiceList')
            this.matterClo[length].columns[1].options = arr
        },
        generateDetailsClick () {
            this.$refs.customFrom.$refs.formbox.validate(async (valid) => {
                if (valid) {
                    // 发请求拿数据
                    const { leaseTerm, initRent } = this.rowForm
                    let res = null
                    console.log(leaseTerm, initRent)
                    res = await getContractRentDetail({ startTime: leaseTerm[0], endTime: leaseTerm[1], rent: initRent })
                    const arr = res.data
                    arr.map(item => {
                        item.date = item.startTime + '~' + item.endTime
                    })
                    this.$set(this.rowForm, 'detailedList', arr)
                    this.hideDetailed(false)
                    // 更新租金数据
                    this.oldData = { billingArea: this.rowForm.billingArea, unitPrice: this.rowForm.unitPrice }
                } else {
                    return false
                }
            })
        },
        hideDetailed (isShow) {
            const length = this.rowFormData.findIndex(item => item.key == 'detailed')
            this.rowFormData[length].hide = isShow
            this.rowFormData[length + 1].hide = isShow
        },
        checkFrom (obj) {
            let isOk = false
            const oldData = this.oldData
            // 校验一下是否修改了配置信息但未生成新的明细
            for (const key in oldData) {
                console.log(oldData[key], obj[key])
                if (oldData[key] != obj[key]) {
                    return '请生成明细'
                }
            }

            if (obj.payServiceList) {
                obj.payServiceList.map(item => {
                    if (isNaN(item.id)) {
                        isOk = true
                    }
                })
                if (isOk) return '请填写完整其它收费项'
            }
        },
        // 初始化表单，区分合同变更，是明细变更还是租赁单元变更
        initFormData (state) {
            const disArr = ['unitPrice', 'billingArea']
            let dis = false
            // 明细变更
            if (state == 3) {
                dis = true
            }
            console.log(dis, disArr)
            this.rowFormData.map(item => {
                if (disArr.indexOf(item.key) >= 0) {
                    item.disabled = dis
                } else if (item.key == 'payServiceList') {
                    item.allDisabled = dis
                }
            })
            console.log(this.rowFormData)
        }

    }
}
</script>
<style scoped>
::v-deep .titlePart {
    padding-left: 15px;
    background: #ebffff;
    width: 100%;
}
::v-deep .el-input-number__increase {
    display: none;
}
::v-deep .el-input-number__decrease {
    display: none;
}
</style>
