<template>
  <!-- 退还保证金和退还记录弹窗 -->
  <div class="bond_return_record">
    <el-dialog v-dialogDrag :close-on-click-modal="false" :title="isReturn ? '保证金退还操作' : '保证金退还记录'" :visible.sync="show" width="50%" @close="close" :before-close="close">
      <ja-form :rules="rules" v-model="rowFormData" :formArray="rowForm" @reset="close" @save="saveForm" v-if="isReturn && show" labelWidth="120px"></ja-form>
      <ja-detail v-else :options="detailOptions" :detailData="detailData" :label_width="8"></ja-detail>
    </el-dialog>
  </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import { getRefundRecord } from '@/api'
export default {
  components: {
    JaForm
  },
  data () {
    return {
      rowForm: [
        {
          input_type: 'number',
          label: '退还金额',
          key: 'depositRefundAmount',
          col: 22
        },
        {
          input_type: 'input',
          label: '退还备注',
          key: 'remark',
          type: 'text',
          col: 22
        }
      ],
      rowFormData: {},
      rules: {
        depositRefundAmount: [{ required: true, message: '请输入退还金额', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入退还备注', trigger: 'blur' }]
      },
      show: false,
      title: '',
      isReturn: false,

      detailOptions: [
        { label: '退还金额', key: 'depositRefundAmount', col: 22 },
        { label: '操作人', key: 'depositRefundOperator', col: 22 },
        { label: '退还备注', key: 'remark', col: 22 },
        { label: '退还时间', key: 'depositRefundTime', col: 22 },
      ],
      detailData: {}
    }
  },
  methods: {
    // 打开弹窗
    showDialog(row, isReturn){
      this.isReturn = isReturn
      this.rowFormData.id = row.id
      if(!isReturn) {
        // 获取保证金退还记录
        getRefundRecord(row.id).then(res => {
          if(res.code == 200){
            this.detailData = res.data
          }
        })
      }
      this.show = true
    },
    // 退还保证金
    saveForm () {
      this.$emit('depositRefund', this.rowFormData)
    },
    // 关闭弹窗
    close () {
      this.rowFormData = {}
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>
