<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="50%"
                   title="查看巡查项"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="{}"
                     v-model="rowForm"
                     :formArray="detailOptions"
                     :allDisabled="allDisabled"
                     @save="saveForm"
                     v-if="show">
            </ja-form>
            </ja-detail>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'select', label: '巡查项类型', key: 'patrolTypeName', labelWidth: '120px', opstion: [], col: 24, placeholder: ' ' },
                { input_type: 'input', type: 'textarea', label: '巡查内容', key: 'remark', labelWidth: '120px', col: 24, maxlength: '200', placeholder: ' ' },
            ],
            rowForm: {},
            show: false,
            allDisabled: true,
        }
    },
    methods: {
        init(row) {
            this.show = true
            this.rowForm = { ...row }
        },
    }
}
</script>
