<template>
    <div>
        <!-- 新增/编辑 -->
        <!-- reset: 取消 -->
        <!-- saveForm: 保存操作 -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="show" width="40%" @close="allDisabled=false" :title="titleText">
            <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" @reset="show = false" @save="saveForm" v-if="show" v-loading="loading_form.isShow" :allDisabled="allDisabled" :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon" :element-loading-background="loading_form.bg">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { addBuilding, updateBuilding } from '@/api'
export default {
    inject: ['getInit',],
    components: {
        JaForm
    },
    data () {
        return {
            show: false, // 显示新增/编辑弹窗
            titleText: '新增楼栋信息',
            rules: {
                buildingName: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText({textMessage:'楼栋名称'}) }],
            },
            rowForm: {},
            labelWidth: `${this.util.setFontSize(140)}px`,
            rowFormData: [
                { input_type: 'input', label: '楼栋名称', key: 'buildingName', width: 300, maxlength: 30 },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', placeholder: '请输入200字以内的描述', col: 22, maxlength: 200 }
            ],
            allDisabled: false,
            isAdd: false,
            loading_form: {
                isShow: false,
                text: '拼命加载中',
                icon: 'el-icon-loading',
            }
        }
    },
    methods: {
        /**
         * init(state,row)
         * state: 1=新增,2=編輯,3=查看
         * row：携带进来的数据
         */
        init (state, row) {
            this.show = true
            this.rowForm = {}
            if (state === 1) {
                this.isAdd = true
            } else if (state === 2) {
                this.isAdd = false
                this.titleText = '编辑楼栋信息'
                this.rowForm = { ...row }
            } else if (state === 3) {
                this.titleText = '查看楼栋信息'
                this.allDisabled = true
                this.rowForm = { ...row }
            }
        },
        saveForm () {
            if (this.isAdd) {
                this.loading_form.isShow = true
                addBuilding(this.rowForm).then(res => {
                    this.loading_form.isShow = false
                    this.show = false
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '新增成功'
                    });
                }).catch(err => {
                    this.loading_form.isShow = false
                })
            } else {
                this.loading_form.isShow = true
                updateBuilding(this.rowForm).then(res => {
                    this.loading_form.isShow = false
                    this.show = false
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '编辑成功'
                    });
                }).catch(err => {
                    this.loading_form.isShow = false
                })
            }
        }
    }
}

</script>