<template>
    <div class="allwh">
        <ja-table
            ref="tst"
            v-model="filterData"
            :conditionData="conditionData"
            :total="total"
            @getInit="getInit"
            @search="search"
            @reset="reset"
            :isTableSlot="true"
            >
            <template #tableTop>
                <div class="topNav" v-if="tenantName && tenantCode">
                    <span>驻户名称：{{tenantName}}</span>
                    <span>驻户编号：{{tenantCode}}</span>
                </div>
            </template>
             <template #tableSlot>
                <contract-list :contractList="contractList" v-if="contractList.length>0"/>
                <div v-else>暂无数据</div>
            </template>
        </ja-table> 
    </div>
</template>
<script>
import table from "@/views/mixins/table"
import mixinsData from '../common/mixinsData'
import {getContract} from '@/api'
import ContractList from './childComp/ContractList'

export default {
    title: '驻户合同',
    mixins: [table],
    components: { ContractList ,},
    name: 'index',
    data () {
        return {
            conditionData:[
                { label:'合同期限',input_type: 'date',type: 'daterange',value: 'time',format:'yyyy-MM-dd',valueFormat:'yyyy-MM-dd', startPlaceholder: '开始日期',endPlaceholder: '结束日期',attribute: {unlinkPanels:true}},
                { input_type: 'select',key: 'buildingId',attribute: {placeholder: '楼栋',size: 'small'},options: [],styleWidth:'100px',
                    relevance:{ sonKey:'floorId', This:this, rqParam:{ rqUrl:"/floor/all",rqData:()=>{return {params:{ buildingId: this.filterData.buildingId } }},labelName:'floorName',valueName:'floorId',resRule:'data'},}
                },
                { input_type: 'select',key: 'floorId',attribute: {placeholder: '楼层',size: 'small'},options: [],styleWidth:'100px'},
                { input_type: 'select',key: 'status',attribute: {placeholder: '合同状态',size: 'small'},options: [
                    {label:"生效",value:2},
                    {label:"过期",value:3}
                ],styleWidth:'100px'},

                { normalBtn:'search',},
                { normalBtn:'reset'},
            ],
            tenantName:'',
            tenantCode:'',
            tenantId:-1,
            contractList:[
             
            ],
            // InitPageSize:5,
        }
    },
    created() {
        this.util.rqOptionsList({ who: this.conditionData[1], rqUrl: "/building/list", rqData: { pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'buildingName', valueName: 'id' })
        // this.util.rqOptionsList({who:[this.conditionData[1]],rqUrl:"/device/category/linkList",resRule:'data',labelName:'categoryName',valueName:'id',rqData:{params:{pageIndex:1,pageSize:200}}}) // 关联仪表
        // console.log("1515",this.$route)
        this.tenantCode=this.$route.query.tenantCode
        this.tenantId=this.$route.query.id
        this.tenantName=this.$route.query.tenantName
        // this.getContract()
    },
    methods:{
        // 要请求的参数
        rqParmas(){
            return {
                pageSize: this.filterData.pageSize,
                pageIndex: this.filterData.pageIndex,
                buildingId:this.filterData.buildingId,
                floorId:this.filterData.floorId,
                status:this.filterData.status,
                tenantId : this.$route.query.id,
                startTime: this.filterData.time?this.filterData.time[0]:'',
                endTime: this.filterData.time?this.filterData.time[1]:'',
            }
        },
        formatter(val){
            return val
        },
        getInit() {
            this.loading = true
            let data =this.rqParmas()
            if(data.tenantId == -1){
                return
            }
            getContract(data).then(res => {
                this.loading = false
                this.contractList = res.data.data
                this.total = res.data.total
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.topNav{
    padding: 8px 0 15px;
    span{
        font-size: 16px;
        color: #606266;
        margin-right: 40px; 
    }
}
</style>
