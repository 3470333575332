<template>
  <!-- 内部车辆管理 -->
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      :valueFormat="formatter"
      @dictonaList="dictonaList"
      @rowDelete="rowDelete"
      @getInit="getInit"
      @search="search"
      @reset="reset"
      :showIndex="false"
      @showDetail="showDetail"
    >
    </ja-table>
    <add-edit ref="addEdit"></add-edit>
    <show-information ref="showInformation"></show-information>
  </div>
</template>

<script>
import { getInternalVehicleList, delInternalVehicle } from '@/api'
import table from '@/views/mixins/table'
import { internalVehicleQuery, internalVehicleColumn } from './internalVehicleColumn'
import AddEdit from './components/AddEdit.vue'
import ShowInformation from './components/ShowInformation.vue'

export default {
  title: '内部车辆信息',
  mixins: [table],
  name: 'index',
  components: {
    AddEdit,
    ShowInformation
  },
  data() {
    return {
      typeCode: '',
      filterData: {
        // pageIndex: 1,
        // pageSize: 10
      },
      conditionData: internalVehicleQuery,
      columns: internalVehicleColumn,
      tableData: [],
      loading: false,
      total: 30,
      rowForm: {}
    }
  },
  created() {},
  methods: {
    getInit() {
      this.loading = true
      // 获取楼层列表
      getInternalVehicleList({
        pageIndex: this.filterData.pageIndex,
        pageSize: this.filterData.pageSize,
        carNumber: this.filterData.carNumber,
        companyId: this.filterData.companyId,
        deptId: this.filterData.deptId,
        userId: this.filterData.userId,
        userName: this.filterData.userName,
        mobile: this.filterData.mobile
      }).then(({ data }) => {
        this.tableData = data.data
        this.total = data.total
        this.loading = false
      })
    },
    //新增
    append() {
      this.$refs.addEdit.init(1)
    },
    //修改
    rowEdit(row) {
      this.$refs.addEdit.init(2, row)
    },
    //查看
    showDetail(row) {
      this.$refs.showInformation.init(row)
    },
    // 删除
    rowDelete(row) {
      this.$confirm('删除后将不再保留车位，是否确定删除？').then((res) => {
        delInternalVehicle(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-col .el-form-item .el-input__inner {
  height: 40px;
}
/deep/ .el-select {
  width: 100% !important;
}
</style>
