import http from './plugin/ajax'

// 新闻资讯
export const addNews = (data) => http.post('/sys/news', data) // 添加
export const editNews = (data) => http.put('/sys/news', data) // 修改
export const rqNews = (data) => http.post('/sys/news/list', data) // 查询 列表
export const delNews = (data) => http.delete('/sys/news/' + data) // 删除 一项

// 通讯报装
export const rqMsgService = (data) => http.get('/routeInstall', { params: data }) // 查询 列表
export const getApprovalMsgService = (data) => http.put('/routeInstall/audit', data) // 审批 一项

// 客户调查 - 题库
export const addQuestion = (data) => http.post('/question', data) // 添加
export const editQuestion = (data) => http.put('/question', data) // 修改
export const rqQuestion = (data) => http.post('/question/list', data) // 查询 列表
export const delQuestion = (data) => http.delete('/question/' + data) // 删除 一项

// 客户调查 - 问卷
export const add_Questionnaire = (data) => http.post('/questionnaire', data) // 添加
export const edit_Questionnaire = (data) => http.put('/questionnaire', data) // 修改
export const rq_Questionnaire = (data) => http.post('/questionnaire/list', data) // 查询 列表
export const del_Questionnaire = (data) => http.delete('/questionnaire/' + data) // 删除 一项

// 投诉监督
export const delSupervision = (data) => http.delete('/psm/' + data) // 删除
export const replySupervision = (data) => http.put('/psm/reply', data) // 回复

// 物品放行
export const rqTransire = (data) => http.post('/transire/list', data) // 查询 列表
export const approveTransire = (data) => http.put('/transire', data) // 修改
export const getTransire = (data) => http.post('/transire/statistics', data) // 放行统计

// 活动申请
export const rqPsmActivity = (data) => http.post('/psmActivityInfo/list', data) // 查询 列表
export const approvePsmActivity = (data) => http.put('/psmActivityInfo', data) // 修改

// 建议反馈
export const getProposalList = (data) => http.post('/mindex/listAll', data) // 查询 列表
export const addFeedbackContent = (data) => http.put('//mindex', data) // 回复
export const getStatisticsTotal = (data) => http.post('/mindex/statistics', data) 

// 延时经营
export const getExtendedOperationList = (data) => http.post('/deferred/list', data) // 查询 列表
export const editExtendedOperation = (data) => http.put('/deferred', data) // 修改
export const getExtendedOperationstatistics = (data) => http.post('/deferred/statistics', data) // 查询 列表

//营业数据查询
export const getturnoverList = (data) => http.post('/turnover/list', data) // 查询 列表
export const getturnoverdetail = (data) => http.post('/turnover/detail', data) // 查询 明细
export const getturnovergetMonth = (data) => http.post('/turnover/statistics', data) // 查询 统计