<template>
  <!-- 表格 -->
  <div class="content">
    <title-box title="出租率" />
    <title-top title="房间总数" :num="num" unit="间" subTitle="" />
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import titleTop from './common/titleTop.vue'

export default {
  components: {
    JaInitChart, TitleBox, titleTop
  },
  data() {
    return {
      options: null,
      num: '100'
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    setOptions() {
      let colorBar = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: 'rgb(255 93 31)', },
        { offset: 1, color: 'rgb(255 208 153)' }
      ])
      let initData = [
        { value: 30, name: '已出租', itemStyle: { color: colorBar, }, },
        { value: 28, name: '空闲中', itemStyle: { color: 'rgb(2 125 180)' } },
      ]
      this.options = {
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}间 ({d}%)'
        },
        legend: {
          orient: 'vertical',
          right: this.util.setFontSize(70),
          top: '20%',
          width: '10%',
          height: '80%',
          itemGap: this.util.setFontSize(18, 1920),
          textStyle: { //图例文字的样式
            fontSize: this.util.setFontSize(15, 1920),
          },
          formatter: function (val) {
            let item = initData.find(v => v.name == val)
            return `${val}  ${item.value}  间`;
          },
          data: [
            '已出租',
            '空闲中',
          ]
        },
        series: [
          {
            // name: 'Area Mode',
            type: 'pie',
            startAngle: 130,
            radius: ['20%', '40%'],
            center: ['30%', '30%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 5
            },
            emphasis: {
              label: {
                show: true,
                fontSize: this.util.setFontSize(15),
                fontWeight: 'bold',
                formatter: '{d}%'
              }
            },
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: initData
          }
        ]

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
}
.echart {
  height: 100%;
  width: 100%;
}
</style>