<template>
   <!-- 登录页面 -->
  <div  class="login">
    <div class="login-pages">
      <div class="login-container">
        <h3>
          您好！
          <i class="line"></i> 欢迎来到
          <br />迎龙海悦城管理平台
        </h3>
        <el-form ref="form" :rules="rules" :model="formItem" label-width="80px">
          <el-form-item prop="username">
            <el-input v-model="formItem.username" placeholder="请输入用户名/账号">
              <i slot="prefix" class="icon icon-username"></i>
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input v-model="formItem.password" placeholder="请输入密码" show-password>
              <i slot="prefix" class="icon icon-password"></i>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-checkbox v-model="checked" style="width: 40%;">记住用户名</el-checkbox>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" :loading="loading" @click="login">登&nbsp;&nbsp;录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <a href="https://beian.miit.gov.cn/#/Integrated/index" class="recordNo" target="_blank">粤ICP备2022125816号-1</a>
  </div>
</template>

<script>
import { reqAuthCode, rqIsNeedCode } from "@/api"

export default {
    data () {
        return {
            checked: false,
            formItem: {
                username: "",
                password: "",
                code: "",
                validateCode: ""
            },
            validateCode: "", // 暂定，防止输入报错
            info: "",
            uuid: "",
            loading: false,
            rules: {
                code: [{ required: false, message: "请输入验证码", trigger: "blur" }],
                username: [
                    { required: true, message: "请输入用户名", trigger: "blur" }
                ],
                password: [{ required: true, message: "请输入密码", trigger: "blur" }]
            }
        }
    },
    created () {
        this.$emit("loading")
        const user = localStorage.setItem("user", "")
        localStorage.setItem("token", "")
        this.$store.commit("setToken", "")
        if (user) {
            this.checked = true
            this.formItem = {
                ...this.formItem,
                ...user
            }
        }
        console.log('登录页======》调用了')
        this.$store.dispatch('FedLogOut').then(() => {
            localStorage.removeItem("token")
        })
    },
    mounted () {
        document.onkeyup = (e) => {
            if (e.keyCode === 13) {
                this.login()
            }
        }
    },
    destroyed () {
        document.onkeyup = null
    },
    methods: {
        login () {
            if (!this.loading) {
                this.loading = true
                const that = this
                // login(this.formItem.username,this.formItem.password,this.formItem.singCode)
                this.$refs.form.validate((ret) => {
                    if (ret) {
                        this.$store.dispatch("Login", {
                            // ...this.formItem,
                            username: this.formItem.username,
                            password: this.formItem.password,
                            loginByPhone: 1,
                            client_id: "web",
                            client_secret: "123456",
                            grant_type: "password",
                            scope: "server",
                            validateCode: this.formItem.validateCode,
                            uuid: this.uuid,
                            loginMethod: 0
                        })
                            .then((res) => {
                                this.loading = false
                                localStorage.setItem("token", JSON.stringify(res))
                                that.$store.commit("setToken", res)
                                // that.getpersonmesg()
                                this.$router.push({ path: this.redirect || "/" })
                            })
                            .catch(() => {
                                this.loading = false
                            })
                    } else {
                        this.loading = false
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/style/mixin.scss";
// 登录页
/deep/ .el-form-item__content {
  margin: 0 !important;
  line-height: 1;
}

/deep/ .el-input__inner {
  height: $theme_height_48;
  line-height: $theme_height_48;
  border: 1px solid $theme_bordercolor_fff;
  background: $theme_background_input;
  opacity: 1;
  border-radius: 8px;
  font-size: $theme_fontsize_16;
  color: $theme_color_6f89a3;

  &:hover {
    border: 1px solid $theme_bordercolor_fff;
  }

  &:focus {
    border: 1px solid $theme_bordercolor_0083ee;
  }
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 58px;
}

/deep/ .el-input__inner::-webkit-input-placeholder {
  color: $theme_color_6f89a3 !important;
}

/deep/ .el-input__prefix,
/deep/ .el-input__suffix {
  top: 0;
  bottom: 0;
  margin: auto;
  height: auto;
  display: flex;
  align-items: center;
}

/deep/ .el-input__suffix {
  right: 20px;
}

.img-code {
  height: $theme_height_48;
  width: 100%;
  display: inline-block;
  background-color: $theme_background_fff;
  border-radius: 8px;
}

.icon-username,
.icon-password,
.icon-code {
  width: 18px;
  height: 18px;
  background: url('/images/login/username.png') center no-repeat;
  background-size: cover;
  display: inline-block;
  margin: 0 14px 0 20px;
}

.icon-password {
  background: url('/images/login/password.png') center no-repeat;
  background-size: cover;
}

.icon-code {
  background: url('/images/login/code.png') center no-repeat;
  background-size: cover;
}

/deep/ .el-checkbox {
  display: flex;
  align-items: center;
  padding-left: 6px;
}

/deep/ .el-checkbox__inner {
  border-radius: 50%;
  margin-top: 2px;
  border: 1px solid $theme_bordercolor_5a7591;
}

/deep/ .el-checkbox__label {
  font-size: $theme_fontsize_16;
  color: $theme_color_5a7591;
}

/deep/ .el-button {
  width: 100%;
  height: $theme_height_48;
  line-height: 1;
  border-radius: 8px;
  margin-top: 24px;
  font-size: $theme_fontsize_18;
  background-color: $theme_background_0083ee;
}
.login{
  position: relative;
  width: 100%;
  min-width: 1000px;
  height: 100%;
  .recordNo{
    position: absolute;
    bottom: 2%;
    transform: translateX(-50%);
    left: 50%;
    font-size: 14px;
    text-decoration: none;
    color: #999;
  }
.login-pages {
  width: 100%;
  min-width: 1000px;
  height: 100%;
  background: url("/images/login/bg.png") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .login-container {
    position: relative;
    width: 24%;
    min-width: 300px;
    margin-right: 20%;

    h3 {
      font-size: $theme_fontsize_30;
      font-weight: bold;
      padding-bottom: 20px;
      color: #6c6c6c;
      text-align: left;
      line-height: 38px;

      & .line {
        display: block;
        width: 20px;
        height: 4px;
        background-color: #22fa6d;
        margin: 18px 0 8px 0;
      }
    }
  }
}
}
</style>
