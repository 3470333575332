<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="40%"
                   title="查看租赁单元计量报表(电)"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="{}"
                     v-model="roomDetails"
                     :formArray="detailOptions"
                     :allDisabled="true"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'input', label: '设备名称', key: 'deviceName', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '设备识别码', key: 'deviceCode', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '楼栋', key: 'buildingName', labelWidth: '120px' , placeholder: ' '},
                { input_type: 'input', label: '楼层', key: 'floorName', labelWidth: '120px' , placeholder: ' '},
                { input_type: 'input', label: '租赁单元名称', key: 'roomName', labelWidth: '120px' , placeholder: ' '},
                { input_type: 'input', label: '租赁单元类型', key: 'roomTypeValue', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '驻户名称', key: 'tenantName', labelWidth: '120px' , placeholder: ' '},
                { input_type: 'input', label: '上次表底(度)', key: 'lastReading', labelWidth: '120px' , placeholder: ' '},
                { input_type: 'input', label: '本次抄表(度)', key: 'thisReading', labelWidth: '120px' , placeholder: ' '},
                { input_type: 'input', label: '本次抄表时间', key: 'updateTime', labelWidth: '120px' , placeholder: ' '},
                { input_type: 'input', label: '本期用量(度)', key: 'currentReading', hide: false, labelWidth: '120px' , placeholder: ' '},
            ],
            //表头数据
            roomDetails: {},
            show: false
        }
    },
    methods: {
        getDetailsData(row) {

            this.roomDetails = row
            this.show = true
        }
    }
}
</script>
