
<template>
  <div >
    <ja-table
      ref="tst"
      v-model="filterData"
      :columns="columns"
      :tableData="tableData"
      :conditionData="conditionData"
      :total="total"
      :border="true"
      :showSelect="!isOnePerson?true:false"
      :loading="loading"
      index_label="序号"
      :showIndex="true"
      @handleSelectItem="handleSelectItem"
      @handleSelectionAll="handleSelectionAll"
      @selectRow="selectRow"
      @getInit="getInit2"
      @search="search"
      @reset="reset"
    >
      <template #right>
        <el-button type="primary" @click="submit()" class="submit" v-if="!isOnePerson">确认添加</el-button>
      </template>
      <template #isAll>
        <el-checkbox v-model="checked" @change="selectAllPerson" v-if="isShowSelectAll">全选</el-checkbox>
      </template>
    </ja-table>
    <div
      class="botttom"
      v-if="isShowSelectText"
    >已选择：{{checked && isShowSelectAll ?(except_person_name?`所有人，除了“${except_person_name}”`:'所有人'):NowPersons2}}</div>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import selectTable from '@/views/mixins/selectTable'
import {rqTenant} from '@/api'

export default {
    title: '选择驻户',
    mixins: [table,selectTable],
    name: 'index',
    data(){
      let conditionData_own = [
          { input_type: 'input', key: 'tenantName',attribute: { placeholder: '驻户名称', size: 'small'} },
          { input_type: 'input', key: 'tenantCode',attribute: { placeholder: '编号', size: 'small'} },
          { input_type: 'select', key: 'category',attribute: { placeholder: '驻户类型', size: 'small',clearable: true} },
          { input_type: 'select', key: 'attribute',attribute: { placeholder: '驻户属性', size: 'small',clearable: true} },
          { input_type: 'input', key: 'unifiedSocialCreditCode',attribute: { placeholder: '纳税人识别号', size: 'small'} },
          { input_type: 'input', key: 'idCard',attribute: { placeholder: '身份证号', size: 'small'} },
      ]
      let columns_own = [
        { label: '驻户名称', prop: 'tenantName', width: '150px' },
        { label: '编号', prop: 'tenantCode', width: '150px' },
        { label: '驻户类型', prop: 'categoryLabel' },
        { label: '驻户属性', prop: 'attributeLabel' },
        { label: '纳税人识别号', prop: 'unifiedSocialCreditCode', width: 160 },
        { label: '身份证号', prop: 'idCard', width: 160 },
        { label: '联系方式', prop: 'contactNumber', width:130},
      ]
      return{
        rq_Method: rqTenant,
        keyName: 'tenantName',
        conditionData: [...conditionData_own, { normalBtn: 'search', }, { normalBtn: 'reset' }, { slotName: 'isAll' }],
        columns: [ ...columns_own,
            { label: '操作', input_type: 'button', width: 120,attribute: { fixed: 'right',align: 'center' },
                list: [
                  { text: '选择', method: 'selectRow' }
                ]
            }
        ],
      }
    },
    created(){
      this.util.rqOptionsList({who:this.conditionData[2],rqUrl:"/sys/dictData/tenant_category",resRule:'data'})
      this.util.rqOptionsList({who:this.conditionData[3],rqUrl:"/sys/dictData/tenant_attribute",resRule:'data'})
    },
    methods:{
    
    }
}
</script>