<template>
    <div>
        <ja-table :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  v-model="filterData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  ref="approvalList"
                  showSelect
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @handleSelectionChange="handleSelectionChange"
                  @append="issuePayment" />
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import { getBillList, editbizBillInfoNew } from '@/api'
export default {
    title: '应收账款审批-已通过',
    mixins: [table],
    name: 'passed',
    data () {
        return {
            conditionData: [
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '租赁单元名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '计费周期开始时间', endPlaceholder: '计费周期结束时间' },
                { input_type: 'date', type: 'month', value: 'billDate', placeholder: '账单日期' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '生成缴费单', icon: '', limit: 'collectFees::approvalList::issuePayment' }
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', width: 200 },
                { label: '租赁单元名称', prop: 'roomName', width: 200 },
                { label: '月固定租金', prop: 'rent', width: 200 },
                { label: '物业管理费', prop: 'propertyManagementFee', width: 200 },
                { label: '电费', prop: 'electricity' },
                { label: '设备维护费服务费（电）', prop: 'iptElectricity', width: 200 },
                { label: '水费', prop: 'water', width: 200 },
                { label: '设备维护费服务费（水）', prop: 'iotWater', width: 200 },
                { label: '计费周期', prop: 'date', width: 200 },
                { label: '账单时间', prop: 'billDate', width: 200 },
                { label: '预计合计', prop: 'amountReceivable', width: 200 },
                { label: '状态', prop: 'approvalStatusName', width: 200 }
            ],
            tableData: [],
            total: 0,
            selectRows: []
        }
    },
    methods: {
        getInit () {
            this.loading = true
            getBillList(this.rqParmas()).then(res => {
                res.data.data.map(item => {
                    item.date = `${item.billDateStart}~${item.billDateEnd}`
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        issuePayment () {
            if (this.selectRows.length <= 0) return this.$message.error('请先勾选账单')
            this.$confirm('是否确定生成缴费单？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let isOk = true
                const ids = []
                this.selectRows.map(item => {
                    if (item.approvalStatus === '1' || item.approvalStatus === '4') {
                        ids.push(item.id)
                    } else if (item.approvalStatus === '0') {
                        isOk = false
                    }
                })
                if (!isOk) {
                    return this.$message.warning('所勾选数据包含未审核的账单，无法生成缴费单')
                }
                if (ids.length <= 0) {
                    return this.$message.warning('所选数据全部已生成缴费单')
                }
                await editbizBillInfoNew({ ids: ids.join(), approvalStatus: 3 })
                this.$message.success('生成缴费单成功')
                this.selectRows = []
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                })
            })
        },
        handleSelectionChange (val) {
            this.selectRows = val
        },
        rqParmas () {
            const obj = { ...this.filterData, status: 3, approvalStatus: "1" }
            if (obj.date) {
                obj.billDateStart = obj.date[0]
                obj.billDateEnd = obj.date[1]
                delete obj.date
            }
            return obj
        }
    }
}
</script>
