// 首页表单查询
export const conditionData = [
    { input_type: 'input', key: 'contractCode', attribute: { placeholder: '合同编号', size: 'small' } },
    { input_type: 'input', key: 'contractName', attribute: { placeholder: '合同名称', size: 'small' } },
    { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
    { input_type: 'select', key: 'contractType', attribute: { placeholder: '合同类型', size: 'small' }, options: [] },
    { input_type: 'date', value: 'date', type: 'monthrange' },
    { normalBtn: 'search' },
    { normalBtn: 'reset' },
    { normalBtn: 'add', text: '新增合同', limit: 'contract::manage::add' }
]
// 首页表单
export const columns = [
    { label: '合同编号', prop: 'contractCode' },
    { label: '合同名称', prop: 'contractName' },
    { label: '合同类型', prop: 'contractTypeName' },
    { label: '租期', prop: 'leaseTerm' },
    { label: '计租开始日期', prop: 'billingTime' },
    { label: '驻户名称', prop: 'tenantName' },
    { label: '驻户租赁单元', prop: 'roomInfo' },
    { label: '状态', prop: 'status' },
    {
        label: '操作',
        input_type: 'button',
        width: 300,
        attribute: { fixed: "right", align: 'center' },
        list: [
            { text: '查看', method: 'rowDetail', limit: 'contract::manage::rowDetail' },
            { text: '提交', method: 'rowSubmit', limit: 'contract::manage::submit' },
            { text: '编辑', method: 'rowEdit', limit: 'contract::manage::edit' },
            { text: '删除', method: 'rowDelete', limit: 'contract::manage::delete' },
            { text: '设置保证金', method: 'setBond', limit: '' }

        ]
    }

]
