<template>
    <el-dialog :visible.sync="show" width="50%" title="查看">
        <ja-form :rules="rules" allDisabled v-model="rowForm" :formArray="rowFormData" v-if="show" :editDataFormat="editDataFormat">
        </ja-form>
    </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
    components: {
        JaForm,
    },
    data () {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'input', label: '楼栋', key: 'buildingName', labelWidth: "10em" },
                { input_type: 'input', label: '楼层', key: 'floorName', labelWidth: "10em" },
                { input_type: 'input', label: '驻户名称', key: 'tenantName', labelWidth: "10em" },
                { input_type: 'input', label: '租赁单元名称', key: 'roomName', labelWidth: "10em" },
                { input_type: 'input', label: '租赁单元类型', key: 'roomTypeValue', labelWidth: "10em" },
                { input_type: 'input', label: '租赁状态', key: 'statusValue', labelWidth: "10em" },
                { input_type: 'input', label: '合同签订日期', key: 'startTime', labelWidth: "10em" },
                { input_type: 'input', label: '租期', key: 'tenancyTerm', labelWidth: "10em" },
                { input_type: 'input', label: '租赁面积（㎡）', key: 'rentalArea', labelWidth: "10em" },
            ],
            show: false,
            rowForm: {},
        }
    },
    methods: {
        init (row) {
            this.show = true
            this.rowForm = { ...row }
        }
    }
}
</script>