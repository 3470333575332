export default {
    loadingFormObj: {
        text: 'loading',
        icon: 'el-icon-loading',
        bg: 'rgba(255,255,255,0.5)',
        formLoading: 'loading_form'
    },

    // 图片与视频格式
    videoType: ".avi,.wmv,.mpg,.mpeg,.mov,.rm,.ram,.swf,.flv,.mp4,.wma,.rm,.rmvb,.flv,.mpg,.mkv",
    imgType: ".jpeg,.png,.jpg,"
}
