<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowDelete="rowDelete" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent">
      <template #content="{row}">
        <div class="table_content">
          {{row.contentText }}
        </div>
      </template>
    </ja-table>

    <!-- 新增/编辑 -->
    <add-edit ref="addEdit" />
  </div>
</template>
<script>
import { rqNews, delNews } from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit.vue'

export default {
  title: '新闻资讯',
  mixins: [table,],
  name: 'index',
  components: {
    AddEdit
  },
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
        { input_type: 'select', key: 'tag', attribute: { placeholder: '咨询类型', size: 'small' }, options: [], }, // 涉及到 异步的，options 要有
        { input_type: 'input', key: 'title', attribute: { placeholder: '标题', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add' },
      ],
      columns: [
        { label: '咨询类型', prop: 'tagName' },
        { label: '标题', prop: 'title' },
        { label: '正文', prop: 'contentText' },
        { label: '作者', prop: 'author' },
        { label: '发布机构', prop: 'publishDeptName' },
        { label: '发布时间', prop: 'publishTime', width: 140 },
        { label: '状态', prop: 'statusValue' },
        // { label: '置顶', prop: 'topValue' },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '编辑', method: 'rowEdit' },
            { text: '删除', method: 'rowDelete' },
          ]
        }
      ],
      con_indexObj: {},
    }
  },
  mounted() {
    this.con_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    let rowFormData = this.$refs.addEdit.rowFormData
    this.util.rqOptionsList({ who: [this.conditionData[this.con_indexObj.tag], rowFormData[this.$refs.addEdit.indexObj.tag]], rqUrl: "/sys/dictData/news_tag", rqMethod: 'get', resRule: 'data' })
  },
  methods: {
    rqParmas() {
      let obj = { ...this.filterData }
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = true
      let data = this.rqParmas()
      rqNews(data).then(res => {
        // console.log(res)
        this.loading = false
        // contentText 是 将富文本转文本
        this.tableData = (res.data.data || []).map(item => {
          let content = item.content
          item.contentText = content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ')
          return item
        })
        this.total = res.data.total
      }).catch(err => {
        this.loading = false
      })
    },

    // 删除
    rowDelete(row) {
      this.$confirm('您确定删除该资讯？', '确认删除该资讯？').then((res) => {
        delNews(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
  }
}
</script>
