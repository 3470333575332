<template>
    <!-- 设备列表 -->
    <!-- rowDetail: 详情操作->详情面板 -->
    <!-- append: 新增操作->新增面板 -->
    <!-- rowEdit: 编辑操作->编辑面板 -->
    <!-- rowDelete: 删除操作->删除面板 -->
    <!-- getInit: 获取ajax数据 -->
    <!-- outPut_: 导出数据 -->
    <div class="allwh minWidth1000">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="append" :showIndex="true" @rowEdit="rowEdit" @rowDelete="rowDelete" @getInit="getInit" :valueFormat="formatter" :editDataFormat="editDataFormat" @search="search" @reset="reset" @showUser="showUser" @disabled="disabled" @enable="enable">
        </ja-table>
        <!-- 新增/编辑 -->
        <!-- reset: 取消 -->
        <!-- saveForm: 保存操作 -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title="titleText" :element-loading-text="isAdd?'新增中':'编辑中'">
            <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :allDisabled="allDisabled" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll" v-loading="loading_form.isShow">
            </ja-form>
        </el-dialog>
        <!-- 分配通行权限 -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" title="通行权限" :visible.sync="openDataScope" width="600px" append-to-body :v-loading="loading_form.isShow">
            <ja-scope class="box" :formArray="rowFormData2" :rowForm2="rowForm2" :checkedKeys="rowForm2.deviceIds" @submitDataScope="submitDataScope" @reset2="openDataScope=false">
            </ja-scope>
        </el-dialog>
    </div>
</template>

<script>
import {
    getCompanyList,
    addCompanyInfo,
    updateCompanyInfo,
    deleteCompanyInfo,
    companyGrant,
    disabledEnterprise,
    enableEnterprise
} from '@/api'
import table from "@/views/mixins/table"
export default {
    title: '企业列表',
    mixins: [table],
    name: 'index',
    data () {
        return {
            filterData: { // 默认页码
                pageIndex: 1,
                pageSize: 10
            },
            conditionData: [ // 表格上方的搜索/选择操作栏
                {
                    input_type: 'input',
                    key: 'companyName',
                    attribute: {
                        placeholder: '公司名称',
                        size: 'small'
                    }
                },
                {
                    input_type: 'select',
                    key: 'companyStatus',
                    attribute: {
                        placeholder: '全部状态',
                        size: 'small'
                    },
                    options: [
                        { label: '启用', value: 0 },
                        { label: '禁用', value: 1 }
                    ]
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'companiesList::index::add' }
                // { normalBtn: 'download' }

            ],
            columns: [ // 表头数据
                { label: '公司名称', prop: 'companyName', attribute: { showOverflowTooltip: true, align: 'center' } },
                { label: '公司地址', prop: 'address', attribute: { showOverflowTooltip: true, align: 'center' } },
                { label: '负责人', prop: 'linkMan', },
                { label: '联系电话', prop: 'phone' },
                { label: '状态', prop: 'companyStatus' },
                {
                    label: '操作', input_type: 'button', width: 200,
                    attribute: {
                        fixed: "right",
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'showUser', limit: 'companiesList::index::showUser' },
                        { text: '禁用', method: 'disabled', limit: 'companiesList::index::disabled' },
                        { text: '启用', method: 'enable', limit: 'companiesList::index::enable' },
                        { text: '编辑', method: 'rowEdit', limit: 'companiesList::index::rowEdit' },
                        { text: '删除', method: 'rowDelete', limit: 'companiesList::index::rowDelete' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 0,
            // 新增/编辑
            titleText: '编辑',
            rowFormData: [
                { input_type: 'input', label: '公司名称', key: 'companyName', maxlength: 30 },
                { input_type: 'input', label: '公司地址', key: 'address', maxlength: 30 },
                { input_type: 'input', label: '负责人姓名', key: 'linkMan', maxlength: 30 },
                { input_type: 'input', label: '联系电话', key: 'phone', maxlength: 30 },
                { input_type: 'radio', label: '状态', key: 'companyStatus', options: [{ "value": 0, "label": "正常" }, { "value": 1, "label": "禁用" }] },
                { input_type: 'input', label: '状态', key: 'statusText', hide: true },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', col: 22, maxlength: 200 }
            ],
            rules: {
                companyName: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText({textMessage:'公司名称'}) }],
                address: [{ required: false, trigger: 'blur' }],
                linkMan: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkText() }],
                phone: [{ required: false, trigger: 'change', validator: this.util.formRules.checkPhone() }]
            },
            rowForm: {},
            showEditAll: false, // 显示新增/编辑弹窗
            labelWidth: `${this.util.setFontSize(140)}px`,
            // 通行权限
            rowFormData2: [
                {
                    input_type: 'select',
                    label: '所属楼栋',
                    key: 'building_id',
                    options: []
                },
                {
                    input_type: 'select',
                    label: '所属楼层',
                    key: 'floor_id',
                    options: []
                }
            ],
            rowForm2: {},
            openDataScope: false,// 是否显示弹出层（数据权限）
            allDisabled: false,
        }
    },
    methods: {
        formatter: function (val, key) {
            if (key === 'companyStatus') {
                return ['启用', '禁用'][val || 0]
            }
            return val || ''
        },
        getInit: function () {
            this.loading = true
            getCompanyList(this.filterData).then(({ data }) => {
                this.tableData = data.data
                this.total = data.total
            })
            this.loading = false

        },
        rowDelete: function (row) {
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteCompanyInfo(row.id).then(res => {
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '操作取消'
                });
            });
        },
        submitDataScope: function (row, checkedKeys) {
            /** 提交按钮（通行权限） */
            // console.log(row, checkedKeys)
            const param = {}
            param.companyId = row.id
            param.deviceIds = checkedKeys
            companyGrant(param).then(res => {
                this.$message.success(res.msg)
                this.openDataScope = false
            })
        },
        saveForm: function () { // 保存
            this.loading_form.isShow = true
            const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
            if (this.isAdd) {
                // 添加公司信息
                addCompanyInfo(paramObj).then(res => {
                    this.loading_form.isShow = false
                    this.$message.success('新增成功')
                    this.showEditAll = false
                    this.getInit()
                }).catch(() => {
                    this.loading_form.isShow = false
                })
            } else {
                // 更新公司信息
                updateCompanyInfo(paramObj).then(res => {
                    this.loading_form.isShow = false
                    this.$message.success('修改成功')
                    this.showEditAll = false
                    this.getInit()
                }).catch(() => {
                    this.loading_form.isShow = false
                })
            }
        },
        // 新增
        append () {
            this.isAdd = true
            this.titleText = '新增'
            this.rowForm = {
                companyStatus: 0
            }
            this.showEditAll = true
            this.allDisabled = false
            this.rowFormData[5].hide = true
            this.rowFormData[4].hide = false
        },
        // 编辑
        rowEdit (row) {
            this.allDisabled = false
            this.isAdd = false
            this.titleText = '编辑'
            this.rowFormData[5].hide = true
            this.rowFormData[4].hide = false
            this.rowForm = {
                ...row
            }
            this.showEditAll = true
        },
        editDataFormat (row, btn) {
            if (row.companyStatus == 0 && btn.text == '启用' || row.companyStatus == 1 && btn.text == '禁用') {
                return false
            } else {
                return true
            }
        },
        showUser (row) {
            this.allDisabled = true
            this.isAdd = false
            this.titleText = '查看企业信息'
            this.rowFormData[4].hide = true
            this.rowFormData[5].hide = false
            this.rowForm = {
                statusText: ['启用', '禁用'][row.companyStatus],
                ...row
            }
            this.showEditAll = true
        },
        //禁用
        disabled (row) {
            this.$confirm('禁用后该企业下的所有部门人员将限制登录，是否确定禁用？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let id = row.id
                disabledEnterprise(id).then(res => {
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '禁用成功'
                    });

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '操作取消'
                });
            });
        },
        //启用
        enable (row) {
            this.$confirm('是否确定启用？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let id = row.id
                enableEnterprise(id).then(res => {
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '启用成功'
                    });
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '操作取消'
                });
            });
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
