<template>
    <div>
        <ja-table :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  v-model="filterData"
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @showDetails="showDetails" />

        <details-list ref="detailsListRefs"></details-list>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import { getArrearageBillList } from '@/api'
import DetailsList from './components/detailsList.vue'
export default {
    title: '欠费账单',
    mixins: [table],
    components: {
        DetailsList
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'input', key: 'roomName', attribute: { placeholder: '租赁单元名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '计费周期开始时间', endPlaceholder: '计费周期结束时间', valueFormat: 'yyyy-MM-dd' },
                { input_type: 'date', type: 'month', value: 'billTime', placeholder: '账单时间', valueFormat: 'yyyy-MM' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/bizBillArrearsNew/export', rqMethod: 'post' }, limit: 'contractFile::arrearageBill::export' }
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', width: 200 },
                { label: '租赁单元名称', prop: 'roomName', width: 200 },
                { label: '计费周期', prop: 'date', width: 200 },
                { label: '账单时间', prop: 'billTime', width: 200 },
                { label: '本期合计应收（元）', prop: 'receivable', width: 200 },
                { label: '本期合计实收（元）', prop: 'received', width: 200 },
                { label: '欠费金额（元）', prop: 'arrears', width: 200 },
                { label: '最后缴费日期', prop: 'lastPayTime', width: 200 },
                { label: '欠费天数', prop: 'arrearsDays', width: 200 },
                { label: '状态', prop: 'arrearsStatusName', width: 200 },
                {
                    label: '操作', input_type: 'button', width: 200, attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '查看', method: 'showDetails', limit: 'contractFile::arrearageBill::showDetails' },

                    ]
                }
            ],
            tableData: [],
            total: 0,

        }
    },
    methods: {
        getInit() {
            this.loading = true
            getArrearageBillList(this.rqParmas()).then(res => {
                res.data.data.map(item => {
                    item.date = `${item.startTime}~${item.endTime}`
                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        showDetails(row) {
            this.$refs.detailsListRefs.init(row)
        },
        rqParmas() {
            let obj = { ...this.filterData }
            if (obj.date) {
                obj.startTime = obj.date[0]
                obj.endTime = obj.date[1]
                delete obj.date
            }
            return obj
        }
    }
}
</script>

<style scoped>
</style>
