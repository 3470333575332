/** 字典数据页面表头栏 */
export const dicDataColumn = [
  { label: '楼栋', prop: 'buildingName' },
  { label: '楼层', prop: 'floorName' },
  { label: '图片文件名称', prop: 'imgName' },
  { label: '备注', prop: 'remark', width: 400 },
  {
    label: '操作',
    input_type: 'button',
    width: 180,
    list: [
      { text: '查看', method: 'seeDetails', limit: 'control::information::details' },
      { text: '编辑', method: 'rowEdit', limit: 'control::information::update' },
      { text: '移除', method: 'rowDelete', limit: 'control::information::delete' }
    ]
  }
]

/** 字典数据页面新增/编辑校验规则 */
export const dicDataRules = {
  imgName: [
    { required: true, message: '请输入图片名称', trigger: 'blur' },
    {
      validator: function (rule, value, callback) {
        if (/^[\a-\z\A-\Z0-9\u4e00-\u9fe5]+$/.test(value) == false) {
          callback(new Error('请输入中文、数字和英文'))
        } else {
          //校验通过
          callback()
        }
      },
      trigger: 'blur'
    }
  ],
  buildingId: [
    { required: true, message: '请选择楼栋', trigger: 'change' },
    // {validator:validate, trigger: 'blur'}
  ],
  floorId: [
    { required: true, message: '请选择楼层', trigger: 'change' }
  ],
  salesControlUrl: [
    { required: true, message: '请上传销控图', trigger: 'blur' }
  ]
}
