<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="50%"
                >
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowForm"
                     :labelWidth="labelWidth"
                     :formArray="rowFormData"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show"
                     v-loading="addLoading">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import cstMin from "../../cstMixin"
import { addProject, editProject } from '@/api'
export default {
    inject: ['getInit'],
    mixins: [cstMin],
    data() {
        return {
            value: null,
            labelWidth: `${this.util.setFontSize(140)}px`,
            rowFormData: [
                { input_type: 'input', key: 'projectName', label: '项目名称', labelWidth: '130px', col: 22, maxlength: 30 },
                { input_type: 'select', key: 'contactsId', label: '联系人', labelWidth: '130px', col: 11, options: [] },
                { input_type: 'input', key: 'clientDepartment', label: '部门名称', labelWidth: '130px', col: 11, disabled: true, placeholder: '-' },
                { input_type: 'input', key: 'customerContact', label: '电话号码', labelWidth: '130px', col: 11, disabled: true, placeholder: '-' },
                { input_type: 'input', key: 'clientCompany', label: '公司名称', labelWidth: '130px', col: 11, disabled: true, placeholder: '-' },
                { input_type: 'select', key: 'leasePurpose', label: '租赁用途', labelWidth: '130px', col: 11, options: [] },
                { input_type: 'input', key: 'demandArea', label: '面积需求（㎡）', labelWidth: '130px', col: 11 },
                { input_type: 'select', key: 'importanceLevel', label: '重要级别', labelWidth: '130px', options: [{value:1,label:'重要紧急'},
                    {value:2,label:'重要不紧急'},
                    {value:3,label:'紧急不重要'},
                    {value:4,label:'不重要不紧急'}] },
                {col: 11, },
                { input_type: "date", label: '开始时间',labelWidth: '130px', key: 'startDate', col: 11, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
                { input_type: "date", label: '结束时间',labelWidth: '130px', key: 'endDate', col: 11, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
                { input_type: 'input', type:'textarea',key: 'remark', label: '备注', labelWidth: '130px', col: 22 },
            ],
            rules: {
                // projectName: [
                //     { required: true, message: '请输入项目名称', trigger: 'blur' }
                // ],
                leasePurpose: [
                    { required: true, message: '请选择租赁用途', trigger: 'blur' }
                ],
                contactsId: [
                    { required: true, message: '请选择联系人', trigger: 'blur' }
                ],
                importanceLevel: [
                    { required: true, message: '请选择重要级别', trigger: 'change' }
                ],
                // demandArea: [
                //     { required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }
                // ],
                unitPrice: [
                    { required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2, min: 0 }) }
                ],
                times: [
                    { required: true, message: '请选择计费区间', trigger: 'blur', }
                ],
                startDate: [{ required: false, trigger: 'change', validator: this.validatorStartTime }],
                endDate: [{ required: false, trigger: 'change', validator: this.validatorEndTime }],
            },
            title: '新增项目信息',
            show: false,
            isAdd: false,
            addLoading: false,
            rowForm: {
                
            },
            contacts: [],

        }
    },
    async mounted() {
        await this.getSelect()
    },
    methods: {
        async init(state, row) {
            this.show = true
            this.rowForm = {}
            this.$set(this.rowForm,'startDate',this.$moment().format("YYYY-MM-DD"))
            let index = this.rowFormData.findIndex(e => e.key === 'contactsId')
            if (state == '1') {
                this.isAdd = true
                this.rowFormData[index].disabled=false
            } else if (state == '2') {
                this.isAdd = false
                this.title = '编辑项目信息'
                this.rowFormData[index].disabled=true
                row.contactsId = Number(row.contactsId)
                row.followUpPeopleDeptId = Number(row.followUpPeopleDeptId)
                row.followUpPeopleId = Number(row.followUpPeopleId)
                this.rowForm = { ...row }

            }
        },
        saveForm() {
            if (this.isAdd) {
                addProject(this.rowForm).then(() => {
                    this.show = false
                    this.getInit()
                    this.$message.success('新增成功')
                })
            } else {
                this.rowForm.updateBy = ''
                this.rowForm.updateTime = ''
                this.rowForm.createBy = ''
                this.rowForm.createTime = ''
                console.log(this.rowForm);
                editProject(this.rowForm).then(() => {
                    this.show = false
                    this.getInit()
                    this.$message.success('编辑成功')
                })
            }
        },
        // 开始时间禁用
        disabledDateStart(val) {
            let now = (new Date()).getTime()
            let time = val.getTime()
            let oneDay = 24 * 3600 * 1000
            return time < now - oneDay
            },
        validatorEndTime(rule, value, callback) {
            if (rule.required && (value === '' || !value)) {
                callback(new Error('请完善计划结束时间'));
            } else {
                let startDate = new Date(this.rowForm.startDate).getTime()
                let time = new Date(value).getTime()
                if (value && (time < startDate)) {
                callback(new Error('结束时间不能小于开始时间'))
                } else {
                callback()
                }
            }
        },
    
    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>