<template>
  <!--  普通的表单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="(isAdd?'新增':'编辑')+' '">
    <ja-form ref="jaForm" v-model="rowForm"
             :rules="rules"
             :formArray="rowFormData"
             :loadingForm="loadingForm"
             :labelWidth="labelWidth"
             @reset="showEditAll = false"
             @addLine="addLine"
             @save="saveForm"
             v-if="showEditAll">
        <!-- 点位信息 -->
        <template #pointData><point-form :rowFormData="pointRowFormData" :rowForm="otherForm"  @rowAdd_formTable="rowAdd_formTable"  @rowDelete_formTable="rowDelete_formTable" /></template>
    </ja-form>
  </el-dialog>
</template>
<script>
import { addPath, updatePath } from '@/api'
import PointForm from "./pointForm.vue"

export default {
    components: { PointForm },
    inject: ['labelWidth', 'getInit'],
    data () {
        return {
            rowFormData: [
                { input_type: 'input', label: '名称', key: 'pathName', col: 11 },
                { input_type: 'select', key: 'type', label: '类型', options: [{ label: '工程巡检', value: '0' }, { label: '物业巡检', value: '1' }, { label: '运营巡检', value: '2' }] },
                { input_type: 'title', text: '点位信息', col: 24, rightBox: { input_type: 'button', text: '加一行', type: 'primary', method: 'addLine' } },
                { input_type: 'customize', customize: 'pointData', col: 24, noMarginLeft: true, noMarginBottom: true },
                { input_type: 'number', label: '最少拍照数', key: 'imgMinLimit', max: 100, min: 1, col: 22 }
            ],
            otherForm: { tableList: [] },
            pointRowFormData: [
                {
                    input_type: 'table',
                    label: '',
                    key: 'tableList',
                    col: 24,
                    styleWidth: '100%',
                    showPage: false,
                    noSearch: true,
                    table_MaxHeight: 300,
                    border: true,
                    loading: false,
                    noMarginLeft: true,
                    noMarginBottom: true,
                    tableSlot: 'table',
                    allDisabled: false,
                    columns: [
                        { label: '点位名称', prop: 'pointName', column_type: 'input', width: '200' },
                        { label: '巡查内容', prop: 'remark', column_type: 'input' },
                        {
                            label: '操作',
                            input_type: 'button',
                            width: 100,
                            list: [
                                { text: '增加', method: 'rowAdd_formTable', key: 'tableList' },
                                { text: '删除', method: 'rowDelete_formTable', key: 'tableList' }
                            ]
                        }
                    ]
                }
            ],
            rowForm: {},
            rules: {
                pathName: [
                    { required: true, message: '请完善巡查路线名称', trigger: 'blur' }
                ],
                imgMinLimit: [
                    { required: true, message: '请完善最少拍照数', trigger: 'change' }
                ]
            },
            loadingForm: false,
            showEditAll: false, // 显示新增/编辑弹窗,
            isAdd: false
        }
    },
    methods: {
        async append () {
            console.log('add ')
            this.isAdd = true
            this.rowForm = {}
            this.showEditAll = true
        },
        rowEdit (row) {
            this.isAdd = false
            this.titleText = '编辑'
            this.rowForm = { ...row }
            this.otherForm.tableList = row.pointData
            this.showEditAll = true
        },

        saveForm () {
            // console.log('要提交的表单', this.rowForm)
            this.loadingForm = true
            const rqMethod = this.isAdd ? addPath : updatePath
            const params = { ...this.rowForm, pointData: this.otherForm.tableList }
            rqMethod(params)
                .then(() => {
                    this.loadingForm = false
                    this.$message.success('提交成功')
                    this.showEditAll = false
                    this.getInit()
                })
                .catch(() => {
                    this.loadingForm = false
                })
        },
        // 编辑/新增弹窗 表格里 的 新增
        rowAdd_formTable (row, info, button) {
            this.otherForm.tableList.splice(info.$index + 1, 0, { ...this.optionsItem })
        },
        // 编辑/新增弹窗 表格里 的 "删除"
        rowDelete_formTable (row, info, button) {
            this.otherForm.tableList.splice(info.$index, 1)
        },
        // 点击顶部的加一行
        addLine () {
            console.info(this.otherForm)
            this.otherForm.tableList.push({})
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
