<template>
  <router-view/>
</template>

<script>
export default {
  title: "收费管理",
  name: 'collectFees'
}
</script>

<style scoped>

</style>
