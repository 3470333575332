<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="45%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowForm"
                     labelWidth="150px"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     @selectCharges="selectCharges"
                     @selectType="selectType"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { editChargingSettings } from '@/api'
export default {
    inject: ['labelWidth', 'getInit'],
    data() {
        return {
            value: null,
            rules: {
                feesName: [{ required: true, trigger: 'change', message: '收费标准名称不能为空' }],
                unitPrice: [{ required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ min: 1, fixedNum: 2 }) }],
            },
            rowFormData: [
                { input_type: 'input', key: 'serviceCode', label: '收费项目编号', col: 11, disabled: true },
                { input_type: 'input', key: 'serviceType', label: '收费项目类型', col: 11, disabled: true },
                { input_type: 'input', key: 'natureCharges', label: '收费项目性质', col: 11, disabled: true },
                { input_type: 'input', key: 'dayName', label: '缴费周期', col: 11, disabled: true },
                { input_type: 'input', key: 'serviceName', label: '收费项目名称', col: 11, disabled: true },
                { input_type: 'input', key: 'feesName', label: '收费标准名称', col: 11 },
                { input_type: 'input', key: 'fees', label: '收费标准', col: 11, disabled: true },
                { input_type: 'input', key: 'unitPrice', label: '单价（元）', col: 11 },
                { input_type: 'input', key: 'updateTime', label: '变更时间', col: 11, hide: true, disabled: true },
                { input_type: 'input', key: 'updateBy', label: '审批人', col: 11, hide: true, disabled: true },

            ],
            title: '查看收费项使用记录',
            show: false,
            allDisabled: false,
            rowForm: {},
            lengthA: null
        }
    },
    mounted() {
        let length1 = this.rowFormData.findIndex(e => e.key === 'serviceType')
        let length2 = this.rowFormData.findIndex(e => e.key === 'natureCharges')
        this.lengthA = length2
        this.util.rqOptionsList({
            who: this.rowFormData[length1],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'chargeitem_type' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
        this.util.rqOptionsList({
            who: this.rowFormData[length2],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'nature_charges' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        init(status = 1, row) {
            this.rowForm = {}
            this.title = '新增收费标准'
            this.show = true
            this.allDisabled = false
            this.rowForm = { ...row }
            this.$set(this.rowFormData[8], 'hide', true)
            this.$set(this.rowFormData[9], 'hide', true)
            if (status == 3) {
                if (row.isEffective == 1) {
                    this.$set(this.rowFormData[8], 'hide', false)
                    this.$set(this.rowFormData[9], 'hide', false)
                }
                this.isAdd = false
                this.title = '查看收费标准'
                this.allDisabled = true
            }

        },
        saveForm() {
            let obj = {
                ...this.rowForm,
                isEffective: 1
            }
            editChargingSettings(obj).then(res => {
                this.show = false
                this.getInit()
            })
        },

    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>