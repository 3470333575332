<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="申请详情" :width="`45%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='10' v-if="showDetail">
        <template #something>
          <ul class="something">
            <li v-for="(item,index) in detailData.ptds" :key="index">
              <span>{{item.goodsName}}</span> <span>{{item.goodsNumber}}</span>
            </li>
          </ul>
        </template>
        <template #uplpadfaceImg>
          <upload-file ref="Upload" :initFileList="[...initFileList]" v-if="showDetail" :noButton="true" :hideDel="true" class="imgs"/>
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      showDetail: false,
      detailOptions: [
        { key: 'a', label: '申请信息', isTitle: true, col: 22 },
        { key: 'applyCode', label: '申请编号', },
        { key: 'applyCompanyName', label: '申请单位', },
        { key: 'applyEmployeePhone', label: '联系方式', },
        { key: 'typeName', label: '放行类型', },
        { key: 'transitTime', label: '出门时间', },
        { key: 'applyDescription', label: '申请说明', col: 22 },
        { key: 'createTime', label: '提交时间', },

        { key: 'a', label: '物品详情', isTitle: true, col: 22 },
        { type: 'customize', customize: 'something', col: 22 },
        { type: 'customize', customize: 'uplpadfaceImg', col: 22 },

        { key: 'a', label: '审核信息', isTitle: true, col: 22 },
        { key: 'statusName', label: '审批状态', },
        { key: 'auditEmployeeName	', label: '审批人', },
        { key: 'auditTime', label: '审批时间', col: 22 },
        { key: 'rejectReason', label: '驳回理由', },
      ],
      detailData: {},
      initFileList: []
    }
  },
  methods: {
    rowDetail(row) {
      // console.log('查看详情---',row,)
      this.initFileList = row.imgUrls ? this.util.backImgVideoObj(row.imgUrls):[]
      this.detailData = { ...row }
      this.showDetail = true
    },
  }
}
</script>
<style lang="scss" scoped>
.something {
  padding-left: 60px;
  > li {
    display: flex;
    width: 70%;
    justify-content: space-between;
  }
}
.imgs{
  padding-left: 60px;
}
</style>