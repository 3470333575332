import Vue from 'vue'
import Vuex from 'vuex'
import permission from './modules/permission'
import user from './modules/user'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: true,
        token: ''
    },
    mutations: {
        setLoading (state, val) {
            state.loading = val
        },
        setToken (state, val) {
            state.token = val
        }
    },
    getters,
    modules: {
        permission,
        user
    }
})
