<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="45%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowFormData"
                     :formArray="rowForm"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show">

            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            value: null,
            rules: {},
            rowForm: [
                { input_type: 'input', key: 'projectName', label: '项目名称', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'leasePurposeName', label: '租赁用途', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'contacts', label: '联系人', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'customerContact', label: '客户联系方式', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'clientCompany', label: '客户公司', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'demandArea', label: '面积需求（㎡）', labelWidth: '130px', col: 11, placeholder: ' ' },
               { input_type: 'select', key: 'followUpPeopleDeptName', styleWidth: '6vw', label: '跟进人', labelWidth: '130px', col: 8, options: [], placeholder: '部门', },
                { input_type: 'select', key: 'followUpPeopleName', styleWidth: '6vw', col: 3, options: [], placeholder: '人员' },
                { input_type: 'input', key: 'followUpPeopleContact', label: '跟进人联系方式', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'updateTime', label: '操作时间', labelWidth: '130px', col: 11, placeholder: ' ' },

            ],
            title: '查看项目信息',
            show: false,
            isAdd: false,
            allDisabled: false,
            hideList: ['place1', 'place2', 'place3']
        }
    },

    methods: {
        init(row) {
            this.show = true
            this.allDisabled = true
            this.rowFormData = { ...row }
        },

    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>