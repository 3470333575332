<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @edit="edit"
                  @showResident="showResident"
                  @deleteResident="deleteResident"
                  @addAccount="addAccount"
                  @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/驻户导入模板.xlsx', filename: '驻户导入模板', uploadURL: '/tenant/upload'})"> </ja-table>
        <add-edit ref="addEdit"></add-edit>
        <import-form ref='importForm'
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
        <add-account ref="addAccount"></add-account>
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import importForm from '../common/importForm.vue'
import AddEdit from './components/AddEdit.vue'
import addAccount from './components/addAccount.vue'
import { rqTenant, delTenant } from '@/api'
import checkResult from '../common/checkResult'
export default {
    mixins: [table],
    components: {
        importForm,
        AddEdit,
        checkResult,
        addAccount
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'search', attribute: { placeholder: '驻户名称/法人姓名', size: 'small', }, styleWidth: '200px' },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '新增', limit: 'occupantInfo::index::add' },
                { normalBtn: 'import', text: '导入', limit: 'occupantInfo::index::import' }
            ],
            columns: [
                { label: '驻户名称', prop: 'tenantName', },
                { label: '企业类型', prop: 'categoryLabel', },
                { label: '营业执照号码', prop: 'licenseNum', },
                { label: '法人姓名', prop: 'linkMan', },
                { label: '联系电话', prop: 'contactNumber', },
                { label: '负责人身份证号', prop: 'idCard', },
                { label: '描述', prop: 'remark', },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: 200,
                    list: [
                        { text: '查看', method: 'showResident', limit: 'occupantInfo::index::check' },
                        { text: '编辑', method: 'edit', limit: 'occupantInfo::index::edit' },
                        { text: '删除', method: 'deleteResident', limit: 'occupantInfo::index::delete' },
                        { text: '开设账号', method: 'addAccount', limit: 'occupantInfo::index::addAccount' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            results: null,
        }
    },
    methods: {
        getInit() {
            rqTenant(this.filterData).then(res => {
                this.tableData = res.data.data
                this.loading = false
                this.total = res.data.total
            })
        },
        append() {
            this.$refs.addEdit.init(1);
        },
        edit(row) {
            this.$refs.addEdit.init(2, row);
        },
        showResident(row) {
            this.$refs.addEdit.init(3, row);
        },
        addAccount(row) {
            this.$refs.addAccount.init(row);
        },
        deleteResident(row) {
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                delTenant(row.id).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.loading = false
                    this.getInit()
                }).catch(res => {
                    this.$message.error('删除失败,请稍后再试')
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        }

    }
}
</script>