<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="45%"
                  >
            <ja-form :rules="rules"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show"
                     v-loading="addLoading">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { editCustomer, addCustomer } from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            value: null,
            rowFormData: [
                { input_type: 'input', key: 'contacts', label: '联系人', labelWidth: '130px', col: 11, maxlength: 30 },
                { input_type: 'input', key: 'customerContact', label: '电话号码', labelWidth: '130px', col: 11 },
                { input_type: 'input', key: 'clientCompany', label: '公司名称', labelWidth: '130px', col: 11, maxlength: 30 },
                { input_type: 'input', key: 'clientDepartment', label: '部门名称', labelWidth: '130px', col: 11, maxlength: 30 },
                { input_type: 'input', key: 'referrerName', label: '推荐人', labelWidth: '130px', col: 11,  maxlength: 30},
                { input_type: 'input', key: 'referrerContact', label: '推荐人电话号码', labelWidth: '130px', col: 11},
                { input_type: 'input', key: 'intermediaryOrgan', label: '推荐人公司', labelWidth: '130px', col: 11, maxlength: 30},
                { input_type: 'input', type: 'textarea', key: 'remark', label: '备注', labelWidth: '130px', col: 24, maxlength: 200 },
              
            ],
            rules: {
                contacts: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                customerContact: [{ required: true, validator: this.util.formRules.checkPhone('请输入电话号码'),phoneType:3 }],
                clientCompany: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
                referrerContact: [{ required: false, validator: this.util.formRules.checkPhone(),phoneType:3  }],
                customerSource: [{ required: true, message: '请选择客户来源', trigger: 'blur' }],
                attachs: [{ required: true, message: '请选择联系人', trigger: 'blur' }],
                followUpPeopleDeptId: [{ required: true, message: '请选择部门', trigger: 'blur' }],
                followUpPeopleId: [{ required: true, message: '请选择人员', trigger: 'blur' }],
            },
            rowForm: {},
            title: '新增',
            show: false,
            isAdd: false,
            allDisabled: false,
            addLoading: false,
        }
    },
    mounted() {
        

    },
    watch: {
        
    },
    methods: {
        async init(title) {
            this.title=title
            this.show = true
            this.rowForm = {}
            this.allDisabled = false
            this.isAdd = true
        },
        edit(row,title){
            this.allDisabled=false
            this.show = true
             this.rowForm=row
            this.title=title
            
        },
        showResident(row){
            this.allDisabled=true
            this.show = true
            this.rowForm=row
        },
        saveForm() {
           if(this.title=='新增'){ 
            addCustomer(this.rowForm).then(() => {
                this.show = false
                this.getInit()
            })} else{
                editCustomer(this.rowForm).then(() => {
                    this.show = false
                    this.getInit()
                })
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>