<template>
  <router-view/>
</template>

<script>
export default {
  title: "房产管理",
  name: 'SysSetting'
}
</script>

<style scoped>

</style>
