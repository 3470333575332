import {invoiceType}from '@/api'
/** 账单核销列表 */
export const chargeBillManagementColumn = [
  { label: '房间名称', prop: 'roomName', width: '150px' },
  { label: '驻户名称', prop: 'tenantName', width: '150px' },
  { label: '账单月份', prop: 'month', width: '150px' },
  { label: '账单日期', prop: 'day', width: '150px' },
  { label: '账单编号', prop: 'billCode', width: '180px' },
  { label: '账单金额', prop: 'amountReceivable' },
  { label: '票据状态', prop: 'invoiceStatus' },
  { label: '票据类型', prop: 'invoiceTypeName' },
  { label: '开票日期', prop: 'invoiceDate' },
  { label: '开票金额(元)', prop: 'invoiceAmount' },
  {
    label: '操作',
    input_type: 'button',
    width: 160,
    list: [
      
    ]
  }
]
/** 新增/修改驻户信息 */
export const addInvoicingData = [
  {
    input_type: 'date',
    label: '开票日期',
    key: 'invoiceDate',
    col: 21,
    },
  {
    input_type: 'radio',
    label: '开具票据',
    key: 'invoiceType',
    col: 21,
    options:[
      {label:'发票',value:0},
      {label:'收据',value:1},
    ]
  },
  {
    input_type: 'select',
    label: '发票类型',
    key: 'invoiceSubtype',
    disabled: false,
    options: function () {
      return invoiceType().then(
        (res) => {
          if (res.code == 200) {
            let data = res.data.invoiceSubTypeName.map((item) => {
              return {
                label: item.dictLabel,
                value: item.dictValue
              }
            })
            return data.length != 0 ? data : []
          }
        }
      )
    },
    col: 21
  },
  {
    label: '开票金额',
    key: 'invoiceAmount',
    input_type: 'input', 
    col: 21
  }
]
 


