<template>
  <!-- 消息模板 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :editDataFormat="editDataFormat" :columns="columns" @rowCancel="rowCancel" @rowDelete="rowDelete" :tableData="tableData" :total="total" :loading="loading" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent" @getInit="getInit" @search="search" @reset="reset">
      <!-- <template #msgContent="{row}">
        <div class="table_msgContent">
          {{row.msgContentText }}
        </div>
      </template> -->
    </ja-table>
    <add-edit ref="addEdit" :declarationList="declarationList" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import { rqMsgTask, delMsgTask, cancelMsgTask } from '@/api'

export default {
  title: '消息任务',
  mixins: [table],
  components: {
    AddEdit,
  },
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { unlinkPanels: true } },
        { input_type: 'select', key: 'receivingType', attribute: { placeholder: '接收端', size: 'small' }, options: [], },
        {
          input_type: 'select', key: 'status', attribute: { placeholder: '状态', size: 'small' }, options: [
            { label: '待发送', value: 0 },
            { label: '已发送', value: 1 },
            { label: '已取消', value: 2 },
          ],
        },
        { input_type: 'input', key: 'taskName', attribute: { placeholder: '任务名称', size: 'small' } },
        { input_type: 'input', key: 'recipient', attribute: { placeholder: '接收人', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增', },
      ],
      columns: [
        { label: '任务名称', prop: 'taskName' },
        { label: '消息类型', prop: 'msgTypeName' },
        { label: '消息内容', column_type:'htmlStr', prop: 'msgContent', width: 250, attribute: { showOverflowTooltip: true, align: 'center' } },
        // { label: '消息内容', prop: 'msgContent', attribute: { showOverflowTooltip: true, align: 'center' }, },
        { label: '接收端', prop: 'receivingTypeName', },
        { label: '通知组', prop: 'groupName', },
        { label: '发送时间', prop: 'sendTime', },
        { label: '创建人', prop: 'createBy', },
        { label: '发送状态', prop: 'statusName' },
        { label: '备注', prop: 'remark', attribute: { showOverflowTooltip: true, align: 'center' }, },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '取消', method: 'rowCancel', },
            { text: '删除', method: 'rowDelete', }
          ]
        }
      ],
      rowForm: {},
      indexObj: {},

      declarationList: [], //变量说明
    }
  },
  mounted() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    const addEdit = this.$refs.addEdit
    this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.receivingType], addEdit.rowFormData[addEdit.indexObj.receivingType]], rqUrl: "/sys/dictData/msg_receiving_type", resRule: 'data', })

  },
  methods: {
    rqParmas() {
      let obj = { ...this.filterData }
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = true
      const rqData = this.rqParmas()
      rqMsgTask(rqData).then(res => {
        const { total, data } = res.data
        this.total = total
        this.tableData = (data || []).map(item => {
          let msgContent = item.msgContent
          item.msgContentText = msgContent.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ')
          return item
        })
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm(`是否确认删除消息任务“${row.taskName}”`, '操作确认', { type: 'warning' }).then(res => {
        if(row.status!=0){
           delMsgTask(row.id).then(res => {
          this.$message.success(res.msg || "已删除")
          this.getInit()
        })
        }else{
          this.$message.error('删除失败，请先取消再删除');
        }   
      })
    },
    // 取消
    rowCancel(row) {
      this.$confirm(`是否确认取消该消息任务“${row.taskName}”`, '操作确认', { type: 'warning' }).then(res => {
        cancelMsgTask(row.id).then(res => {
          this.$message.success(res.msg || "已取消")
          this.getInit()
        })
      })
    },
    // 取消预约
    editDataFormat(row,btn){
      if(row.status !=0 && btn.text=='取消'){
        return false
      }else{
        return true
      }
    },
  }
}
</script>
<style scoped>
.table_msgContent {
  max-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
