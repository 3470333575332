<template>
  <el-row class='ja-detail' v-loading="loadingForm||false" :element-loading-text="loadingFormObj.text" :element-loading-spinner="loadingFormObj.icon" :element-loading-background="loadingFormObj.bg" :element-loading-custom-class="loadingFormObj.formLoading">
    <h2 v-if="titleText">{{titleText}}</h2>
    <template v-for="(item,index) in options">
      <el-col :key="index" :span="parseInt(item.col) || 12" v-if="!item.hide" :style="{margin:isPrint?'10px 0':'',}" class="el-col-mine">
        <template v-if="item.type === 'normal' || !item.type">
          <div class="item">
            <span :class="{'isTitle': item.isTitle}" :style="{width:item.label_width?item.label_width+'em':label_width+'em'}">{{item.label}}{{item.isTitle?'':':'}}</span>
            <span class="itemRight" v-if="item.rightText">{{detailData[item.key]}}{{item.rightText}}</span>
            <span class="itemRight" v-else v-html="detailData[item.key]"></span>
          </div>
        </template>

        <template v-if="item.type === 'img'">
          <div class="item itemImg">
            <span :style="{width:item.label_width?item.label_width+'em':label_width+'em'}">{{item.label}}：</span>
            <div v-if="Array.isArray(detailData[item.key]) && detailData[item.key].length>0" class="imgBox">
              <el-image v-for="(imgUrl,img_index) in detailData[item.key]" :key="img_index" class="img" :lazy='true' :src="imgUrl" :preview-src-list="detailData[item.key]" z-index="3000" fit='cover'></el-image>
            </div>
            <el-image v-if="!Array.isArray(detailData[item.key]) && detailData[item.key]" class="img" :lazy='true' z-index="3000" :src="detailData[item.key]" :preview-src-list="[detailData[item.key]]" fit='cover'></el-image>
          </div>
        </template>

        <template v-if="item.type === 'table'">
          <div class="item tableBox">
            <span v-if="item.showTableLabel" :style="{width:item.label_width?item.label_width+'em':label_width+'em'}">{{item.label}}:</span>
            <div class="table" :style="{marginLeft:'0',width:item.showTableLabel ? `calc(100% - ${item.label_width?item.label_width+1+'em':label_width+1+'em'} - 6%)` : '100%'}">
              <ja-table class="conditon" v-bind="item" v-on="$listeners" v-model="item.filterData" :tableData="detailData[item.key]" :valueFormat="valueFormat" :isFromForm="true" :style="{width:item.styleWidth}">

              </ja-table>
            </div>
          </div>
        </template>

        <template v-if="item.type === 'customize'">
          <div class="item" :style="{display: 'flex',alignItems:item.alignItems}">
            <span :style="{width:item.label && item.label_width?item.label_width+'em':label_width+'em','flex-shrink':0}" v-if="item.label">{{item.label}}{{item.label && item.label.trim() ?':':''}}</span>
            <div :style="{marginLeft:'0',width:`calc(100% - ${item.label && item.label_width || item.label_width === 0 ? item.label_width+'em':label_width+1+'em'})`}">
              <slot :name="item.customize" :row="detailData[item.key]"></slot>
            </div>
          </div>
        </template>
      </el-col>
    </template>
    <template v-if="isButton">
      <div class="btns">
        <el-button v-for="(item,index) in btnBox" :key="index+0.0001" :type="item.type || ''" @click="itemEventBtn(item.method)">{{item.label}}</el-button>
      </div>
    </template>
    <slot></slot>
  </el-row>
</template>

<script>
/**
 * 源于广州之窗项目，部分修改
 */
import constObj from '@/util/config'
import tableM from '@/views/mixins/table.js'
export default {
  mixins: [tableM],
  name: 'ja-detail',
  props: {
    options: Array, // label,key,col,show,type, img、table、normal
    detailData: Object,
    // 表单的加载
    loadingForm: {
      type: Boolean,
      default: false
    },
    // table内容格式转换
    valueFormat: {
      type: Function,
      default: function (val, key, row, scope) {
        // console.log('val--->', val, key)
        return val
      }
    },
    label_width: {
      type: Number,
      default: 8
    },
    showTableLabel: {
      type: Boolean,
      default: true
    },
    titleText: String,
    isPrint: {
      type: Boolean,
      default: false
    },
    isButton: Boolean,
    btnBox: {
      type: Array,
      default: function () {
        return [{ label: '保存', method: 'save', type: 'primary', },
        { label: '取消', method: 'reset', }
        ]
      }
    },
  },
  data() {
    return {
      loadingFormObj: {},
    }
  },
  computed: {
    i18n() {
      return ''
    }
  },
  created() {
    this.loadingFormObj = constObj.loadingFormObj
  },
  methods: {
    itemEventBtn(method,) {
      this.$emit(method)
    },
  }
}
</script>

<style scoped lang='scss'>
@import '~@/style/mixin.scss';
.ja-detail {
  h2 {
    color: $sub-title-color;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .el-col-mine {
    margin-bottom: 5px;
  }
  .item {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    line-height: 40px;
    font-size: 14px;
    .itemRight {
      flex: 1;
      line-height: 18px;
    }
    &.tableBox {
      display: flex;
      margin: 10px 0;
      .title {
        margin-top: 8px;
        margin-bottom: initial;
        font-weight: bold;
        text-align: left;
      }
    }
    &.itemImg {
      display: flex;
      .imgBox {
        display: flex;
        flex-wrap: wrap;
        .img {
          margin: 10px 10px 10px;
        }
      }
    }
    .img {
      width: 150px;
      height: 150px;
    }
    > span:nth-of-type(1) {
      width: 8em;
      text-align: right;
      margin-right: 1em;
      &.isTitle {
        text-align: left;
        font-weight: 600;
        font-size: 15px;
        color: #606266;
      }
    }
    h2 {
      width: 6em;
      margin-top: 20px;
      font-size: 18px;
      text-align: right;
    }
    .table {
      width: 100%;
      // margin-top: 20px;
      margin-left: 20%;
    }
  }
  .btns {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }
}
</style>
