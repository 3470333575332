export default class myCanvas {
    constructor ({ canvas = {}, div = {}, addressLists = [], img = null, state = false }) {
        // 画布的dom
        this.canvas = canvas
        this.div = div
        // 完成以后存放的位置
        this.addressLists = addressLists
        // 点击坐标
        this.start = {
            startX: 0,
            startY: 0
        }
        // 拖动时的锁
        this.liftLock = false
        // 画布对象
        this._ctx = null
        // 拖动的数组下标
        this.moveId = null
        // addressList
        this._length = null
        // 所有路径的最大值与最小值
        this.CoordinateOption = []
        // 当前点击的坐标
        this.currentClickId = null
        // 传进来的图片
        this.img = img
        // 缩放平移
        this.canvasInfo = {
            lastEvtPos: {
                x: null,
                y: null
            },
            offsetEvtPos: {
                x: null,
                y: null
            },
            scale: 1,
            scaleStep: 0.1,
            offset: { x: -300, y: -300 }

        }
        this.state = state
        // 画布状态
        this.CANVAS_STATE = {
            BRUSH: true,
            MOVE: true,
            PAN: true,
            DELETE: true
        }
        // 响应外界
        this.responseData = null
    }

    // 初始化
    init () {
        if (this.isDOM(this.canvas)) {
            if (this.canvas.tagName !== 'CANVAS') throw Error('传入的不是一个canvas DOM')
        } else {
            throw Error('传入的不是一个DOM')
        }
        if (toString.call(this.div) === '[object HTMLElement]') {
            if (!this.isDOM(this.div)) throw Error(`${this.div},不是一个DOM,初始化失败`)
            this.canvas.width = this.div.offsetWidth
            this.canvas.height = this.div.offsetHeight
        }
        this.coordinateCollection()
        this.closeClick()
        // 初始化坐标
        this._ctx = this.canvas.getContext("2d")
        this.drawLine()
    }

    // 获取点击的id
    getClickItemId (isPointer) {
        if (this.CANVAS_STATE.DELETE) {
            this.closeState('DELECT')
            this.closeClick()
            this.coordinateCollection()
            this.clickEvent(isPointer)
        } else {
            this.closeClick()
        }
        this.CANVAS_STATE.DELETE = !this.CANVAS_STATE.DELETE
    }

    // 画出来
    drawLine () {
        const _ctx = this._ctx
        const addressLists = this.addressLists.length > 0 ? [...this.addressLists] : []
        _ctx.save()
        _ctx.setTransform(this.canvasInfo.scale, 0, 0, this.canvasInfo.scale, this.canvasInfo.offset.x, this.canvasInfo.offset.y)
        _ctx.clearRect(-300, -300, 5000, 5000) // 清除画布 偏移解决重影
        // 放图片进画布
        if (this.img) {
            _ctx.drawImage(this.img, 0, 300)
        }
        // 判空
        if (addressLists.length > 0) {
            for (const iterator of addressLists) {
                _ctx.beginPath()
                const arrList = iterator.childs
                if (arrList.length > 0) {
                    _ctx.moveTo(arrList[arrList.length - 1].startX, arrList[arrList.length - 1].startY)
                    _ctx.globalAlpha = 0.2
                    console.log(iterator.colour)
                    if (iterator.colour === 0) {
                        _ctx.fillStyle = '#fff'
                    } else if (iterator.colour === 1) {
                        _ctx.fillStyle = 'green'
                    } else if (iterator.colour === 2) {
                        _ctx.fillStyle = 'purple'
                    } else if (iterator.colour === 3) {
                        _ctx.fillStyle = 'red'
                    }
                    for (const ops of arrList) {
                        _ctx.arc(ops.startX, ops.startY, 3, 0, 2 * Math.PI)
                        _ctx.lineTo(ops.startX, ops.startY)
                    }
                    _ctx.stroke()
                    _ctx.fill()
                    // 填充
                    _ctx.closePath()
                }
            }

            // 画图形标识文字
            if (this.CANVAS_STATE.BRUSH) {
                for (const iterator of addressLists) {
                    const textLength = this.CoordinateOption.findIndex(e => e.id === iterator.id)
                    // 避免此时没有最新画出来的文字坐标
                    if (this.CoordinateOption[textLength]) {
                        const { textCoordinates } = this.CoordinateOption[textLength]
                        _ctx.globalAlpha = 1
                        _ctx.fillStyle = "#fff"
                        _ctx.textAlign = "center"
                        _ctx.font = "7px Arial"
                        _ctx.fillText(iterator.name || '', textCoordinates.textX, textCoordinates.textY)
                    }
                }
            }
            _ctx.restore()
        }
    }

    setUpMouseupEvemt (e) {
        if (this.liftLock) {
            const canvasPosition = this.getCanvasPosition(e, this.canvasInfo.offset, this.canvasInfo.scale)
            this.start.startX = canvasPosition.x
            this.start.startY = canvasPosition.y
            this.addressLists[this.addressLists.length - 1].childs.push({ ...this.start })
            this.drawLine()
        }
        this.canvas.onmousemove = null
    }

    setUpMousedownEvemt (e) {
        // 只监听左键
        if (e.which === 1) {
            this.liftLock = true
            this.moveId = null
            this.canvas.onmousemove = this.setUpMovePlace.bind(this)
        }
    }

    setUpMovePlace (e) {
        const childs = this.addressLists[this._length].childs
        const canvasPosition = this.getCanvasPosition(e, this.canvasInfo.offset, this.canvasInfo.scale)
        if (this.liftLock) {
            for (let index = 0; index < childs.length; index++) {
                if (Math.abs(childs[index].startX - canvasPosition.x) <= 3) {
                    this.liftLock = false
                    // 当前移动的点坐标
                    this.number = index
                    break
                }
            }
        }
        if (!this.liftLock && this.number >= 0) {
            childs[this.number].startX = canvasPosition.x
            childs[this.number].startY = canvasPosition.y

            this.drawLine()
        }
    }

    // 开关
    switchBrush () {
        // 保存最后一个坐标
        if (this.CANVAS_STATE.BRUSH) {
            this.closeState('BRUSH')
            this.closeClick()
            this.canvas.style.cursor = 'crosshair'
            // 新增局域
            if (this.addressLists.length === 0) {
                this.addressLists.push({ id: null, childs: [] })
            } else if (!this.addressLists[this.addressLists.length - 1].childs.length < 1) {
                this.addressLists.push({ id: null, childs: [] })
            }
            this._length = this.addressLists.length - 1 < 0 ? 0 : this.addressLists.length - 1
            this.canvas.onmousedown = this.setUpMousedownEvemt.bind(this)
            this.canvas.onmouseup = this.setUpMouseupEvemt.bind(this)
        } else {
            this.closeClick()
        }
        this.CANVAS_STATE.BRUSH = !this.CANVAS_STATE.BRUSH
    }

    // 放大
    enlargeCanvas () {
        if (this.CANVAS_STATE.MOVE) {
            this.closeClick()
            this.closeState('MOVE')
            this.canvas.onwheel = function (e) {
                e.preventDefault()
                const canvasPosition = this.getCanvasPosition(e, this.canvasInfo.offset, this.canvasInfo.scale)
                const realCanvasPosition = {
                    x: canvasPosition.x - this.canvasInfo.offset.x,
                    y: canvasPosition.y - this.canvasInfo.offset.y
                }
                const deltaX = realCanvasPosition.x / this.canvasInfo.scale * this.canvasInfo.scaleStep
                const deltaY = realCanvasPosition.y / this.canvasInfo.scale * this.canvasInfo.scaleStep
                if (e.wheelDelta > 0 && this.canvasInfo.scale <= 2) {
                    this.canvasInfo.offset.x -= deltaX
                    this.canvasInfo.offset.y -= deltaY
                    this.canvasInfo.scale += this.canvasInfo.scaleStep
                } else if (e.wheelDelta < 0 && this.canvasInfo.scale >= 1) {
                    this.canvasInfo.offset.x += deltaX
                    this.canvasInfo.offset.y += deltaY
                    this.canvasInfo.scale -= this.canvasInfo.scaleStep
                }
                this.drawLine()
            }.bind(this)
        } else {
            this.closeClick()
        }
        this.CANVAS_STATE.MOVE = !this.CANVAS_STATE.MOVE
    }

    // 平移
    panCanvas () {
        if (this.CANVAS_STATE.PAN) {
            this.closeClick()
            this.closeState('PAN')
            this.canvas.style.cursor = 'move'
            this.canvas.onmousedown = this.panMousedownEvent.bind(this)
            this.canvas.onmouseup = this.panMouseUp.bind(this)
        } else {
            this.closeClick()
        }
        this.CANVAS_STATE.PAN = !this.CANVAS_STATE.PAN
    }

    panMousedownEvent (e) {
        const canvasPosition = this.getCanvasPosition(e, this.canvasInfo.offset, this.canvasInfo.scale)
        this.canvasInfo.lastEvtPos = canvasPosition
        this.canvasInfo.offsetEvtPos = canvasPosition
        this.canvas.onmousemove = this.panMouseMove.bind(this)
    }

    panMouseMove (e) {
        const canvasPosition = this.getCanvasPosition(e, this.canvasInfo.offset, this.canvasInfo.scale)
        this.canvasInfo.offset.x += canvasPosition.x - this.canvasInfo.offsetEvtPos.x
        this.canvasInfo.offset.y += canvasPosition.y - this.canvasInfo.offsetEvtPos.y
        this.drawLine()
    }

    panMouseUp () {
        this.canvas.onmousemove = null
    }

    // 收集所有坐标系
    coordinateCollection () {
        // // 鼠标按下
        const addCoordinateOption = []
        this.canvas.onmousedown = null
        // 鼠标抬起
        this.canvas.onmouseup = null
        const addressLists = this.addressLists
        // 1.点击完成绘制后获取所有路径的最大X，Y坐标，与最小X,Y坐标
        // 过滤出所有的坐标
        for (let i = 0; i < addressLists.length; i++) {
            const obj = { id: null, name: null, textCoordinates: { textX: 0, textY: 0 }, x: [], y: [] }
            for (let j = 0; j < addressLists[i].childs.length; j++) {
                obj.x.push(addressLists[i].childs[j].startX)
                obj.y.push(addressLists[i].childs[j].startY)
            }
            obj.id = addressLists[i].id
            obj.name = addressLists[i].name
            addCoordinateOption[i] = obj
        }
        // 对坐标进行排序
        addCoordinateOption.map((e) => {
            e.x.sort((a, b) => a - b)
            e.y.sort((a, b) => a - b)
        })

        // 获取文字坐标
        addCoordinateOption.map((e) => {
            e.textCoordinates.textX = ((e.x[e.x.length - 1] - e.x[0]) / 2) + Number(e.x[0])
            e.textCoordinates.textY = ((e.y[e.y.length - 1] - e.y[0]) / 2) + Number(e.y[0])
        })
        this.CoordinateOption = addCoordinateOption
    }

    // 点击识别事件，只有非画图非平移有效
    clickEvent (isPointer = true) {
        const arrXY = []
        this.CoordinateOption.map(e => {
            arrXY.push(e.textCoordinates)
        })
        if (isPointer) {
            this.canvas.onmousemove = function (e) {
                const canvasPosition = this.getCanvasPosition(e, this.canvasInfo.offset, this.canvasInfo.scale)
                const isOk = this.identifyCoordinates(canvasPosition.x, canvasPosition.y, arrXY)
                if (isOk) {
                    this.canvas.style.cursor = 'pointer'
                } else {
                    this.canvas.style.cursor = ''
                }
            }.bind(this)
        } else {
            this.closeClick()
            this.canvas.style.cursor = `url(${require('@/util/MyCanvas/ico/delete.ico')}),crosshair`
        }
        // 2.开始监听鼠标点击事件，当点击时先判断是否在路径内，
        this.canvas.onclick = function (e) {
            const canvasPosition = this.getCanvasPosition(e, this.canvasInfo.offset, this.canvasInfo.scale)
            const isOk = this.identifyCoordinates(canvasPosition.x, canvasPosition.y, arrXY)
            // 1.在路径内就查找所有坐标看其在那个路径内
            if (isOk) {
                const maxMinPaths = this.CoordinateOption
                for (let i = 0; i < maxMinPaths.length; i++) {
                    if (canvasPosition.x > maxMinPaths[i].x[0] && canvasPosition.x < maxMinPaths[i].x[maxMinPaths[i].x.length - 1]) {
                        if (canvasPosition.y > maxMinPaths[i].y[0] && canvasPosition.y < maxMinPaths[i].y[maxMinPaths[i].y.length - 1]) {
                            this.responseData = i
                            break
                        }
                    }
                }
            } else {
                this.responseData = null
            }
        }.bind(this)
    }

    // 真正的坐标
    getCanvasPosition = (e, offset = { x: 0, y: 0 }, scale = 1) => {
        return {
            x: (e.offsetX - offset.x) / scale,
            y: (e.offsetY - offset.y) / scale
        }
    }

    closeClick () {
        this.canvas.style.cursor = ''
        this.canvas.onmousedown = null
        this.canvas.onmouseup = null
        this.canvas.onmousemove = null
        this.canvas.onwheel = null
        this.canvas.onclick = null
    }

    closeState (stateName = '') {
        for (const key in this.CANVAS_STATE) {
            if (key !== stateName) {
                this.CANVAS_STATE[key] = true
            }
        }
    }

    getAllState (obj) {
        for (const key in obj) {
            if (!obj[key]) {
                return false
            }
        }
        return true
    }

    deleteLocation (length) {
        const arr = this.addressLists.splice(length, 1)
        if (arr.length > 0) {
            this.coordinateCollection()
            this.drawLine()
            this.responseData = null
            return true
        }
    }

    cancelDelete () {
        this.responseData = null
    }

    collectionRepaint () {
        this.coordinateCollection()
        this.drawLine()
    }

    showcCickEvent () {
        const arrXY = []
        this.CoordinateOption.map(e => {
            arrXY.push(e.textCoordinates)
        })
        // 2.开始监听鼠标点击事件，当点击时先判断是否在路径内，
        this.canvas.onclick = function (e) {
            const canvasPosition = this.getCanvasPosition(e, this.canvasInfo.offset, this.canvasInfo.scale)
            const isOk = this.identifyCoordinates(canvasPosition.x, canvasPosition.y, arrXY)
            // 1.在路径内就查找所有坐标看其在那个路径内
            if (isOk) {
                const maxMinPaths = this.CoordinateOption
                for (let i = 0; i < maxMinPaths.length; i++) {
                    if (canvasPosition.x > maxMinPaths[i].x[0] && canvasPosition.x < maxMinPaths[i].x[maxMinPaths[i].x.length - 1]) {
                        if (canvasPosition.y > maxMinPaths[i].y[0] && canvasPosition.y < maxMinPaths[i].y[maxMinPaths[i].y.length - 1]) {
                            this.responseData = i
                            break
                        }
                    }
                }
            } else {
                this.responseData = null
            }
        }.bind(this)
    }

    identifyCoordinates (x, y, arrXY) {
        let maxX, maxY, minX, minY
        for (let i = 0; i < arrXY.length; i++) {
            maxX = arrXY[i].textX + 40
            maxY = arrXY[i].textY + 40
            minX = arrXY[i].textX - 40
            minY = arrXY[i].textY - 40
            if ((x < maxX && x > minX) && (y < maxY && y > minY)) {
                return true
            }
        }
        return false
    }

    // 识别DOM
    isDOM = (typeof HTMLElement === 'object')
        ? function (obj) {
            return obj instanceof HTMLElement
        }
        : function (obj) {
            return obj && typeof obj === 'object' && obj.nodeType === 1 && typeof obj.nodeName === 'string'
        }
}
