<template>
  <!-- 详情弹窗 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="详情" :width="`40%`">
    <div class="main" v-if="showDetail">
      <div class="bigTitle">
        <span>{{detailData.questionnaireTitle}}</span>
        <span class="numBox"><el-tag effect="dark" >参与：{{detailData.participateNumber}}</el-tag></span>
      </div>
      <ul class="list">
        <li v-for="(item,index) in detailData.pcsqq" :key="index" class="item">
          <div class="title">
            <span>{{index+1}}、{{item.questionDescription}}（{{["单选","多选"][item.questionType]}}）</span>
            <span class="numBox"><el-tag effect="dark" size="small">参与：{{item.sum|filterSum(item.pcsqo)}}</el-tag></span>
          </div>
          <ul class="optionsList">
            <li v-for="(val,i) in item.pcsqo" :key="i" class="optionItem">
              <span>{{labels[i]}}：{{val.optionContent}}</span>
              <span class="numBox"><el-tag  size="mini">{{val.selectNumber}}</el-tag></span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </el-dialog>
</template>
<script>

export default {
  data() {
    return {
      showDetail: false,
      labels: [],
      detailData: {},
    }
  },
  created() {
    this.labels = this.generateBig()
  },
  filters:{
    filterSum(val,options){
      let sum = 0
      options.forEach(v=>{
        sum = sum + v.selectNumber
      })
      return sum
    }
  },
  methods: {
    // 获得大学字母
    generateBig() {
      var str = [];
      for (var i = 65; i < 91; i++) {
        str.push(String.fromCharCode(i));
      }
      return str;
    },
    rowDetail(row) {
      this.detailData = { ...row }
      this.showDetail = true
    },
  },
}
</script>
<style lang="scss" scoped>
.main {
  color: '#303133';
  padding: 0 30px 0;
  font-size: 14px;
  .bigTitle,
  .title,
  .optionItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .numBox {
    flex-shrink: 0;
    width: 130px;
    font-weight: normal;
    text-align: right;
  }
  .bigTitle {
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  .title {
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  ul.list {
    > li.item {
      margin-bottom: 15px;
    }
    ul.optionsList{
      >li{
        line-height: 1.5;
        margin-bottom: 10px;
      }
    }

  }
}
</style>