<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="活动详情" :width="`45%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='10' v-if="showDetail">
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      showDetail: false,
      detailOptions: [
        { key: 'a', label: '活动信息', isTitle: true, col: 22 },
        { key: 'activityCode', label: '申请编号', },
        { key: 'activityTopic', label: '活动主题', },
        { key: 'activityTypeName', label: '活动类型', },
        { key: 'area', label: '活动区域', },
        { key: 'companyName', label: '举办单位', },
        { key: 'activityPrincipal', label: '负责人', },
        { key: 'phone', label: '联系方式', },
        { key: 'participantsNumber', label: '参予人数', },
        { key: 'startTime', label: '开始时间', },
        { key: 'endTime', label: '结束时间', },
        { key: 'activityContent', label: '活动说明', col: 22 },

        { key: 'a', label: '审核信息', isTitle: true, col: 22 },
        { key: 'statusName', label: '审批状态', },
        { key: 'auditEmployee', label: '审批人', },
        { key: 'auditTime', label: '审批时间', col: 22 },
        { key: 'rejectReason', label: '驳回理由', },
      ],
      detailData: {},
      initFileList: []
    }
  },
  methods: {
    rowDetail(row) {
      // console.log('查看详情---',row,)
      this.detailData = { ...row }
      this.showDetail = true
    },
  }
}
</script>
<style lang="scss" scoped>
.something {
  padding-left: 60px;
  > li {
    display: flex;
    width: 70%;
    justify-content: space-between;
  }
}
.imgs{
  padding-left: 60px;
}
</style>