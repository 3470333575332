<template>
  <div class="page">
    <Header :headerData="headerData" :loading="loading" />

    <div class="middle">
      <Waterchart :waterData="waterDataL" :loading="loading" class="box"></Waterchart>

      <Waterchart :waterData="waterDataR" :loading="loading" class="box"></Waterchart>
    </div>

    <div class="middle2">
      <Topchart :loading="loading" class="box"></Topchart>

      <AlarmType :repairMonthStatistics="repairMonthStatistics" :loading="loading" class="box"></AlarmType>
    </div>

    <Energy :monthlyStatistics="monthlyStatistics" :loading="loading"></Energy>

  </div>
</template>

<script>
import Header from './childComp/Header.vue'
import Waterchart from './childComp/Waterchart.vue'
import Topchart from './childComp/Topchart.vue'
import AlarmType from './childComp/AlarmType.vue'
import Energy from './childComp/Energy.vue'
import { getStatisticalData } from '@/api'
export default {
  title: "工单统计",
  data() {
    return {
      //维修工单处理率
      repairWorkOrder: {},
      //部门完成率
      inspectionWorkOrder: [],
      headerData: {},//头部数据
      loading: true,
      //巡检工单统计 
      waterDataL: {},//水球图左
      waterDataR: {},//水球图右
      //维修工单状态分析
      repairMonthStatistics:[],
      //维修工单月度统计
      monthlyStatistics:[]
    }
  },
  components: {
    Header, Waterchart, AlarmType, Energy, Topchart
  },
  methods: {
    async init() {
      this.loading = true
      await getStatisticalData().then(res => {
        // 维修工单处理率
        this.repairWorkOrder = res.data.repairWorkOrder
        this.repairWorkOrder.Chartone = parseFloat(this.repairWorkOrder.workOrderProcessing.match(/\d+/))
        this.repairWorkOrder.Charttwo = 100 - parseFloat(this.repairWorkOrder.workOrderProcessing.match(/\d+/))
        //部门完成率
        this.inspectionWorkOrder = res.data.inspectionWorkOrder
        this.inspectionWorkOrder.forEach(item => {
          item.Chartone = parseFloat(item.workOrderProcessing.match(/\d+/))
          item.Charttwo = 100 - parseFloat(item.workOrderProcessing.match(/\d+/))
        })
        this.headerData = {
          chartData: [
            { ...this.repairWorkOrder },
            { ...this.inspectionWorkOrder[0] },
            { ...this.inspectionWorkOrder[1] }
          ],
          //未完成工单数
          unfinishedWorkOrder: res.data.unfinishedWorkOrder
        }

        //巡检工单统计 水球图数据
        this.waterDataL = res.data.inspectionWorkOrderStatistics[0]
        this.waterDataR = res.data.inspectionWorkOrderStatistics[1]
        //维修工单状态分析
        this.repairMonthStatistics = res.data.repairMonthStatistics
        //维修工单月度统计
        this.monthlyStatistics = res.data.monthlyStatistics
        this.loading = false
      })
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.boxCommon {
  margin: 15px 0 15px 0;
  border-radius: 20px;
  background: white;
}

.page {
  margin: 15px;
  .middle {
    display: flex;
    justify-content: space-between;

    .box {
      width: calc((100% - 20px) / 2);
      @extend .boxCommon;
      height: 360px;
    }
  }

  .middle2 {
    display: flex;
    justify-content: space-between;

    .box {
      width: calc((100% - 20px) / 2);
      @extend .boxCommon;
      height: 360px;
      margin-top: 0;
    }

    .mid {
      margin: 0 20px;
    }
  }
}</style>
