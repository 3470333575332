<template>
  <!-- 销控图信息管理 -->
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      :valueFormat="formatter"
      @dictonaList="dictonaList"
      @rowDelete="rowDelete"
      @getInit="getInit"
      @search="search"
      @reset="reset"
      :showIndex="false"
      @seeDetails="seeDetails"
    >
    </ja-table>
    <!-- 新增/编辑 -->
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      :title="titleText"
      @close="allDisabled = false"
    >
      <ja-form
        ref="jaForm"
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        @inclick="inclick"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
        :allDisabled="allDisabled"
      >
        <template v-slot: uploadChart>
          <el-upload
            ref="upload"
            class="avatar-uploader"
            :action="$uploadUrl + '/oss/upload'"
            :limit="1"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-error="imgErrorMethod"
            accept=".png,.jpg,.jpeg"
            :file-list="fileList"
          >
            <img v-if="salesControlUrl" :src="salesControlUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              png、jpg图片格式，大小限制3mb，为了更好的展示效果，请上传1600x800像素
            </div>
          </el-upload>
          <span
            ref="deleteIcon"
            class="deleteIcon"
            v-show="salesControlUrl"
            v-if="!isShow"
            @click="handleRemove()"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  controlInforList,
  addControlInfor,
  editControlInfor,
  delControlInfor,
  getBuildingList,
  getFloorList,
} from '@/api'
import table from '@/views/mixins/table'
import { dicDataColumn, dicDataRules } from './controlInformationCulumn'

export default {
  title: '销控图信息管理',
  mixins: [table],
  name: 'index',
  data() {
    return {
      allDisabled: false,
      fileList: [],
      salesControlUrl: '',
      typeCode: '',
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        {
          input_type: 'select',
          key: 'buildingId',
          method: 'changeCity',
          attribute: { placeholder: '楼栋' },
          options: function () {
            return getBuildingList({ pageIndex: 1, pageSize: 200 }).then(
              (res) => {
                if (res.code == 200) {
                  let data = res.data.data.map((item) => {
                    return {
                      label: item.buildingName,
                      value: item.id
                    }
                  })
                  return data.length != 0 ? data : []
                }
              }
            )
          }
        },
        {
          input_type: 'select',
          key: 'floorId',
          method: 'changeCity',
          // multiple: true,
          attribute: { placeholder: '楼层' },
          options: function () {
            return getFloorList({ pageIndex: 1, pageSize: 200 }).then(
              (res) => {
                if (res.code == 200) {
                  let data = res.data.data.map((item) => {
                    return {
                      label: item.floorName,
                      value: item.id
                    }
                  })
                  return data.length != 0 ? data : []
                }
              }
            )
          }
        },
        {
          input_type: 'input',
          key: 'imgName',
          attribute: { placeholder: '图片文件名' }
        },
        { normalBtn: 'search' },
        { normalBtn: 'reset' },
        { normalBtn: 'add', limit: 'control::information::add' }
      ],
      columns: dicDataColumn,
      tableData: [],
      // 新增/编辑
      rowFormData: [
        {
          input_type: 'input',
          label: '图片名称',
          key: 'imgName',
          maxlength: 30
        },
        {
          input_type: 'select',
          label: '楼栋',
          key: 'buildingId',
          method: 'changeCity',
          options: function () {
            return getBuildingList({ pageIndex: 1, pageSize: 200 }).then(
              (res) => {
                if (res.code == 200) {
                  let data = res.data.data.map((item) => {
                    return {
                      label: item.buildingName,
                      value: item.id
                    }
                  })
                  return data.length != 0 ? data : []
                }
              }
            )
          }
        },
        {
          input_type: 'select',
          label: '楼层',
          key: 'floorId',
          method: 'changeCity',
          options: function () {
            return getFloorList({ pageIndex: 1, pageSize: 200 }).then(
              (res) => {
                if (res.code == 200) {
                  let data = res.data.data.map((item) => {
                    return {
                      label: item.floorName,
                      value: item.id
                    }
                  })
                  return data.length != 0 ? data : []
                }
              }
            )
          }
        },
        {
          label: '备注',
          key: 'remark',
          input_type: 'input',
          type: 'textarea',
          maxlength: 200
        },
        {
          label: '销控图',
          key: 'salesControlUrl',
          slotName: 'uploadChart'
        }
      ],
      loading: false,
      total: 30,
      showDetail: false,
      labelWidth: `${this.util.setFontSize(120)}px`,
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      isAdd: true, // 新增/编辑
      rules: dicDataRules,
      isShow:false,
    }
  },
  created() {},
  methods: {
    // 图片上传成功
    handleAvatarSuccess(res) {
      this.$refs.upload.clearFiles()
      if (res.code !== 200) return this.$message.error('图片上传失败')
      this.$refs.jaForm.$refs.formbox.clearValidate('salesControlUrl')
      this.salesControlUrl = res.data
      this.$set(this.rowForm,'salesControlUrl',res.data)
    },
    // 上传之前
    beforeAvatarUpload(file) {
      let name = file.name
      let size = file.size
      let pattern = /.(jpg|png|jpeg)/
      if (!pattern.test(name)) {
        console.log(this.$refs.upload)
        // this.$refs.upload.abort()
        this.$message.error('只接受图片格式：jpg,png,jpeg')
        return false
      }
      if (size / 1024 / 1024 > 3) {
        this.$refs.upload.abort()
        this.$message.error('图片导入失败，图片不能大于3M')
        return false
      }
    },
    // 图片上传失败
    imgErrorMethod() {
      this.$message.error('图片上传失败，请重试')
      this.$refs.imgUpload.abort()
      return false
    },
    handleRemove() {
      this.$refs.upload.clearFiles()
      this.salesControlUrl = null
      this.$set(this.rowForm, 'salesControlUrl', '')
    },
    getInit() {
      this.loading = true
      // 获取楼层列表
      controlInforList({
        pageIndex: this.filterData.pageIndex,
        pageSize: this.filterData.pageSize,
        imgName: this.filterData.imgName,
        buildingId: this.filterData.buildingId,
        floorId: this.filterData.floorId,
        remark: this.filterData.remark,
        salesControlUrl: this.filterData.salesControlUrl
      }).then(({ data }) => {
        this.tableData = data.data
        this.total = data.total
        this.loading = false
      })
    },
    // 新增
    append() {
      this.isShow = false
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {}
      this.showEditAll = true
      this.salesControlUrl = null
    },
    // 编辑
    rowEdit(row, details) {
      if (details == 'details') {
        this.isShow = true
        this.titleText = '查看'
        this.allDisabled = true
      } else {
        this.isShow = false
        this.titleText = '编辑'
      }
      this.salesControlUrl = null
      this.isAdd = false
      this.showEditAll = true
      this.rowForm = { ...row }
      this.salesControlUrl = row.salesControlUrl
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该字典').then((res) => {
        delControlInfor(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    // 保存
    saveForm() {
      this.loading_form.isShow = true
      if (this.isAdd) {
        addControlInfor(this.rowForm)
          .then(({ msg }) => {
            this.loading_form.isShow = false
            this.showEditAll = false
            this.$message.success(msg)
            this.getInit()
          })
          .catch((err) => {
            console.log(err)
            this.loading_form.isShow = false
          })
      } else {
        editControlInfor(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.showEditAll = false
            this.$message.success(res.msg)
            this.getInit()
          })
          .catch((err) => {
            console.log(err)
            this.loading_form.isShow = false
          })
      }
    },
    seeDetails(row) {
      // console.log(row)
      this.rowEdit(row, 'details')
    }
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-col .el-form-item .el-input__inner {
  height: 40px;
}
/deep/ .el-select {
  width: 100% !important;
}
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px solid #d9d9d9;
  margin-left: 120px;
  margin-top: -60px;
}
.avatar {
  width: 178px;
  height: 150px;
  display: block;
  margin-left: 120px;
  margin-top: -60px;
}
.deleteIcon {
  position: relative;
  bottom: 225px;
  left: 285px;
}
.deleteIcon :hover {
  color: #409eff;
}
</style>
