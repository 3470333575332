import http from './plugin/ajax'

// =========  合同信息管理  ===========

// 列表
export const getContractList = (data) => http.post('/contract/list', data) // 查询列表
export const addContractItem = (data) => http.post('/contract', data) // 新增
export const editContractItem = (data) => http.put('/contract', data) // 编辑
export const delContractItem = (data) => http.delete('/contract/' + data) // 删除
export const auditContractItem = (data) => http.post(`/contract/audit`, data) // 审核
export const submitContractItem = (data) => http.put('/contract/submit/' + data) // 提交
export const ContractHistoryApi = (data) => http.post('/contract/listHis', data) // 历史
export const getRentList = (data) => http.get('/rent', { params: data }) // 查询合同租金设置
export const getNewBondList = (data) => http.post('/bizPayServiceNew/list', data) // 查询保证金列表
export const setNewBondList = (data) => http.post('/bizPayServiceUse/bzj', data) // 保存保证金列表
export const getContractRentDetail = (data) => http.post('/contractRentDetail/generate/details', data)// 生成明细
export const getContractRentDetailYear = (data) => http.post('/contractRentDetail/generate/details/year', data)// 生成区间明细

//= =============合同档案============
export const getArchivesList = (data) => http.post('/contract/list', data) // 合同档案列表
export const getArchivesHead = () => http.post('/contract/amount') // 头部信息

// 租金设置
export const createRentInterval = (data) => http.post('/contractRentDetail/generate/interval', data) // 生成区间

// 合同变更
export const contractChange = (data) => http.put('/contract/change', data) // 合同变更
export const getContractPreVerDetail = (data) => http.post('/contract/his/getPrevious/' + data) // 变更前合同详情

// =========  空间管理  ===========

export const spaceManageList = (data) => http.post('/spaceTemp/query', data) // 空间列表
export const addSpaceItem = (data) => http.post('/spaceTemp/add', data) // 新增空间
export const delSpaceItem = (data) => http.delete('/spaceTemp/delete/' + data) // 删除空间
export const editSpaceItem = (data) => http.put('/spaceTemp/upData', data) // 更新空间

// =========  仪表管理 ===========
export const getInstrumentManagement = (data) => http.post('/bizIot/list', data) // 仪表管理列表
export const setBindingSpace = (data) => http.get(`/bizIot/bindSpace/${data.spaceId}/${data.id}`) // 绑定租赁单元

// =========合同终止==========
export const getTerminationList = (data) => http.post('/tifContract/list', data)// 获取终止列表
export const addTermination = (data) => http.post('/tifContract/add/contracttermination', data)// 新增合同终止
export const approvalTermination = (data) => http.post('/tifContract/audit', data)// 审批合同终止
export const getTerminationDetails = (data) => http.get(`/tifContract/detail/${data.contractCode}/${data.terminationId}`) // 获取合同信息详情
