<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="45%"
                   @close="close"
                   :before-close="close">
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowForm"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            value: null,
            rules: {},
            rowFormData: [
                { input_type: 'input', key: 'evidenceCode', label: '合同编号', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'billCode', label: '账单编号', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'serviceType', label: '收费项目类型', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'serviceName', label: '收费项目名称', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'fees', label: '收费标准', labelWidth: '130px', col: 11, placeholder: ' ' },
                { input_type: 'input', key: 'createTime', label: '使用时间', labelWidth: '130px', col: 11, placeholder: ' ' },
               ],
            title: '查看收费项使用记录',
            show: false,
            isAdd: false,
            allDisabled: false,
            rowForm: {}
        }
    },
    methods: {
        async init(row) {
            this.rowForm = { ...row }
            this.show = true
            this.isAdd = false
            this.allDisabled = true

        },

    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>