<template>
  <el-dialog :visible.sync="show" width="40%" :title="titleText">
    <ja-form
      :rules="rules"
      allDisabled
      v-model="rowForm"
      :formArray="rowFormData"
      v-if="show"
      :editDataFormat="editDataFormat"
      :valueFormat="formatter"
    >
    </ja-form>
  </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
  components: {
    JaForm
  },
  data() {
    return {
      rules: {},
      rowFormData: [
        {
          input_type: 'input',
          label: '公司名称',
          key: 'tenantName',
          maxlength: 20
        },
        {
          input_type: 'input',
          label: '店铺名称',
          key: 'storeName',
          maxlength: 20,
          disabled: true
        },
        {
          input_type: 'input',
          label: '车牌号码',
          key: 'carNumber',
          maxlength: 20,
          disabled: true
        },
        {
          input_type: 'select',
          label: '服务类型',
          key: 'serviceType',
          disabled: true,
          options: [
            {
              value: 0,
              label: '月卡'
            },
            {
              value: 1,
              label: '季卡'
            },
            {
              value: 2,
              label: '半年卡'
            },
            {
              value: 3,
              label: '年卡'
            }
          ]
        },
        {
          input_type: 'input',
          label: '申请人',
          key: 'applicant',
          maxlength: 20,
          disabled: true
        },
        {
          input_type: 'input',
          label: '申请时间',
          key: 'applicationTime',
          maxlength: 20,
          disabled: true
        },
        {
          input_type: 'input',
          label: '联系方式',
          key: 'contactNumber',
          maxlength: 20,
          disabled: true,
          placeholder: ' '
        },
        {
          input_type: 'input',
          label: '确认时间',
          key: 'confirmationTime',
          maxlength: 20,
          disabled: true
        }
      ],
      show: false,
      titleText: '新增',
      rowForm: {}
    }
  },
  methods: {
    init(state, row) {
      this.show = true
      this.rowForm = { ...row }
      if (state == '1') {
        this.titleText = '查看车位申请'
        this.rowFormData[7].hide = true
      } else {
        this.titleText = '查看车位申请记录'
        this.rowFormData[7].hide = false
      }
    }
  }
}
</script>