<template>
  <router-view/>
</template>

<script>
export default {
  title: "子系统模块",
  name: 'subsystemModule'
}
</script>

<style scoped>

</style>
