
import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

const isDev = process.env.NODE_ENV === 'development'
// /prod-api
const http = axios.create({
    baseURL: isDev ? '/prod-api' : process.env.VUE_APP_ROOT,
    headers: {
        "Content-Type": "application/json;charset=utf8",
        "clientId": 'web',
        "sign": ''
    }
})
let timer = null
http.interceptors.request.use(
    config => {
        const token = store.state.token
        if (token) {
            config.headers.Authorization = token.token_type + ' ' + token.access_token
        } else {
            delete config.headers.Authorization
        }
        const isAuth = config.url.split('/')
        if (isAuth[1] !== 'auth') {
            config.url = '/admin' + config.url
        }
        // 对 get 方法 进行编码
        let url = config.url
        if (config.method === 'get' && config.params) {
            url += '?'
            const keys = Object.keys(config.params)
            for (const key of keys) {
                url += `${key}=${encodeURI(config.params[key])}&`
            }
            url = url.substring(0, url.length - 1)
            config.params = {}
        }
        config.url = url

        return config
    },
    err => {
        return Promise.reject(err)
    })
http.interceptors.response.use(res => {
    if (res.status === 200 && res.data instanceof Blob) {
        return res
    }
    if (res.data.code === 200) {
        return res.data
    } else if (res.data.code === 401 || res.data.code === 403) {
        localStorage.removeItem('token')
        router.replace('/login')
    } else {
        if (res.data.msg && !res.data.msg.includes('被拒绝申请入园')) {
            Message.error(res.data.msg)
        }
        return Promise.reject(res)
    }
}, err => {
    clearTimeout(timer)
    timer = setTimeout(() => {
        if ({ err }.err.response.status === 401) { // 是授权过期
            store.commit('setToken', null)
            localStorage.removeItem('token')
            router.replace('/login')
        } else if ({ err }.err.response.status === 403) { // 没有权限
            store.commit('setToken', null)
            localStorage.removeItem('token')
            router.replace('/login')
        } else {
            Message.error('服务器繁忙')
        }
    }, 100)
    return Promise.reject(err)
})

export default http
