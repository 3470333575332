<template>
  <div class="titleTop">
    <div class="title">
      <span>{{title}}</span><span class="num" v-if="num">{{num}}</span><span v-if="unit">{{unit}}</span>
    </div>
    <div class="subTitle">{{subTitle}}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '默认大标题'
    },
    num:{
      type: String,
      default: '0'
    },
    unit: {
      type: String,
      default: '个'
    },
    subTitle: {
      type: String,
      default: '默认小标题'
    }
  }
}
</script>

<style lang="scss" scoped>
.titleTop{
  text-align: center;
  margin:15px;
  .title{
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }
  .num{
    display: inline-block;
    margin: 0 20px;
    font-size: 30px;
    font-weight: bold;
    color: #409eff;
  }
  .subTitle{
    margin-top: 10px;
    color: #5e6d82;
    font-size: 14px;
  }
}

</style>