<template>
  <!--  普通的表单 -->
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="showEditAll"
    width="50%"
    :title="isAdd ? '新增' : '编辑'"
  >
    <ja-form
      :rules="rules"
      ref="jaForm"
      :label-width="labelWidth"
      v-model="rowForm"
      :formArray="rowFormData"
      :loadingForm="loadingForm"
      @reset="showEditAll = false"
      @save="saveForm"
      v-if="showEditAll"
    >
      <template #msg>
        <div style="marginright: 80px">
          <editor-mce
            :config="Config"
            :value.sync="rowForm.msgContent"
            :Id="Date.now()"
            ref="msg"
            :url="$uploadUrl + '/oss/upload'"
          ></editor-mce>
        </div>
      </template>
      <template #sendTime>
        <div class="sendTime">
          <el-form-item
            prop="sendDate"
            :rules="rules.sendDate"
            v-if="rowForm.sendTimeType == 1"
          >
            <el-date-picker
              v-model="rowForm.sendDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="{ disabledDate: disabledDateFun }"
              style="margin-left: 20px"
              @change="dateChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            prop="sendTime"
            :rules="rules.sendTime"
            v-if="rowForm.sendTimeType == 1"
          >
            <el-time-picker
              placeholder="开始时间"
              v-model="rowForm.sendTime"
              value-format="HH:mm:ss"
              format="HH:mm"
              class="time"
              style="margin-left: 10px"
              :picker-options="{ selectableRange: selectableRange }"
              @change="timeChange"
            ></el-time-picker>
          </el-form-item>
        </div>
      </template>
    </ja-form>
  </el-dialog>
</template>
<script>
import editorMce from '@/components/editorMce'
import { addMsgTask, editMsgTask, rqMsgGroup } from '@/api'

export default {
  inject: ['labelWidth', 'getInit'],
  components: {
    editorMce
  },
  props: {
    declarationList: Array
  },
  data() {
    return {
      Config: { height: 300 },
      rowFormData: [
        { input_type: 'input', label: '任务名称', key: 'taskName' },
        {
          input_type: 'select',
          label: '消息类型',
          key: 'msgType',
          options: []
        },
        {
          input_type: 'checkbox',
          label: '接收多端(多选)',
          key: 'receivingType',
          options: []
        },
        {
          input_type: 'select',
          label: '通知组(多选)',
          key: 'groupIds',
          options: [],
          multiple: true,
          collapseTags: true
        },
        {
          input_type: 'radio',
          label: '发送时间',
          key: 'sendTimeType',
          options: [
            { label: '实时', value: 0 },
            { label: '定时', value: 1 }
          ],
          isFlex: true,
          col: 22,
          rightBox: { input_type: 'customize', customize: 'sendTime' }
        },

        {
          input_type: 'customize',
          customize: 'msg',
          label: '消息内容',
          key: 'msgContent',
          col: 24
        },
        {
          input_type: 'input',
          label: '备注',
          key: 'remark',
          type: 'textarea',
          col: 22,
          rows: 1,
          maxlength:100

        }
      ],
      rowForm: {},
      rules: {
        sendTime: [
          { required: true, message: '请选择正确时间', trigger: 'change' }
        ],
        sendDate: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        taskName: [
          { required: true, message: '请输入任务名称', trigger: 'blur' }
        ],
        receivingType: [
          { required: true, message: '请勾选接收方式', trigger: 'change' }
        ],
        msgType: [
          { required: true, message: '请选择消息类型', trigger: 'change' }
        ],
        groupIds: [
          { required: true, message: '请选择通知组', trigger: 'change' }
        ],
        // eventId: [{ required: true, message: '请输入关联事件号', trigger: 'blur' }],
        sendTimeType:[
          {required: true,message: '请选择发送时间', trigger: 'change'}
          ],
        msgContent: [
          { required: true, message: '请输入消息内容', trigger: 'change' }
        ]
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {},
      selectableRange: '00:00:00 - 23:59:59'
    }
  },
  watch: {
    'rowForm.msgContent': {
      handler: function () {
        if (this.$refs.jaForm) {
          this.$refs.jaForm.$refs.formbox.validateField('msgContent')
        }
      }
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData })
    this.util.rqOptionsList({
      who: [this.rowFormData[this.indexObj.groupIds]],
      httpFun: rqMsgGroup,
      resRule: 'data',
      valueName: 'id',
      labelName: 'groupName'
    })
    this.util.rqOptionsList({
      who: [this.rowFormData[this.indexObj.msgType]],
      rqUrl: '/sys/dictData/task_msg_type',
      resRule: 'data'
    })
  },
  methods: {
    append() {
      this.loadingForm = false
      this.isAdd = true
      this.rowForm = { msgContent: '', receivingType: [], groupIds: [] }
      this.showEditAll = true
    },
    rowEdit(row) {
      console.log('row---', row)
      this.isAdd = false
      this.titleText = '编辑'
      this.showEditAll = true
      this.rowForm = {
        ...row,
        receivingType: row.receivingType.split(','),
        groupIds: row.groupIds.split(',').map((v) => Number(v))
      }
    },
    // saveForm() {
    //   this.loadingForm = true
    //   let rqMethod = this.isAdd ? addMsgTask : editMsgTask
    //   const rqObj = {
    //     ...this.rowForm,
    //     sendTime:`${this.rowForm.sendDate} ${this.rowForm.sendTime}`,
    //     groupIds: this.rowForm.groupIds.join(','),
    //     receivingType: this.rowForm.receivingType.join(',')
    //   }
    //   rqMethod(rqObj).then(res => {
    //     this.loadingForm = false
    //     this.$message.success('提交成功')
    //     this.showEditAll = false
    //     this.getInit()
    //   }).catch(error => {
    //     this.loadingForm = false
    //   })
    // },
    saveForm() {
      this.loadingForm = true
      let rqMethod = this.isAdd ? addMsgTask : editMsgTask
      const rqObj = {
        ...this.rowForm,
        sendTime: `${this.rowForm.sendDate} ${this.rowForm.sendTime}`,
        groupIds: this.rowForm.groupIds.join(','),
        receivingType: this.rowForm.receivingType.join(',')
      }
      if(this.rowForm.sendTimeType == 1){
        console.log(1111);
        // this.realtime()
            const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      let nowDay_day = nowDate.split(' ')[0] //当前的日期
      let nowDay_time = nowDate.split(' ')[1] //现在的时间，时分秒
      const activeTime = Number(this.rowForm.sendTime.replace(/:/gi, ''))
      const nowTime = Number(nowDay_time.replace(/:/gi, ''))
      if (this.rowForm.sendDate===nowDay_day&&activeTime > nowTime||this.rowForm.sendDate!=nowDay_day) {
           rqMethod(rqObj).then((res) => {
          this.loadingForm = false
          this.$message.success('提交成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
        this.loadingForm = false
      })            
      } else {
        this.loadingForm = false
        this.$message.error('时间错误，请重新选择时间')
      }
      }else{
          rqMethod(rqObj).then((res) => {
          this.loadingForm = false
          this.$message.success('提交成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
        this.loadingForm = false
      })

      }

      // const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      // let nowDay_day = nowDate.split(' ')[0] //当前的日期
      // let nowDay_time = nowDate.split(' ')[1] //现在的时间，时分秒
      // const activeTime = Number(this.rowForm.sendTime.replace(/:/gi, ''))
      // const nowTime = Number(nowDay_time.replace(/:/gi, ''))
      // // if(this.rowForm.sendDate===nowDay_day){
      // //   if(nowTime > activeTime){
      // //      this.loadingForm = false
      // //     this.$message.error('时间错误，请重新选择时间')
      // //   }

      // // }else{
      // //    rqMethod(rqObj).then((res) => {
      // //     this.loadingForm = false
      // //     this.$message.success('提交成功')
      // //     this.showEditAll = false
      // //     this.getInit()
      // //   })
      // // }

      // if (this.rowForm.sendDate===nowDay_day&&activeTime > nowTime||this.rowForm.sendDate!=nowDay_day) {
      //      rqMethod(rqObj).then((res) => {
      //     this.loadingForm = false
      //     this.$message.success('提交成功')
      //     this.showEditAll = false
      //     this.getInit()
      //   })            
      // } else {
      //   this.loadingForm = false
      //   this.$message.error('时间错误，请重新选择时间')
      // }
    },
    // realtime(){
    //    const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    //   let nowDay_day = nowDate.split(' ')[0] //当前的日期
    //   let nowDay_time = nowDate.split(' ')[1] //现在的时间，时分秒
    //   const activeTime = Number(this.rowForm.sendTime.replace(/:/gi, ''))
    //   const nowTime = Number(nowDay_time.replace(/:/gi, ''))
    //   if (this.rowForm.sendDate===nowDay_day&&activeTime > nowTime||this.rowForm.sendDate!=nowDay_day) {
    //        rqMethod(rqObj).then((res) => {
    //       this.loadingForm = false
    //       this.$message.success('提交成功')
    //       this.showEditAll = false
    //       this.getInit()
    //     })            
    //   } else {
    //     this.loadingForm = false
    //     this.$message.error('时间错误，请重新选择时间')
    //   }

    // },
    // 富文本的校验
    editorCheck(rule, value, callback) {
      console.log('校验---》', rule, value)
      if (rule.required) {
        if (
          value === undefined ||
          value === '' ||
          value === null ||
          (!value && value != 0)
        ) {
          // 如果为空
          return callback(new Error('请完善消息内容'))
        }
      }
    },
    disabledDateFun(time) {
      return time.getTime() < Date.now() - 24 * 3600 * 1000 // 当天之后的时间可选
    },
    dateChange() {
      this.pickerOptions_Time()
    },
    timeChange() {
      // console.log(this.rowForm.sendTime)
      // console.log(this.rowForm.sendDate);
      const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      let nowDay_day = nowDate.split(' ')[0] //当前的日期
      let nowDay_time = nowDate.split(' ')[1] //当前的时间，时分秒
      const activeTime = Number(this.rowForm.sendTime.replace(/:/gi, ''))
      const nowTime = Number(nowDay_time.replace(/:/gi, ''))
      if(this.rowForm.sendDate===nowDay_day){
         if (nowTime > activeTime) {
          this.$set(this.rowForm, 'sendTime', '')
        }
      }
      // if (this.rowForm.sendTime) {
      //   // 选择的 time
      //   const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      //   let nowDay_time = nowDate.split(' ')[1] //现在的时间，时分秒
      //   const activeTime = Number(this.rowForm.sendTime.replace(/:/gi, ''))
      //   console.log(activeTime)
      //   const nowTime = Number(nowDay_time.replace(/:/gi, ''))
      //   console.log(nowTime)
      //   if (nowTime > activeTime) {
      //     this.$set(this.rowForm, 'sendTime', '')
      //   }
      // }
    },
    pickerOptions_Time() {
      if (this.rowForm.sendDate) {
        const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        console.log(nowDate) //当前时间格式化之后
        let nowDay_day = nowDate.split(' ')[0]
        console.log(nowDay_day)
        let nowDay_time = nowDate.split(' ')[1] //现在的时间，时分秒
        let isToday = nowDay_day === this.rowForm.sendDate
        if (isToday) {
          this.selectableRange = `${nowDate.split(' ')[1]} - 23:59:59`
        } else {
          this.selectableRange = '00:00:00 - 23:59:59'

        }
      }
      return `00:00:00 - 23:59:59`
    }
  }
}
</script>
<style lang="scss" scoped>
.declaration {
  display: flex;
  align-items: center;
  > li {
    margin-right: 30px;
  }
}
.sendTime {
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>