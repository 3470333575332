<template>
    <div class="allwh">
        <ja-table
            ref="tst"
            v-model="filterData"
            :conditionData="conditionData"
            :columns="columns"
            :tableData="tableData"
            :total="total"
            :loading="loading"
            :showIndex="true"
            @getInit="getInit"
            :valueFormat="formatter"
            @search="search"
            @reset="reset"
            >
            <template #tableTop>
                <div class="topNav" v-if="tenantName && tenantCode">
                    <span>驻户名称：{{tenantName}}</span>
                    <span>驻户编号：{{tenantCode}}</span>
                </div>
            </template>
        </ja-table> 
    </div>
</template>
<script>
import table from "@/views/mixins/table"
import mixinsData from '../common/mixinsData'
import {rqTenantTable} from '@/api'

export default {
    title: '驻户抄表',
    mixins: [table,mixinsData],
    name: 'index',
    data () {
        return {
            conditionData:[
                { label:'抄表日期',input_type: 'date',type: 'daterange',value: 'time',format:'yyyy-MM-dd',valueFormat:'yyyy-MM-dd', startPlaceholder: '开始日期',endPlaceholder: '结束日期',attribute: {unlinkPanels:true}},
                { input_type: 'select',key: 'deviceCategory',attribute: {placeholder: '仪表类型',size: 'small'},options: [],styleWidth:'100px'},
                { input_type: 'select',key: 'buildingId',attribute: {placeholder: '楼栋',size: 'small'},options: [],styleWidth:'100px',
                    relevance:{ sonKey:'floorId', This:this, rqParam:{ rqUrl:"/floor/all",rqData:()=>{return {params:{ buildingId: this.filterData.buildingId } }},labelName:'floorName',valueName:'floorId',resRule:'data'},}
                },
                { input_type: 'select',key: 'floorId',attribute: {placeholder: '楼层',size: 'small'},options: [],styleWidth:'100px'},
                { input_type: 'input',key: 'deviceCode',attribute: {placeholder: '仪表编码',size: 'small',},styleWidth:'100px'},

                { normalBtn:'search',},
                { normalBtn:'reset'},
            ],
            columns:[
                { label: '楼栋', prop: 'buildingName',  },
                { label: '楼层', prop: 'floorName',  },
                { label: '房间', prop: 'roomName' },
                { label: '仪表类型', prop: 'deviceCategory' },
                { label: '仪表编码', prop: 'deviceCode'   },
                { label: '抄表时间', prop: 'recordTime',width:150,attribute:{showOverflowTooltip:true,align:'center'} },
                { label: '来源', prop: 'source' },
                { label: '读数', prop: 'data' },
            ],
            tenantName:'',
            tenantCode:'',
            tenantId:-1,
        }
    },
    created() {
        this.util.rqOptionsList({ who: this.conditionData[2], rqUrl: "/building/list", rqData: { pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'buildingName', valueName: 'id' })
        this.util.rqOptionsList({who:[this.conditionData[1]],rqUrl:"/device/category/linkList",resRule:'data.data',labelName:'categoryName',valueName:'id',rqData:{params:{pageIndex:1,pageSize:200}}}) // 关联仪表

    },
    methods:{
        // 要请求的参数
        rqParmas(){
            return {
                pageSize: this.filterData.pageSize,
                pageIndex: this.filterData.pageIndex,
                buildingId:this.filterData.buildingId,
                floorId:this.filterData.floorId,
                deviceCategory: this.filterData.deviceCategory,
                deviceCode:this.filterData.deviceCode,
                tenantId : this.tenantId,
                startTime: this.filterData.time?this.filterData.time[0]:'',
                endTime: this.filterData.time?this.filterData.time[1]:'',
            }
        },
        formatter(val){
            return val
        },
        getInit() {
            this.loading = true
            let data =this.rqParmas()
            if(data.tenantId == -1){
                return
            }
            rqTenantTable(data).then(res => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.topNav{
    padding: 8px 0 15px;
    span{
        font-size: 16px;
        color: #606266;
        margin-right: 40px; 
    }
}
</style>
