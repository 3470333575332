<template>
  <div class="page">
    <year-analysis class="boxCommon firstBox" />
    <month-electricity class="boxCommon boxCommon2" />
    <div class="middle">
      <device-electricity class="box"/>
      <cold-analysis class="box"/>
    </div>
  </div>
</template>

<script>
import YearAnalysis from './childComp/YearAnalysis.vue'
import MonthElectricity from './childComp/MonthElectricity.vue'
import DeviceElectricity from './childComp/DeviceElectricity.vue'
import ColdAnalysis from './childComp/ColdAnalysis.vue'



export default {
  title: "能耗分析",
  components: {
    YearAnalysis, MonthElectricity, DeviceElectricity, ColdAnalysis
  }
}
</script>

<style lang="scss" scoped>
.boxCommon {
  padding: 15px 0 15px 0;
  border-radius: 20px;
  background: white;
  &.boxCommon2 {
    margin: 15px 0;
  }
}
.page {
  margin: 15px;
  .firstBox {
    width: 100%;
  }
  .middle {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    .box {
      width: calc((100% - 20px) / 2);
      @extend .boxCommon;
      height: 480px;
    }
  }
}
</style>
