<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag
               :close-on-click-modal="false"
               :visible.sync="show"
               width="50%"
               title="放行条申请审批">
        <ja-form :rules="rules"
                 ref="jaForm"
                 :label-width="labelWidth"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :loadingForm="loadingForm"
                 :btnBox="btnBox"
                 @reject="reject"
                 @adopt="adopt">
            <template #imgList>
                <div class="img-box">
                    <div v-for="(item,index) in rowForm.imgUrls"
                         :key="index"
                         class="img-item">
                        <el-image :src="item"
                                  :preview-src-list="rowForm.imgUrls"></el-image>
                    </div>
                </div>
            </template>
        </ja-form>
    </el-dialog>
</template>
<script>
import { approveTransire } from '@/api'
export default {
    inject: ['labelWidth', 'getInit',],
    data() {
        return {
            rowFormData: [
                { input_type: 'input', label: '店铺名称', key: 'applyDeptName', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '申请人', key: 'applyEmployeeName', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '申请人联系方式', key: 'applyEmployeePhone', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '放行条类型', key: 'typeName', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '放行时间', key: 'transitTime', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '物品搬运人', key: 'transireEmployeeName', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '身份证号码', key: 'transireEmployeeIdCard', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '搬运人联系方式', key: 'transireEmployeePhone', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', type: 'textarea', label: '放行申请原因', key: 'applyDescription', col: 24, disabled: true, placeholder: ' ' },
                { input_type: 'customize', customize: 'imgList', label: '图片', col: 24 },
                { input_type: 'input', label: '提交时间', key: 'createTime', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '审批状态', key: 'statusName', col: 12, disabled: true, placeholder: ' ' },

            ],
            rowForm: { feedbackType: 0 },
            rules: {},
            btnBox: [
                { label: '驳回', method: 'reject', col: 8 },
                { label: '通过', method: 'adopt', type: 'primary' }
            ],
            loadingForm: false,
            show: false,
            dialogImageUrl: '',
            dialogVisible: false,
        }
    },
    methods: {
        rowEdit(row) {
            this.rowForm = { ...row }
            this.show = true
        },
        adopt() {
            this.$confirm('是否确定通过？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await approveTransire({ ...this.rowForm, status: 1 })
                this.show = false
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        reject() {
            this.$confirm('是否确定驳回？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await approveTransire({ ...this.rowForm, status: 2 })
                this.show = false
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
.img-box {
    display: flex;
    justify-content: space-between;
}
.img-item {
    height: 150px;
    width: 150px;
    .el-image {
        width: 100%;
        height: 100%;
    }
}
</style>