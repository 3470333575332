<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag
               :close-on-click-modal="false"
               :visible.sync="show"
               width="50%"
               title="回复">
        <ja-form :rules="rules"
                 ref="jaForm"
                 :label-width="labelWidth"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :loadingForm="loadingForm"
                 :btnBox="btnBox"
                 :allDisabled="allDisabled"
                 @reset="show = false"
                 @save="saveForm">

        </ja-form>
    </el-dialog>
</template>
<script>
import editorMce from '@/components/editorMce'
import { addFeedbackContent } from '@/api'

export default {
    inject: ['labelWidth', 'getInit',],
    components: {
        editorMce
    },
    data() {
        return {
            rowFormData: [
                { input_type: 'input', label: '事件编号', key: 'complaintNumber', col: 11, disabled: true },
                { input_type: 'input', label: '反馈人', key: 'introducerEmployeeName', col: 11, disabled: true },
                { input_type: 'input', label: '反馈人联系方式', key: 'introducerEmployeePhone', col: 11, disabled: true },
                { input_type: 'input', label: '建议反馈方向', key: 'feedbackDirectionName', col: 11, disabled: true },
                { input_type: 'input', label: '建议反馈描述', type: 'textarea', key: 'complaintsContent', col: 24, disabled: true },
                { input_type: 'input', label: '提交时间', key: 'complaintsTime', col: 11, disabled: true },
                { input_type: 'input', label: '回复状态', key: 'statusValue', col: 11, disabled: true },
                { input_type: 'radio', label: '回复方式', key: 'replyWay', col: 11, options: [{ label: '回复', value: 1 }, { label: '致电', value: 2 }, { label: '现场', value: 0 }] },
                { input_type: 'input', label: '回复', type: 'textarea', key: 'feedbackContent', col: 24 },

            ],
            rowForm: {},
            rules: {
                replyWay: [{ required: true, trigger: 'blur' }],
                feedbackContent: [{ required: true, message: '请完善回复内容', trigger: 'blur' }],
            },
            btnBox: [
                { label: '取消', method: 'reset', col: 8, limit: '' },
                { label: '确认', method: 'save', type: 'primary' }
            ],
            loadingForm: false,
            show: false,
            allDisabled: false
        }
    },
    computed: {
        feedbackContentLength: function () {
            return this.rowFormData.findIndex(item => item.key == 'feedbackContent')
        }
    },
    watch: {
        'rowForm.replyWay': {
            handler: function (newVal, oldVal) {
                if (newVal != '1') {
                    this.$set(this.rowFormData[this.feedbackContentLength], 'hide', true)
                } else {
                    this.$set(this.rowFormData[this.feedbackContentLength], 'hide', false)
                }
            }
        }
    },
    methods: {
        rowEdit(row) {
            this.rowForm = { ...row, replyWay: 1 }
            this.show = true
        },
        saveForm() {
            this.loadingForm = true
            addFeedbackContent(this.rowForm).then(res => {
                this.loadingForm = false
                this.$message.success('建议反馈回复成功')
                this.show = false
                this.getInit()
            }).catch(error => {
                this.loadingForm = false
            })
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
</style>