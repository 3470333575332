<template>
  <!--  普通的表单 -->
  <div>
    <el-dialog v-dialogDrag :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="55%"
      title="变更内容"
    >

        <!-- 按钮组 -->
        <div class="btn_group">
          <el-link type="primary" @click="toBefore" style="margin-right: 10px">查看变更前合同</el-link>
          <el-link type="primary" @click="toAfter">查看变更后合同</el-link>
        </div>
        <!-- 变更项 -->
        <div v-for="(item, index) in changDatas.changeList" :key="index">
          <div class="changbox">
            <div class="title">变更项{{index + 1}}：{{ item.changeName }}</div>
            <div class="before">变更前：{{ item.changeName == '违约金比例' ? item.changeBefore + '%' : item.changeBefore }}</div>
            <div class="after">变更后：{{ item.changeName == '违约金比例' ? item.changeAfter + '%': item.changeAfter }}</div>
          </div>
        </div>
        <div v-if="!changDatas.changeList">
          <div style="font-weight: bold">无变更</div>
        </div>

    </el-dialog>
    <!-- 查看变更后合同 -->
    <detail ref="detail"/>
  </div>
</template>
<script>
import { getContractChangeDetail } from '@/api'
import Detail from '../../contractManage/childComp/Detail'
import { getContractPreVerDetail } from '@/api'
export default {
  inject: ['getInit'],
  components: {
    Detail
    },
  data() {
    return {
      showEditAll: false,
      changDatas: [],
      rowData: {}
    }
  },

  methods: {
    append(row) {
      this.rowData = row
      this.changDatas = []
      getContractChangeDetail(row.id).then((res) => {
        if (res.code === 200) {
          this.changDatas = res.data
          if(this.changDatas.changeList.length == 0) this.changDatas.changeList = undefined
        }
        this.showEditAll = true
      })
    },
    // 查看变更前
    toBefore(){
      // 获取上一个版本的合同详情
      getContractPreVerDetail(this.rowData.contractId).then(res => {
        if(res.code == 200){
          let obj = res.data.data[0]
          obj.contractId = this.rowData.contractId
          this.$refs.detail.rowDetail(obj)
        }
      })
    },
    // 查看变更后
    toAfter(){
      this.rowData.contractTypeName = this.rowData.contractTypeLabel
      this.$refs.detail.rowDetail(this.rowData,true)
    }
  }
}
</script>
<style lang="scss">
.btn_group {
  display: flex;
  justify-content: right;
}

.changbox {
  margin-top: 20px;
  margin-bottom: 20px;

  .title {
    font-weight: bold;
    padding-bottom: 8px;
  }

  .before {
    color: #7f7f7f;
    padding: 8px 0;
  }

  .after {
    color: #d9001b;
    padding: 6px 0;
  }
}
</style>
