<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="50%"
                   >
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowFormData"
                     :formArray="rowForm"
                     :allDisabled="allDisabled"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show"
                     v-loading="addLoading">
                <template #storeInformation>
                    <store-information ref="shopFrom"
                                       :shopDTOList="rowFormData.shopDTOList"
                                       :state="state"
                                       :tenantId="tenantId"
                                       :allDisabled="shopFromAllDisabled"></store-information>
                </template>
                <template #tooltip>
                    <el-tooltip placement="bottom"
                                v-show="isAdd">
                        <div slot="content">
                            待该驻户相关合同提交后再继续完善店铺信息
                        </div>
                        <el-button type="text"
                                   icon="el-icon-warning"
                                   size=""></el-button>
                    </el-tooltip>
                </template>
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import StoreInformation from './storeInformation'
import { addTenant, editTenant } from '@/api'
export default {
    inject: ['getInit'],
    components: {
        JaForm,
        StoreInformation
    },
    data() {
        return {
            rules: {
                tenantName: [{ required: true, trigger: 'blur' }],
                category: [{ required: true, trigger: 'change', message: '企业类型不能为空' }],
                linkMan: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText({ textMessage: '负责人姓名' }) }],
                licenseNum: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkBusinessLicense() }],
                contactNumber: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkPhone() }],
                idCard: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkIdCard() }]
            },
            rowForm: [
                { input_type: 'title', text: '驻户信息', col: 24, key: 'title1', noMarginBottom: true },
                { col: 11, input_type: 'input', label: '驻户名称', key: 'tenantName', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'select', label: '企业类型', key: 'category', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'input', label: '营业执照号码', key: 'licenseNum', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'input', label: '负责人姓名', key: 'linkMan', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'input', label: '联系电话', key: 'contactNumber', labelWidth: '120px', maxlength: 30 },
                { col: 11, input_type: 'input', label: '负责人身份证号', key: 'idCard', labelWidth: '120px', maxlength: 30 },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', col: 22, maxlength: 200, labelWidth: '120px', },
                { input_type: 'title', text: '店铺信息', col: 24, key: 'title2', noMarginBottom: true, customizeTitle: 'tooltip' },
                { input_type: 'customize', customize: 'storeInformation', col: 24, key: 'shopFrom', hide: true }
            ],
            rowFormData: {},
            title: '新增驻户信息',
            show: false,
            isAdd: false,
            allDisabled: false,
            addLoading: false,
            state: null,
            shopFromAllDisabled: true,
            tenantId: null
        }
    },
    mounted() {
        let length = this.rowForm.findIndex((e) => e.key === 'category')
        this.util.rqOptionsList({
            who: [this.rowForm[length]],
            rqUrl: '/sys/dictData/tenant_category',
            resRule: 'data',
            rqMethod: 'get',
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        init(state, row) {
            let hideLength = this.rowForm.findIndex(item => item.key === "shopFrom")
            this.$set(this.rowForm[hideLength], 'hide', true)
            this.state = state
            this.show = true
            this.rowFormData = { shopDTOList: [] }
            this.allDisabled = false
            this.isAdd = true
            if (state == 2) {
                this.$set(this.rowForm[hideLength], 'hide', false)
                this.isAdd = false
                this.title = '编辑驻户信息'
                let {
                    tenantName,
                    category,
                    linkMan,
                    licenseNum,
                    idCard,
                    contactNumber,
                    remark,
                    id,
                    shopDTOList
                } = row
                this.rowFormData = {
                    tenantName: tenantName,
                    category: category,
                    linkMan: linkMan,
                    licenseNum: licenseNum,
                    idCard: idCard,
                    contactNumber: contactNumber,
                    remark: remark,
                    id: id,
                    shopDTOList: shopDTOList.length > 0 ? shopDTOList : []
                }
                this.tenantId = id
            } else if (state == 3) {
                this.$set(this.rowForm[hideLength], 'hide', false)
                this.isAdd = false
                this.title = '查看驻户信息'
                this.allDisabled = true
                this.rowFormData = { ...row }
                this.tenantId = row.id
            }
            console.log(this.rowFormData);
        },
        saveForm() {
            this.addLoading = true
            if (this.isAdd) {
                addTenant(this.rowFormData)
                    .then((res) => {
                        this.show = false
                        this.addLoading = false
                        this.getInit()
                    })
                    .catch((res) => {
                        this.show = false
                        this.addLoading = false
                    })
            } else {
                editTenant(this.rowFormData)
                    .then((res) => {
                        this.show = false
                        this.addLoading = false
                        this.getInit()
                    })
                    .catch((res) => {
                        this.show = false
                        this.addLoading = false
                    })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.formItemText {
    margin-bottom: 15px !important;
    background-color: #ebffff;
    border-bottom: 1px solid rgb(217, 217, 217);
    padding-left: 10px;
}
/deep/.el-icon-warning {
    color: black;
    font-size: 0.933vw;
    margin-left: 20px;
}
</style>
