<template>
    <div>
        <el-table :data="tableList"
                  border
                  show-summary
                  :show-header="false"
                  :span-method="arraySpanMethod"
                  header-row-class-name="newTable"
                  sum-text="合同一次性费用小计"
                  style="width: 100%">
            <el-table-column prop="date"
                             label=""
                             align="center"
                             width="180">
            </el-table-column>
            <el-table-column prop="name"
                             label=""
                             width="180">
            </el-table-column>
            <el-table-column prop="address"
                             label="">
            </el-table-column>
            <el-table-column prop="A"
                             align="center"
                             label="应收款">
            </el-table-column>
            <el-table-column prop="B"
                             align="center"
                             label="抵优惠券">
            </el-table-column>
            <el-table-column prop="D"
                             align="center"
                             label="未收款">
            </el-table-column>
        </el-table>

        <!-- 总计 -->
        <el-table :data="totalList"
                  border
                  :show-header="false"
                  :span-method="arraySpanMethodTotal"
                  cell-class-name="totalTable"
                  style="width: 100%">
            <el-table-column prop="name"
                             width="180">
            </el-table-column>
            <el-table-column prop=""
                             width="180">
            </el-table-column>
            <el-table-column prop="">
            </el-table-column>
            <el-table-column prop="A"
                             align="center">
            </el-table-column>
            <el-table-column prop=""
                             align="center">
            </el-table-column>
            <el-table-column prop="total"
                             align="center"
                             label="未收款">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        tableList: {
            type: Array,
            default: () => {}
        },
        totalList: {
            type: Array,
            default: () => {}
        }
    },
    methods: {
        arraySpanMethod ({ columnIndex }) {
            if (columnIndex === 0) {
                return [1, 3]
            } else if (columnIndex === 1) {
                return [0, 0]
            } else if (columnIndex === 2) {
                return [0, 0]
            }
        },
        arraySpanMethodTotal ({ columnIndex }) {
            if (columnIndex === 0) {
                return [1, 3]
            } else if (columnIndex === 1) {
                return [0, 0]
            } else if (columnIndex === 2) {
                return [0, 0]
            }
            if (columnIndex == 3) {
                return [1, 2]
            } else if (columnIndex == 4) {
                return [0, 0]
            }
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-table--border,
.el-table--group {
    border-top: 0;
}
::v-deep .newTable {
    font-size: 16px;
    font-weight: 800;
    .el-table__cell {
        border-color: #0a0a0a !important;
    }
}
::v-deep .totalTable {
    font-size: 16px;
    font-weight: 800;
    background-color: #f5f7fa;
    .el-table__cell {
        border-color: #0a0a0a !important;
    }
}
::v-deep .el-table__footer-wrapper {
    font-size: 16px;
    font-weight: 800;
}
::v-deep .el-table {
    border-color: #080808;
}
::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
    border-color: #0e0e0f !important;
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
    content: '';
    position: absolute;
    background-color: #0e0e0f;
    z-index: 1;
}
::v-deep .el-table::before {
    content: '';
    position: absolute;
    z-index: 1;
}
</style>
