<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :modal="false"
                   :visible.sync="show"
                   width="55%"
                   :title="!allDisabled?'费用配置':'查看费用配置'">
            <ja-form ref="customFrom"
                     :rules="rules"
                     v-model="rowForm"
                     v-loading="loading"
                     :formArray="rowFormData"
                     :allDisabled="allDisabled"
                     labelWidth="11vw"
                     @reset="reset"
                     @save="saveForm"
                     @deleteTabelRow="deleteTabelRow"
                     @generateDetailsClick="generateDetailsClick"
                     @generateIntervalClick="generateIntervalClick"
                     @selectServiceName="selectServiceName"
                     v-if="show">

                <template #addTabel>
                    <el-button type="text"
                               v-show="!allDisabled"
                               size="mini"
                               style="float:right"
                               @click="addTabel">添加一项</el-button>
                </template>
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { getRentList, getChargingSettings, getContractRentDetail, createRentInterval, getContractRentDetailYear } from '@/api'
import deepCopy from '@/util/deepCopy.js'
export default {
    props: {
        allDisabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            rowForm: { payServiceList: [] },
            rules: {
                billingArea: [{ required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }],
                unitPrice: [{ required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }],
                initRent: [{ required: true, trigger: 'change', message: '不能为空' }],
                incrementType: [{ required: true, trigger: 'blur', message: '请选择递增方式' }],
                incrementTime: [{ required: true, trigger: 'blur', message: '首次递增日期不能为空' }]
            },
            rowFormData: [],
            rentClo: [
                { input_type: 'title', text: '基本信息', col: 24 },
                { input_type: 'input', label: '租赁单元名称', key: 'roomName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '租赁单元类型', key: 'roomTypeValue', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '所属楼栋', key: 'buildingName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '所属楼层', key: 'floorName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '套内总体使用面积', key: 'totalArea', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'title', text: '租金配置', col: 24 },
                { input_type: 'input', label: '计租面积（㎡）', key: 'billingArea', col: 12, styleWidth: '80%' },
                { input_type: 'input', label: '租金单价（元）', key: 'unitPrice', col: 12, styleWidth: '80%' },
                { input_type: 'input', label: '租金（元/月）', key: 'initRent', col: 12, styleWidth: '80%', disabled: true, placeholder: '输入单价自动计算租金' },
                { input_type: 'input', label: '计租开始时间', key: 'billingTime', col: 12, styleWidth: '80%', disabled: true },
                {
                    input_type: 'select',
                    label: '递增方式',
                    key: 'incrementType',
                    styleWidth: '80%',
                    options: [
                        {
                            label: '不递增',
                            value: '0'
                        },
                        {
                            label: '年递增',
                            value: '1'
                        }
                    ],
                    col: 12,
                    placeholder: '请选择'
                },
                { input_type: 'date', label: '首次递增日期', key: 'incrementTime', hide: true, pickerOptions: { disabledDate: this.disabledDateFun }, valueFormat: 'yyyy-MM-dd', col: 12, styleWidth: '80%' },
                { label: '', col: 2 },
                { label: '', rightBoxItem: { input_type: 'button', text: '生成区间', method: 'generateIntervalClick' }, col: 2.5, hide: true, bukey: 2 },
                { label: '', rightBoxItem: { input_type: 'button', text: '生成明细', method: 'generateDetailsClick' }, col: 2.5, hide: true, bukey: 1 },
                { input_type: 'title', text: '租金递增区间', col: 24, key: 'intervaled', hide: false },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'intervaledList',
                    isRequired: true,
                    border: true,
                    table_MaxHeight: '500',
                    hide: false,
                    columns: [
                        { label: '区间开始日期', prop: 'startTime' },
                        { label: '区间结束日期', prop: 'endTime' },
                        { column_type: 'number', label: '租金（元/月）', prop: 'adjustRent', min: 0 }
                    ]
                },
                { input_type: 'title', text: '每月租金明细', col: 24, key: 'detailed', hide: false },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'detailedList',
                    isRequired: true,
                    border: true,
                    table_MaxHeight: '500',
                    hide: false,
                    columns: [
                        { label: '账单周期', prop: 'date' },
                        { label: '租金（元/月）', prop: 'rent' },
                        { column_type: 'number', label: '调整租金（元/月）', prop: 'adjustRent', min: 0 }

                    ]
                }

            ],
            matterClo: [
                { input_type: 'title', text: '基本信息', col: 24 },
                { input_type: 'input', label: '租赁单元名称', key: 'roomName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '租赁单元类型', key: 'roomTypeValue', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '所属楼栋', key: 'buildingName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '所属楼层', key: 'floorName', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'input', label: '套内总体使用面积', key: 'totalArea', col: 12, styleWidth: '80%', maxlength: 30, disabled: true },
                { input_type: 'title', text: '物管配置', col: 24 },
                { input_type: 'input', label: '计租面积（㎡）', key: 'billingArea', col: 12, styleWidth: '80%' },
                { input_type: 'input', label: '管理费单价（元）', key: 'unitPrice', col: 12, styleWidth: '80%' },
                { input_type: 'input', label: '物业管理费（元/月）', key: 'initRent', col: 12, styleWidth: '80%', disabled: true, placeholder: '输入单价自动计算物业管理费' },
                { input_type: 'input', label: '计租开始时间', key: 'billingTime', col: 12, styleWidth: '80%', disabled: true },
                {
                    input_type: 'select',
                    label: '递增方式',
                    key: 'incrementType',
                    styleWidth: '80%',
                    options: [
                        {
                            label: '不递增',
                            value: '0'
                        },
                        {
                            label: '年递增',
                            value: '1'
                        }
                    ],
                    col: 12,
                    placeholder: '请选择'
                },
                { input_type: 'date', label: '首次递增日期', key: 'incrementTime', hide: true, pickerOptions: { disabledDate: this.disabledDateFun }, valueFormat: 'yyyy-MM-dd', col: 12, styleWidth: '80%' },
                { label: '', col: 2 },
                { label: '', rightBoxItem: { input_type: 'button', text: '生成区间', method: 'generateIntervalClick' }, col: 2.5, hide: true, bukey: 2 },
                { label: '', rightBoxItem: { input_type: 'button', text: '生成明细', method: 'generateDetailsClick' }, col: 2.5, hide: true, bukey: 1 },
                { input_type: 'title', text: '物管递增区间', col: 24, key: 'intervaled', hide: false },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'intervaledList',
                    isRequired: true,
                    border: true,
                    table_MaxHeight: '500',
                    hide: false,
                    columns: [
                        { label: '区间开始日期', prop: 'startTime' },
                        { label: '区间结束日期', prop: 'endTime' },
                        { column_type: 'number', label: '租金（元/月）', prop: 'adjustRent', min: 0 }
                    ]
                },
                { input_type: 'title', text: '每月物管明细', col: 24, key: 'detailed', hide: false },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'detailedList',
                    isRequired: true,
                    border: true,
                    table_MaxHeight: '500',
                    hide: false,
                    columns: [
                        { label: '账单周期', prop: 'date' },
                        { label: '租金（元/月）', prop: 'rent' },
                        { column_type: 'number', label: '调整租金（元/月）', prop: 'adjustRent', min: 0 }
                    ]
                },
                { input_type: 'title', text: '其他收费项', col: 24 },
                { input_type: 'customize', customize: 'addTabel', col: 24 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'payServiceList',
                    isRequired: true,
                    border: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType' },
                        { column_type: 'select', label: '收费项目名称', prop: 'serviceName', options: [], placeholder: '请选择', method: 'selectServiceName' },
                        { label: '收费项目性质', prop: 'natureCharges' },
                        { label: '缴费周期', prop: 'dayName' },
                        { label: '收费标准名称', prop: 'feesName' },
                        { label: '收费标准', prop: 'fees' },
                        { label: '费用（单价）', prop: 'unitPrice' },
                        {
                            label: '操作',
                            input_type: 'button',
                            attribute: { fixed: "right", align: 'center' },
                            list: [
                                { text: '删除', method: 'deleteTabelRow' }

                            ]
                        }
                    ]
                }
            ],
            tableData: [],
            show: false,
            loading: false,
            oldData: { billingArea: '', unitPrice: '' },
            isIncrement: false, // 判断递增区间是否有数据
            newIncrement: false// 递增数据是否变化了

        }
    },
    computed: {
        initRent: function () {
            const data = this.rowForm
            if (data.billingArea >= 0 && data.unitPrice >= 0) {
                const num = Number(data.unitPrice * data.billingArea)
                return num.toFixed(2)
            } else {
                return 0
            }
        }

    },
    watch: {
        initRent: function (newVla) {
            if (newVla) {
                this.rowForm.initRent = newVla
            }
        },
        'rowForm.incrementType': {
            handler (newVal, oldVal) {
                const incrementTime = this.rowFormData.findIndex(item => item.key === 'incrementTime')
                const buKeyLength = this.rowFormData.findIndex(item => item.bukey === 1)
                const buKeyLength2 = this.rowFormData.findIndex(item => item.bukey === 2)
                if (newVal === '0') {
                    this.$set(this.rowFormData[incrementTime], 'hide', true)
                    this.$set(this.rowFormData[buKeyLength], 'hide', false)
                    this.$set(this.rowFormData[buKeyLength2], 'hide', true)
                    this.hideintervaled(true)
                    if (this.allDisabled) {
                        this.$set(this.rowFormData[buKeyLength], 'hide', true)
                        this.$set(this.rowFormData[buKeyLength2], 'hide', true)
                    }
                } else if (newVal === '1') {
                    this.$set(this.rowFormData[incrementTime], 'hide', false)
                    this.$set(this.rowFormData[buKeyLength], 'hide', false)
                    this.$set(this.rowFormData[buKeyLength2], 'hide', false)
                    if (this.allDisabled) {
                        this.$set(this.rowFormData[buKeyLength], 'hide', true)
                        this.$set(this.rowFormData[buKeyLength2], 'hide', true)
                    }
                }
            },
            deep: true
        },
        'rowForm.intervaledList': {
            handler (newVal, oldVal) {
                if (oldVal && newVal === oldVal) {
                    this.newIncrement = true
                }
            },
            deep: true
        }
    },
    mounted () {
        getChargingSettings({ pageIndex: 1, pageSize: 100, natureCharges: '周期性' }).then(res => {
            const data = res.data.data
            data.map(item => {
                if (item.day) {
                    item.dayName = `每月${item.day}日`
                } else {
                    item.dayName = '-'
                }
                item.label = item.serviceName
                item.value = item.id
            })
            const length = this.matterClo.findIndex(item => item.key === 'payServiceList')
            this.matterClo[length].columns[1].options = data
            this.tableData = data
        })
    },
    methods: {
        async init (row, contractRentReqs = [], payServiceList = []) {
            this.loading = true
            this.rowForm = {}
            this.show = true
            const incrementTime = new Date(row.billingTime)
            incrementTime.setFullYear(incrementTime.getFullYear() + 1)
            const month = incrementTime.getMonth() + 1
            const day = incrementTime.getDate()
            const firstIncrementalData = incrementTime.getFullYear() + '-' + month + '-' + day
            this.rowForm = { ...row, billingTime: row.billingTime, incrementTime: firstIncrementalData, payServiceList: [], detailedList: [], intervaledList: [] }
            // 0-租赁合同，1-物管合同
            if (row.contractType === '0') {
                this.rowFormData = this.rentClo
            } else if (row.contractType === '1') {
                this.rowFormData = this.matterClo
            }
            // 回显逻辑
            if (contractRentReqs.length > 0) {
                const obj = deepCopy(contractRentReqs[0])
                this.rowForm = { ...obj, ...row }
            } else {
                // 回显远程数据
                const { roomId, contractId, changeId, clear } = row
                if (roomId && contractId) {
                    let rentData
                    if (changeId === 0 || !changeId) {
                        rentData = await getRentList({ roomId, contractId })
                    } else {
                        rentData = await getRentList({ roomId, contractId, changeId })
                    }
                    const { billingArea, unitPrice, initRent, incrementType, incrementTime } = rentData.data
                    if (clear) { // 清空过房间信息则重置房间信息数据
                        const obj = deepCopy(contractRentReqs[0])
                        this.rowForm = { ...obj, ...row, payServiceList, incrementTime: firstIncrementalData }
                        this.$set(this.rowForm, 'detailedList', [])
                        this.$set(this.rowForm, 'intervaledList', [])
                    } else {
                        this.rowForm = { billingArea, unitPrice, initRent, ...row, payServiceList, incrementType, incrementTime }
                        // 如果存在合同id，请求明细回显
                        const detailedList = await getContractRentDetail({ roomId, contractId, changeId })
                        const arr = Array.isArray(detailedList.data) ? detailedList.data : []
                        arr.map(item => {
                            item.date = item.startTime + '~' + item.endTime
                        })
                        const interval = rentData.data.interval.map(item => {
                            return { adjustRent: item.rent, startTime: item.startTime, endTime: item.endTime }
                        })
                        this.$set(this.rowForm, 'detailedList', arr)
                        this.$set(this.rowForm, 'intervaledList', interval)
                    }
                }
            }
            // 记录原始租金数据
            this.oldData = { billingArea: this.rowForm.billingArea || 0, unitPrice: this.rowForm.unitPrice || 0 }

            if (this.rowForm.payServiceList) {
                this.initSelectData()
                this.rowForm.payServiceList.map(item => {
                    if (item.day) {
                        item.dayName = `每月${item.day}日`
                    } else {
                        item.dayName = '-'
                    }
                })
            }

            // 隐藏按钮
            const buKeyLength = this.rowFormData.findIndex(item => item.bukey === 1)
            const buKeyLength2 = this.rowFormData.findIndex(item => item.bukey === 2)
            if (this.allDisabled) {
                if (buKeyLength >= 0) {
                    this.$set(this.rowFormData[buKeyLength], 'hide', true)
                    this.$set(this.rowFormData[buKeyLength2], 'hide', true)
                }
            }
            // 判断是否存在明细，不存在隐藏表格
            if (this.rowForm.detailedList.length > 0) {
                this.hideDetailed(false)
            } else {
                this.hideDetailed(true)
            }
            // 判断是否存在区间，不存在隐藏表格
            if (this.rowForm.intervaledList) {
                if (this.rowForm.intervaledList.length > 0) {
                    this.hideintervaled(false)
                } else {
                    this.hideintervaled(true)
                }
            } else {
                this.hideintervaled(true)
                this.loading = false
                return
            }
            this.loading = false
        },
        reset () {
            this.show = false
        },
        saveForm () {
            if (this.rowForm.incrementType === 1) { // 是否为年递增
                const length = this.rowFormData.findIndex(item => item.key === 'intervaled')
                if (this.rowFormData[length].hide) {
                    this.$message.error('请先生成区间')
                    return
                }
                this.isIncrement = false
                this.rowForm.intervaledList.forEach(item => {
                    if (item.adjustRent !== undefined) {} else {
                        this.isIncrement = true
                    }
                })
                if (this.isIncrement) {
                    this.$message.error('请完善递增区间数据')
                    return
                }
                console.log(this.newIncrement)
                if (this.newIncrement) {
                    this.$message.error('请重新生成明细')
                    return
                }
            }
            const obj = deepCopy(this.rowForm)
            const mas = this.checkFrom(obj)
            if (mas) {
                this.$message.error(mas)
                return false
            }
            this.$emit('getContractRentReqs', obj)
            this.show = false
        },
        addTabel () {
            this.rowForm.payServiceList.push({})
        },
        deleteTabelRow (row, bt) {
            const length = this.matterClo.findIndex(item => item.key === 'payServiceList')
            this.rowForm.payServiceList.splice(bt.$index, 1)
            if (row.value) {
                this.matterClo[length].columns[1].options.push(row)
            }
        },
        selectServiceName (row, val, index, $index) {
            const length = this.tableData.findIndex(item => item.id === val)

            this.$set(this.rowForm.payServiceList, $index, { ...this.tableData[length] })

            this.initSelectData()
        },
        initSelectData () {
            const ids = this.rowForm.payServiceList.map(item => {
                return item.id
            })
            const arr = this.tableData.filter(item => {
                if (ids.indexOf(item.id) < 0) {
                    return item
                }
            })
            const length = this.matterClo.findIndex(item => item.key === 'payServiceList')
            this.matterClo[length].columns[1].options = arr
        },
        // 生成区间
        generateIntervalClick () {
            this.$refs.customFrom.$refs.formbox.validate((valid) => {
                if (valid) {
                    // 发请求拿数据
                    const { leaseTerm, initRent, incrementTime, billingTime } = this.rowForm
                    createRentInterval({ incrementTime: incrementTime, startTime: billingTime, endTime: leaseTerm[1], rent: initRent }).then((res) => {
                        const arr = res.data
                        arr.map(item => {
                            item.date = item.startTime + '~' + item.endTime
                        })
                        this.$set(this.rowForm, 'intervaledList', arr)
                        this.hideintervaled(false)
                    })
                } else {
                    return false
                }
            })
        },
        // 生成明细
        generateDetailsClick () {
            this.newIncrement = false
            this.$refs.customFrom.$refs.formbox.validate(async (valid) => {
                if (valid) {
                    if (this.rowForm.incrementType === '1') { // 判断是否区间
                        this.isIncrement = false
                        this.rowForm.intervaledList.forEach(item => {
                            if (item.adjustRent !== undefined) {} else {
                                this.isIncrement = true
                            }
                        })
                        const length = this.rowFormData.findIndex(item => item.key === 'intervaled')
                        if (this.rowFormData[length].hide) {
                            this.$message.error('请先生成区间')
                            return
                        }
                        if (this.isIncrement) {
                            this.$message.error('请完善递增区间数据')
                            return
                        }
                        const rentInterval = this.rowForm.intervaledList.map(item => {
                            return { startTime: item.startTime, endTime: item.endTime, rent: item.adjustRent }
                        })
                        const res = await getContractRentDetailYear({ rentInterval, startTime: this.rowForm.billingTime, endTime: this.rowForm.leaseTerm[1] })
                        console.log(this.rowForm.detailedList)
                        const arr = res.data
                        arr.map((item) => {
                            item.date = item.startTime + '~' + item.endTime
                        })
                        this.$set(this.rowForm, 'detailedList', arr)
                        this.hideDetailed(false)
                        // 更新租金数据
                        this.oldData = { billingArea: this.rowForm.billingArea, unitPrice: this.rowForm.unitPrice }
                        return
                    }
                    // 发请求拿数据
                    const { leaseTerm, initRent } = this.rowForm
                    const res = await getContractRentDetail({ startTime: this.rowForm.billingTime, endTime: leaseTerm[1], rent: initRent })
                    const arr = res.data
                    arr.map(item => {
                        item.date = item.startTime + '~' + item.endTime
                    })
                    this.$set(this.rowForm, 'detailedList', arr)
                    this.hideDetailed(false)
                    // 更新租金数据
                    this.oldData = { billingArea: this.rowForm.billingArea, unitPrice: this.rowForm.unitPrice }
                } else {
                    return false
                }
            })
        },
        // 是否隐藏区间
        hideintervaled (isShow) {
            const length = this.rowFormData.findIndex(item => item.key === 'intervaled')
            this.rowFormData[length].hide = isShow
            this.rowFormData[length + 1].hide = isShow
        },
        // 是否隐藏明细
        hideDetailed (isShow) {
            const length = this.rowFormData.findIndex(item => item.key === 'detailed')
            this.rowFormData[length].hide = isShow
            this.rowFormData[length + 1].hide = isShow
        },
        checkFrom (obj) {
            let isOk = false
            const oldData = this.oldData
            // 校验一下是否修改了配置信息但未生成新的明细
            for (const key in oldData) {
                console.log(oldData[key], obj[key])
                if (oldData[key] !== obj[key]) {
                    return '请生成明细'
                }
            }

            if (obj.payServiceList) {
                obj.payServiceList.map(item => {
                    if (isNaN(item.id)) {
                        isOk = true
                    }
                })
                if (isOk) return '请填写完整其它收费项'
            }
        }

    }
}
</script>
<style scoped>
::v-deep .titlePart {
    padding-left: 15px;
    background: #ebffff;
    width: 100%;
}
::v-deep .el-input-number__increase {
    display: none;
}
::v-deep .el-input-number__decrease {
    display: none;
}
</style>
