<template>
  <div>
    <el-dialog v-dialogDrag
               :close-on-click-modal="false"
               :visible.sync="show"
               width="55%"
               title="设置保证金">
      <ja-form :rules="rules"
               :showSelect="true"
               v-model="rowForm"
               :formArray="rowFormData"
               :allDisabled="allDisabled"
               @reset="reset"
               @save="saveForm"
               @handleSelectItem="handleSelectItem"
               @handleSelectionAll="handleSelectItem"
               v-if="show">
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import { getbizBillDetailNewList } from '@/api'
export default {
    inject: ['getInit'],
    props: {
        allDisabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            rowForm: { bzjDTOS: [] },
            rules: {},

            rowFormData: [
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'bzjDTOS',
                    isRequired: true,
                    border: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType', disabled: true },
                        { label: '收费项目名称', prop: 'serviceName', disabled: true },
                        { label: '金额', prop: 'receivable', disabled: true }
                    ]
                }
            ],
            tableData: [],
            show: false,
            evidenceCode: null,
            checkboxItems: []
        }
    },
    computed: {
        initRent: function () {
            const data = this.rowForm
            if (data.billingArea >= 0 && data.unitPrice >= 0) {
                return data.unitPrice * data.billingArea
            } else {
                return 0
            }
        }
    },
    mounted () {
    },
    methods: {
        async init (row) {
            const res = await getbizBillDetailNewList({ billId: row.id, pageIndex: 1, pageSize: 1000 })
            this.rowForm = { ...row }
            this.rowForm.bzjDTOS = res.data.data
            this.show = true
        },
        saveForm () {
            this.$confirm(`预缴费用为 ${this.rowForm.date} ${this.rowForm.tenantName}的虚拟账单，预缴费项将不再于原账单体现，是否确定生成预缴费用？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                console.info(this.rowForm)
                this.$message.success('操作成功')
                this.show = false
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                })
            })
        },
        reset () {
            this.show = false
        },
        handleSelectItem (selection, row) {
            this.checkboxItems = []
            selection.map(e => {
                this.checkboxItems.push(e.id)
            })
            console.info(this.checkboxItems)
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.input_text {
  border: 0 !important;
  padding: 0 !important;
}
</style>
