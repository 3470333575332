<template>
    <!-- 部门列表 -->
    <!-- rowDetail: 详情操作->详情面板 -->
    <!-- append: 新增操作->新增面板 -->
    <!-- rowEdit: 编辑操作->编辑面板 -->
    <!-- rowDelete: 删除操作->删除面板 -->
    <!-- getInit: 获取ajax数据 -->
    <!-- outPut_: 导出数据 -->
    <div class="allwh minWidth1000">
        <div class="allwh-wrapper" style="display: flex;">
            <!-- 部门列表主体 -->
            <ja-table ref="tst" style="width: 100%" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :editDataFormat="editDataFormat" @append="append" :showIndex="true" @rowEdit="rowEdit" @rowDelete="rowDelete" @getInit="getInit" :valueFormat="formatter" @search="search" @reset="reset" @showUser="showUser" @disabled="disabled" @enable="enable">
            </ja-table>
            <!-- 新增/编辑 -->
            <!-- reset: 取消 -->
            <!-- saveForm: 保存操作 -->
            <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title="titleText" :element-loading-text="isAdd?'新增中':'编辑中'">
                <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :allDisabled="allDisabled" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll" v-loading="loading_form.isShow" :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon" :element-loading-background="loading_form.bg" :element-loading-custom-class="loading_form.formLoading">
                </ja-form>
            </el-dialog>
        </div>

    </div>
</template>

<script>
import {
    queryDeptList,
    addDepartmentInfo,
    updateDepartmentInfo,
    deleteDepartmentInfo,
    disabledDepartment,
    enableDepartment,
} from '@/api'
import table from "@/views/mixins/table"
export default {
    title: '部门列表',
    mixins: [table],
    name: 'index',
    data () {
        return {
            filterData: { // 默认页码
                pageIndex: 1,
                pageSize: 10
            },
            companyData: [],
            conditionData: [ // 表格上方的搜索/选择操作栏
                {
                    input_type: 'input',
                    key: 'deptName',
                    attribute: {
                        placeholder: '部门名称',
                        size: 'small'
                    }
                },
                {
                    input_type: 'select',
                    key: 'deptStatus',
                    attribute: {
                        placeholder: '全部状态',
                        size: 'small'
                    },
                    options: [
                        { label: '正常', value: 0 },
                        { label: '禁用', value: 1 }
                    ]
                },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'departmentList::index::add' }
            ],
            columns: [ // 表头数据
                {
                    label: '部门名称',
                    prop: 'deptName', attribute: { showOverflowTooltip: true, align: 'center' }
                },
                {
                    label: '所属公司',
                    prop: 'companyName', attribute: { showOverflowTooltip: true, align: 'center' }
                },
                {
                    label: '负责人',
                    prop: 'linkMan'
                },
                {
                    label: '联系电话',
                    prop: 'phone'
                },
                {
                    label: '状态',
                    prop: 'deptStatus'
                },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    list: [
                        { text: '查看', method: 'showUser', limit: 'departmentList::index::showUser' },
                        { text: '禁用', method: 'disabled', limit: 'departmentList::index::disabled' },
                        { text: '启用', method: 'enable', limit: 'departmentList::index::enable' },
                        { text: '编辑', method: 'rowEdit', limit: 'departmentList::index::rowEdit' },
                        { text: '删除', method: 'rowDelete', limit: 'departmentList::index::rowDelete' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 0,
            titleText: '编辑',
            rowFormData: [
                { input_type: 'input', label: '部门名称', key: 'deptName', maxlength: 30 },
                { input_type: 'select', label: '所属公司', key: 'companyId', options: [] },
                { input_type: 'input', label: '负责人姓名', key: 'linkMan', maxlength: 30 },
                { input_type: 'input', label: '联系电话', key: 'phone', maxlength: 30 },
                { input_type: 'radio', label: '状态', key: 'deptStatus', options: [{ "value": 0, "label": "正常" }, { "value": 1, "label": "禁用" }] },
                { input_type: 'input', label: '状态', key: 'statusText', hide: true },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', col: 22, maxlength: 200 }
            ],
            rules: {
                deptName: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText({textMessage:'部门名称'}) }],
                companyId: [{ required: true, message: '请选择所属公司', trigger: 'blur' }],
                linkMan: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkText() }],
                phone: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkPhone() }]

            },
            rowForm: {},
            showEditAll: false, // 显示新增/编辑弹窗
            labelWidth: `${this.util.setFontSize(140)}px`,
            allDisabled: false,
        }
    },
    created () {
        this.util.rqOptionsList({ who: [this.conditionData[0], this.rowFormData[1],], rqUrl: "/company", rqData: { params: { pageIndex: 1, pageSize: 200 } }, labelName: 'companyName', valueName: 'id', otherKeys: [{ keyName: "disabled", keyValue: "disabled" }] })
    },
    methods: {
        formatter: function (val, key) {
            if (key === 'deptStatus') {
                return ['启用', '禁用'][val || 0]
            }
            return val || ''
        },
        getInit: function () {
            this.loading = true
            queryDeptList(this.filterData).then(({ data }) => {
                this.loading = false
                this.tableData = data.data
                this.total = data.total
            })
        },
        rowDelete: function (row) { // 删除
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteDepartmentInfo(row.id).then(res => {
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '操作取消'
                });
            });
        },
        saveForm: function () { // 保存
            this.loading_form.isShow = true
            const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
            if (this.isAdd) {
                addDepartmentInfo(paramObj).then(res => {
                    this.loading_form.isShow = false
                    this.$message.success('新增成功')
                    this.showEditAll = false
                    this.getInit()
                }).catch(error => {
                    this.loading_form.isShow = false
                })
            } else {
                updateDepartmentInfo(paramObj).then(res => {
                    this.loading_form.isShow = false
                    this.$message.success('修改成功')
                    this.showEditAll = false
                    this.getInit()
                }).catch(error => {
                    this.loading_form.isShow = false
                })
            }
        },
        // 新增
        append () {
            this.allDisabled = false
            this.isAdd = true
            this.rowForm = {
                deptStatus: 0
            }
            this.titleText = '新增'
            this.showEditAll = true
            this.rowFormData[5].hide = true
            this.rowFormData[4].hide = false
        },
        // 编辑
        rowEdit: function (row) {
            this.allDisabled = false
            this.isAdd = false
            this.titleText = '编辑'
            this.rowFormData[5].hide = true
            this.rowFormData[4].hide = false
            this.rowForm = {
                ...row
            }
            this.showEditAll = true
        },
        //查看
        showUser (row) {
            this.allDisabled = true
            this.showEditAll = true
            this.isAdd = false
            this.titleText = '查看部门信息'
            this.rowFormData[4].hide = true
            this.rowFormData[5].hide = false
            this.rowForm = {
                statusText: ['启用', '禁用'][row.deptStatus],
                ...row
            }
        },
        editDataFormat (row, btn) {
            if (row.deptStatus == 0 && btn.text == '启用' || row.deptStatus == 1 && btn.text == '禁用') {
                return false
            } else {
                return true
            }
        },
        //禁用
        disabled (row) {
            if (row.companyStatus === 1) {
                this.$confirm('您已禁用该企业的登录权限，启用企业状态后可恢复登录', '确认提示', {
                    showCancelButton: false,
                    type: 'warning'
                }).then(() => {
                    return false
                })
            } else {
                this.$confirm('禁用后该部门下所有人员将限制登录，是否确定禁用？', '确认提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let id = row.id
                    disabledDepartment(id).then(res => {
                        this.getInit()
                        this.$message({
                            type: 'success',
                            message: '禁用成功'
                        });
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            }

        },
        //启用
        enable (row) {
            if (row.companyStatus === 1) {
                this.$confirm('您已禁用该企业的登录权限，启用企业状态后可恢复登录', '确认提示', {
                    showCancelButton: false,
                    type: 'warning'
                }).then(() => {
                    return false
                })
            } else {
                this.$confirm('是否确定启用？', '确认提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let id = row.id
                    enableDepartment(id).then(res => {
                        this.getInit()
                        this.$message({
                            type: 'success',
                            message: '启用成功'
                        });
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            }

        }
    }
}
</script>
