<template>
    <div>
        <el-row :gutter="50">
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(83, 110, 196, 1)' }">
                    <p style="white-space: nowrap;">总车位</p>
                    <div class="testBox">{{parkingSpaceList.totalParkingSpaces || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(141, 197, 113, 1)' }">
                    <p style="white-space: nowrap;">剩余车位</p>
                    <div class="testBox">{{parkingSpaceList.remainingParking || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(250, 200, 89, 1)' }">
                    <p style="white-space: nowrap;">Vip车辆</p>
                    <div class="testBox">{{parkingSpaceList.useParking || 0}}</div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="never"
                         :body-style="{ background: 'rgba(238, 102, 102, 1)' }">
                    <p style="white-space: nowrap;">累计车辆总数</p>
                    <div class="testBox">{{parkingSpaceList.totalVehicleQuantity || 0}}</div>
                </el-card>
            </el-col>

        </el-row>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :valueFormat="formatter"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @lookRoom="lookRoom">
        </ja-table>
        <show-information ref="show"></show-information>
    </div>
</template>

<script>
import { getStatisticsList, getParkingSpace } from '@/api'
import ShowInformation from './ShowInformation.vue'
import table from '@/views/mixins/table'
export default {
    title: '车辆统计',
    mixins: [table],
    name: 'vehicleStatistics',
    components: {
        ShowInformation,
    },
    data() {
        return {
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'carNumber', attribute: { placeholder: '车牌号码', size: 'small' } },
                { input_type: 'select', key: 'type', attribute: { placeholder: '全部类型', size: 'small' }, options: [{ label: '你好', value: 1 }] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
            ],
            columns: [
                // 表头数据
                { label: '类型', prop: 'typeValue' },
                { label: '车牌号码', prop: 'carNumber' },
                { label: '使用公司名称', prop: 'companyname' },
                { label: '使用部门', prop: 'deptname' },
                {
                    label: '操作', input_type: 'button', width: 200, attribute: { fixed: 'right', align: 'center' },
                    list: [
                        {
                            text: '查看',
                            method: 'lookRoom',
                            limit: 'passModule::vehicleStatistics::show'
                        }
                    ]
                }
            ],
            tableData: [],
            total: 30,
            parkingSpaceList: {}
        }
    },
    mounted() {
        let index = this.conditionData.findIndex((e) => e.key === 'type')
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'car_type' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        getInit() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            getStatisticsList({ ...this.filterData }).then((res) => {
                this.tableData = res.data.data
                this.total = res.data.total
            }).then(() => {
                getParkingSpace().then(res => {
                    this.parkingSpaceList = res.data
                    loading.close()
                })
            }).catch((err) => {
                loading.close()
            })

        },
        lookRoom(row) {
            this.$refs.show.init(row)
        },
        formatter(val, key) {
            if(val == null){
                return '-'
            }else{
                return val
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.el-row {
    padding: 0.5vw;
    border-radius: 5vw;
    .el-card {
        color: #fff;
        border-radius: 0.4vw;
        .testBox {
            text-align: center;
            margin: 10px;
            font-size: 2vw;
        }
    }
}
</style>

