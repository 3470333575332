<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="show"
                   width="50%"
                   >
            <ja-form class="opFrom"
                     :rules="rules"
                     v-model="rowForm"
                     :labelWidth="labelWidth"
                     :formArray="rowFormData"
                     @reset="show = false"
                     @save="saveForm"
                     v-if="show"
                     :btnBox="btnBox"
                     v-loading="addLoading">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import cstMin from "../../cstMixin"
import { addProject, editProject,getCmProjectInfoEvolve, addCmProjectInfoEvolve} from '@/api'
export default {
    inject: ['getInit'],
    mixins: [cstMin],
    data() {
        return {
            value: null,
            labelWidth: `${this.util.setFontSize(140)}px`,
            rowFormData: [
                { input_type: 'input', key: 'projectName', label: '项目名称', labelWidth: '130px', col: 24, maxlength: 30, disabled: true, },
                { input_type: 'select', key: 'contacts', label: '联系人', labelWidth: '130px', col: 11, options: [], disabled: true, },
                { input_type: 'input', key: 'clientDepartment', label: '部门名称', labelWidth: '130px', col: 11, disabled: true, placeholder: '-' },
                { input_type: 'input', key: 'customerContact', label: '电话号码', labelWidth: '130px', col: 11, disabled: true, placeholder: '-' },
                { input_type: 'input', key: 'clientCompany', label: '公司名称', labelWidth: '130px', col: 11, disabled: true, placeholder: '-' },
                {
                        input_type: 'table',
                        col: '24',
                        noSearch: true,
                        showPage: false,
                        key: 'dtoList',
                        isRequired: true,
                        border: true,
                        table_MaxHeight: 500,
                        hide: false,
                        columns: [
                            { label: '跟进日期',column_type: 'date',valueFormat:"yyyy-MM-dd", prop: 'evolveDate' },
                            { label: '跟进描述', column_type: 'input', prop: 'evolveDescribe' },
                            { column_type: 'select', label: '进度', prop: 'progressStatus', options: [
                                // {value:1,label:'未开始'},
                                {value:2,label:'初步接触'},
                                {value:3,label:'洽谈阶段'},
                                {value:4,label:'交付诚意金'},
                                {value:5,label:'交付定金'},
                                {value:6,label:'正式签约'},
                                {value:7,label:'暂停跟进'},
                            ] }
                        ]
                    },
            ],
            rules: {
                leasePurpose: [
                    { required: true, message: '请选择租赁用途', trigger: 'blur' }
                ],
                contacts: [
                    { required: true, message: '请选择联系人', trigger: 'blur' }
                ],
                attachs: [
                    { required: true, message: '请选择联系人', trigger: 'blur' }
                ],
                demandArea: [
                    { required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }
                ],
                unitPrice: [
                    { required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ fixedNum: 2, min: 0 }) }
                ],
                times: [
                    { required: true, message: '请选择计费区间', trigger: 'blur', }
                ],
                startDate: [{ required: false, trigger: 'change', validator: this.validatorStartTime }],
                endDate: [{ required: false, trigger: 'change', validator: this.validatorEndTime }],
            },
            title: '新增项目信息',
            show: false,
            isAdd: false,
            addLoading: false,
            projectId:"",
            rowForm: {
                
            },
            contacts: [],
            btnBox:[
                    {
                        label: '保存',
                        method: 'save',
                        type: 'primary',
                        monitor: true,
                        col: 8,
                        isCenter: true
                    },
            ]
        }
    },
    async mounted() {
        await this.getSelect()
    },
    methods: {
        init(row,type) {
           this.projectId=row.id
            row.contactsId = Number(row.contactsId)
            row.followUpPeopleDeptId = Number(row.followUpPeopleDeptId)
            row.followUpPeopleId = Number(row.followUpPeopleId)
            getCmProjectInfoEvolve(row.id).then(res=>{
                this.rowForm = res.data
                
                 this.show = true
                 this.rowForm.dtoList.map(item=>{
                    item.evolveDescribeDisabled=true
                    item.evolveDateDisabled=true
                    item.progressStatusDisabled=true
                    return item
                 })
                if(type=="新增"){
                    this.rowForm.dtoList.unshift({evolveDate:this.$moment().format("YYYY-MM-DD"),progressStatus:this.rowForm.progressStatus})
                    this.isAdd = true
                    this.title = '新增跟进'
                }else{
                    this.btnBox=[]
                    this.title = '查看跟进'
                }
            })
            
        },
        saveForm() {
            let obj={...this.rowForm.dtoList[0]}
            obj.projectId=this.projectId
            if(!obj.evolveDate){
               this.$message.error('请填写跟进日期！') 
               return 
            }else if(!obj.evolveDescribe){
                this.$message.error('请填写跟进描述！') 
                return 
            }else if(!obj.progressStatus){
                this.$message.error('请选择进度状态！') 
                return 
            }
            addCmProjectInfoEvolve(obj).then(() => {
                    this.show = false
                    this.getInit()
                    this.$message.success('新增成功')
            })

        },
        // 开始时间禁用
        disabledDateStart(val) {
            let now = (new Date()).getTime()
            let time = val.getTime()
            let oneDay = 24 * 3600 * 1000
            return time < now - oneDay
            },
        validatorEndTime(rule, value, callback) {
            if (rule.required && (value === '' || !value)) {
                callback(new Error('请完善计划结束时间'));
            } else {
                let startDate = new Date(this.rowForm.startDate).getTime()
                let time = new Date(value).getTime()
                if (time < startDate) {
                callback(new Error('结束时间不能小于开始时间'))
                } else {
                callback()
                }
            }
        },
    
    }
}
</script>
<style lang="scss" scoped>
.customizeSelect {
    margin: 0 10px;
}
</style>