<template>
  <!--  结单 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" title="新增用户组">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
  </el-dialog>
</template>
<script>
import { addMsgGroupItem } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      rowFormData: [
        { input_type: 'input', label: '用户组名', key: 'groupName', col: 22, rows: 5 },
      ],
      rowForm: {},
      rules: {
        groupName: [{ required: true, message: '请输入用户组名', trigger: 'blur' },
        {
         validator:(value,rule,callback)=>{
           const reg=/^[\u4e00-\u9fa5]{0,18}$/
           if(!reg.test(this.rowForm.groupName)){
            // console.log(this.rowForm.groupName);
            callback(new Error('请输入中文且不能超过18个中文字符'))
           }else{
             callback()
           }
 
         },
         trigger: 'blur' 
        }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
    }
  },
  created() {
  },
  methods: {
    append() {
      this.rowForm = {}
      this.showEditAll = true
    },
    saveForm() {
      this.loadingForm = true
      const rqMethod = addMsgGroupItem
      rqMethod(this.rowForm).then(res => {
        this.loadingForm = false
        this.$message.success(res.msg || '提交成功')
        this.showEditAll = false
        this.$parent.getGroupList()
      }).catch(error => {
        this.loadingForm = false
      })
    },
  }
}
</script>