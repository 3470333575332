<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="40%"
                   title="查看仪表详情"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="{}"
                     v-model="roomDetails"
                     :formArray="detailOptions"
                     :allDisabled="true" 
                     v-if="show">
            </ja-form>
            </ja-detail>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'input', label: '设备详情', key: 'deviceName', labelWidth: '100px' ,placeholder:' '},
                { input_type: 'input', label: '设备识别码', key: 'deviceNumber', labelWidth: '100px' ,placeholder:' '},
                { input_type: 'input', label: '设备大类', key: 'categoryName', labelWidth: '100px' ,placeholder:' '},
                { input_type: 'input', label: '设备小类', key: 'subCategoryName', labelWidth: '100px' ,placeholder:' '},
                { input_type: 'input', label: '所属楼栋', key: 'buildingName', labelWidth: '100px' ,placeholder:' '},
                { input_type: 'input', label: '所属楼层', key: 'floorName', labelWidth: '100px',placeholder:' ' },
                { input_type: 'input', label: '设备位置', key: 'installLocation', labelWidth: '100px' ,placeholder:' '},
                { input_type: 'input', label: '设备品牌', key: 'brand', labelWidth: '100px',placeholder:' ' },
                { input_type: 'input', label: '设备型号', key: 'model', labelWidth: '100px',placeholder:' ' },
                { input_type: 'input', label: '绑定状态', key: 'statusName', labelWidth: '100px' ,placeholder:' '},
                { input_type: 'input', label: '绑定租赁单元', key: 'roomName', hide: false, labelWidth: '100px' ,placeholder:' '},
            ],
            //表头数据
            roomDetails: {},
            show: false
        }
    },
    methods: {
        getDetailsData(row) {
            let length = this.detailOptions.findIndex(item => { return item.key === 'roomName' })
            if (row.status == '1') {
                this.$set(this.detailOptions[length], 'hide', false)
            } else {
                this.$set(this.detailOptions[length], 'hide', true)
            }
            this.roomDetails = row
            this.show = true
        }
    }
}
</script>
