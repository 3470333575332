/** 内部车辆管理页面搜索栏 */
export const dicManagerQuery = [
    {
        input_type: 'input',
        key: 'tenantName',
        lable: '驻户名称',
        attribute: {
            placeholder: '驻户名称',
            size: 'small'
        }
    },
    {
        input_type: 'input',
        key: 'storeName',
        lable: '店铺名称',
        attribute: {
            placeholder: '店铺名称',
            size: 'small'
        }
    },
    {
        input_type: 'input',
        key: 'carNumber',
        lable: '车牌号码',
        attribute: {
            placeholder: '车牌号码',
            size: 'small'
        }
    },
    {
        input_type: 'input',
        key: 'applicant',
        lable: '申请人',
        attribute: {
            placeholder: '申请人',
            size: 'small'
        }
    },
    {
        input_type: 'input',
        key: 'contactNumber',
        lable: '联系方式',
        attribute: {
            placeholder: '联系方式',
            size: 'small'
        }
    },
    {
        input_type: 'select',
        key: 'serviceType',
        lable: '全部服务类型',
        attribute: {
            placeholder: '全部服务类型',
            size: 'small',
            clearable: true
        },
        options: [
            {
                value: 0,
                label: '月卡'
            },
            {
                value: 1,
                label: '季卡'
            },
            {
                value: 2,
                label: '半年卡'
            },
            {
                value: 3,
                label: '年卡'
            }
        ]
    },
    { normalBtn: 'search' },
    { normalBtn: 'reset' }
]

/** 内部车辆管理页面表头栏 */
/** 数字字典页面表头栏 */
export const dicManagerColumn = [
    { label: '驻户名称', prop: 'tenantName' },
    { label: '店铺名称', prop: 'storeName' },
    { label: '车牌号码', prop: 'carNumber' },
    { label: '服务类型', prop: 'serviceType' },
    { label: '申请人', prop: 'applicant' },
    { label: '申请时间', prop: 'applicationTime', width: 180 },
    { label: '联系方式', prop: 'contactNumber' },
    { label: '确认时间', prop: 'confirmationTime', hide: true , width: 180 },
    {
        label: '操作',
        input_type: 'button',
        list: [
            { text: '查看', method: 'showDetail', limit: 'tenant::apply::details' },
            { text: '通过/驳回', method: 'rowEdit', limit: 'tenant::apply::audit' }
        ]
    }
]
