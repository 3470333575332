<template>
  <div>
    <ja-table
      @getInit="getInit"
      :conditionData="conditionData"
      :columns="obviousChargeColumn"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      v-model="filterData"
      @append="append"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @search="search"
      :showIndex="false"
      @reset="reset"
      :valueFormat="formatter"
      @ConfirmReceipt="ConfirmReceipt"
      @importForm="
        normalEvent({
          ref: 'importForm',
          method: 'importForm',
          url: '/files/账单导入模板.xlsx',
          filename: '账单导入模板',
          uploadURL: '/bill/upload/add'
        })
      "
    >
    </ja-table>
    <import-form ref="importForm" @openResult="openResult" />
    <check-result ref="checkResult" :results="results" />
    <confirm-receipt ref="confirmReceipt" />
  </div>
</template> 
<script>
import { obviousChargeColumn } from './billManage'
import table from '@/views/mixins/table'
import importForm from '../common/importForm.vue'
import checkResult from '../common/checkResult'
import confirmReceipt from './components/confirmReceipt'
import { rqBill } from '@/api'

export default {
  title: '账单管理',
  mixins: [table],
  name: 'billManage',
  components: {
    importForm,
    checkResult,
    confirmReceipt
  },
  data() {
    return {
      conditionData: [
        {
          input_type: 'input',
          attribute: {
            placeholder: '驻户名称',
            size: 'small'
          },
          key: 'tenantName'
        },
        {
          input_type: 'input',
          attribute: {
            placeholder: '合同编号',
            size: 'small'
          },
          key: 'contractCode'
        },
        {
          input_type: 'date',
          value: 'billDate',
          type: 'month',
          valueFormat: 'yyyy-MM',
          placeholder: '选择月份'
        },
        { normalBtn: 'search', size: 'small', text: '' },
        { normalBtn: 'reset', size: 'small', text: '' },
        { normalBtn: 'import', text: '导入', limit: 'bill::manage::import' }
      ],
      obviousChargeColumn,
      tableData: [],
      total: 0,
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      title: '',
      results: null
    }
  },
  methods: {
    getInit() {
      this.loading = true
      let rqData = this.rqParmas()
      rqBill(rqData).then((res) => {
        this.tableData = res.data.data
        this.loading = false
        this.total = res.data.total
      })
    },
    rqParmas() {
      return {
        pageSize: this.filterData.pageSize,
        pageIndex: this.filterData.pageIndex,
        status: 0,
        tenantName: this.filterData.tenantName || '',
        contractCode: this.filterData.contractCode || '',
        billDate: this.filterData.billDate || ''
      }
    },
    ConfirmReceipt(row) {
      this.$refs.confirmReceipt.init(row)
    },
    openResult({ data, uploadFlag }) {
      this.results = { data, uploadFlag }
      this.$nextTick(() => {
        this.$refs.checkResult.rowDetail()
      })
    },
    formatter(val, key) {
      if (key === 'status') {
        return ['未缴纳', '已缴纳 '][val || 0]
      }
      return val || ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
