import { getWorkConfig, delWorkConfig } from '@/api'

const common = {
    columns: [
        { label: '接单人', prop: 'employeeNames' },
        {
            label: '操作',
            input_type: 'button',
            width: 160,
            attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
            list: [
                { text: '编辑', method: 'rowEdit', limit: 'workOrderManage::orderConfig::edit' },
                { text: '删除', method: 'rowDelete', limit: 'workOrderManage::orderConfig::delete' }
            ]
        }
    ],
    rowFormData: [
        { input_type: 'select', label: '接单人设置(多选)', key: 'employeeIdList', col: 22, multiple: true, options: [] }
    ],
    rules: {
        workOrderTypeId: [{ required: true, message: '请选择类型', trigger: 'change' }],
        employeeIdList: [{ required: true, message: '请选择接单人', trigger: 'change' }]
    }

}

export default {
    repair: {
        typeText: '维修',
        columns: [{ label: '报修类型', prop: 'workOrderTypeValue' }, ...common.columns],
        getInit: getWorkConfig,
        rowDelete: delWorkConfig,
        rowFormData: [
            { input_type: 'select', label: '报修类型', key: 'workOrderTypeId', col: 11, options: [] },
            ...common.rowFormData
        ],
        rules: { ...common.rules }
    },
    alarm: {
        typeText: '告警',
        columns: [{ label: '告警类型', prop: 'workOrderTypeValue' }, ...common.columns],
        getInit: getWorkConfig,
        rowDelete: delWorkConfig,
        rowFormData: [
            { input_type: 'select', label: '告警类型', key: 'workOrderTypeId', col: 11, options: [] },
            ...common.rowFormData
        ],
        rules: { ...common.rules }
    },
    scan: {
        typeText: '巡检',
        columns: [{ label: '巡检类型', prop: 'workOrderTypeValue' }, { label: '巡检线路', prop: 'patrolPathName' }, ...common.columns],
        getInit: getWorkConfig,
        rowDelete: delWorkConfig,
        rowFormData: [
            { input_type: 'select', label: '巡检类型', key: 'workOrderTypeId', col: 11, options: [{ label: '工程巡检', value: "0" }, { label: '物业巡检', value: "1" }, { label: '运营巡检', value: "2" }] },
            { input_type: 'select', label: '关联线路', key: 'patrolPathId', col: 22, clearable: true, options: [] },
            ...common.rowFormData
        ],
        rules: { patrolPathId: [{ required: true, message: '请选择关联线路', trigger: 'change' }], ...common.rules }
    }
}
