<template>
  <div class="edit">
    <el-dialog v-dialogDrag :close-on-click-modal="false"
      :title="title"
      :visible.sync="show"
      width="50%"
      @close="close"
      :before-close="close"
    >
      <ja-form :rules="rules" v-model="rowFormData" :formArray="rowForm"
        @reset="close" @save="saveForm" labelWidth="120px">
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import { arrivalRegistrationData } from '../billPaymentManage'
export default {
  name: 'ArrivalRegistration',
  components: {
    JaForm
  },
  data () {
    return {
      rowForm: arrivalRegistrationData,
      rowFormData: {},
      rules: {
        transactionAmount: [
            { required: true, trigger: 'change',validator:this.util.formRules.checkNumber({min:-100000})} 
        ],
        receivedDate: [
            { required: true, message: '选择日期时间', trigger: 'blur' }
        ]
        }
    }
  },
  props: {
    title: String,
    tenantNum: String,
    show: {
      type: Boolean,
      default: false
    }
  },
  created () {},
  methods: {
    /** 保存表单 */
    saveForm () {
      this.$emit('saveForm', this.rowFormData, this.tenantNum)
    },
    /** 关闭弹出框 */
    close () {
      this.rowFormData = {}
      this.$emit('closeForm', false)
    }
  }
}
</script>

<style scoped>

</style>
