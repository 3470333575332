<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @lookRoom="lookRoom"                                                                                                                          
                  @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/入库记录导入模板.xlsx', filename: '入库记录导入模板', uploadURL: '/materials/warehousing/upload'})">
        </ja-table>

        <import-form ref='importForm'
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
        <addEditWarehousing ref="addEditWarehousing"></addEditWarehousing>
        <!-- 入库详情 -->
        <warehousingDetails ref="warehousingDetails"></warehousingDetails>
        <el-dialog :visible.sync="dialogTableVisible">
            <el-image style="width: 100%; height: 100%"
                      :src="urlImg"
                      v-if="urlImg"
                      fit="加载失败"></el-image>
            <i v-else
               class="el-icon-picture-outline"></i>
        </el-dialog>
    </div>
</template>

<script>
import importForm from './common/importForm.vue'
import checkResult from './common/checkResult.vue'
import {getWarehousing} from '@/api'
import table from '@/views/mixins/table'
import addEditWarehousing from './addEditWarehousing.vue'
import warehousingDetails from './warehousingDetails.vue'
export default {
    title: '入库记录',
    mixins: [table],
    name: 'toRentList',
    components: {
        addEditWarehousing,
        importForm,
        checkResult,
        warehousingDetails
    },
    data() {
        return {
            dialogTableVisible: false,
            urlImg: '',
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'materialNumber', attribute: { placeholder: '物资编号', size: 'small' } },
                { input_type: 'select', key: 'materialType', attribute: { placeholder: '物资类型', size: 'small' }, options: [] },
                { input_type: 'input', key: 'materialName', attribute: { placeholder: '物资名称', size: 'small' } },
                { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'materialLists::allMaterials::add' },
                { normalBtn: 'import', text: '导入', limit: 'materialLists::allMaterials::import' }
            ],
            columns: [
                // 表头数据
                { label: '入库日期', prop: 'recordDate' },
                { label: '入库物资', prop: 'recordMaterial' },
                { label: '入库数量', prop: 'recordAmount' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '详情', method: 'lookRoom', limit: 'materialLists::allMaterials::show' },
                    ]
                }
            ],
            results:{},
            tableData: [],
            loading: false,
            total: 30,
            limitVisible:false,
            LastlimitUser:'',//上次权限人
            UserData:[],//用户列表
            limitUser:'',//当前权限人
            limitid:'',//权限id

        }
    },
    mounted() {
        let index1 = this.conditionData.findIndex((e) => e.key === 'materialType')
        this.util.rqOptionsList({
            who: [this.conditionData[index1]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'material_type' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
       async getInit() {
        let obj = { ...this.filterData }
            this.filterData.startTime = obj.time ? obj.time[0] : ''
            this.filterData.endTime = obj.time ? obj.time[1] : ''
            getWarehousing(this.filterData)
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
        },
        editDataFormat(row, btn) {
            if (row.materialsStatus == 1 && btn.text == '启用' || row.materialsStatus == 2 && btn.text == '禁用') {
                return false
            } else {
                return true
            }
        },
        append() {
            this.$refs.addEditWarehousing.init()
        },
        lookRoom(row) {
            this.$refs.warehousingDetails.initDetail(row)
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
    }
}
</script>

<style scoped>
.download {
    width: 5vw;
}
.el-icon-picture-outline {
    height: 400px;
    width: 100%;
    font-size: 150px;
    font-weight: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d2d2d2;
}
</style>

