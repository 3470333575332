<template>
    <div>
        <el-dialog :visible.sync="show"
                   :close-on-click-modal="false"
                   width="50%"
                   title="新增变更">
            <ja-form ref="jaForm"
                     v-model="rowForm"
                     :rules="rules"
                     :formArray="rowFormData"
                     :loadingForm="loadingForm"
                     @contractSelect="contractSelect"
                     @reset="show = false"
                     @save="saveForm">
            </ja-form>
        </el-dialog>
        <change-operation ref="operationRefs"
                          @updateSuccess="updateSuccess"></change-operation>
    </div>
</template> 
<script>
import changeOperation from './changeOperation.vue'
import { getArchivesList } from '@/api'
import deepCopy from '@/util/deepCopy.js'
export default {
    inject: ['getInit'],
    components: {
        changeOperation
    },
    data() {
        return {
            rowFormData: [
                { input_type: 'select', label: '合同名称', key: 'contractName', col: 12, options: [], labelWidth: '120px', method: 'contractSelect' },
                { input_type: 'input', label: '驻户名称', key: 'tenantName', col: 12, disabled: true, labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '租赁单元', key: 'roomName', col: 12, disabled: true, labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '租期', key: 'leaseTerm', col: 12, disabled: true, labelWidth: '120px', placeholder: ' ' },
                { input_type: 'select', label: '变更模板', key: 'changeTemplate', col: 12, options: [{ label: '租赁单元变更', value: 1 }, { label: '驻户变更', value: 2 }, { label: '明细变更', value: 3 }], labelWidth: '120px' },
                { input_type: 'input', label: '变更生效日期', key: 'takeEffectDate', col: 12, disabled: true, labelWidth: '120px' },

            ],
            rowForm: {},
            rules: {
                contractName: [{ required: true, message: '请选择合同名称', trigger: 'blur' }],
                changeTemplate: [{ required: true, message: '请选择变更模板', trigger: 'blur' }]
            },
            show: false,
            contractList: []

        }
    },
    mounted() {
        let length = this.rowFormData.findIndex(item => item.key == 'contractName')
        this.util.rqOptionsList({
            who: this.rowFormData[length],
            httpFun: getArchivesList,
            rqData: { pageIndex: 1, pageSize: 1000, queryFlag: 2, status: 2 },
            labelName: 'contractName',
            valueName: 'contractName',
            isReturnAll: true
        }).then(res => {
            res.map(item => {
                if (Array.isArray(item.roomInfo) && item.roomInfo.length > 0) {
                    item.roomName = item.roomInfo[0].roomName
                } else {
                    item.roomName = ''
                }
            })
            this.contractList = res
        })
    },
    methods: {
        initState() {
            this.rowForm = { takeEffectDate: '立即生效' }
            this.show = true
        },
        saveForm() {
            let { contractCode, changeId, changeTemplate } = this.rowForm
            changeId = changeId ? changeId : 0
            this.$refs.operationRefs.rowEdit(contractCode, changeId, changeTemplate)
        },
        contractSelect(row, val) {
            let length = this.contractList.findIndex(item => item.contractName == val)
            if (length >= 0) {
                Object.assign(this.rowForm, this.contractList[length])
            }
            console.log(this.rowForm);
        },
        updateSuccess() {
            this.show = false
            this.getInit()
        }
    }
}
</script>
<style lang="scss" scoped>
</style>