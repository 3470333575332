import http from './plugin/ajax'

// =========  收费管理  ===========

// 收费项配置
export const getPayList = (data) => http.post('/pay/list', data) // 查询列表
export const addPayItem = (data) => http.post('/pay', data) // 新增
export const editPay = (data) => http.put('/pay', data) // 修改
export const delPay = (data) => http.delete('/pay/' + data) // 删除
export const getBillPay = (data) => http.get('/bill/pay', { params: data }) //
export const submitPay = (data) => http.put('/pay/submit/' + data) // 提交
export const approvalPay = (data) => http.put('/pay/audit/' + data) // 审批

export const switchAj = (data) => http.put(`/bill/approved/setting`, data) // 审核开关
export const billingDaySetting = (data) => http.post(`/bill/setBillDay/${data}`) // 账单日设置
export const getBillingDaySetting = () => http.post(`/bill/getBillDay`) // 获取账单日设置
export const getSwitchAj = () => http.get('/bill/approved/setting')// 获取审核设置
export const datailld = (data) => http.delete(`/bill/detail/${data}`)// 删除收费项

//= ============收费项使用记录==================
export const getUsageRecordList = (data) => http.post('/bizPayServiceUse/list', data) // 查询列表

//= ===============收费项目标准==================
export const getChargingSettings = (data) => http.post('/bizPayServiceNew/list', data) // 查询列表
export const addChargingSettings = (data) => http.post('/bizPayServiceNew', data) // 新增
export const editChargingSettings = (data) => http.put('/bizPayServiceNew', data) // 修改
export const delChargingSettings = (data) => http.delete('/bizPayServiceNew/' + data) // 删除

//= ===============收费项目历史记录==================
export const getChargingHistory = (data) => http.post('/bizPayServiceNewHis/list', data) // 查询列表

// ===============财务统计==================
export const getFinanceStatistics = (data) => http.get('/bizBillInfoNew/cockpit/money/statistics', { params: data })  //账单金额统计
export const getWriteoffStatistics = (data) => http.get('/bizBillInfoNew/cockpit/writeoff/statistics', { params: data })  //账单核销分布
export const getExamineStatistics = (data) => http.get('/bizBillInfoNew/cockpit/examine/statistics', { params: data })  //应收审批分布
export const getAnnualStatistics = (data) => http.get('/bizBillInfoNew/cockpit/annual/overdue', { params: data })  //年度逾期分布图

