<template>
    <div>
        <el-dialog :visible.sync="show"
                   :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   width="60%"
                   title="已通过查看合同">

            <el-tabs v-model="activeName">
                <el-tab-pane label="合同内容"
                             name="content">
                    <detail-content v-if="activeName =='content' && show"
                                    :data="datailData"></detail-content>
                </el-tab-pane>
                <el-tab-pane label="变更日志"
                             name="second">
                    <change-detail v-if="activeName =='second' && show"
                                   :pid="id"></change-detail>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>

    </div>
</template>
<script>
import DetailContent from './detailContent.vue'
import ChangeDetail from './changeDetail.vue'
import { getContractChangeDetail } from '@/api'
import deepCopy from '@/util/deepCopy.js'
export default {
    components: {
        DetailContent,
        ChangeDetail
    },
    data() {
        return {
            activeName: 'content',
            show: false,
            datailData: {},
            id: null
        };
    },
    methods: {
        async init(row) {
            this.activeName = 'content'
            let res = await getContractChangeDetail({ contractCode: row.contractCode, changeId: row.changeId })
            this.show = true
            this.datailData = deepCopy(res.data)
            this.id = res.data.changeId
        }
    }
};
</script>
<style lang="scss" scoped>
.el-tabs {
    background: #ffffff !important;
}
::v-deep .el-tabs__nav-scroll {
    background: #ffffff !important;
}
::v-deep .el-dialog__body {
    padding: 0.5vw;
}
</style>