<template>
    <div>
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="show" width="40%" @close="allDisabled=false" :title="titleText">
            <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" @reset="show = false" @save="saveForm" v-if="show" v-loading="loading_form.isShow" :allDisabled="allDisabled" :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon" :element-loading-background="loading_form.bg">
                <template #testStr>
                    <span>请按照地面：1层，2层，3层... 地下：负1层，负2层，负3层...输入后自动排序</span>
                </template>
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { addFloorInfo, updateFloorInfo } from '@/api'
export default {
    inject: ['getInit',],
    name: '新增/编辑/查看楼层',
    components: {
        JaForm
    },
    data () {
        return {
            show: false, // 显示新增/编辑弹窗
            titleText: '新增楼层信息',
            rules: {
                floorName: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText({textMessage:'楼层名称'}) }],
                type: [{ required: true, trigger: 'blur', message: '地面/地下不可为空' }],
            },
            rowForm: {},
            labelWidth: `${this.util.setFontSize(140)}px`,
            rowFormData: [
                { customize: 'testStr', input_type: 'customize', col: 24, hide: false },
                { input_type: 'input', label: '楼层名称', key: 'floorName', width: 300, maxlength: 30 },
                { input_type: 'select', label: '地面/地下', key: 'type', options: [] },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', placeholder: '请输入200字以内的描述', col: 22, maxlength: 200 }
            ],
            allDisabled: false,
            isAdd: false,
            loading_form: {
                isShow: false,
                text: '拼命加载中',
                icon: 'el-icon-loading',
            },
            state: 0
        }
    },
    mounted () {
        let index = this.rowFormData.findIndex(item => item.key == 'type')
        const paramObj = { pageIndex: 1, pageSize: 200, dictType: 'floor_type' }
        this.util.rqOptionsList({ who: [this.rowFormData[index]], rqUrl: "/sys/dictData/query", rqData: paramObj, rqMethod: 'post', labelName: 'dictLabel', valueName: 'dictValue' })
    },
    methods: {
        /**
         * init(state,row)
         * state: 1=新增,2=編輯,3=查看
         * row：携带进来的数据
         */
        init (state, row) {
            this.rowFormData[0].hide = false
            this.show = true
            this.rowForm = {}
            if (state === 1) {
                this.isAdd = true
            } else if (state === 2) {
                this.isAdd = false
                this.titleText = '编辑楼层信息'
                this.rowForm = { ...row }
            } else if (state === 3) {
                this.rowFormData[0].hide = true
                this.titleText = '查看楼层信息'
                this.allDisabled = true
                this.rowForm = { ...row }
            }
        },
        saveForm () {
            if (this.isAdd) {
                this.loading_form.isShow = true
                addFloorInfo(this.rowForm).then(res => {
                    this.loading_form.isShow = false
                    this.show = false
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '新增成功'
                    });
                }).catch(err => {
                    this.loading_form.isShow = false
                    console.log(err);
                })
            } else {
                this.loading_form.isShow = true
                updateFloorInfo(this.rowForm).then(res => {
                    this.loading_form.isShow = false
                    this.show = false
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '编辑成功'
                    });
                }).catch(err => {
                    console.log(err);
                    this.loading_form.isShow = false
                })
            }
        }
    }
}

</script>