<template>
    <div>
        <!-- 详情弹窗 -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="查看详情" :width="`50%`">
            <ja-detail :options="detailOptions" :total="total" :detailData="detailData" :label_width='12'
                @expenseAllocation="expenseAllocation" v-if="showDetail">
                <template #uplpadfaceImg>
                    <upload-file @pdfTitleClickEvemt="pdfTitleClickEvemt" ref="Upload" :initFileList="[...initFileList]"
                        :noButton="true" :hideDel="true" accept="pdf" />
                </template>
                <template #paymentDate>
                    <span>每个月的第</span>
                    <el-select v-model="rowForm.day" class="day" placeholder="请选择日期">
                        <el-option v-for="item in 28" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                    <span>日</span>

                </template>
            </ja-detail>
        </el-dialog>
        <!-- 在线预览pdf -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="pdfShow" width="60%">
            <pdf ref="pdf" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :src="pdfUrl" v-for="i in numPages" :key="i" :page="i"
                class="pdf">
            </pdf>
        </el-dialog>
        <!--费用配置-->
        <rent-allocation ref="rentA" :allDisabled="true"></rent-allocation>
    </div>
</template>
<script>
import pdf from 'vue-pdf'
import RentAllocation from './rentAllocation.vue'
export default {
    inject: ['labelWidth', 'getInit'],
    components: {
        pdf,
        RentAllocation
    },
    data() {
        return {
            showDetail: false,
            total: 23,
            detailOptions:
                [
                    { label: '合同编号', key: 'contractCode', },
                    { label: '合同名称', key: 'contractName' },
                    { label: '合同类型', key: 'contractTypeName' },
                    { label: '租期', key: 'leaseTerm' },
                    { label: '计租开始日期', key: 'billingTime', },
                    { customize: 'paymentDate', label: '缴费日期', key: 'day' },
                    { label: '驻户名称', key: 'tenantName', },
                    { label: '营业执照号/身份证号', key: 'cardCode', },
                    { label: '驻户类型', key: 'tenantType', },
                    {
                        type: 'table', label: '驻户租赁单元', noSearch: true, showPage: false, key: 'roomInfo', col: 24,
                        columns: [
                            { label: '租赁单元名称', prop: 'roomName' },
                            { label: '租赁单元类型', prop: 'roomTypeValue' },
                            { label: '所属楼栋', prop: 'buildingName' },
                            { label: '所属楼层', prop: 'floorName', attribute: { showOverflowTooltip: false, } },
                            { label: '套内总体使用面积', prop: 'totalArea' },
                            {
                                label: '操作',
                                input_type: 'button',
                                width: '150',
                                list: [
                                    { text: '查看', method: 'expenseAllocation', limit: 'contract::manage::expense_allocation' }
                                ]
                            }
                        ]
                    },
                    { type: 'customize', customize: 'uplpadfaceImg', label: '合同附件', key: 'urlLists', col: 24, alignItems: 'flex-start' },
                    { label: '审批备注', key: 'rejectReason' }
                ],
            detailData: {},
            initFileList: [],
            pdfUrl: '',
            pdfShow: false,
            numPages: null,
            loading: true,
            contractId: null,
            //区分合同类型
            contractType: null,
            isHistory: false,
        }
    },
    methods: {
        pdfTitleClickEvemt(item) {
            // 使用线上ip无法访问
            //判断当前环境
            if (process.env.NODE_ENV === 'development') {
                let urlList = item.url.split('/')
                this.pdfUrl = `/upload/${urlList[urlList.length - 1]}`
            } else {
                this.pdfUrl = item.url
            }
            this.pdfShow = true

            let loadingTask = pdf.createLoadingTask(this.pdfUrl)
            loadingTask.promise.then(pdf => {
                this.numPages = pdf.numPages
            }).catch(err => {
                console.error('pdf 加载失败', err);
            })
            // 加载loading
            setTimeout(() => {
                this.loading = false
            }, this.numPages * 300);
        },
        rowDetail(row, isHistory, isContractId) {
            console.log(row);
            // 先清空数据
            this.detailData = {}
            // 设置要传递到ViewContractRent的参数（是否是历史合同）
            this.isHistory = isHistory
            this.contractType = row.contractType
            //传给费用配置 用的 根据传入的isContractId判断使用合同ID还是id
            this.contractId = isContractId == 1 ? row.contractId : row.id
            this.showDetail = true
            let obj = { ...row }
            obj.dateRage = `${row.startTime} 至 ${row.endTime}`
            obj.liquidatedDamagesRate = `${row.liquidatedDamagesRate}%`
            obj.liquidatedDamages = ['否', '是'][row.liquidatedDamages]
            obj.tenantType = obj.tenantType == 0 ? '商业' : '办公'
            if (row.attachmentListInfo.length > 0) {
                this.initFileList = row.attachmentListInfo.map(item => {
                    if (!item) return
                    return {
                        name: item.attachmentName ? item.attachmentName : undefined,
                        url: item.attachmentUrl ? item.attachmentUrl : item,
                        raw: { type: item.attachmentType === 'pdf' ? 'pdf' : 'image' },
                        status: 'success',
                        response: { data: item }

                    }
                })
            } else {
                this.initFileList = []
            }
            this.detailData = obj
        },
        // 配置费用
        expenseAllocation(row) {
            let { contractType, id, billingTime } = this.detailData
            let payServiceList = []
            if (this.detailData.bizPayServiceNewDTOS) {
                payServiceList = [...this.detailData.bizPayServiceNewDTOS]
            }

            this.$refs.rentA.init({ ...row, contractType, billingTime, contractId: id }, [], payServiceList)
        },
    }
}
</script>
<style lang="scss" scoped>
.pdf {
    margin-bottom: 15px;
    box-shadow: 0px 0px 5px #888888;
}
</style>
