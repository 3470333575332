<template>
  <router-view/>
</template>

<script>
export default {
  title: "客户管理",
  name: 'customerManagement'
}
</script>

<style scoped>

</style>
