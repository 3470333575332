<template>
    <div class="main">
        <div class="head">
            <el-button plain
                       @click="editState(1)"
                       :class="state == 1? 'buttonSelect':''"
                       size="mini">按小时统计</el-button>
            <el-button plain
                       @click="editState(2)"
                       :class="state == 2? 'buttonSelect':''"
                       size="mini">按日统计</el-button>
            <el-button plain
                       @click="editState(3)"
                       :class="state == 3? 'buttonSelect':''"
                       size="mini">按月统计</el-button>

            <div class="dateBox"
                 v-if="state === 1">
                <el-date-picker v-model="dateHours.day"
                                size="mini"
                                align="right"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                @change="changeEvent"
                                :picker-options="pickerOptions">
                </el-date-picker>
                <el-time-picker is-range
                                v-model="dateHours.hour"
                                size="mini"
                                format='HH:mm:ss'
                                @change="changeEvent"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="选择时间范围"
                                :picker-options="pickerOptions">
                </el-time-picker>
            </div>

            <div class="dateBox"
                 v-if="state === 2">
                <el-date-picker v-model="dateDay"
                                type="daterange"
                                size="mini"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @blur="changeEvent"
                                :picker-options="pickerOptions">
                </el-date-picker>
            </div>
            <div class="dateBox"
                 v-if="state === 3">
                <el-date-picker v-model="dateMonth"
                                size="mini"
                                type="monthrange"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @blur="changeEvent"
                                range-separator="至"
                                start-placeholder="开始月份"
                                end-placeholder="结束月份"
                                :picker-options="pickerOptions">
                </el-date-picker>
            </div>

        </div>

        <div class="view"
             v-loading="loading">
            <el-row class="view-box">
                <el-col class="view-buttons">
                    <el-button type="info"
                               style="margin-right:10px">人数统计</el-button>
                    <el-button type="info"
                               @click="tapView(1)"
                               icon="el-icon-s-data"></el-button>
                    <el-button type="info"
                               @click="tapView(2)"
                               icon="el-icon-menu"></el-button>
                </el-col>
                <el-col :span="24"
                        v-show="viewState == 1">
                    <div ref="wrap"
                         class="wrap"></div>
                </el-col>
                <el-col :span="24"
                        v-show="viewState == 2">
                    <ja-table v-model="fromData"
                              :columns="columns"
                              :tableData="tableData"
                              :conditionData="conditionData"
                              table_MaxHeight="800"
                              :showIndex="false"
                              :showPage="false"
                              :loading="loading"></ja-table>
                </el-col>

            </el-row>
        </div>
    </div>
</template>
<script>
import { getHour, getMoth, getDay } from '@/api'
export default {
    data() {
        return {
            dateHours: {
                day: '',
                hour: [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 59)]
            },
            dateYear: {
                year1: '',
                year2: ''
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            state: 1,
            viewState: 1,
            fromData: [],
            columns: [
                { label: '时间', prop: 'thisListTime' },
                { label: '本期人数统计（人次）', prop: 'thisListCount' },
                { label: '环比日期', prop: 'ringRationTime' },
                { label: '环比人数统计（人次）', prop: 'ringRationCount' },
                { label: '同比日期', prop: 'yearListTime' },
                { label: '同比人数统计（人次）', prop: 'yearListCount' },
            ],
            columnsData: [
                { label: '时间', prop: 'thisListTime' },
                { label: '本期人数统计（人次）', prop: 'thisListCount' },
                { label: '环比日期', prop: 'ringRationTime' },
                { label: '环比人数统计（人次）', prop: 'ringRationCount' },
                { label: '同比日期', prop: 'yearListTime' },
                { label: '同比人数统计（人次）', prop: 'yearListCount' },
            ],
            dateDay: '',
            dateMonth: '',
            ringRation: [[], []],//环比
            thisList: [[], []],//本期
            yearList: [[], []],//同比
            tableData: [],
            title: null,
            conditionData: [
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/passenger/upload/ratio', rqMethod: 'post' }, limit: 'passengerFlow::statistics::download' }
            ],
            rqData: {},
            loading: false,
            viewTitle: ''
        }
    },

    mounted() {
        let date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth() + 1,
            strDate = date.getDate(),
            h = date.getHours(),
            m = date.getMinutes(),
            s = date.getSeconds()
        if (h >= 0 && h <= 9) h = '0' + h
        if (m >= 0 && m <= 9) m = '0' + m
        if (s >= 0 && s <= 9) s = '0' + s
        if (month >= 1 && month <= 9) month = '0' + month
        if (strDate >= 0 && strDate <= 9) strDate = '0' + strDate
        let dayMonth = date.getMonth() + 1
        let dayYear =  date.getFullYear()
        if (month - 1 == 0) {
            dayMonth = month - 1 == 0 ? '12' : month - 1
            dayYear = year - 1
        }
        if (dayMonth >= 1 && dayMonth <= 9) dayMonth = '0' + dayMonth
        this.dateDay = [`${dayYear}-${dayMonth}-01 00:00:00`, `${year}-${month}-${strDate} 23:59:59`]
        this.dateMonth = [`${year - 1}-${month}-01 00:00:00`, `${year}-${month}-01 23:59:59`]
        this.dateHours.day = `${year}-${month}-${strDate}`
        this.changeEvent()
    },
    methods: {
        getInit(data, type) {
            this.ringRation = [[], []]//环比
            this.thisList = [[], []]//本期
            this.yearList = [[], []]//同比       
            this.tableData = []
            this.loading = true
            if (type == 'hour') {
                getHour(data).then(res => {
                    let data = res.data
                    let tableArr = []
                    if (Array.isArray(data.ringRation)) {
                        data.ringRation.map((item, i) => {
                            this.ringRation[0].push(item.time)
                            this.ringRation[1].push(item.count)
                            tableArr[i] = Object.assign({}, { ringRationTime: item.time, ringRationCount: item.count })
                        })
                    }
                    if (Array.isArray(data.thisList)) {
                        data.thisList.map((item, i) => {
                            this.thisList[0].push(item.time)
                            this.thisList[1].push(item.count)
                            if (tableArr[i]) {
                                tableArr[i] = Object.assign(tableArr[i], { thisListTime: item.time, thisListCount: item.count })
                            } else {
                                tableArr[i] = Object.assign({}, { thisListTime: item.time, thisListCount: item.count })
                            }
                        })
                    }
                    if (Array.isArray(data.yearList)) {
                        data.yearList.map((item, i) => {
                            this.yearList[0].push(item.time)
                            this.yearList[1].push(item.count)
                            if (tableArr[i]) {
                                tableArr[i] = Object.assign(tableArr[i], { yearListTime: item.time, yearListCount: item.count })
                            } else {
                                tableArr[i] = Object.assign({}, { yearListTime: item.time, yearListCount: item.count })
                            }
                        })
                    }
                    this.tapView(this.viewState)
                    this.tableData = tableArr
                    this.loading = false
                })
            } else if (type == 'day') {
                getDay(data).then(res => {
                    let data = res.data
                    let tableArr = []

                    if (Array.isArray(data.ringRation)) {
                        data.ringRation.map((item, i) => {
                            this.ringRation[0].push(item.time)
                            this.ringRation[1].push(item.count)
                            tableArr[i] = Object.assign({}, { ringRationTime: item.time, ringRationCount: item.count })
                        })
                    }
                    if (Array.isArray(data.thisList)) {
                        data.thisList.map((item, i) => {
                            this.thisList[0].push(item.time)
                            this.thisList[1].push(item.count)
                            if (tableArr[i]) {
                                tableArr[i] = Object.assign(tableArr[i], { thisListTime: item.time, thisListCount: item.count })
                            } else {
                                tableArr[i] = Object.assign({}, { thisListTime: item.time, thisListCount: item.count })
                            }
                        })
                    }
                    if (Array.isArray(data.yearList)) {
                        data.yearList.map((item, i) => {
                            this.yearList[0].push(item.time)
                            this.yearList[1].push(item.count)
                            if (tableArr[i]) {
                                tableArr[i] = Object.assign(tableArr[i], { yearListTime: item.time, yearListCount: item.count })
                            } else {
                                tableArr[i] = Object.assign({}, { yearListTime: item.time, yearListCount: item.count })
                            }
                        })
                    }
                    this.tapView(this.viewState)
                    let tablelength = data.thisList.length
                    tableArr = tableArr.slice(0,tablelength)
                    this.tableData = tableArr
                    this.loading = false
                })
            } else if (type == 'month') {
                getMoth(data).then(res => {
                    let data = res.data
                    let tableArr = []
                    if (Array.isArray(data.ringRation)) {
                        data.ringRation.map((item, i) => {
                            this.ringRation[0].push(item.time)
                            this.ringRation[1].push(item.count)
                            tableArr[i] = Object.assign({}, { ringRationTime: item.time, ringRationCount: item.count })
                        })
                    }
                    if (Array.isArray(data.thisList)) {
                        data.thisList.map((item, i) => {
                            this.thisList[0].push(item.time)
                            this.thisList[1].push(item.count)
                            if (tableArr[i]) {
                                tableArr[i] = Object.assign(tableArr[i], { thisListTime: item.time, thisListCount: item.count })
                            } else {
                                tableArr[i] = Object.assign({}, { thisListTime: item.time, thisListCount: item.count })
                            }
                        })
                    }
                    if (Array.isArray(data.yearList)) {
                        data.yearList.map((item, i) => {
                            this.yearList[0].push(item.time)
                            this.yearList[1].push(item.count)
                            if (tableArr[i]) {
                                tableArr[i] = Object.assign(tableArr[i], { yearListTime: item.time, yearListCount: item.count })
                            } else {
                                tableArr[i] = Object.assign({}, { yearListTime: item.time, yearListCount: item.count })
                            }
                        })
                    }
                    this.tapView(this.viewState)
                    this.tableData = tableArr
                    this.loading = false
                })
            }

        },
        editState(state) {
            if (this.state == state) return
            this.state = state
            this.viewTitle = ''
            switch (state) {
                case 1:
                    this.columns = this.columnsData
                    let str = []
                    let arr = this.dateHours.hour.map(item => {
                        let date = new Date(item)
                        let h = date.getHours()
                        let m = date.getMinutes()
                        let s = date.getSeconds()
                        if (h >= 0 && h <= 9) h = '0' + h
                        if (m >= 0 && m <= 9) m = '0' + m
                        if (s >= 0 && s <= 9) s = '0' + s
                        str.push(`${h}:${m}:${s}`)
                        return `${this.dateHours.day} ${h}:${m}:${s}`
                    })
                    let dayStr = this.dateHours.day.split('-')
                    this.viewTitle = `${dayStr[0]}年${dayStr[1]}月${dayStr[2]}日 ${str[0]}至${str[1]}`
                    this.rqData = { startTime: arr[0], endTime: arr[1], type: 'hour,cameraIndexCode' }
                    this.getInit({ startTime: arr[0], endTime: arr[1], type: 'hour,cameraIndexCode' }, 'hour')
                    break
                case 2:
                    this.columns = this.columnsData
                    this.rqData = { startTime: this.dateDay[0], endTime: this.dateDay[1], type: 'day,cameraIndexCode' }
                    this.getInit({ startTime: this.dateDay[0], endTime: this.dateDay[1], type: 'day,cameraIndexCode' }, 'day')
                    let dLength = this.dateDay[0].indexOf(':') - 2
                    let dStr1 = this.dateDay[0].slice(0, dLength).split('-')
                    let dStr2 = this.dateDay[1].slice(0, dLength).split('-')
                    this.viewTitle = `${dStr1[0]}年${dStr1[1]}月${dStr1[2]}日 至 ${dStr2[0]}年${dStr2[1]}月${dStr2[2]}日`
                    break
                case 3:
                    this.columns = this.columns.slice(0,4)
                    let mLength = this.dateMonth[0].lastIndexOf('-')
                    let mStr1 = this.dateMonth[0].slice(0, mLength).split('-')
                    let mStr2 = this.dateMonth[1].slice(0, mLength).split('-')
                    this.viewTitle = `${mStr1[0]}年${mStr1[1]}月 至 ${mStr2[0]}年${mStr2[1]}月`
                    this.rqData = { startTime: this.dateMonth[0], endTime: this.dateMonth[1], type: 'month,cameraIndexCode' }
                    this.getInit({ startTime: this.dateMonth[0], endTime: this.dateMonth[1], type: 'month,cameraIndexCode' }, 'month')
                    break

            }

        },
        async tapView(state) {
            if (state == 1) {
                await this.setOptions()
                this.$forceUpdate()
            }
            this.viewState = state
        },
        async setOptions() {
            let options = {
                title: {
                    text: `人脸客流同环比 ${this.viewTitle} 人数统计 柱状图`,
                    left: 'center'
                },
                legend: {
                    top: '5%',
                    data: ['本期', '环比', '同比'],
                    align: 'right',
                    right: '10%'
                },
                color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                },
                formatter: function (params) {
                    if (typeof params != 'object') return params
                    let str = '人数统计<br/>'
                    params.map(item => {
                        if (item.componentIndex == 0) {
                            str += `${item.marker}${item.seriesName}${this.thisList[0][item.dataIndex]}&nbsp&nbsp${item.value}个<br/>`
                        } else if (item.componentIndex == 1) {
                            str += `${item.marker}${item.seriesName}${this.ringRation[0][item.dataIndex]}&nbsp&nbsp${item.value}个<br/>`
                        } else if (item.componentIndex == 2) {
                            str += `${item.marker}${item.seriesName}${this.yearList[0][item.dataIndex]}&nbsp&nbsp${item.value}个<br/>`
                        }
                    })

                    return str
                }.bind(this),
                xAxis: [
                    {
                        type: 'category',
                        data: this.thisList[0],
                        axisPointer: {
                            type: 'none',
                        }
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位:人次',
                        axisTick: {
                            show: false,
                        },
                        axisPointer: {
                            label: {
                                show: true,
                            }
                        },
                        nameGap: 20,
                        nameTextStyle: {
                            fontSize: this.util.setFontSize(16, 1920),
                        },

                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: this.util.setFontSize(14, 1920),
                        },
                    }
                ],
                series: [
                    {
                        name: '本期',
                        type: 'bar',
                        data: this.thisList[1],
                    },
                    {
                        name: '环比',
                        type: 'bar',
                        data: this.ringRation[1],
                    },
                    {
                        name: '同比',
                        type: 'bar',
                        data: this.yearList[1],
                    }
                ]
            }
            if(this.state == 3){
                options.legend.data = options.legend.data.slice(0,2)
                options.series = options.series.slice(0,2)
            }
            const myChart = this.$echarts.init(this.$refs.wrap)
            myChart.setOption(options)
        },
        changeEvent() {
            this.viewTitle = ''
            switch (this.state) {
                case 1:
                    let str = []
                    let arr = this.dateHours.hour.map(item => {
                        let date = new Date(item)
                        let h = date.getHours()
                        let m = date.getMinutes()
                        let s = date.getSeconds()
                        if (h >= 0 && h <= 9) h = '0' + h
                        if (m >= 0 && m <= 9) m = '0' + m
                        if (s >= 0 && s <= 9) s = '0' + s
                        str.push(`${h}:${m}:${s}`)
                        return `${this.dateHours.day} ${h}:${m}:${s}`
                    })
                    this.rqData = { startTime: arr[0], endTime: arr[1], type: 'hour,cameraIndexCode' }
                    this.getInit({ startTime: arr[0], endTime: arr[1], type: 'hour,cameraIndexCode' }, 'hour')
                    let dayStr = this.dateHours.day.split('-')
                    this.viewTitle = `${dayStr[0]}年${dayStr[1]}月${dayStr[2]}日 ${str[0]}至${str[1]}`
                    break
                case 2:
                    let dLength = this.dateDay[0].indexOf(':') - 2
                    let dStr1 = this.dateDay[0].slice(0, dLength).split('-')
                    let dStr2 = this.dateDay[1].slice(0, dLength).split('-')
                    this.viewTitle = `${dStr1[0]}年${dStr1[1]}月${dStr1[2]}日 至 ${dStr2[0]}年${dStr2[1]}月${dStr2[2]}日`
                    this.rqData = { startTime: this.dateDay[0], endTime: this.dateDay[1], type: 'day,cameraIndexCode' }
                    this.getInit({ startTime: this.dateDay[0], endTime: this.dateDay[1], type: 'day,cameraIndexCode' }, 'day')
                    break
                case 3:
                    let mLength = this.dateMonth[0].lastIndexOf('-')
                    let mStr1 = this.dateMonth[0].slice(0, mLength).split('-')
                    let mStr2 = this.dateMonth[1].slice(0, mLength).split('-')
                    this.viewTitle = `${mStr1[0]}年${mStr1[1]}月 至 ${mStr2[0]}年${mStr2[1]}月`
                    this.rqData = { startTime: this.dateMonth[0], endTime: this.dateMonth[1], type: 'month,cameraIndexCode' }
                    this.getInit({ startTime: this.dateMonth[0], endTime: this.dateMonth[1], type: 'month,cameraIndexCode' }, 'month')
                    break
            }
        },
        rqParmas() {
            return this.rqData
        }
    }
}
</script>
<style lang="scss" scoped>
.main {
    padding: 0 1vw;
}
.head {
    display: flex;
    .el-button {
        margin-left: 0;
    }
}
.dateBox {
    margin-left: 10px;
}
.buttonSelect {
    color: rgb(2, 167, 240);
}
.mLeft {
    margin-left: 20px;
}
.view-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    .el-button {
        margin-left: 0;
    }
}
.wrap {
    width: 100%;
    height: 500px;
}
.view-box {
    margin: 20px 0;
}
</style>