<template>
  <div class="edit">
    <el-dialog v-dialogDrag :close-on-click-modal="false"
      title="票据开具登记"
      center
      :visible.sync="show"
      width="30%"
      @close="close"
      :before-close="close"
    >
      <ja-form
       :rules="rules"
       v-model="rowFormData" 
       :formArray="rowForm"
        @reset="close" 
        @save="saveForm" 
        v-if="show"
        labelWidth="120px">
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import { addInvoicingData } from '../chargeBillManagement'
import {invoiCing } from '@/api'
export default {
  inject: [ 'getInit'],
  name: 'EditOccupant',
  components: {
    JaForm
  },
  data () {
    return {
      rowForm: addInvoicingData,
      rowFormData: {},
      rules: {
          invoiceDate: [{ required: true, message: '请选择开票日期', trigger: 'blur' }],
          invoiceSubtype: [{ required: true, message: '请选择票据类型', trigger: 'blur' }],
          invoiceType: [{ required: true, message: '请选择发票类型', trigger: 'blur' }],
          invoiceAmount: [{ required: true, trigger: 'change', validator:this.util.formRules.checkNumber({fixedNum:2,})}]
        },
      show: false,
      billCode:null
    }
  },
  watch:{
    'rowFormData.invoiceType':{
      handler(newVal,oldVal){
        if(newVal == 1){
          this.$set(this.rowForm[2], 'hide', true)
        }else{
          this.$set(this.rowForm[2], 'hide', false)
        }
        
      }
    }
  },
  methods: {
    openBillingRegistration(billCode){
      this.billCode = billCode
      this.show = true
    },
    /** 保存表单 */
    saveForm () {
      let obj = this.rowFormData
      if(obj.invoiceType === 1){
        obj.invoiceSubtype = null
      }
      obj.billCode = this.billCode
      invoiCing(obj).then((res) =>{
        if(res.code === 200){
          this.getInit()
          this.close()
          return this.$message.success('开票成功')
        }
      }).catch((error)=>{
        return this.close()
      })
    },
    /** 关闭弹出框 */
    close () {
      this.show = false
      this.rowFormData = {}
      this.$emit('closeForm', false)
    }
  }
}
</script>

<style less="scss" scoped>
    .el-form-item__content .el-input-number .el-input__inner {
        padding: 0;
    }
</style>
