<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @lookRoom="lookRoom"
                  @deleteItem="deleteItem"
                  @editItem="editItem"
                  @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/物资供应商管理模板.xlsx', filename: '物资供应商管理模板', uploadURL: '/materialsSupplierInfo/upload'})">
        </ja-table>
        <add-edit ref="addRefs"></add-edit>
        <show-details ref="detailRefs"></show-details>
        <import-form ref='importForm'
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
    </div>
</template>

<script>
import importForm from '@/views/home/components/common/importForm.vue'
import checkResult from '@/views/home/components/common/checkResult.vue'
import { getSupplierList, deleteSupplier } from '@/api'
import table from '@/views/mixins/table'
import addEdit from './components/addEdit.vue'
import showDetails from './components/showDetails.vue'
export default {
    title: '物资供应商管理',
    mixins: [table],
    name: 'supplyChain',
    components: {
        addEdit,
        showDetails,
        importForm,
        checkResult
    },
    data() {
        return {
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'materialName', attribute: { placeholder: '物资名称', size: 'small' } },
                { input_type: 'select', key: 'materialType', attribute: { placeholder: '物资类型', size: 'small' }, options: [] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'material::supplyChain::add' },
                { normalBtn: 'import', text: '导入', limit: 'material::supplyChain::import' }
            ],
            columns: [
                // 表头数据
                { label: '物资类型', prop: 'materialTypeName' },
                { label: '物资名称', prop: 'materialName' },
                { label: '物资供应商单位', prop: 'supplierCompany' },
                { label: '负责人', prop: 'principal' },
                { label: '联系方式', prop: 'contact' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'material::supplyChain::show' },
                        { text: '编辑', method: 'editItem', limit: 'material::supplyChain::edit' },
                        { text: '删除', method: 'deleteItem', limit: 'material::supplyChain::delete' },

                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,

        }
    },
    mounted() {
        let index1 = this.conditionData.findIndex((e) => e.key === 'materialType')
        this.util.rqOptionsList({
            who: [this.conditionData[index1]],
            rqData: { pageIndex: 1, pageSize: 100, dictType: 'material_type' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        getInit() {
            getSupplierList(this.filterData).then((res) => {
                if (res.code === 200) {
                    this.tableData = res.data.data
                    this.total = res.data.total
                    this.loading = false
                }
            })
        },
        deleteItem(row) {
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteSupplier(row.id).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getInit()
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        append() {
            this.$refs.addRefs.init()
        },
        editItem(row) {
            this.$refs.addRefs.init(1, row)
        },
        lookRoom(row) {
            this.$refs.detailRefs.init(row)
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        }
    }
}
</script>

<style scoped>
.download {
    width: 5vw;
}
</style>

