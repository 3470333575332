<template>
    <div>
        <el-dialog
            v-dialogDrag
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="show"
            width="45%"
            @close="close"
            :before-close="close"
        >
            <ja-form
                class="opFrom"
                :rules="rules"
                v-model="rowFormData"
                :formArray="rowForm"
                :allDisabled="allDisabled"
                @reset="show = false"
                @save="saveForm"
                v-if="show"
                v-loading="addLoading"
            >
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { addLedger,editLedger } from '@/api'
export default {
    inject: ['getInit'],
    components: {
        JaForm,
    },
    data() {
        return {
            rules: {},
            rowForm: [
                { input_type: 'input', key: 'deviceName', label: '设备名称', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'model', label: '设备型号', labelWidth: '7vw', col: 11 },
                { input_type: 'select', key: 'type', label: '设备类型', labelWidth: '7vw', col: 11, options: [] },
                { input_type: 'input', key: 'spec', label: '型号/规格/功率', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'unit', label: '单位', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'number', label: '数量', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'place', label: '运行地点', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'registrationTime', label: '入册时间', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'department', label: '维管部门', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'linkman', label: '负责人', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'attributeSpecification', label: '设备属性说明', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'manufacturer', label: '品牌/厂家', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'shelfLife', label: '保质期', labelWidth: '7vw', col: 11 },
                { input_type: 'input', key: 'accessories', label: '配件', labelWidth: '7vw', col: 11 },
                { input_type: 'input', type: 'textarea', key: 'remark', label: '备注', labelWidth: '7vw', col: 24 },
            ],
            rowFormData: {},
            title: '新增',
            show: false,
            isAdd: false,
            allDisabled: false,
            addLoading: false,
        }
    },
    mounted() {
        let length = this.rowForm.findIndex((e) => e.key === 'type')
        this.util.rqOptionsList({
            who: [this.rowForm[length]],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'equipment _type' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        init(state, row) {
            this.state = state
            this.show = true
            this.rowFormData = {}
            this.allDisabled = false
            this.isAdd = true
            if (state == 2) {
                this.isAdd = false
                this.title = '编辑'
                this.rowFormData = { ...row }
            } else if (state == 3) {
                this.isAdd = false
                this.title = '查看驻户信息'
                this.allDisabled = true
                this.rowFormData = { ...row }
            }
        },
        saveForm() {
            this.addLoading = true
            if (this.isAdd) {
                addLedger(this.rowFormData).then((res) => {
                    this.show = false
                    this.addLoading = false
                    this.getInit()
                }).catch((res) => {
                    this.addLoading = false
                })
            } else {
                editLedger(this.rowFormData).then((res) => {
                    this.show = false
                    this.addLoading = false
                    this.getInit()
                }).catch((res) => {
                    // this.show = false
                    this.addLoading = false
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.formItemText {
    margin-bottom: 15px !important;
    background-color: #ebffff;
    border-bottom: 1px solid rgb(217, 217, 217);
    padding-left: 10px;
}
</style>