<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @lookRoom="lookRoom"
                  @deleteItem="deleteItem"
                  @editItem="editItem"
                  @disable="disable"
                  @showImg="showImg"
                  @limitConfig="limitConfig"
                  @enable="enable"
                  @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/物资管理导入模板.xlsx', filename: '物资管理导入模板', uploadURL: '/materialsDetailedInfo/upload'})">
        </ja-table>

        <import-form ref='importForm'
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
        <add-edit-material ref="materialRefs"></add-edit-material>
        <el-dialog :visible.sync="dialogTableVisible">
            <el-image style="width: 100%; height: 100%"
                      :src="urlImg"
                      v-if="urlImg"
                      fit="加载失败"></el-image>
            <i v-else
               class="el-icon-picture-outline"></i>
        </el-dialog>
        <!-- 权限配置弹框 -->
        <el-dialog
            title="权限配置"
            :visible.sync="limitVisible"
            width="30%"
            >
            <div style="margin-bottom: 10px;">上次权限人：{{ LastlimitUser }}</div>
            <span>权限人：</span>
            <el-select v-model="limitUser" placeholder="请选择">
                <el-option
                v-for="item in UserData"
                :key="item.id"
                :label="item.username"
                :value="item.id">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="limitVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveLimitConfig">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import importForm from '@/views/home/components/common/importForm.vue'
import checkResult from '@/views/home/components/common/checkResult.vue'
import { deleteMateria, getMaterialList, editMateria,setThreshold,getrqParameterList,getUserInfo  } from '@/api'
import table from '@/views/mixins/table'
import addEditMaterial from './addEditMaterial.vue'
export default {
    title: '全部物资',
    mixins: [table],
    name: 'toRentList',
    components: {
        addEditMaterial,
        importForm,
        checkResult
    },
    data() {
        return {
            dialogTableVisible: false,
            urlImg: '',
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'materialNumber', attribute: { placeholder: '物资编号', size: 'small' } },
                { input_type: 'select', key: 'materialType', attribute: { placeholder: '物资类型', size: 'small' }, options: [] },
                { input_type: 'input', key: 'materialName', attribute: { placeholder: '物资名称', size: 'small' } },
                { input_type: 'select', key: 'materialsStatus', attribute: { placeholder: '物资状态', size: 'small' }, options: [] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { input_type: 'button', text:'权限配置',method: 'limitConfig',limit:'materialLists::allMaterials::limitconfig' },
                // { normalBtn: 'add', limit: 'materialLists::allMaterials::add' },
                // { normalBtn: 'import', text: '导入', limit: 'materialLists::allMaterials::import' }
            ],
            columns: [
                // 表头数据
                { label: '物资编号', prop: 'materialNumber' },
                { label: '物资类型', prop: 'materialTypeName' },
                { label: '物资名称', prop: 'materialName' },
                { label: '数量（个）', prop: 'amount' },
                { label: '价格（元）', prop: 'price' },
                { label: '状态', prop: 'materialsStatusName' },
                {
                    label: '图片',
                    input_type: 'button',
                    prop: 'imgUrl',
                    list: [
                        { text: '查看', method: 'showImg' },
                    ]
                },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'materialLists::allMaterials::show' },
                        { text: '禁用', method: 'disable', limit: 'materialLists::allMaterials::disable' },
                        { text: '启用', method: 'enable', limit: 'materialLists::allMaterials::enable' },
                        { text: '编辑', method: 'editItem', limit: 'materialLists::allMaterials::editItem' },
                        // { text: '删除', method: 'deleteItem', limit: 'materialLists::allMaterials::deleteItem' },

                    ]
                }
            ],
            results:{},
            tableData: [],
            loading: false,
            total: 30,
            limitVisible:false,
            LastlimitUser:'',//上次权限人
            UserData:[],//用户列表
            limitUser:'',//当前权限人
            limitid:'',//权限id

        }
    },
    mounted() {
        let index1 = this.conditionData.findIndex((e) => e.key === 'materialType')
        this.util.rqOptionsList({
            who: [this.conditionData[index1]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'material_type' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
        let index2 = this.conditionData.findIndex((e) => e.key === 'materialsStatus')
        this.util.rqOptionsList({
            who: [this.conditionData[index2]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'materials_status' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
       async getInit() {
            getMaterialList(this.filterData)
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
            await getUserInfo({ pageIndex :1, pageSize :999}).then(res => {
                this.UserData = res.data.data
            })
            await getrqParameterList('materials_audit_employee').then(res => {
                this.limitid = res.data.configId
                this.UserData.forEach(item => {
                    if(item.id == res.data.configValue){
                        this.LastlimitUser = item.username
                    }
                })
            })
        },
        editDataFormat(row, btn) {
            if (row.materialsStatus == 1 && btn.text == '启用' || row.materialsStatus == 2 && btn.text == '禁用') {
                return false
            } else {
                return true
            }
        },
        enable(row) {
            this.$confirm('是否确定启用？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                row.materialsStatus = '1'
                editMateria(row).then(() => {
                    this.getInit()
                })
                this.$message({
                    type: 'success',
                    message: '启用成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消启用'
                });
            });
        },
        disable(row) {
            this.$confirm('禁用后该物资将不再申请范围当中，是否确定禁用？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                row.materialsStatus = '2'
                console.log(row);
                editMateria(row).then(() => {
                    this.getInit()
                })
                this.$message({
                    type: 'success',
                    message: '禁用成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消禁用'
                });
            });
        },
        deleteItem(row) {
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteMateria(row.id).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getInit()
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        editItem() {
            console.log('修改');
        },
        append() {
            this.$refs.materialRefs.init()
        },
        editItem(row) {
            this.$refs.materialRefs.init(1, row)
        },
        lookRoom(row) {
            this.$refs.materialRefs.init(2, row)
        },
        showImg(row) {
            this.dialogTableVisible = true
            this.urlImg = row.imgUrl
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
        //更新权限
        saveLimitConfig(){
            setThreshold({ configValue:this.limitUser, configId:this.limitid }).then(res => {
                console.log(res);
            })
            this.limitVisible = false
            this.loading = true
            this.getInit()
        },
        //权限配置弹窗
        limitConfig(){
            this.limitVisible = true
        },
    }
}
</script>

<style scoped>
.download {
    width: 5vw;
}
.el-icon-picture-outline {
    height: 400px;
    width: 100%;
    font-size: 150px;
    font-weight: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d2d2d2;
}
</style>

