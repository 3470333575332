<template>
    <div class="edit">
        <el-dialog v-dialogDrag :close-on-click-modal="false" :title="title" :visible.sync="show" width="25%" @close="close" :before-close="close">
            <ja-form :rules="rules" v-model="rowFormData" :formArray="rowForm" @reset="close" @save="saveForm" labelWidth="120px">
            </ja-form>
        </el-dialog>
    </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import { editCost } from '../billManage'
export default {
    name: 'EditOccupant',
    components: {
        JaForm
    },
    data () {
        return {
            rowForm: editCost,
            rowFormData: {},
            rules: {
                payProject: [
                    { required: true, message: '请选择收费项', trigger: 'blur' }
                ],
                payProjectDetail: [
                    { required: true, message: '请选择项目明细', trigger: 'blur' }
                ],
                consumption: [
                    { required: true, trigger: 'change',validator:this.util.formRules.checkNumber({fixedNum:2})}
                ],
                unitPrice: [
                    { required: true, trigger: 'change',validator:this.util.formRules.checkNumber({fixedNum:2,min:0})}
                ],
                times: [
                    { required: true, message: '请选择计费区间', trigger: 'blur',}
                ],
            }
        }
    },
    props: {
        title: String,
        id: String,
        show: {
            type: Boolean,
            default: false
        },
        costData: [Object, Array]
    },
    created () {
    },
    methods: {
        /** 保存表单 */
        saveForm () {
            if (this.rowFormData.consumption || this.rowFormData.unitPrice) {
                this.rowFormData.totalCost = this.rowFormData.consumption * this.rowFormData.unitPrice
            }
            this.close()
            this.rowFormData.time = this.rowFormData.times ? this.rowFormData.times[0] + ' ~ ' + this.rowFormData.times[1] : ''
            this.rowFormData.source = 1
            this.costData.push(this.rowFormData)
            this.rowFormData = {}
        },
        /** 关闭弹出框 */
        close () {
            this.$emit('closeFormCost', false)
        }
    }
}
</script>

<style scoped>
</style>
