<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title='title'>
        <ja-form :rules="rules" :allDisabled="allDisabled" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
    </el-dialog>
</template>
<script>
import { generatePublicWaterElectricity} from '@/api'
export default {
    inject: ['getInit'],
    data () {
        return {
            rowFormData: [
                { input_type: 'select', label: '类型', key: 'type', options: [{label:'公摊水费',value:'1'},{label:'公摊电费',value:'2'}],},
                { input_type: 'date', label: ' 日期', type: 'month', key: 'date',valueFormat: 'yyyy-MM'},
            ],
            rowForm: {},
            rules: {
                type: [{ required: true, message: '请选择类型', trigger: 'change' }],
                date: [{ required: true, message: '请选择日期', trigger: 'change' }],
            },
            loadingForm: false,
            labelWidth: `${this.util.setFontSize(140)}px`,
            showEditAll: false, // 显示新增/编辑弹窗,
            copyRowForm: [],
            allDisabled: false,
            indexObj:{},
            title:'生成公摊费用'
        }
    },
    mounted () {
    },
    watch: {
        
    },
    methods: {
        init () {
            this.showEditAll = true
        },
        saveForm () {
            this.loadingForm = true
            generatePublicWaterElectricity(this.rowForm)
                .then((res) => {
                    this.loadingForm = false
                    this.$message.success('提交成功')
                    this.showEditAll = false
                    this.getInit()
                })
                .catch((error) => {
                    this.loadingForm = false
                })
        }
    }
}
</script>
