
/** 菜单管理页面搜索栏 */
export const menuManagerQuery = [
  {
    input_type: 'input',
    key: 'menuName',
    label: '菜单名称：',
    attribute: {
      placeholder: '请输入菜单名称',
      size: 'small'
    }
  },
  {
    input_type: 'select',
    key: 'status',
    label: '状态：',
    attribute: {
      placeholder: '菜单状态',
      size: 'small'
    },
    options: [
      { label: '正常', value: 0 },
      { label: '停用', value: 1 }
    ]
  },
  { normalBtn: 'search' },
  { normalBtn: 'reset' },
]

/** 菜单管理页面表头栏 */
export const menuManagerColumn = [
  { label: '菜单名称', prop: 'menuName', width: 130, attribute: { showOverflowTooltip: true } },
  { label: '图标', prop: 'icon', width: 100, slotName: 'icon' },
  { label: '排序', prop: 'orderNum', width: 50 },
  { label: '状态', prop: 'status' },
  { label: '创建时间', prop: 'createTime' },
  {
    label: '操作',
    input_type: 'button',
    width: 140,
    attribute: {
      fixed: "right",
      align: 'center'
    },
    list: [
      { text: '修改', method: 'handleUpdate', limit: 'admin::menuPro::update' },
    ]
  }
]

/** 菜单管理页面新增/编辑校验 */
export const menuManagerRules = {
  menuType: [
    { required: true, message: '请选择层级目录', trigger: 'change' }
  ],
  menuName: [
    { required: true, message: '请输入菜单名称', trigger: 'blur' }
  ],
}
