<template>
    <el-dialog :visible.sync="show"
               :close-on-click-modal="false"
               width="80%"
               title="应收账款编辑">
        <ja-form :rules="rules"
                 v-if="show"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :btnBox="btnBox"
                 @editTabelRow="editTabelRow"
                 @selectSumRule="selectSumRule"
                 @selectServiceType="selectServiceType"
                 @blurNum="blurNum"
                 @deleteTabelRow="deleteTabelRow"
                 @append="append"
                 @reset="show = false"
                 @save="save">
            <template #mesContent>
                <el-tooltip placement="bottom">
                    <div slot="content">
                        计算优惠：如有减免调整后金额，则预计实收合计为调整后金额-优惠金额；
                        <br />
                        不计算优惠：按照调整后应收金额合;
                    </div>
                    <el-button type="text"
                               icon="el-icon-warning"
                               size=""></el-button>
                </el-tooltip>

            </template>
            <template #total>
                <span>合计：{{total}}</span>
            </template>
        </ja-form>
    </el-dialog>
</template>
<script>
import { editbizBillDetailNew, getbizBillDetailNewList, getChargingSettings } from '@/api'

export default {
    inject: ['getInit'],
    data () {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'text2', label: '驻户名称', key: 'tenantName', labelWidth: '110px', col: 5 },
                { input_type: 'text2', label: '租赁单元名称', key: 'roomName', labelWidth: '110px', col: 11 },
                { input_type: 'select', label: '合计规则', key: 'sumRule', labelWidth: '110px', col: 6, method: 'selectSumRule', options: [{ label: '不计算优惠', value: '0' }, { label: '计算优惠', value: '1' }] },
                { input_type: 'customize', customize: 'mesContent', label: '', col: 2, labelWidth: '10px' },
                { input_type: 'text2', label: '收费清单', key: 'amount', labelWidth: '110px', col: 11 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'amountDTOs',
                    isRequired: true,
                    border: true,
                    columns: [
                        { label: '收费项目类型', prop: 'serviceType' },
                        { label: '收费项目性质', prop: 'natureCharges' },
                        { label: '收费项目名称', prop: 'serviceName' },
                        { label: '计费周期', prop: 'date' },
                        { label: '缴费周期', prop: 'day' },
                        { label: '本期应收', prop: 'receivable' },
                        { label: '调整后应收', prop: 'adjustedReceivable', blurMethod: 'blurNum' },
                        { label: '优惠金额', prop: 'coupon', blurMethod: 'blurNum' },
                        { label: '未收金额', prop: 'uncollected' }
                    ]
                },
                { input_type: 'text2', label: '收费分项明细', key: 'detail', labelWidth: '110px', col: 11 },
                {
                    input_type: 'table',
                    col: '24',
                    noSearch: true,
                    showPage: false,
                    key: 'bizBillDetailNewDTOs',
                    isRequired: true,
                    border: true,
                    editDataFormat: this.editDataFormat,
                    columns: [
                        { column_type: 'select', label: '收费项目类型', prop: 'serviceType', width: '150', placeholder: '请选择', method: 'selectServiceType', options: [] },
                        { label: '收费项目性质', prop: 'natureCharges' },
                        { column_type: 'select', label: '收费项目名称', prop: 'serviceName', placeholder: '请选择', width: '150' },
                        { label: '计费周期', prop: 'date', width: '200' },
                        { label: '上期抄表', prop: 'lastReading' },
                        { label: '本期抄表', prop: 'thisReading' },
                        { label: '本期用量', prop: 'currentReading' },
                        { label: '倍率', prop: 'rate' },
                        { label: '缴费周期', prop: 'day' },
                        { column_type: 'number', label: '本期应收', prop: 'receivable', styleWidth: '100%', blurMethod: 'blurNum', min: 0, attribute: { controls: false, controlsPosition: 'left' } },
                        { column_type: 'number', label: '调整后应收', prop: 'adjustedReceivable', styleWidth: '100%', blurMethod: 'blurNum', min: 0, attribute: { controls: false, controlsPosition: 'left' } },
                        { column_type: 'number', label: '优惠金额', prop: 'coupon', styleWidth: '100%', blurMethod: 'blurNum', min: 0, attribute: { controls: false, controlsPosition: 'left' } },
                        { label: '未收金额', prop: 'uncollected' },
                        { column_type: 'input', label: '备注', prop: 'remark', width: '200' },
                        {
                            label: '操作',
                            input_type: 'button',
                            attribute: { fixed: "right", align: 'center' },
                            width: '150',
                            list: [
                                { text: '修改应收', method: 'editTabelRow', limit: 'contractFile::editRows::editTabelRow' },
                                { text: '删除', method: 'deleteTabelRow', limit: 'contractFile::editRows::deleteTabelRow' }
                            ]
                        }
                    ]
                },
                { input_type: 'customize', customize: 'total', label: '', col: 24, labelWidth: '10px' }
            ],
            show: false,
            rowForm: {},
            btnBox: [
                { label: '添加收费项', method: 'append', type: 'primary', monitor: true, col: 8, limit: 'contractFile::editRows::append' },
                { label: '取消', method: 'reset', col: 8 },
                { label: '确定', method: 'save', type: 'primary', monitor: true, col: 8, limit: 'contractFile::editRows::save' }
            ],
            total: 0,
            disabledList: { uncollectedDisabled: true, receivableDisabled: true, serviceTypeDisabled: true, serviceNameDisabled: true, adjustedReceivableDisabled: true, remarkDisabled: true,couponDisabled:true  }
        }
    },
    mounted () {
        const tableLength = this.rowFormData.findIndex(item => item.key === 'bizBillDetailNewDTOs')
        const length = this.rowFormData[tableLength].columns.findIndex(item => item.prop === 'serviceType')
        this.util.rqOptionsList({ rqUrl: '/sys/dictData/query', rqMethod: 'post', rqData: { dictType: 'chargeitem_type' }, labelName: 'dictLabel', valueName: 'dictValue' }).then(res => {
            this.rowFormData[tableLength].columns[length].options = res.filter(item => {
                if (item.label === '保证金类') {
                    item.disabled = true
                }
                return item
            })
        })
    },
    methods: {
        async init (row) {
            this.show = true
            this.total = 0
            this.rowForm = {}
            const res = await getbizBillDetailNewList({ billId: row.id, pageIndex: 1, pageSize: 1000 })
            const amountDTOMap = new Map()
            res.data.data.map(item => {
                this.total += Number(item.uncollected) || 0
                if (item.adjustedReceivable === 0) {
                    item.adjustedReceivable = null
                }
                item = Object.assign(item, this.disabledList)
                item.date = `${item.billDateStart}~${item.billDateEnd}`
                let serviceDTO = amountDTOMap.get(item.serviceName)
                if (serviceDTO !== undefined) {
                    serviceDTO.receivable += Number(item.receivable)
                    serviceDTO.adjustedReceivable += Number(item.adjustedReceivable)
                    serviceDTO.coupon += Number(item.coupon)
                    serviceDTO.uncollected += Number(item.uncollected)
                } else {
                    serviceDTO = {
                        serviceType: item.serviceType,
                        natureCharges: item.natureCharges,
                        serviceName: item.serviceName,
                        date: item.date,
                        day: item.day,
                        receivable: Number(item.receivable),
                        adjustedReceivable: Number(item.adjustedReceivable),
                        coupon: Number(item.coupon),
                        uncollected: Number(item.uncollected)
                    }
                }
                amountDTOMap.set(item.serviceName, serviceDTO)
            })
            this.total = this.total.toFixed(2)
            const amountDTOs = []
            amountDTOMap.forEach((value, key) => {
                amountDTOs.push(value)
            })
            this.rowForm = { ...row, bizBillDetailNewDTOs: [...res.data.data], amountDTOs: amountDTOs }
        },
        async save () {
            const obj = { ...this.rowForm, amountReceivable: this.total, status: 2, approvalStatus: "0" }
            await editbizBillDetailNew(obj)
            this.$message.success('操作成功')
            this.show = false
            this.getInit()
        },
        async append () {
            const obj = {
                id: Date.now(),
                date: this.rowForm.date,
                natureCharges: '临时性',
                adjustedReceivableDisabled: true,
                receivableDisabled: false
            }
            this.rowForm.bizBillDetailNewDTOs.push(obj)
        },
        deleteTabelRow (row) {
            const length = this.rowForm.bizBillDetailNewDTOs.findIndex(item => item.id === row.id)
            if (length >= 0) {
                this.rowForm.bizBillDetailNewDTOs.splice(length, 1)
            }
        },
        editTabelRow (row, val, itemData) {
            row.adjustedReceivableDisabled = false
            row.remarkDisabled = false
            //点击应收修改按钮，将应收金额赋值给调整后金额
            this.$set(this.rowForm.bizBillDetailNewDTOs[val.$index], 'adjustedReceivable',row.receivable)
        },
        blurNum (item, row, index) {
            let uncollected = null
            const { sumRule } = this.rowForm
            if (sumRule === '1') {
                //adjustedReceivableDisabled是判断调整后应收是否聚焦状态
                if (row.adjustedReceivable != null && !row.adjustedReceivableDisabled) {
                    uncollected = (row.adjustedReceivable || 0) - (row.coupon || 0)
                } else if (row.receivable) {
                    uncollected = (row.receivable || 0) - (row.coupon || 0)
                }
            } else {
                if(row.adjustedReceivable != null && !row.adjustedReceivableDisabled){
                    uncollected = row.adjustedReceivable 
                }else{
                    uncollected = row.receivable || 0
                }
            }
            if(Number(uncollected)<0 && !row.adjustedReceivableDisabled){
                row.coupon=0
                this.$set(this.rowForm.bizBillDetailNewDTOs[index], 'uncollected', Number(row.adjustedReceivable).toFixed(2))
                this.$message.error("优惠金额不能大于调整后金额！")
                return
            }
            if(Number(uncollected)<0 ){
                 row.coupon=0
                this.$set(this.rowForm.bizBillDetailNewDTOs[index], 'uncollected', Number(row.receivable).toFixed(2))
                this.$message.error("优惠金额不能大于本期应收金额！")
                return
            }

            this.$set(this.rowForm.bizBillDetailNewDTOs[index], 'uncollected', Number(uncollected).toFixed(2))
            this.total = 0
            const { bizBillDetailNewDTOs } = this.rowForm
            bizBillDetailNewDTOs.map(item => {
                this.total += Number(item.uncollected) || 0
            })
            this.total = this.total.toFixed(2)
        },
        editDataFormat (row, btn) {
            if (row.natureCharges === '周期性' && btn.text === '修改应收') {
                return true
            } else if ((row.serviceType !== '保证金类' && row.natureCharges === '临时性') && btn.text === '删除') {
                return true
            }
        },
        async selectServiceType (method, val, clo, itemData) {
            const res = await getChargingSettings({ serviceType: val, natureCharges: '临时性', pageIndex: 1, pageSize: 1000 })
            const options = []
            res.data.data.map(item => {
                options.push({ label: item.serviceName, value: item.serviceName })
            })
            this.$set(this.rowForm.bizBillDetailNewDTOs[itemData], 'serviceNameOptions', options)
        },
        selectSumRule (method, val) {
            this.total = 0
            const { bizBillDetailNewDTOs } = this.rowForm
            bizBillDetailNewDTOs.map(item => {
                if (val === '1') {
                    item.couponDisabled=false;
                     //adjustedReceivableDisabled是判断调整后应收是否聚焦状态
                    if (item.adjustedReceivable != null && !item.adjustedReceivableDisabled) {
                        item.uncollected = (item.adjustedReceivable || 0) - (item.coupon || 0)
                    } else if (item.receivable) {
                        item.uncollected = (item.receivable || 0) - (item.coupon || 0)
                    }
                } else {
                    item.couponDisabled=true;
                    item.coupon= Number(0).toFixed(2)
                    if(item.adjustedReceivable != null && !item.adjustedReceivableDisabled){
                        item.uncollected = item.adjustedReceivable 
                    }else{
                        item.uncollected = item.receivable || 0
                    }
                }
                item.uncollected = Number(item.uncollected).toFixed(2)
                this.total += Number(item.uncollected) || 0
            })
            this.total = this.total.toFixed(2)
            this.$set(this.rowForm, 'bizBillDetailNewDTOs', bizBillDetailNewDTOs)
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.input_text {
    border: 0 !important;
    padding: 0 !important;
}
/deep/.el-icon-warning {
    color: black;
    font-size: 16px;
}
</style>
