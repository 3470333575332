<template>
    <ul class="content" v-loading="loading">
        <li v-for="item in headerData.chartData">
            <div class="top">
                <div>
                    {{ !item.deptName ? '维修工单处理率' : item.deptName + '工单完成率' }}
                    <span v-if="item.contrast != 0">
                        <p :class="item.contrast == 2 ? 'green' : 'red'">{{ item.contrast == 2 ? '⬆' : '⬇' }}</p>
                    </span>
                </div>
            </div>
            <div class="bottom">
                <workStatistics class="box" :statisticsData="item"/>
            </div>
        </li>
        <li>
           <div class="top"><div>未完成工单数</div></div>
           <div class="bottom">
                <noworkChart :unfinishedWorkOrder="headerData.unfinishedWorkOrder" class="box"/>
           </div>
        </li>
    </ul>
</template>

<script>
import workStatistics from './workStatistics.vue'
import noworkChart from './noworkChart.vue'
export default {
    props: {
        headerData: Object, loading:Boolean
    },
    data() {
        return {
            loading: false
        }
    },
    components: {
        workStatistics, noworkChart
    },
}
</script>

<style lang="scss" scoped>
@import '@/style/theme.scss';

ul.content {
    color: #606266;
    display: flex;
    width: 100%;
    justify-content: space-between;

    li {
        width: 25%;
        background: white;
        margin:0 10px;
        border-radius: 20px;

        .top {
            text-align: center;
            width: 100%;
            height: 30px;
            position: relative;
            color: #4352D4;
            font-weight: 700;
            font-size: 16px;
            div {
                height: 30px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 50%);
                display: flex;
                align-items: center;

                span {
                    .green {
                        color: green;
                    }

                    .red {
                        color: red;
                    }

                    p {
                        font-size: 26px;
                    }
                }
            }

        }

        .bottom {
            >.box {
                height: 300px;
                width: 100%;
                flex: 1;
                text-align: center;
            }
        }
    }
}
</style>