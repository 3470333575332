<template>
  <!-- 字典数据 -->
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      :valueFormat="formatter"
      @dictonaList="dictonaList"
      @rowDelete="rowDelete"
      @getInit="getInit"
      @search="search"
      @reset="reset"
    >
      <template #img="{ row }">
        <img :src="row.peoplePic" alt="" />
      </template>
    </ja-table>
    <!-- 详情弹窗 -->
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="showDetail"
      title="修正凭证"
      :width="`${util.setFontSize(800)}px`"
    >
    </el-dialog>
    <!-- 新增/编辑 -->
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      :title="isAdd ? '新增' : '编辑'"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        @inclick="inclick"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
        <template #upload>
          <el-input-number class="upload" v-model="rowForm.dictSort" :max="1000" :min="1" controls-position="right"></el-input-number>
        </template>
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import { dictionDataList, addDicData, editDicData, delDicData } from '@/api'
import table from '@/views/mixins/table'
import {
  dicDataQuery,
  dicDataColumn,
  dicDataFormData,
  dicDataRules
} from '../systemSettingColumn'

export default {
  title: '字典数据',
  mixins: [table],
  name: 'index',
  data() {
    // //验证用户名是否存在
    // var validate = (rule, value, callback) => {
    //   if (value != "") {
    //     if (this.nameRules()) {
    //       callback(new Error("用户名已存在，请重新输入"));
    //     }
    //     callback();
    //   }
    // }
    return {
      typeCode: '',
      filterData: {
        // pageIndex: 1,
        // pageSize: 10
      },
      conditionData: dicDataQuery,
      columns: dicDataColumn,
      tableData: [],
      // 新增/编辑
      rowFormData: dicDataFormData,
      loading: false,
      total: 30,
      showDetail: false,
      labelWidth: `${this.util.setFontSize(120)}px`,
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      isAdd: true, // 新增/编辑
      rules: {
        dictLabel: [
          { required: true, message: '请输入字典标签', trigger: 'blur' }
        ],
        dictValue: [
          { required: true, message: '请输入字典键值', trigger: 'blur' },
          // { validator: validate, trigger: 'blur'}
        ],
        dictSort: [
          { required: true, message: '请输入显示排序', trigger: 'blur' }
        ],
      }
    }
  },
  created() {},
  methods: {
    // nameRules() {
    //   let params = {
    //     dictValue : this.rowForm.dictValue
    //   }
    //   dictionDataList(params).then(res=>{
    //     this.a = res.message
    //     return this.a
    //   })
    //   //通过后端接口判断 返回true和false
    //   if(this.a == 'ok'){
    //     return false
    //   }else{
    //     return true;
    //   }
    // },
    getInit() {
      this.loading = true
      // 获取楼层列表
      dictionDataList({
        pageIndex: this.filterData.pageIndex,
        pageSize: this.filterData.pageSize,
        dictType: this.$route.query.typeCode,
        dictCode: this.filterData.dictCode,
        dictLabel: this.filterData.dictLabel,
        dictValue: this.filterData.dictValue,
        dictSort: this.filterData.dictSort,
        remark: this.filterData.remark,
        status: this.filterData.status
      }).then(({ data }) => {
        this.tableData = data.data
        this.total = data.total
        this.loading = false
      })
    },
    // 新增
    append() {
      console.log(this.rowFormData);
      this.isAdd = true
      this.rowForm = {
        'dictType': this.$route.query.typeCode,
        status: 0
      }
      this.showEditAll = true
    },
    // 编辑
    rowEdit(row) {
      this.isAdd = false
      this.showEditAll = true
      this.$nextTick(() => {
        this.rowFormData.forEach((item) => {
          this.$set(this.rowForm, item.key, row[item.key])
        })
      })
      this.$set(this.rowForm, 'dictId', row.dictId)
      this.$set(this.rowForm, 'dictCode', row.dictCode)
    },
    // 列表跳转
    dictonaList(row) {
      this.$router.push({
        path: '/home/sysSetting/dictionaList',
        query: { dictType: row.dictType }
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该字典').then((res) => {
        delDicData(row.dictCode).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    // 保存
    saveForm() {
      this.loading_form.isShow = true
      if (this.isAdd) {
        addDicData(this.rowForm)
          .then(({ msg }) => {
            this.loading_form.isShow = false
            this.showEditAll = false
            this.$message.success(msg)
            this.getInit()
          })
          .catch((err) => {
            console.log(err)
            this.loading_form.isShow = false
          })
      } else {
        editDicData(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.showEditAll = false
            this.$message.success(res.msg)
            this.getInit()
          })
          .catch((err) => {
            console.log(err)
            this.loading_form.isShow = false
          })
      }
    },
    formatter(val, key) {
      if (key === 'status') {
        const statusVal = { 0: '正常', 1: '停用' }
        return statusVal[val]
      }
      return val
    }
  },

  watch: {
    showEditAll(val) {
        console.log(val);
      if (!val) {
        this.$set(this, 'rowForm', {})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-col .el-form-item .el-input__inner {
  height: 40px;
}
/deep/ .el-select {
  width: 100% !important;
}
</style>
