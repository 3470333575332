<template>
  <!-- 表格 -->
  <div class="content">
    <title-box title="收缴率">
      <template #right>
        <div class="selections">
          <el-select v-model="activeYear" placeholder="年份" @change="selectYear" class="selectBox" size="small">
            <el-option v-for="item in years" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="activeMonth" placeholder="类型" @change="selectType" class="selectBox typeSelect" size="small">
            <el-option v-for="item in monthList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </template>
    </title-box>
    <title-top title="应收" :num="num" unit="W" subTitle="" />
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import titleTop from './common/titleTop.vue'

export default {
  components: {
    JaInitChart, TitleBox, titleTop
  },
  data() {
    return {
      options: null,
      num: '100',
      // 年份
      activeYear: '',
      years: [
        { label: '2019', value: '2019' },
        { label: '2020', value: '2020' },
        { label: '2021', value: '2021' },
      ],
      // 月份
      activeMonth: '',
      monthList: []
    }
  },
  created() {
    this.activeYear = '2021'
    this.monthList = this.util.numberRange(1,12,null,null,'月',true)
    this.setOptions()
  },
  methods: {
    selectYear(val) {
      console.log('选择年份', val)
    },
    selectType(val) {
      console.log('选择月份', val)
    },
    setOptions() {
      let colorBar = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: '#8f15ff', },
        { offset: 1, color: '#d095ff' }
      ])
      let initData = [
        { value: 30, name: '实收', itemStyle: { color: colorBar, }, },
        { value: 28, name: '未收', itemStyle: { color: '#333333' } },
      ]
      this.options = {
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}间 ({d}%)'
        },
        legend: {
          orient: 'vertical',
          right: this.util.setFontSize(70),
          top: '20%',
          width: '10%',
          height: '80%',
          itemGap: this.util.setFontSize(18, 1920),
          textStyle: { //图例文字的样式
            fontSize: this.util.setFontSize(15, 1920),
          },
          formatter: function (val) {
            let item = initData.find(v => v.name == val)
            return `${val}  ${item.value}  W`;
          },
          data: [
            '实收',
            '未收',
          ]
        },
        series: [
          {
            // name: 'Area Mode',
            type: 'pie',
            startAngle: 130,
            radius: ['20%', '40%'],
            center: ['30%', '30%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 5
            },
            emphasis: {
              label: {
                show: true,
                fontSize: this.util.setFontSize(15),
                fontWeight: 'bold',
                formatter: '{d}%'
              }
            },
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: initData
          }
        ]

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  overflow: hidden;
  border-radius: 20px;
  width: 100%;
  height: 550px;
  background: white;
  overflow: hidden;
  .selections {
    .selectBox {
      width: 90px;
    }
    .typeSelect {
      margin-left: 10px;
    }
  }
}
.echart {
  height: 100%;
  width: 100%;
}
</style>