<template>
  <el-form ref="form"
           :size="type || 'small'"
           :model="form"
           class="u-form"
           :rules="rules"
           :label-width="labelWidth">
    <el-row :gutter="10">
      <template v-for="(item, index) in formArray">
        <el-col :span="item.col || 24"
                :key="index"
                v-if="item.show === undefined || item.show === null || item.show">
          <el-form-item :label="item.label"
                        :prop="item.key"
                        :width="!item.width && !item.width !== 0 ? labelWidth : 0">
            <template v-if="item.type === 'input'">
              <el-input v-model="form[item.key]"
                        :placeholder="'请输入' + item.label"
                        :disabled="item.disabled" />
            </template>
            <template v-if="item.type === 'code'">
              <div class="code">
                <el-input v-model="form[item.key]"
                          :placeholder="'请输入' + item.label"
                          :disabled="item.disabled"
                          style="margin-right: 10px" />
                <veri-code @changeCode="$emit('changeCode')"
                           :identify-code="item.code"
                           content-height="32" />
              </div>
            </template>
            <template v-if="item.type === 'password'">
              <el-input v-model="form[item.key]"
                        :placeholder="'请输入' + item.label"
                        :disabled="item.disabled"
                        type="password" />
            </template>
            <template v-if="item.type === 'cascader'">
              <el-cascader v-model="form[item.key]"
                           :options="item.options"
                           clearable
                           :props="{ expandTrigger: 'hover', checkStrictly: true }"></el-cascader>
            </template>
            <template v-if="item.type === 'number'">
              <input type="number"
                     v-model="form[item.key]"
                     :placeholder="'请输入' + item.label"
                     :disabled="item.disabled" />
            </template>
            <template v-if="item.type === 'textarea'">
              <el-input type="textarea"
                        :rows="item.rows || 3"
                        v-model="form[item.key]"
                        :placeholder="'请输入' + item.label" />
            </template>
            <template v-if="item.type === 'select'">
              <el-select v-model="form[item.key]"
                         :placeholder="'选择' + item.label"
                         :disabled="item.disabled"
                         clearable
                         filterable
                         allow-create
                         style="width: 100%">
                <el-option v-for="itm in item.options"
                           :key="itm.value"
                           :label="itm.label"
                           :value="itm.value">
                </el-option>
              </el-select>
            </template>
            <template v-if="item.type === 'radio'">
              <el-radio-group v-model="form[item.key]"
                              :disabled="item.disabled">
                <el-radio v-for="item in item.options"
                          :key="item.value"
                          :label="item.value">{{ item.label }}
                </el-radio>
              </el-radio-group>
            </template>
            <template v-if="item.type === 'upload'">
              <el-button class="upload">
                <input type="file"
                       class="file"
                       ref="file"
                       @change="fileChange(form, item.key, arguments[0], item)" />
                {{ item.btnText || '选择文件' }}
              </el-button>
              <span>{{ item.filename || '' }}</span>
            </template>
            <template v-if="item.type === 'button'">
              <div :style="{ textAlign: item.width === 0 ? 'center' : 'left' }">
                <el-button v-for="(btn, index) in item.btnArr"
                           :key="index"
                           :type="btn.type"
                           @click="handle(btn)"
                           :disabled="btn.disabled">
                  <i :class="item.icon"
                     v-if="item.icon" />
                  {{ btn.text }}
                </el-button>
                <span>{{ item.desc ? item.desc : '' }}</span>
              </div>
            </template>
            <template v-if="item.type === 'showText'">
              <span>{{ form[item.key] }}</span>
            </template>
            <template v-if="item.type === 'showImg'">
              <template v-for="(itm, n) in urls">
                <div class="img-wrap"
                     :key="n">
                  <el-image :src="itm"
                            :preview-src-list="urls"
                            fit="cover"
                            v-if="itm" />
                </div>
              </template>
            </template>
          </el-form-item>
        </el-col>
      </template>
      <slot></slot>
    </el-row>
  </el-form>
</template>

<script>
import VeriCode from '../VeriCode/VeriCode'
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  name: 'uForm',
  components: { VeriCode },
  props: {
    formArray: Array,
    value: Object,
    labelWidth: {
      type: String,
      default: '80px'
    },
    type: String || null
  },
  data () {
    const form = {}
    this.formArray.forEach(item => {
      if (item.key) {
        form[item.key] = ''
      }
    })
    return {
      form,
      imageUrl: '',
      fileList: [],
      btnArr: [],
      filename: ''
    }
  },

  computed: {
    rules () {
      const rules = {}
      this.formArray.forEach(item => {
        rules[item.key] = item.rule
      })
      return rules
    },
    urls () {
      let urls = []
      try {
        this.formArray.forEach(item => {
          if (item.type === 'showImg') {
            urls = this.form[item.key].split(',').map(url => {
              return url.includes('http') || !url ? url : this.$baseUrl + url
            })
          }
        })
        return urls
      } catch (e) {
        return ''
      }
    }
  },
  created () {
    this.$emit('change', this.form)
    Object.keys(this.form).forEach(key => {
      this.form[key] = this.value[key] || ''
    })
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        Object.keys(this.form).forEach(key => {
          this.form[key] = val[key]
        })
      }
    },
    formArray: {
      deep: true,
      handler (val) {
        console.log(val)
        this.$forceUpdate()
      }
    },
    form: {
      deep: true,
      handler (val) {
        this.$emit('change', val)
      }
    },
    fileList: {
      deep: true,
      handler (val) {
        console.log(val)
      }
    }
  },
  updated () {
    this.$emit('init', this.$refs.form)
  },
  methods: {
    reset () {
      console.log('SD==========>>>>>>>>>>>>>>清空2')
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      })
    },
    submit (btn) {
      this.$refs.form.validate(valid => {
        if (valid) {
          btn.disabled = true
          this.$emit('submit', btn)
          this.$forceUpdate()
        } else {
          return false
        }
      })
    },
    handle (btn) {
      if (btn.emit === 'submit') {
        this[btn.emit](btn)
      } else {
        this.$emit(btn.emit, btn)
      }
    },
    cancel (key) {
      this.form[key] = ''
    },
    handleAvatarSuccess (file) {
      console.log(file)
      let key = ''
      this.formArray.forEach(item => {
        if (item.type === 'uploadImg') {
          key = item.key
        }
      })
      this.form[key] = file.data
      this.imageUrl = file.data
    },
    beforeAvatarUpload () { },
    fileChange (form, key, e, forms) {
      const file = e.target.files[0]
      this.$set(forms, 'filename', file.name)
      this.form[key] = file
    },
    selected (key) {
      console.log(key)
      this.$emit('selected', key)
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #e5e5e5;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.img-wrap {
  position: relative;
  width: 80px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
    vertical-align: bottom;
  }

  span {
    position: absolute;
    right: 0;
    top: 0;

    transform: translate(50%, -50%);
    font-size: 12px;
    padding: 4px;
    text-align: center;
    line-height: 1;
    font-weight: 600;
    vertical-align: bottom;
    background-color: #ff4949;
    color: #fff;
    border-radius: 50%;
    z-index: 11;
    cursor: pointer;
  }
}
.code {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}
.upload {
  position: relative;

  .file {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
  }
}
</style>
