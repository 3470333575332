import http from './plugin/ajax'

// 消控图编辑查询楼栋
export const buildingTree = () => http.get('/building/tree/build')
// 查询消控图
export const checkTheControlChart = (data) => http.post('/sysSalesControlNew/detailed', data)
// 修改消控图
export const editAcontrolMap = (data) => http.post('/sysSalesControlNew/coordinate', data)
// 查询空间信息
export const getSpatialInformation = (data) => http.get(`/sysSalesControlNew/salesControlSpaecinfo/${data}`)

// 销控图信息管理
export const controlInforList = (data) => http.post('/sysSalesControlNew/list', data)
// 新增销控图信息
export const addControlInfor = (data) => http.post('/sysSalesControlNew', data)
// 编辑销控图信息
export const editControlInfor = (data) => http.put('/sysSalesControlNew', data)
// 删除销控图信息
export const delControlInfor = (data) => http.delete('/sysSalesControlNew/' + data)
