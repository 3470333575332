<template>
  <!-- 表格 -->
  <div class="content">
    <title-box :title="today" />
    <title-top title="本月帐单总数" :num="num" unit="单" subTitle="" />
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import titleTop from './common/titleTop.vue'

export default {
  components: {
    JaInitChart, TitleBox, titleTop
  },
  data() {
    return {
      options: null,
      today: '',
      num: '100',
    }
  },
  created() {
    let now = new Date()
    this.today = this.$moment(now).format("YYYY-MM-DD dddd")
    this.setOptions()
  },
  methods: {
    setOptions() {
      let colorBar = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: '#8d8d8d', },
        { offset: 0.5, color: '#4d4d4d' },
        { offset: 1, color: '#000000' }
      ])
      let colorBar2 = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: '#fad19c', },
        { offset: 0.5, color: '#f8b968' },
        { offset: 1, color: '#f59b26' }
      ])
      let initData = [
        { value: 90, itemStyle: { color: colorBar, }, },
        { value: 100, itemStyle: { color: colorBar2, }, },
        { value: 50, itemStyle: { color: colorBar } },
        { value: 40, itemStyle: { color: colorBar2 } }
      ]
      let yAxis = ['已审核帐单', '未审核帐单', '已核销帐单', '未核销帐单',]
      let percent = [90, 100, 50, 40]
      this.options = {
        grid: {
          top: '5%',
          bottom: '40%',
          left: '28%',
          right: '26%'
        },
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        // tooltip: {
        //   trigger: 'item',
        //   formatter: '{a} <br/>{b} : {c} ({d}%)'
        // },
        xAxis: { type: 'value', show: false },
        yAxis: [
          {
            type: 'category',
            data: yAxis,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { fontWeight: 400, fontSize: this.util.setFontSize(15), lineHeight: this.util.setFontSize(18), margin: this.util.setFontSize(25), }
          },
          {
            type: 'category',
            data: percent,
            axisLine: { show: false },
            axisTick: { show: false },

            axisLabel: {
              margin: this.util.setFontSize(25),
              textStyle: {
                rich: {
                   margin: this.util.setFontSize(5),
                  a: {
                    verticalAlign: 'center',
                    color: '#409eff',
                    fontSize: this.util.setFontSize(18),
                    fontWeight: 'bold'
                  },
                  b:{
                    fontSize: this.util.setFontSize(15),
                  }
                }
              },
              formatter: function (val) {
                return `{a|${val}}  {b|单}`
              },
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: this.util.setFontSize(18),
            showBackground: true,
            backgroundStyle: {
              //   borderRadius: this.util.setFontSize(20),
              color: 'rgba(215, 215, 215, 1)',
              barBorderRadius: 20,
            },
            itemStyle: {
              barBorderRadius: this.util.setFontSize(9),
            },
            data: initData
          }
        ]

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
}
.echart {
  height: 100%;
  width: 100%;
}
</style>