<template>
  <el-dialog :visible.sync="show" width="40%" title="查看">
    <ja-form
      :rules="rules"
      allDisabled
      v-model="rowForm"
      :formArray="rowFormData"
      v-if="show"
      :editDataFormat="editDataFormat"
    >
    </ja-form>
  </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
  components: {
    JaForm
  },
  data() {
    return {
      rules: {},
      rowFormData: [
        {
          input_type: 'input',
          label: '设备识别码',
          key: 'deviceCode',
          maxlength: 20,
          labelWidth: '10vw',
        },
        {
          input_type: 'input',
          label: '上次表底',
          key: 'lastReading',
          labelWidth: '10vw',
          
        },
        {
          input_type: 'input',
          label: '本次抄表',
          key: 'thisReading',
          labelWidth: '10vw',
        },
        {
          input_type: 'input',
          label: '本次抄表时间',
          key: 'updateTime',
          disabled: true,
          labelWidth: '10vw',
        },
        {
          input_type: 'input',
          label: '本期用量（kw/h）',
          key: 'currentReading',
          labelWidth: '10vw',
        }
      ],
      show: false,
      titleText: '新增',
      rowForm: {}
    }
  },
  methods: {
    init(row) {
      this.show = true
      this.rowForm = { ...row }
    }
  }
}
</script>