<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  :valueFormat="valueFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @rowDelete="rowDelete"
                  @showResident="showResident"> </ja-table>

        <add-edit ref="addEdie"></add-edit>
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import { getChargingSettings, delChargingSettings } from '@/api'
import AddEdit from './components/addEdit.vue'
export default {
    title: '收费项目自定义',
    name: 'custom',
    mixins: [table],
    components: {
        AddEdit
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'serviceCode', attribute: { placeholder: '收费项目编号', size: 'small', } },
                { input_type: 'select', key: 'serviceType', attribute: { placeholder: '收费项目类型', size: 'small', }, options: [] },
                { input_type: 'select', key: 'natureCharges', attribute: { placeholder: '收费项目性质', size: 'small', }, options: [] },
                { input_type: 'input', key: 'serviceName', attribute: { placeholder: '收费项目名称', size: 'small', } },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
                { normalBtn: 'add' ,limit: 'chargeConfig::custom::add'},
            ],
            columns: [
                { label: '收费项目编号', prop: 'serviceCode' },
                { label: '收费项目类型', prop: 'serviceType' },
                { label: '收费项目性质', prop: 'natureCharges' },
                { label: '缴费周期', prop: 'day' },
                { label: '收费项目名称', prop: 'serviceName' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: '150px',
                    list: [
                        { text: '查看', method: 'showResident', limit: 'chargeConfig::custom::showResident' },
                        { text: '删除', method: 'rowDelete', limit: 'chargeConfig::custom::rowDelete' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
        }
    },
    mounted() {
        let length1 = this.conditionData.findIndex(e => e.key === 'serviceType')
        let length2 = this.conditionData.findIndex(e => e.key === 'natureCharges')
        this.lengthA = length2
        this.util.rqOptionsList({
            who: this.conditionData[length1],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'chargeitem_type' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
        this.util.rqOptionsList({
            who: this.conditionData[length2],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'nature_charges' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        getInit() {
            getChargingSettings(this.filterData).then(res => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        append() {
            this.$refs.addEdie.init()
        },
        showResident(row) {
            this.$refs.addEdie.init(3, row);
        },
        rowDelete(row) {
            this.$confirm('删除收费项后，该收费项的数据将不再可用，请谨慎操作。', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delChargingSettings(row.id).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getInit()
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        valueFormat(val, clo) {
            if (clo == 'day') {
                if (val) {
                    return `每月${val}日`
                } else {
                    return '-'
                }
            }
            return val
        }

    }
}
</script>