import util from '@/util/util'
let rowData_common = {
    first:[
        {input_type: 'input',label: '收费项名称', key: 'serviceName',col:24},
        {input_type: 'input',label: '收费项编号', key: 'serviceCode',col:24,disabled:true},
    ],
    remark:[{label: '备注',key: 'remark',input_type: 'input',type:'textarea',col:22,maxlength:200,showWordLimt:true,rows:2},]
}
let rules_common ={
    serviceName:[{ required: true, message: '请输入项目名称', trigger: 'blur' }],
    frequency:[{ required: true, message: '请选择计费频率', trigger: 'change' }]
}
//------  不同的表单
let FormObj = {
    periodicity:{
        titleText:'周期类费用',
        rules:{
            serviceName : rules_common.serviceName,
            frequency : rules_common.frequency,
            frequencyCategory:[{ required: true, message: '请选择计费周期', trigger: 'change' }],
            frequencyMode:[{ required: true, message: '请选择计费方式', trigger: 'change' }],
            billType:[{ required: true, message: '请选择计费方式', trigger: 'change' }]
        },
        rowFormData:[
            ...rowData_common.first,
            {input_type: 'radio',label: '计费频率', key: 'frequency',col:24,options:[],method:'frequencySelect'},
            {input_type: 'customize',customize:'frequencyDay',label: '计费日期',key: 'frequencyDay',col:24,isRequired:true},
            {input_type: 'select',label: '计费周期', key: 'frequencyCategory',col:24,options:[]},
            {input_type: 'select',label: '计费方式', key: 'frequencyMode',col:24,options:[]},
            {input_type: 'select',label: '所属合同', key: 'billType',col:24,options:[]},
        ]
    },
    instrument:{ 
        titleText:'仪表类费用',
        rules:{
            serviceName : rules_common.serviceName,
            frequency : rules_common.frequency,
            deviceCategory:[{ required: true, message: '请选择关联仪表', trigger: 'change' }],
            unitPrice:[{ required:true, validator:util.formRules.checkNumber({min:0,fixedNum:2}),trigger: 'blur' }],
            billType:[{ required: true, message: '请选择计费方式', trigger: 'change' }],
            frequencyMode:[{ required: true, message: '请选择计费方式', trigger: 'change' }],
        },
        rowFormData:[
            ...rowData_common.first,
            {input_type: 'select',label: '关联仪表', key: 'deviceCategory',col:24,options:[],method:'selectDevice'},
            {input_type: 'radio',label: '计费频率', key: 'frequency',col:24,options:[],method:'frequencySelect'},
            {input_type: 'customize',customize:'frequencyDay',label: '计费日期',key: 'frequencyDay',col:24,isRequired:true},
            {input_type: 'select',label: '所属合同', key: 'billType',col:24,options:[]},
            {input_type: 'select',label: '计费方式', key: 'frequencyMode',col:24,options:[]},
            // 下面这三个,根据关联仪表而出现
            {input_type: 'input',label: '单价', key: 'unitPrice',styleWidth:'100px',rightBox:{input_type: 'text',text:'/kw · h'},col:18,hide:true,}, //124 水表 元/m³   电表125 text:'/kw · h'
            {input_type: 'button',label: '时间段标准',key: 'timeInterval',text:'设置',method:'setTime',isRequired:true,id:0,hide:true},
        ],
       
    },
    deposit:{
        titleText:'保证金类费用',
        rowFormData:[ ...rowData_common.first,...rowData_common.remark, 
        {input_type: 'select',label: '所属合同', key: 'billType',col:24,options:[]},
        {input_type: 'select',label: '计费方式', key: 'frequencyMode',col:24,options:[]},
    ],
        rules:{
            serviceName : rules_common.serviceName, 
            billType:[{ required: true, message: '请选择计费方式', trigger: 'change' }],
            frequencyMode:[{ required: true, message: '请选择计费方式', trigger: 'change' }],
        },
    },
    temporary:{ 
        titleText:'临时类费用',
        rowFormData:[ ...rowData_common.first,...rowData_common.remark, 
            {input_type: 'select',label: '所属合同', key: 'billType',col:24,options:[]},
            {input_type: 'select',label: '计费方式', key: 'frequencyMode',col:24,options:[]},
        ],
            rules:{
                serviceName : rules_common.serviceName, 
                billType:[{ required: true, message: '请选择计费方式', trigger: 'change' }],
                frequencyMode:[{ required: true, message: '请选择计费方式', trigger: 'change' }],
            },
    }
}

//-----  表单按钮
let btnArrObj = {
    first:[
        { label: '下一步',method: 'nextBtn',type: 'primary', monitor: true,col: 8,isCenter: true },
        { label: '取消', method: 'reset', col: 8, isCenter: true }
    ],
    second:[
        { label: '上一步',method: 'lastBtn',type: 'primary',col: 8,isCenter: true },
        { label: '保存',method: 'save',type: 'primary', monitor: true,col: 8,isCenter: true },
        { label: '取消', method: 'reset', col: 8, isCenter: true }
    ],
}
export {
    FormObj,
    btnArrObj
}
