<template>
    <!--  指派 -->
    <el-dialog v-dialogDrag
               :close-on-click-modal="false"
               :visible.sync="showEditAll"
               width="50%"
               :title="(isAdd?'新增':'编辑')+'计划'">
        <ja-form :rules="rules"
                 ref="jaForm"
                 :label-width="labelWidth"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :loadingForm="loadingForm"
                 @reset="showEditAll = false"
                 @save="saveForm"
                 v-if="showEditAll">
            <template #frequency>
                <frequency :planTimeReqList.sync="rowForm.planTimeReqList"
                           :executionFrequency.sync="rowForm.executionFrequency"
                           v-if="isAdd?true:rowForm.executionFrequency !== undefined" />
            </template>
            <template #minute>
                <div class="timeBox">
                    <span>分钟</span>
                    <el-switch v-model="rowForm.warnFlag"
                               active-text="开启提醒"
                               :active-value="0"
                               :inactive-value="1"></el-switch>
                </div>
            </template>

        </ja-form>
    </el-dialog>
</template>
<script>
import frequency from './frequency'
import { addInspection, editInspection, rqInspectionItem } from '@/api'

export default {
    inject: ['labelWidth', 'getInit',],
    components: {
        frequency
    },
    data() {
        return {
            rowForm: {},
            // 基础
            rowFormData_base: [
                { input_type: "input", label: '计划名称', key: 'planName', col: 11, maxlength: 30 },
                {
                    input_type: "select", label: '设备类型', key: 'deviceCategory', col: 11, options: [],
                    relevance: {
                        sonKey: 'deviceIds', This: this, type: 'select', // 必填。而 rqParam 是异步请求，必填字段
                        rqParam: {
                            rqUrl: "/device/info",
                            rqData: () => { return { params: { subcategory: this.rowForm.deviceCategory, pageIndex: 1, pageSize: 2000 } } },
                            labelName: 'deviceName', valueName: 'id', resRule: 'data.data'
                        }, // 
                    }
                },
                { input_type: "select", label: '检巡设备', key: 'deviceIds', col: 22, multiple: true, options: [] },
                { input_type: "date", label: '计划开始时间', key: 'planStartTime', col: 11, pickerOptions: { disabledDate: this.disabledDateStart }, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
                { input_type: "date", label: '计划结束时间', key: 'planEndTime', col: 11, pickerOptions: { disabledDate: this.disabledDateStart }, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
                { input_type: "customize", label: '执行频率', key: 'executionFrequency', col: 22, customize: 'frequency', },
                { input_type: "input", label: '工单提前创建时间', key: 'advanceCreateTime', placeholder: '时间', type: 'number', col: 12, rightBox: { input_type: 'customize', customize: 'minute', }, isFlex: true },
                { input_type: 'switch', key: 'enablePlan', label: '是否启用计划', activeValue: 0, inactiveValue: 1, col: 22, },
            ],
            rowFormData: [],
            rules: {
                planName: [{ required: true, trigger: 'change', validator: this.util.formRules.checkText("计划名称") }],
                deviceCategory: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
                deviceIds: [{ required: true, message: '请选择检巡设备', trigger: 'change' }],
                planStartTime: [{ required: true, trigger: 'change', validator: this.validatorStartTime }],
                planEndTime: [{ required: true, trigger: 'change', validator: this.validatorEndTime }],
                executionTime: [{ required: true, trigger: 'change', validator: this.validator_executionTime }],
                advanceCreateTime: [{ required: true, validator: this.util.formRules.checkNumber({ isIncludeMin: false, min: 0, isInt: true }), trigger: 'change' }],
                executionFrequency: [{ required: true, message: '请完善执行频率', trigger: 'change' }],
                enablePlan: [{ required: true, message: '请选择计划结束时间', trigger: 'change' }],
            },
            loadingForm: false,
            showEditAll: false, // 显示新增/编辑弹窗,
            isAdd: false,
            indexObj: {},
        }
    },
    created() {
        this.rowFormData = [...this.rowFormData_base]
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
        const params = { params: { pageIndex: 1, pageSize: 200, isAll: 2 } }
        this.util.rqOptionsList({ who: [this.rowFormData[this.indexObj.deviceCategory]], rqUrl: "/device/category", resRule: 'data.data', rqData: params, labelName: 'categoryName', valueName: 'id', isReturnAll: true })
    },
    watch: {
        'rowForm.planStartTime': function () {
            if (this.$refs.jaForm) {
                this.$refs.jaForm.$refs.formbox.validateField('planStartTime');
                if (this.rowForm.planEndTime) {
                    this.$refs.jaForm.$refs.formbox.validateField('planEndTime');
                }
            }
        },
        'rowForm.planEndTime': function () {
            if (this.$refs.jaForm) {
                this.$refs.jaForm.$refs.formbox.validateField('planEndTime');
            }
        },
    },
    methods: {
        append() {
            this.isAdd = true
            this.rowForm = {}
            this.showEditAll = true
            this.$nextTick(() => {
                this.util.clearSelectOptions(this.rowFormData) //  这个 要加，清除关联的下拉列表
                this.$refs.jaForm.$refs.formbox.clearValidate()
            })
        },
        async rowEdit(row) {
            this.isAdd = false
            this.rowForm = {}
            this.showEditAll = true
            this.loadingForm = true
            let res = await rqInspectionItem(row.id)

            this.rowForm = { ...res.data, deviceIds: ((res.data.deviceIds || '').split(',') || []).map(v => Number(v)) }
            if (this.rowForm.planTimeReqList.length <= 0) {
                this.rowForm.planTimeReqList = [
                    { season: '', month: '', day: '', week: '', planTime: '', }
                ]
            }
            this.$nextTick(() => {
                this.util.setOptionsInit({ arr: this.rowFormData, This: this, obj: this.rowForm, ref: 'jaForm' })
                this.$refs.jaForm.$refs.formbox.clearValidate()
                this.loadingForm = false
            })
        },
        saveForm() {
            console.log(this.rowForm);
            this.loadingForm = true
            const rqMethod = this.isAdd ? addInspection : editInspection
            const rqObj = { ...this.rowForm, planType: '0' }
            rqObj.deviceIds = rqObj.deviceIds.join(',')
            rqObj.advanceCreateTime = Number(rqObj.advanceCreateTime)
            rqMethod(rqObj).then(res => {
                this.loadingForm = false
                this.$message.success(res.msg || '提交成功')
                this.showEditAll = false
                this.getInit()
            }).catch(error => {
                this.loadingForm = false
            })
        },
        // 开始时间禁用
        disabledDateStart(val) {
            let now = (new Date()).getTime()
            let time = val.getTime()
            let oneDay = 24 * 3600 * 1000
            return time < now - oneDay
        },
        // 校验开始时间
        validatorStartTime(rule, value, callback) {
            if (value === '' || !value) {
                callback(new Error('请完善计划开始时间'));
            } else {
                callback()
            }
        },
        validatorEndTime(rule, value, callback) {
            if (value === '' || !value) {
                callback(new Error('请完善计划结束时间'));
            } else {
                let planStartTime = new Date(this.rowForm.planStartTime).getTime()
                let time = new Date(value).getTime()
                if (time < planStartTime) {
                    callback(new Error('结束时间不能小于开始时间'))
                } else {
                    callback()
                }
            }
        },
        // 校验 执行时间
        validator_executionTime(rule, value, callback) {
            if (value === '' || !value) {
                callback(new Error('请完善执行时间'));
            } else {
                let now = new Date().getTime()
                let minutes_3 = 3 * 60 * 1000
                let time = new Date(value).getTime()
                if (!(time > (now + minutes_3))) {
                    callback(new Error('请选择3分钟之后的时间'))
                } else {
                    callback()
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.btns {
    margin-left: 20px;
    display: flex;
    align-items: center;
    .ipTestWord {
        margin-left: 10px;
    }
}
.timeBox {
    display: flex;
    align-items: center;
    span {
        margin: 0 10px;
    }
    .el-switch,
    & > span {
        flex-shrink: 0;
    }
}
</style>