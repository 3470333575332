<template>
    <div>
        <el-container>
            <el-header>
                <el-button size="mini" type="primary" @click="toMap" v-if="isAuth('editMap::header::save')">保存</el-button>
            </el-header>
            <el-container>
                <el-aside v-loading="loadingTree" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgb(36 33 33 / 90%)">
                    <el-tree class="u-menu" node-key="id" accordion :default-expanded-keys="expandedkey" highlight-current ref="tree" @node-click="nodeClick" :data="treeData" :props="defaultProps"></el-tree>
                </el-aside>
                <el-main ref="canvasDiv" v-loading="loadingCanvas" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgb(36 33 33 / 90%)">
                    <canvas class="canvas" ref="canvas"></canvas>
                    <el-menu v-show="showTool" ref="headle" @select="handleSelect" close="handleClose" default-active="1-4-1" collapse-transition text-color="#fff" background-color="#333333" :collapse="true">
                        <el-menu-item class="tool" disabled>
                            工具
                        </el-menu-item>
                        <el-menu-item index="1" v-if="isAuth('editMap::tool::brush')">
                            <i class="el-icon-edit"></i>
                        </el-menu-item>
                        <el-menu-item index="2" v-if="isAuth('editMap::tool::zoom')">
                            <i class="el-icon-zoom-in"></i>
                        </el-menu-item>
                        <el-menu-item index="3" v-if="isAuth('editMap::tool:pan')">
                            <i class="el-icon-rank"></i>
                        </el-menu-item>
                        <el-menu-item index="4" v-if="isAuth('editMap::tool:delete')">
                            <i class="el-icon-delete"></i>
                        </el-menu-item>
                    </el-menu>
                </el-main>
            </el-container>
        </el-container>
        <!-- 选择空间 -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" width="50%" title="添加空间信息" :visible.sync="showFrom" v-if="showFrom">
            <select-space @selectRow="selectSpace" :checkRoomsData="checkRoomsData" v-if="showFrom" />
        </el-dialog>
    </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import { myMinxin } from '../mixin/index'
import selectSpace from './components/selectSpace/selectSpace.vue'
import {
    buildingTree,
    editAcontrolMap,
} from '@/api'
export default {
    mixins: [myMinxin],
    title: "销控图编辑",
    name: 'controlDiagramEditing',
    components: {
        JaForm,
        selectSpace
    },
    data () {
        return {
            //画布状态
            canvas_state: null,
            showFrom: false,
            //判定是否是画笔打开绑定
            diaFrequency: 0,
            rowFormData: {
                controlCoordinateReqList: []
            },
            rules: {},
            showTool: false,
            //操作状态
            operatingState: null,
            loadingCanvas: false,
            isHeadle: null,
            headleNum: null,
            isEdit: false,
            // // 空间弹窗
            // selectedRooms: [],
            //记录本地操作已绑定的房间进行过滤不再显示
            checkRoomsData: [],
        };
    },
    watch: {
        'canvasObj.CANVAS_STATE.BRUSH': {
            handler (newVal, oldVal) {
                if (oldVal === false && newVal === true) {
                    // console.log(this.canvasObj.addressLists);
                    if (this.canvasObj.addressLists[this.canvasObj.addressLists.length - 1].childs.length >= 3) {
                        this.showFrom = true
                    }
                }
            },
            deep: true
        },
        'canvasObj.responseData': {
            handler (newVal, oldVal) {
                console.log(newVal);
                if (typeof newVal === 'number') {
                    if (this.canvasObj.addressLists[newVal]) {
                        this.messageBoxDelete(newVal)
                    }
                }
            },
            deep: true,
        }
    },
    methods: {
        //请求左侧楼层
        getInit () {
            this.loadingTree = true
            buildingTree().then((res) => {
                this.loadingTree = false
                this.treeData = res.data
                this.$nextTick(() => {
                    let id = this.$route.params.setid
                    if (id) {
                        this.$set(this.expandedkey, 0, id)
                        this.$refs.tree.setCurrentKey(id)
                        let Objitem = this.$refs.tree.getCurrentNode()
                        let item = this.$refs.tree.getNode(Objitem)
                        this.nodeClick(Objitem, item)
                    }else{
                        this.$router.push({ name: 'eliminationChart_addedControlMap.vue'})
                    }
                })
            })
        },
        //工具选择
        handleSelect (index) {
            if (index === '1') {
                this.canvasObj.switchBrush()
                this.canvasObj.addressLists = this.canvasObj.addressLists
            } else if (index === '2') {
                this.canvasObj.enlargeCanvas()
            } else if (index === '3') {
                this.canvasObj.panCanvas()
            } else if (index === '4') {
                this.canvasObj.getClickItemId(false)
            }
            this.headleNum = index
        },
        //选择楼层
        nodeClick (Objitem, item) {
            this.canvasObj.addressLists = []
            this.rowFormTree = {}
            this.getCheckTheControlChart(Objitem, item)
        },
        // 保存绘图
        toMap () {
            //保存前校验
            let isOk = this.saveCheck()
            if (isOk) {
                this.$confirm('存在未绑定空间的区域，确定提交未绑定的区域将被删除', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '确定提交'
                    });
                    //删除没有绑定的空间
                    this.canvasObj.addressLists = this.canvasObj.addressLists.filter(e => e.id !== null)
                    this.save()
                }).catch((err) => {
                    console.log(err);
                    this.$message({
                        type: 'info',
                        message: '取消提交'
                    });
                });
            } else {
                this.save()
            }
        },
        messageBoxDelete (length) {
            console.log(length);
            this.$confirm('确认永久删除该局域吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let { id } = this.canvasObj.addressLists[length]
                let isOk = this.canvasObj.deleteLocation(length)
                if (isOk) {
                    console.log(this.checkRoomsData);
                    let deleteLength = this.checkRoomsData.findIndex(e => e.id == id)
                    // this.checkRoomsData.splice(deleteLength,1)
                    this.$delete(this.checkRoomsData, deleteLength)
                    console.log(this.checkRoomsData);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }
            }).catch((err) => {
                this.canvasObj.cancelDelete()
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 选择空间
        selectSpace ({ list }) {
            let { id, roomName: name } = list[0]
            this.showFrom = false
            this.checkRoomsData.push(...list)
            this.canvasObj.addressLists[this.canvasObj._length] = { ...this.canvasObj.addressLists[this.canvasObj._length], id, name }
            console.log(this.canvasObj.addressLists);
            this.canvasObj.collectionRepaint()
        },
        saveCheck () {
            let objArr = this.canvasObj.addressLists.length > 0 ? [...this.canvasObj.addressLists] : []
            for (let i = 0; i < objArr.length; i++) {
                if (!objArr[i].id) {
                    return true
                }
            }
            return false
        },
        //提交操作
        save () {
            //保存被重构后的坐标，用于入参
            let arrData = []
            console.log(this.$refs);
            let setid = this.$refs.tree.getCurrentKey()
            console.log(setid);
            (this.canvasObj.addressLists || []).map(e => {
                arrData.push({ childs: [...e.childs], roomId: e.id, roomName: e.name })
            })
            console.log(arrData);
            //统计参数
            let obj = {
                ...this.rowFormTree,
                controlCoordinateReqList: [...arrData]
            }
            editAcontrolMap(obj).then(res => {
                if (res.code === 200) {
                    this.$router.push({ name: 'eliminationChart_addedControlMap.vue', params: { setid: setid ? setid : '' } })
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    })
                } else {
                    return this.$message.error('修改失败')
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.el-button {
    float: right;
}
.el-main {
    width: 80%;
    height: 750px;
    min-height: 700px;
    overflow: hidden;
    position: relative;
    padding: 0;
}
.el-header {
    height: 40px !important;
    margin: 10px 10px;
    // padding: 10px 0;
    background: #fff;
    border-radius: 0.521vw;
}
.el-menu {
    width: 80px;
    height: 70%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.canvas {
    background-color: #027db4;
}
.tool {
    border-bottom: 1px solid #fff;
}
.qy_color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}
.qy_box {
    position: absolute;
    left: 3%;
    top: 5%;
    font-size: 12px;
}
.qy_box_main {
    margin: 0 10px;
    display: inline-block;
}
.qy_blue {
    background-color: #003aff;
}
.qy_red {
    background-color: #ff0089;
}
.el-aside {
    width: 240px !important;
    position: relative;
    margin: 0 10px;
}
.u-menu {
    box-sizing: border-box;
    width: 95%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    height: 100%;
    padding: 10px 10px;
    background: #fff;
}
.u-menu::-webkit-scrollbar {
    width: 7px;
}
.u-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(7, 150, 126, 0.2);
}
.u-menu::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(21, 31, 15, 0.1);
}
</style>
