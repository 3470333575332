import http from './plugin/ajax'

// 获取省市区数据
export const queryregion = (data) => http.get('/sys/region', data)

// 添加楼层信息
export const addFloorInfo = (data) => http.post('/floor/add', data)

// 更新楼层信息
export const updateFloorInfo = (data) => http.put('/floor/upData', data)

// 删除楼层信息
export const deleteFloorInfo = (data) => http.delete('/floor/delete/' + data)

// 添加企业信息
export const addCompanyInfo = (data) => http.post('/company/add', data)

// 更新企业信息
export const updateCompanyInfo = (data) => http.post('/company/update', data)

// 删除企业信息
export const deleteCompanyInfo = (data) => http.delete('/company/' + data)

// 禁用部门
export const disabledDepartment = (data) => http.delete(`/department/disable/${data}`)
// 启用部门
export const enableDepartment = (data) => http.get(`/department/enable/${data}`)

// 查询部门信息
export const queryDeptList = (data) => http.get('/department', {
    params: data
})

// 添加部门信息
export const addDepartmentInfo = (data) => http.post('/department/add', data)

// 更新部门信息
export const updateDepartmentInfo = (data) => http.post('/department/update', data)

// 删除部门信息
export const deleteDepartmentInfo = (data) => http.delete('/department/' + data)

/** 用户管理 */
// 添加用户信息
export const employeeAdd = (data) => http.post('/employee/add', data)

// 更新用户信息
export const employeeUpdate = (data) => http.post('/employee/update', data)

// 删除用户信息
export const employeeDelete = (data) => http.delete('/employee/delete/' + data)
// 禁用
export const disabledPersonnel = (data) => http.delete(`/employee/disable/${data}`)
// 启用
export const enablePersonnel = (data) => http.get(`/employee/enable/${data}`)
// 重置密码
export const resetPasswordPersonnel = (data) => http.get(`/employee/reset/${data}`)

// 用户通行权限详情
export const employeeGrantDetail = (data) => http.get('/secDoorAccess/employeeGrantDetail/' + data)

export const getEmployList = (data) => http.get('/employee', {
    params: data
})

// 同步公众号
export const syncMpUserAll = () => http.post('/mp/user/sync') // 同步公众号粉丝

export const getCompanyList = (data) => http.get('/company', {
    params: data
})
// 禁用企业
export const disabledEnterprise = (data) => http.delete(`/company/disable/${data}`)
// 启用企业
export const enableEnterprise = (data) => http.get(`/company/enable/${data}`)

// 添加企业通行权限
export const companyGrant = (data) => http.post('/secDoorAccess/companyGrant', data)
