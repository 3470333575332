<template>
    <div class="allwh minWidth1000">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="append" @rowEdit="rowEdit" @getInit="getInit" :valueFormat="formatter" @search="search" @reset="reset" @rowDelete="rowDelete" @lookRoom="lookRoom">
        </ja-table>
        <add-edit-show ref="addEdit"></add-edit-show>
    </div>
</template>

<script>
import {
    getFloorList,
    deleteFloorInfo
} from '@/api'
import table from "@/views/mixins/table"
import AddEditShow from './components/AddEditShow.vue'
export default {
    title: '楼层列表',
    mixins: [table],
    name: 'index',
    components: {
        AddEditShow,
    },
    data () {
        return {
            conditionData: [ // 表格上方的搜索/选择操作栏
                {
                    input_type: 'input',
                    key: 'floorName',
                    attribute: {
                        placeholder: '楼层名称',
                        size: 'small'
                    },
                },
                {
                    text: '查询',
                    input_type: 'button',
                    method: 'search',
                    size: 'small',
                    attribute: {
                        type: 'primary',
                        icon: 'el-icon-search'
                    }
                },
                {
                    text: '重置',
                    input_type: 'button',
                    method: 'reset',
                    size: 'small',
                    attribute: {
                        type: 'default'
                    }
                },
                { text: '新增', input_type: 'button', method: 'append', size: 'small', attribute: { type: 'primary', limit: '', plain: true } ,limit: 'floor::operate::add'},
            ],
            columns: [ // 表头数据
                {
                    label: '楼层名称',
                    prop: 'floorName',
                    name: "floorName"
                },
                {
                    label: '地面/地下',
                    prop: 'typeName'
                },
                {
                    label: '描述',
                    prop: 'remark'
                },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: "right",
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom' ,limit: 'floor::operate::show'},
                        { text: '编辑', method: 'rowEdit', limit: 'floor::operate::edit' },
                        { text: '删除', method: 'rowDelete', limit: 'floor::operate::delete' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,
        }
    },
    methods: {
        getInit: function () {
            this.loading = true
            // 获取楼层信息列表
            getFloorList(this.filterData).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.data
                    this.total = res.data.total
                    this.loading = false
                }
            })
        },
        lookRoom (row) {
            this.$refs.addEdit.init(3, row)
        },
        rowEdit (row) {
            this.$refs.addEdit.init(2, row)
        },
        append () {
            this.$refs.addEdit.init(1)
        },
        rowDelete (row) {
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteFloorInfo(row.id).then(res => {
                    if (res.code == 200) {
                        this.getInit()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
    }
}
</script>

<style lang="scss" scoped>
.heandBatchAdd {
    height: 1.667vw;
}
</style>
