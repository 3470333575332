import http from './plugin/ajax'

// =========  客户管理  ===========

// 客户列表
export const getCustomer = (data) => http.post('/cmClientInfo/list', data) // 查询列表
export const addCustomer = (data) => http.post('/cmClientInfo', data) // 新增
export const editCustomer = (data) => http.put('/cmClientInfo', data)

// 项目列表
export const getProject = (data) => http.post('/cmProjectInfo/list', data) // 查询列表
export const addProject = (data) => http.post('/cmProjectInfo', data) // 新增
export const editProject = (data) => http.put('/cmProjectInfo', data) // 编辑
export const deleteProject = (data) => http.delete('/cmProjectInfo/' + data) // 删除
export const getCmProjectInfoEvolve = (data) => http.get('/cmProjectInfo/evolve/' + data) // 查看跟进记录
export const addCmProjectInfoEvolve = (data) => http.post('/cmProjectInfo/evolve', data) // 添加跟进
export const importanceStatistics = (data) => http.post('/cmProjectInfo/statistics/importance', data)  //项目统计重要程度
export const progressStatistics = (data) => http.post('/cmProjectInfo/statistics/progress', data)  //项目进度统计

// 跟进状态
export const getFollowLists = (data) => http.post('/cmProjectRecord/list', data) // 查询列表
export const addFollow = (data) => http.post('/cmProjectRecord', data) // 新增
export const deleteFollow = (data) => http.delete('/cmProjectRecord/' + data) // 删除

