<template>
  <div class="page">
    <Header style="margin-bottom:20px" />
    <!-- 财务统计类 -->
    <div class="middle">
      <billVerification class="box" />
      <receivable class="box" />
    </div>
    <!-- 年度逾期分布图 实收款进度-->
    <div class="middle">
      <annualOverdue class="box" style="margin-bottom: 20px" />
      <alarm-type class="box" />
    </div>

  </div>
</template>

<script>
import Header from './childComp/Header.vue'
import receivable from './childComp/receivable.vue'
import billVerification from './childComp/billVerification.vue'
import AlarmType from './childComp/AlarmType.vue'
import annualOverdue from './childComp/annualOverdue.vue'

export default {
  title: "财务统计",
  data () {
    return {
      btnList:[
        {
          name:"电表",
          href:"http://www.zonvinfo.com/default"
        },
        {
          name:"水表",
          href:"https://www.100123100.com/"
        },
        {
          name:"监控",
          href:"https://120.236.119.130:50443/portal/cas/loginPage?service=https://120.236.119.130:50443/portal/cas/loginPage"
        },
        {
          name:"停车场",
          href:"https://yun.jslife.com.cn/jportal/index.html#/login"
        }
      ]
    }
  },
  components: {
    Header, receivable, billVerification, AlarmType, annualOverdue,
  }
}
</script>

<style lang="scss" scoped>
.boxCommon {
  margin: 15px 0 15px 0;
  border-radius: 20px;
  background: white;
}
.page {
  margin: 15px;
  .middle {
    display: flex;
    justify-content: space-between;
    .box {
      width: calc((100% - 20px) / 2);
      @extend .boxCommon;
      height: 480px;
    }
  }
  .middle2 {
    display: flex;
    justify-content: space-between;
    .box {
      flex: 1;
      @extend .boxCommon;
      height: 400px;
      margin-top: 0;
    }
    .mid {
      margin: 0 20px;
    }
  }
}

</style>
