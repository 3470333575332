<template>
    <el-main>
        <div id="player"></div>
    </el-main>
</template>
<script>
const IS_MOVE_DEVICE = document.body.clientWidth < 992
import { monitorView } from '@/api'
export default {
    data () {
        return {
            player: null,
            urls: {
                realplay: ''
            },
            mode: 1,
            isOnline: false
        }
    },
    created () {

    },
    mounted () {
        this.init()
        this.createPlayer()
        //选择视频
        this.$bus.$on('TreeClick', id => {
            if (!id) return this.$message.warning('视频请求失败')
            this.monitorView(id)
        })
        //首次进入请求视频
        this.$bus.$on('getFirstTreeData', id => {
            if (!id) return this.$message.warning('首次加载失败')
            this.monitorView(id)
        })

    },
    methods: {
        init () {
            // 设置播放容器的宽高并监听窗口大小变化
            window.addEventListener('resize', () => {
                this.player.JS_Resize()
            })
        },
        createPlayer () {
            this.player = new window.JSPlugin({
                szId: 'player',
                szBasePath: './js',
                iMaxSplit: 1,
                iCurrentSplit: IS_MOVE_DEVICE ? 1 : 2,
                openDebug: true,
                oStyle: {
                    borderSelect: IS_MOVE_DEVICE ? '#000' : '#FFCC00'
                }
            })

            // 事件回调绑定
            this.player.JS_SetWindowControlCallback({
                windowEventSelect: function (iWndIndex) {
                    //插件选中窗口回调
                    console.log('windowSelect callback: ', iWndIndex)
                },
                pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {
                    //插件错误回调
                    console.log('pluginError callback: ', iWndIndex, iErrorCode, oError)
                },
                performanceLack: function () {
                    //性能不足回调
                    console.log('performanceLack callback: ')
                }
            })
        },
        monitorView (id) {
            monitorView({
                deviceId: id,
                protocol: "ws"
            }).then((res) => {
                if (res.code !== 200) return false
                this.realplay(res.data.url)
            })
        },
        /* 播放 */
        realplay (playURL) {
            let { player, urls, mode } = this,
            index = player.currentWindowIndex
            player.JS_Play(playURL, { playURL, mode:1 }, index).then(
                () => {
                    console.log('realplay success')
                },
                (e) => {
                    console.error(e)
                }
            )
        }
    },
    beforeDestroy(){
        this.$bus.$off('TreeClick')
        this.$bus.$off('getFirstTreeData')
    }

}
</script>
<style lang="scss" scoped>
.el-main {
    min-height: 400px;
    height: 82vh;
    background: #fff;
    padding: auto 10px;
}
.video_box {
    width: 100%;
    height: 100%;
    min-height: 60%;
    position: relative;
}
@media screen and (max-width: 991px) {
    #player {
        width: 100%;
        height: 98%;
    }
}
@media screen and (min-width: 992px) {
    #player {
        width: 100%;
        height: 98%;
    }
}
.video_box_hender {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    height: 1.563vw;
    width: 95%;
    position: absolute;
    left: 50%;
    top: 6px;
    transform: translateX(-50%);
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
        .right_color {
            width: 0.521vw;
            height: 0.521vw;
            display: inline-block;
            border-radius: 50%;
            margin: 0 10px;
        }
    }
}
.stateColor1 {
    background-color: red;
}
.stateColor2 {
    background-color: blue;
}
</style>