<template>
    <div class="tabsBox">
        <el-tabs v-model="activeName">
            <el-tab-pane label="待确定" name="1">
                <determined v-if="activeName === '1'"></determined>
            </el-tab-pane>
            <el-tab-pane label="待提交" name="2">
                <submitted-list v-if="activeName === '2'"></submitted-list>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import Determined from './components/determined.vue'
import SubmittedList from './components/submittedList.vue'
export default {
    title: '应收账款申请',
    name: 'receivableList',
    components: {
        SubmittedList,
        Determined
    },
    data () {
        return {
            activeName: '1'
        }
    }
}
</script>
<style lang="scss" scoped>
.tabsBox {
    padding: 10px 18px;
    overflow: hidden;
}
.el-tabs {
    padding: 10px 10px;
    background-color: #fff !important;
    /deep/.el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll {
        background-color: #fff !important;
    }
}
</style>
