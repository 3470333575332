import echarts from '/node_modules/echarts'; // 导入 ECharts
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'reset.scss/reset.scss'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/common.scss'
import '@/assets/icons' // icon
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import './util/dialogDragMove'

import JAUI from '@/components'
import util from '@/util/util'
import {dealOptions} from '@/util/common'
import Print from '@/util/print'
import config from "@/util/config"

import _ from 'lodash'
Vue.prototype._ = _

Vue.prototype.$uploadUrl = process.env.NODE_ENV === 'development' ? '/prod-api/admin' : process.env.VUE_APP_ROOT + '/admin'

import './permission'

Vue.config.productionTip = false // permission control

Vue.prototype.$baseUrl = process.env.VUE_APP_ROOT
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.prototype.$config = config
Vue.prototype.util = util
Vue.prototype.isAuth = util.isAuth
Vue.prototype.dealOptions = dealOptions
Vue.prototype.$video = Video
Vue.prototype.$bus = new Vue()


Vue.use(JAUI)
Vue.use(Print)
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')