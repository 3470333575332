<template>
<div class="u-table">
  <table>
    <tr>
      <template v-for="(item,index) in headers">
        <th :key="index">{{item.title}}</th>
      </template>
    </tr>
    <template v-for="(item,index) in tableData">
      <tr :key="index">
        <template v-for="(c,i) in headers" >
          <td  :key="i" v-if="c.prop">
            {{item[c.prop]}}
          </td>
          <td v-else :key="i" >
            <slot :name="c.slot" :info="item"></slot>
          </td>
        </template>

      </tr>
    </template>
  </table>
</div>
</template>

<script>
export default {
  name: "uTable",
  props: {
    headers: Array,
    tableData: Array
  }
}
</script>

<style scoped lang="scss">
@import "~@/style/mixin.scss";
.u-table{
  width: 100%;
 table{
   width: 100%;
   text-align: center;
   tr{
     line-height: 3em;
     font-size: 14px;
     &:nth-of-type(1){
       background-color: $table-header-color;
     }
   }
 }
}
</style>
