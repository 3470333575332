<template>
  <!-- 表格 -->
  <div class="content" v-loading="loading">
    <div class="header">月度区域维修TOP3分析</div>
    <div class="select">
      时间: <el-select v-model="value" placeholder="请选择" @change="getNewData">
        <el-option v-for="item in slelctArr" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import { getrepairTop } from '@/api'
export default {
  components: {
    JaInitChart,
  },
  data() {
    return {
      options: null,
      loading:true,
      value: 0,
      slelctArr: [
        {
          value: 0,
          label: '月'
        }, {
          value: 1,
          label: '季度'
        }, {
          value: 2,
          label: '年'
        }
      ]
    }
  },
  props: {
    loading: Boolean
  },
  created() {
    this.init(0)
  },  
  methods: {
    getNewData(e){
      this.init(e)
    },
    async init(i) {
      this.loading = true
      let { data: res } = await getrepairTop(i)
      this.setOptions(res)
      this.loading = false
    },
    setOptions(res) {
      let xAxisData = res.map(item => item.buildAndFloorName);
      let seriesData = res.map(item => ({
        value: Number(item.total),
        buildAndFloorName: item.buildAndFloorName
      }));
      this.options = {
        grid: {
          top: '18%',
          bottom: '28%',
        },
        xAxis: [
          {
            type: 'value',
            data: xAxisData,
            axisTick: {
              show: false,
            },
            axisPointer: {
              label: {
                show: false,
              }
            },
            axisLine: {
              show: true,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: false,  // 隐藏网格线
            },
          }
        ],
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisPointer: {
              show: false,
              type: 'none',
            },
            axisLabel: {
              show: false,
              fontSize: this.util.setFontSize(15, 1920),
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '0',
            type: 'bar',
            data: seriesData,
            barWidth: 35,
            itemStyle: {
              color: function (params) {
                // 根据柱子的索引设置不同的颜色
                if (params.dataIndex === 0) {
                  return '#4554D1'; // 第一种颜色
                } else if (params.dataIndex === 1) {
                  return '#9FA6E7'; // 第二种颜色
                } else {
                  return '#D9DCF6'; // 第三种颜色
                }
              },
            },
            label: {
              show: true,
              color: '#4352D4',
              position: 'right',
              formatter: function (params) {
                return params.data.buildAndFloorName;
              },
              fontSize: this.util.setFontSize(14, 1920),
            },
            barCategoryGap: '50%',
          },
        ],
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.data.buildAndFloorName + ' : ' + params.data.value;
          },
        },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  position: relative;
  color: #4352D4;
  font-weight: 700;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;

  .select {
    color: #aaa;
    position: absolute;
    z-index: 99;
    font-size: 16px;
    right: 20px;
    top: 13px;

    /deep/ .el-input__inner {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      width: 100px;
    }

    /deep/ .el-input__suffix {
      height: 30px;
      line-height: 45px;
      font-size: 16px;
    }
  }
}

.echart {
  height: 100%;
  width: 100%;
}
</style>