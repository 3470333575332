<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="50%"
                   :title="title"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="rules"
                     v-model="rowForm"
                     :formArray="detailOptions"
                     :allDisabled="allDisabled"
                     @save="saveForm"
                     @reset="show = false"
                     v-if="show">
            </ja-form>
            </ja-detail>
        </el-dialog>
    </div>
</template>
<script>
import { editSupplier, addSupplier } from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'select', label: '物资类型', key: 'materialType', labelWidth: '140px', opstion: [] },
                { input_type: 'input', label: '物资名称', key: 'materialName', labelWidth: '140px', maxlength: 30 },
                { input_type: 'input', label: '物资供应商单位', key: 'supplierCompany', labelWidth: '140px', maxlength: 30 },
                { input_type: 'input', label: '负责人', key: 'principal', labelWidth: '140px', maxlength: 30 },
                { input_type: 'input', label: '联系方式', key: 'contact', labelWidth: '140px' },
            ],
            rules: {
                materialType: [{ required: true, message: '请选择物资类型', trigger: 'blur' }],
                materialName: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText() }],
                supplierCompany: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText() }],
                principal: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText() }],
                contact: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkPhone() }],
            },
            title: null,
            rowForm: {},
            show: false,
            allDisabled: false,
            isAdd: true
        }
    },
    mounted() {
        let index1 = this.detailOptions.findIndex((e) => e.key === 'materialType')
        this.util.rqOptionsList({
            who: [this.detailOptions[index1]],
            rqData: { pageIndex: 1, pageSize: 100, dictType: 'material_type' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        async init(state, row) {
            this.title = '新增物资供应商信息'
            this.isAdd = true
            this.allDisabled = false
            this.rowForm = {}
            this.show = true
            if (state === 1) {
                this.isAdd = false
                this.rowForm = { ...row }
                this.title = '编辑物资供应商信息'
            }

        },
        saveForm() {
            if (this.isAdd) {
                addSupplier(this.rowForm).then(() => {
                    this.getInit()
                    this.show = false
                    this.$message.success('新增成功')
                })
            } else {
                editSupplier(this.rowForm).then(() => {
                    this.getInit()
                    this.show = false
                    this.$message.success('修改成功')
                })
            }
        }
    }
}
</script>
