<template>
    <el-dialog :visible.sync="show"
               width="40%"
               title="查看内部车辆">
        <ja-form :rules="rules"
                 allDisabled
                 v-model="rowForm"
                 :formArray="rowFormData"
                 v-if="show">
        </ja-form>
    </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
    components: {
        JaForm
    },
    data() {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'input', label: '类型', key: 'typeValue', labelWidth: '7vw', placeholder: ' ' },
                { input_type: 'input', label: '车牌号码', key: 'carNumber', labelWidth: '7vw', placeholder: ' ' },
                { input_type: 'input', label: '所属公司名称', key: 'companyname', labelWidth: '7vw', placeholder: ' ' },
                { input_type: 'input', label: '所属部门', key: 'deptname', labelWidth: '7vw', placeholder: ' ' },
            ],
            show: false,
            titleText: '新增',
            rowForm: {}
        }
    },
    methods: {
        init(row) {
            this.show = true
            this.rowForm = { ...row }
        }
    }
}
</script>