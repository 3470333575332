<template>
  <div class="container">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :showIndex="true"
      @append="normalEvent({ref:'addEdit',method:'append'})"
      @rowEdit="rowEdit"
      @rowDetail="normalEvent"
      @rowDelete="rowDelete"
      @getInit="getInit"
      @search="search"
      @reset="reset"
      @rowSubmit="rowSubmit"
      @rowApproval="rowApproval"
      :valueFormat="formatter"
      :editDataFormat="editDataFormat"
    />
    <!-- 新增编辑弹窗 -->
    <add-edit ref="addEdit"/>
    <!-- 查看详情 弹窗 -->
    <detail ref="detail"/>
  </div>
</template>

<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit/AddEdit.vue'
import Detail from './childComp/Detail/Detail.vue'
import {getPayList, delPay, submitPay, approvalPay,} from '@/api'

export default {
  title: '收费项目管理',
  name: 'ChargeItem',
  components: {
    AddEdit, Detail
  },
  mixins: [table],
  provide(){
    return{
      ...this.timeFormOptions
    }
  },
  data () {
    return {
      conditionData:[
        { input_type: 'input',key: 'serviceName',attribute: {placeholder: '收费项目名称',}},
        { input_type: 'select',key: 'category',attribute: {placeholder: '收费类别',},options: []},
        { input_type: 'select',key: 'status',attribute: {placeholder: '审核状态',},options: []},
        { input_type: 'select',key: 'isValid',attribute: {placeholder: '是否生效',},options: [{value:0,label:'否'},{value:1,label:'是'}]},
        { normalBtn: 'search', },
        { normalBtn: 'reset', },
        { normalBtn: 'add', limit:'charge::config::add'}
      ],
      columns:[
        { label: '收费项目编码', prop: 'serviceCode', width:140,attribute:{showOverflowTooltip:true,align:'center'} },
        { label: '收费项目名称', prop: 'serviceName',width:150,attribute:{showOverflowTooltip:true,align:'center'}   },
        { label: '收费项类别', prop: 'categoryLabel' },
        { label: '所在账单', prop: 'billTypeName' },
        // { label: '关联仪表', prop: 'device' },
        { label: '计费日期', prop: 'billingDate',width:150,attribute:{showOverflowTooltip:true,align:'center'}   },
        { label: '计费公式', prop: 'frequencyModeLabel', },
        { label: '单价', prop: 'unitPrice' },
        { label: '审核状态', prop: 'status' },
        { label: '是否生效', prop: 'isEffectiveLabel' },
        { label: '操作',
          input_type: 'button',
          width: 160,
          list: [
            { text: '查看', method: 'rowDetail', },
            { text: '提交', method: 'rowSubmit', limit: 'charge::config::submit'},
            { text: '审批', method: 'rowApproval', limit: 'charge::config::audit'},
            { text: '编辑', method: 'rowEdit', limit: 'charge::config::update' },
            { text: '删除', method: 'rowDelete', limit: 'charge::config::del'}
          ]
        }
      ],
      // 时间段的options
      timeFormOptions:{
        category:{},
        timeInterval:{},
      },
      activeRow:null, // 当前编辑的项
      categoryLink_idObj:{}
    }
  },
  mounted(){
    this.util.rqOptionsList({who:[this.conditionData[1],this.$refs.addEdit.rowFormData_first[0]],rqUrl:"/sys/dictData/service_category",resRule:'data'})
    this.util.rqOptionsList({who:[this.conditionData[2]],rqUrl:"/sys/dictData/status_type",resRule:'data'})
    this.util.rqOptionsList({who:[this.timeFormOptions.category],rqUrl:"/sys/dictData/service_time_interval_category",resRule:'data',})
    this.util.rqOptionsList({who:[this.timeFormOptions.timeInterval],rqUrl:"/sys/dictData/service_time_interval",resRule:'data'})

    // 关联仪表
    this.util.rqOptionsList({who:[this.$refs.addEdit.typeForm.instrument.rowFormData[2]],rqUrl:"/device/category/linkList",resRule:'data.data',labelName:'categoryName',valueName:'id',rqData:{params:{pageIndex:1,pageSize:200}}}).then(res=>{
      let categoryLink_idObj = {}
      res.forEach(v => {
        categoryLink_idObj[v.value] = v.label
      })
      this.$refs.detail.categoryLink_idObj = categoryLink_idObj
    }) 

  },
  methods: {
    getInit () {
      this.loading = true
      getPayList(this.filterData).then(res=>{
        console.log(res.data);
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    rowEdit (row) {
      this.activeRow = row
      this.$refs.addEdit.rowEdit()
    },
     // 删除
    rowDelete (row) { 
      this.$confirm('删除收费项后，该收费项的数据将不再可用，请谨慎操作。','确定删除该收费项吗？', {
          type: 'warning'
        }).then(res => {
        delPay(row.id).then(res=>{
          this.$message.success("已删除")
          this.getInit()
        })  
      })
    },
    // 提交
    rowSubmit(row){
      this.$confirm('是否确定进行提交？','提示', {
          type: 'warning'
        }).then(res => {
        submitPay(row.id).then(res=>{
          this.$message.success("已提交")
          this.getInit()
        })  
      })
    },
    // 审批
    rowApproval(row){
      this.$confirm('是否审批通过？','提示', {
          type: 'warning'
        }).then(res => {
        approvalPay(row.id).then(res=>{
          this.$message.success("已审批")
          this.getInit()
        })  
      })
    },
    formatter (val, key) {
      if(val!=0){
        return val || '-'
      }
      return val
    },
    editDataFormat(row,btn){
      if(row.isEffective !=0 && btn.text=='提交' || row.isEffective !=1 &&  btn.text == '审批' ||  row.isEffective !=0 &&  btn.text == '编辑'){
        return false
      }else{
        return true
      }
    },
  }
}
</script>

<style scoped>

</style>
