<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="50%"
                   :title="title"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="{}"
                     v-model="roomDetails"
                     :formArray="detailOptions"
                     :allDisabled="allDisabled"
                     v-if="show">
            </ja-form>
            <div class="dialog-footer"
                 v-if="isPass">
                <el-button @click="show = false">取消</el-button>
                <el-button type="primary"
                           @click="savePass">确认领取</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {editReceive,getPersonalmess} from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'input', label: '申请人', key: 'applicant', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '申请时间', key: 'createTime', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '物资编号', key: 'materialNumber', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '物资类型', key: 'materialTypeName', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '物资名称', key: 'materialName', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '数量（个）', key: 'amount', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '价格（元）', key: 'price', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '申请数量（个）', key: 'forNumber', labelWidth: '120px', placeholder: ' ' },
                { input_type: 'input', label: '领取状态', key: 'receiveStatusName', labelWidth: '120px', placeholder: ' ' },
            ],
            roomDetails: {},
            show: false,
            allDisabled: false,
            isPass: false,
            title: null
        }
    },
    methods: {
        init(state, row) {
            this.allDisabled = true
            this.roomDetails = row
            this.show = true
            this.isPass = false
            this.title = '待领取物资查看'
            console.log(this.roomDetails);
            if (state === 1) {
                this.isPass = true
                this.title = '确定领取'
            }
        },
        async savePass() {
            // let {data:res} = await getPersonalmess()
            this.roomDetails.receiveStatus = 1
            // this.roomDetails.applicantEmployeeId = res.userId
            editReceive(this.roomDetails).then((res) => {
                this.show = false
                this.getInit()
                if(res.msg=='当前物资库存不足，请先完成入库再执行'){
                    this.$alert('当前物资库存不足，请先完成入库再执行。', {
                        confirmButtonText: '已知晓',
                        showClose:false,
                        callback: action => {
                            
                        }
                    });
                }else{
                  this.$message.success('领取成功')  
                }
            })
        }
    }
}
</script>
<style scoped>
.dialog-footer {
    display: flex;
    justify-content: flex-end;
}
</style>
