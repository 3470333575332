<template>
    <!-- formArray： 操作栏数据 -->
    <div v-loading="loadingForm||false"
         :element-loading-text="loadingFormObj.text"
         :element-loading-spinner="loadingFormObj.icon"
         :element-loading-background="loadingFormObj.bg"
         :element-loading-custom-class="loadingFormObj.formLoading">
        <el-form :class="['ja_new_form',isEditFormDetail?'ja-EditForm':'']"
                 :rules="rules"
                 :ref="customizeRef"
                 :model="nowFilterData"
                 :label-width="labelWidth"
                 :disabled="allDisabled">
            <el-form-item class="nolabel">
                <el-col :class="{'facac': item.isCenter, 'fac': item.rightBox}"
                        :span='item.col ? Number(item.col) : Number(cols)'
                        v-for="(item,index) in formArray"
                        :key="index+0.1"
                        v-if="!item.hide && (item.limit?isAuth(item.limit):true)"
                        :style="{marginLeft: item.colMarginLeft}">
                    <el-form-item :prop="item.OtherKey?(Array.isArray(nowFilterData[item.key])?`${item.key}[${index-1}].${item.OtherKey}`:item.OtherKey):item.key"
                                  v-if="!item.noLabel && !item.relationDisplay"
                                  :label="item.label?item.label + ':':(item.labelEmpty?' ':'')"
                                  :rules="item.OtherKey?rules[item.otherRule]:rules[item.key]"
                                  :class="[item.isFlex?'el-form-itemFlex':'',
            item.isNotText?'formItemNormal':(item.input_type === 'text'||item.input_type === 'text2'||item.noMarginBottom?'formItemText':''),item.isRequired?'is-required':'']"
                                  :label-width="item.labelWidth">
                        <!-- 密码+textarea -->
                        <el-input :style="{width:item.styleWidth}"
                                  v-if="item.input_type === 'input'&& item.type!=='number'"
                                  :disabled="item.disabled || isDisabledFun(item)"
                                  :type="item.type || 'text'"
                                  v-model="nowFilterData[item.key]"
                                  :placeholder="item.placeholder||'请输入'+item.label"
                                  @focus="itemEvent(item.focusMethod)"
                                  :autosize="{ minRows: 2, maxRows: 20}"
                                  :show-password="item.showPassword?item.showPassword:false"
                                  :show-word-limit="item.showWordLimt||(item.type === 'textarea'?true:false)"
                                  :rows="item.rows"
                                  :auto-complete="item.autoComplete?item.autoComplete:'new-password'"
                                  @change="itemEvent(item.changeMethod)">
                            <template :slot="item.slotPosition"
                                      v-if="item.slotPosition">{{item.slotName}}</template>
                        </el-input>

                        <!-- 下拉框 -->
                        <el-select :style="{width:item.styleWidth}"
                                   @change="itemEvent(item.method, item, nowFilterData[item.key])"
                                   filterable
                                   v-bind="item.attribute"
                                   :clearable="!item.clearable?false:true"
                                   :multiple="item.multiple||false"
                                   @remove-tag="itemEvent(item.method2)"
                                   :disabled="item.disabled || isDisabledFun(item)"
                                   v-if="item.input_type === 'select'"
                                   v-model="nowFilterData[item.key]"
                                   :placeholder="allDisabled?' ':item.placeholder||item.label"
                                   :loading="item.loading?item.loading:false"
                                   :loading-text="item.loadingText?item.loadingText:'加载中...'"
                                   :no-match-text="item.noMatchText?item.noMatchText:'无匹配的项'"
                                   :no-data-text="item.noDataText?item.noDataText:'无数据'">
                            <el-option v-for="(list,index2) in selectOptions"
                                       :key="index2+0.01"
                                       :label="list.label"
                                       :value="list.value"
                                       :class="nowFilterData[item.key] === list.label ? 'selected' : ''"
                                       :disabled="list.disabled"></el-option>
                        </el-select>
                        <!-- 右边 -->
                        <span v-if="item.rightBox && (item.rightBox.input_type === 'text' || !item.rightBox.input_type)"
                              class="right-text">{{item.rightBox.text}}</span>
                        <div v-if="item.rightBox && item.rightBox.input_type === 'customize'"
                             :class="['right-customize',item.rightBox.isPosRight?'posRight':'']"
                             :style="{width: item.rightBox.width,marginLeft:item.rightBox.marginLeft }">
                            <slot :name="item.rightBox.customize"
                                  :row="nowFilterData">
                            </slot>
                        </div>
                        <el-button v-if="item.rightBoxItem && item.rightBoxItem.input_type === 'button' && !item.rightBoxItem.hide"
                                   :disabled="item.disabled"
                                   @click="itemEvent(item.rightBoxItem.method)"
                                   :type="item.rightBoxItem.type || 'primary'"
                                   :size="item.size || 'small'"
                                   class="right-text">{{item.rightBoxItem.label|| item.rightBoxItem.text}}</el-button>
                    </el-form-item>
                    <el-button v-if="item.rightBox && item.rightBox.input_type === 'button'"
                               :disabled="item.disabled"
                               @click="itemEvent(item.rightBox.method)"
                               :type="item.rightBox.type"
                               :size="item.size || ''"
                               class="right-text"
                               :style="{marginBottom: item.marginBottom}">{{item.rightBox.label|| item.rightBox.text}}</el-button>
                </el-col>
                <el-col :span="24">
                    <slot></slot>
                </el-col>
                <!-- 默认按钮 -->
                <el-col v-if="(!noButton && btnBox.length>0) && !allDisabled"
                        :span="24"
                        class="facac">
                    <el-button v-for="(item,index) in btnBox"
                               :disabled="item.disabled"
                               :style="item.style"
                               :plain="item.plain || false"
                               :key="index+0.0001"
                               :type="item.type || ''"
                               @click="itemEventBtn(item.method,item.monitor,item)">{{item.label}}</el-button>
                </el-col>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'customizeFrom',
    data() {
        return {
            loadingFormObj: {},
            header: {
                Authorization: ''
            },
            nowFilterData: this.value,
            // 图片预览相关
            dialogVisible: false,
            dialogImageUrl: ''
        }
    },
    props: {
        selectOptions: {
            type: Array,
            default: () => { return [] }
        },
        cols: {
            type: [Number, String],
            default: 11
        },
        // 选定参数
        value: {
            type: Object,
            default: function () {
                return {}
            }
        },
        // 底部是否有按钮
        noButton: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Object,
            default: () => { }
        },
        formArray: {
            type: Array,
            default: function () {
                return []
            }
        },
        marginLeft: {
            type: [String, Number],
            default: '80px'
        },
        customizeRef: {
            type: [String],
            default: 'formbox'
        },
        labelWidth: {
            type: [String, Number],
            default: '80px'
        },
        // 是否是编辑的弹窗
        isEditFormDetail: {
            type: Boolean,
            default: false
        },
        onSuccess: {
            type: Function,
            default: () => () => { }
        },
        // 是否禁止全部表单
        allDisabled: {
            type: Boolean,
            default: false
        },
        // 表单的加载
        loadingForm: {
            type: Boolean,
            default: false
        },
        // 是否禁用函数
        isDisabledFun: {
            type: Function,
            default: () => {
                return false
            }
        }
    },
    methods: {
        // 自定义事件monitor开启校验
        itemEvent(method, monitor = { input_type: "" }, val, data) {
            // console.log('触发开始---',method, monitor, val, data)
            if (monitor.relevance) {
                const relevance = monitor.relevance
                if (relevance.type == 'select') {
                    this.dealSelect(monitor, val, data)
                } else if (relevance.type == 'isDisplay') {
                    this.dealDisplay(monitor, val)
                }
            }
            if (method) this.$emit(method, monitor, val, data)

            // 触发change判断是否，是下拉框
            if (monitor.input_type == "select") {
                // 遍历所有的表单项
                this.formArray.forEach(async (item, index) => {
                    // 判断每一项是否有handleSelectContent函数，有则执行这项函数
                    if (item.handleSelectContent) {
                        item.options = await item.handleSelectContent(monitor, monitor.key, val, item, this.nowFilterData, true)
                        this.$forceUpdate()
                    }
                })
            }
        },
        itemEventBtn(method, monitor, val) {
            if (monitor) {
                this.$refs.formbox.validate((valid) => {
                    if (valid) {
                        this.$emit(method, val)
                    } else {
                        return false
                    }
                })
            } else {

                this.$emit(method, val)
            }
        },
    },
    mounted() {
        const token = this.$store.state.token
        this.header.Authorization = token.token_type + ' ' + token.access_token
    },
}
</script>

<style lang="scss" scoped>
.facac {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.right-text {
    margin-left: 10px;
    flex-shrink: 0;
}
.avatar {
    height: 90px;
}
</style>

<style lang="scss" >
@import '~@/style/mixin.scss';
.el-input.is-disabled .el-input__inner {
    // color: $theme_color_333 ;
    color: #606266 !important;
}
.el-input-number {
    line-height: 30px;
}
.ja_new_form {
    .el-date-editor .el-range__icon {
        margin-top: -6px;
    }
    .el-form-item {
        width: 100%;
    }
    .el-form-item .el-form-item {
        margin-bottom: 22px;
    }
    .el-form-item .el-form-item.formItemText {
        margin-bottom: 0;
    }
    &.ja-EditForm {
        .el-form-item .el-form-item {
            margin-bottom: 0;
        }
        .el-form-item .el-form-item.formItemNormal {
            margin-bottom: 22px;
        }
    }
    // .el-input {
    //   width: auto;
    // }
    // .el-button {
    //   height: $theme_height_32;
    //   line-height: $theme_height_32;
    //   padding-top: 0;
    //   padding-bottom: 0;
    // }
    & /deep/ textarea {
        background-color: $theme-color !important;
    }
    & .el-select {
        width: 100%;
        .el-input__inner {
            box-sizing: border-box;
            // width: calc(100% - 4px);
        }
        .el-input__suffix {
            right: 4px;
            top: 0;
            // right: 20px;
        }
    }
    & .el-date-editor .el-range-separator {
        width: 8% !important;
        margin-top: -6px;
    }
    .radioDiv {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        .radioText {
            margin-right: 10px;
        }
        .radio_item {
            line-height: 40px;
        }
    }
}
.posRight {
    position: absolute;
}
.right-customize {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
}
.el-form-item__content {
    position: relative;
}
.nolabel > .el-form-item__content {
    margin-left: 10px !important;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}
.el-form-itemFlex {
    .el-form-item__content {
        display: flex;
    }
}
.titlePart {
    font-weight: 600;
    font-size: 17px;
    color: #606266;
}
</style>
