<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag
               :close-on-click-modal="false"
               :visible.sync="show"
               width="50%"
               title="查看">
        <ja-form :rules="rules"
                 ref="jaForm"
                 :label-width="labelWidth"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :loadingForm="loadingForm"
                 :allDisabled="allDisabled"
                 @reset="show = false"
                 @save="saveForm">

        </ja-form>
    </el-dialog>
</template>
<script>
import editorMce from '@/components/editorMce'
import { replySupervision } from '@/api'

export default {
    inject: ['labelWidth', 'getInit',],
    components: {
        editorMce
    },
    data() {
        return {
            rowFormData: [
                { input_type: 'input', label: '事件编号', key: 'complaintNumber', col: 11 },
                { input_type: 'input', label: '反馈人', key: 'introducerEmployeeName', col: 11 },
                { input_type: 'input', label: '反馈人联系方式', key: 'introducerEmployeePhone', col: 11 },
                { input_type: 'input', label: '建议反馈方向', key: 'feedbackDirectionName', col: 11 },
                { input_type: 'input', label: '建议反馈描述', type: 'textarea', key: 'complaintsContent', col: 24 },
                { input_type: 'input', label: '提交时间', key: 'complaintsTime', col: 11 },
                { input_type: 'input', label: '回复状态', key: 'statusValue', col: 11 },
                { input_type: 'input', label: '回复人', key: 'feedbackEmployeeName', col: 11 },
                { input_type: 'input', label: '回复方式', key: 'replyWayName', col: 11 },
                { input_type: 'input', label: '回复内容', type: 'textarea', key: 'feedbackContent', col: 24 },
                { input_type: 'input', label: '回复时间', key: 'updateTime', col: 11 },

            ],
            rowForm: {},
            rules: {},
            loadingForm: false,
            show: false,
            allDisabled: true
        }
    },
    computed: {
        feedbackContentLength: function () {
            return this.rowFormData.findIndex(item => item.key == 'feedbackContent')
        }
    },
    methods: {
        rowDetail(row) {
            this.rowForm = { ...row }
            if (row.replyWay != '1') {
                this.$set(this.rowFormData[this.feedbackContentLength], 'hide', true)
            } else {
                this.$set(this.rowFormData[this.feedbackContentLength], 'hide', false)
            }
            this.show = true
        },
        saveForm() {
            this.loadingForm = true
            let rqMethod = replySupervision
            rqMethod(this.rowForm).then(res => {
                this.loadingForm = false
                this.$message.success(res.msg || '提交成功')
                this.show = false
                this.getInit()
            }).catch(error => {
                this.loadingForm = false
            })
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
</style>