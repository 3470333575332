<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag
               :close-on-click-modal="false"
               :visible.sync="show"
               width="55%"
               title="延时经营申请审批">
        <ja-form :rules="rules"
                 ref="jaForm"
                 :label-width="labelWidth"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :loadingForm="loadingForm"
                 :btnBox="btnBox"
                 @reject="reject"
                 @adopt="adopt">
        </ja-form>
    </el-dialog>
</template>
<script>
import { editExtendedOperation } from '@/api'
export default {
    inject: ['labelWidth', 'getInit',],
    data() {
        return {
            rowFormData: [
                { input_type: 'input', label: '店铺名称', key: 'applyDeptName', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '申请人', key: 'applyEmployeeName', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '申请人联系方式', key: 'applyEmployeePhone', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '延时时间', key: 'deferredBusinessTime', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '涉及人员数量', key: 'involvedNumber', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '延时经营申请原因', key: 'reason', type: 'textarea', col: 24, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '延时经营内容', key: 'content', type: 'textarea', col: 24, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '提交时间', key: 'createTime', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '审批状态', key: 'approvalStatusName', col: 12, disabled: true, placeholder: ' ' },
            ],
            rowForm: {},
            rules: {},
            btnBox: [
                { label: '驳回', method: 'reject', col: 8 },
                { label: '通过', method: 'adopt', type: 'primary' }
            ],
            loadingForm: false,
            show: false,
            dialogImageUrl: '',
            dialogVisible: false,
        }
    },
    methods: {
        rowEdit(row) {
            this.rowForm = { ...row }
            this.show = true
        },
        adopt() {
            this.$confirm('是否确定通过？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let { id } = this.rowForm
                await editExtendedOperation({ id, approvalStatus: 1 })
                this.show = false
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        reject() {
            this.$confirm('是否确定驳回？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                 let { id } = this.rowForm
                await editExtendedOperation({ id, approvalStatus: 2 })
                this.show = false
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
.img-box {
    display: flex;
    justify-content: space-between;
}
.img-item {
    height: 150px;
    width: 150px;
    .el-image {
        width: 100%;
        height: 100%;
    }
}
</style>