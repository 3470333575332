<template>
  <!-- 通行权限 -->
  <!-- nowFilterData：rowForm2 -->
  <div class="box">
    <el-form label-width="84px">

      <!-- 操作条件 -->
      <el-form-item label="授权区域：">
        <el-select v-model="rowForm2.dong" placeholder="选择楼栋" @change="buildChange">
          <el-option v-for="item in formArray[0].options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="rowForm2.floor" placeholder="选择楼层" @change="floorChange">
          <el-option v-for="item in floorList[0].options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <!-- 权限列表 -->
      <!-- default-expand-all: 是否默认展开所有节点 -->
      <!-- :default-expanded-keys="rowForm2.deviceIds" -->

      <el-form-item label="">
        <div class='treeContent'>
          <el-tree show-checkbox
            :data="deptOptions"
            :default-expanded-keys="rowForm2.deviceIds"
            :default-checked-keys="rowForm2.deviceIds"
            ref="authority"
            node-key="id"
            empty-text="暂无数据">
          </el-tree>
        </div>
      </el-form-item>

    </el-form>

    <div class="dialog-footer">
      <el-button type="primary" @click="submitDataScope">确 定</el-button>
      <el-button @click="reset">取 消</el-button>
    </div>
  </div>
</template>

<script>
  import {
    queryAll,
    employeeGrantDetail
  } from '@/api'

  export default {
    name: 'ja-scope',
    props: {
      formArray: {
        type: Array,
        default: function() {
          return []
        }
      },
      rowForm2: {
        type: Object,
        default: function() {
          return {}
        }
      },
      checkedKeys: {
        type: Array,
        default: function() {
          return []
        }
      }
    },
    data() {
      return {
        rowForm: {},
        floorList: [{
          options: []
        }],
        building_id: '',
        deptOptions: []
      }
    },
    created() {
      const paramObj = this.global.dropdown
      this.getInit(paramObj)
    },
    methods: {
      getInit(paramJson) {
        paramJson.category_code = '130,131'
        const paramObj = this.util.paramChange(paramJson, 'device_by_floor')
        queryAll('device_by_floor', paramObj).then(res => {

          if (res.data.data.length > 0) {
            const result = []
            const code = []
            const floorCode = []
            res.data.data.map(item => { // 楼宇
              if (code.indexOf(item.building_id) === -1) { // 楼宇去重
                code.push(item.building_id)
                const json = {}
                json.id = item.building_id
                json.label = item.building_name
                const children = []
                res.data.data.map(item2 => {

                    if (item.building_id === item2.building_id) { // 楼层

                      if (floorCode.indexOf(item2.floor_id) === -1) { // 楼层去重

                        floorCode.push(item2.floor_id)
                        const json2 = {}
                        json2.id = item2.floor_id
                        json2.label = item2.floor_name
                        children.push(json2)
                        const children2 = []

                        res.data.data.map(item3 => {
                          if (item2.floor_id === item3.floor_id) { // 设备
                            const json3 = {}
                            json3.id = item3.id
                            json3.label = item3.device_name
                            children2.push(json3)
                          }
                        })
                        json2.children = children2

                      }
                      
                    }

                })
                json.children = children
                result.push(json)
              }
            })
            this.deptOptions = result

          } else {
            this.deptOptions = res.data.data
          }
        })

      },

      reset() {
        this.$emit('reset2', false)
      },

      buildChange(id) {
        this.building_id = id
        const paramJsonOld = this.global.dropdown
        const paramJson = {}
        paramJson.pageIndex = paramJsonOld.pageIndex
        paramJson.pageSize = paramJsonOld.pageSize
        paramJson.building_id = id
        this.getInit(paramJson)


        const paramJson2 = {}
        paramJson2.pageIndex = paramJsonOld.pageIndex
        paramJson2.pageSize = paramJsonOld.pageSize
        paramJson2.parentId = id
        const paramObj = this.util.paramChange(paramJson2, 'floor_info')
        this.util.rqOptionsList({
          who: this.floorList[0],
          rqUrl: "/query/floor_info",
          rqData: paramObj,
          rqMethod: 'post',
          labelName: 'floor_name',
          valueName: 'id',
          addAll: true
        })
        // 获取楼层列表
      },

      floorChange(id) {
        const paramJsonOld = this.global.dropdown
        const paramJson = {}
        paramJson.pageIndex = paramJsonOld.pageIndex
        paramJson.pageSize = paramJsonOld.pageSize
        paramJson.building_id = this.building_id
        paramJson.floor_id = id
        this.getInit(paramJson)
      },

      getDeptAllCheckedKeys() { // 所有权限节点数据
        // 半选中的权限节点
        const halfCheckedKeys = this.$refs.authority.getHalfCheckedKeys()
        // console.log('========================>>>', halfCheckedKeys)
        // 目前被选中的权限节点
        const checkedKeys = this.$refs.authority.getCheckedKeys()
        // console.log('------------------------>>>', checkedKeys)
        // console.log("半选中的部门节点>>", halfCheckedKeys)
        // console.log("目前被选中的部门节点>>", checkedKeys)
        // console.log('目前 选中的key',nowKeys)
        // checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys)
        return checkedKeys
      },

      submitDataScope: function() {
        /** 提交按钮（通行权限） */
        let deptIds = []
        deptIds = this.getDeptAllCheckedKeys()
        if (deptIds.length > 0) {
          this.$emit('submitDataScope', this.rowForm2, deptIds)
        } else {
          this.$message.error('请先选择权限')
        }
      },
      setDefaultChecked () {
        this.$nextTick(() => {
          this.$refs.authority.setCheckedKeys(this.rowForm2.deviceIds)
        })
      }
    },
    watch: {
      'rowForm2.deviceIds': {
        handler: function() {
          this.setDefaultChecked()  // 如果为true。那么调用设置树形默认值的方法
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/style/mixin.scss';

  /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }

  /deep/ .el-select {
    margin-right: 10px;
  }

  /deep/ .el-input__prefix,
  /deep/ .el-input__suffix {
    top: 0;
  }

  .treeContent {
    max-height: 500px;
    overflow: auto;
    border: 1px solid $theme_bordercolor_DCDFE6;
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
</style>
