<template>
  <!-- 详情弹窗 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false"
    :visible.sync="showDetail"
    width="40%"
    @close="allDisabled=false"
    title="详情"
  >
    <ja-form
      :rules="rules"
      :label-width="labelWidth"
      v-model="rowForm"
      :formArray="rowFormData"
      :allDisabled="allDisabled"
      @reset="showDetail = false"
      v-if="showDetail"
      ref="jaForm"
    >
    </ja-form>
  </el-dialog>
</template>
<script>
import {
  getDepartment
} from '@/api'
export default {
  data() {
    return {
      //详情
      showDetail: false,
      detailData: {},
      rules:{},
      rowForm:{},
      allDisabled:true,
      labelWidth: `${this.util.setFontSize(140)}px`,
      rowFormData: [
        {
          input_type: 'input',
          label: '社区名称',

          key: 'parkName'
        },
        {
          input_type: 'select',
          label: '省',
          key: 'provinceId',
          options: [],
          relevance: {
            type: 'select', // 必填 -----注意！！！！！！！！！！
            sonKey: 'cityId', // 必填
            otherSonKey: ['districtId'], // 若还涉及到孙子字段
            This: this, // 必填
            // childrenKey:'children',// 非异步的 情况下。拿的是自己的 的哪个字段.默认 是 children 。
            dealOptionsParma: {
              // 若还要对子元素的值，进行处理。（如果本来就是 {label,value} 字段，就不必写 dealOptionsParma 字段）
              labelName: 'label',
              valueName: 'value',
              isReturnAll: true
            }
          }
        },
        {
          input_type: 'select',
          label: '市',
          key: 'cityId',
          options: [],
          relevance: { sonKey: 'districtId', This: this, type: 'select' }
        },
        { input_type: 'select', options: [], label: '区', key: 'districtId' },
        {
          input_type: 'input',
          label: '详细地址',
          maxlength: 30,
          key: 'address'
        },
        {
          input_type: 'input',
          label: '联系电话',
          key: 'phone'
        },
        {
          input_type: 'select',
          label: '管理部门',
          key: 'deptId',
          method: 'changeCity',
          multiple: true,
          col: 22,
          options: function () {
            return getDepartment({ pageIndex: 1, pageSize: 200 }).then(
              (res) => {
                if (res.code == 200) {
                  let data = res.data.data.map((item) => {
                    return {
                      label: item.companyName + '-' + item.deptName,
                      value: item.id
                    }
                  })
                  return data.length != 0 ? data : []
                }
              }
            )
          }
        },
        {
          input_type: 'input',
          type: 'textarea',
          label: '备注',
          key: 'remark',
          col: 22
        }
      ]
    }
  },
  mounted() {
    this.util.rqOptionsList({
      who: [this.rowFormData[1]],
      rqUrl: '/sys/region',
      labelName: 'label',
      valueName: 'value',
      resRule: 'data',
      isReturnAll: true
    })
  },
  methods: {
    showDetailFun(row) {
    //   console.log('row==>', row)
      this.showDetail = true
      this.allDisabled=true
      this.rowForm = {...row}
      if(row.deptId!=""){
        this.rowForm.deptId = row.deptId.split(',').map(Number)
      }else{
        this.rowForm.deptId=[]
      }
      
      this.$nextTick(() => {
        this.util.setOptionsInit({
          arr: this.rowFormData,
          This: this,
          obj: this.rowForm,
          ref: 'jaForm'
        })
      })
    }
  }
}
</script>