<template>
    <div>
        <el-col :span="6"
                v-loading="loading">
            <div class="box"
                 ref="wrap"></div>
        </el-col>
    </div>
</template>

<script>
import { getGraphicalPie } from '@/api'
export default {
    title: '报修记录',
    props: {
        // 1=近30天报修类型占比 2=近30天报修处理率
        type: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            loading: false
        }
    },
    mounted() {
        this.setOpsition()
    },
    methods: {
        async setOpsition() {
            let data = await this.getInit()
            let option = {
                title: {
                    text: this.type == 1 ? '近30天报修类型占比' : '近30天报修处理率',
                    left: 'left',
                    textStyle: {
                        fontSize: 13
                    },
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '10%',
                    left: 'center'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['50%', '60%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '35',
                                fontWeight: 'bold',
                                formatter: '{d} %'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data,
                        itemStyle: {
                            normal: {
                                color: '#f59a22'
                            },
                        },
                    }
                ]
            }
            const myChart = this.$echarts.init(this.$refs.wrap)
            myChart.setOption(option)
            window.addEventListener('resize', () => {
                myChart.resize();
            })
        },
        async getInit() {
            this.loading = true
            let obj1 = { itemStyle: { color: '#f59a22' } }
            let obj2 = { itemStyle: { color: '#555555' } }
            await getGraphicalPie().then(res => {
                let { achieveNumber, numberHousehold, numberPublic, undoneNumber } = res.data
                if (this.type === 1) {
                    obj1.name = '租户报修'
                    obj1.value = numberHousehold
                    obj2.name = '公区报修'
                    obj2.value = numberPublic
                } else {
                    obj1.name = '已完成'
                    obj1.value = achieveNumber
                    obj2.name = '未完成'
                    obj2.value = undoneNumber
                }
            })
            this.loading = false
            return [obj1, obj2]
        }
    }
}
</script>
<style scoped>
.box {
    width: 100%;
    height: 100%;
    background: #ffffff;
}
.el-col {
    height: 400px;
}
</style>