// 巡更
import http from './plugin/ajax'

/**
 * 巡更路线
 */
// 列表
export const pathList = (data) => http.post('/patrol/path/list', data)
// 新增
export const addPath = (data) => http.post('/patrol/path', data)
// 修改
export const updatePath = (data) => http.put('/patrol/path', data)
// 删除
export const deleteByPathId = (id) => http.delete(`/patrol/path/${id}`)
// 发布
export const pushLine = (id) => http.put(`/patrol/path/publish/${id}`)
// 作废
export const cancellation = (id) => http.put(`/patrol/path/cancellation/${id}`)

/**
 * 巡更计划
 */
// 列表
export const planList = (data) => http.post('/patrol/plan/list', data)
// 新增
export const addPlan = (data) => http.post('/patrol/plan', data)
// 修改
export const updatePlan = (data) => http.put('/patrol/plan', data)
// 删除
export const deleteByPlanId = (id) => http.delete(`/patrol/plan/${id}`)
// 发布
export const pushPlan = (id) => http.put(`/patrol/plan/publish/${id}`)
// 作废
export const cancePlan = (id) => http.put(`/patrol/plan/cancellation/${id}`)
