<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @lookRoom="lookRoom">
        </ja-table>
        <show-details ref="detailRefs"></show-details>
    </div>
</template>

<script>
import { getDecPatrolRecordList } from '@/api'
import table from '@/views/mixins/table'
import showDetails from './components/showDetails.vue'
export default {
    title: '装修巡查记录',
    mixins: [table],
    name: 'patrolRecord',
    components: {
        showDetails,
    },
    data() {
        return {
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'select', key: 'patrolType', attribute: { placeholder: '巡查项目类型', size: 'small', }, options: [] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
            ],
            columns: [
                // 表头数据
                { label: '驻户名称', prop: 'tenantName' },
                { label: '报装人', prop: 'shipper' },
                { label: '报装人手机号码', prop: 'shipperPhone' },
                { label: '所属楼栋', prop: 'buildingName' },
                { label: '所属楼层', prop: 'floorName' },
                { label: '所属租赁单元', prop: 'roomName' },
                { label: '巡查项目类型', prop: 'patrolTypeName' },
                { label: '巡查人员', prop: 'updateBy' },
                { label: '巡查时间', prop: 'updateTime' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'renovation::patrolRecord::show' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,

        }
    },
    mounted() {
        let index = this.conditionData.findIndex((e) => e.key === 'patrolType')
        this.util.rqOptionsList({
            who: [this.conditionData[index]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'patrol_type' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        getInit() {
            getDecPatrolRecordList(this.filterData)
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
        },
        lookRoom(row) {
            this.$refs.detailRefs.init(row)
        }
    }
}
</script>

<style scoped>
.download {
    width: 5vw;
}
</style>

