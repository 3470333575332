import http from './plugin/ajax'
// 头部信息
export const getBillList = (data) => http.post('/bizBillInfoNew/list', data) // 查询账单列表

export const getBillStatistics = (data) => http.post('/bizBillInfoNew/statistics', data) // 查询账单统计

export const getbizBillDetailNewList = (data) => http.post('/bizBillDetailNew/list', data) // 查询账单详情列表

export const editbizBillInfoNew = (data) => http.put('/bizBillInfoNew', data) // 修改账单列表

export const editbizBillDetailNew = (data) => http.post('/bizBillInfoNew/updateDetail', data) // 修改账单明细列表

export const bizBillInfoNewBatchSubmit = (data) => http.post('/bizBillInfoNew/batch/submit', data) // 批量修改账单状态

export const getBillPdfList = (data) => http.post('/bizBillDetailNew/getBill', data) // 获取预览账单列表

export const getBillPdfTitle = (data) => http.get(`/bizBillDetailNew/getBillScattered/${data}`) // 获取预览账单头部信息

export const getArrearageBillList = (data) => http.post('/bizBillArrearsNew/list', data)// 获取欠费账单列表
export const writeOffRegistration = (data) => http.put('/bizBillDetailNew', data)// 核销登记
export const writeOffRegistrationHistory = (data) => http.put('/bizBillDetailNew/history', data)// 历史核销登记
export const setWriteOffAll = (data) => http.put(`/bizBillDetailNew/writeOffByBillId/${data}`)// 一键核销
export const customBillToFixed = (data) => http.put(`/bizBillDetailNew/zeroAfter`, data)// 长短尾抹零
export const showWriteOffDetail = (data) => http.get(`/bizBillDetailNew/getWriteOffRecord/${data}`)// 查看核销记录
export const getHistoryWriteOffRecord = (data) => http.get(`/bizBillDetailNew/getHistoryWriteOffRecord/${data}`)// 查看历史核销记录
export const getArrearageBillListDerail = (data) => http.post('/bizBillDetailNew/listNew', data) // 账单核销数据详情
export const getArrearageBillListHistory = (data) => http.post('/bizBillDetailNew/listNewHistory', data) // 账单核销数据历史
export const getArrearageBillListConfirm = (data) => http.put(`/bizBillDetailNew/confirm/${data}`) // 账单核销确认
export const getArrearageBillListCancel = (data) => http.put(`/bizBillDetailNew/cancel/${data}`) // 账单核销取消
