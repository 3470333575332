<template>
    <el-dialog :visible.sync="show"
               :close-on-click-modal="false"
               width="50%"
               :title="title">
        <ja-form :rules="rules"
                 v-if="show"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :allDisabled="allDisabled"
                 :btnBox="btnBox"
                 label-width="12vw"
                 @save="saveForm"
                 @reset="show = false">
        </ja-form>
    </el-dialog>
</template>
<script>
import { editbizBillInfoNew } from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'input', label: '驻户名称', key: 'tenantName', col: 12, styleWidth: '85%', disabled: true },
                { input_type: 'input', label: '租赁单元名称', key: 'roomName', col: 12, styleWidth: '85%', disabled: true },
                { input_type: 'input', label: '合同批次', key: 'contractBatch', col: 12, styleWidth: '85%', disabled: true },
                { input_type: 'input', label: '计费周期', key: 'date', col: 12, styleWidth: '85%', disabled: true },
                { input_type: 'input', label: '租金', key: 'rent', col: 12, styleWidth: '85%', disabled: true },
                { input_type: 'input', label: '物业管理费', key: 'propertyManagementFee', col: 12, styleWidth: '85%', disabled: true },
                { input_type: 'input', label: '电费', key: 'electricity', col: 12, styleWidth: '85%', disabled: true },
                { input_type: 'input', label: '设备维护服务费（电）', key: 'iptElectricity', col: 12, styleWidth: '85%', disabled: true },
                { input_type: 'input', label: '水费', key: 'water', col: 12, styleWidth: '85%', disabled: true },
                { input_type: 'input', label: '设备维护服务费（水）', key: 'iotWater', col: 12, styleWidth: '85%', disabled: true },
            ],
            show: false,
            rowForm: {},
            allDisabled: false,
            title: '',
            btnBox: [
                { label: '取消', method: 'reset', col: 8, limit: '' },
                { label: '确认', method: 'save', type: 'primary' }
            ],
        }
    },
    methods: {
        init(state = 1, row) {
            this.title = '开新账单'
            this.allDisabled = false
            this.show = true
            this.rowForm = { ...row }
            if (state == 2) {
                this.allDisabled = true
                this.title = '查看'
            }
        },
        saveForm() {
            this.$confirm(`该明细为 ${this.rowForm.date} ${this.rowForm.tenantName}的本期账单，是否确定开新账单？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await editbizBillInfoNew({ ...this.rowForm, status: 1 })
                this.$message.success('操作成功')
                this.show = false
                this.getInit()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },

    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
</style>
