<template>
    <div>
        <ja-table :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  v-model="filterData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @approval="approval"
                  @append="addTermination"
                  @showDetails="showDetails" />

        <termination ref="terminationRef"></termination>
        <all-detail ref="allDetailRefs"></all-detail>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import { getTerminationList } from '@/api'
import Termination from './components/termination.vue'
import AllDetail from './components/allDetail.vue'
export default {
    title: '合同终止',
    mixins: [table],
    components: {
        Termination,
        AllDetail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'contractCode', attribute: { placeholder: '合同编号', size: 'small' } },
                { input_type: 'input', key: 'contractName', attribute: { placeholder: '合同名称', size: 'small' } },
                { input_type: 'input', key: 'tenantName', attribute: { placeholder: '驻户名称', size: 'small' } },
                { input_type: 'select', key: 'contractType', attribute: { placeholder: '合同类型', size: 'small' }, options: [] },
                { input_type: 'date', type: 'daterange', value: 'date', startPlaceholder: '开始时间', endPlaceholder: '结束时间', styleWidth: '300px', valueFormat: 'yyyy-MM-dd' },
                { input_type: 'date', value: 'contractBatch', type: 'month', placeholder: '合同批次', valueFormat: 'yyyy-MM-dd' },
                { input_type: 'select', key: 'status', attribute: { placeholder: '全部状态', size: 'small' }, options: [{ label: '已终止', value: 1 }, { label: '已驳回', value: 2 }, { label: '待审批', value: 3 }, { label: '已通过', value: 4 }] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '新增终止', limit: 'terminationOfContract::index::add' }
            ],
            columns: [
                { label: '合同编号', prop: 'contractCode', width: 150 },
                { label: '合同名称', prop: 'contractName', width: 150 },
                { label: '合同类型', prop: 'contractTypeName', width: 150 },
                { label: '租期', prop: 'date', width: 200 },
                { label: '计租开始日期', prop: 'billingTime', width: 150 },
                { label: '驻户名称', prop: 'tenantName', width: 150 },
                { label: '驻户租赁单元', prop: 'roomName', width: 150 },
                { label: '合同批次', prop: 'contractBatch', width: 150 },
                { label: '手动终止申请人', prop: 'terminationApplyName', width: 150 },
                { label: '申请时间', prop: 'terminationApplyDate', width: 150 },
                { label: '终止时间', prop: 'terminationDate', width: 200, },
                { label: '状态', prop: 'statusName' },
                { label: '终止方式', prop: 'remark' },
                {
                    label: '操作', input_type: 'button', width: 200, attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '通过/驳回', method: 'approval', limit: 'terminationOfContract::index::approval' },
                        { text: '查看', method: 'showDetails', limit: 'terminationOfContract::index::showDetails' },

                    ]
                }
            ],
            tableData: [],
            total: 0,
            contractTypeOptions: []

        }
    },
    async mounted() {
        let length = this.conditionData.findIndex(item => item.key == 'contractType')
        await this.util.rqOptionsList({
            who: [this.conditionData[length]],
            rqUrl: '/sys/dictData/contract_type',
            resRule: 'data'
        }).then(res => {
            this.contractTypeOptions = res
        })
        this.getInit()
    },
    methods: {
        getInit() {
            this.loading = true
            console.log(this.contractTypeOptions);
            getTerminationList(this.rqParmas()).then(res => {
                res.data.data.map(item => {
                    item.date = item.startTime + '至' + item.endTime
                    if (typeof item.contractType == 'string') {
                        this.contractTypeOptions.map(option => {
                            if (option.value == item.contractType) {
                                item.contractTypeName = option.label
                            }
                        })
                    }
                    if (item.status) {
                        item.statusName = ['已终止', '已驳回', '待审批','已通过'][item.status - 1]
                    }

                })
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },
        showDetails(row) {
            this.$refs.allDetailRefs.init(3, row)
        },
        addTermination() {
            this.$refs.terminationRef.initState()
        },
        approval(row) {
            this.$refs.allDetailRefs.init(2, row)
        },
        rqParmas() {
            let obj = { ...this.filterData }
            if (obj.date) {
                obj.startTime = this.filterData.date[0] || ''
                obj.endTime = this.filterData.date[1] || ''
            }
            return obj
        },
        editDataFormat(row, bto) {
            if ((row.status == 3 && bto.text == '通过/驳回') || row.status != 3 && bto.text == '查看') {
                return true
            }
        }
    }
}
</script>

<style scoped>
</style>
