<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @append="append"
                  @showResident="showResident"
                  @Delete="Delete"
                  @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/设备台账模板.xlsx', filename: '设备台账模板', uploadURL: '/iotDeviceRecordTemp/upload'})"> </ja-table>
        <add-edit ref="addEdit"></add-edit>

        <import-form ref='importForm'
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
        <show-detail ref="detailRef"></show-detail>
    </div>
</template> 
<script>
import table from "@/views/mixins/table"
import importForm from '@/views/home/components/common/importForm.vue'
import { getFollowLists, deleteFollow } from '@/api'
import checkResult from '@/views/home/components/common/checkResult.vue'
import AddEdit from './components/AddEdit.vue'
import ShowDetail from './components/showDetail.vue'
export default {
    title: '跟进状态记录',
    name: 'followUpStatus',
    mixins: [table],
    components: {
        importForm,
        checkResult,
        AddEdit,
        ShowDetail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'projectName', attribute: { placeholder: '项目名称', size: 'small', } },
                { input_type: 'select', key: 'progressStatus', attribute: { placeholder: '进度状态', size: 'small', }, options: [] },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
                { normalBtn: 'add', text: '新增', limit: 'customerManagement::followUpStatus::add' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqParmas, rqUrl: '/cmProjectRecord/export', rqMethod: 'post' }, limit: 'customerManagement::followUpStatus::import' }
            ],
            columns: [
                { label: '项目名称', prop: 'projectName' },
                { label: '进度状态', prop: 'progressStatusName' },
                { label: '进度描述', prop: 'remark' },
                { label: '跟进人', prop: 'followUpPeopleName' },
                { label: '跟进人联系方式', prop: 'followUpPeopleContact' },
                { label: '操作时间', prop: 'updateTime' },
                {
                    label: '操作', prop: 'operation',
                    input_type: 'button',
                    width: '150px',
                    list: [
                        { text: '查看', method: 'showResident', limit: 'customerManagement::followUpStatus::show' },
                        { text: '删除', method: 'Delete', limit: 'customerManagement::followUpStatus::delete' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            results: null,
        }
    },
    mounted() {
        let index = this.conditionData.findIndex(e => e.key === 'progressStatus')
        this.util.rqOptionsList({
            who: this.conditionData[index],
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            rqData: { dictType: 'progress_status' },
            labelName: 'dictLabel',
            valueName: 'dictValue'
        })
    },
    methods: {
        getInit() {
            getFollowLists(this.filterData).then(res => {
                this.tableData = res.data.data
                this.loading = false
                this.total = res.data.total
            })
        },
        append() {
            this.$refs.addEdit.init(1);
        },
        Delete(row) {
            this.$confirm('是否确定删除该项目跟进记录？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteFollow(row.id).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getInit()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        showResident(row) {
            this.$refs.detailRef.init(row);
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },


    }
}
</script>