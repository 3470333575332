<template>
  <el-dialog :visible.sync="show" width="50%" title="查看缴费单">
    <ja-form
      :rules="rules"
      allDisabled
      v-model="rowForm"
      :formArray="rowFormData"
      v-if="show"
      :editDataFormat="editDataFormat"
    >
    </ja-form>
  </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
  components: {
    JaForm
  },
  data() {
    return {
      rules: {},
      rowFormData: [
        {
          input_type: 'input',
          label: '驻户名称',
          key: 'tenantName',
          labelWidth: '100px',
          styleWidth: '180px'
        },
        {
          input_type: 'input',
          label: '合同编号',
          key: 'contractCode',
          labelWidth: '125px',
          styleWidth: '180px'
        },
        {
          input_type: 'input',
          label: '账单时间',
          key: 'billDate',
          labelWidth: '100px',
          styleWidth: '180px'
        },
        {
          input_type: 'input',
          label: '本期合计应收（元）',
          key: 'amountReceivable',
          disabled: true,
          labelWidth: '125px',
          styleWidth: '180px'
        },
        {
          input_type: 'input',
          label: '本期已缴（元）',
          key: 'amountWriteOff',
          labelWidth: '100px',
          styleWidth: '180px'
        },
        {
          input_type: 'input',
          label: '到账时间',
          key: 'paymentDate',
          labelWidth: '125px',
          styleWidth: '180px'
        },
        {
          input_type: 'input',
          label: '状态',
          key: 'statusName',
          labelWidth: '100px',
          styleWidth: '180px',
          disabled: true
        },
        {
          input_type: 'input',
          type: 'textarea',
          label: '备注',
          key: 'remark',
          col: 18,
          labelWidth: '100px',
          styleWidth: '260px',
          disabled: true
        },
        {
          input_type: 'input',
          label: '确认人',
          key: 'updateBy',
          labelWidth: '100px',
          styleWidth: '180px',
          disabled: true
        },
        {
          input_type: 'input',
          label: '确认时间',
          key: 'billSendTime',
          labelWidth: '125px',
          styleWidth: '180px',
          disabled: true
        },
      ],
      show: false,
      titleText: '查看缴费单',
      rowForm: {}
    }
  },
  methods: {
    init(row) {
      this.show = true
      this.rowForm = { ...row }
    }
  }
}
</script>