<template>
  <!-- 驻户申请 -->
  <div class="allwh">
    <!-- <div class="allwh-wrapper minWidth1600" style="display: flex"> -->
    <div class="top">
      <el-tabs
        v-model="activeMenu"
        type="card"
        @tab-click="handleClick"
        class="menu"
      >
        <el-tab-pane
          v-for="(item, index) in menuList"
          :key="index"
          :label="item.label"
          :name="item.value"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      :valueFormat="formatter"
      @dictonaList="dictonaList"
      @getInit="getInit"
      @search="search"
      @reset="reset"
      :showIndex="false"
      @showDetail="showDetail"
    >
    </ja-table>
    <add-edit ref="addEdit"></add-edit>
    <show-information ref="showInformation"></show-information>
    <set-up ref="setUp"></set-up>
  </div>
  <!-- </div> -->
</template>

<script>
import { getTenantApplyList, delDiction } from '@/api'
import table from '@/views/mixins/table'
import {
  dicManagerQuery,
  dicManagerColumn,
  dicManagerFormData,
  dicManagerRules
} from './tenantApplicationColumn'
import AddEdit from './components/AddEdit.vue'
import ShowInformation from './components/ShowInformation.vue'
import SetUp from './components/SetUp.vue'

export default {
  title: '驻户申请',
  mixins: [table],
  name: 'index',
  components: {
    AddEdit,
    ShowInformation,
    SetUp
  },
  data() {
    return {
      typeCode: '',
      filterData: {
        // pageIndex: 1,
        // pageSize: 10
      },
      conditionData: dicManagerQuery,
      columns: dicManagerColumn,
      tableData: [],
      loading: false,
      total: 30,
      rowForm: {},
      activeMenu: 'approval',
      menuList: [
        { label: '待确定', value: 'approval' },
        { label: '已确定', value: 'order' }
      ],
      status: '0'
    }
  },
  created() {
    this.columns[7].hide = true
    this.columns[8].list[1].text = '通过/驳回'
    this.status = '0'
  },
  methods: {
    handleClick(tab, event) {
      if (tab.index == 0) {
        this.columns[7].hide = true
        this.columns[8].list[1].text = '通过/驳回'
        this.status = '0'
        this.filterData.pageIndex = 1
        this.getInit()
      } else {
        this.columns[7].hide = false
        this.columns[8].list[1].text = '设置'
        this.status = '1'
        this.filterData.pageIndex = 1
        this.getInit()
      }
    },
    getInit() {
      this.loading = true
      // 获取楼层列表
      getTenantApplyList({
        pageIndex: this.filterData.pageIndex,
        pageSize: this.filterData.pageSize,
        status: this.status,
        tenantName: this.filterData.tenantName,
        storeName: this.filterData.storeName,
        carNumber: this.filterData.carNumber,
        serviceType: this.filterData.serviceType,
        applicant: this.filterData.applicant,
        contactNumber: this.filterData.contactNumber
      }).then(({ data }) => {
        this.tableData = data.data
        this.total = data.total
        this.loading = false
      })
    },
    //通过/驳回 or 设置
    rowEdit(row) {
      let state = this.columns[8].list[1].text
      if (state == '通过/驳回') {
        this.$refs.addEdit.init(row)
      } else {
        this.$refs.setUp.init(row)
      }
    },
    //查看
    showDetail(row) {
      let state = this.columns[8].list[1].text
      if (state == '通过/驳回') {
        this.$refs.showInformation.init(1, row)
      } else {
        this.$refs.showInformation.init(2, row)
      }
    },
    // 删除
    // rowDelete(row) {
    //   this.$confirm('删除后将不再保留车位，是否确定删除？').then((res) => {
    //     delInternalVehicle(row.id).then((res) => {
    //       this.$message.success('已删除')
    //       this.getInit()
    //     })
    //   })
    // },
    formatter(val, key) {
      if (key === 'serviceType') {
        const statusVal = { 0: '月卡', 1: '季卡', 2: '半年卡', 3: '年卡' }
        return statusVal[val]
      }
      return val
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-col .el-form-item .el-input__inner {
  height: 40px;
}
/deep/ .el-select {
  width: 100% !important;
}
.top {
  width: 98% !important;
  height: 40px;
  background: white !important;
  // margin-left: 16px;
  // margin-top: 10px;
  display: flex;
  align-items: center;
  margin: 20px 30px 0 15px;
  justify-content: space-between;
}
.menu {
  background: white !important;
  width: 100%;
  /deep/ .el-tabs__header {
    margin-bottom: 0;
  }
  /deep/ .el-tabs__nav-scroll {
    background: white !important;
  }
}
</style>
