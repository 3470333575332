
<template>
  <div>
    <ja-table ref="tst" v-model="filterData" :columns="columns" :tableData="tableData" :conditionData="conditionData" :total="total" :border="true" :showSelect="!isOnePerson?true:false" :loading="loading" index_label="序号" :showIndex="true" @handleSelectItem="handleSelectItem" @handleSelectionAll="handleSelectionAll" @selectRow="selectRow" @getInit="getInit2" @search="search" @reset="reset">
      <template #right>
        <el-button type="primary" @click="submit()" class="submit" v-if="!isOnePerson">确认添加</el-button>
      </template>
    </ja-table>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import selectTable from '@/views/mixins/selectTable'
import { deviceInfoGet } from '@/api'

export default {
  title: '选择设备',
  mixins: [table, selectTable],
  name: 'index',
  data() {
    let conditionData_own = [
      {
        input_type: 'select', key: 'category', attribute: { placeholder: '设备大类', size: 'small' }, options: [],
        relevance: { sonKey: 'subcategory', This: this, rqParam: { rqUrl: "/device/category", rqData: () => { return { params: { pageIndex: 1, pageSize: 200, isAll: '2', parentId: this.filterData.category } } }, labelName: 'categoryName', valueName: 'id' }, }
      },
      { input_type: 'select', key: 'subcategory', attribute: { placeholder: '设备小类', size: 'small' }, options: [] },
      { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small' } },
      { normalBtn: 'search' },
      { normalBtn: 'add', limit: 'device::manage::add' },
      { normalBtn: 'reset' },
    ]
    let columns_own = [
      { label: '设备名称', prop: 'deviceName', attribute: { showOverflowTooltip: false, align: 'center' } },
      { label: '设备编码', prop: 'deviceCode' },
      { label: '设备大类', prop: 'categoryName' },
      { label: '设备小类', prop: 'subcategoryName' },
      { label: '所属楼栋', prop: 'buildingName', width: 80 },
      { label: '所属楼层', prop: 'floorName', width: 80 },
      { label: '设备状态', prop: 'runStatus', width: 80 },
      { label: '设备品牌', prop: 'brand' },
      { label: '设备型号', prop: 'model' },
      { label: '检测时间', prop: 'monitorTime', attribute: { showOverflowTooltip: false, align: 'center' } },
    ]
    return {
      rq_Method: deviceInfoGet,
      keyName: 'deviceName',
      conditionData: [...conditionData_own, { normalBtn: 'search', }, { normalBtn: 'reset' }, { slotName: 'isAll' }],
      columns: [...columns_own,
      {
        label: '操作', input_type: 'button', width: 120, attribute: { fixed: 'right', align: 'center' },
        list: [
          { text: '选择', method: 'selectRow' }
        ]
      }
      ],
    }
  },
  created() {
    // 顶部搜索框
    this.util.rqOptionsList({ who: [this.conditionData[0]], rqUrl: "/device/category", rqData: { params: { pageIndex: 1, pageSize: 200, isAll: '1' } }, rqMethod: 'get', labelName: 'categoryName', valueName: 'id' }) // 获取设备大类列表
  },
  methods: {

  }
}
</script>