<template>
  <!--  普通的表单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="'查看'">
    <ja-form ref="jaForm"
             v-model="rowForm"
             :rules={}
             :all-disabled="true"
             :formArray="rowFormData"
             :loadingForm="loadingForm"
             :labelWidth="labelWidth"
             v-if="showEditAll">
        <!-- 点位信息 -->
        <template #pointData><point-form :rowFormData="pointRowFormData" :rowForm="otherForm" /></template>
    </ja-form>
  </el-dialog>
</template>
<script>
import PointForm from "./pointForm.vue"

export default {
    components: { PointForm },
    inject: ['labelWidth', 'getInit'],
    data () {
        return {
            rowFormData: [
                { input_type: 'input', label: '名称', key: 'pathName', col: 11 },
                { input_type: 'select', key: 'type', label: '类型', options: [{ label: '工程巡检', value: '0' }, { label: '物业巡检', value: '1' }, { label: '运营巡检', value: '2' }] },
                { input_type: 'title', text: '点位信息', col: 24 },
                { input_type: 'customize', customize: 'pointData', col: 24, noMarginLeft: true, noMarginBottom: true },
                { input_type: 'number', label: '最少拍照数', key: 'imgMinLimit', col: 22 }
            ],
            otherForm: { tableList: [] },
            pointRowFormData: [
                {
                    input_type: 'table',
                    label: '',
                    key: 'tableList',
                    col: 24,
                    styleWidth: '100%',
                    showPage: false,
                    noSearch: true,
                    table_MaxHeight: 300,
                    border: true,
                    loading: false,
                    noMarginLeft: true,
                    noMarginBottom: true,
                    tableSlot: 'table',
                    allDisabled: true,
                    columns: [
                        { label: '点位名称', prop: 'pointName', column_type: 'input', width: '200' },
                        { label: '巡查内容', prop: 'remark', column_type: 'input' }
                    ]
                }
            ],
            rowForm: {},
            loadingForm: false,
            showEditAll: false
        }
    },
    methods: {
        // 查看
        rowDetail (row) {
            this.showEditAll = true
            this.loadingForm = true
            this.rowForm = { ...row }
            this.otherForm.tableList = row.pointData
            console.info(this.rowForm)
            this.loadingForm = false
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
