<template>
  <el-dialog :visible.sync="show" width="40%" title="查看内部车辆">
    <ja-form
      :rules="rules"
      allDisabled
      v-model="rowForm"
      :formArray="rowFormData"
      v-if="show"
      :editDataFormat="editDataFormat"
    >
    </ja-form>
  </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
  components: {
    JaForm
  },
  data() {
    return {
      rules: {},
      rowFormData: [
        {
          input_type: 'input',
          label: '车牌号码',
          key: 'carNumber',
          maxlength: 20
        },
        {
          input_type: 'select',
          label: '所属公司',
          key: 'companyId',
          options: [],
          maxlength: 30,
          relevance: {
            sonKey: 'deptId',
            This: this,
            type: 'select',
            rqParam: true, // 必填。而 rqParam 是异步请求，必填字段
            rqParam: {
              rqUrl: '/department',
              rqData: () => {
                return {
                  params: {
                    pageIndex: 1,
                    pageSize: 200,
                    companyId: this.rowForm.companyId
                  }
                }
              },
              labelName: 'deptName',
              valueName: 'id'
            }
          }
        },
        {
          input_type: 'select',
          label: '所属部门',
          key: 'deptId',
          options: [],
          maxlength: 30,
          relevance: {
            sonKey: 'userId',
            This: this,
            type: 'select',
            rqParam: true, // 必填。而 rqParam 是异步请求，必填字段
            rqParam: {
              rqUrl: '/employee',
              rqData: () => {
                return {
                  params: {
                    pageIndex: 1,
                    pageSize: 200,
                    deptId: this.rowForm.deptId
                  }
                }
              },
              labelName: 'username',
              valueName: 'id'
            }
          }
        },
        {
          input_type: 'select',
          label: '使用人',
          key: 'userId',
          options: [],
          maxlength: 20,
          method: 'changeId'
        },
        {
          input_type: 'input',
          label: '联系方式',
          key: 'mobile',
          maxlength: 20,
          disabled: true
        }
      ],
      show: false,
      titleText: '新增',
      rowForm: {}
    }
  },
  mounted() {
    let index = this.rowFormData.findIndex((item) => item.key == 'companyId')
    const paramObj = { params: { pageIndex: 1, pageSize: 200 } }
    this.util.rqOptionsList({
      who: [this.rowFormData[index]],
      rqUrl: '/company',
      rqData: paramObj,
      rqMethod: 'get',
      labelName: 'companyName',
      valueName: 'id',
      otherKeys: [{ keyName: 'disabled', keyValue: 'disabled' }]
    }) // 所属公司
  },
  methods: {
    init(row) {
      this.show = true
      if (row && row.companyId) {
        let index = this.rowFormData.findIndex((item) => item.key == 'deptId')
        const paramObj = {
          params: { pageIndex: 1, pageSize: 200, companyId: row.companyId }
        }
        this.util.rqOptionsList({
          who: [this.rowFormData[index]],
          rqUrl: '/department',
          rqData: paramObj,
          rqMethod: 'get',
          labelName: 'deptName',
          valueName: 'id'
        }) // 所属公司
      }
      if (row && row.deptId) {
        let index = this.rowFormData.findIndex((item) => item.key == 'userId')
        const paramObj = {
          params: { pageIndex: 1, pageSize: 200, deptId: row.deptId }
        }
        this.util.rqOptionsList({
          who: [this.rowFormData[index]],
          rqUrl: '/employee',
          rqData: paramObj,
          rqMethod: 'get',
          labelName: 'username',
          valueName: 'id'
        }) // 所属部门
      }
      this.show = true
      this.rowForm = { ...row }
    }
  }
}
</script>