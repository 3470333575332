<template>
  <div>
    <div class="content">
      <div class="leftBox">
        <div class="title">状态类型</div>
        <div class="menu">
          <el-tabs
            v-model="activePage"
            tab-position="left"
            style="height: 200px"
          >
            <el-tab-pane
              v-for="(item, index) in leftMenu"
              :key="index"
              :label="item.label"
              :name="item.type"
            ></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="rightBox allwh">
        <ja-table
          ref="tst"
          v-model="filterData"
          :columns="columns"
          :noSearch="noSearch"
          :tableData="tableData"
          :total="total"
          :loading="loading"
          @getInit="getInit"
          @rowDetail="normalEvent"
        >
        </ja-table>
        <detail ref="detail" @getInit="getInit" />
      </div>
    </div>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import { Msglist } from '@/api'
import Detail from './Detail.vue'

export default {
  title: '消息中心',
  mixins: [table],
  components: {
    Detail
  },
  name: 'index',
  data() {
    return {
      leftMenu: [
        { label: '未读', value: 0, type: 'unread' },
        { label: '已读', value: 1, type: 'read' }
      ],
      activePage: 'unread',
      activePageValue: 0,
      noSearch: true,
      columns: [
        { label: '消息类型', prop: 'msgTypeName', width: 150 },
        { label: '接收时间', prop: 'sendTime' },
        { label: '状态', prop: 'readStatusName', width: 140 },
        {
          label: '操作',
          input_type: 'button',
          width: 140,
          attribute: {
            fixed: 'right',
            showOverflowTooltip: false,
            align: 'center'
          },
          list: [{ text: '查看', method: 'rowDetail' }]
        }
      ]
    }
  },
  watch: {
    activePage: {
      handler: function (val) {
        let item = this.leftMenu.find((v) => v.type === val)
        this.activePageValue = item.value
        console.log(this.activePageValue)
        this.filterData.pageIndex = 1
        this.getInit()
      }
    }
  },
  created() {
    console.log(this.filterData)
    // this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData })
    // this.util.rqOptionsList({
    //   who: [this.conditionData[this.indexObj.msgType]],
    //   rqUrl: '/sys/dictData/msg_type',
    //   resRule: 'data'
    // })
  },

  methods: {
    //  rqParmas() {
    //   let obj = { ...this.filterData }
    //   return obj
    // },
    getInit() {
      // 这里要怎么判断点击第5页返回未读默认的是第一页
      //  this.filterData.pageIndex = 1
      this.loading = true
      const rqData = { ...this.filterData, readStatus: this.activePageValue }
      Msglist(rqData).then((res) => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
        if(this.activePageValue === 0){
          this.$store.commit('SET_MESSAGE', this.total)
        }
      })
    }
    // rowDetail(){
    //     const detail=this.$refs.detail
    //     detail.rowDetail()

    // }
  }
}
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  .leftBox {
    margin: 16px 0 0 15px;
    width: 200px;
    flex-shrink: 0;
    .title {
      margin-bottom: 15px;
      background-color: #ffffff;
      line-height: 40px;
      padding: 10px 15px;
      font-size: 13px;
      text-align: center;
      background: white;
      color: #606266;
    }
    .menu {
      margin-bottom: 15px;
      ::v-deep .el-tabs--left {
        .el-tabs__header.is-left {
          width: calc(100% - 10px);
        }
        .el-tabs__item {
          text-align: center;
          // color: #606266;
        }
      }
      ::v-deep .el-tabs,
      ::v-deep .el-tabs__nav-scroll {
        background: white !important;
      }
    }
  }
  .rightBox {
    margin: 16px 0 0 0px;
    .bigTitle {
      color: #606266;
      font-size: 20px;
      margin: 10px 0;
    }
  }
}
</style>