<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :editDataFormat="editDataFormat" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowEdit="normalEvent" @rowDetail="normalEvent">
    </ja-table>

    <!-- 新增/编辑 -->
    <add-edit ref="addEdit" />
    <detail ref="detail" />
  </div>
</template>
<script>
import { rqTransire, } from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit.vue'
import Detail from './childComp/Detail.vue'

export default {
  title: '物品放行',
  mixins: [table,],
  name: 'index',
  components: {
    AddEdit, Detail
  },
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
        { input_type: 'select', key: 'type', attribute: { placeholder: '放行类型', size: 'small' }, options: [], },
        {
          input_type: 'select', key: 'status', attribute: { placeholder: '审批状态', size: 'small' }, options: [
            { label: '审核中', value: 0 },
            { label: '已同意', value: 1 },
            { label: '已驳回', value: 2 },
          ],
        },
        { input_type: 'input', key: 'applyEmployeeName', attribute: { placeholder: '申请人', size: 'small' } },
        { input_type: 'input', key: 'applyCode', attribute: { placeholder: '申请编号', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
      ],
      columns: [
        { label: '申请编号', prop: 'applyCode' },
        { label: '申请人', prop: 'applyEmployeeName' },
        { label: '申请单位', prop: 'applyCompanyName' },
        { label: '联系方式', prop: 'applyEmployeePhone', },
        { label: '放行类型', prop: 'typeName', },
        { label: '申请说明', prop: 'applyDescription', width: 140 },

        { label: '出门时间', prop: 'transitTime', },
        { label: '提交时间', prop: 'createTime' },
        { label: '审批状态', prop: 'statusName' },
        { label: '驳回理由', prop: 'rejectReason', },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '详情', method: 'rowDetail' },
            { text: '审批', method: 'rowEdit' },
          ]
        }
      ],
      con_indexObj: {},
    }
  },
  mounted() {
    this.con_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.util.rqOptionsList({ who: [this.conditionData[this.con_indexObj.type],], rqUrl: "/sys/dictData/transire_type", rqMethod: 'get', resRule: 'data' })
  },
  methods: {
    rqParmas() {
      let obj = { ...this.filterData }
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = true
      let data = this.rqParmas()
      rqTransire(data).then(res => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
      }).catch(err => {
        this.loading = false
      })
    },
    editDataFormat(row, btn) {
      if (row.status !== 0 && btn.text == '审批') {
        return false
      } else {
        return true
      }
    },
  }
}
</script>
