<template>
    <div>
        <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="show" width="40%" :title="titleText" v-loading="addLoading" :element-loading-text="isAdd?'新增中':'编辑中'">
            <ja-form :rules="rules" v-model="rowForm" :formArray="rowFormData" @reset="show = false" @save="saveForm" v-if="show">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import { employeeAdd, employeeUpdate } from '@/api'
export default {
    components: {
        JaForm
    },
    inject: ['labelWidth', 'getInit',],
    data () {
        return {
            rules: {
                username: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkText({textMessage:'姓名'}) }],
                jobNum: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkText() }],
                positionName: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkText() }],
                companyId: [{ required: true, message: '请选择所属公司', trigger: 'change' }],
                deptId: [{ required: true, message: '请选择所属部门', trigger: 'change' }],
                status: [{ required: true, message: '请选择状态', trigger: 'change' }],
                mobile: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkPhone() }],
                email: [{ required: false, trigger: 'blur', validator: this.util.formRules.checkEmail() }],
            },
            rowFormData: [
                { input_type: 'input', label: '用户账号', key: 'loginName', hide: true, disabled: true, maxlength: 30 },
                { input_type: 'input', label: '姓名', key: 'username', maxlength: 30 },
                {
                    input_type: 'select', label: '所属公司', key: 'companyId', options: [], maxlength: 30,
                    relevance: {
                        sonKey: 'deptId', This: this, type: "select", rqParam: true, // 必填。而 rqParam 是异步请求，必填字段
                        rqParam: { rqUrl: "/department", rqData: () => { return { params: { pageIndex: 1, pageSize: 200, companyId: this.rowForm.companyId } } }, labelName: 'deptName', valueName: 'id', otherKeys: [{ keyName: 'disabled', keyValue: 'disabled' }] },
                    }
                },
                { input_type: 'select', label: '所属部门', key: 'deptId', options: [], maxlength: 30 },
                { input_type: 'input', label: '昵称', key: 'nickname', hide: true, maxlength: 30 },
                { input_type: 'input', label: '手机号', key: 'mobile', styleWidth: 'auto', maxlength: 30, },
                { input_type: 'input', label: '工号', key: 'jobNum', styleWidth: 'auto', maxlength: 30 },
                { input_type: 'radio', label: '性别', key: 'gender', options: [{ "value": 0, "label": "女" }, { "value": 1, "label": "男" }] },
                { input_type: 'input', label: '职位', key: 'positionName', maxlength: 30 },
                { input_type: 'input', label: '初始化密码', type: 'password', key: 'password', showPassword: true, disabled: true },
                { input_type: 'input', label: '邮箱', key: 'email', maxlength: 30 },
                { input_type: 'radio', label: '状态', key: 'status', options: [{ "value": 0, "label": "正常" }, { "value": 1, "label": "停用" }] },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', col: 22, maxlength: 200 }

            ],
            show: false,
            titleText: '新增',
            rowForm: {},
            isAdd: false,
            addLoading: false
        }
    },
    mounted () {
        let index = this.rowFormData.findIndex(item => item.key == 'companyId')
        const paramObj = { params: { pageIndex: 1, pageSize: 200 } }
        this.util.rqOptionsList({ who: [this.rowFormData[index]], rqUrl: "/company", rqData: paramObj, rqMethod: 'get', labelName: 'companyName', valueName: 'id', otherKeys: [{ keyName: 'disabled', keyValue: 'disabled' }] }) // 所属公司
    },
    methods: {
        init (state, row) {
            this.show = true
            this.rowForm = { password: 123456 }
            if (row && row.companyId) {
                let index = this.rowFormData.findIndex(item => item.key == 'deptId')
                const paramObj = { params: { pageIndex: 1, pageSize: 200, companyId: row.companyId } }
                this.util.rqOptionsList({ who: [this.rowFormData[index]], rqUrl: "/department", rqData: paramObj, rqMethod: 'get', labelName: 'deptName', valueName: 'id', otherKeys: [{ keyName: 'disabled', keyValue: 'disabled' }] }) // 所属公司
            }
            if (state == 1) {
                this.isAdd = true
                this.titleText = "新增"
                this.rowFormData[0].hide = true
                this.rowFormData[1].hide = false
                this.rowFormData[4].hide = true
            } else if (state == 2) {
                this.isAdd = false
                this.titleText = "编辑"
                this.rowForm = { password: 123456, ...row }
                this.rowFormData[0].hide = false
                this.rowFormData[1].hide = true
                this.rowFormData[4].hide = false
            }
        },
        saveForm () {
            this.addLoading = true
            if (this.isAdd) {
                employeeAdd(this.rowForm).then(res => {
                    if (res.code !== 200) return this.$message.error('新增失败')
                    this.show = false
                    this.addLoading = false
                    this.getInit()
                }).catch(err => {
                    this.addLoading = false
                })
            } else {
                employeeUpdate(this.rowForm).then(res => {
                    if (res.code !== 200) return this.$message.error('编辑失败')
                    this.show = false
                    this.addLoading = false
                    this.getInit()
                }).catch(err => {
                    this.addLoading = false
                })
            }

        }
    }
}
</script>