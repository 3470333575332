<template>
  <router-view />
</template>

<script>
export default {
  title: '收费配置',
  name: 'chargeConfig'
}
</script>

<style scoped>

</style>
