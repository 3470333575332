// 空间表头
export const spaceColumns = [
    //TODO l 未改名称
    { label: '单元编号', prop: 'spaceCode' },
    { label: '关联状态', prop: 'spaceStatusName' },
    { label: '关联租赁区域', prop: 'roomName' ,width:130},
    { label: '所在楼栋', prop: 'buildingName' },
    { label: '所在楼层', prop: 'floorName' },
    { label: '套内面积(m²)', prop: 'insideArea' },
    { label: '建筑面积(m²)', prop: 'outsideArea' },
    { label: '房产面积(m²)', prop: 'premisesPermitArea' },
    { label: '操作', prop: 'operation',
        input_type: 'button',
        list: [
        { text: '删除', method: 'rowDelete_formTable' },
    ]},
]
// 空间表头
export const spaceColumnsTwo = [
    //TODO l 未改名称
    { label: '空间编号', prop: 'spaceCode' },
    { label: '关联状态', prop: 'spaceStatusName' },
    { label: '关联空间', prop: 'roomName' },
    { label: '所在楼栋', prop: 'buildingName' },
    { label: '所在楼层', prop: 'floorName' },
    { label: '套内面积(m²)', prop: 'insideArea' },
    { label: '建筑面积(m²)', prop: 'outsideArea' },
    { label: '房产面积(m²)', prop: 'premisesPermitArea' },
]