<template>
    <div class="edit">
        <el-dialog v-dialogDrag :close-on-click-modal="false" :title="title" :visible.sync="show" width="25%" @close="close" :before-close="close">
            <ja-form :rules="rules" v-model="adjustFormData" :formArray="rowForm" @reset="close" @save="saveForm" labelWidth="120px">
            </ja-form>
        </el-dialog>
    </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import { AdjustUnitPriceData } from '../billManage'
export default {
    title:'调整',
    name: 'EditOccupant',
    components: {
        JaForm
    },
    data () {
        return {
            rowForm: AdjustUnitPriceData,
            rules: {}
        }
    },
    
    props: {
        title: String,
        id: String,
        show: {
            type: Boolean,
            default: false
        },
        correctionEventData: [Object, Array],
        adjustFormData: {
            type: Object,
            default:{}
        }
    },
    methods: {
        /** 保存表单 */
        saveForm () {
            this.$emit('correctionEvent',this)
        },
        /** 关闭弹出框 */
        close () {
            this.$emit('closeAdjustmentDialog', false)
        }
    }
}
</script>

<style less="scss" scoped>
     /deep/ .el-input-number .el-input__inner {
        padding-left: 0;
    }
</style>
